import { MapEuropeSVG } from 'assets/maps/MapEuropeSVG';
import { MapItalySVG } from 'assets/maps/MapItalySVG';
import { DocidSVG } from 'assets/products/DocIDSVG';
import { EegbillSVG } from 'assets/products/EegbillSVG';
import { PayslipSVG } from 'assets/products/PayslipSVG';
import { ReceiptSVG } from 'assets/products/ReceiptSVG';
import { VehicleRegSVG } from 'assets/products/VehicleRegSVG';
import { CadastralSVG } from 'assets/products/cadastralSVG';
import { DefaultSVG } from 'assets/products/deafultSVG';
import {TransportDocSVG} from "../../assets/products/TransportDoc";

export const modelInfo = {
    'model1': {
        map: <MapEuropeSVG width={407} height={207} />,
        supportedDocs: 'Europe',
        svg: <DocidSVG width={340} height={200} />,
        bigSVG: <DocidSVG width={'100%'} height={'100%'} />,
        title: "Identity Documents",
        description: "Streamline document processes with a solution that can automatically extract key information from Identity Documents. Achieve unprecedented accuracy by eliminating 90% of repetitive tasks with myBiros.",
        fields: ["Name", "Surname", "Id Number", "Issue Date", "Expire Date", "Birth Date", "Birth Place", "Fiscal Code", "Issue Place", "Residence", "Address", "Nationality", "Sex"],
        img_example: 'https://assets-global.website-files.com/643edefedd2d924e7c378774/653926a5bb96259e6c021aca_Cartadidentita%CC%80_OCR.png'

    },
    'model2': {
        map: <MapItalySVG width={407} height={207} />,
        supportedDocs: 'Italy',
        svg: <ReceiptSVG width={340} height={200} />,
        bigSVG: <ReceiptSVG width={'100%'} height={'100%'} />,
        title: "Expense Notes",
        description: "Streamline document processes with a solution that can automatically extract key information from Receipt. Achieve unprecedented accuracy by eliminating 90% of repetitive tasks with myBiros.",
        fields: ["Vendor Name", "Fiscal Code", "Address", "Residence", "Postal Code", "Currency", "Total", "Terms Of Payment", "Issue Date", "Time", "PhoneNumber"],
        img_example: 'https://assets-global.website-files.com/643edefedd2d924e7c378774/6539268154a0542a563f90ef_OCR_ricevute.png'
    },
    'model3': {
        map: <MapItalySVG width={407} height={207} />,
        supportedDocs: 'Italy',
        svg: <EegbillSVG width={340} height={200} />,
        bigSVG: <EegbillSVG width={'100%'} height={'100%'} />,
        title: "Energy/Gas Bill",
        description: "Streamline document processes with a solution that can automatically extract key information from Receipt. Achieve unprecedented accuracy by eliminating 90% of repetitive tasks with myBiros.",
        fields: ["ReceiverResidence", "ReceiverAddress", "ReceiverPostalCode", "DeliveryResidence", "DeliveryAddress", "DeliveryPostalCode", "AvailablePower", "BillNumber", "BillRate", "CustomerNumber", "CustomerType", "BillExpireDate", "F1Consumption", "F2Consumption", "F3Consumption", "MarketType", "CustomerFiscalCode", "REMI", "PDR", "GasScope", "BillIssueDate", "Periodicity", "POD", "PowerUsed", "ReceiverName", "ReferencePeriod", "ReferralDistributorStation", "SupplyStartDate", "SupplyType", "SupplyVoltage", "Total", "EnergyExpenditure", "Deductions", "GasTotalConsumption", "GasExpenditure", "Total", "Consumption", "AnnualConsumption", "TotalVAT", "CustomerVAT", "CustomerNumber"],
        img_example: 'https://assets-global.website-files.com/643edefedd2d924e7c378774/64cce158acde683f829d244a_Bollette_OCR.png'
    },
    'model4': {
        map: <MapItalySVG width={407} height={207} />,
        supportedDocs: 'Italy',
        svg: <PayslipSVG width={340} height={200} />,
        bigSVG: <PayslipSVG width={'100%'} height={'100%'} />,
        title: "Payslip",
        description: "Streamline document processes with a solution that can automatically extract key information from Payslip. Achieve unprecedented accuracy by eliminating 90% of repetitive tasks with myBiros.",
        fields: ["Indirizzo", "Data Nascita", "Data Assunzione", "Mese Retribuzione", "Anno Retribuzione", "TipoRapporto", "IBAN", "ID_Cedolino", "DatoreLavoro", "Nome", "Cognome", "Totale Trattenute", "Netto", "Totale Competenze"],
        img_example: 'https://assets-global.website-files.com/643edefedd2d924e7c378774/6538eef2065196ad4c99ac66_BustaPaga-p-1080.png'
    },
    'model5': {
        map: <MapItalySVG width={407} height={207} />,
        supportedDocs: 'Italy',
        svg: <VehicleRegSVG width={340} height={200} />,
        bigSVG: <VehicleRegSVG width={'100%'} height={'100%'} />,
        title: "Vehicle Registration Document",
        description: "Streamline document processes with a solution that can automatically extract key information from Vehicle Registration Documents. Achieve unprecedented accuracy by eliminating 90% of repetitive tasks with myBiros.",
        fields: ["OwnerAddress", "RenterAddress", "OwnerBirthDate", "RenterBirthDate", "OwnerBirthPlace", "RenterBirthPlace", "LicensePlateNumber", "VehicleManufacturer", "RegistrationDate", "OwnerFiscalCode", "RenterFiscalCode", "VehicleModel", "VehicleIdNumber", "VehicleBodywork", "VehicleApproval", "VehicleCapacity", "VehiclePower", "VehicleSupply", "RevisionDate", "OwnershipChangeDate", "Owner", "Renter", "RenterName", "OwnerName", "OwnerPostalCode", "OwnerResidence", "RenterResidence", "RenterPostalCode"],
        img_example: 'https://assets-global.website-files.com/643edefedd2d924e7c378774/654269c3b3bc14ad497cca5f_654269a9c91557b80f7bd78b_Librettodicircolazione.png'
    },
    'model6': {
        map: <MapItalySVG width={407} height={207} />,
        supportedDocs: 'Italy',
        svg: <CadastralSVG width={340} height={200} />,
        bigSVG: <CadastralSVG width={'100%'} height={'100%'} />,
        title: "Cadastral Documents",
        description: "Streamline document processes with a solution that can automatically extract key information Cadastral Documents. Achieve unprecedented accuracy by eliminating 90% of repetitive tasks with myBiros.",
        fields: ["Address", "Holder Birth Date", "Holder Fiscal Code", "Cadastral Category", "Total Area", "Property Right", "Holder", "Sheet", "Parcel", "Subordinate", "Holder Domicile", "Holder Birth Date", "Municipality"],
        img_example: 'https://assets-global.website-files.com/643edefedd2d924e7c378774/6538ef87242f7f0a4b9564ae_Visuracatastale-p-1080.png'
    },
    'model7': {
        map: <MapItalySVG width={407} height={207} />,
        supportedDocs: 'Italy',
        svg: <TransportDocSVG width={340} height={200} />,
        bigSVG: <TransportDocSVG width={'100%'} height={'100%'} />,
        title: "Transport Documents",
        description: "Streamline document processes with a solution that can automatically extract key information from Transport Documents. Achieve unprecedented accuracy by eliminating 90% of repetitive tasks with myBiros.",
        fields: ["Document Date", "Document Number", "Recipient Name", "Recipient Address", "Recipient Postal Code", "Recipient City", "Delivery Address", "Delivery Postal Code", "Delivery City", "Sender VAT", "Sender", "Total Amount", "Product Code", "Product Quantity", "Product Price", "Payment Mark"],
        img_example: ''
    },

    'default': {
        map: <MapItalySVG width={407} height={207} />,
        supportedDocs: 'Default',
        svg: <DefaultSVG width={340} height={200} />,
        bigSVG: <DefaultSVG width={'90%'} height={'90%'} />,
        title: "Default Title",
        description: "Streamline document processes with a solution that can automatically extract key information from your documents. Achieve unprecedented accuracy by eliminating 90% of repetitive tasks with myBiros.",
        fields: ["Default Label"],
    }
}

export const getModelInfo = (model) => {
    if (modelInfo[model]) return modelInfo[model]
    return modelInfo['default']
}