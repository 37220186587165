import { CanvasToolbarBtn } from "components/productResultCanvas/components/canvasToolbarBtn";

type Props = {
  zoom: (scale: number) => void;
  rotate: (angle: number) => void;
  handleSimpleAnnotationMode: () => void;
};

export function CanvasToolbar({
  zoom,
  rotate,
  handleSimpleAnnotationMode,
}: Props) {
  return (
    <div className="absolute z-30 !w-full flex flex-row justify-between gap-10 max-lg:!flex-col p-1">
      <div className="w-fit flex flex-row gap-5">
        <CanvasToolbarBtn action={zoom} param={1.2} icon={"ZoomIn"} />
        <CanvasToolbarBtn action={zoom} param={0.8} icon={"ZoomOut"} />
        <CanvasToolbarBtn action={rotate} param={-90} icon={"RotateLeft"} />
        <CanvasToolbarBtn action={rotate} param={90} icon={"RotateRight"} />
      </div>

    </div>
  );
}
