import { useEffect, useState } from "react"
import * as API from '../../apis/api'
import { getModelInfo } from "../products/products"

export default function CatalogItem({ item, select, fallback, type = "USE_CASE" }) {

    const stub_props = {
        service: {},
        image_url: "https://assets-global.website-files.com/643edefedd2d924e7c378774/64ba97f5207f66103efa354d_Frame%208420.svg",
        name: "USE CASE NAME",
        task: "Information Extraction"
    }
    const [status, setStatus] = useState('ACTIVE')

    useEffect(() => {
        if (type === 'MODEL') return;
        const fetchUseCaseStatus = async () => {

            await API.getResourceJobs('USE_CASE', item.public_id).then((jobs) => {
                jobs.forEach((job) => {
                    if (job.find((x) => x === 'USE_CASE_DELETE'))
                        setStatus('DELETING')
                })
            })
        }

        fetchUseCaseStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        status === 'ACTIVE' &&
        <div className="h-full w-full flex">
            <div id={item.name} role="listitem" className="catalog-item">
                <div className="catalog-item-container" onClick={() => { fallback && fallback(item) }}>

                    <div className="catalog-item-top">
                        {
                            getModelInfo(item.hasOwnProperty("model") ? item.model : item.public_id).svg
                        }

                    </div>
                    <div className="catalog-item-bottom items-center">
                        <span className="text-card-title">
                            {item.name}
                        </span>

                        <div className="catalog-item-footer">
                            <label className='service-card-badge'>
                                {item.task ? item.task : stub_props.task}
                            </label>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}