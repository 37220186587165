import * as API from "apis/api";
import { userIsAdmin } from "auth/auth";
import { Button } from "components/button/Button";
import { useNotifications } from "components/notificationSystem/notificationContext";
import { getModelInfo } from "components/products/products";
import { JuneEvents, trackJune } from "helpers/hooks/useJune";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export const CreateUseCaseSecondStep = ({ selectedProduct, nUseCases, loadingData, setLoadingData, setNavigateAfterLoad, analytics }) => {

    const navigate = useNavigate()


    const [navigateToDashboard, setNavigateToDashboard] = useState(false)


    useEffect(() => {
        if (navigateToDashboard) navigate('/login', { replace: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateToDashboard])

    const notification = useNotifications()
    const checkDeployStatus = async (resourceID) => {
        setLoadingData(true)
        let interval;
        const response = await API.getUseCaseDeployStatus(resourceID).catch(e => {
            setNavigateAfterLoad({ 'to': '/', 'replace': true })
            setLoadingData(false)
            return
        })
        if (response['deploy_status'] === "running") {
            setNavigateAfterLoad({ 'to': `/usecase/${resourceID}`, 'replace': true })
            setLoadingData(false)
            return
        }
        else if (response['deploy_status'] === "error") {
            alert("create use case error")
            setNavigateAfterLoad({ 'to': '/', 'replace': true })
            setLoadingData(false)
            return
        }
        if (loadingData) {
            interval = setInterval(checkDeployStatus(resourceID), 3000)
        } else {
            setNavigateAfterLoad({ 'to': `/usecase/${resourceID}`, 'replace': true })
            setLoadingData(false)
        }
        return () => setInterval(interval)
    }

    const handleCreateBtn = async () => {
        const stubName = selectedProduct.name
        const stubDescription = 'This is a ' + stubName + ' Use Case'
        await API.addUseCase(stubName, stubDescription, selectedProduct).then(res => {
            checkDeployStatus(res.resource)
            trackJune(analytics, JuneEvents.SETUP)
        }).catch(e => {
            if (e.response.status === 403) {
                notification.addNotification({
                    title: `Can't create a new use case`,
                    message: 'Max use case limit reached.',
                    severity: 'ERROR',
                    timeout: 10000
                })
            }
            setLoadingData(false)
            setNavigateToDashboard(true)
        })



    }

    const model = getModelInfo(selectedProduct.public_id)

    return (
        <div className="product-bg default-page-layout !overflow-y-scroll py-5">
            <div className="default-page-container max-[1370px]:!max-w-[900px] max-[1250px]:!max-w-[800px]">
                <div className="flex !flex-row !h-fit max-lg:!flex-col">
                    <div className="dashboard-head !flex-col !w-1/2 max-lg:!w-full">



                        <div className="page-header relative !flex-col !items-start mb-4">
                            <Button className=" absolute right-10 lg:hidden" inverted action={() => { handleCreateBtn() }} disabled={!userIsAdmin() && nUseCases >= 3}>
                                Create Use Case
                            </Button>

                            <div className="text-page-title">{selectedProduct.name}</div>
                            <div className="text-subtitle">Choose a product and start using myBiros! </div>
                        </div>
                        <div className="w-full flex-col h-[80%]">
                            <div className="w-full text-description leading-6">
                                {model.description}
                            </div>
                            <div className="w-full flex flex-row gap-10 mt-10">
                                <div className="relative pb-[56.25%] h-full w-full">
                                    <iframe title='example' src="https://www.loom.com/embed/7e85c88a23cf4dd389e7ee0ff7082ca0?sid=6d32a721-5fcb-4099-a357-c34a656f1c7b" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                                        className='absolute top-0 left-0 !w-full !h-full' /></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center max-lg:!w-full max-lg:!justify-center items-end w-1/2">

                        <Button className="max-lg:hidden" inverted action={() => { handleCreateBtn() }} disabled={!userIsAdmin() && nUseCases >= 3}>
                            Create Use Case
                        </Button>

                        {model.bigSVG}

                    </div>

                </div>

                <div className="relative flex items-center max-md:justify-center ">
                    <div className="absolute h-[150px] w-[1000vw] bg-[#f4f7fc] -left-[100%]">
                    </div>
                    <div className="w-1/2 flex justify-end px-20 z-10 max-md:hidden">
                        {model.map}
                    </div>
                    <div className=" !h-32 flex justify-start items-center max-md:px-0 px-40  z-10">

                        <img src={'https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/64aa9b4837b580ededfaa148_invoice%201.svg'} alt='Supported Languages' />
                        <div className="flex flex-col h-full justify-center ps-10">
                            <span className="text-slate-500">
                                Supported documents
                            </span>
                            <span className="text-4xl font-light ">
                                {model.supportedDocs}
                            </span>
                        </div>
                    </div>
                </div>


                <div className="!h-fit mb-12">


                    <div className="h-fit flex flex-col gap-5">
                        <div className="page-header flex flex-col gap-2">
                            <div className="text-title text-primary-color">Supported Labels</div>
                        </div>
                        <div className={"mb-5"}>
                            <div className="text-description">myBiros can identifies and extracts any information or elements in the document.
                                The labels supported to date are: </div>

                        </div>

                        <div className="h-fit mb-32 w-full flex  justify-center items-center gap-10">
                            {/*<div className="w-1/2 h-full">
                            <img src={model.img_example} alt={model.title} />
                        </div>*/}
                            <div className=" h-fit flex flex-row flex-wrap gap-5" >
                                {model.fields.map((label) => <div className="bg-[#f1f1f1] h-fit px-6 py-1 rounded-[20px]">
                                    <p className="text-[#546681]"> {label} </p>
                                </div>)}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}