import { DashboardSVG } from "assets/sidebar/DashboardSVG"
import { MonitorSVG } from "assets/sidebar/MonitorSVG"
import { OrgSVG } from "assets/sidebar/OrgSVG"
import { SettingsSVG } from "assets/sidebar/SettingsSVG"
import { UsecasesSVG } from "assets/sidebar/UsecasesSVG"
import { UserSVG } from "assets/sidebar/userSVG"
import { ValidationSVG } from "assets/sidebar/ValidationSVG"
import { ValidationBar } from "assets/sidebar/ValidationBar"
import { isUserAdmin } from "auth/auth"
export const Default = () => [

    { title: "Home", svg: <DashboardSVG width={25} height={25} />, to: "/", children: [] },
    {
        title: "Organization",
        svg: <OrgSVG width={25} height={25} />,
        to: "/organization",
        children: []
    },
    {
        title: "User", svg: <UserSVG width={25} height={25} />, to: "/user",
        children: []
    },

]

export const UseCase = ({ UCID }) => {

    let menu =
        [

            {
                title: "Home",
                svg: <DashboardSVG width={25} height={25} />,
                to: "/",
                children: []
            },
            {
                title: "Documents",
                svg: <UsecasesSVG width={25} height={25} />,
                to: "/usecase/" + UCID + "/documents",
                children: []
            },


        ]
    if (true) {
        menu = menu.concat([
            {
                title: "Settings",
                svg: <SettingsSVG width={25} height={25} />,
                to: "/usecase/" + UCID + "/settings/",
                children: [
                    {
                        title: "General",
                        svg: <ValidationSVG width={25} height={25} />,
                        to: "/usecase/" + UCID + "/settings/general",
                        children: []
                    },
                    {
                        title: "API Keys",
                        svg: <MonitorSVG width={25} height={25} />,
                        to: "/usecase/" + UCID + "/settings/api-keys",
                        children: []
                    },
                    {
                        title: "Validation",
                        svg: <ValidationBar width={25} height={25} />,
                        to: "/usecase/" + UCID + "/settings/validation",
                        children: []
                    },
                    
                ]
            }])
    }
    else {
        menu = menu.concat([
            {
                title: "Settings",
                svg: <SettingsSVG width={25} height={25} />,
                to: "/usecase/" + UCID + "/settings",
                children: []
            }])
    }


    return menu
}
