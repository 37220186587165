/*

    Creazione a step:
    1- campo obbligatorio o confidence score? (Campo Obbligatorio, Core Rule)scegliere nomi??
    2- CO - 'Seleziona i campi che ti aspetti di ricevere sempre valorizzati durante l'elaborazione', toogle vicino per label a lista di label
    3- CO - Applicare a tutte le pagine, altrimenti selezione range di pagine
    2- CS - Seleziona il campo (stubba false must exists per ora) e slider da 0 a 100
    3- CS - identico al 3 di CO
    4- Scegli nome??? Forse.

*/
import { useEffect, useState } from "react";
import { StepResume } from "./steps/stepResume";
type Dict = {
  [key: string]: any;
};

type Rule = {
  rule_apply_on_pages: string;
  rule_args: Dict;
  rule_class: "RequiredFields" | "FieldMinConfidence" | null | undefined;
  rule_enable: boolean;
  rule_name: string;
};

type Props = {
  rule: Rule;
  labels: Array<any>;
  setSelectedInfoIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const InfoPolicyModal = ({
  rule,
  labels,
  setSelectedInfoIndex,
}: Props) => {
  const [ruleToView, setRuleToView] = useState<Dict>({});

  useEffect(() => {
    let newRuleToView = {} as Dict;
    newRuleToView["name"] = rule.rule_name;
    newRuleToView["policyType"] = rule.rule_class;
    if (Object.keys(rule.rule_args).find((x) => x === "field")) {
      newRuleToView["selectedLabels"] = null;
      newRuleToView["selectedScoreLabel"] = {
        label: labels.find((x) => x[0] === rule.rule_args["field"]),
        score: rule.rule_args["min_value"],
      };
    } else {
      //rule.rule_args["required_fields"].map(x => labels.find((y) => y[0] === x));
      newRuleToView["selectedLabels"] = rule.rule_args["required_fields"].map(
        (x: string) => labels.find((y) => y[0] === x)
      );
      newRuleToView["selectedScoreLabel"] = null;
    }

    if (rule.rule_apply_on_pages === "*") {
      newRuleToView["isAllPages"] = true;
      newRuleToView["fromAToB"] = null;
    } else {
      newRuleToView["isAllPages"] = false;
      let [a, b] = rule.rule_apply_on_pages.split("-");
      newRuleToView["fromAToB"] = { A: a, B: b };
    }
    setRuleToView(newRuleToView);
  }, [rule, labels]);

  const backCallback = () => {
    setSelectedInfoIndex(-1);
  };

  return (
    <div className="absolute flex items-center justify-center bg-black w-full !z-10 h-full !pointer-events-default bg-opacity-10">
      <div className="h-[70%] min-h-[400px] !max-h-[50%] w-fit min-w-[500px] flex flex-col gap-2 ">
        <div className="bg-white shadow-md flex flex-col w-full border px-10 rounded-myb p-1 ">
          <div className="h-20 !text-2xl text-gray-500 w-full flex items-center justify-center">
            Validation Policy
          </div>

          <div className="w-full h-full flex gap-2 flex-col !overflow-hidden">
            {Object.keys(ruleToView).length > 0 && (
              <StepResume
                backCallback={backCallback}
                policyType={ruleToView["policyType"]}
                selectedLabels={ruleToView["selectedLabels"]}
                selectedScoreLabel={ruleToView["selectedScoreLabel"]}
                isAllPages={ruleToView["isAllPages"]}
                fromAToB={ruleToView["fromAToB"]}
                createCallback={null}
                newIndex={-1}
                infoName={ruleToView["name"]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
