import { EmptyField } from "assets/usecase/EmptyField";
import { useContext } from "react";
import { StageContext } from "../stageContext";

type Dict<T> = {
  [key: string]: T | null;
};
type Aggregation = {
  aggregation_type: string;
  aggregation_fields: Array<string>;
};

type Label = {
  tag: string;
  tag_alias: string;
  tag_multiple_values: false;
  tag_aggregation: Aggregation | null;
};

type Entity = {
  id: number;
  confidence: number;
  text: string;
  bounding_boxes: Array<any>;
};

type Props = {
  labelKey: string;
  field: Entity | null;
  label: Label | null;
  formatText: (text: string | undefined) => string;
};

export const AggregatedEntityPill = ({
  labelKey,
  field,
  label,
  formatText,
}: Props) => {
  const stageContext = useContext(StageContext);

  const scoreColorMap = (score: number | undefined) => {
    if (!score) return "rgba(0,0,0,0.15)";
    if (score >= 95) {
      return "rgba(0, 255, 0, 0.25)";
    } else if (score === 0) {
      return "rgba(136,136,136,0.25)";
    } else if (score >= 80) {
      return "rgba(255,183,0,0.25)";
    } else if (score < 80) {
      return "rgba(255,0,0,0.25)";
    }
    return "rgba(0,0,0,0.15)";
  };

  return (
    <div
      id={`anchor-${field?.id}`}
      className="h-fit !min-h-[2.5rem] grid grid-cols-3 ps-5 pe-[0.9rem] items-center border-b"
      onMouseEnter={(e) => {
        stageContext?.setHighlighted(
          field && label ? { id: field.id, tag: labelKey } : null
        );
      }}
      onMouseLeave={() => {
        stageContext?.setHighlighted(null);
      }}
    >
      <div className="!font-semibold whitespace-wrap ">
        {formatText(label?.tag_alias)}
      </div>
      <div className="text-center text-[14px] whitespace-nowrap overflow-x-auto overflow-y-hidden">
        {field?.text}
      </div>

      <div className="flex justify-end">
        <div>
          {field ? (
            <>
              {field.confidence ? (
                <div className="flex flex-row items-center gap-2">
                  <div
                    className="h-8 w-8 rounded-full flex justify-center items-center text-[12px]"
                    style={{
                      backgroundColor: field
                        ? scoreColorMap(field.confidence)
                        : "white",
                    }}
                  >
                    {Math.floor(field?.confidence)}
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center gap-2">
                  <div
                    className="h-8 w-8 rounded-full flex justify-center items-center text-[12px]"
                    style={{
                      backgroundColor: field
                        ? scoreColorMap(field.confidence)
                        : "white",
                    }}
                  >
                    <EmptyField width={20} height={20} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <> </>
          )}
        </div>
      </div>
    </div>
  );
};
