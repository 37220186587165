export default function Plan({ text = 'Plan Usage', planUsage, planLimit, width = 15 }) {

    let barColor = () => {

        let percent = (planUsage * 100) / planLimit
        if (percent < 75) {
            return 'rgba(60,232,141,0.75)'
        }
        if (percent < 90)
            return 'rgba(234, 179, 8, 0.75)'

        if (percent > 90)
            return 'rgba(249, 115, 22, 0.75)'
    }

    const remToPx = (rem) => rem / 0.0625
    return (
        <div className={`relative w-[${remToPx(width)}px] h-11 flex flex-col justify-center`}>
            <div className={`relative w-[${remToPx(width)}px] h-11 flex flex-col justify-center items-start`}>
                <div className="relative h-full flex flex-row justify-center items-center gap-2 mb-2">
                    <span className="text-sm text-primary-color ">
                        {text}
                    </span>
                    {planUsage === 0 && planLimit === 0 ?
                        <></>
                        :
                        <span className="font-thin text-primary-color ">{planUsage} / {planLimit}</span>
                    }
                </div>
                <div className={`absolute bottom-1 h-2 bg-slate-200 rounded-full`} style={{
                    width: `${width}rem`
                }} />
                <div className={`absolute bottom-1 h-2 rounded-full max-w-[${remToPx(width)}px] !transition-all !duration-300 !ease-in-out`} style={{
                    width: `${(planUsage * width) / planLimit}rem`,
                    backgroundColor: barColor()
                }} />

            </div>
        </div>
    )
}