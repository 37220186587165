export const Error503SVG = ({ width = 657, height = 638 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 657 638" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M463.547 584.891C461.983 584.891 460.421 584.767 458.876 584.52C458.476 584.453 458.081 584.369 457.691 584.257C449.661 581.971 442.501 577.334 437.136 570.933C431.772 564.538 428.451 556.678 427.602 548.373C424.379 518.379 422.481 487.835 421.959 457.633C421.431 427.039 422.313 396.051 424.564 365.552C424.662 364.228 425.02 362.936 425.616 361.751C426.213 360.565 427.038 359.508 428.043 358.641C429.048 357.774 430.214 357.113 431.475 356.697C432.735 356.281 434.066 356.117 435.389 356.214C436.713 356.312 438.005 356.67 439.191 357.267C440.376 357.863 441.433 358.688 442.3 359.693C443.167 360.698 443.828 361.865 444.244 363.125C444.661 364.386 444.824 365.716 444.727 367.04C442.515 396.944 441.656 427.314 442.172 457.296C442.677 486.897 444.541 516.812 447.703 546.273C448.118 550.456 449.751 554.426 452.4 557.69C455.049 560.954 458.596 563.37 462.604 564.639C463.663 564.762 464.736 564.667 465.755 564.352C466.774 564.038 467.716 563.516 468.522 562.82C472.823 559.389 476.068 551.691 476.602 543.673C476.602 543.398 476.646 543.112 476.686 542.859C482.553 503.354 487.449 463.158 491.245 423.395C496.556 367.697 499.869 311.1 501.093 255.116C501.122 253.788 501.412 252.48 501.947 251.265C502.482 250.05 503.251 248.952 504.21 248.034C505.169 247.116 506.3 246.397 507.537 245.915C508.774 245.435 510.094 245.202 511.421 245.231C512.749 245.26 514.057 245.55 515.272 246.086C516.487 246.62 517.585 247.389 518.503 248.348C519.421 249.307 520.14 250.438 520.622 251.675C521.103 252.912 521.335 254.232 521.306 255.559C520.071 312.015 516.73 369.128 511.374 425.315C507.556 465.297 502.637 505.712 496.742 545.436C495.725 559.237 489.908 571.612 481.138 578.614C476.175 582.673 469.96 584.891 463.547 584.891Z" fill="white" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M386.44 88.1225C385.913 88.0719 385.609 87.8249 385.609 87.314C385.609 87.1028 385.693 86.9001 385.843 86.7502C385.992 86.6009 386.195 86.5167 386.407 86.5167C386.618 86.5167 386.821 86.6009 386.97 86.7502C387.12 86.9001 387.204 87.1028 387.204 87.314C387.209 87.4178 387.193 87.5217 387.157 87.6194C387.121 87.7171 387.066 87.8064 386.995 87.8816C386.923 87.9574 386.837 88.0175 386.742 88.059C386.647 88.1006 386.544 88.1219 386.44 88.1225Z" fill="black" />
            <path d="M297.93 78.8076C298.452 78.8469 298.772 79.0827 298.794 79.5937C298.81 79.7009 298.802 79.8098 298.771 79.9132C298.739 80.0165 298.686 80.1114 298.613 80.1916C298.541 80.2714 298.451 80.3348 298.351 80.3758C298.252 80.4174 298.144 80.4359 298.036 80.4303C297.929 80.4437 297.82 80.4331 297.716 80.4C297.614 80.3668 297.519 80.3124 297.438 80.2394C297.359 80.1658 297.295 80.0765 297.253 79.9772C297.211 79.8772 297.191 79.7694 297.194 79.661C297.182 79.5561 297.193 79.4499 297.224 79.3489C297.255 79.2478 297.307 79.1546 297.376 79.0743C297.445 78.994 297.53 78.9295 297.625 78.8834C297.72 78.8374 297.824 78.8121 297.93 78.8076Z" fill="black" />
            <path d="M255.555 112.103C256.055 112.159 256.364 112.412 256.358 112.917C256.368 113.024 256.354 113.133 256.317 113.234C256.28 113.335 256.22 113.427 256.144 113.503C256.067 113.579 255.974 113.636 255.873 113.672C255.771 113.708 255.663 113.72 255.555 113.709C255.449 113.722 255.342 113.71 255.241 113.677C255.14 113.644 255.047 113.588 254.97 113.515C254.893 113.441 254.833 113.352 254.794 113.252C254.755 113.153 254.74 113.046 254.747 112.94C254.747 112.378 255.073 112.159 255.555 112.103Z" fill="black" />
            <path d="M273.837 144.124C273.833 144.016 273.851 143.908 273.892 143.808C273.933 143.707 273.995 143.617 274.074 143.543C274.153 143.469 274.247 143.413 274.349 143.378C274.451 143.344 274.56 143.332 274.668 143.343C274.87 143.365 275.057 143.461 275.192 143.613C275.326 143.766 275.398 143.964 275.393 144.167C275.387 144.37 275.306 144.565 275.165 144.711C275.023 144.857 274.832 144.944 274.629 144.955C274.521 144.956 274.414 144.935 274.316 144.892C274.216 144.851 274.127 144.789 274.053 144.711C273.979 144.633 273.921 144.541 273.884 144.44C273.847 144.339 273.831 144.231 273.837 144.124Z" fill="black" />
            <path d="M363.628 97.5664C363.628 97.4592 363.651 97.3531 363.694 97.2548C363.737 97.1566 363.801 97.0679 363.88 96.9954C363.959 96.9224 364.052 96.8668 364.154 96.832C364.255 96.7967 364.363 96.7832 364.47 96.7916C364.58 96.7854 364.69 96.8023 364.793 96.841C364.896 96.8792 364.99 96.9387 365.069 97.0156C365.148 97.092 365.211 97.1841 365.252 97.2863C365.294 97.3879 365.314 97.4974 365.311 97.6074C365.308 97.7175 365.283 97.8258 365.237 97.9258C365.19 98.0252 365.123 98.1144 365.041 98.1869C364.957 98.2593 364.861 98.3143 364.756 98.3475C364.651 98.3811 364.54 98.3924 364.431 98.3806C363.914 98.3694 363.673 98.0493 363.628 97.5664Z" fill="black" />
            <path d="M294.465 108.448C294.409 108.942 294.156 109.268 293.657 109.268C293.549 109.277 293.441 109.263 293.339 109.226C293.238 109.189 293.146 109.13 293.069 109.053C292.994 108.977 292.935 108.884 292.899 108.782C292.862 108.681 292.849 108.572 292.859 108.465C292.848 108.358 292.861 108.25 292.897 108.148C292.932 108.046 292.99 107.954 293.066 107.878C293.141 107.802 293.233 107.743 293.334 107.707C293.436 107.67 293.544 107.657 293.651 107.667C294.168 107.651 294.404 107.959 294.465 108.448Z" fill="black" />
            <path d="M382.56 98.869C382.05 98.8297 381.724 98.5827 381.707 98.083C381.715 97.8808 381.8 97.6894 381.943 97.5462C382.087 97.4036 382.279 97.3205 382.481 97.3132C382.683 97.3065 382.88 97.3761 383.034 97.508C383.187 97.6405 383.285 97.8258 383.307 98.0268C383.324 98.5546 383.01 98.7792 382.56 98.869Z" fill="black" />
            <path d="M394.817 113.849C394.923 113.845 395.029 113.864 395.127 113.905C395.225 113.946 395.312 114.008 395.384 114.087C395.455 114.166 395.508 114.259 395.539 114.361C395.57 114.462 395.579 114.569 395.564 114.674C395.57 114.783 395.552 114.891 395.511 114.992C395.47 115.093 395.408 115.183 395.329 115.257C395.25 115.332 395.156 115.388 395.053 115.422C394.95 115.456 394.841 115.467 394.733 115.455C394.627 115.457 394.522 115.436 394.426 115.393C394.329 115.351 394.242 115.288 394.172 115.209C394.102 115.13 394.049 115.037 394.018 114.936C393.987 114.835 393.978 114.729 393.992 114.624C393.989 114.517 394.009 114.411 394.05 114.312C394.091 114.214 394.153 114.125 394.23 114.051C394.308 113.978 394.401 113.923 394.502 113.888C394.603 113.852 394.71 113.84 394.817 113.849Z" fill="black" />
            <path d="M355.992 106.393C355.998 106.501 355.982 106.609 355.945 106.711C355.908 106.812 355.851 106.905 355.777 106.984C355.703 107.063 355.614 107.125 355.514 107.169C355.415 107.212 355.309 107.234 355.2 107.235C355.089 107.238 354.977 107.219 354.873 107.177C354.769 107.134 354.675 107.071 354.597 106.991C354.519 106.911 354.459 106.815 354.419 106.711C354.38 106.606 354.363 106.494 354.369 106.382C354.386 106.178 354.479 105.988 354.629 105.85C354.78 105.712 354.976 105.635 355.181 105.635C355.385 105.635 355.582 105.712 355.732 105.85C355.883 105.988 355.975 106.178 355.992 106.382V106.393Z" fill="black" />
            <path d="M249.839 128.273C249.995 128.295 250.141 128.362 250.259 128.466C250.377 128.57 250.462 128.706 250.503 128.858C250.545 129.01 250.54 129.171 250.491 129.32C250.442 129.469 250.35 129.601 250.227 129.699C250.103 129.796 249.954 129.856 249.797 129.868C249.64 129.882 249.483 129.849 249.344 129.774C249.206 129.698 249.093 129.584 249.019 129.445C248.946 129.306 248.914 129.149 248.93 128.992C248.98 128.481 249.306 128.256 249.839 128.273Z" fill="black" />
            <path d="M351.758 98.2626C352.247 98.33 352.572 98.5771 352.555 99.088C352.562 99.1964 352.546 99.3047 352.507 99.4058C352.468 99.5074 352.407 99.5984 352.329 99.6742C352.251 99.7494 352.157 99.8067 352.055 99.8426C351.952 99.878 351.843 99.8909 351.736 99.8797C351.628 99.8909 351.519 99.878 351.417 99.8409C351.316 99.8039 351.224 99.7438 351.148 99.6658C351.073 99.5877 351.016 99.4939 350.983 99.3912C350.95 99.2879 350.94 99.1784 350.955 99.0712C350.961 98.5434 351.281 98.3412 351.758 98.2626Z" fill="black" />
            <path d="M280.26 97.2183C280.221 97.7293 279.962 98.0437 279.463 98.0549C279.262 98.0381 279.075 97.9466 278.939 97.7983C278.802 97.6501 278.727 97.4564 278.727 97.2548C278.727 97.0538 278.802 96.8595 278.939 96.7113C279.075 96.5631 279.262 96.4716 279.463 96.4547C279.567 96.448 279.67 96.4626 279.768 96.498C279.866 96.5333 279.955 96.5889 280.03 96.6608C280.105 96.7327 280.164 96.8191 280.204 96.9151C280.244 97.0111 280.263 97.1145 280.26 97.2183Z" fill="black" />
            <path d="M372.128 112.277C372.13 112.384 372.11 112.49 372.068 112.588C372.026 112.686 371.964 112.775 371.885 112.846C371.806 112.919 371.713 112.973 371.612 113.007C371.511 113.041 371.403 113.052 371.297 113.041C371.189 113.049 371.082 113.032 370.981 112.992C370.881 112.954 370.79 112.892 370.716 112.814C370.642 112.736 370.586 112.642 370.553 112.539C370.519 112.437 370.509 112.328 370.522 112.221C370.513 112.113 370.529 112.005 370.568 111.904C370.607 111.804 370.668 111.713 370.747 111.639C370.826 111.565 370.92 111.51 371.023 111.478C371.126 111.445 371.235 111.437 371.342 111.452C371.45 111.447 371.557 111.466 371.657 111.507C371.757 111.547 371.847 111.608 371.922 111.686C371.996 111.764 372.053 111.858 372.089 111.96C372.124 112.061 372.138 112.17 372.128 112.277Z" fill="black" />
            <path d="M378.99 126.847C378.928 127.375 378.675 127.701 378.175 127.706C378.068 127.718 377.96 127.705 377.858 127.669C377.756 127.634 377.663 127.576 377.587 127.5C377.51 127.424 377.451 127.333 377.414 127.231C377.377 127.13 377.362 127.022 377.372 126.915C377.358 126.808 377.368 126.701 377.402 126.599C377.435 126.497 377.491 126.405 377.566 126.328C377.641 126.252 377.732 126.193 377.832 126.156C377.933 126.12 378.041 126.106 378.147 126.117C378.251 126.11 378.354 126.122 378.453 126.155C378.551 126.188 378.642 126.24 378.72 126.307C378.798 126.375 378.863 126.458 378.909 126.55C378.955 126.643 378.982 126.744 378.99 126.847Z" fill="black" />
            <path d="M293.163 125.724C293.663 125.724 293.983 125.994 293.994 126.51C294.006 126.618 293.995 126.726 293.961 126.828C293.927 126.93 293.871 127.024 293.798 127.103C293.724 127.181 293.634 127.243 293.534 127.283C293.434 127.323 293.326 127.341 293.219 127.336C293.112 127.351 293.002 127.342 292.899 127.31C292.796 127.277 292.701 127.222 292.622 127.149C292.543 127.075 292.481 126.984 292.442 126.884C292.402 126.783 292.386 126.674 292.394 126.567C292.384 126.46 292.397 126.352 292.431 126.25C292.466 126.149 292.521 126.056 292.593 125.976C292.665 125.897 292.754 125.834 292.851 125.791C292.95 125.747 293.056 125.725 293.163 125.724Z" fill="black" />
            <path d="M309.71 115.084C309.552 115.075 309.401 115.018 309.275 114.923C309.149 114.827 309.055 114.696 309.002 114.547C308.951 114.398 308.944 114.237 308.983 114.084C309.023 113.931 309.106 113.793 309.223 113.687C309.34 113.581 309.486 113.512 309.643 113.489C309.799 113.466 309.959 113.489 310.102 113.557C310.245 113.623 310.365 113.731 310.447 113.867C310.529 114.001 310.569 114.157 310.563 114.315C310.565 114.425 310.545 114.533 310.501 114.633C310.458 114.733 310.394 114.823 310.313 114.896C310.232 114.969 310.136 115.024 310.032 115.056C309.928 115.089 309.818 115.098 309.71 115.084Z" fill="black" />
            <path d="M320.625 82.0586C320.659 81.5364 320.883 81.2332 321.411 81.2276C321.518 81.2124 321.627 81.2214 321.729 81.2534C321.832 81.2854 321.926 81.3399 322.006 81.4129C322.085 81.4864 322.147 81.5762 322.186 81.6762C322.226 81.7761 322.243 81.8839 322.236 81.9912C322.248 82.099 322.236 82.2073 322.202 82.3095C322.167 82.4117 322.111 82.5055 322.037 82.5841C321.962 82.6627 321.872 82.7239 321.772 82.7643C321.672 82.8042 321.564 82.8222 321.456 82.8165C321.351 82.8244 321.245 82.8104 321.146 82.7761C321.047 82.7419 320.955 82.6874 320.878 82.6167C320.8 82.5453 320.737 82.4594 320.694 82.3634C320.65 82.2674 320.627 82.1641 320.625 82.0586Z" fill="black" />
            <path d="M387.9 99.2059C388.355 99.2565 388.686 99.4698 388.692 99.992C388.707 100.099 388.698 100.207 388.664 100.309C388.631 100.412 388.575 100.505 388.501 100.583C388.426 100.66 388.334 100.72 388.233 100.756C388.132 100.793 388.024 100.806 387.917 100.795C387.81 100.807 387.702 100.796 387.599 100.762C387.497 100.729 387.403 100.673 387.325 100.599C387.246 100.525 387.184 100.435 387.144 100.335C387.104 100.235 387.086 100.128 387.092 100.02C387.075 99.5091 387.373 99.2509 387.9 99.2059Z" fill="black" />
            <path d="M377.501 114.197C377.504 114.306 377.484 114.414 377.443 114.515C377.402 114.615 377.341 114.706 377.264 114.782C377.187 114.859 377.095 114.918 376.994 114.958C376.893 114.997 376.784 115.015 376.676 115.011C376.568 115.02 376.459 115.005 376.357 114.965C376.255 114.926 376.164 114.865 376.09 114.785C376.016 114.706 375.959 114.611 375.927 114.507C375.894 114.403 375.885 114.294 375.901 114.186C375.892 114.079 375.906 113.97 375.943 113.869C375.979 113.767 376.038 113.675 376.113 113.598C376.189 113.521 376.281 113.462 376.382 113.424C376.483 113.386 376.591 113.37 376.698 113.378C376.806 113.376 376.912 113.396 377.011 113.437C377.111 113.478 377.2 113.539 377.276 113.615C377.351 113.692 377.409 113.783 377.448 113.883C377.487 113.983 377.505 114.09 377.501 114.197Z" fill="black" />
            <path d="M357.822 113.664C357.825 113.557 357.849 113.452 357.895 113.355C357.941 113.258 358.008 113.172 358.089 113.103C358.171 113.034 358.267 112.983 358.37 112.954C358.473 112.925 358.581 112.918 358.687 112.934C358.795 112.933 358.902 112.955 359.001 112.999C359.099 113.043 359.187 113.109 359.257 113.191C359.328 113.273 359.38 113.369 359.41 113.473C359.439 113.577 359.445 113.686 359.428 113.793C359.428 113.902 359.404 114.009 359.358 114.107C359.313 114.206 359.246 114.293 359.164 114.364C359.081 114.434 358.984 114.486 358.88 114.516C358.775 114.545 358.665 114.551 358.558 114.534C358.058 114.5 357.822 114.175 357.822 113.664Z" fill="black" />
            <path d="M314.56 68.207C314.453 68.2092 314.347 68.1885 314.248 68.1469C314.15 68.1048 314.061 68.0425 313.989 67.9644C313.916 67.8858 313.861 67.7926 313.827 67.6916C313.793 67.5899 313.78 67.4827 313.791 67.376C313.798 67.1649 313.889 66.965 314.044 66.8207C314.199 66.6764 314.405 66.5995 314.616 66.6068C314.827 66.6147 315.027 66.7056 315.172 66.8606C315.316 67.015 315.393 67.221 315.385 67.4322C315.389 67.5394 315.37 67.6461 315.329 67.7455C315.288 67.8443 315.227 67.9336 315.148 68.0071C315.07 68.0801 314.977 68.1362 314.876 68.1705C314.774 68.2053 314.667 68.2177 314.56 68.207Z" fill="black" />
            <path d="M356.761 82.9288C356.845 82.4628 357.081 82.1596 357.609 82.1877C357.716 82.1793 357.823 82.1944 357.923 82.2332C358.023 82.2719 358.114 82.3325 358.187 82.4106C358.26 82.4892 358.315 82.583 358.347 82.6851C358.379 82.7873 358.388 82.8957 358.373 83.0018C358.379 83.1096 358.362 83.218 358.322 83.3185C358.282 83.419 358.22 83.5094 358.142 83.5829C358.063 83.6571 357.969 83.7126 357.866 83.7463C357.763 83.78 357.654 83.7901 357.547 83.7766C357.438 83.7772 357.331 83.7548 357.231 83.7115C357.132 83.6683 357.042 83.6048 356.968 83.5251C356.894 83.4454 356.838 83.3516 356.802 83.2489C356.767 83.1461 356.753 83.0372 356.761 82.9288Z" fill="black" />
            <path d="M278.329 102.665C278.25 103.153 277.981 103.473 277.486 103.451C277.379 103.457 277.27 103.439 277.17 103.398C277.07 103.357 276.98 103.296 276.906 103.216C276.833 103.137 276.778 103.042 276.745 102.939C276.713 102.836 276.703 102.727 276.717 102.62C276.708 102.513 276.724 102.406 276.762 102.306C276.801 102.206 276.862 102.116 276.94 102.043C277.019 101.97 277.112 101.916 277.215 101.885C277.317 101.853 277.425 101.845 277.531 101.862C278.048 101.873 278.272 102.187 278.329 102.665Z" fill="black" />
            <path d="M332.646 67.0616C332.752 67.0554 332.858 67.0712 332.958 67.1088C333.058 67.1458 333.148 67.2037 333.224 67.2783C333.3 67.353 333.359 67.4429 333.397 67.5422C333.436 67.6411 333.454 67.7472 333.449 67.8533C333.461 67.9605 333.451 68.0689 333.417 68.1711C333.382 68.2733 333.326 68.367 333.251 68.4445C333.176 68.5226 333.085 68.5832 332.985 68.6214C332.884 68.6601 332.776 68.6758 332.669 68.6674C332.561 68.6792 332.453 68.6674 332.351 68.6326C332.249 68.5984 332.155 68.5416 332.077 68.4675C331.999 68.3929 331.939 68.3025 331.899 68.2025C331.86 68.102 331.842 67.9948 331.849 67.887C331.841 67.7792 331.856 67.6702 331.893 67.5686C331.929 67.4664 331.988 67.3738 332.063 67.2957C332.138 67.2177 332.229 67.1571 332.33 67.1166C332.43 67.0762 332.538 67.0571 332.646 67.0616Z" fill="black" />
            <path d="M351.539 68.3138C351.55 68.421 351.537 68.5294 351.502 68.6315C351.468 68.7337 351.411 68.8275 351.337 68.9061C351.263 68.9847 351.173 69.047 351.074 69.088C350.974 69.129 350.867 69.1487 350.759 69.1447C350.648 69.1543 350.537 69.1402 350.432 69.1043C350.327 69.0684 350.23 69.0111 350.148 68.9359C350.067 68.8606 350.001 68.7691 349.957 68.6675C349.912 68.5659 349.889 68.4558 349.889 68.3446C349.889 68.2335 349.912 68.1234 349.957 68.0218C350.001 67.9202 350.067 67.8286 350.148 67.7534C350.23 67.6787 350.327 67.6209 350.432 67.585C350.537 67.549 350.648 67.535 350.759 67.5445C350.861 67.5417 350.963 67.5591 351.058 67.5968C351.154 67.6344 351.241 67.6905 351.313 67.7624C351.386 67.8343 351.444 67.9202 351.483 68.0151C351.521 68.1099 351.541 68.2116 351.539 68.3138Z" fill="black" />
            <path d="M323.871 161.664C323.872 161.771 323.852 161.878 323.811 161.977C323.769 162.076 323.708 162.166 323.63 162.24C323.553 162.314 323.461 162.372 323.36 162.409C323.26 162.446 323.153 162.462 323.045 162.456C322.834 162.456 322.631 162.372 322.482 162.222C322.332 162.073 322.248 161.87 322.248 161.658C322.248 161.447 322.332 161.244 322.482 161.095C322.631 160.945 322.834 160.861 323.045 160.861C323.568 160.861 323.809 161.181 323.871 161.664Z" fill="black" />
            <path d="M302.714 68.9201C302.713 69.0262 302.69 69.1312 302.646 69.2278C302.602 69.3244 302.538 69.4103 302.459 69.481C302.379 69.5512 302.286 69.604 302.185 69.636C302.084 69.6686 301.977 69.6787 301.871 69.6669C301.764 69.6753 301.656 69.6596 301.556 69.6214C301.455 69.5827 301.364 69.522 301.29 69.444C301.216 69.3665 301.16 69.2727 301.127 69.1705C301.093 69.0683 301.083 68.9594 301.097 68.8527C301.086 68.7455 301.102 68.6377 301.14 68.5372C301.178 68.4361 301.239 68.3457 301.317 68.2722C301.396 68.1986 301.49 68.1436 301.593 68.1122C301.696 68.0802 301.805 68.0723 301.911 68.0891C302.019 68.0875 302.126 68.1082 302.227 68.1498C302.327 68.1913 302.417 68.2531 302.492 68.3306C302.568 68.4086 302.626 68.5013 302.664 68.6023C302.702 68.704 302.719 68.8123 302.714 68.9201Z" fill="black" />
            <path d="M362.454 103.776C362.349 103.775 362.246 103.751 362.15 103.707C362.055 103.663 361.969 103.6 361.9 103.521C361.83 103.443 361.777 103.35 361.745 103.25C361.713 103.15 361.702 103.045 361.713 102.94C361.705 102.832 361.722 102.724 361.762 102.622C361.801 102.521 361.863 102.43 361.942 102.356C362.02 102.282 362.115 102.227 362.219 102.194C362.322 102.16 362.431 102.151 362.539 102.165C362.647 102.162 362.755 102.181 362.855 102.222C362.955 102.264 363.045 102.326 363.119 102.406C363.193 102.485 363.249 102.579 363.284 102.682C363.318 102.785 363.33 102.894 363.319 103.001C363.302 103.496 362.993 103.765 362.454 103.776Z" fill="black" />
            <path d="M257.858 125.629C257.863 125.736 257.845 125.842 257.806 125.941C257.768 126.04 257.708 126.131 257.633 126.205C257.557 126.281 257.467 126.339 257.367 126.377C257.268 126.415 257.161 126.432 257.055 126.426C256.947 126.438 256.838 126.427 256.736 126.391C256.633 126.356 256.54 126.298 256.463 126.222C256.385 126.147 256.326 126.054 256.288 125.953C256.251 125.851 256.236 125.743 256.246 125.634C256.236 125.527 256.25 125.418 256.286 125.316C256.322 125.214 256.381 125.122 256.456 125.044C256.533 124.967 256.624 124.908 256.726 124.87C256.828 124.833 256.936 124.818 257.044 124.826C257.152 124.816 257.262 124.83 257.364 124.866C257.467 124.903 257.56 124.961 257.638 125.038C257.715 125.115 257.775 125.207 257.813 125.309C257.851 125.411 257.866 125.52 257.858 125.629Z" fill="black" />
            <path d="M337.974 68.9875C338.536 69.038 338.833 69.2682 338.833 69.7848C338.846 69.8926 338.835 70.0021 338.8 70.1048C338.766 70.2081 338.709 70.3019 338.634 70.3805C338.559 70.4585 338.467 70.5192 338.366 70.5585C338.264 70.5972 338.156 70.6129 338.047 70.6045C337.94 70.6186 337.831 70.6079 337.728 70.5742C337.626 70.5405 337.532 70.4838 337.454 70.4091C337.376 70.3345 337.315 70.2429 337.277 70.1419C337.239 70.0402 337.224 69.9319 337.233 69.8241C337.226 69.7202 337.24 69.6163 337.273 69.5181C337.307 69.4198 337.36 69.3289 337.429 69.2514C337.498 69.1733 337.582 69.1099 337.675 69.065C337.769 69.0195 337.87 68.9931 337.974 68.9875Z" fill="black" />
            <path d="M391.673 125.387C391.673 125.949 391.359 126.224 390.87 126.218C390.762 126.226 390.652 126.211 390.551 126.172C390.449 126.134 390.356 126.074 390.28 125.997C390.203 125.919 390.144 125.826 390.106 125.724C390.068 125.622 390.053 125.513 390.062 125.404C390.052 125.296 390.066 125.188 390.104 125.086C390.141 124.984 390.2 124.892 390.278 124.816C390.355 124.741 390.449 124.683 390.551 124.648C390.654 124.613 390.762 124.601 390.87 124.613C390.976 124.604 391.081 124.618 391.181 124.653C391.281 124.688 391.372 124.745 391.447 124.818C391.524 124.892 391.583 124.98 391.622 125.079C391.661 125.177 391.678 125.282 391.673 125.387Z" fill="black" />
            <path d="M374.195 71.5422C373.684 71.4916 373.381 71.2221 373.381 70.728C373.37 70.6208 373.382 70.5119 373.418 70.4097C373.455 70.3075 373.512 70.2143 373.589 70.1374C373.665 70.0604 373.758 70.0015 373.86 69.965C373.962 69.9285 374.07 69.915 374.178 69.9251C374.286 69.9122 374.394 69.9234 374.497 69.9577C374.599 69.9925 374.692 70.0498 374.769 70.125C374.846 70.2008 374.905 70.2923 374.942 70.394C374.979 70.4956 374.992 70.6039 374.981 70.7112C374.986 70.8179 374.969 70.9245 374.931 71.0245C374.894 71.1244 374.837 71.2154 374.764 71.2929C374.69 71.3704 374.602 71.4327 374.504 71.4754C374.407 71.518 374.302 71.541 374.195 71.5422Z" fill="black" />
            <path d="M363.774 84.8659C363.774 85.4274 363.499 85.7081 362.988 85.7025C362.878 85.7143 362.767 85.7031 362.662 85.6694C362.557 85.6363 362.46 85.5812 362.378 85.5088C362.295 85.4364 362.228 85.3471 362.182 85.2477C362.135 85.1478 362.109 85.0394 362.107 84.9294C362.104 84.8193 362.124 84.7098 362.166 84.6082C362.208 84.506 362.27 84.4139 362.349 84.3376C362.428 84.2607 362.522 84.2011 362.625 84.163C362.728 84.1242 362.838 84.1074 362.948 84.1136C363.053 84.1046 363.158 84.1169 363.258 84.1501C363.357 84.1837 363.449 84.2376 363.526 84.3084C363.604 84.3791 363.666 84.465 363.709 84.5611C363.751 84.6571 363.773 84.7609 363.774 84.8659Z" fill="black" />
            <path d="M354.061 111.783C353.993 112.316 353.707 112.608 353.218 112.597C353.113 112.595 353.01 112.571 352.915 112.529C352.819 112.486 352.732 112.426 352.66 112.35C352.588 112.274 352.531 112.184 352.493 112.087C352.456 111.989 352.438 111.885 352.441 111.78C352.443 111.676 352.466 111.572 352.509 111.476C352.551 111.381 352.612 111.294 352.688 111.222C352.764 111.15 352.853 111.093 352.951 111.056C353.049 111.018 353.153 111 353.258 111.003C353.362 111.001 353.465 111.02 353.561 111.059C353.658 111.097 353.746 111.154 353.821 111.227C353.895 111.299 353.954 111.385 353.996 111.481C354.037 111.576 354.059 111.679 354.061 111.783Z" fill="black" />
            <path d="M399.186 85.0175C399.286 85.018 399.386 85.0394 399.477 85.0798C399.569 85.1202 399.651 85.1792 399.72 85.2527C399.788 85.3263 399.839 85.4133 399.872 85.5082C399.905 85.6031 399.918 85.7036 399.911 85.8035C399.924 85.9097 399.913 86.0175 399.881 86.1197C399.848 86.2218 399.794 86.3156 399.723 86.3948C399.651 86.4745 399.563 86.5374 399.464 86.5795C399.366 86.6216 399.259 86.6424 399.153 86.6401C399.041 86.648 398.93 86.6323 398.825 86.5947C398.72 86.5565 398.624 86.497 398.543 86.42C398.463 86.3426 398.4 86.2494 398.358 86.146C398.315 86.0427 398.296 85.9321 398.299 85.8204C398.3 85.7081 398.324 85.5969 398.371 85.4947C398.417 85.392 398.483 85.2999 398.567 85.2247C398.65 85.1494 398.748 85.0922 398.855 85.0562C398.961 85.0209 399.074 85.0074 399.186 85.0175Z" fill="black" />
            <path d="M360.45 109.15C360.294 109.128 360.148 109.061 360.03 108.957C359.912 108.853 359.827 108.717 359.786 108.565C359.745 108.413 359.749 108.252 359.798 108.103C359.847 107.954 359.939 107.822 360.063 107.725C360.187 107.627 360.336 107.568 360.493 107.554C360.649 107.541 360.806 107.574 360.945 107.649C361.083 107.725 361.196 107.839 361.27 107.978C361.344 108.117 361.375 108.274 361.36 108.431C361.309 108.942 360.984 109.166 360.45 109.15Z" fill="black" />
            <path d="M338.407 87.8923C338.328 88.3751 338.081 88.684 337.565 88.6615C337.353 88.6531 337.154 88.5616 337.01 88.406C336.866 88.2511 336.79 88.045 336.798 87.8333C336.806 87.6217 336.898 87.4223 337.054 87.2786C337.209 87.1349 337.415 87.0585 337.627 87.0669C338.121 87.0781 338.362 87.4038 338.407 87.8923Z" fill="black" />
            <path d="M283.242 122.681C283.232 122.896 283.138 123.098 282.979 123.243C282.821 123.387 282.611 123.463 282.397 123.453C282.182 123.444 281.98 123.349 281.835 123.191C281.691 123.032 281.615 122.823 281.625 122.608C281.62 122.5 281.638 122.392 281.68 122.291C281.721 122.191 281.784 122.101 281.864 122.027C281.943 121.953 282.038 121.898 282.141 121.864C282.244 121.831 282.354 121.82 282.462 121.833C282.569 121.836 282.675 121.86 282.773 121.903C282.871 121.948 282.96 122.011 283.033 122.09C283.106 122.17 283.162 122.263 283.198 122.364C283.234 122.466 283.249 122.574 283.242 122.681Z" fill="black" />
            <path d="M356.699 119.93C356.591 119.939 356.482 119.925 356.38 119.888C356.277 119.852 356.183 119.795 356.105 119.72C356.025 119.646 355.963 119.555 355.922 119.455C355.88 119.355 355.86 119.247 355.863 119.138C355.858 118.927 355.936 118.722 356.082 118.569C356.228 118.416 356.428 118.327 356.64 118.321C356.851 118.316 357.056 118.395 357.21 118.541C357.363 118.687 357.452 118.888 357.457 119.099C357.468 119.205 357.457 119.312 357.423 119.413C357.39 119.513 357.336 119.606 357.265 119.685C357.193 119.763 357.106 119.826 357.008 119.868C356.911 119.91 356.805 119.932 356.699 119.93Z" fill="black" />
            <path d="M275.769 138.767C275.825 138.301 276.032 137.976 276.566 137.97C276.672 137.954 276.78 137.962 276.883 137.993C276.986 138.025 277.08 138.078 277.159 138.151C277.237 138.224 277.299 138.314 277.338 138.414C277.377 138.514 277.393 138.621 277.386 138.728C277.399 138.835 277.388 138.944 277.355 139.046C277.322 139.149 277.267 139.243 277.194 139.322C277.121 139.401 277.031 139.464 276.931 139.505C276.831 139.546 276.724 139.564 276.616 139.559C276.508 139.566 276.399 139.551 276.297 139.514C276.195 139.478 276.101 139.42 276.022 139.346C275.942 139.272 275.879 139.182 275.835 139.083C275.792 138.984 275.769 138.876 275.769 138.767Z" fill="black" />
            <path d="M343.46 137.852C343.96 137.891 344.28 138.138 344.285 138.638C344.298 138.747 344.287 138.858 344.254 138.963C344.222 139.068 344.169 139.165 344.097 139.248C344.025 139.331 343.937 139.398 343.838 139.446C343.738 139.493 343.631 139.52 343.521 139.524C343.411 139.528 343.302 139.509 343.2 139.469C343.098 139.428 343.005 139.367 342.928 139.289C342.85 139.211 342.79 139.118 342.75 139.016C342.71 138.913 342.692 138.804 342.696 138.694C342.687 138.588 342.7 138.481 342.734 138.38C342.768 138.279 342.823 138.186 342.894 138.106C342.966 138.027 343.053 137.964 343.151 137.92C343.248 137.876 343.353 137.853 343.46 137.852Z" fill="black" />
            <path d="M275.235 89.9641C275.223 89.8568 275.235 89.7479 275.269 89.6452C275.303 89.5424 275.359 89.4481 275.432 89.3684C275.505 89.2886 275.595 89.2257 275.694 89.1831C275.794 89.141 275.901 89.1202 276.009 89.1219C276.225 89.1174 276.434 89.1994 276.59 89.3487C276.746 89.4981 276.836 89.7036 276.84 89.9192C276.845 90.1353 276.763 90.3442 276.614 90.4997C276.464 90.6558 276.259 90.7457 276.043 90.7501C275.936 90.7625 275.828 90.7507 275.725 90.7153C275.623 90.6805 275.53 90.6233 275.453 90.5486C275.376 90.4733 275.316 90.3818 275.278 90.2808C275.24 90.1797 275.226 90.0719 275.235 89.9641Z" fill="black" />
            <path d="M247.083 121.8C247.085 121.905 247.065 122.01 247.023 122.107C246.981 122.203 246.919 122.29 246.841 122.36C246.762 122.431 246.669 122.483 246.568 122.514C246.468 122.546 246.362 122.555 246.257 122.541C246.149 122.545 246.041 122.525 245.941 122.483C245.841 122.442 245.75 122.38 245.676 122.3C245.603 122.221 245.546 122.127 245.512 122.024C245.477 121.921 245.466 121.812 245.477 121.704C245.471 121.596 245.489 121.487 245.53 121.387C245.57 121.286 245.632 121.195 245.711 121.121C245.79 121.047 245.884 120.991 245.988 120.957C246.09 120.923 246.2 120.911 246.308 120.924C246.418 120.923 246.528 120.946 246.629 120.991C246.731 121.036 246.821 121.102 246.895 121.185C246.968 121.268 247.023 121.366 247.055 121.472C247.088 121.578 247.097 121.69 247.083 121.8Z" fill="black" />
            <path d="M300.744 74.1979C300.704 74.7201 300.457 75.0345 299.952 75.0401C299.844 75.0519 299.735 75.0401 299.633 75.0053C299.53 74.9705 299.437 74.9132 299.359 74.8386C299.281 74.7639 299.22 74.6724 299.181 74.5713C299.142 74.4708 299.125 74.3624 299.132 74.2541C299.121 74.1468 299.132 74.0385 299.167 73.9363C299.202 73.8335 299.259 73.7403 299.334 73.6628C299.409 73.5848 299.501 73.5247 299.602 73.4865C299.702 73.4478 299.811 73.4321 299.918 73.4399C300.023 73.4315 300.129 73.445 300.228 73.4792C300.328 73.5135 300.419 73.568 300.497 73.6387C300.574 73.71 300.636 73.7965 300.679 73.8925C300.721 73.9891 300.743 74.0929 300.744 74.1979Z" fill="black" />
            <path d="M294.106 89.5879C294.639 89.6384 294.926 89.9023 294.903 90.4189C294.908 90.5295 294.89 90.6401 294.85 90.7434C294.811 90.8467 294.75 90.9405 294.672 91.0197C294.595 91.0983 294.502 91.16 294.399 91.2016C294.296 91.2426 294.186 91.2616 294.075 91.2583C293.965 91.2549 293.856 91.2285 293.756 91.1814C293.656 91.1336 293.567 91.0657 293.494 90.9826C293.422 90.8989 293.367 90.8012 293.334 90.6957C293.301 90.5901 293.29 90.4784 293.303 90.3683C293.298 90.2628 293.316 90.1578 293.356 90.0595C293.395 89.9618 293.454 89.8726 293.53 89.799C293.605 89.7255 293.696 89.6687 293.795 89.6323C293.895 89.5958 294 89.5812 294.106 89.5879Z" fill="black" />
            <path d="M380.921 121.502C380.928 121.609 380.913 121.715 380.876 121.815C380.841 121.916 380.784 122.007 380.711 122.085C380.637 122.162 380.549 122.224 380.451 122.266C380.353 122.308 380.247 122.328 380.14 122.327C380.033 122.341 379.924 122.33 379.821 122.297C379.718 122.263 379.623 122.208 379.544 122.134C379.465 122.06 379.403 121.97 379.362 121.869C379.322 121.769 379.304 121.661 379.309 121.553C379.295 121.445 379.304 121.336 379.337 121.232C379.371 121.129 379.426 121.035 379.501 120.956C379.575 120.877 379.666 120.815 379.766 120.775C379.867 120.736 379.976 120.72 380.084 120.727C380.192 120.714 380.302 120.724 380.405 120.758C380.508 120.791 380.602 120.847 380.682 120.921C380.762 120.994 380.824 121.085 380.866 121.185C380.907 121.285 380.926 121.394 380.921 121.502Z" fill="black" />
            <path d="M343.976 121.407C343.465 121.339 343.156 121.087 343.162 120.576C343.151 120.468 343.165 120.359 343.203 120.258C343.241 120.157 343.301 120.065 343.38 119.991C343.458 119.917 343.553 119.861 343.657 119.829C343.76 119.797 343.869 119.79 343.976 119.806C344.083 119.797 344.191 119.813 344.291 119.85C344.392 119.887 344.482 119.947 344.558 120.023C344.632 120.1 344.69 120.192 344.725 120.294C344.76 120.395 344.773 120.503 344.762 120.609C344.762 121.131 344.442 121.362 343.976 121.407Z" fill="black" />
            <path d="M288.043 75.7589C288.045 75.865 288.026 75.9711 287.985 76.0694C287.944 76.1676 287.883 76.2558 287.806 76.3293C287.728 76.4023 287.636 76.4585 287.536 76.4933C287.436 76.5286 287.329 76.5427 287.223 76.5337C287.115 76.5444 287.006 76.5309 286.903 76.4938C286.801 76.4568 286.709 76.3973 286.633 76.3198C286.556 76.2423 286.499 76.1485 286.464 76.0452C286.43 75.9425 286.418 75.833 286.431 75.7252C286.423 75.6174 286.439 75.509 286.477 75.4079C286.516 75.3063 286.576 75.2148 286.654 75.1396C286.731 75.0638 286.824 75.0065 286.926 74.9711C287.029 74.9352 287.138 74.9223 287.245 74.9335C287.354 74.9273 287.463 74.9447 287.564 74.9846C287.665 75.0245 287.757 75.0851 287.833 75.1637C287.908 75.2417 287.966 75.3355 288.003 75.4383C288.039 75.541 288.052 75.6505 288.043 75.7589Z" fill="black" />
            <path d="M373.056 76.107C372.983 76.6011 372.719 76.9155 372.225 76.9043C372.117 76.9116 372.009 76.8953 371.908 76.856C371.808 76.8172 371.717 76.756 371.642 76.678C371.568 76.5994 371.511 76.5056 371.477 76.4034C371.443 76.3007 371.432 76.1923 371.444 76.0845C371.434 75.9784 371.448 75.8706 371.485 75.7701C371.522 75.6701 371.582 75.5792 371.659 75.5051C371.736 75.431 371.83 75.3754 371.931 75.3428C372.033 75.3097 372.141 75.3007 372.247 75.3153C372.769 75.3209 372.994 75.6297 373.056 76.107Z" fill="black" />
            <path d="M243.203 132.479C243.169 132.967 242.939 133.27 242.411 133.27C242.305 133.283 242.196 133.272 242.094 133.238C241.992 133.204 241.899 133.149 241.821 133.075C241.742 133.001 241.681 132.911 241.642 132.811C241.602 132.711 241.585 132.603 241.592 132.496C241.577 132.387 241.587 132.276 241.623 132.172C241.657 132.068 241.716 131.973 241.794 131.895C241.872 131.817 241.966 131.759 242.071 131.724C242.174 131.689 242.286 131.678 242.395 131.693C242.5 131.688 242.606 131.704 242.705 131.741C242.804 131.779 242.894 131.836 242.97 131.909C243.046 131.984 243.106 132.072 243.146 132.17C243.186 132.268 243.205 132.373 243.203 132.479Z" fill="black" />
            <path d="M335.139 110.52C335.639 110.559 335.97 110.8 335.982 111.306C335.973 111.508 335.889 111.7 335.745 111.842C335.602 111.985 335.409 112.068 335.207 112.075C335.005 112.083 334.808 112.013 334.654 111.881C334.502 111.749 334.404 111.563 334.381 111.362C334.371 111.256 334.383 111.149 334.417 111.048C334.45 110.947 334.504 110.854 334.575 110.774C334.646 110.695 334.733 110.632 334.831 110.588C334.928 110.544 335.033 110.521 335.139 110.52Z" fill="black" />
            <path d="M323.421 75.8824C323.525 75.8812 323.628 75.902 323.723 75.9424C323.818 75.9829 323.905 76.0429 323.976 76.1182C324.047 76.1934 324.102 76.2827 324.137 76.3804C324.172 76.4781 324.186 76.582 324.179 76.6853C324.191 76.7925 324.178 76.9014 324.142 77.0031C324.106 77.1047 324.048 77.1968 323.971 77.2731C323.895 77.3489 323.802 77.4068 323.7 77.4421C323.598 77.4775 323.489 77.4893 323.382 77.4769C323.275 77.4854 323.168 77.4702 323.066 77.4332C322.965 77.3967 322.874 77.3383 322.798 77.2625C322.722 77.1867 322.663 77.0957 322.625 76.9946C322.587 76.8941 322.571 76.7869 322.579 76.6797C322.574 76.1687 322.854 75.8824 323.421 75.8824Z" fill="black" />
            <path d="M290.586 119.189C290.589 119.297 290.572 119.404 290.534 119.505C290.497 119.605 290.439 119.698 290.366 119.777C290.292 119.856 290.204 119.919 290.106 119.964C290.008 120.008 289.902 120.033 289.794 120.037C289.683 120.042 289.571 120.025 289.468 119.984C289.364 119.943 289.269 119.882 289.191 119.802C289.113 119.722 289.053 119.627 289.014 119.522C288.976 119.417 288.961 119.306 288.969 119.194C288.959 119.088 288.973 118.98 289.01 118.88C289.046 118.779 289.105 118.688 289.182 118.613C289.258 118.537 289.35 118.48 289.451 118.445C289.552 118.409 289.66 118.397 289.766 118.408C289.872 118.404 289.977 118.421 290.076 118.458C290.175 118.495 290.265 118.551 290.342 118.624C290.418 118.697 290.479 118.784 290.521 118.882C290.563 118.979 290.585 119.083 290.586 119.189Z" fill="black" />
            <path d="M340.871 131.277C340.871 131.839 340.573 132.142 340.085 132.148C339.977 132.157 339.868 132.142 339.766 132.106C339.664 132.069 339.571 132.011 339.494 131.936C339.416 131.86 339.355 131.769 339.316 131.668C339.276 131.567 339.259 131.459 339.265 131.35C339.253 131.243 339.265 131.135 339.3 131.033C339.335 130.931 339.392 130.838 339.467 130.761C339.543 130.683 339.634 130.624 339.735 130.586C339.836 130.548 339.944 130.533 340.051 130.542C340.155 130.531 340.259 130.542 340.358 130.574C340.457 130.606 340.548 130.658 340.625 130.727C340.702 130.797 340.764 130.881 340.806 130.976C340.849 131.071 340.871 131.173 340.871 131.277Z" fill="black" />
            <path d="M253.585 117.487C253.801 117.487 254.007 117.573 254.16 117.726C254.313 117.879 254.399 118.086 254.399 118.302C254.399 118.518 254.313 118.724 254.16 118.877C254.007 119.03 253.801 119.116 253.585 119.116C253.368 119.116 253.162 119.03 253.009 118.877C252.856 118.724 252.771 118.518 252.771 118.302C252.771 118.086 252.856 117.879 253.009 117.726C253.162 117.573 253.368 117.487 253.585 117.487Z" fill="black" />
            <path d="M321.327 118.139C321.299 118.644 321.058 118.959 320.547 118.97C320.44 118.983 320.332 118.973 320.229 118.939C320.127 118.906 320.033 118.851 319.954 118.778C319.876 118.705 319.814 118.615 319.774 118.515C319.733 118.416 319.716 118.308 319.721 118.201C319.706 118.094 319.715 117.985 319.747 117.882C319.779 117.778 319.835 117.684 319.908 117.605C319.982 117.527 320.073 117.466 320.174 117.427C320.274 117.388 320.383 117.372 320.491 117.381C320.597 117.368 320.705 117.378 320.807 117.411C320.909 117.443 321.003 117.497 321.082 117.569C321.162 117.641 321.224 117.729 321.267 117.827C321.309 117.925 321.329 118.032 321.327 118.139Z" fill="black" />
            <path d="M303.27 117.785C303.258 117.998 303.162 118.198 303.003 118.34C302.844 118.482 302.635 118.555 302.422 118.543C302.209 118.531 302.01 118.435 301.868 118.276C301.726 118.117 301.653 117.909 301.664 117.695C301.659 117.587 301.678 117.48 301.719 117.38C301.76 117.28 301.823 117.19 301.903 117.117C301.983 117.044 302.078 116.989 302.181 116.957C302.285 116.925 302.394 116.917 302.501 116.932C302.61 116.929 302.718 116.95 302.818 116.993C302.919 117.037 303.008 117.101 303.081 117.182C303.154 117.263 303.209 117.359 303.242 117.463C303.274 117.567 303.284 117.677 303.27 117.785Z" fill="black" />
            <path d="M284.325 118.066C284.219 118.067 284.115 118.045 284.019 118.002C283.922 117.96 283.836 117.897 283.766 117.818C283.696 117.74 283.643 117.647 283.612 117.546C283.58 117.446 283.571 117.34 283.584 117.235C283.576 117.128 283.592 117.02 283.631 116.919C283.671 116.819 283.732 116.729 283.811 116.655C283.889 116.582 283.983 116.526 284.086 116.494C284.188 116.461 284.297 116.451 284.403 116.466C284.511 116.46 284.619 116.478 284.718 116.518C284.818 116.558 284.909 116.62 284.982 116.699C285.056 116.777 285.112 116.871 285.146 116.973C285.179 117.075 285.191 117.184 285.178 117.291C285.179 117.4 285.158 117.508 285.114 117.608C285.071 117.708 285.007 117.797 284.926 117.871C284.845 117.944 284.75 118 284.647 118.033C284.543 118.067 284.433 118.078 284.325 118.066Z" fill="black" />
            <path d="M341.775 95.27C341.772 95.3717 341.748 95.4716 341.706 95.5637C341.663 95.6563 341.602 95.7389 341.527 95.8062C341.451 95.8742 341.362 95.9258 341.266 95.9584C341.17 95.9904 341.067 96.0028 340.966 95.9943C340.859 96.0028 340.751 95.987 340.65 95.9483C340.549 95.9101 340.458 95.8495 340.383 95.772C340.309 95.6939 340.253 95.6007 340.218 95.498C340.184 95.3958 340.173 95.2874 340.186 95.1802C340.179 95.0724 340.195 94.9646 340.234 94.8641C340.272 94.763 340.332 94.6715 340.408 94.5957C340.485 94.5199 340.577 94.4609 340.678 94.4233C340.779 94.3857 340.887 94.37 340.994 94.3773C341.494 94.411 341.775 94.6749 341.775 95.27Z" fill="black" />
            <path d="M346.778 78.2742C347.34 78.3023 347.615 78.5662 347.62 79.0659C347.622 79.1709 347.604 79.2748 347.565 79.3725C347.527 79.4696 347.469 79.5583 347.397 79.6341C347.324 79.7094 347.237 79.7694 347.141 79.8115C347.045 79.8531 346.942 79.8756 346.837 79.8772C346.732 79.8795 346.628 79.8604 346.531 79.8222C346.434 79.7835 346.345 79.7268 346.27 79.6538C346.194 79.5808 346.134 79.4943 346.092 79.3983C346.05 79.3023 346.028 79.199 346.026 79.094C346.018 78.9901 346.03 78.8851 346.064 78.7863C346.098 78.6875 346.152 78.5971 346.223 78.5196C346.294 78.4427 346.379 78.3815 346.475 78.3388C346.571 78.2967 346.674 78.2748 346.778 78.2742Z" fill="black" />
            <path d="M260.923 115.685C260.44 115.607 260.126 115.359 260.131 114.843C260.122 114.735 260.138 114.626 260.176 114.525C260.214 114.423 260.274 114.331 260.352 114.255C260.43 114.179 260.523 114.122 260.626 114.087C260.728 114.051 260.838 114.039 260.946 114.051C261.054 114.047 261.162 114.066 261.263 114.106C261.364 114.147 261.455 114.209 261.529 114.288C261.604 114.366 261.661 114.46 261.697 114.563C261.733 114.665 261.747 114.774 261.737 114.882C261.737 115.399 261.406 115.629 260.923 115.685Z" fill="black" />
            <path d="M334.477 98.6557C334.392 99.1161 334.168 99.4642 333.646 99.4361C333.443 99.4142 333.257 99.3165 333.123 99.1627C332.989 99.0089 332.919 98.8107 332.925 98.6068C332.931 98.4036 333.015 98.2099 333.157 98.065C333.3 97.9196 333.492 97.8343 333.696 97.8247C334.241 97.8247 334.443 98.1616 334.477 98.6557Z" fill="black" />
            <path d="M266.73 97.9426C267.235 97.9875 267.544 98.2346 267.549 98.7455C267.558 98.8567 267.545 98.9679 267.509 99.0729C267.473 99.1779 267.415 99.2744 267.34 99.3564C267.265 99.4384 267.174 99.5035 267.072 99.5479C266.97 99.5928 266.86 99.6158 266.749 99.6158C266.638 99.6158 266.528 99.5928 266.426 99.5479C266.325 99.5035 266.233 99.4384 266.158 99.3564C266.083 99.2744 266.026 99.1779 265.99 99.0729C265.954 98.9679 265.94 98.8567 265.949 98.7455C265.944 98.6405 265.96 98.5361 265.997 98.4373C266.034 98.339 266.091 98.2492 266.164 98.1739C266.237 98.0987 266.325 98.0392 266.423 97.9993C266.52 97.9595 266.625 97.9404 266.73 97.9426Z" fill="black" />
            <path d="M285.628 99.2452C285.627 99.3508 285.605 99.4547 285.562 99.5501C285.519 99.6461 285.457 99.732 285.378 99.8022C285.299 99.8719 285.207 99.9246 285.107 99.9561C285.007 99.9881 284.901 99.9987 284.797 99.9864C284.69 99.9948 284.583 99.9797 284.483 99.9415C284.383 99.9033 284.293 99.8432 284.22 99.7657C284.146 99.6883 284.091 99.595 284.057 99.4934C284.024 99.3918 284.014 99.284 284.028 99.1779C284.021 99.0706 284.038 98.9628 284.078 98.8629C284.117 98.7624 284.178 98.672 284.256 98.5979C284.334 98.5232 284.427 98.467 284.53 98.4322C284.632 98.398 284.74 98.3862 284.847 98.3974C284.956 98.3957 285.064 98.4165 285.165 98.4592C285.265 98.5019 285.354 98.5659 285.428 98.6456C285.502 98.7259 285.558 98.8208 285.592 98.9241C285.627 99.0274 285.639 99.1369 285.628 99.2452Z" fill="black" />
            <path d="M330.613 109.38C330.546 109.941 330.271 110.216 329.777 110.2C329.668 110.206 329.56 110.187 329.46 110.147C329.359 110.106 329.269 110.044 329.195 109.965C329.121 109.886 329.066 109.791 329.032 109.689C328.999 109.585 328.988 109.476 329.002 109.369C328.995 109.261 329.012 109.153 329.052 109.053C329.092 108.953 329.153 108.863 329.233 108.789C329.311 108.716 329.405 108.661 329.508 108.627C329.611 108.595 329.72 108.585 329.827 108.599C329.93 108.597 330.034 108.615 330.13 108.653C330.226 108.691 330.314 108.748 330.387 108.821C330.461 108.894 330.519 108.981 330.558 109.077C330.596 109.173 330.615 109.276 330.613 109.38Z" fill="black" />
            <path d="M376.148 66.1521C375.666 66.1015 375.357 65.8713 375.345 65.3548C375.331 65.2481 375.34 65.1392 375.374 65.0364C375.407 64.9337 375.462 64.8393 375.537 64.7613C375.611 64.6833 375.703 64.6226 375.803 64.5844C375.904 64.5463 376.013 64.5317 376.12 64.5406C376.228 64.5272 376.337 64.5378 376.44 64.5715C376.543 64.6052 376.637 64.6614 376.715 64.7366C376.794 64.8113 376.855 64.9022 376.893 65.0033C376.933 65.1049 376.949 65.2133 376.94 65.3211C376.945 65.4283 376.929 65.5356 376.891 65.6366C376.854 65.7371 376.796 65.8292 376.722 65.9067C376.649 65.9842 376.559 66.0465 376.461 66.0886C376.362 66.1307 376.256 66.1526 376.148 66.1521Z" fill="black" />
            <path d="M309.209 64.6529C309.315 64.6456 309.421 64.6619 309.52 64.7001C309.619 64.7383 309.709 64.7978 309.782 64.8747C309.855 64.951 309.911 65.0431 309.945 65.1436C309.979 65.2436 309.99 65.3503 309.979 65.4558C309.989 65.5631 309.975 65.6709 309.939 65.7719C309.903 65.8735 309.846 65.9656 309.77 66.042C309.695 66.1189 309.603 66.1779 309.503 66.2149C309.402 66.2525 309.294 66.2677 309.187 66.2587C309.079 66.2666 308.971 66.2509 308.87 66.2127C308.768 66.1745 308.677 66.115 308.6 66.0386C308.524 65.9617 308.465 65.8696 308.428 65.768C308.391 65.6664 308.376 65.558 308.384 65.4502C308.379 65.3418 308.397 65.2335 308.437 65.1324C308.477 65.0313 308.538 64.9404 308.616 64.8646C308.695 64.7893 308.788 64.7315 308.89 64.695C308.992 64.6585 309.101 64.6439 309.209 64.6529Z" fill="black" />
            <path d="M327.284 66.7247C327.174 66.7303 327.065 66.7135 326.963 66.6742C326.861 66.6349 326.769 66.5748 326.691 66.4973C326.614 66.4204 326.554 66.3278 326.514 66.2256C326.475 66.1234 326.458 66.0144 326.464 65.905C326.454 65.7972 326.468 65.6888 326.505 65.5872C326.542 65.4855 326.601 65.3929 326.678 65.3165C326.755 65.2402 326.847 65.1812 326.948 65.1442C327.05 65.1071 327.159 65.0931 327.267 65.1021C327.47 65.1195 327.66 65.2121 327.798 65.3626C327.937 65.5125 328.013 65.7096 328.013 65.9134C328.013 66.1178 327.937 66.3148 327.798 66.4647C327.66 66.6152 327.47 66.7079 327.267 66.7247H327.284Z" fill="black" />
            <path d="M309.546 163.175C309.533 163.067 309.545 162.957 309.579 162.854C309.614 162.751 309.67 162.656 309.746 162.578C309.821 162.499 309.912 162.438 310.013 162.398C310.115 162.359 310.223 162.342 310.332 162.349C310.438 162.34 310.546 162.353 310.647 162.387C310.748 162.422 310.842 162.477 310.92 162.55C310.999 162.622 311.062 162.71 311.104 162.808C311.147 162.906 311.169 163.012 311.169 163.118C311.174 163.23 311.157 163.341 311.117 163.446C311.078 163.55 311.018 163.646 310.941 163.726C310.864 163.806 310.771 163.87 310.668 163.913C310.565 163.957 310.455 163.979 310.344 163.977C310.237 163.981 310.132 163.962 310.033 163.923C309.935 163.884 309.845 163.824 309.771 163.749C309.696 163.674 309.637 163.584 309.599 163.485C309.56 163.386 309.542 163.281 309.546 163.175Z" fill="black" />
            <path d="M272.153 101.508C271.625 101.452 271.328 101.188 271.339 100.683C271.331 100.575 271.346 100.467 271.385 100.366C271.423 100.265 271.484 100.173 271.561 100.099C271.639 100.023 271.733 99.9667 271.835 99.9313C271.937 99.8965 272.045 99.8847 272.153 99.8965C272.261 99.8881 272.368 99.9038 272.469 99.9426C272.57 99.9813 272.661 100.041 272.736 100.119C272.81 100.197 272.867 100.291 272.901 100.393C272.935 100.495 272.946 100.603 272.934 100.711C272.938 100.815 272.921 100.92 272.884 101.017C272.847 101.115 272.789 101.204 272.716 101.279C272.643 101.354 272.555 101.412 272.459 101.452C272.362 101.491 272.258 101.51 272.153 101.508Z" fill="black" />
            <path d="M318.485 159.739C318.487 159.847 318.464 159.954 318.42 160.053C318.376 160.152 318.311 160.24 318.23 160.312C318.15 160.383 318.055 160.437 317.951 160.469C317.848 160.501 317.739 160.51 317.632 160.497C317.419 160.492 317.216 160.403 317.069 160.249C316.922 160.096 316.841 159.89 316.846 159.677C316.85 159.464 316.939 159.262 317.093 159.114C317.247 158.967 317.453 158.887 317.666 158.89C317.777 158.888 317.887 158.907 317.99 158.949C318.093 158.991 318.186 159.054 318.263 159.134C318.34 159.213 318.399 159.308 318.438 159.413C318.476 159.516 318.493 159.628 318.485 159.739Z" fill="black" />
            <path d="M278.891 151.468C278.89 151.626 278.842 151.779 278.753 151.909C278.665 152.04 278.54 152.141 278.394 152.201C278.249 152.26 278.088 152.275 277.935 152.244C277.78 152.212 277.639 152.136 277.527 152.024C277.416 151.913 277.341 151.77 277.311 151.616C277.28 151.461 277.297 151.301 277.357 151.156C277.417 151.011 277.52 150.886 277.651 150.799C277.782 150.712 277.936 150.665 278.093 150.665C278.201 150.657 278.309 150.672 278.41 150.709C278.511 150.746 278.602 150.805 278.678 150.882C278.754 150.958 278.812 151.05 278.849 151.151C278.886 151.253 278.9 151.361 278.891 151.468Z" fill="black" />
            <path d="M357.109 101.839C356.62 101.761 356.323 101.514 356.345 100.997C356.336 100.89 356.352 100.783 356.391 100.683C356.429 100.583 356.49 100.492 356.568 100.419C356.646 100.346 356.74 100.291 356.843 100.259C356.945 100.227 357.053 100.218 357.159 100.233C357.267 100.226 357.375 100.242 357.475 100.282C357.576 100.321 357.666 100.382 357.74 100.46C357.815 100.539 357.87 100.633 357.904 100.735C357.938 100.838 357.948 100.947 357.934 101.053C357.934 101.16 357.911 101.265 357.869 101.363C357.827 101.46 357.766 101.548 357.688 101.622C357.611 101.695 357.52 101.752 357.421 101.79C357.321 101.827 357.215 101.844 357.109 101.839Z" fill="black" />
            <path d="M307.183 70.0374C307.773 70.0374 308.07 70.3462 308.076 70.8235C308.086 70.9307 308.072 71.0391 308.037 71.1407C308.001 71.2423 307.943 71.3344 307.868 71.4113C307.792 71.4883 307.701 71.5483 307.6 71.586C307.499 71.6241 307.391 71.6399 307.284 71.632C307.177 71.6455 307.07 71.6342 306.968 71.6C306.866 71.5657 306.774 71.509 306.697 71.4344C306.621 71.3591 306.562 71.2676 306.526 71.1671C306.489 71.066 306.476 70.9582 306.487 70.8516C306.476 70.7522 306.486 70.6511 306.515 70.5551C306.545 70.4591 306.594 70.3704 306.659 70.294C306.724 70.2182 306.804 70.1559 306.894 70.1115C306.984 70.0677 307.083 70.0425 307.183 70.0374Z" fill="black" />
            <path d="M329.681 145.505C329.681 146.027 329.423 146.33 328.912 146.347C328.755 146.353 328.6 146.311 328.467 146.229C328.333 146.147 328.227 146.027 328.161 145.884C328.096 145.742 328.073 145.583 328.097 145.428C328.121 145.273 328.191 145.129 328.297 145.013C328.402 144.897 328.54 144.815 328.692 144.777C328.844 144.739 329.004 144.747 329.152 144.799C329.3 144.852 329.429 144.947 329.523 145.073C329.617 145.198 329.672 145.348 329.681 145.505Z" fill="black" />
            <path d="M325.302 72.1037C325.195 72.1065 325.089 72.0857 324.991 72.043C324.893 72.0009 324.804 71.938 324.733 71.8589C324.661 71.7791 324.606 71.6854 324.574 71.5832C324.542 71.4815 324.532 71.3737 324.545 71.2671C324.537 71.1593 324.553 71.0503 324.593 70.9498C324.633 70.8488 324.694 70.7578 324.773 70.6837C324.852 70.6096 324.947 70.554 325.05 70.5209C325.153 70.4877 325.263 70.4782 325.37 70.4922C325.478 70.4877 325.585 70.5063 325.685 70.5473C325.785 70.5882 325.874 70.65 325.948 70.7292C326.021 70.8083 326.076 70.9027 326.109 71.0054C326.142 71.1076 326.152 71.2165 326.139 71.3232C326.14 71.431 326.119 71.5377 326.077 71.6365C326.034 71.7353 325.972 71.8246 325.893 71.8982C325.814 71.9717 325.721 72.0279 325.619 72.0632C325.518 72.0986 325.41 72.1126 325.302 72.1037Z" fill="black" />
            <path d="M362.291 72.1879C362.298 72.2951 362.281 72.4029 362.244 72.5034C362.206 72.6045 362.148 72.696 362.073 72.7735C361.998 72.8504 361.908 72.911 361.809 72.9515C361.709 72.9919 361.601 73.011 361.494 73.0076C361.387 73.0194 361.279 73.0076 361.177 72.9728C361.075 72.9385 360.983 72.8818 360.905 72.8072C360.827 72.733 360.767 72.6426 360.728 72.5421C360.689 72.4422 360.673 72.3344 360.68 72.2272C360.667 72.1199 360.678 72.0116 360.713 71.9094C360.747 71.8072 360.803 71.7134 360.878 71.6359C360.952 71.5579 361.043 71.4972 361.144 71.4591C361.245 71.4203 361.353 71.4046 361.46 71.413C361.567 71.4035 361.674 71.4164 361.776 71.4512C361.877 71.486 361.97 71.5422 362.049 71.6152C362.127 71.6881 362.189 71.7769 362.231 71.8757C362.273 71.9745 362.293 72.0806 362.291 72.1879Z" fill="black" />
            <path d="M288.178 142.608C288.186 142.716 288.172 142.825 288.137 142.928C288.101 143.03 288.044 143.124 287.97 143.204C287.896 143.284 287.807 143.347 287.707 143.39C287.608 143.434 287.5 143.456 287.392 143.456C287.284 143.468 287.176 143.457 287.074 143.423C286.972 143.389 286.878 143.334 286.799 143.26C286.721 143.186 286.659 143.096 286.618 142.996C286.579 142.896 286.561 142.789 286.566 142.681C286.552 142.574 286.561 142.466 286.594 142.363C286.627 142.26 286.682 142.166 286.755 142.088C286.83 142.009 286.919 141.948 287.02 141.909C287.12 141.87 287.228 141.853 287.335 141.861C287.441 141.848 287.548 141.858 287.65 141.89C287.751 141.922 287.845 141.974 287.924 142.045C288.004 142.116 288.068 142.202 288.111 142.3C288.155 142.396 288.178 142.502 288.178 142.608Z" fill="black" />
            <path d="M380.372 72.6539C380.327 73.1199 380.114 73.4288 379.586 73.44C379.48 73.4546 379.371 73.445 379.269 73.4125C379.167 73.3799 379.073 73.3254 378.995 73.2525C378.917 73.1795 378.855 73.0896 378.816 72.9902C378.776 72.8903 378.759 72.7836 378.766 72.6764C378.754 72.5691 378.766 72.4608 378.801 72.358C378.835 72.2558 378.892 72.1621 378.966 72.084C379.04 72.0054 379.131 71.9442 379.23 71.9038C379.331 71.8634 379.439 71.8454 379.547 71.851C379.655 71.8432 379.764 71.8583 379.866 71.8954C379.968 71.9324 380.061 71.9908 380.14 72.0666C380.218 72.143 380.279 72.2345 380.319 72.3356C380.359 72.4366 380.377 72.5456 380.372 72.6539Z" fill="black" />
            <path d="M282.787 140.654C282.801 140.763 282.791 140.873 282.758 140.978C282.725 141.082 282.67 141.178 282.596 141.259C282.523 141.341 282.432 141.405 282.331 141.448C282.231 141.49 282.122 141.511 282.012 141.507C281.905 141.521 281.797 141.512 281.693 141.48C281.591 141.447 281.496 141.393 281.416 141.32C281.337 141.247 281.274 141.158 281.232 141.059C281.191 140.959 281.171 140.852 281.176 140.744C281.159 140.638 281.167 140.529 281.199 140.425C281.23 140.322 281.285 140.228 281.358 140.149C281.432 140.07 281.522 140.009 281.623 139.97C281.724 139.931 281.831 139.915 281.939 139.924C282.044 139.912 282.149 139.921 282.25 139.951C282.351 139.982 282.444 140.033 282.524 140.103C282.603 140.171 282.668 140.256 282.713 140.351C282.758 140.446 282.784 140.549 282.787 140.654Z" fill="black" />
            <path d="M349.585 73.7544C349.58 73.8616 349.554 73.9661 349.507 74.0621C349.46 74.1586 349.394 74.244 349.313 74.3142C349.233 74.3844 349.138 74.4366 349.036 74.4691C348.934 74.5011 348.827 74.5124 348.72 74.5011C348.613 74.5034 348.506 74.4809 348.408 74.4366C348.31 74.3922 348.223 74.3265 348.154 74.244C348.084 74.1615 348.034 74.0649 348.007 73.961C347.98 73.8566 347.976 73.7482 347.996 73.6421C347.994 73.5348 348.015 73.4282 348.059 73.3299C348.102 73.2316 348.166 73.1435 348.247 73.0728C348.328 73.0014 348.423 72.9492 348.526 72.9183C348.629 72.888 348.737 72.8802 348.844 72.8953C349.361 72.9234 349.568 73.2659 349.585 73.7544Z" fill="black" />
            <path d="M367.665 74.1418C367.672 74.2485 367.657 74.3552 367.62 74.4551C367.583 74.5551 367.525 74.646 367.45 74.7218C367.375 74.7976 367.285 74.8566 367.186 74.8953C367.086 74.9335 366.98 74.9503 366.874 74.9447C366.766 74.9576 366.658 74.9464 366.556 74.9122C366.454 74.8779 366.36 74.8218 366.282 74.7471C366.204 74.6724 366.144 74.5815 366.105 74.4804C366.067 74.3799 366.051 74.2721 366.059 74.1643C366.049 74.057 366.061 73.9487 366.096 73.8471C366.132 73.7454 366.189 73.6522 366.264 73.5747C366.338 73.4973 366.429 73.4372 366.53 73.3984C366.63 73.3597 366.738 73.3429 366.845 73.3502C366.953 73.3423 367.06 73.3569 367.162 73.3934C367.263 73.4299 367.355 73.4877 367.433 73.5624C367.51 73.6371 367.571 73.7275 367.611 73.8274C367.651 73.9273 367.669 74.0346 367.665 74.1418Z" fill="black" />
            <path d="M240.474 138.666C239.963 138.61 239.654 138.369 239.654 137.852C239.642 137.745 239.654 137.638 239.688 137.536C239.723 137.434 239.781 137.342 239.856 137.265C239.932 137.189 240.023 137.131 240.124 137.094C240.225 137.058 240.333 137.044 240.44 137.055C240.547 137.043 240.656 137.055 240.757 137.09C240.86 137.125 240.953 137.182 241.03 137.257C241.107 137.332 241.167 137.424 241.205 137.525C241.243 137.626 241.258 137.734 241.249 137.841C241.255 137.947 241.239 138.053 241.203 138.153C241.167 138.252 241.111 138.343 241.038 138.42C240.966 138.497 240.879 138.559 240.782 138.602C240.684 138.644 240.579 138.666 240.474 138.666Z" fill="black" />
            <path d="M384.93 73.7937C385.43 73.8442 385.728 74.0857 385.716 74.6078C385.725 74.7151 385.711 74.8229 385.675 74.9239C385.638 75.025 385.581 75.1171 385.506 75.1934C385.43 75.2704 385.34 75.3299 385.239 75.3686C385.139 75.4068 385.032 75.4231 384.925 75.4163C384.817 75.4259 384.709 75.4119 384.607 75.3748C384.506 75.3377 384.415 75.2788 384.339 75.2019C384.263 75.1249 384.205 75.0329 384.17 74.9307C384.134 74.829 384.122 74.7207 384.133 74.6134C384.127 74.5062 384.144 74.399 384.182 74.299C384.22 74.1985 384.278 74.107 384.352 74.0301C384.428 73.9532 384.518 73.8925 384.617 73.8515C384.716 73.8111 384.823 73.7914 384.93 73.7937Z" fill="black" />
            <path d="M346.267 133.31C346.272 133.416 346.253 133.521 346.214 133.619C346.174 133.717 346.114 133.806 346.037 133.878C345.961 133.951 345.87 134.007 345.77 134.041C345.67 134.076 345.564 134.089 345.459 134.079C345.351 134.09 345.242 134.076 345.14 134.04C345.038 134.003 344.945 133.944 344.869 133.867C344.793 133.79 344.735 133.697 344.699 133.595C344.662 133.493 344.65 133.384 344.661 133.276C344.653 133.169 344.669 133.061 344.707 132.961C344.745 132.859 344.805 132.769 344.882 132.693C344.959 132.617 345.051 132.56 345.153 132.524C345.254 132.488 345.363 132.475 345.47 132.484C345.578 132.479 345.686 132.497 345.788 132.538C345.889 132.578 345.98 132.639 346.055 132.717C346.131 132.795 346.188 132.888 346.225 132.991C346.261 133.093 346.276 133.202 346.267 133.31Z" fill="black" />
            <path d="M390.258 77.3534C390.057 77.331 389.871 77.2338 389.739 77.0806C389.607 76.9273 389.537 76.7296 389.544 76.5275C389.552 76.3254 389.635 76.1333 389.777 75.9896C389.92 75.8459 390.112 75.7617 390.314 75.7532C390.422 75.7471 390.529 75.7645 390.63 75.8038C390.73 75.8431 390.82 75.9043 390.894 75.9823C390.969 76.0604 391.025 76.1536 391.06 76.2557C391.094 76.3579 391.106 76.4663 391.095 76.573C391.099 76.6819 391.081 76.7903 391.039 76.8908C390.998 76.9913 390.936 77.0822 390.856 77.1563C390.776 77.2305 390.682 77.2866 390.579 77.3203C390.475 77.3546 390.366 77.3658 390.258 77.3534Z" fill="black" />
            <path d="M291.243 132.731C290.765 132.681 290.451 132.445 290.451 131.929C290.437 131.822 290.449 131.714 290.483 131.612C290.517 131.51 290.574 131.418 290.648 131.34C290.724 131.263 290.815 131.204 290.916 131.167C291.016 131.13 291.124 131.116 291.231 131.126C291.339 131.113 291.448 131.124 291.55 131.159C291.653 131.193 291.747 131.25 291.825 131.326C291.903 131.401 291.963 131.492 292.001 131.594C292.039 131.695 292.054 131.804 292.045 131.912C292.052 132.02 292.035 132.128 291.997 132.229C291.96 132.33 291.901 132.422 291.825 132.5C291.75 132.577 291.659 132.637 291.558 132.677C291.458 132.717 291.35 132.735 291.243 132.731Z" fill="black" />
            <path d="M375.166 102.272C375.058 102.274 374.952 102.254 374.854 102.211C374.755 102.169 374.667 102.106 374.594 102.027C374.522 101.948 374.467 101.854 374.434 101.752C374.401 101.649 374.39 101.542 374.402 101.435C374.395 101.328 374.411 101.22 374.45 101.119C374.49 101.019 374.55 100.928 374.629 100.854C374.708 100.78 374.801 100.724 374.904 100.69C375.006 100.657 375.115 100.647 375.222 100.66C375.33 100.655 375.438 100.673 375.539 100.713C375.639 100.754 375.729 100.816 375.803 100.895C375.877 100.974 375.933 101.069 375.966 101.172C375.999 101.275 376.01 101.384 375.997 101.491C375.998 101.599 375.978 101.705 375.936 101.804C375.894 101.903 375.833 101.993 375.754 102.066C375.676 102.139 375.583 102.196 375.482 102.231C375.38 102.267 375.273 102.281 375.166 102.272Z" fill="black" />
            <path d="M309.064 101.648C309.056 101.86 308.965 102.06 308.811 102.204C308.656 102.349 308.45 102.425 308.238 102.418C308.027 102.41 307.827 102.319 307.683 102.164C307.538 102.009 307.462 101.804 307.469 101.592C307.473 101.488 307.497 101.384 307.541 101.29C307.584 101.194 307.646 101.108 307.722 101.037C307.799 100.966 307.889 100.91 307.987 100.873C308.085 100.836 308.19 100.82 308.295 100.823C308.399 100.827 308.502 100.851 308.597 100.895C308.693 100.938 308.779 101 308.85 101.077C308.921 101.153 308.977 101.243 309.014 101.341C309.05 101.439 309.067 101.544 309.064 101.648Z" fill="black" />
            <path d="M311.49 78.1564C311.491 78.263 311.47 78.3692 311.428 78.4669C311.387 78.5651 311.325 78.6538 311.247 78.7268C311.17 78.8004 311.078 78.8565 310.978 78.893C310.877 78.9289 310.771 78.9441 310.665 78.9368C310.453 78.9368 310.25 78.8531 310.101 78.7038C309.951 78.5539 309.867 78.3512 309.867 78.1395C309.867 77.9284 309.951 77.7257 310.101 77.5758C310.25 77.4265 310.453 77.3422 310.665 77.3422C310.774 77.3332 310.885 77.3478 310.988 77.3855C311.092 77.4231 311.186 77.4826 311.264 77.5595C311.343 77.637 311.403 77.7308 311.442 77.8335C311.481 77.9363 311.498 78.0469 311.49 78.1564Z" fill="black" />
            <path d="M334.605 130.177C334.502 130.176 334.401 130.154 334.307 130.11C334.214 130.068 334.131 130.005 334.063 129.928C333.996 129.851 333.946 129.76 333.916 129.661C333.887 129.562 333.879 129.459 333.892 129.357C333.899 129.145 333.991 128.946 334.145 128.802C334.3 128.657 334.506 128.58 334.717 128.588C334.929 128.595 335.128 128.687 335.273 128.841C335.417 128.996 335.494 129.201 335.487 129.413C335.47 129.907 335.178 130.177 334.605 130.177Z" fill="black" />
            <path d="M327.177 102.058C327.187 102.17 327.173 102.283 327.136 102.39C327.1 102.497 327.041 102.594 326.965 102.677C326.889 102.76 326.796 102.826 326.693 102.871C326.59 102.917 326.479 102.94 326.366 102.94C326.253 102.94 326.142 102.917 326.039 102.871C325.936 102.826 325.843 102.76 325.767 102.677C325.691 102.594 325.632 102.497 325.596 102.39C325.559 102.283 325.545 102.17 325.555 102.058C325.546 101.95 325.561 101.841 325.599 101.739C325.637 101.637 325.696 101.543 325.773 101.466C325.849 101.389 325.942 101.329 326.044 101.291C326.145 101.252 326.255 101.237 326.363 101.244C326.472 101.24 326.579 101.257 326.68 101.296C326.781 101.336 326.873 101.396 326.949 101.472C327.026 101.548 327.086 101.64 327.125 101.741C327.164 101.842 327.182 101.95 327.177 102.058Z" fill="black" />
            <path d="M316.863 80.1046C316.865 80.2108 316.846 80.3163 316.805 80.4146C316.764 80.5123 316.702 80.601 316.625 80.6734C316.548 80.7458 316.456 80.8014 316.355 80.8362C316.255 80.8705 316.148 80.8834 316.043 80.8739C315.936 80.884 315.828 80.8699 315.727 80.8329C315.626 80.7958 315.534 80.7363 315.459 80.6594C315.384 80.5824 315.326 80.4898 315.291 80.3882C315.256 80.2866 315.244 80.1782 315.257 80.071C315.248 79.9637 315.263 79.8553 315.301 79.7543C315.338 79.6526 315.398 79.5611 315.475 79.4853C315.553 79.4101 315.645 79.3523 315.747 79.3169C315.849 79.281 315.958 79.2686 316.065 79.2793C316.174 79.2742 316.282 79.2922 316.383 79.3326C316.484 79.3725 316.575 79.4337 316.651 79.5117C316.726 79.5898 316.784 79.683 316.82 79.7852C316.857 79.8879 316.871 79.9968 316.863 80.1046Z" fill="black" />
            <path d="M373.701 87.9933C374.184 88.0327 374.515 88.2572 374.526 88.7738C374.53 88.8788 374.514 88.9832 374.478 89.0815C374.441 89.1797 374.386 89.2701 374.315 89.3465C374.243 89.4234 374.158 89.4857 374.063 89.5295C373.968 89.5733 373.864 89.598 373.76 89.602C373.655 89.6065 373.551 89.5896 373.452 89.5531C373.354 89.5172 373.264 89.4616 373.187 89.3903C373.11 89.3195 373.048 89.2336 373.004 89.1387C372.96 89.0433 372.936 88.9406 372.932 88.8356C372.909 88.3302 373.218 88.0663 373.701 87.9933Z" fill="black" />
            <path d="M331.237 122.883C331.131 122.884 331.026 122.862 330.929 122.82C330.831 122.777 330.744 122.715 330.672 122.637C330.6 122.558 330.545 122.466 330.511 122.366C330.477 122.265 330.464 122.158 330.474 122.052C330.464 121.945 330.479 121.837 330.517 121.737C330.555 121.636 330.615 121.545 330.692 121.47C330.77 121.395 330.863 121.339 330.965 121.304C331.067 121.27 331.175 121.259 331.282 121.272C331.39 121.264 331.498 121.28 331.599 121.318C331.7 121.357 331.791 121.417 331.866 121.495C331.941 121.572 331.998 121.665 332.033 121.768C332.068 121.87 332.08 121.979 332.068 122.086C332.069 122.194 332.048 122.301 332.006 122.4C331.964 122.5 331.902 122.59 331.825 122.664C331.747 122.738 331.655 122.797 331.553 122.834C331.452 122.872 331.345 122.889 331.237 122.883Z" fill="black" />
            <path d="M392.6 89.2229C392.61 89.3313 392.597 89.4408 392.562 89.5441C392.528 89.6468 392.471 89.7417 392.396 89.8215C392.322 89.9006 392.232 89.9635 392.131 90.0056C392.031 90.0477 391.923 90.0679 391.814 90.0651C391.706 90.0769 391.597 90.0646 391.495 90.0303C391.392 89.9961 391.299 89.9394 391.219 89.8658C391.141 89.7917 391.079 89.7013 391.038 89.6008C390.997 89.5009 390.978 89.393 390.983 89.2847C390.968 89.1769 390.979 89.0674 391.012 88.9635C391.044 88.8597 391.1 88.7648 391.174 88.6845C391.248 88.6047 391.338 88.5424 391.44 88.5014C391.541 88.4604 391.649 88.4425 391.758 88.4481C391.865 88.4414 391.971 88.4565 392.072 88.4919C392.173 88.5278 392.265 88.5834 392.345 88.6558C392.423 88.7283 392.486 88.8159 392.53 88.913C392.574 89.0107 392.598 89.1162 392.6 89.2229Z" fill="black" />
            <path d="M373.594 125C373.593 125.106 373.571 125.211 373.528 125.307C373.484 125.404 373.422 125.49 373.342 125.56C373.263 125.631 373.171 125.684 373.07 125.716C372.969 125.748 372.862 125.759 372.757 125.747C372.65 125.755 372.542 125.738 372.442 125.699C372.341 125.66 372.251 125.599 372.177 125.52C372.104 125.441 372.049 125.348 372.016 125.245C371.983 125.143 371.973 125.034 371.988 124.927C371.982 124.819 372 124.712 372.04 124.612C372.081 124.512 372.142 124.422 372.221 124.348C372.299 124.275 372.393 124.218 372.495 124.185C372.598 124.151 372.706 124.14 372.813 124.152C372.922 124.149 373.031 124.17 373.132 124.212C373.232 124.254 373.323 124.318 373.397 124.399C373.47 124.479 373.527 124.574 373.56 124.678C373.594 124.782 373.606 124.892 373.594 125Z" fill="black" />
            <path d="M296.397 103.114C296.398 103.22 296.377 103.327 296.334 103.424C296.292 103.523 296.229 103.61 296.15 103.682C296.071 103.753 295.977 103.808 295.875 103.84C295.773 103.873 295.666 103.884 295.56 103.872C295.45 103.876 295.339 103.857 295.237 103.817C295.134 103.776 295.041 103.715 294.963 103.637C294.885 103.559 294.824 103.465 294.784 103.362C294.744 103.259 294.725 103.149 294.729 103.039C294.734 102.928 294.761 102.82 294.808 102.72C294.857 102.621 294.925 102.533 295.008 102.46C295.092 102.388 295.19 102.334 295.296 102.302C295.401 102.27 295.512 102.259 295.622 102.272C295.73 102.269 295.838 102.291 295.937 102.333C296.036 102.375 296.126 102.438 296.199 102.518C296.273 102.598 296.328 102.692 296.362 102.795C296.396 102.898 296.408 103.007 296.397 103.114Z" fill="black" />
            <path d="M251.732 122.878C251.837 122.883 251.94 122.909 252.034 122.955C252.128 123.001 252.212 123.066 252.281 123.147C252.348 123.227 252.399 123.32 252.429 123.421C252.46 123.521 252.469 123.627 252.456 123.731C252.462 123.838 252.443 123.946 252.402 124.044C252.361 124.144 252.298 124.232 252.219 124.304C252.14 124.376 252.045 124.43 251.943 124.46C251.84 124.492 251.731 124.5 251.625 124.483C251.517 124.488 251.41 124.47 251.309 124.429C251.209 124.387 251.12 124.325 251.047 124.245C250.974 124.165 250.92 124.07 250.888 123.966C250.855 123.863 250.847 123.754 250.862 123.647C250.884 123.158 251.199 122.894 251.732 122.878Z" fill="black" />
            <path d="M360.989 89.4588C361.517 89.4981 361.82 89.7732 361.825 90.2617C361.835 90.3695 361.822 90.4784 361.786 90.58C361.75 90.6822 361.691 90.7749 361.615 90.8518C361.539 90.9293 361.448 90.9888 361.346 91.0264C361.244 91.064 361.136 91.0792 361.028 91.0702C360.921 91.0803 360.813 91.0674 360.712 91.0309C360.61 90.995 360.518 90.9371 360.442 90.8619C360.366 90.7867 360.306 90.6951 360.269 90.5941C360.231 90.4936 360.216 90.3858 360.225 90.2785C360.217 90.1735 360.23 90.068 360.264 89.968C360.299 89.8687 360.354 89.7771 360.426 89.7002C360.498 89.6233 360.585 89.5615 360.682 89.52C360.779 89.4784 360.883 89.4577 360.989 89.4588Z" fill="black" />
            <path d="M360.927 126.415C360.929 126.521 360.909 126.627 360.868 126.725C360.826 126.823 360.766 126.912 360.689 126.985C360.611 127.058 360.52 127.115 360.42 127.151C360.32 127.187 360.214 127.203 360.107 127.195C360.001 127.207 359.892 127.194 359.791 127.159C359.69 127.123 359.598 127.065 359.522 126.989C359.446 126.913 359.388 126.821 359.352 126.72C359.317 126.619 359.304 126.51 359.316 126.404C359.305 126.296 359.319 126.187 359.355 126.086C359.392 125.984 359.45 125.892 359.526 125.815C359.602 125.738 359.694 125.679 359.795 125.642C359.897 125.605 360.005 125.591 360.113 125.601C360.607 125.606 360.865 125.926 360.927 126.415Z" fill="black" />
            <path d="M312.978 90.8344C312.927 91.3958 312.652 91.6541 312.152 91.6485C312.044 91.6586 311.935 91.644 311.834 91.6053C311.733 91.5665 311.642 91.5053 311.569 91.4261C311.495 91.347 311.44 91.2521 311.409 91.1488C311.378 91.0449 311.371 90.9354 311.389 90.8287C311.381 90.7209 311.396 90.6131 311.435 90.5121C311.473 90.411 311.532 90.3195 311.609 90.2437C311.686 90.1679 311.778 90.1101 311.88 90.073C311.981 90.0365 312.089 90.0225 312.197 90.0314C312.301 90.0298 312.406 90.05 312.503 90.0898C312.6 90.1297 312.687 90.1892 312.76 90.2645C312.834 90.3397 312.89 90.429 312.928 90.5272C312.965 90.6249 312.982 90.7299 312.978 90.8344Z" fill="black" />
            <path d="M397.064 127.398C397.065 127.505 397.043 127.611 396.999 127.71C396.955 127.807 396.891 127.896 396.811 127.966C396.731 128.038 396.636 128.091 396.533 128.123C396.431 128.154 396.322 128.164 396.216 128.15C396.108 128.156 396.001 128.138 395.901 128.098C395.801 128.057 395.711 127.995 395.638 127.915C395.565 127.835 395.51 127.741 395.478 127.638C395.446 127.535 395.437 127.426 395.453 127.319C395.448 127.211 395.468 127.104 395.509 127.004C395.551 126.905 395.614 126.816 395.693 126.743C395.773 126.67 395.868 126.616 395.97 126.583C396.074 126.551 396.182 126.541 396.289 126.555C396.397 126.554 396.504 126.576 396.603 126.619C396.702 126.661 396.791 126.724 396.864 126.804C396.937 126.883 396.993 126.977 397.028 127.08C397.062 127.182 397.074 127.29 397.064 127.398Z" fill="black" />
            <path d="M397.985 91.2162C397.987 91.3234 397.967 91.4301 397.927 91.5295C397.886 91.6289 397.825 91.7187 397.748 91.7939C397.671 91.8686 397.58 91.9264 397.479 91.9646C397.379 92.0022 397.272 92.0191 397.165 92.0135C396.964 91.9966 396.777 91.9051 396.641 91.7569C396.505 91.6086 396.429 91.4149 396.429 91.2134C396.429 91.0124 396.505 90.8181 396.641 90.6699C396.777 90.5216 396.964 90.4301 397.165 90.4133C397.681 90.4133 397.923 90.7277 397.985 91.2162Z" fill="black" />
            <path d="M295.173 121.957C295.066 121.966 294.959 121.952 294.858 121.916C294.757 121.879 294.665 121.822 294.589 121.746C294.513 121.671 294.454 121.58 294.416 121.48C294.378 121.38 294.363 121.272 294.37 121.165C294.358 121.058 294.369 120.95 294.403 120.847C294.437 120.745 294.493 120.651 294.568 120.574C294.643 120.496 294.734 120.435 294.834 120.397C294.935 120.358 295.043 120.343 295.151 120.351C295.258 120.341 295.367 120.354 295.468 120.39C295.57 120.426 295.663 120.484 295.74 120.559C295.817 120.635 295.878 120.726 295.917 120.827C295.955 120.927 295.972 121.035 295.965 121.143C295.973 121.25 295.958 121.357 295.922 121.458C295.885 121.559 295.827 121.651 295.752 121.728C295.678 121.805 295.587 121.865 295.487 121.905C295.387 121.944 295.281 121.962 295.173 121.957Z" fill="black" />
            <path d="M350.32 87.196C349.759 87.196 349.484 86.9378 349.472 86.4212C349.458 86.3145 349.467 86.2056 349.501 86.1028C349.533 86.0001 349.588 85.9058 349.662 85.8272C349.735 85.748 349.826 85.6862 349.926 85.6464C350.026 85.6065 350.134 85.5891 350.242 85.5958C350.445 85.5998 350.639 85.6806 350.786 85.8227C350.932 85.9642 351.019 86.1562 351.028 86.3594C351.038 86.5627 350.971 86.762 350.84 86.9175C350.708 87.0731 350.522 87.1724 350.32 87.196Z" fill="black" />
            <path d="M281.378 92.7266C281.272 92.7266 281.167 92.7041 281.07 92.6614C280.973 92.6182 280.886 92.5559 280.814 92.4778C280.743 92.3998 280.688 92.3083 280.654 92.2078C280.619 92.1078 280.606 92.0017 280.615 91.8956C280.604 91.7884 280.618 91.68 280.655 91.5789C280.693 91.4773 280.753 91.3858 280.831 91.3111C280.908 91.2359 281.002 91.1792 281.105 91.1455C281.207 91.1118 281.316 91.1017 281.423 91.1152C281.531 91.1073 281.639 91.123 281.74 91.1617C281.841 91.1999 281.932 91.26 282.007 91.338C282.082 91.4155 282.139 91.5087 282.174 91.6109C282.209 91.7137 282.221 91.822 282.209 91.9293C282.203 92.3897 281.883 92.6536 281.378 92.7266Z" fill="black" />
            <path d="M284.146 86.4829C284.112 87.0051 283.849 87.3027 283.343 87.3027C283.235 87.3111 283.127 87.2959 283.025 87.2589C282.924 87.2213 282.832 87.1629 282.755 87.0865C282.678 87.0102 282.619 86.9186 282.581 86.817C282.543 86.716 282.527 86.6076 282.535 86.4998C282.524 86.3925 282.538 86.2842 282.574 86.182C282.611 86.0804 282.669 85.9883 282.745 85.9114C282.821 85.835 282.913 85.776 283.015 85.739C283.116 85.7019 283.224 85.6873 283.332 85.6969C283.438 85.6901 283.545 85.7053 283.645 85.7418C283.745 85.7783 283.837 85.8356 283.913 85.9097C283.99 85.9838 284.05 86.0731 284.091 86.1719C284.13 86.2707 284.15 86.3763 284.146 86.4829Z" fill="black" />
            <path d="M319.406 123.608C319.322 124.102 319.053 124.41 318.547 124.382C318.44 124.385 318.332 124.364 318.233 124.321C318.135 124.279 318.045 124.216 317.974 124.136C317.901 124.056 317.847 123.961 317.814 123.858C317.781 123.756 317.771 123.647 317.784 123.54C317.778 123.432 317.796 123.325 317.836 123.225C317.877 123.125 317.939 123.035 318.018 122.962C318.098 122.889 318.193 122.834 318.296 122.802C318.399 122.77 318.508 122.761 318.615 122.777C319.131 122.782 319.35 123.113 319.406 123.608Z" fill="black" />
            <path d="M384.043 111.575C383.934 111.582 383.824 111.567 383.721 111.529C383.618 111.492 383.524 111.434 383.444 111.358C383.365 111.283 383.302 111.192 383.259 111.091C383.216 110.991 383.194 110.882 383.195 110.772C383.181 110.665 383.192 110.556 383.226 110.453C383.259 110.349 383.316 110.256 383.391 110.177C383.466 110.099 383.556 110.038 383.658 109.999C383.759 109.96 383.868 109.944 383.976 109.953C384.083 109.941 384.192 109.953 384.295 109.988C384.397 110.023 384.491 110.079 384.569 110.155C384.647 110.229 384.708 110.32 384.747 110.421C384.786 110.522 384.802 110.631 384.796 110.739C384.805 110.844 384.792 110.95 384.759 111.05C384.725 111.15 384.672 111.242 384.601 111.32C384.531 111.399 384.445 111.462 384.349 111.506C384.253 111.55 384.149 111.574 384.043 111.575Z" fill="black" />
            <path d="M318.363 92.7265C318.374 92.8349 318.362 92.9438 318.328 93.0471C318.294 93.1505 318.239 93.2453 318.166 93.3262C318.093 93.4065 318.004 93.4711 317.904 93.5149C317.804 93.5581 317.697 93.5805 317.588 93.58C317.481 93.5951 317.372 93.5862 317.269 93.5542C317.165 93.5222 317.07 93.4677 316.99 93.3947C316.91 93.3223 316.847 93.2324 316.806 93.1325C316.765 93.0326 316.746 92.9247 316.751 92.8164C316.736 92.7097 316.745 92.6013 316.777 92.4986C316.809 92.3953 316.864 92.3009 316.937 92.2218C317.01 92.1432 317.1 92.0814 317.2 92.0415C317.3 92.0011 317.408 91.9843 317.515 91.991C317.62 91.9787 317.726 91.9882 317.827 92.0191C317.928 92.05 318.022 92.1022 318.102 92.1712C318.182 92.2403 318.246 92.3256 318.291 92.4211C318.336 92.5171 318.361 92.621 318.363 92.7265Z" fill="black" />
            <path d="M348.671 109.835C348.626 110.317 348.39 110.632 347.874 110.638C347.66 110.642 347.455 110.562 347.301 110.415C347.147 110.267 347.058 110.065 347.054 109.851C347.049 109.639 347.13 109.433 347.277 109.279C347.424 109.125 347.627 109.036 347.84 109.032C347.948 109.027 348.056 109.043 348.158 109.082C348.259 109.119 348.351 109.178 348.429 109.253C348.507 109.329 348.569 109.419 348.61 109.519C348.652 109.619 348.673 109.726 348.671 109.835Z" fill="black" />
            <path d="M281.304 128.043C281.306 128.149 281.286 128.255 281.244 128.353C281.203 128.451 281.142 128.539 281.065 128.611C280.988 128.684 280.897 128.741 280.797 128.776C280.696 128.812 280.59 128.826 280.484 128.818C280.377 128.829 280.269 128.815 280.168 128.779C280.067 128.743 279.975 128.684 279.899 128.608C279.823 128.532 279.765 128.439 279.729 128.337C279.694 128.236 279.681 128.128 279.693 128.021C279.684 127.913 279.699 127.805 279.737 127.703C279.774 127.602 279.834 127.51 279.911 127.434C279.988 127.358 280.081 127.3 280.183 127.263C280.285 127.227 280.393 127.213 280.501 127.223C280.609 127.219 280.717 127.238 280.817 127.278C280.917 127.318 281.008 127.378 281.084 127.456C281.16 127.533 281.218 127.625 281.256 127.726C281.294 127.828 281.31 127.935 281.304 128.043Z" fill="black" />
            <path d="M369.165 86.8592C369.169 86.967 369.15 87.0742 369.11 87.1747C369.071 87.2752 369.011 87.3662 368.935 87.442C368.858 87.5178 368.767 87.5773 368.666 87.616C368.566 87.6548 368.458 87.6727 368.351 87.6677C368.243 87.6767 368.135 87.6615 368.034 87.6239C367.933 87.5868 367.841 87.5279 367.766 87.4515C367.69 87.3746 367.631 87.2831 367.595 87.1814C367.558 87.0804 367.544 86.972 367.553 86.8648C367.543 86.7581 367.556 86.6497 367.592 86.5487C367.629 86.447 367.687 86.3555 367.763 86.2797C367.84 86.2039 367.932 86.1461 368.034 86.1102C368.135 86.0748 368.243 86.0619 368.351 86.0731C368.457 86.0641 368.565 86.0782 368.666 86.1141C368.767 86.15 368.859 86.2073 368.936 86.282C369.013 86.3561 369.073 86.4465 369.113 86.5459C369.152 86.6452 369.17 86.7525 369.165 86.8592Z" fill="black" />
            <path d="M260.267 102.159C260.27 102.266 260.251 102.371 260.211 102.47C260.172 102.569 260.112 102.658 260.036 102.733C259.96 102.807 259.869 102.864 259.77 102.902C259.67 102.94 259.564 102.957 259.458 102.951C259.351 102.964 259.242 102.953 259.14 102.918C259.038 102.884 258.944 102.827 258.868 102.752C258.791 102.676 258.731 102.584 258.695 102.483C258.658 102.381 258.644 102.273 258.655 102.165C258.644 102.058 258.657 101.95 258.692 101.849C258.728 101.747 258.784 101.654 258.86 101.578C258.934 101.5 259.025 101.441 259.126 101.402C259.226 101.364 259.334 101.348 259.441 101.356C259.935 101.345 260.205 101.66 260.267 102.159Z" fill="black" />
            <path d="M386.301 123.495C386.301 123.601 386.279 123.705 386.237 123.802C386.194 123.898 386.131 123.985 386.053 124.056C385.974 124.126 385.882 124.179 385.781 124.212C385.681 124.244 385.574 124.254 385.47 124.242C385.362 124.252 385.255 124.237 385.155 124.199C385.054 124.161 384.964 124.101 384.89 124.024C384.816 123.946 384.76 123.852 384.727 123.75C384.695 123.648 384.685 123.54 384.7 123.433C384.693 123.326 384.709 123.219 384.748 123.119C384.787 123.019 384.847 122.928 384.925 122.853C385.002 122.779 385.096 122.723 385.197 122.688C385.299 122.653 385.407 122.641 385.515 122.653C385.623 122.651 385.731 122.672 385.831 122.714C385.931 122.757 386.021 122.82 386.095 122.899C386.169 122.979 386.226 123.073 386.261 123.175C386.297 123.278 386.31 123.387 386.301 123.495Z" fill="black" />
            <path d="M305.661 94.2482C305.666 94.356 305.65 94.4638 305.613 94.5648C305.576 94.6665 305.518 94.7591 305.445 94.8377C305.371 94.9163 305.282 94.9792 305.183 95.0224C305.084 95.0662 304.978 95.0892 304.87 95.0904C304.763 95.1038 304.655 95.0926 304.553 95.0584C304.451 95.0241 304.358 94.9674 304.281 94.8927C304.204 94.8175 304.145 94.7265 304.108 94.6255C304.071 94.525 304.057 94.4172 304.067 94.3099C304.053 94.2038 304.062 94.0954 304.094 93.9932C304.127 93.8905 304.181 93.7967 304.254 93.7181C304.327 93.639 304.417 93.5772 304.517 93.5368C304.616 93.4969 304.723 93.4789 304.83 93.4846C305.341 93.4565 305.594 93.7653 305.661 94.2482Z" fill="black" />
            <path d="M339.529 83.2826C339.419 83.2921 339.307 83.2781 339.202 83.2422C339.097 83.2062 339.001 83.1489 338.919 83.0737C338.837 82.9985 338.771 82.907 338.727 82.8053C338.682 82.7037 338.659 82.5937 338.659 82.4825C338.659 82.3713 338.682 82.2618 338.727 82.1596C338.771 82.058 338.837 81.9671 338.919 81.8918C339.001 81.8166 339.097 81.7587 339.202 81.7228C339.307 81.6869 339.419 81.6734 339.529 81.6824C339.73 81.6992 339.917 81.7907 340.053 81.939C340.19 82.0872 340.265 82.2815 340.265 82.4825C340.265 82.6841 340.19 82.8778 340.053 83.026C339.917 83.1742 339.73 83.2657 339.529 83.2826Z" fill="black" />
            <path d="M322.915 95.5339C322.807 95.5384 322.699 95.5199 322.6 95.4794C322.5 95.439 322.409 95.3778 322.335 95.2998C322.26 95.2211 322.204 95.1285 322.168 95.0263C322.132 94.9247 322.119 94.8163 322.129 94.7085C322.121 94.6013 322.136 94.4935 322.175 94.3924C322.213 94.2919 322.274 94.201 322.351 94.1263C322.429 94.0516 322.523 93.9949 322.625 93.9612C322.728 93.927 322.836 93.9157 322.943 93.9281C323.05 93.9191 323.158 93.9343 323.259 93.9719C323.36 94.0095 323.451 94.0696 323.525 94.1471C323.6 94.2245 323.657 94.3177 323.691 94.4194C323.725 94.5216 323.736 94.6299 323.724 94.7366C323.729 94.8438 323.711 94.9505 323.672 95.0505C323.633 95.1498 323.573 95.2402 323.497 95.3155C323.42 95.3907 323.329 95.4491 323.229 95.4867C323.129 95.5243 323.022 95.5406 322.915 95.5339Z" fill="black" />
            <path d="M367.407 122.198C367.929 122.266 368.226 122.53 368.21 123.04C368.187 123.241 368.089 123.427 367.936 123.559C367.783 123.692 367.586 123.761 367.384 123.754C367.181 123.747 366.989 123.664 366.846 123.521C366.702 123.378 366.618 123.186 366.609 122.984C366.621 122.485 366.958 122.249 367.407 122.198Z" fill="black" />
            <path d="M267.144 116.791C267.077 117.313 266.819 117.622 266.313 117.617C266.112 117.6 265.925 117.508 265.789 117.36C265.653 117.212 265.577 117.018 265.577 116.817C265.577 116.616 265.653 116.421 265.789 116.273C265.925 116.125 266.112 116.033 266.313 116.016C266.419 116.011 266.524 116.027 266.624 116.064C266.724 116.1 266.815 116.156 266.892 116.228C266.97 116.301 267.032 116.388 267.075 116.484C267.118 116.581 267.142 116.686 267.144 116.791Z" fill="black" />
            <path d="M359.867 95.5957C359.867 96.1571 359.58 96.4435 359.092 96.4547C358.984 96.4665 358.875 96.4547 358.773 96.4199C358.67 96.3851 358.577 96.3278 358.498 96.2532C358.42 96.1785 358.36 96.087 358.32 95.9859C358.282 95.8854 358.265 95.777 358.272 95.6687C358.26 95.5614 358.272 95.4531 358.307 95.3509C358.342 95.2481 358.399 95.1549 358.474 95.0774C358.549 94.9994 358.64 94.9393 358.741 94.9011C358.842 94.8624 358.95 94.8467 359.058 94.8545C359.161 94.8416 359.267 94.8512 359.366 94.8826C359.466 94.9146 359.557 94.9674 359.634 95.0381C359.711 95.1083 359.772 95.1948 359.812 95.2913C359.852 95.3879 359.871 95.4918 359.867 95.5957Z" fill="black" />
            <path d="M381.27 116.056C381.291 115.847 381.394 115.655 381.555 115.521C381.716 115.387 381.924 115.321 382.133 115.338C382.342 115.355 382.536 115.453 382.674 115.612C382.811 115.77 382.882 115.975 382.87 116.185C382.873 116.293 382.852 116.4 382.809 116.499C382.765 116.598 382.701 116.687 382.62 116.758C382.54 116.83 382.445 116.883 382.341 116.914C382.237 116.945 382.129 116.953 382.022 116.937C381.517 116.909 381.275 116.595 381.27 116.056Z" fill="black" />
            <path d="M297.076 116.584C296.969 116.584 296.863 116.562 296.764 116.52C296.665 116.477 296.577 116.414 296.504 116.335C296.43 116.257 296.374 116.164 296.338 116.063C296.303 115.962 296.288 115.854 296.296 115.747C296.289 115.639 296.305 115.53 296.345 115.428C296.384 115.327 296.445 115.235 296.524 115.16C296.603 115.086 296.697 115.029 296.8 114.994C296.904 114.96 297.013 114.949 297.121 114.961C297.325 114.981 297.513 115.077 297.649 115.23C297.785 115.383 297.859 115.581 297.855 115.785C297.851 115.989 297.771 116.185 297.63 116.333C297.489 116.481 297.297 116.571 297.093 116.584H297.076Z" fill="black" />
            <path d="M318.194 105.455C318.196 105.347 318.221 105.241 318.267 105.143C318.313 105.045 318.378 104.958 318.459 104.886C318.541 104.815 318.636 104.761 318.739 104.729C318.842 104.697 318.951 104.685 319.058 104.697C319.166 104.694 319.273 104.713 319.372 104.756C319.471 104.798 319.56 104.862 319.632 104.942C319.703 105.023 319.756 105.118 319.788 105.221C319.819 105.324 319.826 105.433 319.811 105.539C319.819 105.648 319.802 105.756 319.761 105.857C319.72 105.957 319.657 106.046 319.576 106.119C319.495 106.191 319.399 106.243 319.295 106.273C319.19 106.302 319.081 106.306 318.974 106.286C318.867 106.286 318.762 106.263 318.664 106.221C318.567 106.178 318.478 106.116 318.405 106.038C318.332 105.96 318.276 105.869 318.24 105.768C318.203 105.668 318.188 105.562 318.194 105.455Z" fill="black" />
            <path d="M279.025 114.523C279.183 114.527 279.337 114.579 279.466 114.671C279.595 114.762 279.694 114.89 279.751 115.038C279.808 115.186 279.82 115.348 279.785 115.502C279.75 115.657 279.67 115.798 279.556 115.907C279.441 116.016 279.296 116.089 279.14 116.117C278.984 116.144 278.823 116.124 278.678 116.06C278.533 115.996 278.41 115.891 278.325 115.758C278.24 115.623 278.196 115.468 278.199 115.309C278.194 115.201 278.212 115.093 278.252 114.993C278.292 114.892 278.353 114.801 278.432 114.727C278.51 114.653 278.604 114.595 278.706 114.561C278.808 114.525 278.917 114.513 279.025 114.523Z" fill="black" />
            <path d="M290.682 82.2326C290.889 82.241 291.085 82.3269 291.232 82.4735C291.379 82.6206 291.465 82.8171 291.473 83.0243C291.48 83.1366 291.464 83.2489 291.426 83.3539C291.387 83.4594 291.327 83.556 291.249 83.6369C291.172 83.7183 291.079 83.7828 290.975 83.8261C290.871 83.8693 290.76 83.8906 290.648 83.889C290.541 83.8923 290.434 83.8732 290.334 83.8328C290.235 83.7924 290.145 83.7317 290.07 83.6543C289.996 83.5773 289.938 83.4853 289.901 83.3848C289.865 83.2837 289.849 83.1765 289.856 83.0692C289.854 82.9597 289.874 82.8508 289.914 82.7492C289.954 82.6475 290.015 82.5549 290.092 82.4769C290.169 82.3988 290.261 82.3371 290.362 82.2949C290.463 82.2528 290.572 82.2321 290.682 82.2326Z" fill="black" />
            <path d="M347.183 97.1453C347.185 97.2531 347.165 97.3592 347.125 97.4586C347.084 97.558 347.023 97.6478 346.946 97.7231C346.869 97.7977 346.778 97.8556 346.677 97.8938C346.578 97.9314 346.47 97.9482 346.363 97.9426C346.163 97.9258 345.976 97.8342 345.839 97.686C345.703 97.5378 345.627 97.3441 345.627 97.1425C345.627 96.9415 345.703 96.7472 345.839 96.599C345.976 96.4508 346.163 96.3593 346.363 96.3424C346.88 96.3424 347.127 96.6568 347.183 97.1453Z" fill="black" />
            <path d="M398.664 104.703C398.198 104.641 397.867 104.422 397.855 103.911C397.872 103.711 397.964 103.524 398.112 103.387C398.26 103.251 398.454 103.176 398.656 103.176C398.857 103.176 399.051 103.251 399.199 103.387C399.347 103.524 399.439 103.711 399.456 103.911C399.467 104.394 399.158 104.641 398.664 104.703Z" fill="black" />
            <path d="M245.881 139.014C246.039 139.023 246.19 139.078 246.316 139.173C246.443 139.268 246.539 139.397 246.592 139.545C246.645 139.694 246.653 139.855 246.616 140.008C246.578 140.161 246.496 140.3 246.38 140.407C246.264 140.514 246.119 140.584 245.963 140.609C245.807 140.634 245.647 140.612 245.504 140.547C245.36 140.482 245.239 140.376 245.155 140.242C245.072 140.108 245.029 139.953 245.034 139.795C245.034 139.261 245.337 139.037 245.881 139.014Z" fill="black" />
            <path d="M330.894 140.957C330.333 140.957 330.058 140.688 330.041 140.188C330.027 140.081 330.038 139.972 330.071 139.87C330.104 139.767 330.159 139.673 330.232 139.594C330.306 139.515 330.395 139.453 330.495 139.412C330.595 139.371 330.702 139.352 330.81 139.357C330.917 139.346 331.026 139.357 331.129 139.392C331.232 139.427 331.326 139.483 331.404 139.557C331.483 139.631 331.545 139.721 331.586 139.821C331.627 139.922 331.646 140.03 331.641 140.137C331.652 140.242 331.641 140.347 331.608 140.447C331.576 140.546 331.523 140.638 331.452 140.715C331.381 140.793 331.295 140.854 331.199 140.896C331.103 140.938 330.999 140.959 330.894 140.957Z" fill="black" />
            <path d="M342.37 144.056C342.374 144.163 342.354 144.269 342.315 144.367C342.274 144.466 342.214 144.554 342.137 144.628C342.06 144.702 341.969 144.758 341.869 144.795C341.769 144.831 341.662 144.845 341.556 144.837C341.344 144.837 341.142 144.753 340.992 144.603C340.843 144.454 340.759 144.251 340.759 144.039C340.759 143.828 340.843 143.625 340.992 143.476C341.142 143.326 341.344 143.242 341.556 143.242C342.078 143.259 342.337 143.557 342.37 144.056Z" fill="black" />
            <path d="M352.763 63.749C352.202 63.7153 351.91 63.4794 351.899 62.9685C351.886 62.8613 351.896 62.7523 351.927 62.649C351.959 62.5457 352.011 62.4503 352.081 62.3677C352.151 62.2858 352.238 62.2189 352.335 62.1712C352.432 62.1241 352.538 62.0971 352.646 62.0926C352.754 62.0881 352.862 62.1055 352.962 62.1443C353.063 62.183 353.155 62.2425 353.232 62.3183C353.309 62.3941 353.369 62.4851 353.409 62.5856C353.45 62.6855 353.469 62.7933 353.465 62.9011C353.475 63.0033 353.465 63.1061 353.435 63.2038C353.405 63.3015 353.356 63.3924 353.291 63.4716C353.226 63.5502 353.145 63.6153 353.055 63.663C352.964 63.7108 352.865 63.74 352.763 63.749Z" fill="black" />
            <path d="M369.956 63.2099C369.956 62.8337 370.281 62.5979 370.776 62.6204C370.883 62.6131 370.991 62.6299 371.092 62.6692C371.193 62.708 371.284 62.7692 371.358 62.8472C371.432 62.9258 371.489 63.019 371.523 63.1218C371.557 63.2245 371.568 63.3329 371.556 63.4401C371.559 63.5479 371.539 63.6552 371.497 63.7546C371.455 63.8539 371.392 63.9426 371.313 64.0156C371.234 64.0886 371.139 64.1437 371.037 64.1773C370.934 64.2105 370.826 64.2217 370.719 64.2093C370.242 64.1869 369.939 63.8949 369.956 63.2099Z" fill="black" />
            <path d="M280.013 146.886C279.813 146.869 279.626 146.778 279.49 146.63C279.353 146.481 279.277 146.288 279.277 146.086C279.277 145.885 279.353 145.691 279.49 145.543C279.626 145.394 279.813 145.303 280.013 145.286C280.121 145.279 280.23 145.294 280.331 145.333C280.432 145.37 280.524 145.43 280.6 145.507C280.676 145.583 280.735 145.676 280.773 145.777C280.81 145.878 280.825 145.987 280.816 146.095C280.823 146.201 280.807 146.308 280.769 146.408C280.73 146.508 280.671 146.599 280.595 146.673C280.519 146.749 280.428 146.807 280.327 146.843C280.227 146.88 280.12 146.895 280.013 146.886Z" fill="black" />
            <path d="M322.685 63.9623C322.691 64.0684 322.674 64.174 322.636 64.2733C322.599 64.3727 322.541 64.4626 322.466 64.5384C322.391 64.6142 322.302 64.6731 322.203 64.7124C322.105 64.7512 321.999 64.7691 321.893 64.7652C321.786 64.7759 321.678 64.7635 321.576 64.7281C321.474 64.6928 321.381 64.6361 321.304 64.5608C321.226 64.4861 321.166 64.3952 321.127 64.2947C321.088 64.1942 321.072 64.0869 321.079 63.9791C321.068 63.8725 321.08 63.7646 321.116 63.6636C321.151 63.562 321.208 63.4699 321.284 63.3929C321.359 63.3166 321.45 63.2571 321.55 63.22C321.651 63.1824 321.758 63.1672 321.865 63.1762C322.387 63.165 322.646 63.457 322.685 63.9623Z" fill="black" />
            <path d="M334.269 146.69C334.426 146.691 334.579 146.739 334.709 146.827C334.84 146.915 334.941 147.04 335.001 147.185C335.06 147.33 335.075 147.49 335.045 147.644C335.014 147.798 334.938 147.94 334.827 148.051C334.716 148.162 334.574 148.238 334.42 148.269C334.266 148.3 334.106 148.285 333.96 148.225C333.815 148.165 333.69 148.064 333.602 147.934C333.515 147.804 333.467 147.65 333.466 147.493C333.456 147.385 333.471 147.276 333.507 147.175C333.544 147.073 333.603 146.981 333.68 146.904C333.757 146.828 333.849 146.769 333.95 146.732C334.052 146.695 334.161 146.681 334.269 146.69Z" fill="black" />
            <path d="M339.631 150.233C339.193 150.176 338.845 149.98 338.85 149.452C338.836 149.347 338.845 149.239 338.877 149.137C338.91 149.035 338.964 148.941 339.036 148.863C339.108 148.783 339.197 148.722 339.296 148.681C339.395 148.64 339.501 148.622 339.608 148.627C339.715 148.616 339.823 148.627 339.924 148.662C340.026 148.696 340.119 148.751 340.197 148.825C340.275 148.899 340.336 148.988 340.376 149.088C340.416 149.188 340.433 149.295 340.428 149.402C340.456 149.907 340.153 150.165 339.631 150.233Z" fill="black" />
            <path d="M326.907 151.72C326.802 151.722 326.698 151.701 326.602 151.659C326.506 151.618 326.42 151.556 326.35 151.478C326.279 151.401 326.226 151.309 326.193 151.21C326.16 151.111 326.149 151.005 326.16 150.901C326.15 150.794 326.164 150.686 326.201 150.585C326.239 150.485 326.298 150.393 326.375 150.318C326.452 150.244 326.545 150.187 326.647 150.153C326.748 150.119 326.857 150.107 326.963 150.12C327.071 150.112 327.178 150.128 327.279 150.166C327.379 150.204 327.47 150.264 327.545 150.341C327.62 150.418 327.677 150.511 327.712 150.613C327.748 150.714 327.761 150.822 327.749 150.929C327.738 151.417 327.435 151.681 326.907 151.72Z" fill="black" />
            <path d="M284.269 153.399C284.276 153.507 284.262 153.616 284.224 153.718C284.187 153.819 284.128 153.912 284.052 153.989C283.976 154.066 283.885 154.126 283.783 154.165C283.682 154.204 283.574 154.22 283.466 154.213C283.358 154.224 283.25 154.21 283.148 154.174C283.046 154.138 282.954 154.079 282.877 154.003C282.8 153.927 282.741 153.835 282.704 153.733C282.667 153.632 282.653 153.524 282.663 153.416C282.652 153.309 282.665 153.202 282.702 153.101C282.738 153 282.796 152.907 282.873 152.832C282.95 152.757 283.042 152.7 283.143 152.664C283.245 152.63 283.353 152.618 283.46 152.63C283.566 152.619 283.672 152.632 283.772 152.666C283.872 152.7 283.964 152.756 284.041 152.829C284.118 152.902 284.178 152.991 284.217 153.089C284.256 153.188 284.274 153.294 284.269 153.399Z" fill="black" />
            <path d="M324.179 156.291C324.218 155.791 324.465 155.494 324.976 155.494C325.082 155.48 325.19 155.491 325.292 155.526C325.393 155.56 325.485 155.617 325.561 155.692C325.638 155.767 325.696 155.859 325.731 155.959C325.767 156.06 325.78 156.168 325.768 156.274C325.777 156.381 325.764 156.488 325.729 156.589C325.694 156.69 325.637 156.782 325.563 156.859C325.489 156.937 325.399 156.997 325.3 157.037C325.201 157.076 325.094 157.094 324.987 157.088C324.881 157.092 324.776 157.073 324.677 157.034C324.578 156.995 324.488 156.937 324.413 156.862C324.337 156.788 324.278 156.698 324.237 156.601C324.197 156.503 324.177 156.397 324.179 156.291Z" fill="black" />
            <path d="M313.101 157.779C313.101 157.885 313.079 157.991 313.036 158.089C312.994 158.187 312.932 158.275 312.854 158.348C312.776 158.421 312.684 158.476 312.583 158.512C312.483 158.547 312.376 158.561 312.27 158.554C312.07 158.533 311.886 158.437 311.754 158.287C311.622 158.136 311.551 157.942 311.556 157.741C311.561 157.541 311.641 157.35 311.78 157.206C311.92 157.062 312.109 156.976 312.309 156.965C312.82 156.976 313.061 157.296 313.101 157.779Z" fill="black" />
            <path d="M398.76 67.7635C398.816 67.2526 399.069 66.9494 399.591 66.9662C399.7 66.9572 399.81 66.9707 399.914 67.0061C400.018 67.0414 400.112 67.0981 400.193 67.1723C400.274 67.2464 400.338 67.3368 400.383 67.4367C400.426 67.5372 400.449 67.6456 400.449 67.7551C400.449 67.8646 400.426 67.9729 400.383 68.0734C400.338 68.1739 400.274 68.2638 400.193 68.3379C400.112 68.412 400.018 68.4687 399.914 68.5041C399.81 68.54 399.7 68.5535 399.591 68.5439C399.484 68.5529 399.376 68.5389 399.275 68.5035C399.174 68.4681 399.08 68.4114 399.002 68.3379C398.924 68.2643 398.862 68.1756 398.821 68.0762C398.778 67.9774 398.758 67.8707 398.76 67.7635Z" fill="black" />
            <path d="M356.902 70.2002C356.917 70.3114 356.907 70.4248 356.874 70.5315C356.841 70.6388 356.785 70.7376 356.711 70.8212C356.636 70.9049 356.544 70.9711 356.442 71.0161C356.339 71.0615 356.228 71.084 356.116 71.0818C356.008 71.0935 355.9 71.0806 355.798 71.0447C355.695 71.0088 355.603 70.9504 355.526 70.874C355.45 70.7977 355.392 70.705 355.356 70.6028C355.32 70.5012 355.307 70.3923 355.319 70.2845C355.313 70.1806 355.328 70.0767 355.362 69.9785C355.397 69.8802 355.451 69.7898 355.521 69.7123C355.59 69.6354 355.674 69.572 355.768 69.527C355.862 69.4827 355.964 69.4563 356.068 69.4507C356.172 69.4456 356.276 69.4602 356.374 69.495C356.473 69.5298 356.563 69.5832 356.64 69.6528C356.718 69.7224 356.781 69.8067 356.826 69.901C356.871 69.9947 356.896 70.0964 356.902 70.2002Z" fill="black" />
            <path d="M336.043 74.3832C336.152 74.3709 336.262 74.3827 336.367 74.4169C336.471 74.4512 336.566 74.5079 336.647 74.582C336.727 74.6567 336.79 74.7476 336.833 74.8493C336.875 74.9503 336.895 75.0598 336.891 75.1693C336.903 75.2771 336.89 75.386 336.856 75.4882C336.821 75.591 336.764 75.6847 336.689 75.7628C336.614 75.8408 336.523 75.9015 336.422 75.9408C336.321 75.9801 336.213 75.9964 336.105 75.9891C335.892 75.9975 335.684 75.9206 335.528 75.7757C335.372 75.6308 335.279 75.4298 335.271 75.217C335.263 75.0042 335.34 74.7965 335.484 74.6404C335.629 74.4837 335.83 74.3917 336.043 74.3832Z" fill="black" />
            <path d="M359.534 78.4146C359.034 78.3584 358.731 78.1058 358.742 77.5892C358.742 77.3781 358.826 77.1749 358.976 77.0255C359.125 76.8762 359.328 76.7919 359.54 76.7919C359.751 76.7919 359.954 76.8762 360.104 77.0255C360.253 77.1749 360.337 77.3781 360.337 77.5892C360.34 77.6965 360.321 77.8037 360.283 77.9037C360.243 78.0036 360.185 78.0945 360.11 78.1715C360.035 78.2484 359.946 78.3096 359.846 78.3517C359.748 78.3933 359.641 78.4146 359.534 78.4146Z" fill="black" />
            <path d="M378.417 78.0328C378.355 78.4988 378.147 78.8525 377.625 78.8469C377.518 78.8587 377.41 78.8458 377.309 78.8104C377.207 78.7745 377.115 78.7167 377.038 78.6409C376.962 78.5651 376.904 78.473 376.867 78.3719C376.831 78.2709 376.817 78.1625 376.828 78.0552C376.814 77.948 376.825 77.8391 376.858 77.7363C376.892 77.6336 376.948 77.5398 377.022 77.4607C377.096 77.382 377.186 77.3203 377.287 77.2804C377.387 77.2406 377.495 77.2237 377.603 77.2299C378.114 77.2074 378.361 77.5331 378.417 78.0328Z" fill="black" />
            <path d="M395.677 77.6959C396.211 77.7408 396.514 78.0047 396.503 78.5101C396.509 78.619 396.491 78.7274 396.452 78.829C396.412 78.9306 396.35 79.0221 396.272 79.0979C396.194 79.1732 396.1 79.231 395.997 79.2675C395.895 79.3034 395.786 79.3175 395.677 79.3074C395.569 79.3158 395.462 79.3001 395.361 79.2613C395.26 79.2231 395.169 79.1625 395.094 79.0844C395.02 79.007 394.964 78.9132 394.929 78.811C394.895 78.7088 394.884 78.6005 394.897 78.4932C394.89 78.3882 394.905 78.2827 394.941 78.1838C394.977 78.085 395.034 77.9946 395.108 77.9194C395.181 77.8442 395.271 77.7852 395.368 77.747C395.467 77.7083 395.572 77.6909 395.677 77.6959Z" fill="black" />
            <path d="M364.88 80.318C364.773 80.3242 364.666 80.3068 364.567 80.2664C364.468 80.2259 364.379 80.1636 364.307 80.0844C364.235 80.0058 364.181 79.9115 364.15 79.8088C364.119 79.7066 364.111 79.5982 364.127 79.4927C364.121 79.3854 364.138 79.2782 364.177 79.1782C364.217 79.0788 364.278 78.989 364.357 78.9155C364.435 78.8419 364.528 78.7869 364.631 78.7532C364.733 78.7201 364.841 78.71 364.947 78.7234C365.055 78.7201 365.162 78.7403 365.261 78.7824C365.36 78.8239 365.45 78.8868 365.523 78.966C365.596 79.0457 365.651 79.1395 365.684 79.2422C365.717 79.3444 365.728 79.4528 365.716 79.56C365.721 79.6678 365.701 79.7751 365.66 79.8739C365.618 79.9733 365.555 80.0626 365.476 80.1344C365.395 80.2068 365.301 80.2607 365.198 80.2928C365.095 80.3242 364.986 80.3326 364.88 80.318Z" fill="black" />
            <path d="M334.083 79.7509C334.588 79.7958 334.92 80.0148 334.936 80.537C334.948 80.6397 334.938 80.743 334.907 80.8413C334.875 80.9396 334.824 81.0299 334.755 81.1069C334.685 81.1832 334.601 81.2439 334.506 81.2854C334.412 81.3264 334.309 81.3472 334.207 81.3455C334.099 81.3607 333.99 81.3528 333.887 81.323C333.783 81.2927 333.687 81.2411 333.605 81.1709C333.522 81.1007 333.456 81.0142 333.409 80.9165C333.363 80.8188 333.338 80.7127 333.336 80.6044C333.325 80.4988 333.336 80.3916 333.369 80.2905C333.401 80.1889 333.454 80.0951 333.524 80.0154C333.594 79.9351 333.679 79.8705 333.776 79.825C333.872 79.7796 333.976 79.7543 334.083 79.7509Z" fill="black" />
            <path d="M285.258 81.9294C285.153 81.9294 285.049 81.907 284.953 81.8643C284.857 81.8222 284.771 81.7599 284.7 81.6824C284.629 81.6049 284.575 81.5134 284.542 81.414C284.508 81.3141 284.496 81.2091 284.505 81.1041C284.495 80.9974 284.509 80.8896 284.546 80.7885C284.583 80.688 284.643 80.5965 284.72 80.5218C284.797 80.4471 284.889 80.3904 284.991 80.3562C285.093 80.3219 285.201 80.3107 285.308 80.3236C285.415 80.3141 285.524 80.3281 285.625 80.3652C285.726 80.4022 285.818 80.4617 285.893 80.5381C285.969 80.615 286.027 80.7077 286.063 80.8093C286.098 80.9109 286.111 81.0193 286.1 81.1265C286.104 81.2366 286.085 81.3466 286.044 81.4482C286.002 81.5504 285.94 81.6425 285.86 81.7189C285.78 81.7947 285.685 81.8525 285.581 81.889C285.478 81.9255 285.367 81.9395 285.258 81.9294Z" fill="black" />
            <path d="M376.474 83.4679C376.475 83.5746 376.454 83.6801 376.411 83.7778C376.369 83.8755 376.305 83.9631 376.226 84.0344C376.147 84.1057 376.053 84.1591 375.952 84.1911C375.85 84.2231 375.743 84.2332 375.637 84.2203C375.529 84.227 375.422 84.2102 375.321 84.1703C375.221 84.1304 375.131 84.0687 375.057 83.9895C374.984 83.9109 374.929 83.8166 374.896 83.7138C374.863 83.6105 374.853 83.5021 374.868 83.3949C374.864 83.2871 374.883 83.1793 374.925 83.0799C374.966 82.98 375.029 82.8907 375.109 82.8171C375.188 82.7441 375.282 82.6891 375.386 82.656C375.488 82.6229 375.597 82.6128 375.705 82.6257C375.812 82.6251 375.918 82.6476 376.017 82.6902C376.115 82.7335 376.203 82.7969 376.276 82.8761C376.349 82.9558 376.404 83.049 376.437 83.1512C376.472 83.2534 376.484 83.3612 376.474 83.4679Z" fill="black" />
            <path d="M307.963 83.4679C307.972 83.2595 308.065 83.0636 308.219 82.9227C308.373 82.7823 308.577 82.7087 308.785 82.7183C308.994 82.7284 309.19 82.8205 309.331 82.9743C309.471 83.1287 309.545 83.3325 309.535 83.5408C309.541 83.6475 309.524 83.7548 309.485 83.8541C309.446 83.9535 309.386 84.0439 309.31 84.1186C309.233 84.1927 309.141 84.25 309.041 84.2859C308.94 84.3224 308.833 84.3359 308.726 84.3269C308.232 84.3044 307.974 84.0069 307.963 83.4679Z" fill="black" />
            <path d="M327.644 84.0125C327.641 84.1198 327.616 84.2253 327.572 84.323C327.527 84.4201 327.462 84.5072 327.382 84.579C327.302 84.6503 327.209 84.7048 327.107 84.7385C327.005 84.7727 326.897 84.7857 326.791 84.7761C326.683 84.7823 326.576 84.7643 326.477 84.7239C326.377 84.6835 326.287 84.6217 326.214 84.5431C326.141 84.4645 326.087 84.3702 326.054 84.268C326.022 84.1658 326.013 84.0574 326.027 83.9507C326.017 83.8435 326.031 83.7346 326.07 83.6341C326.108 83.533 326.17 83.4421 326.249 83.3691C326.328 83.2955 326.423 83.2416 326.527 83.2107C326.631 83.1804 326.74 83.1742 326.847 83.1928C326.954 83.1911 327.06 83.2113 327.159 83.2523C327.258 83.2933 327.347 83.3539 327.422 83.4308C327.497 83.5077 327.555 83.5987 327.593 83.6987C327.631 83.7986 327.648 83.9058 327.644 84.0125Z" fill="black" />
            <path d="M313.926 86.2415C313.583 86.2415 313.32 85.9496 313.325 85.4442C313.316 85.337 313.33 85.2286 313.367 85.1276C313.404 85.0265 313.463 84.9344 313.54 84.8592C313.617 84.7834 313.709 84.7256 313.811 84.6896C313.912 84.6542 314.021 84.6413 314.128 84.6526C314.235 84.6475 314.342 84.6655 314.441 84.7048C314.541 84.7441 314.631 84.8047 314.706 84.8816C314.78 84.9586 314.837 85.0506 314.873 85.1517C314.909 85.2528 314.923 85.36 314.914 85.4667C314.903 85.9383 314.628 86.2528 313.926 86.2415Z" fill="black" />
            <path d="M332.275 85.113C332.383 85.1169 332.488 85.1433 332.585 85.1905C332.682 85.2376 332.768 85.305 332.838 85.3875C332.907 85.4701 332.958 85.5661 332.988 85.67C333.018 85.7733 333.025 85.8822 333.01 85.9889C333.009 86.0972 332.983 86.2039 332.937 86.3016C332.89 86.3993 332.823 86.4858 332.74 86.5548C332.657 86.6239 332.559 86.6744 332.455 86.7031C332.35 86.7311 332.241 86.7368 332.134 86.7188C332.027 86.7182 331.92 86.6941 331.823 86.648C331.726 86.602 331.64 86.5346 331.572 86.4521C331.503 86.369 331.454 86.2719 331.427 86.1674C331.4 86.0635 331.396 85.9546 331.416 85.8485C331.455 85.3263 331.792 85.1298 332.275 85.113Z" fill="black" />
            <path d="M325.673 89.3577C325.678 89.4638 325.66 89.57 325.621 89.6693C325.582 89.7682 325.522 89.8574 325.445 89.9316C325.369 90.0051 325.277 90.0618 325.177 90.0977C325.077 90.1331 324.97 90.1472 324.864 90.1382C324.756 90.15 324.648 90.1371 324.546 90.1011C324.444 90.0652 324.351 90.0068 324.274 89.9304C324.198 89.8535 324.14 89.7609 324.104 89.6592C324.068 89.557 324.055 89.4487 324.067 89.3409C324.059 89.2331 324.075 89.1247 324.113 89.0236C324.151 88.9226 324.21 88.8305 324.287 88.7541C324.364 88.6783 324.456 88.6194 324.557 88.5818C324.659 88.5447 324.767 88.5295 324.875 88.538C324.983 88.534 325.09 88.5526 325.19 88.5924C325.291 88.6323 325.381 88.6929 325.456 88.7704C325.532 88.8479 325.589 88.94 325.627 89.0411C325.664 89.1421 325.68 89.2505 325.673 89.3577Z" fill="black" />
            <path d="M300.299 92.3223C300.254 92.7883 300.035 93.1027 299.513 93.114C299.406 93.1297 299.298 93.1207 299.195 93.0887C299.093 93.0567 298.999 93.0022 298.92 92.9287C298.842 92.8557 298.78 92.7653 298.741 92.6653C298.702 92.5654 298.685 92.4576 298.693 92.3504C298.68 92.2437 298.69 92.1353 298.723 92.0331C298.756 91.9309 298.812 91.8366 298.885 91.758C298.958 91.6794 299.048 91.6176 299.148 91.5778C299.247 91.5373 299.355 91.5194 299.463 91.525C299.957 91.5081 300.226 91.8113 300.299 92.3223Z" fill="black" />
            <path d="M384.448 93.5014C384.027 93.4397 383.69 93.2488 383.701 92.7097C383.686 92.6042 383.696 92.4969 383.728 92.3959C383.76 92.2942 383.814 92.201 383.887 92.123C383.96 92.0455 384.049 91.9843 384.147 91.945C384.246 91.9057 384.353 91.8889 384.459 91.8956C384.566 91.8849 384.673 91.8979 384.775 91.9327C384.876 91.9675 384.969 92.0242 385.046 92.0983C385.123 92.1724 385.184 92.2622 385.223 92.3622C385.262 92.4621 385.279 92.5694 385.273 92.6761C385.29 93.1758 385.009 93.4509 384.448 93.5014Z" fill="black" />
            <path d="M336.441 93.288C336.443 93.3947 336.423 93.5008 336.381 93.599C336.34 93.6973 336.278 93.7855 336.2 93.8584C336.122 93.9314 336.03 93.9876 335.93 94.023C335.829 94.0578 335.722 94.0718 335.616 94.0628C335.509 94.0712 335.402 94.0555 335.302 94.0173C335.202 93.9792 335.111 93.9191 335.037 93.8422C334.963 93.7647 334.907 93.672 334.873 93.5704C334.839 93.4688 334.828 93.361 334.841 93.2543C334.832 93.1471 334.847 93.0387 334.885 92.9382C334.923 92.8371 334.982 92.7456 335.06 92.6704C335.137 92.5951 335.23 92.5384 335.332 92.5031C335.434 92.4682 335.542 92.4564 335.65 92.4682C336.161 92.4514 336.402 92.7658 336.441 93.288Z" fill="black" />
            <path d="M310.175 97.0274C310.069 97.0325 309.963 97.0139 309.865 96.9741C309.767 96.9337 309.679 96.8719 309.607 96.7938C309.535 96.7158 309.481 96.6226 309.45 96.521C309.418 96.4199 309.408 96.3127 309.423 96.2077C309.415 96.1004 309.432 95.9926 309.47 95.8927C309.509 95.7922 309.57 95.7018 309.647 95.6277C309.725 95.553 309.818 95.4963 309.92 95.462C310.022 95.4272 310.13 95.4154 310.237 95.4272C310.347 95.4233 310.457 95.4424 310.56 95.4828C310.662 95.5232 310.756 95.5844 310.834 95.6625C310.912 95.7411 310.973 95.8343 311.013 95.937C311.053 96.0404 311.072 96.1504 311.068 96.2605C311.063 96.3711 311.036 96.4794 310.988 96.5788C310.94 96.6782 310.872 96.7669 310.788 96.8388C310.705 96.9106 310.607 96.9645 310.501 96.9971C310.396 97.0297 310.285 97.0398 310.175 97.0274Z" fill="black" />
            <path d="M370.63 99.5597C370.562 100.031 370.315 100.368 369.816 100.357C369.708 100.363 369.602 100.345 369.502 100.305C369.403 100.265 369.314 100.205 369.239 100.128C369.165 100.051 369.109 99.9589 369.074 99.8578C369.038 99.7568 369.025 99.6495 369.035 99.5428C369.024 99.4362 369.037 99.3289 369.074 99.2278C369.11 99.1268 369.169 99.0358 369.246 98.9606C369.322 98.8859 369.415 98.8286 369.516 98.7944C369.618 98.7601 369.726 98.7495 369.832 98.7624C370.349 98.7624 370.59 99.0656 370.63 99.5597Z" fill="black" />
            <path d="M393.251 102.777C392.774 102.71 392.465 102.462 392.482 101.952C392.484 101.847 392.507 101.744 392.549 101.648C392.591 101.552 392.652 101.465 392.727 101.393C392.803 101.32 392.892 101.264 392.99 101.226C393.087 101.187 393.191 101.169 393.296 101.171C393.401 101.173 393.504 101.196 393.6 101.238C393.696 101.281 393.782 101.341 393.855 101.416C393.927 101.492 393.984 101.582 394.022 101.679C394.06 101.776 394.079 101.881 394.077 101.985C394.065 102.491 393.745 102.732 393.251 102.777Z" fill="black" />
            <path d="M312.832 103.518C312.833 103.412 312.856 103.307 312.9 103.211C312.944 103.114 313.008 103.028 313.087 102.958C313.167 102.887 313.26 102.834 313.361 102.802C313.462 102.77 313.569 102.759 313.674 102.771C313.781 102.762 313.889 102.777 313.989 102.814C314.089 102.853 314.18 102.912 314.254 102.99C314.328 103.068 314.383 103.161 314.416 103.263C314.449 103.365 314.458 103.474 314.444 103.58C314.451 103.688 314.435 103.795 314.395 103.896C314.356 103.996 314.295 104.087 314.217 104.161C314.138 104.235 314.044 104.291 313.942 104.324C313.839 104.358 313.73 104.368 313.624 104.355C313.515 104.356 313.408 104.336 313.308 104.294C313.208 104.252 313.118 104.19 313.044 104.111C312.969 104.032 312.912 103.939 312.875 103.837C312.839 103.735 312.824 103.626 312.832 103.518Z" fill="black" />
            <path d="M282.939 103.754C283.416 103.877 283.753 104.119 283.697 104.635C283.695 104.743 283.671 104.85 283.625 104.948C283.579 105.045 283.512 105.132 283.43 105.201C283.347 105.27 283.25 105.321 283.146 105.35C283.042 105.378 282.933 105.383 282.826 105.365C282.719 105.366 282.612 105.343 282.515 105.298C282.417 105.254 282.331 105.188 282.261 105.106C282.191 105.024 282.141 104.928 282.112 104.825C282.084 104.721 282.078 104.612 282.096 104.506C282.136 103.984 282.489 103.804 282.939 103.754Z" fill="black" />
            <path d="M385.947 106.202C385.841 106.207 385.736 106.19 385.637 106.151C385.538 106.113 385.449 106.054 385.374 105.979C385.299 105.904 385.242 105.814 385.204 105.715C385.166 105.616 385.15 105.51 385.155 105.405C385.142 105.298 385.152 105.19 385.186 105.087C385.219 104.985 385.275 104.891 385.349 104.813C385.423 104.735 385.514 104.675 385.614 104.637C385.715 104.598 385.823 104.582 385.93 104.59C386.037 104.579 386.146 104.591 386.248 104.625C386.349 104.66 386.443 104.716 386.521 104.791C386.599 104.865 386.66 104.955 386.699 105.055C386.739 105.156 386.756 105.264 386.75 105.371C386.758 105.48 386.742 105.589 386.705 105.692C386.668 105.794 386.61 105.887 386.534 105.966C386.458 106.045 386.367 106.106 386.266 106.147C386.165 106.187 386.056 106.206 385.947 106.202Z" fill="black" />
            <path d="M270.11 106.864C270.009 106.868 269.908 106.849 269.815 106.81C269.722 106.771 269.639 106.711 269.571 106.636C269.503 106.562 269.452 106.473 269.423 106.377C269.392 106.281 269.383 106.179 269.397 106.078C269.384 105.972 269.396 105.863 269.432 105.761C269.466 105.659 269.524 105.566 269.598 105.489C269.674 105.411 269.765 105.352 269.866 105.314C269.967 105.276 270.075 105.261 270.183 105.27C270.29 105.261 270.398 105.277 270.499 105.314C270.601 105.351 270.693 105.41 270.769 105.487C270.845 105.563 270.904 105.655 270.941 105.756C270.979 105.857 270.994 105.966 270.986 106.073C270.986 106.544 270.711 106.859 270.11 106.864Z" fill="black" />
            <path d="M288.335 107.325C287.807 107.291 287.498 107.067 287.487 106.544C287.473 106.438 287.482 106.329 287.515 106.226C287.548 106.123 287.603 106.029 287.676 105.95C287.75 105.871 287.84 105.809 287.94 105.77C288.041 105.73 288.149 105.712 288.256 105.719C288.364 105.708 288.472 105.72 288.575 105.754C288.677 105.789 288.771 105.845 288.849 105.919C288.927 105.994 288.989 106.083 289.029 106.184C289.069 106.284 289.087 106.392 289.082 106.499C289.09 106.604 289.077 106.708 289.043 106.807C289.01 106.906 288.956 106.996 288.887 107.074C288.817 107.151 288.732 107.214 288.637 107.256C288.542 107.3 288.439 107.323 288.335 107.325Z" fill="black" />
            <path d="M235.882 135.893C235.826 136.454 235.529 136.729 235.046 136.712C234.834 136.706 234.634 136.615 234.489 136.461C234.345 136.307 234.267 136.101 234.274 135.89C234.281 135.679 234.371 135.478 234.525 135.334C234.679 135.189 234.885 135.111 235.096 135.118C235.2 135.113 235.304 135.128 235.401 135.165C235.498 135.202 235.587 135.259 235.661 135.332C235.734 135.405 235.793 135.492 235.831 135.589C235.869 135.685 235.886 135.789 235.882 135.893Z" fill="black" />
            <path d="M306.301 106.202C306.829 106.241 307.132 106.522 307.132 107.01C307.142 107.118 307.127 107.225 307.091 107.327C307.054 107.428 306.996 107.519 306.92 107.595C306.844 107.671 306.752 107.73 306.651 107.766C306.55 107.803 306.442 107.817 306.335 107.808C306.228 107.819 306.12 107.807 306.019 107.771C305.917 107.735 305.826 107.677 305.749 107.602C305.674 107.525 305.616 107.433 305.58 107.332C305.544 107.231 305.532 107.123 305.543 107.016C305.534 106.912 305.548 106.807 305.582 106.708C305.616 106.609 305.671 106.518 305.743 106.442C305.814 106.365 305.9 106.304 305.996 106.263C306.093 106.222 306.196 106.201 306.301 106.202Z" fill="black" />
            <path d="M343.285 107.886C343.246 108.38 342.999 108.695 342.493 108.7C342.283 108.706 342.08 108.627 341.928 108.483C341.776 108.338 341.687 108.138 341.682 107.928C341.677 107.718 341.755 107.515 341.9 107.363C342.045 107.211 342.244 107.123 342.454 107.117C342.56 107.108 342.667 107.121 342.768 107.155C342.869 107.19 342.961 107.245 343.04 107.318C343.118 107.39 343.18 107.478 343.223 107.576C343.265 107.674 343.286 107.78 343.285 107.886Z" fill="black" />
            <path d="M250.991 110.958C250.946 111.491 250.66 111.789 250.166 111.783C249.965 111.766 249.778 111.675 249.642 111.526C249.505 111.378 249.43 111.184 249.43 110.983C249.43 110.781 249.505 110.588 249.642 110.439C249.778 110.291 249.965 110.2 250.166 110.183C250.272 110.171 250.381 110.183 250.482 110.218C250.585 110.251 250.678 110.307 250.756 110.381C250.834 110.454 250.896 110.544 250.937 110.644C250.977 110.743 250.996 110.85 250.991 110.958Z" fill="black" />
            <path d="M292.512 113.827C292.517 113.933 292.499 114.039 292.46 114.139C292.422 114.238 292.363 114.328 292.287 114.403C292.212 114.479 292.121 114.537 292.021 114.575C291.922 114.613 291.815 114.63 291.709 114.624C291.602 114.634 291.494 114.619 291.392 114.582C291.291 114.546 291.199 114.488 291.122 114.412C291.046 114.336 290.987 114.244 290.95 114.143C290.912 114.042 290.897 113.934 290.906 113.827C290.897 113.72 290.911 113.612 290.947 113.512C290.984 113.411 291.043 113.319 291.119 113.244C291.195 113.168 291.287 113.11 291.388 113.074C291.489 113.038 291.597 113.025 291.704 113.035C292.214 113.029 292.467 113.327 292.512 113.827Z" fill="black" />
            <path d="M354.762 123.686C355.217 123.759 355.543 123.989 355.526 124.5C355.536 124.611 355.522 124.723 355.482 124.827C355.444 124.93 355.381 125.024 355.301 125.101C355.22 125.177 355.123 125.234 355.017 125.267C354.911 125.3 354.799 125.309 354.689 125.292C354.582 125.298 354.476 125.281 354.376 125.242C354.276 125.203 354.186 125.144 354.112 125.067C354.037 124.99 353.98 124.898 353.944 124.798C353.908 124.697 353.894 124.59 353.903 124.483C353.937 123.961 354.251 123.72 354.762 123.686Z" fill="black" />
            <path d="M305.841 124.242C306.402 124.242 306.694 124.534 306.711 125.017C306.724 125.125 306.714 125.234 306.68 125.337C306.647 125.44 306.591 125.534 306.517 125.613C306.444 125.693 306.353 125.755 306.253 125.795C306.152 125.835 306.044 125.854 305.936 125.848C305.723 125.861 305.514 125.788 305.354 125.647C305.195 125.505 305.098 125.306 305.085 125.093C305.073 124.88 305.145 124.67 305.287 124.511C305.428 124.351 305.628 124.255 305.841 124.242Z" fill="black" />
            <path d="M238.953 125.977C238.515 125.91 238.172 125.707 238.189 125.174C238.177 125.068 238.189 124.96 238.223 124.859C238.258 124.757 238.315 124.665 238.389 124.588C238.464 124.512 238.555 124.452 238.655 124.415C238.756 124.377 238.863 124.362 238.969 124.371C239.076 124.362 239.183 124.376 239.284 124.413C239.384 124.449 239.476 124.507 239.552 124.582C239.628 124.658 239.686 124.748 239.724 124.849C239.761 124.949 239.775 125.057 239.767 125.163C239.784 125.674 239.469 125.921 238.953 125.977Z" fill="black" />
            <path d="M244.36 127.92C243.866 127.886 243.563 127.65 243.558 127.128C243.544 127.021 243.554 126.913 243.588 126.81C243.621 126.708 243.677 126.615 243.751 126.537C243.825 126.459 243.916 126.398 244.017 126.36C244.117 126.321 244.225 126.305 244.332 126.314C244.44 126.303 244.549 126.316 244.65 126.351C244.752 126.386 244.846 126.443 244.924 126.518C245.002 126.593 245.062 126.683 245.102 126.784C245.141 126.884 245.158 126.992 245.152 127.1C245.159 127.207 245.144 127.314 245.107 127.415C245.07 127.516 245.012 127.608 244.938 127.685C244.864 127.762 244.774 127.823 244.674 127.863C244.574 127.904 244.468 127.923 244.36 127.92Z" fill="black" />
            <path d="M285.066 129.963C285.062 129.859 285.08 129.755 285.119 129.657C285.158 129.56 285.217 129.472 285.292 129.399C285.367 129.325 285.457 129.269 285.555 129.233C285.654 129.196 285.759 129.182 285.864 129.189C285.97 129.175 286.079 129.185 286.182 129.219C286.284 129.253 286.378 129.309 286.455 129.385C286.534 129.459 286.593 129.551 286.631 129.652C286.668 129.753 286.682 129.862 286.672 129.969C286.683 130.076 286.671 130.185 286.635 130.286C286.6 130.388 286.542 130.481 286.468 130.559C286.393 130.636 286.302 130.697 286.202 130.735C286.101 130.774 285.993 130.79 285.886 130.783C285.777 130.791 285.667 130.775 285.564 130.736C285.461 130.698 285.367 130.638 285.29 130.56C285.212 130.482 285.152 130.389 285.114 130.286C285.075 130.183 285.059 130.073 285.066 129.963Z" fill="black" />
            <path d="M278.497 134.197C278.392 134.196 278.288 134.173 278.192 134.13C278.097 134.086 278.011 134.024 277.941 133.946C277.871 133.867 277.817 133.776 277.785 133.676C277.752 133.576 277.74 133.47 277.751 133.366C277.74 133.259 277.755 133.151 277.793 133.051C277.831 132.95 277.892 132.86 277.971 132.786C278.049 132.713 278.144 132.659 278.247 132.628C278.35 132.597 278.459 132.591 278.565 132.608C278.672 132.601 278.78 132.617 278.88 132.656C278.981 132.695 279.072 132.755 279.146 132.833C279.221 132.91 279.278 133.003 279.314 133.105C279.349 133.207 279.361 133.315 279.351 133.422C279.345 133.916 279.031 134.186 278.497 134.197Z" fill="black" />
            <path d="M248.571 134.433C248.577 134.539 248.561 134.646 248.524 134.746C248.487 134.845 248.429 134.936 248.354 135.013C248.28 135.089 248.19 135.149 248.091 135.188C247.991 135.227 247.885 135.246 247.779 135.241C247.669 135.253 247.559 135.241 247.454 135.208C247.349 135.174 247.252 135.119 247.17 135.046C247.087 134.973 247.021 134.884 246.974 134.784C246.928 134.684 246.903 134.575 246.901 134.465C246.898 134.355 246.919 134.246 246.961 134.144C247.003 134.042 247.066 133.95 247.145 133.874C247.224 133.797 247.318 133.738 247.422 133.7C247.525 133.662 247.636 133.646 247.746 133.652C247.852 133.642 247.961 133.655 248.063 133.689C248.164 133.724 248.258 133.78 248.336 133.854C248.414 133.929 248.475 134.018 248.516 134.118C248.557 134.218 248.575 134.326 248.571 134.433Z" fill="black" />
            <path d="M333.578 134.775C333.511 135.252 333.28 135.589 332.769 135.578C332.555 135.578 332.349 135.493 332.198 135.342C332.046 135.19 331.961 134.984 331.961 134.77C331.961 134.556 332.046 134.35 332.198 134.198C332.349 134.046 332.555 133.961 332.769 133.961C333.264 133.961 333.511 134.275 333.578 134.775Z" fill="black" />
            <path d="M351.652 135.236C351.655 135.345 351.636 135.454 351.594 135.555C351.553 135.656 351.491 135.748 351.412 135.823C351.333 135.899 351.239 135.958 351.137 135.995C351.034 136.032 350.924 136.047 350.815 136.039C350.705 136.044 350.594 136.027 350.49 135.988C350.387 135.949 350.293 135.889 350.213 135.811C350.134 135.734 350.071 135.64 350.03 135.538C349.989 135.436 349.969 135.325 349.972 135.214C349.975 135.104 350.001 134.995 350.048 134.895C350.096 134.794 350.163 134.705 350.247 134.632C350.33 134.559 350.427 134.505 350.533 134.471C350.638 134.438 350.75 134.427 350.86 134.438C350.966 134.433 351.072 134.45 351.171 134.488C351.27 134.526 351.36 134.584 351.435 134.66C351.509 134.735 351.568 134.825 351.605 134.925C351.642 135.024 351.658 135.13 351.652 135.236Z" fill="black" />
            <path d="M337.34 136.684C337.362 136.483 337.46 136.298 337.613 136.165C337.766 136.033 337.964 135.964 338.166 135.971C338.368 135.978 338.56 136.061 338.704 136.204C338.848 136.347 338.932 136.538 338.94 136.74C338.945 136.851 338.926 136.961 338.886 137.064C338.846 137.167 338.785 137.261 338.707 137.339C338.629 137.418 338.536 137.479 338.433 137.52C338.33 137.561 338.22 137.58 338.11 137.576C337.999 137.572 337.891 137.545 337.791 137.497C337.691 137.45 337.602 137.382 337.53 137.298C337.458 137.214 337.403 137.117 337.371 137.011C337.338 136.905 337.328 136.794 337.34 136.684Z" fill="black" />
            <path d="M295.488 139.233C295.491 139.341 295.472 139.448 295.431 139.548C295.389 139.647 295.328 139.737 295.249 139.81C295.17 139.883 295.076 139.94 294.974 139.973C294.872 140.008 294.764 140.019 294.657 140.008C294.549 140.017 294.442 140.001 294.341 139.962C294.241 139.924 294.15 139.863 294.075 139.785C294.001 139.707 293.945 139.614 293.912 139.512C293.878 139.409 293.868 139.301 293.882 139.194C293.876 139.086 293.894 138.978 293.935 138.877C293.974 138.777 294.036 138.686 294.114 138.612C294.193 138.537 294.286 138.48 294.388 138.445C294.491 138.41 294.599 138.397 294.707 138.408C294.814 138.407 294.919 138.428 295.018 138.47C295.116 138.511 295.204 138.573 295.278 138.65C295.351 138.728 295.407 138.819 295.443 138.92C295.479 139.021 295.494 139.127 295.488 139.233Z" fill="black" />
            <path d="M258.969 121.047C258.756 121.041 258.554 120.951 258.408 120.796C258.262 120.641 258.183 120.435 258.189 120.222C258.195 120.009 258.285 119.807 258.44 119.661C258.595 119.514 258.801 119.435 259.014 119.441C259.12 119.436 259.225 119.454 259.324 119.493C259.422 119.531 259.511 119.59 259.585 119.665C259.659 119.74 259.717 119.83 259.754 119.929C259.791 120.028 259.806 120.133 259.8 120.239C259.806 120.348 259.788 120.458 259.749 120.56C259.709 120.663 259.648 120.755 259.568 120.832C259.49 120.909 259.395 120.967 259.292 121.005C259.189 121.042 259.079 121.056 258.969 121.047Z" fill="black" />
            <path d="M300.524 122.305C300.737 122.305 300.941 122.39 301.092 122.54C301.242 122.691 301.327 122.895 301.327 123.108C301.327 123.321 301.242 123.525 301.092 123.675C300.941 123.826 300.737 123.911 300.524 123.911C300.417 123.922 300.309 123.909 300.206 123.874C300.105 123.838 300.012 123.78 299.936 123.705C299.859 123.629 299.8 123.537 299.763 123.436C299.726 123.335 299.711 123.226 299.721 123.119C299.711 123.011 299.725 122.901 299.761 122.798C299.798 122.696 299.857 122.603 299.933 122.525C300.009 122.448 300.102 122.387 300.204 122.349C300.306 122.312 300.415 122.297 300.524 122.305Z" fill="black" />
            <path d="M368.811 69.5714C368.598 69.5714 368.394 69.4872 368.243 69.3367C368.092 69.1857 368.008 68.9818 368.008 68.7685C368.008 68.5557 368.092 68.3513 368.243 68.2008C368.394 68.0504 368.598 67.9656 368.811 67.9656C368.918 67.9583 369.026 67.9734 369.127 68.0116C369.227 68.0498 369.319 68.1093 369.394 68.1862C369.469 68.2637 369.527 68.3558 369.563 68.4574C369.599 68.5591 369.613 68.6669 369.602 68.7741C369.613 68.8813 369.599 68.9891 369.563 69.0897C369.527 69.1907 369.469 69.2828 369.394 69.3592C369.318 69.435 369.227 69.4933 369.126 69.5304C369.025 69.5669 368.917 69.5815 368.811 69.5714Z" fill="black" />
            <path d="M389.646 64.1251C389.409 64.3458 389.133 64.5187 388.832 64.636C388.72 64.6473 388.607 64.6321 388.502 64.5922C388.397 64.5529 388.302 64.4901 388.225 64.4081C388.148 64.3261 388.091 64.2279 388.058 64.1201C388.025 64.0128 388.017 63.8994 388.035 63.7882C388.033 63.6866 388.053 63.585 388.094 63.4918C388.135 63.3986 388.196 63.3149 388.272 63.2475C388.349 63.1796 388.438 63.1291 388.536 63.0993C388.634 63.0695 388.736 63.0617 388.838 63.0752C389.348 63.0976 389.59 63.412 389.646 64.1251Z" fill="black" />
            <path d="M351.995 129.874C351.989 129.661 352.068 129.454 352.214 129.299C352.361 129.144 352.563 129.054 352.776 129.048C352.988 129.042 353.195 129.121 353.35 129.268C353.504 129.414 353.595 129.616 353.601 129.829C353.61 129.936 353.595 130.045 353.559 130.146C353.523 130.249 353.466 130.342 353.391 130.42C353.316 130.497 353.225 130.559 353.125 130.6C353.025 130.64 352.917 130.658 352.809 130.654C352.703 130.664 352.595 130.651 352.494 130.615C352.393 130.58 352.301 130.523 352.224 130.45C352.147 130.376 352.087 130.286 352.047 130.186C352.007 130.087 351.99 129.98 351.995 129.874Z" fill="black" />
            <path d="M386.087 69.1784C386.084 69.0728 386.106 68.9672 386.148 68.8707C386.192 68.7735 386.255 68.6871 386.334 68.6169C386.414 68.5467 386.508 68.4951 386.609 68.4653C386.712 68.435 386.818 68.4277 386.923 68.4428C387.03 68.4355 387.137 68.4518 387.237 68.4906C387.337 68.5299 387.427 68.5905 387.5 68.668C387.574 68.746 387.63 68.8392 387.663 68.9409C387.696 69.043 387.706 69.1508 387.692 69.257C387.698 69.3653 387.678 69.4737 387.637 69.5742C387.596 69.6747 387.534 69.7651 387.454 69.8387C387.374 69.9122 387.279 69.9678 387.176 70.0015C387.073 70.0346 386.964 70.0453 386.856 70.0318C386.748 70.0324 386.64 70.0105 386.541 69.9667C386.442 69.9229 386.352 69.8583 386.28 69.778C386.207 69.6972 386.152 69.6023 386.119 69.499C386.086 69.3957 386.075 69.2862 386.087 69.1784Z" fill="black" />
            <path d="M320.299 87.3645C320.249 87.9259 319.962 88.2348 319.468 88.2235C319.36 88.2263 319.251 88.2061 319.151 88.1646C319.051 88.123 318.961 88.0607 318.886 87.9815C318.811 87.9029 318.754 87.8092 318.718 87.707C318.682 87.6042 318.667 87.4953 318.676 87.3869C318.671 87.2786 318.689 87.1708 318.729 87.0697C318.77 86.9692 318.832 86.8788 318.911 86.8041C318.99 86.73 319.085 86.6739 319.187 86.6396C319.29 86.6054 319.4 86.5941 319.507 86.6065C319.61 86.6031 319.711 86.62 319.807 86.6559C319.903 86.6924 319.99 86.7474 320.063 86.8182C320.137 86.8889 320.196 86.9737 320.237 87.0675C320.277 87.1612 320.299 87.2623 320.299 87.3645Z" fill="black" />
            <path d="M380.517 102.631C380.622 102.627 380.727 102.644 380.827 102.68C380.926 102.717 381.016 102.772 381.093 102.844C381.171 102.916 381.234 103.002 381.278 103.098C381.322 103.194 381.347 103.297 381.351 103.403C381.354 103.509 381.338 103.614 381.301 103.713C381.265 103.812 381.209 103.903 381.137 103.98C381.065 104.057 380.979 104.12 380.883 104.164C380.787 104.208 380.684 104.233 380.579 104.237C380.366 104.245 380.158 104.168 380.002 104.023C379.845 103.879 379.753 103.678 379.745 103.465C379.736 103.252 379.813 103.044 379.958 102.888C380.103 102.731 380.304 102.639 380.517 102.631Z" fill="black" />
            <path d="M364.234 66.8595C364.15 67.3817 363.875 67.668 363.364 67.6287C363.256 67.6276 363.149 67.6029 363.052 67.5574C362.953 67.5119 362.866 67.4462 362.796 67.3643C362.726 67.2829 362.673 67.1868 362.642 67.0835C362.612 66.9802 362.603 66.8713 362.617 66.7641C362.615 66.6563 362.638 66.549 362.682 66.4502C362.727 66.3519 362.792 66.2643 362.874 66.1936C362.956 66.1229 363.052 66.0712 363.156 66.0414C363.26 66.0122 363.37 66.0061 363.476 66.0229C363.582 66.0257 363.686 66.0499 363.781 66.0937C363.877 66.138 363.963 66.2009 364.034 66.2789C364.105 66.3576 364.159 66.4491 364.194 66.549C364.228 66.6484 364.242 66.7545 364.234 66.8595Z" fill="black" />
            <path d="M389.141 82.0024C389.128 82.159 389.068 82.3078 388.971 82.4302C388.874 82.5532 388.742 82.6442 388.593 82.6924C388.443 82.7407 388.283 82.7441 388.132 82.7014C387.981 82.6593 387.846 82.5734 387.744 82.4549C387.641 82.3365 387.576 82.1899 387.556 82.0344C387.536 81.8789 387.563 81.7211 387.632 81.5807C387.702 81.4398 387.811 81.323 387.947 81.2444C388.082 81.1658 388.238 81.1287 388.395 81.1377C388.894 81.1658 389.141 81.4971 389.141 82.0024Z" fill="black" />
            <path d="M330.276 90.4807C330.435 90.4852 330.589 90.5368 330.718 90.6284C330.848 90.7204 330.947 90.8485 331.004 90.9972C331.06 91.1455 331.072 91.3072 331.036 91.4621C331.001 91.6166 330.92 91.7575 330.805 91.867C330.69 91.9759 330.545 92.0483 330.388 92.0747C330.231 92.1017 330.071 92.0809 329.926 92.0163C329.781 91.9517 329.658 91.8451 329.574 91.7109C329.489 91.5761 329.446 91.42 329.451 91.2611C329.446 91.1533 329.465 91.0461 329.505 90.9467C329.546 90.8468 329.608 90.7569 329.686 90.6828C329.764 90.6087 329.857 90.5526 329.959 90.5177C330.061 90.4829 330.169 90.47 330.276 90.4807Z" fill="black" />
            <path d="M286.353 112.693C286.245 112.703 286.136 112.69 286.033 112.655C285.931 112.62 285.837 112.563 285.758 112.489C285.68 112.414 285.617 112.324 285.577 112.223C285.536 112.123 285.517 112.015 285.522 111.906C285.51 111.799 285.522 111.69 285.557 111.588C285.591 111.486 285.648 111.392 285.722 111.314C285.796 111.235 285.886 111.174 285.986 111.134C286.087 111.093 286.195 111.075 286.302 111.081C286.41 111.066 286.519 111.076 286.622 111.109C286.725 111.142 286.819 111.198 286.898 111.273C286.976 111.347 287.037 111.438 287.076 111.539C287.115 111.64 287.131 111.748 287.122 111.856C287.132 111.963 287.12 112.07 287.086 112.172C287.052 112.274 286.996 112.367 286.924 112.446C286.852 112.525 286.763 112.588 286.665 112.63C286.566 112.672 286.46 112.694 286.353 112.693Z" fill="black" />
            <path d="M347.39 128.717C347.285 128.721 347.179 128.702 347.081 128.662C346.984 128.622 346.895 128.562 346.822 128.486C346.749 128.409 346.693 128.319 346.657 128.219C346.621 128.12 346.607 128.014 346.615 127.908C346.601 127.801 346.612 127.692 346.647 127.59C346.682 127.488 346.74 127.395 346.816 127.318C346.893 127.242 346.986 127.184 347.088 127.149C347.191 127.114 347.3 127.103 347.407 127.117C347.515 127.109 347.623 127.124 347.725 127.161C347.827 127.198 347.92 127.257 347.996 127.333C348.074 127.409 348.133 127.501 348.172 127.602C348.211 127.703 348.228 127.812 348.221 127.92C348.225 128.029 348.206 128.137 348.165 128.238C348.125 128.339 348.063 128.43 347.985 128.506C347.906 128.581 347.813 128.639 347.71 128.675C347.607 128.712 347.498 128.726 347.39 128.717Z" fill="black" />
            <path d="M268.29 110.632C268.733 110.727 269.065 110.952 269.031 111.48C269.037 111.582 269.021 111.684 268.984 111.78C268.947 111.874 268.891 111.961 268.818 112.033C268.745 112.105 268.658 112.16 268.562 112.196C268.466 112.23 268.363 112.245 268.262 112.238C268.154 112.247 268.045 112.233 267.943 112.196C267.842 112.159 267.75 112.1 267.673 112.024C267.597 111.947 267.538 111.855 267.5 111.753C267.463 111.652 267.449 111.543 267.459 111.435C267.453 110.924 267.762 110.694 268.29 110.632Z" fill="black" />
            <path d="M332.545 104.08C332.542 104.186 332.517 104.291 332.47 104.388C332.424 104.484 332.358 104.569 332.276 104.638C332.194 104.707 332.099 104.758 331.997 104.788C331.894 104.817 331.786 104.825 331.68 104.809C331.573 104.812 331.465 104.79 331.367 104.747C331.268 104.703 331.18 104.638 331.11 104.556C331.039 104.475 330.987 104.379 330.958 104.275C330.928 104.172 330.922 104.063 330.939 103.956C330.939 103.848 330.962 103.742 331.006 103.644C331.051 103.546 331.116 103.458 331.198 103.388C331.28 103.318 331.376 103.265 331.479 103.236C331.583 103.206 331.692 103.199 331.798 103.215C332.315 103.254 332.528 103.585 332.545 104.08Z" fill="black" />
            <path d="M274.432 113.4C274.434 113.506 274.414 113.611 274.373 113.708C274.331 113.805 274.27 113.893 274.193 113.965C274.115 114.037 274.024 114.092 273.924 114.126C273.824 114.16 273.718 114.173 273.613 114.164C273.505 114.173 273.398 114.159 273.297 114.123C273.197 114.086 273.105 114.027 273.029 113.951C272.953 113.875 272.896 113.783 272.86 113.682C272.824 113.581 272.81 113.474 272.821 113.366C272.812 113.258 272.827 113.15 272.865 113.048C272.902 112.947 272.962 112.855 273.039 112.78C273.116 112.703 273.209 112.645 273.311 112.609C273.413 112.572 273.522 112.559 273.629 112.569C273.738 112.565 273.847 112.584 273.948 112.625C274.049 112.665 274.141 112.727 274.217 112.805C274.293 112.883 274.351 112.977 274.388 113.079C274.425 113.182 274.44 113.292 274.432 113.4Z" fill="black" />
            <path d="M325.234 107.471C325.235 107.576 325.215 107.681 325.174 107.779C325.133 107.876 325.071 107.964 324.994 108.036C324.917 108.107 324.825 108.163 324.725 108.197C324.625 108.231 324.519 108.244 324.414 108.234C324.306 108.244 324.198 108.23 324.097 108.193C323.996 108.156 323.904 108.097 323.828 108.02C323.752 107.944 323.695 107.851 323.659 107.749C323.623 107.647 323.611 107.539 323.622 107.431C323.614 107.324 323.63 107.216 323.668 107.115C323.706 107.015 323.766 106.923 323.843 106.848C323.92 106.773 324.012 106.715 324.113 106.679C324.215 106.643 324.323 106.63 324.431 106.64C324.54 106.635 324.649 106.653 324.75 106.693C324.852 106.734 324.944 106.795 325.02 106.874C325.096 106.953 325.154 107.046 325.191 107.149C325.228 107.252 325.243 107.362 325.234 107.471Z" fill="black" />
            <path d="M338.968 101.373C338.755 101.36 338.556 101.263 338.415 101.103C338.274 100.943 338.202 100.733 338.215 100.52C338.229 100.307 338.326 100.108 338.487 99.9673C338.647 99.8258 338.856 99.7545 339.069 99.7674C339.177 99.7652 339.284 99.786 339.383 99.8286C339.482 99.8713 339.57 99.9347 339.643 100.014C339.715 100.094 339.77 100.189 339.803 100.292C339.835 100.394 339.845 100.503 339.833 100.61C339.833 100.719 339.81 100.827 339.765 100.926C339.72 101.026 339.655 101.114 339.573 101.187C339.491 101.259 339.395 101.313 339.29 101.345C339.186 101.377 339.076 101.387 338.968 101.373Z" fill="black" />
            <path d="M341.214 125.91C341.212 125.804 341.234 125.7 341.278 125.603C341.321 125.506 341.384 125.42 341.463 125.35C341.543 125.281 341.636 125.228 341.737 125.198C341.839 125.168 341.945 125.159 342.05 125.174C342.157 125.167 342.264 125.183 342.364 125.222C342.464 125.261 342.554 125.321 342.627 125.399C342.701 125.477 342.756 125.57 342.79 125.672C342.823 125.774 342.833 125.882 342.819 125.988C342.824 126.096 342.805 126.204 342.764 126.304C342.724 126.404 342.662 126.493 342.582 126.568C342.504 126.641 342.409 126.697 342.307 126.73C342.205 126.764 342.096 126.775 341.988 126.763C341.879 126.765 341.771 126.743 341.671 126.7C341.571 126.656 341.482 126.592 341.408 126.511C341.335 126.431 341.28 126.335 341.247 126.231C341.213 126.127 341.202 126.018 341.214 125.91Z" fill="black" />
            <path d="M316.291 110.834C316.287 110.726 316.306 110.62 316.347 110.52C316.388 110.42 316.451 110.331 316.529 110.257C316.608 110.184 316.702 110.128 316.804 110.094C316.906 110.06 317.014 110.048 317.122 110.059C317.278 110.066 317.43 110.119 317.557 110.211C317.684 110.304 317.781 110.432 317.835 110.58C317.89 110.727 317.899 110.887 317.863 111.04C317.827 111.193 317.746 111.332 317.631 111.439C317.516 111.546 317.372 111.617 317.217 111.643C317.062 111.668 316.903 111.647 316.759 111.582C316.616 111.518 316.495 111.412 316.412 111.279C316.328 111.146 316.286 110.991 316.291 110.834Z" fill="black" />
            <path d="M302.08 99.6832C302.125 99.206 302.355 98.8803 302.866 98.8691C302.973 98.855 303.082 98.8646 303.185 98.8971C303.287 98.9297 303.382 98.9853 303.461 99.0589C303.539 99.1324 303.601 99.2222 303.641 99.3227C303.681 99.4227 303.698 99.5305 303.692 99.6383C303.704 99.745 303.693 99.8533 303.659 99.9555C303.626 100.058 303.57 100.151 303.497 100.231C303.424 100.309 303.335 100.372 303.236 100.413C303.137 100.454 303.03 100.473 302.922 100.469C302.423 100.492 302.159 100.172 302.08 99.6832Z" fill="black" />
            <path d="M314.824 98.1897C314.836 97.987 314.924 97.7961 315.07 97.6557C315.217 97.5148 315.411 97.4339 315.614 97.43C315.817 97.4255 316.014 97.4979 316.166 97.6327C316.319 97.7675 316.415 97.9539 316.435 98.156C316.444 98.2644 316.43 98.3733 316.393 98.4755C316.357 98.5782 316.299 98.6714 316.224 98.7495C316.148 98.8275 316.057 98.8887 315.956 98.9286C315.855 98.9684 315.746 98.987 315.638 98.9814C315.53 98.9915 315.422 98.978 315.32 98.9421C315.219 98.9061 315.126 98.8489 315.049 98.7736C314.971 98.6984 314.911 98.6069 314.872 98.5064C314.833 98.4053 314.817 98.2975 314.824 98.1897Z" fill="black" />
            <path d="M284.617 148.015C284.612 147.907 284.63 147.801 284.67 147.701C284.709 147.601 284.77 147.512 284.848 147.438C284.926 147.365 285.019 147.309 285.121 147.275C285.223 147.24 285.33 147.229 285.437 147.24C285.545 147.23 285.653 147.244 285.755 147.281C285.856 147.319 285.948 147.379 286.023 147.456C286.099 147.534 286.156 147.627 286.191 147.73C286.225 147.832 286.236 147.941 286.223 148.048C286.232 148.159 286.218 148.27 286.18 148.374C286.142 148.477 286.082 148.572 286.004 148.651C285.926 148.729 285.832 148.79 285.728 148.828C285.625 148.867 285.513 148.882 285.403 148.874C285.292 148.879 285.182 148.86 285.079 148.817C284.976 148.775 284.884 148.71 284.809 148.628C284.734 148.546 284.678 148.449 284.645 148.343C284.612 148.237 284.602 148.125 284.617 148.015Z" fill="black" />
            <path d="M382.201 79.9699C382.195 79.8638 382.212 79.7571 382.251 79.6583C382.29 79.5589 382.35 79.4696 382.427 79.3961C382.504 79.3225 382.596 79.2664 382.696 79.2316C382.797 79.1967 382.904 79.1844 383.01 79.1951C383.118 79.1866 383.226 79.2018 383.328 79.2394C383.429 79.277 383.521 79.3366 383.597 79.4135C383.673 79.4904 383.731 79.5836 383.767 79.6852C383.804 79.7874 383.817 79.8958 383.807 80.0036C383.805 80.1091 383.782 80.213 383.739 80.3096C383.697 80.4062 383.636 80.4938 383.56 80.5667C383.484 80.6397 383.394 80.697 383.296 80.7352C383.197 80.7734 383.093 80.7919 382.988 80.7896C382.882 80.7874 382.778 80.7644 382.681 80.7223C382.585 80.6796 382.497 80.619 382.424 80.5426C382.351 80.4668 382.294 80.377 382.256 80.2787C382.218 80.1804 382.199 80.0755 382.201 79.9699Z" fill="black" />
            <path d="M398.979 121.99C398.986 122.095 398.971 122.199 398.935 122.298C398.898 122.395 398.842 122.485 398.769 122.559C398.695 122.633 398.607 122.691 398.51 122.729C398.412 122.766 398.308 122.783 398.204 122.777C398.097 122.788 397.989 122.776 397.888 122.74C397.786 122.704 397.695 122.646 397.618 122.57C397.542 122.495 397.485 122.403 397.449 122.301C397.413 122.2 397.4 122.092 397.412 121.985C397.4 121.878 397.41 121.77 397.445 121.667C397.478 121.565 397.534 121.471 397.608 121.393C397.682 121.314 397.772 121.253 397.871 121.212C397.972 121.172 398.079 121.154 398.187 121.16C398.687 121.143 398.956 121.44 398.979 121.99Z" fill="black" />
            <path d="M370.231 80.6717C370.341 80.6594 370.451 80.67 370.557 80.7032C370.662 80.7369 370.758 80.7924 370.84 80.8666C370.922 80.9401 370.987 81.0305 371.031 81.1316C371.075 81.2326 371.097 81.3421 371.096 81.4522C371.107 81.56 371.096 81.6695 371.063 81.7722C371.029 81.8755 370.974 81.9704 370.9 82.0501C370.827 82.1299 370.737 82.1933 370.637 82.2354C370.537 82.2775 370.429 82.2978 370.321 82.2944C370.213 82.3039 370.104 82.2905 370.001 82.2551C369.899 82.2197 369.805 82.163 369.726 82.0889C369.648 82.0148 369.585 81.9244 369.543 81.8244C369.502 81.7245 369.481 81.6167 369.484 81.5083C369.472 81.4033 369.483 81.2967 369.515 81.1961C369.547 81.0951 369.6 81.0024 369.671 80.9233C369.741 80.8441 369.827 80.7812 369.924 80.738C370.02 80.6947 370.125 80.6723 370.231 80.6717Z" fill="black" />
            <path d="M257.499 108.347C257.391 108.352 257.283 108.334 257.182 108.294C257.082 108.255 256.991 108.194 256.915 108.117C256.84 108.039 256.782 107.946 256.745 107.845C256.708 107.743 256.693 107.635 256.701 107.527C256.694 107.419 256.711 107.311 256.75 107.211C256.789 107.11 256.849 107.019 256.926 106.944C257.004 106.868 257.096 106.811 257.198 106.775C257.299 106.739 257.408 106.725 257.516 106.735C257.623 106.727 257.731 106.743 257.831 106.781C257.932 106.82 258.023 106.88 258.097 106.958C258.171 107.036 258.226 107.129 258.26 107.232C258.294 107.334 258.304 107.443 258.29 107.549C258.298 107.656 258.283 107.762 258.246 107.862C258.208 107.962 258.15 108.052 258.075 108.128C258 108.203 257.91 108.262 257.811 108.3C257.711 108.337 257.605 108.353 257.499 108.347Z" fill="black" />
            <path d="M366.739 110.211C366.739 110.817 366.43 111.104 365.953 111.109C365.845 111.118 365.737 111.102 365.635 111.065C365.534 111.029 365.442 110.97 365.365 110.895C365.288 110.819 365.228 110.728 365.188 110.628C365.15 110.527 365.132 110.42 365.138 110.312C365.129 110.206 365.143 110.1 365.179 110C365.215 109.901 365.272 109.81 365.346 109.734C365.42 109.658 365.509 109.6 365.608 109.562C365.707 109.524 365.813 109.508 365.919 109.515C366.02 109.498 366.123 109.504 366.222 109.532C366.32 109.56 366.411 109.608 366.489 109.674C366.567 109.74 366.63 109.822 366.673 109.915C366.716 110.008 366.738 110.109 366.739 110.211Z" fill="black" />
            <path d="M291.355 95.7192C291.355 95.6137 291.377 95.5092 291.421 95.4127C291.465 95.3166 291.529 95.2307 291.609 95.1617C291.689 95.0921 291.783 95.041 291.884 95.0112C291.986 94.9814 292.092 94.9741 292.197 94.9893C292.304 94.9826 292.411 95 292.511 95.0393C292.611 95.0791 292.7 95.1403 292.773 95.2189C292.846 95.297 292.901 95.3908 292.934 95.4929C292.966 95.5951 292.975 95.7029 292.961 95.8091C292.965 95.9174 292.945 96.0247 292.904 96.1246C292.862 96.2245 292.799 96.3138 292.72 96.3868C292.64 96.4604 292.546 96.5148 292.443 96.548C292.34 96.5811 292.232 96.5917 292.124 96.5783C292.016 96.5783 291.908 96.5553 291.809 96.5109C291.71 96.4665 291.621 96.4014 291.548 96.3206C291.475 96.2397 291.421 96.1442 291.387 96.0404C291.354 95.9371 291.343 95.8276 291.355 95.7192Z" fill="black" />
            <path d="M315.184 115.432C315.29 115.431 315.395 115.452 315.493 115.495C315.59 115.537 315.677 115.601 315.748 115.68C315.819 115.759 315.872 115.853 315.904 115.955C315.935 116.056 315.944 116.164 315.93 116.269C315.937 116.376 315.92 116.484 315.88 116.583C315.841 116.683 315.779 116.773 315.701 116.846C315.623 116.92 315.53 116.975 315.427 117.009C315.325 117.042 315.217 117.052 315.111 117.038C315.002 117.042 314.895 117.023 314.795 116.982C314.695 116.94 314.605 116.877 314.531 116.798C314.458 116.718 314.402 116.624 314.368 116.521C314.335 116.418 314.324 116.309 314.336 116.202C314.335 116.094 314.357 115.987 314.401 115.888C314.444 115.789 314.508 115.701 314.588 115.628C314.668 115.555 314.763 115.5 314.865 115.467C314.968 115.433 315.076 115.422 315.184 115.432Z" fill="black" />
            <path d="M324.319 143.613C324.32 143.722 324.298 143.831 324.255 143.931C324.212 144.032 324.148 144.122 324.067 144.196C323.986 144.27 323.892 144.326 323.788 144.362C323.684 144.397 323.574 144.409 323.465 144.399C323.358 144.401 323.251 144.379 323.152 144.336C323.054 144.293 322.966 144.229 322.894 144.148C322.823 144.068 322.77 143.973 322.738 143.869C322.707 143.767 322.698 143.658 322.713 143.551C322.709 143.444 322.729 143.337 322.772 143.237C322.814 143.138 322.878 143.05 322.958 142.978C323.038 142.906 323.134 142.853 323.237 142.822C323.34 142.791 323.449 142.783 323.555 142.799C323.66 142.797 323.765 142.817 323.862 142.858C323.958 142.899 324.046 142.96 324.118 143.037C324.19 143.113 324.245 143.204 324.28 143.303C324.314 143.403 324.327 143.508 324.319 143.613Z" fill="black" />
            <path d="M299.019 111.193C298.914 111.2 298.809 111.183 298.711 111.145C298.613 111.107 298.524 111.049 298.45 110.974C298.376 110.899 298.319 110.809 298.283 110.711C298.246 110.612 298.231 110.507 298.239 110.402C298.226 110.295 298.237 110.187 298.271 110.084C298.305 109.983 298.362 109.89 298.437 109.812C298.511 109.735 298.602 109.675 298.703 109.637C298.804 109.599 298.912 109.584 299.019 109.593C299.126 109.583 299.235 109.595 299.336 109.63C299.438 109.666 299.531 109.723 299.609 109.798C299.686 109.872 299.746 109.963 299.785 110.064C299.824 110.164 299.84 110.272 299.833 110.379C299.842 110.489 299.826 110.598 299.788 110.701C299.751 110.803 299.691 110.896 299.614 110.974C299.536 111.051 299.443 111.111 299.34 111.149C299.237 111.187 299.128 111.202 299.019 111.193Z" fill="black" />
            <path d="M280.934 109.133C281.039 109.127 281.145 109.142 281.244 109.179C281.343 109.216 281.433 109.274 281.508 109.348C281.583 109.422 281.642 109.511 281.68 109.61C281.719 109.708 281.736 109.813 281.732 109.919C281.746 110.026 281.736 110.134 281.703 110.238C281.67 110.341 281.615 110.435 281.542 110.513C281.468 110.593 281.378 110.654 281.278 110.694C281.178 110.734 281.07 110.751 280.962 110.744C280.855 110.757 280.747 110.746 280.645 110.712C280.542 110.678 280.448 110.622 280.369 110.548C280.291 110.475 280.23 110.385 280.189 110.285C280.149 110.185 280.131 110.077 280.137 109.969C280.127 109.86 280.141 109.751 280.177 109.648C280.213 109.544 280.271 109.45 280.346 109.371C280.422 109.292 280.513 109.229 280.614 109.188C280.716 109.147 280.825 109.128 280.934 109.133Z" fill="black" />
            <path d="M274.87 95.3655C274.871 95.4716 274.849 95.5766 274.806 95.6737C274.763 95.7709 274.7 95.8573 274.62 95.9281C274.541 95.9988 274.448 96.0516 274.347 96.0836C274.246 96.1151 274.139 96.1252 274.034 96.1123C273.926 96.119 273.818 96.1021 273.718 96.0623C273.618 96.0219 273.527 95.9607 273.454 95.8815C273.38 95.8023 273.325 95.7086 273.293 95.6058C273.259 95.5025 273.25 95.3941 273.264 95.2869C273.261 95.1791 273.281 95.0718 273.323 94.9725C273.365 94.8725 273.428 94.7832 273.507 94.7097C273.586 94.6367 273.68 94.5811 273.783 94.5469C273.885 94.5126 273.994 94.5008 274.101 94.5121C274.209 94.5126 274.316 94.5351 274.415 94.5794C274.514 94.6232 274.602 94.6878 274.675 94.7681C274.747 94.8484 274.802 94.9433 274.836 95.046C274.869 95.1493 274.881 95.2577 274.87 95.3655Z" fill="black" />
            <path d="M389.792 95.444C389.686 95.444 389.581 95.4216 389.485 95.3778C389.388 95.334 389.302 95.2705 389.233 95.1903C389.163 95.1105 389.111 95.0168 389.081 94.9151C389.05 94.8135 389.042 94.7068 389.057 94.6018C389.051 94.494 389.069 94.3862 389.109 94.2863C389.15 94.1863 389.212 94.0965 389.292 94.0235C389.371 93.9505 389.466 93.8961 389.569 93.8641C389.672 93.8321 389.781 93.8231 389.888 93.8382C389.996 93.836 390.102 93.8568 390.202 93.8994C390.301 93.9421 390.389 94.005 390.462 94.0853C390.534 94.165 390.589 94.2593 390.621 94.3621C390.654 94.4648 390.664 94.5732 390.651 94.6804C390.652 94.7894 390.63 94.8972 390.585 94.9965C390.541 95.0959 390.476 95.1846 390.395 95.2571C390.313 95.3295 390.218 95.3834 390.114 95.416C390.01 95.448 389.9 95.4575 389.792 95.444Z" fill="black" />
            <path d="M397.597 72.3114C398.114 72.3507 398.423 72.6033 398.423 73.1087C398.423 73.3203 398.339 73.523 398.189 73.6724C398.04 73.8223 397.837 73.906 397.626 73.906C397.414 73.906 397.211 73.8223 397.062 73.6724C396.912 73.523 396.828 73.3203 396.828 73.1087C396.823 73.0054 396.839 72.9015 396.875 72.8038C396.911 72.7067 396.967 72.6179 397.039 72.5433C397.112 72.4686 397.198 72.4091 397.294 72.3692C397.39 72.3293 397.494 72.3097 397.597 72.3114Z" fill="black" />
            <path d="M331.529 73.1929C331.478 73.7544 331.209 74.0464 330.72 74.052C330.519 74.0352 330.332 73.9436 330.196 73.7954C330.06 73.6472 329.984 73.4535 329.984 73.2519C329.984 73.0503 330.06 72.8566 330.196 72.7084C330.332 72.5602 330.519 72.4686 330.72 72.4518C330.822 72.4473 330.924 72.463 331.019 72.4978C331.115 72.5327 331.203 72.586 331.278 72.6545C331.353 72.7236 331.414 72.8067 331.457 72.8987C331.5 72.9914 331.524 73.0913 331.529 73.1929Z" fill="black" />
            <path d="M288.615 124.601C288.621 124.708 288.604 124.815 288.566 124.915C288.527 125.015 288.468 125.105 288.392 125.18C288.317 125.255 288.226 125.314 288.126 125.351C288.026 125.389 287.919 125.405 287.812 125.399C287.705 125.408 287.597 125.394 287.496 125.357C287.395 125.321 287.303 125.262 287.226 125.186C287.149 125.111 287.09 125.019 287.053 124.918C287.016 124.817 287 124.709 287.009 124.601C286.999 124.494 287.012 124.386 287.049 124.285C287.085 124.183 287.144 124.091 287.219 124.015C287.296 123.938 287.388 123.88 287.489 123.843C287.591 123.807 287.699 123.794 287.807 123.804C287.914 123.796 288.022 123.811 288.123 123.848C288.224 123.885 288.316 123.943 288.392 124.019C288.469 124.094 288.529 124.185 288.567 124.286C288.606 124.386 288.622 124.494 288.615 124.601Z" fill="black" />
            <path d="M318.8 74.7594C318.793 74.9728 318.701 75.1738 318.545 75.3192C318.389 75.4646 318.182 75.5421 317.969 75.5343C317.757 75.527 317.555 75.4354 317.41 75.2793C317.265 75.1238 317.187 74.9166 317.195 74.7033C317.188 74.5955 317.206 74.4877 317.245 74.3866C317.284 74.2861 317.345 74.1951 317.423 74.1199C317.501 74.0447 317.594 73.9874 317.696 73.9515C317.798 73.9155 317.907 73.9021 318.014 73.9116C318.123 73.9099 318.232 73.9307 318.332 73.9734C318.433 74.016 318.523 74.0795 318.597 74.1598C318.671 74.2401 318.728 74.3344 318.763 74.4383C318.798 74.5416 318.811 74.6511 318.8 74.7594Z" fill="black" />
            <path d="M352.236 81.8284C352.129 81.8385 352.02 81.8261 351.918 81.7919C351.815 81.7576 351.722 81.702 351.642 81.6285C351.563 81.5555 351.499 81.4668 351.457 81.3674C351.414 81.2686 351.393 81.1614 351.394 81.0535C351.383 80.9463 351.394 80.8379 351.429 80.7358C351.463 80.6336 351.518 80.5404 351.592 80.4612C351.665 80.3826 351.755 80.3203 351.854 80.2793C351.954 80.2383 352.061 80.2186 352.169 80.2226C352.276 80.2052 352.387 80.213 352.492 80.2445C352.596 80.2759 352.692 80.3304 352.773 80.4045C352.854 80.4786 352.916 80.5701 352.956 80.6717C352.997 80.7734 353.013 80.8829 353.005 80.9918C353.019 81.099 353.01 81.2085 352.977 81.3118C352.944 81.4151 352.889 81.51 352.816 81.5897C352.742 81.6695 352.653 81.7324 352.553 81.7734C352.452 81.8149 352.344 81.8334 352.236 81.8284Z" fill="black" />
            <path d="M354.503 93.7203C354.506 93.8265 354.487 93.932 354.448 94.0297C354.407 94.128 354.347 94.2161 354.27 94.2885C354.193 94.3615 354.101 94.4171 354.001 94.4519C353.901 94.4862 353.794 94.4991 353.689 94.4895C353.581 94.5002 353.472 94.4867 353.371 94.4502C353.269 94.4138 353.177 94.3542 353.101 94.2773C353.025 94.2004 352.968 94.1072 352.932 94.005C352.897 93.9028 352.885 93.7944 352.897 93.6866C352.89 93.5794 352.907 93.4722 352.945 93.3717C352.984 93.2717 353.043 93.1808 353.12 93.1055C353.197 93.0303 353.289 92.9724 353.39 92.9365C353.491 92.9 353.599 92.886 353.706 92.895C353.814 92.891 353.922 92.9096 354.023 92.95C354.123 92.9904 354.214 93.0516 354.289 93.1291C354.364 93.2071 354.422 93.3004 354.459 93.402C354.496 93.5042 354.511 93.6125 354.503 93.7203Z" fill="black" />
            <path d="M345.144 85.2814C344.454 85.2028 344.111 84.9838 344.1 84.4672C344.084 84.3662 344.091 84.2629 344.12 84.1646C344.149 84.0664 344.199 83.976 344.267 83.8996C344.336 83.8232 344.42 83.7632 344.514 83.7233C344.609 83.684 344.711 83.6655 344.813 83.67C345.318 83.6419 345.762 83.9956 345.661 84.456C345.544 84.7626 345.368 85.0428 345.144 85.2814Z" fill="black" />
            <path d="M367.901 105.747C367.385 105.674 367.082 105.416 367.098 104.905C367.091 104.798 367.107 104.69 367.146 104.59C367.185 104.49 367.245 104.4 367.323 104.327C367.401 104.252 367.494 104.196 367.596 104.163C367.698 104.128 367.806 104.118 367.913 104.13C368.02 104.123 368.129 104.14 368.23 104.179C368.331 104.218 368.422 104.279 368.496 104.357C368.572 104.435 368.628 104.529 368.663 104.631C368.698 104.734 368.71 104.843 368.699 104.95C368.693 105.449 368.35 105.685 367.901 105.747Z" fill="black" />
            <path d="M358.215 132.591C358.106 132.604 357.996 132.593 357.891 132.56C357.787 132.527 357.691 132.47 357.609 132.396C357.529 132.323 357.465 132.232 357.422 132.131C357.379 132.03 357.358 131.921 357.362 131.811C357.35 131.704 357.362 131.596 357.397 131.493C357.431 131.391 357.488 131.298 357.562 131.22C357.636 131.142 357.726 131.081 357.827 131.041C357.927 131.002 358.035 130.985 358.142 130.991C358.248 130.977 358.357 130.988 358.459 131.021C358.561 131.054 358.654 131.11 358.732 131.185C358.809 131.259 358.869 131.35 358.907 131.451C358.945 131.551 358.96 131.659 358.951 131.766C358.963 131.87 358.954 131.975 358.923 132.075C358.891 132.175 358.839 132.267 358.769 132.345C358.7 132.423 358.614 132.486 358.519 132.528C358.424 132.57 358.32 132.592 358.215 132.591Z" fill="black" />
            <path d="M255.072 131.811C254.966 131.815 254.862 131.795 254.765 131.756C254.667 131.716 254.579 131.655 254.507 131.579C254.435 131.502 254.38 131.411 254.345 131.312C254.311 131.213 254.299 131.107 254.308 131.002C254.298 130.895 254.311 130.787 254.347 130.686C254.383 130.584 254.442 130.493 254.518 130.417C254.595 130.341 254.687 130.283 254.789 130.247C254.89 130.212 254.998 130.199 255.106 130.21C255.213 130.202 255.321 130.217 255.422 130.254C255.523 130.292 255.614 130.351 255.69 130.427C255.766 130.504 255.824 130.596 255.861 130.697C255.898 130.798 255.912 130.906 255.903 131.013C255.909 131.123 255.891 131.232 255.851 131.334C255.811 131.436 255.75 131.528 255.67 131.603C255.592 131.679 255.497 131.737 255.394 131.772C255.29 131.808 255.181 131.821 255.072 131.811Z" fill="black" />
            <path d="M375.531 119.587C375.537 119.694 375.521 119.801 375.482 119.9C375.443 119.999 375.383 120.089 375.306 120.163C375.229 120.237 375.138 120.294 375.037 120.329C374.936 120.364 374.829 120.378 374.723 120.368C374.615 120.378 374.507 120.365 374.405 120.329C374.304 120.292 374.212 120.234 374.136 120.158C374.06 120.081 374.001 119.989 373.965 119.888C373.928 119.786 373.915 119.678 373.925 119.571C373.919 119.463 373.935 119.355 373.974 119.254C374.012 119.153 374.071 119.062 374.148 118.986C374.225 118.91 374.316 118.851 374.417 118.814C374.518 118.776 374.626 118.76 374.734 118.768C374.842 118.764 374.949 118.782 375.049 118.822C375.149 118.862 375.24 118.923 375.315 119C375.39 119.078 375.448 119.17 375.486 119.271C375.523 119.372 375.539 119.48 375.531 119.587Z" fill="black" />
            <path d="M308.649 119.677C308.582 120.132 308.385 120.48 307.863 120.486C307.756 120.5 307.648 120.49 307.546 120.456C307.444 120.422 307.351 120.367 307.273 120.292C307.195 120.218 307.136 120.127 307.098 120.026C307.06 119.926 307.045 119.818 307.055 119.711C307.041 119.605 307.052 119.497 307.084 119.395C307.117 119.293 307.172 119.199 307.244 119.121C307.317 119.042 307.406 118.98 307.505 118.94C307.604 118.9 307.711 118.881 307.818 118.886C308.318 118.852 308.576 119.161 308.649 119.677Z" fill="black" />
            <path d="M237.015 131.345C236.907 131.348 236.8 131.329 236.7 131.288C236.599 131.249 236.508 131.188 236.432 131.111C236.357 131.034 236.297 130.943 236.258 130.842C236.22 130.74 236.202 130.633 236.207 130.525C236.198 130.417 236.213 130.309 236.25 130.208C236.288 130.107 236.348 130.015 236.425 129.939C236.503 129.864 236.595 129.806 236.697 129.771C236.799 129.735 236.908 129.722 237.015 129.733C237.122 129.724 237.23 129.738 237.331 129.775C237.432 129.812 237.524 129.871 237.599 129.948C237.674 130.025 237.732 130.117 237.766 130.219C237.801 130.321 237.814 130.429 237.801 130.536C237.807 131.053 237.481 131.283 237.015 131.345Z" fill="black" />
            <path d="M392.016 119.997C392.012 119.891 392.032 119.784 392.074 119.685C392.116 119.586 392.179 119.498 392.259 119.427C392.339 119.355 392.433 119.302 392.536 119.27C392.638 119.239 392.746 119.23 392.852 119.245C392.96 119.238 393.067 119.254 393.168 119.293C393.268 119.333 393.358 119.393 393.432 119.472C393.506 119.55 393.561 119.645 393.593 119.747C393.627 119.85 393.636 119.958 393.622 120.065C393.626 120.173 393.606 120.28 393.564 120.38C393.523 120.48 393.46 120.569 393.381 120.642C393.301 120.715 393.207 120.77 393.104 120.804C393.001 120.837 392.892 120.847 392.785 120.834C392.678 120.834 392.572 120.813 392.474 120.77C392.376 120.727 392.288 120.664 392.216 120.586C392.143 120.507 392.088 120.414 392.054 120.312C392.019 120.211 392.006 120.104 392.016 119.997Z" fill="black" />
            <path d="M321.001 100.918C320.895 100.925 320.789 100.908 320.69 100.869C320.591 100.831 320.501 100.771 320.427 100.695C320.354 100.619 320.296 100.528 320.261 100.428C320.225 100.328 320.212 100.222 320.221 100.115C320.208 100.009 320.22 99.9016 320.255 99.8005C320.29 99.6989 320.346 99.6062 320.421 99.5299C320.495 99.453 320.586 99.3934 320.687 99.3564C320.787 99.3188 320.894 99.3036 321.001 99.3126C321.108 99.3025 321.216 99.316 321.318 99.3519C321.419 99.3884 321.512 99.4457 321.589 99.5209C321.666 99.5967 321.725 99.6877 321.763 99.7887C321.802 99.8892 321.817 99.997 321.81 100.104C321.817 100.213 321.801 100.322 321.763 100.423C321.725 100.525 321.666 100.617 321.589 100.694C321.513 100.772 321.421 100.831 321.319 100.87C321.218 100.909 321.109 100.925 321.001 100.918Z" fill="black" />
            <path d="M315.638 165.881C315.536 165.878 315.434 165.854 315.342 165.808C315.249 165.764 315.168 165.699 315.102 165.62C315.036 165.541 314.989 165.449 314.962 165.349C314.935 165.25 314.93 165.146 314.948 165.044C314.941 164.937 314.959 164.83 315 164.73C315.04 164.631 315.102 164.541 315.181 164.468C315.259 164.396 315.354 164.341 315.456 164.308C315.558 164.276 315.666 164.267 315.773 164.281C315.881 164.278 315.988 164.299 316.087 164.342C316.186 164.385 316.276 164.447 316.349 164.527C316.422 164.607 316.476 164.702 316.51 164.804C316.543 164.907 316.554 165.016 316.542 165.123C316.509 165.6 316.211 165.886 315.638 165.881Z" fill="black" />
            <path d="M339.99 65.2144C339.777 65.2144 339.573 65.1296 339.422 64.9791C339.272 64.8286 339.188 64.6243 339.188 64.4115C339.188 64.1987 339.272 63.9943 339.422 63.8438C339.573 63.6933 339.777 63.6086 339.99 63.6086C340.098 63.6013 340.207 63.617 340.308 63.6546C340.409 63.6928 340.501 63.7523 340.577 63.8292C340.653 63.9056 340.712 63.9977 340.749 64.0993C340.787 64.2009 340.801 64.3093 340.793 64.4171C340.798 64.5238 340.78 64.6299 340.742 64.7293C340.703 64.8286 340.644 64.9185 340.568 64.9937C340.493 65.0689 340.402 65.1273 340.303 65.165C340.203 65.2031 340.097 65.22 339.99 65.2144Z" fill="black" />
            <path d="M394.116 66.6069C393.906 66.5923 393.71 66.4946 393.572 66.3357C393.434 66.1762 393.365 65.969 393.38 65.7591C393.395 65.5491 393.492 65.3537 393.651 65.2161C393.811 65.078 394.018 65.0089 394.228 65.0235C394.335 65.0185 394.442 65.037 394.542 65.078C394.642 65.119 394.731 65.1813 394.804 65.2605C394.876 65.3402 394.931 65.434 394.963 65.5367C394.996 65.6395 395.005 65.7478 394.991 65.8545C394.969 66.3374 394.655 66.6069 394.116 66.6069Z" fill="black" />
            <path d="M382.33 67.2694C382.257 67.7859 382.005 68.0891 381.494 68.0779C381.293 68.0571 381.108 67.9611 380.975 67.8095C380.841 67.6579 380.77 67.4614 380.775 67.2598C380.78 67.0583 380.861 66.8657 381.001 66.7208C381.142 66.576 381.332 66.4889 381.533 66.4777C381.638 66.4755 381.742 66.4946 381.84 66.5333C381.937 66.5726 382.026 66.6304 382.1 66.7046C382.174 66.7781 382.233 66.8663 382.273 66.9634C382.313 67.0605 382.332 67.165 382.33 67.2694Z" fill="black" />
            <path d="M345.211 102.479C345.223 102.588 345.212 102.697 345.178 102.8C345.144 102.904 345.089 102.999 345.016 103.079C344.943 103.159 344.853 103.223 344.753 103.266C344.653 103.309 344.545 103.329 344.436 103.327C344.329 103.338 344.221 103.325 344.118 103.29C344.016 103.255 343.923 103.198 343.845 103.123C343.767 103.049 343.706 102.958 343.667 102.857C343.628 102.757 343.61 102.649 343.616 102.541C343.608 102.33 343.684 102.124 343.828 101.968C343.972 101.813 344.171 101.721 344.383 101.713C344.595 101.705 344.801 101.781 344.956 101.925C345.111 102.068 345.203 102.268 345.211 102.479Z" fill="black" />
            <path d="M390.186 112.732C390.183 112.837 390.16 112.941 390.117 113.038C390.074 113.134 390.013 113.221 389.936 113.294C389.86 113.367 389.77 113.424 389.672 113.462C389.573 113.499 389.469 113.518 389.363 113.515C389.257 113.513 389.154 113.489 389.057 113.447C388.96 113.404 388.873 113.343 388.801 113.266C388.728 113.19 388.671 113.1 388.633 113.001C388.596 112.903 388.577 112.798 388.58 112.693C388.572 112.585 388.589 112.477 388.628 112.376C388.667 112.275 388.727 112.184 388.804 112.109C388.882 112.034 388.975 111.976 389.076 111.94C389.178 111.904 389.286 111.891 389.394 111.901C389.502 111.896 389.611 111.915 389.711 111.956C389.812 111.997 389.903 112.059 389.978 112.137C390.053 112.216 390.11 112.31 390.146 112.412C390.182 112.515 390.195 112.624 390.186 112.732Z" fill="black" />
            <path d="M390.533 107.392C390.533 106.831 390.803 106.55 391.314 106.55C391.42 106.538 391.529 106.549 391.631 106.583C391.733 106.616 391.827 106.671 391.905 106.744C391.985 106.817 392.047 106.907 392.088 107.006C392.13 107.105 392.149 107.212 392.145 107.319C392.153 107.427 392.138 107.535 392.101 107.637C392.064 107.739 392.006 107.831 391.931 107.909C391.855 107.986 391.764 108.047 391.663 108.087C391.563 108.127 391.455 108.144 391.347 108.139C391.243 108.15 391.139 108.138 391.04 108.105C390.941 108.072 390.85 108.018 390.774 107.948C390.697 107.877 390.635 107.791 390.594 107.696C390.552 107.6 390.532 107.497 390.533 107.392Z" fill="black" />
            <path d="M327.772 113.973C328.334 113.973 328.648 114.27 328.659 114.742C328.671 114.849 328.659 114.957 328.625 115.059C328.59 115.161 328.535 115.254 328.461 115.333C328.388 115.411 328.298 115.472 328.198 115.513C328.099 115.554 327.992 115.572 327.885 115.567C327.685 115.562 327.494 115.483 327.349 115.345C327.205 115.207 327.118 115.02 327.104 114.821C327.09 114.622 327.15 114.425 327.274 114.268C327.397 114.111 327.575 114.006 327.772 113.973Z" fill="black" />
            <path d="M345.913 116.028C345.7 116.028 345.496 115.943 345.346 115.793C345.195 115.642 345.11 115.438 345.11 115.225C345.11 115.012 345.195 114.808 345.346 114.657C345.496 114.507 345.7 114.422 345.913 114.422C346.02 114.413 346.128 114.427 346.23 114.463C346.331 114.5 346.424 114.557 346.501 114.632C346.578 114.708 346.638 114.798 346.677 114.899C346.716 114.999 346.734 115.106 346.727 115.214C346.734 115.322 346.717 115.431 346.679 115.533C346.64 115.635 346.58 115.727 346.503 115.804C346.426 115.881 346.334 115.941 346.232 115.979C346.131 116.018 346.022 116.034 345.913 116.028Z" fill="black" />
            <path d="M396.014 96.5502C396.026 96.6585 396.014 96.768 395.981 96.8713C395.948 96.9746 395.892 97.0695 395.818 97.1498C395.745 97.2301 395.656 97.2941 395.556 97.3368C395.456 97.3795 395.348 97.4002 395.239 97.398C395.132 97.4092 395.023 97.3963 394.921 97.3609C394.819 97.3256 394.726 97.2688 394.648 97.1942C394.57 97.1195 394.509 97.0285 394.47 96.928C394.43 96.8275 394.413 96.7197 394.419 96.6119C394.415 96.5075 394.432 96.4031 394.468 96.3048C394.505 96.2065 394.56 96.1161 394.631 96.0392C394.702 95.9623 394.788 95.9005 394.883 95.8567C394.978 95.8129 395.081 95.7883 395.186 95.7838C395.397 95.7759 395.603 95.8517 395.758 95.9954C395.835 96.0667 395.898 96.1526 395.941 96.2475C395.985 96.343 396.01 96.4457 396.014 96.5502Z" fill="black" />
            <path d="M363.937 116.466C363.734 116.442 363.549 116.342 363.419 116.185C363.289 116.029 363.223 115.829 363.235 115.626C363.247 115.424 363.336 115.233 363.485 115.093C363.633 114.954 363.828 114.877 364.032 114.877C364.139 114.872 364.247 114.891 364.346 114.932C364.445 114.972 364.535 115.033 364.609 115.112C364.682 115.19 364.738 115.284 364.772 115.385C364.806 115.488 364.818 115.595 364.807 115.702C364.796 116.19 364.481 116.46 363.937 116.466Z" fill="black" />
            <path d="M371.758 94.9724C371.651 94.9735 371.545 94.9511 371.447 94.9079C371.349 94.8646 371.261 94.8012 371.19 94.722C371.118 94.6423 371.064 94.5485 371.031 94.4463C370.999 94.3447 370.988 94.2369 371 94.1302C370.992 94.0235 371.009 93.9163 371.048 93.8163C371.086 93.717 371.147 93.6266 371.225 93.553C371.302 93.4795 371.396 93.4244 371.498 93.3913C371.599 93.3576 371.708 93.3475 371.814 93.361C371.921 93.3565 372.028 93.375 372.128 93.416C372.227 93.4564 372.317 93.5176 372.391 93.5963C372.464 93.6743 372.52 93.7681 372.554 93.8697C372.588 93.9719 372.6 94.0797 372.589 94.1864C372.59 94.2942 372.57 94.4008 372.528 94.5002C372.487 94.5996 372.425 94.6889 372.346 94.763C372.268 94.8365 372.175 94.8938 372.074 94.9297C371.973 94.9657 371.865 94.9803 371.758 94.9724Z" fill="black" />
            <path d="M287.592 93.8046C287.547 94.2875 287.317 94.6131 286.806 94.6243C286.696 94.6367 286.585 94.626 286.479 94.5929C286.373 94.5598 286.276 94.5053 286.193 94.4329C286.109 94.3599 286.041 94.2712 285.994 94.1712C285.946 94.0713 285.92 93.9624 285.916 93.8518C285.913 93.7411 285.932 93.6311 285.973 93.5283C286.015 93.4256 286.076 93.3324 286.155 93.2549C286.234 93.1769 286.328 93.1162 286.431 93.0769C286.535 93.0371 286.645 93.0191 286.756 93.0241C286.863 93.0168 286.97 93.0309 287.071 93.0668C287.173 93.1028 287.266 93.1589 287.344 93.2319C287.423 93.3054 287.485 93.3942 287.528 93.4924C287.57 93.5912 287.592 93.6974 287.592 93.8046Z" fill="black" />
            <path d="M339.384 118.655C339.382 118.761 339.359 118.865 339.317 118.962C339.276 119.058 339.215 119.146 339.139 119.219C339.063 119.293 338.974 119.35 338.875 119.389C338.777 119.427 338.673 119.446 338.567 119.444C338.462 119.443 338.358 119.42 338.261 119.378C338.164 119.336 338.077 119.275 338.003 119.199C337.93 119.124 337.873 119.034 337.834 118.936C337.795 118.838 337.777 118.733 337.778 118.627C337.77 118.52 337.786 118.412 337.824 118.311C337.863 118.21 337.922 118.119 337.999 118.043C338.075 117.967 338.168 117.909 338.27 117.872C338.371 117.835 338.479 117.821 338.587 117.83C338.695 117.824 338.804 117.842 338.906 117.881C339.007 117.921 339.099 117.982 339.174 118.06C339.25 118.139 339.308 118.233 339.344 118.335C339.38 118.438 339.394 118.547 339.384 118.655Z" fill="black" />
            <path d="M326.705 120.081C326.649 120.604 326.391 120.941 325.908 120.941C325.801 120.948 325.694 120.933 325.594 120.895C325.493 120.858 325.402 120.8 325.326 120.724C325.25 120.649 325.19 120.558 325.153 120.458C325.115 120.357 325.098 120.25 325.105 120.143C325.091 120.038 325.101 119.93 325.134 119.828C325.168 119.727 325.224 119.635 325.299 119.558C325.374 119.482 325.465 119.423 325.566 119.388C325.666 119.352 325.774 119.34 325.88 119.352C325.983 119.34 326.088 119.35 326.187 119.381C326.286 119.412 326.377 119.464 326.455 119.533C326.533 119.601 326.595 119.686 326.639 119.781C326.682 119.875 326.704 119.978 326.705 120.081Z" fill="black" />
            <path d="M276.262 120.66C276.271 120.449 276.364 120.249 276.52 120.106C276.675 119.963 276.882 119.888 277.093 119.896C277.305 119.905 277.504 119.998 277.647 120.154C277.79 120.309 277.866 120.516 277.857 120.727C277.853 120.832 277.827 120.935 277.783 121.03C277.739 121.125 277.677 121.211 277.599 121.281C277.522 121.352 277.432 121.407 277.334 121.443C277.235 121.479 277.13 121.495 277.026 121.491C276.921 121.487 276.818 121.462 276.723 121.417C276.628 121.373 276.543 121.311 276.472 121.234C276.401 121.157 276.346 121.066 276.31 120.967C276.274 120.869 276.258 120.765 276.262 120.66Z" fill="black" />
            <path d="M362.831 121.014C362.831 121.541 362.573 121.844 362.056 121.833C361.95 121.844 361.842 121.832 361.741 121.797C361.64 121.762 361.548 121.705 361.471 121.631C361.394 121.556 361.334 121.467 361.296 121.367C361.257 121.267 361.241 121.16 361.248 121.053C361.241 120.947 361.256 120.84 361.293 120.74C361.33 120.64 361.388 120.549 361.463 120.473C361.538 120.397 361.627 120.338 361.727 120.3C361.827 120.261 361.933 120.244 362.04 120.25C362.144 120.238 362.25 120.249 362.35 120.283C362.449 120.317 362.54 120.372 362.615 120.445C362.691 120.518 362.749 120.607 362.787 120.705C362.824 120.804 362.839 120.909 362.831 121.014Z" fill="black" />
            <path d="M313.236 122.4C312.714 122.4 312.428 122.131 312.416 121.614C312.403 121.508 312.415 121.4 312.448 121.299C312.483 121.197 312.538 121.105 312.612 121.027C312.686 120.95 312.777 120.889 312.877 120.851C312.977 120.813 313.085 120.797 313.191 120.806C313.403 120.8 313.608 120.878 313.762 121.024C313.915 121.169 314.005 121.37 314.011 121.581C314.017 121.792 313.938 121.997 313.793 122.151C313.648 122.305 313.447 122.395 313.236 122.4Z" fill="black" />
            <path d="M379.956 90.767C379.883 91.2667 379.603 91.5756 379.114 91.5531C379.007 91.5598 378.899 91.5424 378.799 91.5031C378.699 91.4633 378.609 91.4026 378.535 91.324C378.461 91.246 378.405 91.1528 378.371 91.0506C378.338 90.9484 378.326 90.84 378.339 90.7333C378.33 90.6261 378.346 90.5177 378.385 90.4172C378.423 90.3167 378.485 90.2263 378.564 90.1528C378.642 90.0792 378.737 90.0248 378.84 89.9928C378.943 89.9613 379.052 89.9535 379.159 89.9697C379.647 89.981 379.878 90.2898 379.956 90.767Z" fill="black" />
            <path d="M306.829 88.1112C306.934 88.1028 307.039 88.1174 307.138 88.1539C307.236 88.1904 307.326 88.2482 307.4 88.3235C307.474 88.3982 307.53 88.4885 307.565 88.5879C307.601 88.6867 307.613 88.7929 307.603 88.8973C307.613 89.0045 307.6 89.1129 307.564 89.2145C307.528 89.3162 307.47 89.4082 307.395 89.4852C307.32 89.5626 307.228 89.6222 307.128 89.6603C307.027 89.698 306.919 89.7137 306.812 89.7058C306.704 89.7142 306.596 89.6996 306.494 89.662C306.392 89.625 306.3 89.566 306.223 89.4897C306.147 89.4139 306.087 89.3218 306.049 89.2207C306.011 89.1191 305.995 89.0113 306.003 88.9029C305.995 88.794 306.011 88.6845 306.05 88.5829C306.089 88.4807 306.15 88.3886 306.229 88.3128C306.308 88.237 306.402 88.1797 306.506 88.1449C306.61 88.1101 306.72 88.0989 306.829 88.1112Z" fill="black" />
            <path d="M265.168 122.187C265.176 122.295 265.161 122.403 265.124 122.504C265.087 122.605 265.029 122.697 264.953 122.775C264.878 122.852 264.786 122.912 264.686 122.951C264.585 122.99 264.478 123.007 264.37 123.001C264.263 123.009 264.155 122.993 264.054 122.955C263.953 122.917 263.861 122.857 263.785 122.78C263.71 122.704 263.651 122.611 263.615 122.51C263.578 122.408 263.564 122.3 263.573 122.193C263.573 121.981 263.657 121.778 263.807 121.629C263.956 121.48 264.159 121.395 264.37 121.395C264.582 121.395 264.784 121.48 264.934 121.629C265.084 121.778 265.168 121.981 265.168 122.193V122.187Z" fill="black" />
            <path d="M350.158 122.53C350.165 122.635 350.151 122.741 350.114 122.84C350.078 122.939 350.021 123.029 349.947 123.105C349.873 123.181 349.785 123.24 349.687 123.28C349.589 123.319 349.484 123.337 349.378 123.332C349.272 123.348 349.164 123.339 349.061 123.307C348.959 123.276 348.865 123.222 348.786 123.149C348.706 123.076 348.645 122.987 348.604 122.888C348.564 122.789 348.546 122.682 348.553 122.574C348.539 122.468 348.549 122.359 348.582 122.257C348.616 122.155 348.671 122.061 348.744 121.982C348.818 121.903 348.908 121.842 349.007 121.802C349.107 121.761 349.214 121.743 349.322 121.749C349.429 121.74 349.537 121.753 349.64 121.788C349.741 121.823 349.835 121.879 349.914 121.953C349.993 122.026 350.055 122.116 350.097 122.216C350.139 122.314 350.16 122.422 350.158 122.53Z" fill="black" />
            <path d="M289.525 88.5211C289.512 88.7339 289.415 88.9332 289.254 89.0741C289.094 89.2151 288.885 89.2869 288.672 89.2735C288.459 89.26 288.26 89.1629 288.119 89.0028C287.978 88.8428 287.906 88.6328 287.92 88.42C287.915 88.3122 287.934 88.2044 287.975 88.1045C288.015 88.0045 288.077 87.9147 288.157 87.8411C288.235 87.767 288.329 87.7114 288.432 87.6778C288.534 87.6441 288.643 87.6328 288.751 87.6452C288.862 87.6435 288.972 87.6654 289.073 87.7103C289.175 87.7552 289.266 87.8215 289.34 87.9046C289.413 87.9877 289.468 88.086 289.5 88.1926C289.532 88.2988 289.541 88.411 289.525 88.5211Z" fill="black" />
            <path d="M302.225 86.9827C302.219 87.1944 302.13 87.3948 301.977 87.5408C301.824 87.6868 301.619 87.766 301.408 87.7604C301.196 87.7553 300.996 87.6666 300.85 87.5133C300.704 87.36 300.625 87.1551 300.63 86.9434C300.632 86.839 300.656 86.7357 300.698 86.6402C300.741 86.5442 300.801 86.4577 300.877 86.3859C300.953 86.3134 301.043 86.2567 301.141 86.2191C301.238 86.1815 301.342 86.1635 301.447 86.1658C301.552 86.1686 301.655 86.1916 301.75 86.2343C301.846 86.2764 301.933 86.3376 302.005 86.4134C302.077 86.4892 302.134 86.5785 302.171 86.6762C302.209 86.7738 302.227 86.8783 302.225 86.9827Z" fill="black" />
            <path d="M337.452 124.017C337.391 124.5 337.144 124.815 336.633 124.809C336.432 124.792 336.245 124.701 336.109 124.553C335.972 124.404 335.896 124.211 335.896 124.009C335.896 123.807 335.972 123.614 336.109 123.466C336.245 123.317 336.432 123.226 336.633 123.209C336.741 123.203 336.849 123.219 336.951 123.257C337.053 123.295 337.145 123.354 337.222 123.431C337.3 123.507 337.36 123.599 337.4 123.7C337.439 123.801 337.457 123.909 337.452 124.017Z" fill="black" />
            <path d="M296.061 85.826C295.955 85.8305 295.849 85.812 295.75 85.7727C295.651 85.7334 295.562 85.6733 295.488 85.5969C295.413 85.5211 295.356 85.4296 295.32 85.3302C295.283 85.2303 295.267 85.1236 295.275 85.0175C295.261 84.9108 295.271 84.8025 295.304 84.6997C295.339 84.5975 295.395 84.5043 295.47 84.4268C295.545 84.3499 295.637 84.2904 295.738 84.2539C295.84 84.2168 295.948 84.2034 296.055 84.2146C296.162 84.2039 296.271 84.2163 296.372 84.2517C296.474 84.287 296.567 84.3443 296.645 84.419C296.722 84.4936 296.782 84.5846 296.821 84.6851C296.86 84.7856 296.877 84.8934 296.869 85.0007C296.873 85.109 296.855 85.2163 296.816 85.3173C296.777 85.4178 296.718 85.5099 296.642 85.5868C296.567 85.6643 296.476 85.725 296.376 85.7665C296.276 85.8075 296.169 85.8277 296.061 85.826Z" fill="black" />
            <path d="M323.943 126.303C323.833 126.308 323.722 126.29 323.619 126.25C323.515 126.211 323.422 126.15 323.343 126.072C323.264 125.995 323.202 125.902 323.161 125.799C323.12 125.696 323.101 125.586 323.104 125.476C323.107 125.365 323.134 125.256 323.181 125.156C323.229 125.056 323.296 124.967 323.38 124.894C323.463 124.822 323.561 124.768 323.666 124.734C323.773 124.701 323.884 124.691 323.994 124.702C324.104 124.697 324.214 124.715 324.318 124.755C324.421 124.794 324.515 124.855 324.594 124.933C324.673 125.011 324.735 125.104 324.776 125.207C324.817 125.309 324.837 125.419 324.833 125.53C324.829 125.641 324.803 125.75 324.756 125.849C324.708 125.949 324.641 126.038 324.557 126.111C324.473 126.184 324.376 126.238 324.27 126.271C324.165 126.304 324.053 126.315 323.943 126.303Z" fill="black" />
            <path d="M388.254 118.088C388.192 118.577 387.945 118.886 387.434 118.88C387.234 118.863 387.046 118.772 386.911 118.623C386.774 118.476 386.698 118.281 386.698 118.08C386.698 117.879 386.774 117.685 386.911 117.536C387.046 117.388 387.234 117.297 387.434 117.28C387.543 117.274 387.651 117.29 387.753 117.328C387.854 117.366 387.947 117.426 388.024 117.502C388.101 117.578 388.162 117.669 388.201 117.77C388.241 117.872 388.259 117.98 388.254 118.088Z" fill="black" />
            <path d="M275.914 126.033C275.914 126.628 275.571 126.881 275.089 126.87C274.986 126.876 274.884 126.861 274.788 126.825C274.692 126.788 274.605 126.732 274.533 126.659C274.461 126.586 274.406 126.498 274.371 126.402C274.336 126.305 274.322 126.203 274.331 126.1C274.322 125.89 274.396 125.686 274.538 125.531C274.68 125.376 274.879 125.284 275.089 125.275C275.299 125.266 275.503 125.341 275.658 125.483C275.813 125.625 275.905 125.823 275.914 126.033Z" fill="black" />
            <path d="M312.063 126.976C312.075 127.086 312.063 127.198 312.029 127.303C311.995 127.408 311.939 127.504 311.864 127.586C311.79 127.668 311.699 127.733 311.598 127.776C311.496 127.821 311.387 127.843 311.277 127.841C311.169 127.853 311.06 127.84 310.958 127.805C310.856 127.768 310.764 127.709 310.688 127.632C310.612 127.554 310.555 127.46 310.521 127.358C310.487 127.255 310.476 127.146 310.491 127.038C310.477 126.932 310.488 126.824 310.521 126.721C310.553 126.619 310.608 126.526 310.681 126.446C310.753 126.368 310.842 126.305 310.941 126.264C311.04 126.223 311.147 126.203 311.254 126.207C311.359 126.2 311.464 126.215 311.563 126.251C311.661 126.286 311.751 126.342 311.827 126.414C311.903 126.487 311.963 126.574 312.004 126.671C312.044 126.768 312.065 126.872 312.063 126.976Z" fill="black" />
            <path d="M337.901 106.005C337.901 106.111 337.879 106.215 337.835 106.311C337.793 106.407 337.73 106.493 337.652 106.563C337.573 106.634 337.481 106.687 337.381 106.72C337.281 106.752 337.175 106.763 337.07 106.752C336.968 106.755 336.866 106.738 336.771 106.699C336.676 106.66 336.591 106.602 336.52 106.527C336.449 106.453 336.395 106.364 336.361 106.268C336.327 106.171 336.314 106.068 336.323 105.966C336.312 105.858 336.325 105.75 336.361 105.647C336.396 105.545 336.454 105.452 336.529 105.374C336.605 105.297 336.696 105.237 336.798 105.198C336.899 105.16 337.007 105.144 337.115 105.152C337.609 105.158 337.89 105.455 337.901 106.005Z" fill="black" />
            <path d="M304.196 81.6093C304.194 81.7149 304.171 81.8193 304.128 81.9159C304.084 82.0125 304.021 82.0989 303.942 82.1697C303.864 82.2399 303.771 82.2938 303.671 82.3269C303.571 82.36 303.464 82.3718 303.359 82.3617C303.252 82.3724 303.143 82.3578 303.042 82.3207C302.941 82.2831 302.85 82.223 302.776 82.1456C302.701 82.0675 302.645 81.9737 302.612 81.8716C302.579 81.7688 302.569 81.6599 302.584 81.5532C302.577 81.446 302.593 81.3382 302.632 81.2376C302.671 81.1371 302.731 81.0462 302.809 80.9715C302.886 80.8963 302.979 80.8396 303.081 80.8042C303.183 80.7688 303.291 80.7565 303.398 80.7671C303.507 80.7671 303.614 80.789 303.714 80.8317C303.814 80.8749 303.904 80.9373 303.978 81.0164C304.053 81.095 304.111 81.1882 304.148 81.2904C304.185 81.3926 304.202 81.501 304.196 81.6093Z" fill="black" />
            <path d="M273.135 132.232C272.934 132.209 272.749 132.111 272.617 131.957C272.485 131.803 272.416 131.606 272.423 131.403C272.431 131.201 272.516 131.008 272.659 130.866C272.802 130.723 272.994 130.639 273.197 130.632C273.308 130.628 273.417 130.647 273.521 130.687C273.623 130.728 273.717 130.789 273.795 130.867C273.872 130.945 273.933 131.039 273.974 131.142C274.014 131.245 274.032 131.355 274.028 131.465C274.024 131.575 273.997 131.684 273.949 131.783C273.901 131.883 273.833 131.971 273.749 132.043C273.665 132.115 273.567 132.169 273.462 132.201C273.356 132.234 273.245 132.244 273.135 132.232Z" fill="black" />
            <path d="M293.427 77.6566C293.434 77.7622 293.419 77.8677 293.383 77.9671C293.346 78.0665 293.289 78.1569 293.216 78.2321C293.142 78.3079 293.053 78.3674 292.955 78.4067C292.857 78.446 292.752 78.464 292.647 78.4595C292.54 78.4747 292.432 78.4663 292.329 78.4343C292.227 78.4028 292.133 78.3489 292.054 78.2759C291.975 78.2035 291.913 78.1148 291.873 78.0154C291.832 77.916 291.815 77.8088 291.821 77.7015C291.808 77.5949 291.818 77.4865 291.851 77.3843C291.884 77.2821 291.94 77.1883 292.013 77.1092C292.086 77.0306 292.176 76.9688 292.275 76.9289C292.375 76.8885 292.483 76.8706 292.59 76.8762C292.698 76.8672 292.806 76.8801 292.908 76.9155C293.01 76.9503 293.104 77.0064 293.182 77.08C293.261 77.1535 293.324 77.2434 293.366 77.3428C293.408 77.4421 293.429 77.5488 293.427 77.6566Z" fill="black" />
            <path d="M354.127 76.4438C353.926 76.4231 353.741 76.3282 353.607 76.1771C353.474 76.0255 353.402 75.8302 353.406 75.628C353.41 75.4265 353.491 75.2339 353.63 75.0884C353.77 74.943 353.959 74.856 354.161 74.8436C354.272 74.8369 354.382 74.8532 354.487 74.8919C354.591 74.9301 354.685 74.9896 354.766 75.0666C354.846 75.1435 354.908 75.2361 354.951 75.3383C354.993 75.4411 355.014 75.5511 355.012 75.6623C355.009 75.7729 354.984 75.8824 354.938 75.9829C354.891 76.0834 354.824 76.1732 354.74 76.2468C354.657 76.3203 354.56 76.3753 354.455 76.4096C354.349 76.4433 354.237 76.4551 354.127 76.4438Z" fill="black" />
            <path d="M296.588 134.646C296.484 134.647 296.38 134.625 296.284 134.583C296.189 134.541 296.103 134.479 296.032 134.402C295.962 134.325 295.909 134.234 295.876 134.135C295.843 134.035 295.831 133.93 295.841 133.826C295.834 133.719 295.85 133.61 295.89 133.509C295.93 133.408 295.991 133.317 296.07 133.243C296.149 133.169 296.243 133.113 296.347 133.08C296.45 133.047 296.56 133.037 296.667 133.051C296.77 133.046 296.872 133.063 296.968 133.101C297.064 133.14 297.15 133.198 297.222 133.273C297.292 133.347 297.346 133.437 297.379 133.534C297.412 133.631 297.424 133.735 297.414 133.838C297.424 133.948 297.41 134.059 297.373 134.164C297.334 134.268 297.274 134.362 297.195 134.439C297.115 134.518 297.02 134.576 296.915 134.612C296.81 134.648 296.698 134.66 296.588 134.646Z" fill="black" />
            <path d="M311.669 109.711C311.563 109.71 311.459 109.689 311.363 109.646C311.267 109.603 311.18 109.541 311.109 109.464C311.038 109.387 310.984 109.295 310.949 109.196C310.915 109.096 310.902 108.991 310.911 108.886C310.905 108.783 310.921 108.68 310.958 108.584C310.995 108.488 311.052 108.401 311.125 108.329C311.199 108.256 311.286 108.201 311.383 108.165C311.479 108.129 311.583 108.115 311.685 108.122C311.793 108.113 311.902 108.128 312.004 108.164C312.107 108.2 312.2 108.257 312.278 108.332C312.356 108.407 312.418 108.497 312.459 108.597C312.501 108.698 312.52 108.805 312.517 108.914C312.517 109.408 312.208 109.683 311.669 109.711Z" fill="black" />
            <path d="M396.732 110.082C396.243 110.037 395.94 109.795 395.935 109.284C395.92 109.178 395.929 109.069 395.962 108.967C395.995 108.865 396.05 108.771 396.124 108.693C396.198 108.615 396.288 108.554 396.388 108.516C396.489 108.478 396.596 108.462 396.704 108.47C396.81 108.458 396.919 108.469 397.021 108.503C397.124 108.536 397.217 108.593 397.296 108.666C397.375 108.74 397.436 108.83 397.476 108.93C397.517 109.03 397.535 109.137 397.529 109.245C397.534 109.353 397.516 109.461 397.478 109.561C397.44 109.662 397.383 109.755 397.308 109.833C397.234 109.911 397.144 109.973 397.045 110.016C396.946 110.059 396.84 110.081 396.732 110.082Z" fill="black" />
            <path d="M301.956 136.628C301.85 136.627 301.746 136.604 301.65 136.561C301.553 136.517 301.466 136.454 301.396 136.375C301.325 136.297 301.272 136.204 301.239 136.104C301.205 136.003 301.194 135.897 301.204 135.791C301.194 135.685 301.209 135.577 301.247 135.476C301.285 135.376 301.345 135.285 301.422 135.21C301.5 135.136 301.593 135.08 301.695 135.047C301.797 135.013 301.906 135.003 302.012 135.017C302.119 135.01 302.227 135.026 302.328 135.065C302.428 135.104 302.519 135.164 302.594 135.241C302.669 135.319 302.726 135.412 302.761 135.514C302.796 135.616 302.809 135.724 302.798 135.831C302.798 135.939 302.775 136.046 302.732 136.146C302.689 136.245 302.626 136.335 302.547 136.409C302.469 136.484 302.375 136.542 302.274 136.579C302.172 136.617 302.064 136.634 301.956 136.628Z" fill="black" />
            <path d="M328.328 95.8876C328.432 95.891 328.536 95.9151 328.631 95.9578C328.726 96.001 328.812 96.0628 328.884 96.1392C328.956 96.2155 329.012 96.3053 329.049 96.403C329.086 96.5013 329.103 96.6057 329.1 96.7102C329.096 96.8152 329.072 96.9179 329.029 97.0134C328.986 97.1088 328.925 97.1947 328.848 97.2666C328.772 97.3385 328.682 97.394 328.584 97.4311C328.486 97.4682 328.382 97.4856 328.277 97.4822C328.066 97.4755 327.866 97.3851 327.721 97.2307C327.576 97.0768 327.499 96.8713 327.505 96.6596C327.512 96.4485 327.602 96.2481 327.756 96.1032C327.911 95.9584 328.116 95.8809 328.328 95.8876Z" fill="black" />
            <path d="M350.602 104.439C350.557 104.967 350.298 105.259 349.788 105.259C349.576 105.259 349.373 105.175 349.224 105.025C349.074 104.876 348.99 104.673 348.99 104.461C348.99 104.25 349.074 104.048 349.224 103.898C349.373 103.748 349.576 103.664 349.788 103.664C349.893 103.656 349.999 103.671 350.099 103.706C350.199 103.742 350.29 103.798 350.366 103.871C350.443 103.944 350.503 104.032 350.544 104.13C350.585 104.228 350.604 104.333 350.602 104.439Z" fill="black" />
            <path d="M343.392 72.5135C343.235 72.5079 343.083 72.4562 342.955 72.3641C342.827 72.2721 342.729 72.1446 342.674 71.9969C342.618 71.8498 342.608 71.6893 342.644 71.536C342.68 71.3827 342.76 71.2434 342.874 71.1356C342.99 71.0278 343.133 70.9565 343.289 70.9307C343.444 70.9043 343.603 70.9251 343.747 70.9897C343.891 71.0542 344.012 71.1598 344.096 71.2929C344.179 71.4265 344.221 71.5815 344.217 71.7387C344.206 72.2664 343.903 72.5135 343.392 72.5135Z" fill="black" />
            <path d="M264.034 104.035C264.03 103.941 264.047 103.847 264.082 103.76C264.117 103.672 264.171 103.594 264.239 103.529C264.306 103.464 264.387 103.413 264.476 103.382C264.564 103.35 264.659 103.337 264.752 103.344C264.863 103.331 264.977 103.342 265.083 103.377C265.19 103.412 265.288 103.47 265.37 103.546C265.452 103.622 265.517 103.716 265.56 103.82C265.602 103.923 265.621 104.035 265.617 104.147C265.619 104.249 265.601 104.35 265.563 104.445C265.525 104.539 265.469 104.626 265.397 104.698C265.325 104.77 265.24 104.827 265.145 104.866C265.051 104.904 264.949 104.923 264.848 104.922C264.393 104.95 264.045 104.596 264.034 104.035Z" fill="black" />
            <path d="M319.957 68.5327C320.066 68.5327 320.174 68.5546 320.274 68.5972C320.374 68.6405 320.464 68.7028 320.539 68.7814C320.614 68.8606 320.672 68.9538 320.711 69.0554C320.748 69.1576 320.765 69.2665 320.76 69.3749C320.738 69.5748 320.641 69.7584 320.49 69.8897C320.338 70.0217 320.143 70.0913 319.942 70.0846C319.741 70.0784 319.551 69.9964 319.407 69.8555C319.265 69.7146 319.18 69.5248 319.171 69.3243C319.169 69.2205 319.189 69.1172 319.228 69.0211C319.266 68.9246 319.324 68.837 319.397 68.7634C319.471 68.6893 319.558 68.6309 319.654 68.5916C319.75 68.5518 319.853 68.5321 319.957 68.5327Z" fill="black" />
            <path d="M314.263 151.58C314.367 151.576 314.472 151.594 314.568 151.633C314.665 151.672 314.752 151.732 314.823 151.809C314.894 151.885 314.947 151.976 314.98 152.075C315.012 152.175 315.023 152.28 315.01 152.383C315.02 152.491 315.007 152.599 314.97 152.701C314.934 152.802 314.876 152.895 314.8 152.971C314.724 153.048 314.632 153.107 314.53 153.144C314.428 153.181 314.32 153.195 314.213 153.186C314.105 153.193 313.997 153.176 313.896 153.138C313.796 153.099 313.705 153.039 313.63 152.961C313.554 152.884 313.496 152.791 313.461 152.69C313.425 152.588 313.411 152.48 313.421 152.372C313.432 151.895 313.702 151.597 314.263 151.58Z" fill="black" />
            <path d="M319.608 153.512C320.097 153.562 320.406 153.804 320.406 154.314C320.389 154.515 320.297 154.702 320.149 154.838C320.001 154.975 319.807 155.051 319.606 155.051C319.404 155.051 319.21 154.975 319.062 154.838C318.914 154.702 318.822 154.515 318.806 154.314C318.8 154.208 318.818 154.101 318.857 154.002C318.895 153.902 318.954 153.812 319.03 153.736C319.105 153.661 319.196 153.601 319.296 153.563C319.395 153.524 319.502 153.507 319.608 153.512Z" fill="black" />
            <path d="M305.342 75.4163C305.44 75.409 305.539 75.4231 305.632 75.4567C305.726 75.4904 305.81 75.5438 305.881 75.6128C305.953 75.6819 306.008 75.765 306.045 75.8571C306.082 75.9486 306.098 76.0474 306.094 76.1462C306.106 76.2546 306.097 76.3635 306.065 76.4679C306.034 76.5718 305.982 76.6684 305.911 76.7509C305.841 76.834 305.754 76.9014 305.657 76.9491C305.559 76.9969 305.453 77.0244 305.344 77.0294C305.236 77.0345 305.127 77.0171 305.026 76.9783C304.924 76.9396 304.832 76.8806 304.754 76.8043C304.676 76.7285 304.615 76.6375 304.574 76.5364C304.533 76.4359 304.513 76.3281 304.516 76.2192C304.499 75.7083 304.797 75.4388 305.342 75.4163Z" fill="black" />
            <path d="M241.732 119.565C241.659 120.272 241.418 120.576 240.907 120.581C240.396 120.587 240.042 120.278 240.143 119.868C240.213 119.676 240.326 119.502 240.471 119.358C240.617 119.215 240.792 119.105 240.985 119.037C241.176 118.97 241.536 119.413 241.732 119.565Z" fill="black" />
            <path d="M334.004 116.426C334.004 117.167 333.724 117.471 333.224 117.488C333.117 117.501 333.008 117.49 332.906 117.456C332.804 117.421 332.711 117.363 332.635 117.288C332.558 117.212 332.499 117.12 332.463 117.019C332.427 116.917 332.415 116.809 332.427 116.701C332.414 116.594 332.427 116.486 332.463 116.384C332.499 116.283 332.559 116.191 332.636 116.116C332.714 116.041 332.808 115.984 332.911 115.952C333.013 115.919 333.123 115.91 333.23 115.927C333.544 115.972 333.819 116.308 334.004 116.426Z" fill="black" />
            <path d="M276.37 107.965C276.379 108.075 276.364 108.186 276.327 108.289C276.29 108.393 276.231 108.488 276.154 108.568C276.078 108.648 275.985 108.71 275.883 108.751C275.781 108.793 275.671 108.812 275.561 108.807C275.452 108.814 275.343 108.799 275.241 108.761C275.139 108.722 275.046 108.662 274.969 108.585C274.892 108.508 274.832 108.415 274.793 108.313C274.755 108.211 274.74 108.102 274.747 107.993C274.742 107.891 274.759 107.788 274.796 107.693C274.833 107.597 274.891 107.51 274.964 107.438C275.037 107.366 275.124 107.31 275.22 107.274C275.317 107.238 275.419 107.223 275.522 107.229C275.627 107.218 275.732 107.228 275.833 107.259C275.934 107.291 276.027 107.342 276.107 107.411C276.186 107.481 276.25 107.566 276.296 107.661C276.341 107.756 276.366 107.859 276.37 107.965Z" fill="black" />
            <path d="M346.155 66.3373C346.155 66.8988 345.858 67.1964 345.375 67.2076C345.267 67.2177 345.159 67.2042 345.057 67.1683C344.955 67.1324 344.863 67.0745 344.785 66.9993C344.708 66.9241 344.648 66.8331 344.609 66.7326C344.57 66.6315 344.554 66.5237 344.561 66.4159C344.549 66.3092 344.562 66.2009 344.598 66.0993C344.633 65.9976 344.69 65.9055 344.765 65.8281C344.84 65.7511 344.931 65.6916 345.031 65.6534C345.132 65.6153 345.239 65.5995 345.347 65.6074C345.449 65.5945 345.553 65.604 345.652 65.6349C345.751 65.6658 345.841 65.7175 345.918 65.7871C345.995 65.8561 346.056 65.9415 346.097 66.0364C346.138 66.1313 346.157 66.234 346.155 66.3373Z" fill="black" />
            <path d="M269.491 93.3778C269.498 93.4817 269.483 93.5856 269.448 93.6833C269.412 93.781 269.357 93.8702 269.285 93.9455C269.213 94.0202 269.126 94.0797 269.03 94.119C268.934 94.1588 268.831 94.1779 268.727 94.1751C268.62 94.1903 268.512 94.1813 268.41 94.1482C268.307 94.1145 268.214 94.0589 268.137 93.9842C268.059 93.909 268 93.818 267.963 93.717C267.926 93.6159 267.913 93.5075 267.924 93.4003C267.908 93.2942 267.916 93.1852 267.947 93.0819C267.979 92.9792 268.033 92.8843 268.106 92.8046C268.178 92.7248 268.267 92.6625 268.367 92.6221C268.467 92.5811 268.574 92.5631 268.682 92.5693C269.193 92.5525 269.457 92.8332 269.491 93.3778Z" fill="black" />
            <path d="M348.086 140.575C348.093 140.362 348.184 140.161 348.339 140.015C348.495 139.869 348.702 139.791 348.915 139.798C349.127 139.804 349.329 139.895 349.475 140.051C349.621 140.206 349.699 140.413 349.692 140.626C349.699 140.734 349.683 140.841 349.644 140.942C349.605 141.043 349.545 141.134 349.468 141.209C349.39 141.284 349.297 141.342 349.195 141.378C349.094 141.414 348.985 141.428 348.878 141.417C348.769 141.422 348.659 141.403 348.557 141.362C348.456 141.32 348.364 141.257 348.29 141.178C348.214 141.098 348.157 141.003 348.122 140.899C348.087 140.795 348.075 140.684 348.086 140.575Z" fill="black" />
            <path d="M271.692 119.565C271.181 119.509 270.895 119.251 270.912 118.734C270.903 118.632 270.916 118.529 270.95 118.432C270.984 118.335 271.039 118.247 271.111 118.172C271.182 118.099 271.268 118.041 271.364 118.003C271.459 117.965 271.562 117.948 271.664 117.954C271.772 117.942 271.882 117.954 271.984 117.989C272.087 118.024 272.18 118.082 272.258 118.157C272.336 118.233 272.396 118.325 272.434 118.427C272.472 118.528 272.487 118.637 272.478 118.745C272.487 118.852 272.473 118.96 272.437 119.061C272.402 119.162 272.344 119.255 272.27 119.332C272.196 119.41 272.106 119.471 272.006 119.511C271.907 119.551 271.8 119.569 271.692 119.565Z" fill="black" />
            <path d="M394.554 83.844C394.526 84.4055 394.217 84.6919 393.74 84.6863C393.632 84.6913 393.524 84.6733 393.424 84.634C393.324 84.5942 393.233 84.5335 393.158 84.4555C393.083 84.378 393.025 84.2854 392.989 84.1837C392.953 84.0821 392.939 83.9743 392.948 83.8665C392.948 83.6537 393.033 83.4493 393.183 83.2989C393.334 83.1484 393.538 83.0636 393.751 83.0636C393.964 83.0636 394.168 83.1484 394.318 83.2989C394.469 83.4493 394.554 83.6537 394.554 83.8665V83.844Z" fill="black" />
            <path d="M378.626 107.987C379.131 108.055 379.434 108.319 379.417 108.824C379.425 108.931 379.408 109.038 379.37 109.137C379.331 109.237 379.27 109.326 379.192 109.399C379.114 109.473 379.02 109.527 378.919 109.56C378.817 109.593 378.709 109.602 378.603 109.588C378.496 109.597 378.388 109.582 378.287 109.544C378.187 109.506 378.095 109.446 378.02 109.369C377.946 109.292 377.889 109.198 377.855 109.096C377.82 108.994 377.81 108.886 377.823 108.779C377.822 108.674 377.842 108.57 377.882 108.472C377.923 108.375 377.982 108.287 378.057 108.214C378.131 108.14 378.22 108.082 378.318 108.043C378.416 108.004 378.521 107.985 378.626 107.987Z" fill="black" />
            <path d="M299.244 141.165C299.317 140.716 299.502 140.396 300.013 140.401C300.119 140.387 300.228 140.396 300.33 140.429C300.432 140.461 300.525 140.516 300.604 140.589C300.683 140.662 300.744 140.752 300.783 140.851C300.823 140.951 300.84 141.058 300.833 141.165C300.848 141.271 300.839 141.38 300.806 141.483C300.772 141.585 300.716 141.679 300.642 141.757C300.567 141.835 300.476 141.895 300.375 141.932C300.274 141.969 300.166 141.983 300.058 141.973C299.952 141.975 299.845 141.955 299.746 141.915C299.647 141.875 299.557 141.816 299.481 141.74C299.405 141.665 299.345 141.575 299.305 141.476C299.264 141.378 299.244 141.271 299.244 141.165Z" fill="black" />
            <path d="M295.353 72.2945C295.362 72.4057 295.349 72.5169 295.313 72.6219C295.277 72.7269 295.219 72.8234 295.144 72.9054C295.069 72.9874 294.977 73.0525 294.876 73.0969C294.774 73.1418 294.664 73.1648 294.553 73.1648C294.442 73.1648 294.332 73.1418 294.23 73.0969C294.129 73.0525 294.037 72.9874 293.962 72.9054C293.887 72.8234 293.829 72.7269 293.793 72.6219C293.757 72.5169 293.743 72.4057 293.753 72.2945C293.747 72.1873 293.765 72.0795 293.805 71.9796C293.846 71.8796 293.907 71.7898 293.986 71.7157C294.065 71.6421 294.158 71.5865 294.261 71.5523C294.363 71.5186 294.471 71.5074 294.578 71.5197C294.682 71.5124 294.786 71.5276 294.883 71.5635C294.981 71.6 295.07 71.6567 295.143 71.7303C295.216 71.8038 295.273 71.892 295.309 71.9897C295.346 72.0868 295.36 72.1912 295.353 72.2945Z" fill="black" />
            <path d="M247.447 116.353C247.447 116.141 247.532 115.936 247.682 115.786C247.833 115.635 248.037 115.55 248.25 115.55C248.463 115.55 248.667 115.635 248.818 115.786C248.968 115.936 249.053 116.141 249.053 116.353C249.064 116.461 249.051 116.57 249.016 116.672C248.981 116.774 248.924 116.868 248.849 116.946C248.774 117.025 248.683 117.086 248.584 117.126C248.483 117.166 248.375 117.184 248.267 117.179C248.156 117.188 248.046 117.172 247.942 117.133C247.838 117.095 247.744 117.034 247.666 116.956C247.588 116.877 247.529 116.783 247.49 116.679C247.453 116.575 247.438 116.464 247.447 116.353Z" fill="black" />
            <path d="M377.918 96.2863C377.918 96.6175 377.626 96.8983 377.121 96.8983C377.018 96.9078 376.916 96.8943 376.82 96.8601C376.723 96.8258 376.635 96.7708 376.562 96.6995C376.489 96.6282 376.433 96.5417 376.396 96.4457C376.36 96.3503 376.344 96.2481 376.351 96.1459C376.341 96.0387 376.353 95.9297 376.388 95.8275C376.424 95.7254 376.48 95.6316 376.555 95.5536C376.63 95.4749 376.72 95.4137 376.821 95.3733C376.921 95.3335 377.029 95.3155 377.137 95.3205C377.615 95.3205 377.94 95.5788 377.918 96.2863Z" fill="black" />
            <path d="M342.163 89.7507C342.161 89.6468 342.182 89.5435 342.224 89.4486C342.266 89.3532 342.328 89.2684 342.406 89.1994C342.484 89.1309 342.576 89.0798 342.675 89.0494C342.774 89.0197 342.879 89.0118 342.983 89.0264C343.089 89.0186 343.197 89.0343 343.297 89.0725C343.398 89.1106 343.488 89.1702 343.563 89.2476C343.637 89.3246 343.694 89.4172 343.728 89.5188C343.763 89.6205 343.775 89.7283 343.763 89.8349C343.768 89.9427 343.749 90.05 343.709 90.1499C343.668 90.2493 343.607 90.3397 343.529 90.4133C343.45 90.4874 343.357 90.5435 343.255 90.5783C343.153 90.6137 343.045 90.6261 342.938 90.6154C342.827 90.6182 342.718 90.5969 342.617 90.5531C342.515 90.5093 342.425 90.4441 342.351 90.3616C342.278 90.2796 342.223 90.1825 342.19 90.0769C342.157 89.9714 342.148 89.8602 342.163 89.7507Z" fill="black" />
            <path d="M354.829 88.3414C354.824 88.2331 354.841 88.1247 354.882 88.0236C354.922 87.9226 354.985 87.8316 355.063 87.757C355.142 87.6823 355.237 87.6256 355.34 87.5908C355.443 87.5559 355.552 87.5441 355.66 87.5554C355.762 87.5492 355.865 87.5644 355.962 87.5997C356.058 87.6351 356.146 87.6907 356.219 87.762C356.293 87.8333 356.352 87.9187 356.391 88.0141C356.43 88.109 356.448 88.2106 356.446 88.3134C356.455 88.4212 356.441 88.5295 356.404 88.6317C356.368 88.7334 356.31 88.826 356.234 88.9029C356.158 88.9804 356.067 89.0405 355.966 89.0792C355.865 89.118 355.757 89.1342 355.649 89.1275C355.542 89.1354 355.434 89.1208 355.334 89.0848C355.233 89.0483 355.141 88.9916 355.064 88.9175C354.986 88.8434 354.926 88.7536 354.885 88.6542C354.845 88.5554 354.826 88.4487 354.829 88.3414Z" fill="black" />
            <path d="M299.378 128.537C299.378 128.642 299.355 128.744 299.312 128.839C299.268 128.934 299.205 129.018 299.126 129.087C299.048 129.155 298.956 129.207 298.856 129.236C298.756 129.267 298.651 129.275 298.547 129.262C298.44 129.268 298.333 129.251 298.233 129.212C298.134 129.172 298.044 129.111 297.97 129.032C297.897 128.954 297.842 128.861 297.808 128.758C297.775 128.656 297.765 128.548 297.778 128.442C297.774 128.335 297.793 128.228 297.835 128.128C297.876 128.03 297.938 127.941 298.017 127.868C298.097 127.796 298.19 127.741 298.293 127.708C298.395 127.675 298.503 127.665 298.609 127.678C298.719 127.676 298.827 127.698 298.927 127.742C299.028 127.786 299.117 127.851 299.19 127.933C299.263 128.014 299.317 128.11 299.35 128.214C299.382 128.319 299.392 128.429 299.378 128.537Z" fill="black" />
            <path d="M262.933 108.695C263.038 108.691 263.142 108.711 263.238 108.752C263.334 108.793 263.421 108.854 263.491 108.931C263.562 109.009 263.614 109.1 263.646 109.2C263.678 109.3 263.687 109.405 263.674 109.509C263.682 109.617 263.665 109.725 263.626 109.826C263.587 109.926 263.526 110.017 263.448 110.092C263.37 110.166 263.276 110.223 263.173 110.257C263.07 110.291 262.962 110.302 262.855 110.289C262.643 110.279 262.444 110.185 262.302 110.028C262.16 109.871 262.086 109.664 262.097 109.453C262.107 109.242 262.201 109.043 262.358 108.901C262.514 108.758 262.722 108.685 262.933 108.695Z" fill="black" />
            <path d="M277.975 85.3376C277.866 85.3488 277.755 85.337 277.65 85.3027C277.545 85.2691 277.449 85.2135 277.366 85.1405C277.284 85.0669 277.217 84.9777 277.171 84.8772C277.125 84.7772 277.1 84.6688 277.099 84.5582C277.097 84.4482 277.118 84.3387 277.16 84.2371C277.203 84.1354 277.266 84.0439 277.345 83.9676C277.425 83.8918 277.52 83.8328 277.623 83.7952C277.727 83.7576 277.837 83.7419 277.947 83.7486C278.147 83.7615 278.335 83.8491 278.473 83.994C278.611 84.1388 278.689 84.3303 278.693 84.5302C278.697 84.7306 278.625 84.9243 278.492 85.0742C278.359 85.2236 278.175 85.3179 277.975 85.3376Z" fill="black" />
            <path d="M322.388 148.947C322.343 149.43 322.107 149.738 321.591 149.738C321.485 149.753 321.376 149.743 321.274 149.711C321.172 149.678 321.079 149.624 321 149.551C320.921 149.477 320.86 149.388 320.821 149.289C320.781 149.189 320.764 149.082 320.771 148.975C320.755 148.869 320.762 148.761 320.795 148.659C320.828 148.557 320.883 148.464 320.958 148.387C321.033 148.31 321.124 148.252 321.225 148.216C321.326 148.181 321.434 148.17 321.54 148.183C321.647 148.173 321.755 148.185 321.856 148.219C321.958 148.253 322.052 148.307 322.132 148.379C322.211 148.45 322.275 148.538 322.319 148.636C322.363 148.733 322.387 148.84 322.388 148.947Z" fill="black" />
            <path d="M291.018 101.092C290.973 101.654 290.653 102.008 290.193 101.901C290.017 101.843 289.855 101.744 289.723 101.614C289.591 101.483 289.49 101.324 289.429 101.149C289.334 100.683 289.699 100.351 290.21 100.357C290.311 100.35 290.414 100.363 290.511 100.396C290.608 100.429 290.697 100.482 290.772 100.551C290.848 100.62 290.909 100.703 290.951 100.797C290.993 100.889 291.016 100.99 291.018 101.092Z" fill="black" />
            <path d="M328.749 77.8026C329.238 77.8588 329.558 78.1002 329.558 78.6055C329.558 78.8172 329.473 79.0199 329.324 79.1698C329.175 79.3191 328.971 79.4028 328.76 79.4028C328.549 79.4028 328.346 79.3191 328.197 79.1698C328.047 79.0199 327.963 78.8172 327.963 78.6055C327.961 78.5011 327.979 78.3972 328.018 78.2995C328.056 78.2024 328.114 78.1137 328.187 78.039C328.26 77.9643 328.348 77.9048 328.444 77.8644C328.541 77.8239 328.644 77.8026 328.749 77.8026Z" fill="black" />
            <path d="M370.152 117.639C370.157 117.745 370.141 117.851 370.103 117.95C370.065 118.048 370.007 118.138 369.932 118.213C369.858 118.289 369.768 118.347 369.67 118.386C369.571 118.424 369.466 118.441 369.36 118.436C369.253 118.446 369.146 118.432 369.045 118.395C368.944 118.358 368.854 118.299 368.779 118.222C368.704 118.144 368.648 118.052 368.615 117.95C368.581 117.848 368.571 117.74 368.585 117.633C368.573 117.527 368.585 117.419 368.619 117.317C368.654 117.216 368.711 117.123 368.785 117.046C368.86 116.969 368.95 116.909 369.051 116.871C369.151 116.833 369.258 116.817 369.366 116.825C369.882 116.819 370.124 117.123 370.152 117.639Z" fill="black" />
            <path d="M351.32 116.376C351.425 116.367 351.531 116.381 351.63 116.418C351.729 116.455 351.819 116.512 351.893 116.587C351.968 116.662 352.025 116.753 352.06 116.852C352.096 116.951 352.11 117.057 352.101 117.162C352.116 117.27 352.105 117.38 352.072 117.484C352.039 117.588 351.982 117.683 351.908 117.763C351.833 117.842 351.742 117.904 351.64 117.944C351.538 117.984 351.429 118.001 351.32 117.993C351.108 117.993 350.903 117.908 350.753 117.758C350.602 117.607 350.518 117.403 350.518 117.19C350.518 116.977 350.602 116.773 350.753 116.622C350.903 116.472 351.108 116.387 351.32 116.387V116.376Z" fill="black" />
            <path d="M333.1 152.883C333.1 152.987 333.079 153.089 333.038 153.184C332.997 153.28 332.936 153.365 332.86 153.436C332.784 153.507 332.695 153.56 332.596 153.595C332.499 153.63 332.395 153.643 332.291 153.635C332.19 153.645 332.088 153.633 331.992 153.6C331.896 153.566 331.808 153.512 331.735 153.44C331.662 153.369 331.606 153.283 331.57 153.188C331.535 153.092 331.52 152.99 331.528 152.888C331.514 152.782 331.523 152.673 331.556 152.57C331.588 152.467 331.642 152.372 331.715 152.293C331.788 152.213 331.877 152.151 331.976 152.108C332.076 152.067 332.184 152.048 332.291 152.052C332.791 152.041 333.072 152.327 333.1 152.883Z" fill="black" />
            <path d="M322.276 113.591C321.939 113.591 321.675 113.282 321.68 112.777C321.671 112.669 321.687 112.562 321.725 112.461C321.765 112.361 321.826 112.271 321.905 112.198C321.984 112.124 322.079 112.07 322.182 112.039C322.285 112.009 322.394 112.001 322.5 112.019C322.608 112.014 322.716 112.032 322.816 112.071C322.916 112.112 323.006 112.173 323.081 112.252C323.155 112.33 323.211 112.424 323.245 112.526C323.28 112.628 323.292 112.737 323.281 112.844C323.247 113.327 322.977 113.613 322.276 113.591Z" fill="black" />
            <path d="M366.379 93.0129C366.274 93.0196 366.17 93.0039 366.072 92.9668C365.975 92.9292 365.886 92.8714 365.813 92.7967C365.74 92.722 365.684 92.6328 365.649 92.5339C365.614 92.4357 365.601 92.3312 365.61 92.2268C365.596 92.1207 365.606 92.0129 365.641 91.9118C365.675 91.8102 365.733 91.7187 365.81 91.6434C365.885 91.5682 365.978 91.5121 366.08 91.4784C366.182 91.4452 366.29 91.4363 366.396 91.452C366.502 91.4396 366.61 91.4503 366.712 91.4845C366.814 91.5182 366.907 91.5744 366.985 91.6479C367.063 91.722 367.123 91.8124 367.162 91.9124C367.201 92.0123 367.217 92.1196 367.21 92.2268C367.221 92.7265 366.929 93.0016 366.379 93.0129Z" fill="black" />
            <path d="M340.519 114.062C340.12 114.001 339.783 113.838 339.766 113.321C339.748 113.215 339.754 113.105 339.782 113.001C339.812 112.896 339.863 112.8 339.935 112.717C340.005 112.635 340.094 112.57 340.192 112.526C340.291 112.482 340.399 112.46 340.507 112.462C340.615 112.449 340.725 112.46 340.828 112.493C340.931 112.527 341.026 112.582 341.105 112.656C341.185 112.73 341.247 112.82 341.289 112.92C341.33 113.021 341.349 113.129 341.344 113.237C341.366 113.759 341.052 114.023 340.519 114.062Z" fill="black" />
            <path d="M342.247 77.1064C342.247 77.5949 342 77.9149 341.472 77.9093C341.37 77.9188 341.268 77.907 341.172 77.8739C341.075 77.8413 340.986 77.788 340.912 77.7189C340.837 77.6493 340.777 77.5651 340.738 77.4708C340.698 77.377 340.678 77.2759 340.68 77.1738C340.664 77.0671 340.671 76.9581 340.701 76.8543C340.731 76.7504 340.784 76.6544 340.855 76.5735C340.926 76.4921 341.014 76.427 341.113 76.3838C341.212 76.34 341.319 76.3181 341.427 76.3203C341.921 76.2866 342.213 76.5786 342.247 77.1064Z" fill="black" />
            <path d="M336.16 141.305C336.688 141.305 336.986 141.586 336.98 142.097C336.991 142.205 336.979 142.314 336.943 142.416C336.907 142.518 336.85 142.611 336.774 142.689C336.699 142.766 336.607 142.826 336.506 142.865C336.405 142.903 336.296 142.919 336.189 142.911C336.086 142.918 335.984 142.903 335.889 142.866C335.793 142.83 335.706 142.775 335.634 142.702C335.562 142.63 335.506 142.543 335.47 142.447C335.433 142.352 335.418 142.25 335.425 142.147C335.41 142.042 335.419 141.936 335.449 141.834C335.479 141.732 335.531 141.639 335.601 141.559C335.671 141.479 335.757 141.415 335.853 141.371C335.95 141.328 336.054 141.305 336.16 141.305Z" fill="black" />
            <path d="M348.288 92.5749C348.18 92.5777 348.072 92.5558 347.973 92.5121C347.875 92.4677 347.787 92.402 347.717 92.32C347.646 92.2375 347.595 92.1409 347.566 92.0365C347.538 91.9321 347.533 91.8226 347.553 91.7159C347.544 91.6143 347.559 91.5115 347.595 91.4161C347.631 91.3206 347.688 91.2341 347.762 91.1634C347.835 91.0927 347.924 91.0388 348.021 91.0062C348.118 90.9736 348.22 90.963 348.322 90.9747C348.43 90.9663 348.539 90.9826 348.64 91.0213C348.741 91.0601 348.833 91.1207 348.908 91.1993C348.983 91.2774 349.04 91.3717 349.075 91.4745C349.109 91.5772 349.12 91.6867 349.108 91.7945C349.111 91.9012 349.092 92.0079 349.052 92.1067C349.012 92.2055 348.95 92.2948 348.873 92.3683C348.795 92.4424 348.703 92.4986 348.603 92.5345C348.502 92.5699 348.394 92.5839 348.288 92.5749Z" fill="black" />
            <path d="M380.236 85.3319C380.321 84.8659 380.568 84.5515 381.09 84.5852C381.193 84.5801 381.295 84.5981 381.39 84.6368C381.485 84.6756 381.57 84.7351 381.639 84.8103C381.709 84.8861 381.761 84.976 381.793 85.0736C381.824 85.1713 381.833 85.2752 381.82 85.3768C381.83 85.4841 381.816 85.593 381.78 85.6946C381.744 85.7963 381.685 85.8889 381.61 85.9653C381.533 86.0422 381.441 86.1012 381.34 86.1382C381.239 86.1753 381.13 86.1893 381.022 86.1798C380.517 86.1685 380.27 85.8373 380.236 85.3319Z" fill="black" />
            <path d="M305.19 112.322C305.157 112.833 304.898 113.164 304.399 113.159C304.291 113.164 304.184 113.147 304.084 113.106C303.984 113.066 303.893 113.005 303.82 112.926C303.746 112.848 303.69 112.754 303.656 112.652C303.623 112.549 303.611 112.44 303.624 112.333C303.611 112.233 303.62 112.13 303.652 112.033C303.684 111.937 303.738 111.849 303.808 111.775C303.879 111.702 303.965 111.645 304.061 111.61C304.156 111.574 304.258 111.561 304.359 111.57C304.464 111.559 304.57 111.572 304.67 111.605C304.771 111.638 304.863 111.691 304.941 111.762C305.019 111.833 305.082 111.919 305.125 112.016C305.168 112.113 305.19 112.217 305.19 112.322Z" fill="black" />
            <path d="M313.433 72.5135C313.394 73.2378 313.147 73.5523 312.641 73.5635C312.54 73.5759 312.438 73.5663 312.34 73.5349C312.243 73.504 312.155 73.4523 312.079 73.3833C312.004 73.3148 311.945 73.2305 311.905 73.1362C311.865 73.0425 311.846 72.9414 311.85 72.8392C311.816 72.362 312.209 71.8903 312.653 71.997C312.943 72.1183 313.208 72.294 313.433 72.5135Z" fill="black" />
            <path d="M298.312 97.7798C298.32 97.8848 298.305 97.9898 298.266 98.088C298.228 98.1857 298.168 98.2733 298.09 98.3446C298.013 98.4159 297.92 98.4693 297.82 98.4996C297.719 98.5299 297.613 98.5372 297.509 98.5209C297.407 98.5271 297.304 98.5108 297.209 98.4738C297.113 98.4367 297.026 98.3794 296.954 98.3059C296.882 98.2329 296.827 98.1447 296.792 98.0482C296.757 97.9516 296.743 97.8483 296.751 97.7461C296.74 97.6389 296.752 97.5299 296.786 97.4277C296.821 97.3256 296.878 97.2324 296.953 97.1543C297.028 97.0768 297.119 97.0167 297.22 96.978C297.321 96.9398 297.43 96.9241 297.538 96.932C298.043 96.8927 298.307 97.2183 298.312 97.7798Z" fill="black" />
            <path d="M289.278 136.488C289.384 136.476 289.493 136.487 289.595 136.52C289.697 136.553 289.791 136.607 289.871 136.68C289.95 136.752 290.014 136.84 290.057 136.939C290.1 137.037 290.121 137.144 290.12 137.251C290.133 137.358 290.123 137.466 290.09 137.568C290.057 137.67 290.002 137.764 289.93 137.843C289.857 137.922 289.768 137.985 289.669 138.026C289.57 138.067 289.463 138.086 289.356 138.082C289.251 138.086 289.147 138.07 289.049 138.033C288.95 137.997 288.86 137.942 288.783 137.871C288.707 137.799 288.644 137.714 288.601 137.619C288.557 137.524 288.532 137.42 288.528 137.316C288.524 137.211 288.54 137.106 288.577 137.008C288.613 136.91 288.668 136.82 288.74 136.743C288.81 136.666 288.896 136.604 288.991 136.56C289.087 136.516 289.189 136.492 289.294 136.488H289.278Z" fill="black" />
            <path d="M283.837 136.134C283.735 136.135 283.634 136.113 283.542 136.071C283.449 136.028 283.367 135.966 283.3 135.889C283.234 135.811 283.185 135.72 283.158 135.622C283.13 135.524 283.125 135.421 283.141 135.32C283.133 135.213 283.149 135.106 283.189 135.006C283.227 134.907 283.288 134.816 283.366 134.743C283.444 134.669 283.537 134.614 283.639 134.58C283.741 134.547 283.849 134.537 283.955 134.551C284.065 134.544 284.175 134.563 284.276 134.605C284.378 134.646 284.469 134.71 284.543 134.791C284.618 134.872 284.673 134.968 284.705 135.074C284.737 135.179 284.746 135.29 284.73 135.398C284.713 135.853 284.421 136.145 283.837 136.134Z" fill="black" />
            <path d="M365.486 129.093C364.925 129.127 364.7 128.852 364.7 128.369C364.685 128.262 364.696 128.152 364.731 128.049C364.766 127.947 364.826 127.854 364.904 127.779C364.983 127.703 365.078 127.648 365.182 127.617C365.286 127.586 365.396 127.58 365.503 127.6C365.611 127.593 365.719 127.61 365.82 127.65C365.92 127.691 366.011 127.753 366.084 127.833C366.157 127.912 366.212 128.007 366.243 128.111C366.274 128.214 366.283 128.324 366.267 128.431C366.276 128.531 366.262 128.632 366.224 128.725C366.186 128.818 366.126 128.901 366.049 128.966C365.973 129.031 365.881 129.077 365.783 129.099C365.685 129.121 365.583 129.119 365.486 129.093Z" fill="black" />
            <path d="M301.805 105.225C301.59 105.464 301.328 105.655 301.036 105.786C300.925 105.81 300.811 105.807 300.703 105.779C300.594 105.75 300.494 105.695 300.41 105.621C300.326 105.546 300.262 105.452 300.221 105.347C300.18 105.242 300.165 105.129 300.176 105.017C300.166 104.917 300.178 104.814 300.212 104.719C300.245 104.624 300.299 104.536 300.37 104.464C300.44 104.391 300.526 104.334 300.621 104.298C300.715 104.262 300.817 104.247 300.918 104.254C301.423 104.242 301.692 104.517 301.805 105.225Z" fill="black" />
            <path d="M393.049 71.1267C393.01 71.6881 392.74 71.9745 392.24 71.9745C392.139 71.9857 392.036 71.9745 391.939 71.9408C391.843 71.9077 391.755 71.8532 391.682 71.7819C391.609 71.7106 391.552 71.6236 391.517 71.5281C391.482 71.4321 391.468 71.3299 391.477 71.2277C391.459 71.121 391.465 71.0116 391.495 70.9071C391.526 70.8033 391.579 70.7072 391.651 70.6264C391.723 70.5455 391.812 70.4821 391.912 70.4405C392.013 70.3984 392.121 70.3799 392.229 70.3855C392.332 70.3782 392.436 70.3911 392.533 70.4248C392.631 70.4585 392.721 70.5113 392.797 70.5809C392.874 70.65 392.936 70.7342 392.979 70.828C393.022 70.9217 393.046 71.0234 393.049 71.1267Z" fill="black" />
            <path d="M357.957 65.6636C357.856 65.663 357.755 65.6405 357.662 65.5979C357.57 65.5552 357.488 65.4934 357.421 65.4165C357.355 65.339 357.305 65.2486 357.277 65.1509C357.248 65.0532 357.241 64.9505 357.255 64.8494C357.251 64.745 357.269 64.6406 357.308 64.5434C357.347 64.4463 357.408 64.3587 357.483 64.2868C357.56 64.215 357.651 64.1605 357.749 64.1268C357.849 64.0931 357.954 64.0808 358.058 64.0914C358.166 64.0864 358.274 64.1044 358.374 64.1442C358.474 64.1846 358.565 64.2458 358.639 64.3244C358.713 64.4031 358.769 64.4963 358.804 64.5984C358.838 64.7012 358.85 64.8096 358.839 64.9168C358.833 65.366 358.541 65.6636 357.957 65.6636Z" fill="black" />
            <path d="M372.477 106.842C372.533 106.365 372.769 106.039 373.291 106.062C373.399 106.058 373.506 106.077 373.605 106.12C373.704 106.163 373.792 106.227 373.863 106.309C373.934 106.39 373.986 106.486 374.016 106.59C374.045 106.694 374.05 106.803 374.032 106.909C374.037 107.011 374.022 107.113 373.985 107.208C373.948 107.303 373.891 107.389 373.818 107.461C373.745 107.532 373.658 107.587 373.562 107.621C373.466 107.656 373.364 107.67 373.263 107.662C372.735 107.662 372.505 107.325 372.477 106.842Z" fill="black" />
            <path d="M334.566 61.8062C335.127 61.7332 335.436 61.9859 335.414 62.5137C335.417 62.6226 335.398 62.7304 335.357 62.8309C335.315 62.9314 335.253 63.0218 335.174 63.0965C335.094 63.1706 335 63.2273 334.897 63.2621C334.795 63.2975 334.685 63.3104 334.577 63.2998C334.469 63.3042 334.361 63.2846 334.261 63.2425C334.16 63.2004 334.07 63.1375 333.997 63.0572C333.924 62.9769 333.869 62.882 333.836 62.7781C333.804 62.6748 333.794 62.5653 333.808 62.4575C333.814 61.9466 334.089 61.7276 334.566 61.8062Z" fill="black" />
            <path d="M293.573 144.803C293.405 144.943 293.062 145.398 292.9 145.336C292.701 145.271 292.517 145.166 292.36 145.028C292.203 144.891 292.075 144.722 291.984 144.534C291.855 144.118 292.259 143.798 292.731 143.792C293.203 143.787 293.489 144.079 293.573 144.803Z" fill="black" />
            <path d="M329.508 158.419C329.603 157.723 329.895 157.363 330.367 157.453C330.659 157.509 330.889 157.885 331.153 158.116C330.87 158.449 330.539 158.739 330.17 158.975C330.052 159.008 329.682 158.548 329.508 158.419Z" fill="black" />
            <path d="M329.367 128.043C328.872 128.111 328.524 127.998 328.552 127.437C328.543 127.331 328.558 127.224 328.595 127.124C328.633 127.024 328.691 126.934 328.767 126.859C328.844 126.785 328.935 126.728 329.036 126.693C329.136 126.658 329.244 126.646 329.35 126.656C329.451 126.65 329.552 126.665 329.647 126.701C329.742 126.737 329.829 126.792 329.9 126.864C329.972 126.935 330.027 127.022 330.063 127.117C330.099 127.212 330.114 127.313 330.108 127.414C330.13 127.942 329.833 128.127 329.367 128.043Z" fill="black" />
            <path d="M382.763 129.031C382.725 128.916 382.714 128.793 382.733 128.674C382.751 128.554 382.798 128.44 382.87 128.341C382.941 128.243 383.035 128.163 383.143 128.108C383.251 128.054 383.371 128.026 383.493 128.026C383.987 127.97 384.29 128.341 384.318 129.031H382.763Z" fill="black" />
            <path d="M321.854 166.723L320.731 167.717C320.226 167.273 320.17 166.847 320.524 166.47C320.877 166.094 321.394 166.173 321.854 166.723Z" fill="black" />
            <path d="M314.549 170.636L312.972 170.451C313.067 169.89 313.365 169.626 313.853 169.671C314.342 169.716 314.606 170.002 314.549 170.636Z" fill="black" />
            <path d="M315.785 62.0084L317.25 61.8848C317.346 62.4463 317.099 62.7663 316.616 62.8113C316.133 62.8562 315.768 62.626 315.785 62.0084Z" fill="black" />
            <path d="M298.946 146.488L297.368 146.707C297.273 146.218 297.436 145.904 297.851 145.791C298.379 145.651 298.761 145.848 298.946 146.488Z" fill="black" />
            <path d="M384.222 61.8455C384.244 61.9477 384.241 62.0538 384.216 62.1555C384.19 62.2565 384.143 62.3508 384.076 62.4311C384.009 62.512 383.925 62.576 383.829 62.6192C383.734 62.663 383.63 62.6844 383.526 62.6821C382.965 62.727 382.723 62.4182 382.622 61.8455H384.222Z" fill="black" />
            <path d="M326.245 137.768C326.183 138.094 326.245 138.531 326.037 138.722C325.829 138.913 325.386 138.947 325.043 139.042L324.937 138.801L325.964 137.678L326.245 137.768Z" fill="black" />
            <path d="M269.907 124.86L269.711 123.372L269.919 123.243C270.121 123.484 270.48 123.709 270.48 123.978C270.48 124.248 270.3 124.618 270.194 124.938L269.907 124.86Z" fill="black" />
            <path d="M303.595 131.165C303.005 130.547 303.033 130.289 303.595 129.705V131.165Z" fill="black" />
            <path d="M399.86 115.747C399.821 116.342 399.793 116.752 399.765 117.207C399.198 116.724 399.198 116.471 399.86 115.747Z" fill="black" />
            <path d="M271.468 136.039C272.136 136.454 272.091 136.881 271.692 137.38L271.468 136.039Z" fill="black" />
            <path d="M281.052 158.02L282.175 158.172L282.13 158.503L281.007 158.301L281.052 158.02Z" fill="black" />
            <path d="M296.061 67.5782L297.24 67.0167C297.088 67.6793 296.858 67.8084 296.061 67.5782Z" fill="black" />
            <path d="M243.125 240.849C240.908 240.804 231.335 240.849 223.339 248.148C219.344 251.85 216.507 256.63 215.17 261.909C209.207 285.746 203.246 309.595 197.287 333.458C196.926 334.895 196.925 336.397 197.283 337.835C197.64 339.271 198.346 340.598 199.337 341.698C200.328 342.799 201.575 343.638 202.967 344.142C204.359 344.647 205.854 344.801 207.32 344.592C220.567 342.681 233.676 339.919 246.567 336.321C257.637 333.232 268.527 329.534 279.189 325.244C281.05 324.499 282.723 323.35 284.085 321.88C285.448 320.41 286.467 318.655 287.069 316.742C287.67 314.83 287.838 312.808 287.562 310.822C287.286 308.836 286.572 306.937 285.472 305.261L243.125 240.849Z" fill="white" />
            <path d="M243.125 240.849C240.908 240.804 231.335 240.849 223.339 248.148C219.344 251.85 216.507 256.63 215.17 261.909C209.207 285.746 203.246 309.595 197.287 333.458C196.926 334.895 196.925 336.397 197.283 337.835C197.64 339.271 198.346 340.598 199.337 341.698C200.328 342.799 201.575 343.638 202.967 344.142C204.359 344.647 205.854 344.801 207.32 344.592C234.932 340.609 261.885 332.934 287.454 321.774C288.3 321.405 289.056 320.856 289.669 320.164C290.281 319.473 290.735 318.656 290.999 317.771C291.262 316.886 291.33 315.954 291.195 315.04C291.061 314.127 290.729 313.253 290.222 312.481L243.125 240.849Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M274.089 327.293L250.435 335.154C251.23 338.556 250.929 342.123 249.576 345.344C246.504 352.576 238.879 355.299 231.513 357.815C228.24 358.938 221.058 360.802 202.266 360.824C193.928 360.824 187.011 360.459 182.171 360.105C181.887 359.912 181.679 359.626 181.581 359.297C181.499 359.022 181.499 358.73 181.581 358.455C180.784 270.219 179.391 206.026 179.481 192.669C179.509 187.767 179.835 175.297 172.598 161.703L172.468 161.462C169.305 155.663 164.986 150.574 159.779 146.508C154.572 142.443 148.587 139.487 142.194 137.824C134.445 135.745 126.358 135.237 118.41 136.33C113.256 137.044 96.1981 140.822 82.9754 156.246C76.6869 163.59 71.701 171.63 68.5231 187.076C65.2721 202.882 62.0268 229.361 68.0907 264.06C70.5545 276.878 73.8593 289.52 77.9839 301.903C83.2786 317.776 86.2993 326.608 93.4637 337.169C99.4568 346.057 106.868 353.903 115.4 360.392C127.04 369.111 137.691 372.806 145.507 375.445C150.217 377.041 155.018 378.353 159.886 379.375V399.588H181.581V382.183C203.545 384.485 225.745 381.707 246.465 374.064C251.944 372.075 256.956 368.981 261.188 364.972C265.42 360.963 268.781 356.127 271.063 350.762C271.215 350.398 271.366 350.021 271.512 349.64C274.236 342.526 275.123 334.84 274.089 327.293ZM131.734 344.923C120.296 337.293 114.463 328.146 109.376 319.977C103.374 310.359 94.216 292.661 90.8809 267.081C85.7867 242.448 85.2044 217.094 89.1628 192.253C92.6888 170.721 108.107 155.567 124.423 154.837C135.714 154.326 147.056 160.76 152.94 168.598C158.398 175.864 158.673 187.447 160.122 210.316C160.307 213.224 160.453 215.975 160.565 218.547C160.341 264.214 160.112 309.88 159.88 355.546C149.82 354.161 140.201 350.53 131.734 344.923Z" fill="white" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M307.031 458.964C307.093 459.127 307.234 459.306 307.205 459.452C307.168 459.763 307.042 460.055 306.84 460.295C306.723 460.36 306.591 460.393 306.456 460.393C306.321 460.393 306.189 460.36 306.071 460.295C305.857 460.066 305.724 459.775 305.689 459.464C305.689 459.312 305.858 459.121 305.953 458.947L307.031 458.964Z" fill="black" />
            <path d="M289.244 458.964C289.177 459.452 289.047 459.963 288.469 459.969C288.183 459.969 287.874 459.683 287.621 459.469C287.515 459.374 287.531 459.138 287.492 458.964H289.244Z" fill="black" />
            <path d="M316.79 482.389C316.274 482.344 315.931 482.119 315.931 481.602C315.919 481.495 315.931 481.386 315.966 481.284C316.001 481.182 316.058 481.089 316.133 481.011C316.208 480.933 316.3 480.873 316.401 480.834C316.501 480.796 316.609 480.78 316.717 480.788C316.921 480.793 317.114 480.875 317.259 481.017C317.405 481.159 317.49 481.352 317.499 481.555C317.509 481.757 317.44 481.956 317.309 482.111C317.178 482.266 316.992 482.365 316.79 482.389Z" fill="black" />
            <path d="M284.147 554.1C284.253 554.1 284.359 554.122 284.457 554.164C284.555 554.207 284.644 554.27 284.716 554.349C284.788 554.427 284.843 554.52 284.878 554.621C284.912 554.723 284.925 554.83 284.916 554.936C284.921 555.046 284.904 555.157 284.865 555.259C284.826 555.362 284.767 555.456 284.69 555.534C284.613 555.613 284.521 555.675 284.418 555.716C284.316 555.757 284.207 555.777 284.097 555.774C283.987 555.771 283.879 555.745 283.779 555.698C283.68 555.651 283.591 555.584 283.519 555.501C283.447 555.419 283.392 555.322 283.359 555.217C283.326 555.112 283.315 555.001 283.327 554.892C283.324 554.784 283.343 554.678 283.383 554.578C283.423 554.479 283.484 554.389 283.561 554.314C283.639 554.24 283.731 554.182 283.831 554.145C283.932 554.108 284.039 554.093 284.147 554.1Z" fill="black" />
            <path d="M268.375 567.171C268.29 567.671 268.038 567.979 267.527 567.968C267.418 567.974 267.309 567.957 267.208 567.918C267.107 567.878 267.016 567.817 266.942 567.738C266.867 567.659 266.811 567.564 266.777 567.463C266.743 567.362 266.732 567.25 266.746 567.143C266.741 567.036 266.76 566.929 266.801 566.828C266.842 566.727 266.903 566.637 266.983 566.564C267.061 566.486 267.156 566.43 267.258 566.396C267.361 566.362 267.469 566.351 267.577 566.362C268.094 566.368 268.318 566.705 268.375 567.171Z" fill="black" />
            <path d="M292.624 466.51C292.562 466.987 292.321 467.307 291.81 467.313C291.701 467.324 291.592 467.311 291.49 467.275C291.387 467.239 291.294 467.182 291.217 467.105C291.14 467.029 291.08 466.937 291.043 466.835C291.006 466.733 290.991 466.624 291.001 466.516C290.992 466.408 291.006 466.299 291.043 466.197C291.08 466.095 291.139 466.002 291.215 465.926C291.291 465.849 291.384 465.789 291.486 465.751C291.587 465.714 291.696 465.699 291.804 465.707C292.315 465.696 292.568 466.005 292.624 466.51Z" fill="black" />
            <path d="M292.433 581.455C292.542 581.444 292.651 581.46 292.754 581.494C292.857 581.528 292.952 581.584 293.031 581.663C293.111 581.736 293.175 581.825 293.218 581.926C293.26 582.022 293.282 582.134 293.281 582.241C293.298 582.348 293.29 582.46 293.258 582.561C293.226 582.662 293.171 582.757 293.096 582.836C293.022 582.915 292.931 582.976 292.83 583.016C292.728 583.049 292.62 583.066 292.512 583.055C292.405 583.072 292.296 583.061 292.194 583.027C292.091 582.999 291.996 582.943 291.916 582.87C291.836 582.797 291.774 582.712 291.732 582.611C291.691 582.51 291.671 582.404 291.675 582.297C291.665 582.19 291.677 582.084 291.711 581.983C291.744 581.882 291.798 581.786 291.869 581.707C291.94 581.629 292.027 581.567 292.125 581.522C292.222 581.477 292.327 581.455 292.433 581.455Z" fill="black" />
            <path d="M331.832 559.799C331.831 559.904 331.808 560.009 331.764 560.105C331.72 560.201 331.657 560.287 331.578 560.357C331.499 560.428 331.407 560.48 331.307 560.513C331.206 560.545 331.1 560.557 330.995 560.545C330.783 560.539 330.583 560.448 330.439 560.294C330.294 560.14 330.216 559.935 330.223 559.723C330.229 559.511 330.32 559.311 330.474 559.166C330.628 559.022 330.834 558.944 331.046 558.951C331.551 558.968 331.804 559.277 331.832 559.799Z" fill="black" />
            <path d="M308.015 538.457C308.121 538.446 308.229 538.458 308.331 538.491C308.433 538.523 308.527 538.577 308.608 538.649C308.688 538.72 308.752 538.807 308.797 538.905C308.842 539.002 308.866 539.108 308.868 539.215C308.873 539.327 308.856 539.439 308.816 539.544C308.776 539.649 308.716 539.744 308.638 539.824C308.56 539.905 308.467 539.969 308.363 540.012C308.26 540.055 308.149 540.076 308.037 540.074C307.823 540.074 307.617 539.989 307.465 539.837C307.313 539.686 307.229 539.48 307.229 539.266C307.229 539.051 307.313 538.846 307.465 538.694C307.617 538.542 307.823 538.457 308.037 538.457H308.015Z" fill="black" />
            <path d="M309.772 503.163C309.768 503.271 309.744 503.377 309.699 503.475C309.655 503.574 309.592 503.662 309.513 503.736C309.435 503.809 309.343 503.867 309.242 503.905C309.142 503.943 309.035 503.961 308.927 503.958C308.819 503.954 308.713 503.93 308.615 503.886C308.517 503.841 308.428 503.778 308.355 503.7C308.28 503.621 308.223 503.529 308.185 503.429C308.147 503.328 308.129 503.22 308.132 503.113C308.134 503.001 308.159 502.891 308.205 502.79C308.252 502.689 308.319 502.598 308.402 502.524C308.486 502.45 308.584 502.394 308.69 502.361C308.796 502.326 308.908 502.315 309.019 502.326C309.53 502.349 309.738 502.675 309.772 503.163Z" fill="black" />
            <path d="M325.903 509.03C325.896 509.136 325.868 509.239 325.82 509.332C325.771 509.426 325.704 509.509 325.623 509.576C325.541 509.643 325.447 509.692 325.345 509.721C325.243 509.75 325.137 509.757 325.033 509.743C324.925 509.739 324.82 509.714 324.722 509.669C324.625 509.623 324.538 509.559 324.466 509.479C324.395 509.399 324.34 509.305 324.306 509.204C324.271 509.102 324.257 508.994 324.265 508.888C324.273 508.78 324.302 508.676 324.352 508.581C324.401 508.485 324.469 508.401 324.552 508.332C324.634 508.264 324.73 508.213 324.833 508.182C324.936 508.152 325.044 508.142 325.151 508.154C325.656 508.216 325.892 508.508 325.903 509.03Z" fill="black" />
            <path d="M274.252 514.061C274.78 514.117 275.089 514.364 275.089 514.875C275.089 515.087 275.005 515.29 274.856 515.439C274.706 515.589 274.503 515.673 274.292 515.673C274.08 515.673 273.877 515.589 273.728 515.439C273.579 515.29 273.495 515.087 273.495 514.875C273.488 514.771 273.503 514.668 273.539 514.57C273.574 514.472 273.629 514.382 273.699 514.306C273.77 514.23 273.856 514.169 273.951 514.127C274.046 514.085 274.149 514.063 274.252 514.061Z" fill="black" />
            <path d="M296.441 572.303C295.88 572.303 295.593 572.05 295.577 571.539C295.57 571.326 295.647 571.124 295.792 570.966C295.937 570.815 296.137 570.725 296.349 570.719C296.56 570.708 296.765 570.787 296.92 570.933C297.074 571.079 297.164 571.275 297.171 571.489C297.185 571.59 297.176 571.696 297.146 571.797C297.115 571.893 297.063 571.988 296.994 572.067C296.925 572.14 296.839 572.202 296.744 572.247C296.648 572.286 296.545 572.303 296.441 572.303Z" fill="black" />
            <path d="M295.369 541.562C295.261 541.569 295.153 541.554 295.052 541.518C294.95 541.481 294.857 541.425 294.778 541.351C294.699 541.277 294.636 541.188 294.593 541.089C294.55 540.99 294.527 540.884 294.527 540.776C294.514 540.669 294.525 540.56 294.559 540.458C294.593 540.356 294.649 540.262 294.722 540.184C294.796 540.105 294.886 540.043 294.986 540.003C295.086 539.963 295.194 539.945 295.302 539.951C295.408 539.936 295.516 539.944 295.619 539.976C295.721 540.008 295.815 540.063 295.894 540.136C295.973 540.209 296.034 540.299 296.074 540.399C296.112 540.499 296.129 540.607 296.121 540.714C296.131 540.82 296.119 540.927 296.086 541.028C296.053 541.129 296 541.222 295.929 541.302C295.859 541.381 295.773 541.445 295.676 541.49C295.579 541.535 295.475 541.559 295.369 541.562Z" fill="black" />
            <path d="M279.109 571.023C279.042 571.528 278.818 571.865 278.301 571.87C278.088 571.87 277.884 571.786 277.733 571.635C277.582 571.483 277.498 571.281 277.498 571.067C277.498 570.854 277.582 570.652 277.733 570.5C277.884 570.349 278.088 570.265 278.301 570.265C278.829 570.248 279.048 570.568 279.109 571.023Z" fill="black" />
            <path d="M309.086 569.192C309.592 569.276 309.884 569.529 309.867 570.046C309.875 570.152 309.858 570.259 309.819 570.36C309.78 570.461 309.719 570.551 309.64 570.624C309.561 570.697 309.467 570.753 309.364 570.787C309.262 570.815 309.153 570.826 309.047 570.809C308.939 570.815 308.831 570.798 308.73 570.759C308.63 570.719 308.54 570.658 308.466 570.579C308.392 570.5 308.336 570.405 308.303 570.304C308.269 570.203 308.259 570.091 308.272 569.984C308.274 569.877 308.296 569.776 308.337 569.675C308.38 569.58 308.44 569.495 308.515 569.417C308.591 569.344 308.68 569.288 308.778 569.248C308.876 569.209 308.981 569.192 309.086 569.192Z" fill="black" />
            <path d="M273.175 520.237C273.118 520.72 272.894 521.057 272.377 521.068C272.269 521.079 272.161 521.065 272.059 521.029C271.957 520.993 271.864 520.936 271.786 520.861C271.708 520.785 271.647 520.694 271.608 520.594C271.569 520.493 271.551 520.385 271.557 520.277C271.542 520.17 271.55 520.061 271.583 519.957C271.616 519.854 271.671 519.76 271.746 519.681C271.821 519.602 271.912 519.542 272.014 519.504C272.116 519.467 272.224 519.453 272.332 519.463C272.854 519.446 273.096 519.76 273.175 520.237Z" fill="black" />
            <path d="M305.313 465.05C305.315 465.156 305.295 465.261 305.254 465.358C305.212 465.456 305.151 465.543 305.074 465.615C304.997 465.687 304.905 465.742 304.805 465.776C304.705 465.81 304.599 465.823 304.494 465.814C304.282 465.814 304.079 465.73 303.93 465.58C303.78 465.431 303.696 465.228 303.696 465.017C303.696 464.805 303.78 464.602 303.93 464.453C304.079 464.303 304.282 464.219 304.494 464.219C305.027 464.23 305.28 464.528 305.313 465.05Z" fill="black" />
            <path d="M281.063 463.422C280.956 463.429 280.848 463.414 280.747 463.377C280.645 463.34 280.552 463.283 280.474 463.209C280.396 463.134 280.335 463.044 280.293 462.945C280.251 462.845 280.231 462.738 280.232 462.63C280.224 462.519 280.241 462.408 280.281 462.304C280.32 462.2 280.382 462.105 280.461 462.027C280.541 461.949 280.637 461.89 280.742 461.852C280.846 461.815 280.958 461.801 281.069 461.811C281.176 461.807 281.281 461.826 281.38 461.867C281.478 461.907 281.567 461.968 281.639 462.046C281.712 462.124 281.768 462.216 281.802 462.317C281.836 462.418 281.849 462.525 281.838 462.63C281.847 462.735 281.832 462.84 281.796 462.939C281.761 463.037 281.704 463.127 281.63 463.203C281.557 463.277 281.469 463.336 281.371 463.374C281.273 463.411 281.168 463.428 281.063 463.422Z" fill="black" />
            <path d="M284.696 500.737C284.752 500.266 284.96 499.935 285.482 499.923C285.589 499.91 285.697 499.92 285.8 499.954C285.902 499.987 285.996 500.042 286.075 500.115C286.153 500.189 286.215 500.278 286.256 500.378C286.295 500.478 286.313 500.585 286.308 500.692C286.321 500.8 286.311 500.908 286.277 501.011C286.244 501.114 286.189 501.207 286.116 501.287C286.043 501.366 285.953 501.428 285.853 501.469C285.754 501.51 285.646 501.529 285.538 501.523C285.033 501.54 284.775 501.226 284.696 500.737Z" fill="black" />
            <path d="M326.56 520.838C327.054 520.906 327.369 521.159 327.357 521.664C327.357 521.875 327.273 522.078 327.124 522.228C326.974 522.377 326.772 522.461 326.56 522.461C326.348 522.461 326.146 522.377 325.996 522.228C325.847 522.078 325.763 521.875 325.763 521.664C325.763 521.131 326.088 520.894 326.56 520.838Z" fill="black" />
            <path d="M281.058 565.7C281.061 565.806 281.042 565.913 281.002 566.009C280.962 566.104 280.903 566.194 280.826 566.267C280.75 566.34 280.659 566.396 280.56 566.435C280.46 566.469 280.354 566.48 280.249 566.475C280.142 566.486 280.033 566.469 279.932 566.435C279.83 566.402 279.738 566.34 279.662 566.267C279.586 566.188 279.528 566.098 279.491 565.992C279.455 565.891 279.441 565.784 279.452 565.677C279.442 565.571 279.456 565.464 279.493 565.363C279.53 565.262 279.588 565.166 279.664 565.093C279.74 565.015 279.832 564.959 279.933 564.919C280.034 564.886 280.142 564.869 280.249 564.88C280.76 564.886 281.018 565.178 281.058 565.7Z" fill="black" />
            <path d="M264.095 560.669C263.88 560.665 263.675 560.577 263.524 560.424C263.373 560.27 263.287 560.064 263.286 559.849C263.284 559.738 263.305 559.626 263.35 559.524C263.394 559.421 263.459 559.328 263.541 559.252C263.623 559.177 263.72 559.119 263.826 559.083C263.933 559.048 264.045 559.036 264.157 559.046C264.264 559.042 264.37 559.06 264.47 559.101C264.569 559.142 264.659 559.203 264.732 559.282C264.805 559.36 264.86 559.453 264.893 559.556C264.927 559.657 264.938 559.766 264.926 559.872C264.926 559.98 264.904 560.086 264.862 560.185C264.82 560.284 264.758 560.374 264.68 560.448C264.603 560.523 264.511 560.58 264.41 560.619C264.309 560.657 264.202 560.674 264.095 560.669Z" fill="black" />
            <path d="M302.54 471.227C302.43 471.228 302.322 471.206 302.222 471.162C302.121 471.119 302.032 471.054 301.959 470.973C301.886 470.891 301.832 470.795 301.799 470.691C301.767 470.586 301.757 470.476 301.771 470.367C301.764 470.26 301.781 470.152 301.821 470.052C301.86 469.953 301.922 469.863 302.001 469.789C302.081 469.716 302.175 469.662 302.278 469.63C302.38 469.598 302.489 469.589 302.596 469.604C302.703 469.597 302.811 469.613 302.911 469.654C303.011 469.694 303.101 469.757 303.173 469.837C303.244 469.917 303.297 470.013 303.327 470.116C303.356 470.22 303.362 470.329 303.343 470.435C303.343 470.54 303.322 470.644 303.281 470.741C303.241 470.837 303.182 470.925 303.107 470.999C303.032 471.073 302.943 471.13 302.846 471.17C302.749 471.209 302.645 471.228 302.54 471.227Z" fill="black" />
            <path d="M321.444 470.895C321.441 471.001 321.416 471.105 321.371 471.2C321.325 471.295 321.261 471.381 321.182 471.45C321.102 471.52 321.009 471.572 320.908 471.604C320.808 471.636 320.702 471.647 320.597 471.636C320.489 471.645 320.382 471.63 320.282 471.591C320.182 471.553 320.092 471.491 320.018 471.413C319.945 471.335 319.89 471.242 319.858 471.139C319.826 471.037 319.817 470.928 319.833 470.822C319.826 470.714 319.843 470.607 319.883 470.507C319.923 470.406 319.984 470.316 320.064 470.243C320.142 470.169 320.237 470.114 320.339 470.081C320.442 470.048 320.551 470.039 320.658 470.053C320.767 470.052 320.874 470.073 320.973 470.116C321.073 470.158 321.163 470.221 321.237 470.301C321.311 470.38 321.367 470.474 321.403 470.576C321.439 470.678 321.453 470.787 321.444 470.895Z" fill="black" />
            <path d="M273.836 533.763C273.73 533.768 273.625 533.749 273.526 533.71C273.428 533.671 273.338 533.611 273.265 533.534C273.191 533.458 273.134 533.367 273.098 533.267C273.062 533.167 273.048 533.061 273.056 532.955C273.056 532.744 273.139 532.54 273.289 532.391C273.439 532.242 273.641 532.157 273.853 532.157C274.064 532.157 274.267 532.242 274.417 532.391C274.566 532.54 274.65 532.744 274.65 532.955C274.659 533.064 274.644 533.173 274.605 533.275C274.568 533.378 274.508 533.471 274.43 533.548C274.353 533.625 274.26 533.684 274.157 533.721C274.055 533.758 273.945 533.773 273.836 533.763Z" fill="black" />
            <path d="M314.129 474.298C314.119 474.51 314.03 474.712 313.879 474.862C313.729 475.014 313.527 475.102 313.314 475.112C313.203 475.113 313.093 475.093 312.99 475.05C312.887 475.008 312.793 474.946 312.715 474.866C312.637 474.787 312.576 474.693 312.535 474.59C312.494 474.486 312.475 474.375 312.478 474.264C312.479 474.157 312.502 474.051 312.546 473.953C312.59 473.855 312.653 473.767 312.732 473.695C312.81 473.622 312.903 473.567 313.005 473.531C313.106 473.496 313.213 473.481 313.32 473.489C313.831 473.489 314.061 473.815 314.129 474.298Z" fill="black" />
            <path d="M322.265 551.281C321.703 551.281 321.456 551.017 321.445 550.501C321.43 550.393 321.441 550.285 321.473 550.182C321.506 550.078 321.561 549.984 321.634 549.904C321.708 549.825 321.798 549.763 321.898 549.722C321.998 549.682 322.106 549.664 322.214 549.67C322.321 549.656 322.429 549.666 322.532 549.7C322.634 549.734 322.727 549.79 322.805 549.866C322.882 549.94 322.941 550.032 322.978 550.133C323.015 550.235 323.028 550.343 323.017 550.45C323.029 550.556 323.018 550.662 322.986 550.763C322.953 550.865 322.9 550.957 322.828 551.036C322.757 551.114 322.67 551.177 322.573 551.219C322.476 551.261 322.371 551.283 322.265 551.281Z" fill="black" />
            <path d="M289.396 592.965C289.351 593.526 289.093 593.807 288.587 593.807C288.477 593.818 288.367 593.802 288.262 593.768C288.158 593.734 288.062 593.672 287.98 593.599C287.9 593.526 287.834 593.437 287.79 593.336C287.746 593.234 287.723 593.122 287.723 593.015C287.723 592.903 287.746 592.791 287.79 592.69C287.834 592.589 287.9 592.499 287.98 592.426C288.062 592.353 288.158 592.297 288.262 592.258C288.367 592.224 288.477 592.207 288.587 592.218C288.69 592.213 288.794 592.224 288.892 592.258C288.99 592.291 289.08 592.342 289.156 592.415C289.231 592.482 289.292 592.572 289.334 592.662C289.375 592.757 289.396 592.864 289.396 592.965Z" fill="black" />
            <path d="M284.388 506.1C284.32 506.594 284.068 506.902 283.562 506.891C283.452 506.901 283.342 506.887 283.237 506.851C283.133 506.815 283.037 506.759 282.956 506.683C282.875 506.609 282.81 506.518 282.766 506.417C282.721 506.316 282.698 506.207 282.698 506.097C282.698 505.987 282.721 505.877 282.766 505.776C282.81 505.675 282.875 505.585 282.956 505.51C283.037 505.435 283.133 505.378 283.237 505.343C283.342 505.307 283.452 505.293 283.562 505.302C284.101 505.308 284.332 505.622 284.388 506.1Z" fill="black" />
            <path d="M269.834 475.965C270.333 476.038 270.642 476.297 270.625 476.802C270.619 477.014 270.53 477.214 270.376 477.359C270.222 477.504 270.017 477.583 269.805 477.577C269.594 477.571 269.393 477.481 269.248 477.327C269.103 477.174 269.024 476.969 269.031 476.757C269.036 476.246 269.345 476.016 269.834 475.965Z" fill="black" />
            <path d="M271.417 557.216C271.309 557.218 271.202 557.196 271.102 557.153C271.003 557.11 270.914 557.046 270.841 556.966C270.769 556.886 270.714 556.791 270.681 556.688C270.647 556.585 270.636 556.476 270.648 556.368C270.64 556.261 270.657 556.153 270.696 556.052C270.736 555.952 270.798 555.861 270.877 555.788C270.956 555.714 271.051 555.66 271.154 555.628C271.257 555.597 271.367 555.588 271.474 555.605C271.582 555.6 271.69 555.619 271.79 555.66C271.89 555.701 271.98 555.764 272.054 555.844C272.127 555.923 272.182 556.018 272.214 556.122C272.247 556.225 272.257 556.334 272.243 556.441C272.246 556.548 272.225 556.654 272.184 556.753C272.143 556.852 272.082 556.941 272.004 557.014C271.926 557.087 271.833 557.143 271.732 557.178C271.631 557.213 271.524 557.226 271.417 557.216Z" fill="black" />
            <path d="M306.003 476.909C306.475 476.987 306.784 477.234 306.762 477.751C306.77 477.858 306.755 477.966 306.716 478.066C306.677 478.167 306.616 478.257 306.537 478.33C306.458 478.403 306.363 478.458 306.26 478.489C306.157 478.519 306.048 478.526 305.942 478.509C305.834 478.517 305.727 478.501 305.626 478.463C305.526 478.425 305.435 478.364 305.361 478.286C305.287 478.208 305.23 478.115 305.197 478.012C305.164 477.91 305.153 477.801 305.167 477.695C305.178 477.201 305.504 476.948 306.003 476.909Z" fill="black" />
            <path d="M270.468 593.358C270.357 593.358 270.248 593.335 270.147 593.296C270.045 593.251 269.953 593.189 269.877 593.105C269.801 593.027 269.742 592.931 269.704 592.83C269.666 592.723 269.649 592.617 269.656 592.504C269.662 592.392 269.69 592.286 269.74 592.19C269.789 592.089 269.858 591.999 269.943 591.932C270.027 591.859 270.126 591.808 270.231 591.775C270.337 591.741 270.448 591.735 270.558 591.746C270.666 591.741 270.773 591.763 270.873 591.803C270.972 591.848 271.06 591.915 271.131 591.993C271.201 592.078 271.252 592.173 271.279 592.28C271.306 592.387 271.309 592.493 271.288 592.6C271.287 592.707 271.265 592.808 271.223 592.903C271.181 592.999 271.119 593.088 271.042 593.156C270.965 593.229 270.875 593.285 270.776 593.319C270.677 593.352 270.572 593.364 270.468 593.358Z" fill="black" />
            <path d="M265.943 590.618C265.942 590.775 265.893 590.932 265.804 591.062C265.714 591.196 265.587 591.297 265.439 591.359C265.291 591.415 265.129 591.432 264.974 591.398C264.818 591.365 264.676 591.286 264.564 591.174C264.453 591.056 264.379 590.916 264.35 590.758C264.321 590.601 264.34 590.438 264.404 590.292C264.467 590.146 264.573 590.023 264.707 589.939C264.841 589.849 264.997 589.804 265.157 589.809C265.673 589.809 265.915 590.107 265.943 590.618Z" fill="black" />
            <path d="M316.38 498.857C316.486 498.868 316.587 498.901 316.68 498.953C316.773 499.005 316.854 499.074 316.92 499.158C316.985 499.242 317.033 499.338 317.061 499.44C317.089 499.543 317.096 499.65 317.082 499.755C317.071 499.859 317.039 499.96 316.989 500.053C316.939 500.145 316.871 500.226 316.789 500.292C316.708 500.358 316.614 500.407 316.514 500.437C316.413 500.467 316.307 500.476 316.203 500.465C316.099 500.454 315.998 500.423 315.905 500.373C315.813 500.323 315.731 500.255 315.666 500.173C315.6 500.091 315.551 499.997 315.521 499.897C315.491 499.796 315.482 499.691 315.493 499.586C315.527 499.076 315.875 498.862 316.38 498.857Z" fill="black" />
            <path d="M297.234 536.172C297.129 536.174 297.024 536.152 296.928 536.11C296.831 536.069 296.745 536.006 296.674 535.928C296.603 535.85 296.55 535.758 296.518 535.658C296.486 535.557 296.475 535.451 296.487 535.347C296.493 535.135 296.583 534.935 296.737 534.79C296.891 534.644 297.095 534.566 297.307 534.572C297.518 534.578 297.719 534.667 297.864 534.821C298.01 534.975 298.088 535.18 298.082 535.392C298.086 535.501 298.068 535.611 298.026 535.712C297.984 535.814 297.92 535.905 297.839 535.979C297.759 536.054 297.663 536.11 297.558 536.143C297.454 536.176 297.343 536.186 297.234 536.172Z" fill="black" />
            <path d="M321.27 587.429C321.163 587.429 321.056 587.406 320.958 587.367C320.859 587.322 320.771 587.26 320.698 587.182C320.626 587.103 320.572 587.008 320.539 586.907C320.506 586.8 320.494 586.693 320.507 586.587C320.498 586.48 320.514 586.373 320.552 586.272C320.592 586.171 320.652 586.076 320.731 586.003C320.81 585.93 320.904 585.873 321.008 585.845C321.11 585.812 321.219 585.8 321.327 585.817C321.434 585.812 321.542 585.834 321.643 585.874C321.743 585.913 321.833 585.975 321.907 586.053C321.981 586.132 322.037 586.227 322.072 586.328C322.106 586.429 322.118 586.542 322.107 586.648C322.11 586.755 322.09 586.862 322.048 586.963C322.006 587.064 321.944 587.154 321.864 587.227C321.785 587.3 321.692 587.356 321.589 587.389C321.487 587.429 321.378 587.44 321.27 587.429Z" fill="black" />
            <path d="M302.473 506.532C302.405 507.026 302.169 507.335 301.647 507.324C301.446 507.307 301.259 507.215 301.123 507.067C300.987 506.919 300.911 506.725 300.911 506.523C300.911 506.322 300.987 506.128 301.123 505.98C301.259 505.832 301.446 505.74 301.647 505.723C302.141 505.746 302.388 506.066 302.473 506.532Z" fill="black" />
            <path d="M288.997 574.144C289.592 574.144 289.878 574.464 289.884 574.936C289.89 575.043 289.874 575.149 289.838 575.25C289.801 575.351 289.745 575.441 289.672 575.52C289.599 575.599 289.511 575.66 289.414 575.705C289.316 575.75 289.21 575.773 289.104 575.773C288.887 575.789 288.675 575.716 288.512 575.57C288.35 575.43 288.25 575.228 288.236 575.015C288.222 574.796 288.294 574.582 288.437 574.419C288.579 574.257 288.781 574.161 288.997 574.144Z" fill="black" />
            <path d="M289.255 526.15C289.256 526.254 289.235 526.358 289.192 526.453C289.15 526.548 289.087 526.634 289.009 526.703C288.931 526.772 288.839 526.824 288.739 526.854C288.639 526.885 288.533 526.894 288.43 526.88C288.322 526.885 288.215 526.867 288.115 526.827C288.015 526.787 287.925 526.726 287.851 526.647C287.777 526.569 287.722 526.475 287.688 526.373C287.654 526.27 287.643 526.162 287.655 526.054C287.651 525.947 287.67 525.839 287.712 525.739C287.754 525.639 287.816 525.55 287.896 525.477C287.975 525.404 288.07 525.349 288.173 525.315C288.276 525.283 288.385 525.272 288.492 525.285C288.997 525.302 289.25 525.622 289.255 526.15Z" fill="black" />
            <path d="M304.842 483.118C304.769 483.596 304.534 483.91 304.011 483.893C303.904 483.903 303.796 483.888 303.696 483.85C303.595 483.812 303.504 483.752 303.43 483.674C303.356 483.597 303.3 483.504 303.266 483.401C303.233 483.3 303.223 483.191 303.237 483.085C303.228 482.978 303.242 482.869 303.28 482.768C303.318 482.667 303.378 482.576 303.455 482.501C303.533 482.426 303.625 482.368 303.727 482.334C303.83 482.299 303.938 482.287 304.045 482.299C304.556 482.299 304.792 482.636 304.842 483.118Z" fill="black" />
            <path d="M304.062 586.171C304 586.648 303.77 586.968 303.248 586.963C303.138 586.974 303.027 586.957 302.923 586.923C302.818 586.884 302.722 586.828 302.641 586.755C302.56 586.682 302.496 586.592 302.451 586.491C302.407 586.39 302.384 586.278 302.384 586.165C302.384 586.059 302.407 585.947 302.451 585.845C302.496 585.744 302.56 585.655 302.641 585.582C302.722 585.509 302.818 585.452 302.923 585.413C303.027 585.379 303.138 585.363 303.248 585.374C303.759 585.368 304 585.699 304.062 586.171Z" fill="black" />
            <path d="M281.53 547.604C281.474 548.081 281.243 548.418 280.732 548.418C280.531 548.406 280.342 548.318 280.202 548.173C280.062 548.028 279.982 547.835 279.978 547.633C279.973 547.432 280.045 547.236 280.179 547.085C280.312 546.934 280.498 546.838 280.699 546.818C281.221 546.807 281.457 547.121 281.53 547.604Z" fill="black" />
            <path d="M291.344 485.37C291.238 485.377 291.131 485.361 291.032 485.323C290.932 485.285 290.842 485.225 290.767 485.149C290.693 485.073 290.635 484.981 290.599 484.881C290.563 484.78 290.549 484.674 290.558 484.567C290.558 484.356 290.642 484.153 290.792 484.003C290.941 483.854 291.144 483.77 291.356 483.77C291.567 483.77 291.77 483.854 291.919 484.003C292.069 484.153 292.153 484.356 292.153 484.567C292.161 484.675 292.146 484.784 292.109 484.886C292.071 484.988 292.011 485.08 291.935 485.156C291.858 485.232 291.765 485.291 291.663 485.328C291.561 485.365 291.452 485.38 291.344 485.37Z" fill="black" />
            <path d="M267.043 586.031C266.938 586.031 266.834 586.008 266.737 585.969C266.641 585.924 266.555 585.862 266.485 585.784C266.415 585.705 266.362 585.61 266.331 585.509C266.3 585.413 266.289 585.306 266.302 585.2C266.309 584.986 266.399 584.79 266.554 584.644C266.708 584.498 266.913 584.419 267.125 584.431C267.337 584.436 267.536 584.526 267.681 584.678C267.826 584.835 267.904 585.037 267.897 585.25C267.901 585.363 267.881 585.469 267.839 585.57C267.796 585.671 267.732 585.761 267.651 585.84C267.57 585.913 267.474 585.969 267.369 586.003C267.264 586.036 267.152 586.042 267.043 586.031Z" fill="black" />
            <path d="M279.452 486.033C279.465 486.142 279.454 486.253 279.42 486.358C279.386 486.462 279.33 486.558 279.255 486.639C279.18 486.719 279.088 486.783 278.987 486.825C278.885 486.866 278.776 486.885 278.666 486.88C278.559 486.895 278.451 486.885 278.348 486.853C278.246 486.82 278.152 486.766 278.073 486.693C277.995 486.62 277.933 486.531 277.892 486.431C277.852 486.331 277.834 486.224 277.84 486.117C277.826 486.01 277.836 485.901 277.868 485.797C277.901 485.694 277.956 485.599 278.029 485.519C278.103 485.439 278.193 485.377 278.293 485.335C278.393 485.294 278.501 485.275 278.609 485.28C278.714 485.272 278.821 485.285 278.921 485.319C279.02 485.353 279.113 485.406 279.192 485.476C279.27 485.547 279.334 485.632 279.379 485.728C279.423 485.824 279.448 485.927 279.452 486.033Z" fill="black" />
            <path d="M280.575 583.734C280.502 584.234 280.283 584.56 279.761 584.554C279.656 584.554 279.553 584.532 279.456 584.492C279.359 584.453 279.272 584.397 279.197 584.318C279.123 584.245 279.064 584.161 279.025 584.06C278.985 583.965 278.964 583.863 278.964 583.757C278.964 583.65 278.985 583.549 279.025 583.454C279.064 583.353 279.123 583.268 279.197 583.195C279.272 583.117 279.359 583.061 279.456 583.021C279.553 582.982 279.656 582.96 279.761 582.96C280.283 582.948 280.514 583.285 280.575 583.734Z" fill="black" />
            <path d="M328.62 584.015C328.513 584.015 328.406 583.998 328.307 583.959C328.208 583.92 328.117 583.858 328.041 583.785C327.965 583.706 327.904 583.616 327.862 583.521C327.821 583.42 327.8 583.313 327.8 583.207C327.797 583.094 327.818 582.982 327.861 582.881C327.904 582.78 327.969 582.684 328.05 582.606C328.132 582.533 328.23 582.471 328.335 582.437C328.441 582.404 328.554 582.387 328.665 582.398C328.771 582.392 328.879 582.409 328.978 582.449C329.078 582.488 329.167 582.55 329.24 582.628C329.313 582.707 329.368 582.797 329.401 582.903C329.433 583.004 329.442 583.111 329.428 583.218C329.432 583.324 329.413 583.431 329.373 583.532C329.333 583.628 329.274 583.717 329.198 583.79C329.122 583.869 329.031 583.925 328.932 583.965C328.833 584.004 328.726 584.021 328.62 584.015Z" fill="black" />
            <path d="M284.831 487.964C284.838 488.071 284.824 488.178 284.79 488.279C284.755 488.38 284.701 488.473 284.63 488.552C284.558 488.632 284.472 488.697 284.375 488.742C284.279 488.788 284.174 488.813 284.067 488.817C283.956 488.824 283.845 488.807 283.741 488.769C283.636 488.731 283.541 488.672 283.46 488.596C283.379 488.52 283.315 488.428 283.27 488.326C283.226 488.225 283.203 488.114 283.202 488.003C283.2 487.897 283.219 487.79 283.259 487.691C283.299 487.591 283.358 487.501 283.434 487.425C283.509 487.349 283.599 487.289 283.698 487.248C283.797 487.208 283.904 487.188 284.011 487.189C284.117 487.18 284.224 487.193 284.325 487.228C284.426 487.263 284.518 487.319 284.595 487.392C284.673 487.465 284.734 487.554 284.774 487.653C284.815 487.751 284.834 487.858 284.831 487.964Z" fill="black" />
            <path d="M276.588 527.598C276.593 527.704 276.576 527.81 276.537 527.909C276.497 528.007 276.437 528.096 276.36 528.169C276.284 528.242 276.192 528.297 276.092 528.332C275.991 528.366 275.885 528.378 275.78 528.367C275.673 528.38 275.565 528.368 275.463 528.333C275.361 528.298 275.269 528.241 275.193 528.165C275.116 528.09 275.058 527.999 275.021 527.898C274.985 527.796 274.971 527.689 274.982 527.581C274.973 527.474 274.989 527.366 275.026 527.265C275.063 527.164 275.122 527.073 275.199 526.997C275.275 526.921 275.367 526.863 275.469 526.826C275.57 526.789 275.678 526.775 275.785 526.784C275.893 526.777 276.001 526.794 276.103 526.833C276.204 526.872 276.296 526.931 276.372 527.009C276.448 527.086 276.507 527.178 276.544 527.28C276.581 527.382 276.596 527.49 276.588 527.598Z" fill="black" />
            <path d="M305.993 580.787C305.948 581.286 305.701 581.59 305.19 581.59C305.083 581.601 304.974 581.589 304.872 581.55C304.771 581.516 304.678 581.46 304.602 581.382C304.525 581.309 304.466 581.213 304.429 581.112C304.392 581.011 304.377 580.905 304.387 580.798C304.377 580.691 304.39 580.584 304.426 580.483C304.462 580.377 304.52 580.287 304.595 580.208C304.671 580.135 304.762 580.074 304.863 580.034C304.963 579.995 305.071 579.984 305.179 579.989C305.285 579.984 305.392 580.001 305.493 580.04C305.593 580.079 305.684 580.135 305.76 580.208C305.837 580.287 305.897 580.377 305.937 580.472C305.977 580.573 305.996 580.68 305.993 580.787Z" fill="black" />
            <path d="M282.518 578.355C282.518 578.85 282.271 579.158 281.743 579.17C281.636 579.186 281.528 579.175 281.424 579.147C281.321 579.113 281.227 579.063 281.147 578.99C281.067 578.917 281.005 578.827 280.964 578.726C280.923 578.625 280.906 578.518 280.912 578.412C280.9 578.305 280.91 578.198 280.945 578.092C280.978 577.99 281.034 577.895 281.108 577.816C281.182 577.738 281.272 577.682 281.371 577.637C281.471 577.597 281.579 577.581 281.687 577.586C281.793 577.575 281.901 577.586 282.002 577.62C282.104 577.659 282.196 577.71 282.275 577.783C282.353 577.856 282.416 577.946 282.457 578.047C282.499 578.142 282.52 578.249 282.518 578.355Z" fill="black" />
            <path d="M290.199 489.895C290.199 490.418 289.935 490.732 289.429 490.743C289.324 490.747 289.22 490.73 289.122 490.693C289.024 490.657 288.934 490.601 288.857 490.53C288.78 490.458 288.719 490.372 288.675 490.277C288.632 490.182 288.607 490.079 288.604 489.974C288.6 489.87 288.617 489.765 288.654 489.667C288.691 489.569 288.746 489.479 288.817 489.402C288.889 489.326 288.975 489.264 289.07 489.22C289.166 489.177 289.268 489.153 289.373 489.149C289.478 489.135 289.585 489.144 289.686 489.176C289.787 489.207 289.88 489.26 289.959 489.331C290.037 489.402 290.099 489.489 290.141 489.587C290.182 489.684 290.202 489.79 290.199 489.895Z" fill="black" />
            <path d="M329.766 577.76C329.766 577.654 329.79 577.547 329.834 577.452C329.879 577.35 329.944 577.266 330.024 577.193C330.105 577.12 330.199 577.07 330.302 577.036C330.404 577.008 330.513 576.997 330.619 577.008C330.727 577.002 330.834 577.019 330.934 577.064C331.034 577.103 331.122 577.165 331.195 577.249C331.267 577.328 331.32 577.423 331.351 577.524C331.381 577.631 331.388 577.738 331.372 577.845C331.376 577.951 331.356 578.058 331.314 578.159C331.273 578.26 331.209 578.35 331.128 578.423C331.048 578.496 330.953 578.546 330.849 578.58C330.746 578.608 330.636 578.619 330.529 578.603C330.423 578.603 330.317 578.58 330.22 578.535C330.122 578.49 330.035 578.429 329.963 578.35C329.892 578.271 329.837 578.176 329.803 578.075C329.769 577.974 329.756 577.867 329.766 577.76Z" fill="black" />
            <path d="M277.52 491.372C277.459 491.889 277.234 492.214 276.712 492.214C276.602 492.224 276.491 492.21 276.387 492.174C276.282 492.138 276.186 492.081 276.105 492.006C276.024 491.932 275.959 491.841 275.914 491.74C275.87 491.639 275.848 491.53 275.848 491.42C275.848 491.31 275.87 491.2 275.914 491.099C275.959 490.998 276.024 490.908 276.105 490.833C276.186 490.758 276.282 490.701 276.387 490.666C276.491 490.63 276.602 490.616 276.712 490.625C277.206 490.625 277.43 490.957 277.52 491.372Z" fill="black" />
            <path d="M295.573 491.883C295.528 492.372 295.286 492.681 294.775 492.687C294.669 492.699 294.56 492.689 294.458 492.654C294.356 492.619 294.263 492.562 294.186 492.487C294.109 492.411 294.051 492.319 294.014 492.217C293.979 492.115 293.966 492.007 293.978 491.9C293.978 491.689 294.062 491.486 294.212 491.336C294.361 491.187 294.564 491.103 294.775 491.103C294.987 491.103 295.19 491.187 295.339 491.336C295.488 491.486 295.573 491.689 295.573 491.9V491.883Z" fill="black" />
            <path d="M312.905 491.552C313.01 491.551 313.114 491.572 313.21 491.615C313.306 491.658 313.391 491.721 313.461 491.8C313.53 491.879 313.581 491.972 313.612 492.072C313.641 492.173 313.65 492.279 313.635 492.383C313.642 492.49 313.625 492.598 313.585 492.698C313.545 492.798 313.483 492.888 313.404 492.961C313.325 493.034 313.23 493.089 313.128 493.121C313.025 493.153 312.917 493.161 312.81 493.146C312.702 493.152 312.594 493.134 312.494 493.093C312.393 493.053 312.303 492.991 312.23 492.912C312.156 492.832 312.101 492.738 312.069 492.635C312.036 492.531 312.026 492.422 312.041 492.315C312.038 492.206 312.06 492.097 312.105 491.996C312.149 491.896 312.214 491.807 312.297 491.734C312.379 491.661 312.476 491.607 312.581 491.576C312.686 491.544 312.796 491.537 312.905 491.552Z" fill="black" />
            <path d="M316.19 535.818C316.139 536.312 315.892 536.621 315.376 536.616C315.175 536.599 314.988 536.507 314.852 536.359C314.715 536.211 314.64 536.017 314.64 535.815C314.64 535.614 314.715 535.42 314.852 535.272C314.988 535.124 315.175 535.032 315.376 535.015C315.483 535.011 315.59 535.028 315.69 535.067C315.79 535.105 315.882 535.164 315.958 535.239C316.034 535.315 316.094 535.405 316.134 535.505C316.174 535.604 316.193 535.711 316.19 535.818Z" fill="black" />
            <path d="M300.686 543.499C300.578 543.5 300.472 543.478 300.373 543.435C300.275 543.391 300.186 543.329 300.113 543.249C300.04 543.17 299.984 543.076 299.949 542.974C299.915 542.873 299.902 542.764 299.911 542.657C299.934 542.456 300.032 542.271 300.186 542.139C300.34 542.006 300.537 541.937 300.74 541.945C300.943 541.953 301.135 542.037 301.277 542.181C301.42 542.324 301.504 542.517 301.511 542.719C301.495 543.247 301.186 543.471 300.686 543.499Z" fill="black" />
            <path d="M310.44 516.638C309.946 516.582 309.631 516.335 309.637 515.824C309.637 515.613 309.72 515.41 309.87 515.26C310.02 515.111 310.222 515.027 310.434 515.027C310.645 515.027 310.848 515.111 310.998 515.26C311.147 515.41 311.231 515.613 311.231 515.824C311.237 515.931 311.22 516.037 311.183 516.137C311.145 516.237 311.087 516.328 311.013 516.404C310.939 516.481 310.85 516.541 310.751 516.581C310.652 516.621 310.546 516.641 310.44 516.638Z" fill="black" />
            <path d="M325.217 575.031C325.38 575.031 325.539 575.082 325.673 575.177C325.807 575.273 325.91 575.402 325.969 575.554C326.029 575.705 326.041 575.874 326.005 576.031C325.97 576.188 325.887 576.334 325.769 576.446C325.65 576.559 325.501 576.632 325.34 576.66C325.18 576.688 325.014 576.665 324.866 576.598C324.718 576.531 324.592 576.418 324.507 576.278C324.422 576.143 324.38 575.98 324.386 575.818C324.398 575.604 324.49 575.408 324.645 575.256C324.8 575.11 325.005 575.031 325.217 575.031Z" fill="black" />
            <path d="M288.996 508.839C288.463 508.806 288.149 508.587 288.143 508.059C288.128 507.952 288.136 507.844 288.168 507.741C288.2 507.638 288.255 507.544 288.328 507.464C288.401 507.385 288.491 507.323 288.591 507.284C288.692 507.244 288.799 507.227 288.907 507.234C289.014 507.221 289.123 507.233 289.226 507.267C289.329 507.3 289.423 507.357 289.501 507.431C289.58 507.506 289.642 507.596 289.681 507.697C289.721 507.798 289.738 507.906 289.732 508.014C289.741 508.117 289.729 508.221 289.697 508.32C289.664 508.418 289.612 508.509 289.543 508.586C289.474 508.663 289.39 508.726 289.296 508.769C289.202 508.813 289.1 508.837 288.996 508.839Z" fill="black" />
            <path d="M276.251 510.305C276.145 510.315 276.039 510.302 275.939 510.267C275.838 510.233 275.746 510.177 275.668 510.105C275.591 510.032 275.53 509.943 275.489 509.846C275.448 509.747 275.429 509.642 275.432 509.536C275.418 509.428 275.428 509.318 275.462 509.215C275.496 509.112 275.552 509.018 275.627 508.939C275.702 508.86 275.793 508.799 275.894 508.759C275.995 508.719 276.104 508.703 276.212 508.71C276.32 508.698 276.43 508.709 276.533 508.744C276.637 508.778 276.732 508.833 276.812 508.908C276.892 508.982 276.955 509.072 276.996 509.173C277.038 509.274 277.058 509.382 277.054 509.491C277.067 509.6 277.054 509.71 277.018 509.814C276.982 509.917 276.924 510.011 276.847 510.089C276.77 510.167 276.676 510.228 276.574 510.265C276.47 510.302 276.36 510.316 276.251 510.305Z" fill="black" />
            <path d="M312.292 511.248C312.092 511.216 311.913 511.11 311.787 510.951C311.661 510.793 311.599 510.594 311.612 510.392C311.625 510.19 311.713 510 311.859 509.86C312.004 509.719 312.197 509.638 312.399 509.631C312.51 509.629 312.621 509.65 312.724 509.692C312.827 509.735 312.92 509.799 312.997 509.88C313.073 509.961 313.132 510.057 313.17 510.162C313.207 510.267 313.221 510.379 313.213 510.49C313.186 510.711 313.075 510.913 312.904 511.053C312.732 511.195 312.513 511.265 312.292 511.248Z" fill="black" />
            <path d="M293.164 515.381C293.164 515.487 293.142 515.592 293.098 515.689C293.054 515.787 292.99 515.873 292.911 515.944C292.831 516.014 292.737 516.067 292.636 516.099C292.535 516.131 292.427 516.14 292.322 516.127C292.214 516.135 292.107 516.119 292.006 516.08C291.906 516.04 291.816 515.979 291.742 515.901C291.668 515.823 291.613 515.728 291.58 515.626C291.548 515.523 291.538 515.414 291.553 515.308C291.547 515.2 291.565 515.093 291.605 514.993C291.645 514.893 291.707 514.803 291.786 514.729C291.864 514.655 291.957 514.6 292.06 514.565C292.162 514.532 292.271 514.52 292.378 514.533C292.486 514.533 292.594 514.555 292.693 514.598C292.793 514.642 292.883 514.705 292.957 514.785C293.03 514.864 293.087 514.958 293.123 515.061C293.158 515.164 293.172 515.273 293.164 515.381Z" fill="black" />
            <path d="M298.542 517.306C298.469 517.84 298.177 518.121 297.683 518.092C297.575 518.096 297.468 518.077 297.368 518.036C297.268 517.994 297.178 517.931 297.105 517.852C297.032 517.772 296.977 517.678 296.944 517.575C296.911 517.472 296.9 517.363 296.914 517.256C296.908 517.147 296.927 517.04 296.968 516.94C297.01 516.839 297.073 516.75 297.154 516.678C297.235 516.606 297.331 516.552 297.435 516.523C297.539 516.492 297.649 516.486 297.756 516.504C297.861 516.504 297.964 516.524 298.06 516.565C298.157 516.606 298.245 516.665 298.318 516.74C298.391 516.815 298.448 516.903 298.487 517C298.525 517.098 298.544 517.202 298.542 517.306Z" fill="black" />
            <path d="M315.785 516.936C316.307 516.992 316.604 517.234 316.599 517.75C316.612 517.856 316.6 517.964 316.565 518.065C316.53 518.166 316.472 518.257 316.397 518.332C316.32 518.408 316.229 518.465 316.127 518.499C316.026 518.533 315.919 518.544 315.813 518.531C315.707 518.542 315.599 518.529 315.498 518.494C315.397 518.459 315.305 518.403 315.229 518.328C315.152 518.254 315.093 518.163 315.055 518.063C315.017 517.964 315.002 517.857 315.01 517.75C315.005 517.54 315.084 517.336 315.229 517.184C315.374 517.031 315.574 516.942 315.785 516.936Z" fill="black" />
            <path d="M285.841 518.71C285.813 519.221 285.566 519.552 285.072 519.575C284.965 519.591 284.856 519.582 284.753 519.55C284.651 519.518 284.556 519.463 284.477 519.39C284.398 519.317 284.336 519.227 284.296 519.127C284.256 519.027 284.24 518.919 284.246 518.811C284.228 518.706 284.235 518.597 284.264 518.494C284.294 518.391 284.347 518.295 284.419 518.216C284.491 518.136 284.58 518.073 284.679 518.033C284.779 517.992 284.886 517.974 284.993 517.98C285.098 517.968 285.204 517.978 285.304 518.008C285.405 518.039 285.498 518.09 285.578 518.159C285.657 518.227 285.722 518.312 285.767 518.407C285.813 518.502 285.838 518.605 285.841 518.71Z" fill="black" />
            <path d="M309.317 521.13C309.278 521.652 309.014 521.955 308.526 521.967C308.418 521.979 308.31 521.968 308.208 521.934C308.106 521.9 308.013 521.845 307.934 521.771C307.856 521.697 307.795 521.607 307.756 521.507C307.716 521.407 307.699 521.299 307.706 521.192C307.692 521.086 307.703 520.978 307.737 520.877C307.771 520.776 307.828 520.684 307.902 520.607C307.976 520.531 308.067 520.472 308.167 520.436C308.267 520.398 308.374 520.384 308.481 520.395C308.584 520.386 308.689 520.397 308.788 520.43C308.887 520.462 308.978 520.514 309.056 520.583C309.135 520.652 309.198 520.736 309.243 520.83C309.287 520.924 309.313 521.026 309.317 521.13Z" fill="black" />
            <path d="M313.854 523.937C313.354 523.864 313.068 523.606 313.09 523.09C313.084 522.982 313.1 522.875 313.14 522.775C313.18 522.675 313.242 522.585 313.321 522.511C313.4 522.438 313.495 522.384 313.597 522.352C313.7 522.32 313.809 522.311 313.916 522.326C314.023 522.321 314.131 522.34 314.231 522.381C314.33 522.421 314.42 522.484 314.493 522.563C314.566 522.642 314.621 522.736 314.655 522.839C314.688 522.941 314.698 523.05 314.685 523.157C314.685 523.264 314.664 523.37 314.621 523.468C314.579 523.566 314.516 523.654 314.439 523.727C314.361 523.801 314.269 523.857 314.168 523.893C314.067 523.93 313.961 523.945 313.854 523.937Z" fill="black" />
            <path d="M310.681 466.954C310.685 467.061 310.668 467.168 310.629 467.267C310.591 467.367 310.532 467.458 310.456 467.534C310.381 467.61 310.29 467.67 310.191 467.709C310.091 467.748 309.984 467.766 309.878 467.762C309.771 467.777 309.661 467.766 309.558 467.732C309.456 467.698 309.362 467.641 309.284 467.565C309.207 467.489 309.148 467.397 309.112 467.294C309.075 467.193 309.063 467.084 309.075 466.976C309.065 466.868 309.078 466.76 309.114 466.658C309.15 466.556 309.208 466.463 309.283 466.385C309.358 466.307 309.449 466.246 309.55 466.207C309.65 466.168 309.758 466.15 309.867 466.156C310.366 466.145 310.625 466.46 310.681 466.954Z" fill="black" />
            <path d="M279.087 468.812C278.981 468.816 278.875 468.797 278.776 468.756C278.677 468.716 278.588 468.654 278.515 468.577C278.442 468.499 278.386 468.407 278.351 468.307C278.316 468.206 278.303 468.098 278.313 467.992C278.313 467.782 278.396 467.581 278.545 467.433C278.693 467.284 278.894 467.201 279.104 467.201C279.314 467.201 279.515 467.284 279.664 467.433C279.812 467.581 279.896 467.782 279.896 467.992C279.904 468.101 279.889 468.211 279.852 468.313C279.814 468.415 279.754 468.508 279.678 468.586C279.602 468.664 279.509 468.724 279.407 468.763C279.305 468.802 279.196 468.819 279.087 468.812Z" fill="black" />
            <path d="M271.203 525.622C271.17 526.183 270.878 526.481 270.384 526.47C270.276 526.475 270.168 526.457 270.067 526.417C269.967 526.377 269.877 526.315 269.803 526.237C269.729 526.159 269.672 526.065 269.638 525.963C269.603 525.861 269.591 525.752 269.603 525.644C269.597 525.537 269.615 525.43 269.655 525.33C269.696 525.23 269.757 525.14 269.836 525.067C269.915 524.994 270.008 524.939 270.111 524.906C270.213 524.872 270.322 524.862 270.428 524.875C270.529 524.868 270.631 524.883 270.727 524.917C270.822 524.952 270.909 525.006 270.982 525.076C271.055 525.147 271.112 525.232 271.15 525.325C271.189 525.42 271.207 525.521 271.203 525.622Z" fill="black" />
            <path d="M294.645 527.974C294.645 528.536 294.364 528.834 293.87 528.85C293.762 528.861 293.653 528.848 293.551 528.813C293.448 528.778 293.354 528.721 293.276 528.647C293.197 528.572 293.135 528.481 293.094 528.381C293.053 528.281 293.035 528.173 293.039 528.064C293.023 527.957 293.032 527.848 293.064 527.745C293.098 527.641 293.153 527.547 293.228 527.469C293.303 527.39 293.394 527.329 293.496 527.292C293.597 527.254 293.706 527.239 293.814 527.25C293.917 527.236 294.023 527.244 294.123 527.274C294.223 527.303 294.316 527.354 294.395 527.423C294.473 527.492 294.537 527.576 294.58 527.672C294.623 527.767 294.645 527.87 294.645 527.974Z" fill="black" />
            <path d="M281.973 529.552C281.974 529.658 281.952 529.764 281.909 529.861C281.867 529.958 281.804 530.046 281.726 530.118C281.648 530.189 281.555 530.244 281.455 530.278C281.354 530.312 281.248 530.325 281.142 530.316C281.037 530.314 280.934 530.29 280.838 530.248C280.743 530.205 280.656 530.145 280.584 530.069C280.512 529.992 280.455 529.903 280.418 529.805C280.38 529.708 280.362 529.604 280.365 529.499C280.367 529.394 280.39 529.291 280.432 529.195C280.475 529.1 280.536 529.013 280.612 528.941C280.688 528.869 280.777 528.812 280.875 528.775C280.973 528.737 281.076 528.719 281.181 528.721C281.29 528.717 281.398 528.736 281.499 528.776C281.6 528.817 281.691 528.879 281.765 528.958C281.84 529.036 281.897 529.13 281.933 529.233C281.969 529.335 281.983 529.444 281.973 529.552Z" fill="black" />
            <path d="M299.255 529.176C299.362 529.178 299.468 529.202 299.565 529.246C299.663 529.291 299.751 529.355 299.823 529.434C299.895 529.514 299.95 529.607 299.984 529.708C300.019 529.809 300.033 529.917 300.024 530.024C300.001 530.226 299.903 530.411 299.75 530.543C299.596 530.675 299.398 530.744 299.195 530.736C298.993 530.728 298.801 530.644 298.658 530.501C298.514 530.357 298.431 530.165 298.424 529.962C298.422 529.855 298.443 529.748 298.484 529.649C298.526 529.55 298.588 529.46 298.666 529.386C298.744 529.312 298.837 529.255 298.938 529.219C299.04 529.183 299.148 529.168 299.255 529.176Z" fill="black" />
            <path d="M267.662 531.029C267.658 530.921 267.677 530.813 267.718 530.713C267.758 530.613 267.818 530.522 267.895 530.446C267.972 530.37 268.064 530.311 268.165 530.272C268.265 530.233 268.373 530.216 268.481 530.22C268.59 530.21 268.699 530.224 268.802 530.261C268.905 530.298 268.997 530.357 269.074 530.434C269.151 530.512 269.21 530.605 269.246 530.708C269.282 530.811 269.295 530.921 269.284 531.029C269.267 531.233 269.174 531.423 269.024 531.561C268.874 531.699 268.677 531.776 268.473 531.776C268.268 531.776 268.071 531.699 267.921 531.561C267.771 531.423 267.678 531.233 267.662 531.029Z" fill="black" />
            <path d="M286.509 530.675C286.618 530.671 286.727 530.69 286.828 530.73C286.929 530.769 287.021 530.829 287.098 530.906C287.175 530.983 287.235 531.075 287.275 531.177C287.315 531.278 287.333 531.386 287.329 531.495C287.329 531.705 287.245 531.906 287.097 532.055C286.949 532.203 286.747 532.287 286.537 532.287C286.327 532.287 286.126 532.203 285.978 532.055C285.829 531.906 285.746 531.705 285.746 531.495C285.739 531.39 285.754 531.285 285.788 531.187C285.824 531.088 285.879 530.997 285.951 530.92C286.022 530.844 286.108 530.782 286.204 530.74C286.3 530.698 286.404 530.676 286.509 530.675Z" fill="black" />
            <path d="M277.964 473.405C277.958 473.621 277.866 473.826 277.71 473.974C277.552 474.123 277.343 474.203 277.127 474.197C276.911 474.191 276.707 474.1 276.558 473.942C276.41 473.786 276.329 473.576 276.336 473.36C276.331 473.252 276.351 473.144 276.392 473.045C276.434 472.945 276.498 472.855 276.578 472.783C276.659 472.711 276.754 472.657 276.858 472.626C276.962 472.594 277.071 472.586 277.178 472.602C277.283 472.6 277.388 472.619 277.485 472.659C277.583 472.699 277.671 472.758 277.745 472.833C277.818 472.908 277.876 472.998 277.914 473.096C277.951 473.195 277.968 473.3 277.964 473.405Z" fill="black" />
            <path d="M305.42 531.967C305.419 532.07 305.398 532.173 305.357 532.269C305.316 532.364 305.256 532.451 305.182 532.523C305.107 532.595 305.018 532.652 304.922 532.689C304.825 532.727 304.721 532.744 304.617 532.741C304.511 532.75 304.404 532.736 304.303 532.701C304.202 532.665 304.11 532.609 304.032 532.535C303.955 532.461 303.894 532.372 303.854 532.273C303.813 532.174 303.794 532.068 303.798 531.961C303.793 531.85 303.811 531.739 303.851 531.635C303.891 531.531 303.952 531.437 304.03 531.357C304.108 531.278 304.201 531.215 304.304 531.173C304.407 531.131 304.518 531.111 304.629 531.113C304.738 531.113 304.846 531.135 304.946 531.179C305.047 531.223 305.137 531.286 305.211 531.366C305.286 531.447 305.343 531.542 305.379 531.645C305.415 531.748 305.429 531.858 305.42 531.967Z" fill="black" />
            <path d="M322.752 531.602C322.856 531.607 322.957 531.632 323.05 531.678C323.143 531.724 323.226 531.788 323.292 531.867C323.36 531.946 323.41 532.038 323.439 532.138C323.469 532.237 323.478 532.341 323.465 532.444C323.469 532.55 323.449 532.657 323.407 532.755C323.365 532.853 323.303 532.941 323.224 533.013C323.145 533.084 323.051 533.138 322.949 533.169C322.848 533.201 322.74 533.21 322.634 533.197C322.527 533.198 322.421 533.177 322.323 533.134C322.225 533.091 322.137 533.027 322.066 532.947C321.994 532.867 321.941 532.773 321.91 532.671C321.878 532.568 321.869 532.461 321.882 532.355C321.899 531.872 322.202 531.597 322.752 531.602Z" fill="black" />
            <path d="M301.384 475.791C301.384 476.003 301.299 476.206 301.15 476.355C301.001 476.504 300.797 476.589 300.586 476.589C300.375 476.589 300.172 476.504 300.023 476.355C299.873 476.206 299.789 476.003 299.789 475.791C299.789 475.58 299.873 475.377 300.023 475.228C300.172 475.078 300.375 474.994 300.586 474.994C300.797 474.994 301.001 475.078 301.15 475.228C301.299 475.377 301.384 475.58 301.384 475.791Z" fill="black" />
            <path d="M310.805 533.831C310.743 534.392 310.49 534.684 309.985 534.673C309.773 534.673 309.57 534.589 309.421 534.439C309.272 534.29 309.188 534.087 309.188 533.876C309.188 533.664 309.272 533.462 309.421 533.312C309.57 533.163 309.773 533.078 309.985 533.078C310.524 533.073 310.732 533.404 310.805 533.831Z" fill="black" />
            <path d="M273.393 551.82C272.888 551.82 272.579 551.54 272.579 551.034C272.577 550.877 272.621 550.722 272.707 550.59C272.793 550.458 272.915 550.354 273.06 550.291C273.204 550.229 273.364 550.211 273.519 550.239C273.673 550.267 273.817 550.34 273.93 550.45C274.043 550.559 274.122 550.7 274.155 550.853C274.189 551.007 274.176 551.167 274.119 551.314C274.061 551.46 273.962 551.587 273.832 551.677C273.704 551.767 273.551 551.817 273.393 551.82Z" fill="black" />
            <path d="M320.737 538.597C320.265 538.524 319.951 538.289 319.962 537.766C319.952 537.659 319.966 537.551 320.003 537.451C320.04 537.35 320.1 537.259 320.177 537.184C320.254 537.109 320.346 537.052 320.448 537.018C320.55 536.984 320.658 536.973 320.765 536.986C320.872 536.976 320.981 536.991 321.082 537.028C321.183 537.065 321.275 537.124 321.35 537.201C321.426 537.277 321.484 537.37 321.52 537.472C321.555 537.573 321.568 537.682 321.557 537.789C321.562 538.283 321.231 538.536 320.737 538.597Z" fill="black" />
            <path d="M293.326 478.374C293.434 478.376 293.539 478.4 293.637 478.444C293.734 478.489 293.822 478.553 293.894 478.632C293.966 478.711 294.021 478.805 294.056 478.906C294.09 479.008 294.104 479.115 294.095 479.222C294.072 479.423 293.974 479.609 293.821 479.741C293.667 479.873 293.469 479.942 293.267 479.934C293.064 479.926 292.872 479.842 292.729 479.699C292.586 479.555 292.503 479.363 292.495 479.16C292.493 479.053 292.514 478.946 292.555 478.847C292.597 478.747 292.659 478.658 292.737 478.584C292.815 478.51 292.909 478.453 293.01 478.417C293.111 478.381 293.219 478.366 293.326 478.374Z" fill="black" />
            <path d="M280.535 481.479C280.43 481.482 280.325 481.463 280.228 481.422C280.132 481.381 280.045 481.32 279.973 481.243C279.902 481.166 279.848 481.075 279.815 480.975C279.783 480.875 279.771 480.769 279.783 480.665C279.772 480.558 279.788 480.45 279.826 480.349C279.864 480.248 279.925 480.157 280.003 480.083C280.081 480.009 280.176 479.953 280.278 479.921C280.381 479.889 280.49 479.88 280.597 479.896C280.704 479.887 280.813 479.902 280.915 479.94C281.017 479.978 281.109 480.037 281.185 480.114C281.261 480.191 281.32 480.284 281.357 480.386C281.395 480.487 281.409 480.596 281.4 480.704C281.402 480.815 281.381 480.924 281.337 481.025C281.293 481.127 281.228 481.217 281.146 481.291C281.063 481.364 280.966 481.419 280.861 481.452C280.756 481.485 280.644 481.494 280.535 481.479Z" fill="black" />
            <path d="M271.934 539.137C271.826 539.148 271.719 539.136 271.616 539.103C271.515 539.07 271.42 539.016 271.34 538.945C271.26 538.874 271.195 538.787 271.15 538.689C271.106 538.592 271.082 538.486 271.08 538.379C271.075 538.272 271.091 538.166 271.126 538.066C271.162 537.966 271.218 537.874 271.289 537.795C271.36 537.717 271.447 537.653 271.543 537.608C271.639 537.562 271.743 537.536 271.849 537.531C271.956 537.526 272.062 537.541 272.162 537.577C272.262 537.613 272.354 537.668 272.432 537.74C272.511 537.811 272.575 537.897 272.62 537.993C272.666 538.09 272.692 538.194 272.697 538.3C272.713 538.407 272.705 538.517 272.673 538.621C272.642 538.724 272.587 538.82 272.514 538.9C272.441 538.98 272.351 539.043 272.25 539.084C272.15 539.125 272.041 539.143 271.934 539.137Z" fill="black" />
            <path d="M282.63 543.028C282.522 543.03 282.416 543.011 282.317 542.969C282.217 542.928 282.129 542.865 282.055 542.787C281.982 542.708 281.926 542.615 281.892 542.513C281.857 542.412 281.845 542.304 281.855 542.197C281.859 542.092 281.883 541.989 281.927 541.894C281.97 541.799 282.032 541.713 282.108 541.641C282.185 541.57 282.275 541.514 282.374 541.477C282.471 541.441 282.576 541.424 282.681 541.427C282.785 541.431 282.888 541.455 282.983 541.499C283.079 541.542 283.165 541.604 283.236 541.681C283.307 541.758 283.363 541.847 283.4 541.946C283.436 542.044 283.453 542.148 283.45 542.253C283.455 542.36 283.438 542.467 283.398 542.567C283.358 542.666 283.297 542.756 283.219 542.829C283.14 542.903 283.048 542.959 282.946 542.993C282.845 543.028 282.737 543.039 282.63 543.028Z" fill="black" />
            <path d="M269.952 544.532C269.452 544.442 269.144 544.207 269.16 543.684C269.152 543.577 269.168 543.469 269.206 543.369C269.245 543.269 269.305 543.179 269.383 543.105C269.461 543.031 269.555 542.976 269.657 542.943C269.759 542.91 269.868 542.901 269.975 542.915C270.082 542.908 270.19 542.924 270.29 542.964C270.39 543.002 270.48 543.062 270.555 543.14C270.629 543.218 270.686 543.31 270.72 543.413C270.755 543.514 270.767 543.623 270.755 543.729C270.755 544.229 270.412 544.465 269.952 544.532Z" fill="black" />
            <path d="M306.066 543.842C306.173 543.833 306.282 543.847 306.383 543.883C306.485 543.919 306.578 543.975 306.657 544.05C306.735 544.124 306.796 544.213 306.838 544.313C306.879 544.413 306.9 544.52 306.897 544.628C306.909 544.738 306.897 544.85 306.864 544.955C306.831 545.061 306.776 545.158 306.703 545.241C306.63 545.325 306.541 545.393 306.441 545.44C306.34 545.487 306.232 545.513 306.121 545.516C306.01 545.519 305.9 545.499 305.798 545.458C305.695 545.416 305.602 545.354 305.524 545.275C305.447 545.195 305.387 545.101 305.347 544.998C305.308 544.894 305.291 544.783 305.297 544.673C305.286 544.567 305.298 544.459 305.333 544.358C305.367 544.256 305.422 544.163 305.495 544.085C305.567 544.006 305.656 543.944 305.754 543.902C305.853 543.86 305.959 543.84 306.066 543.842Z" fill="black" />
            <path d="M324.134 545.891C323.976 545.883 323.824 545.829 323.697 545.735C323.569 545.641 323.473 545.512 323.419 545.363C323.365 545.214 323.357 545.052 323.394 544.898C323.432 544.745 323.514 544.606 323.631 544.499C323.747 544.391 323.892 544.321 324.049 544.296C324.205 544.272 324.365 544.294 324.509 544.36C324.652 544.426 324.774 544.533 324.857 544.668C324.94 544.802 324.981 544.958 324.976 545.116C324.981 545.225 324.962 545.334 324.921 545.435C324.879 545.536 324.816 545.627 324.736 545.7C324.656 545.774 324.56 545.829 324.456 545.862C324.352 545.896 324.243 545.905 324.134 545.891Z" fill="black" />
            <path d="M312.292 546.627C312.291 546.73 312.269 546.833 312.226 546.928C312.184 547.024 312.123 547.109 312.047 547.18C311.971 547.25 311.881 547.305 311.783 547.34C311.686 547.376 311.582 547.391 311.478 547.385C311.371 547.395 311.264 547.381 311.162 547.346C311.061 547.31 310.969 547.253 310.892 547.178C310.815 547.103 310.756 547.013 310.717 546.913C310.679 546.813 310.663 546.706 310.669 546.598C310.667 546.487 310.687 546.377 310.73 546.274C310.772 546.171 310.834 546.077 310.914 545.999C310.993 545.922 311.088 545.861 311.192 545.821C311.295 545.781 311.406 545.763 311.517 545.768C311.626 545.77 311.732 545.795 311.831 545.841C311.929 545.885 312.017 545.95 312.089 546.031C312.162 546.111 312.217 546.205 312.252 546.308C312.287 546.41 312.301 546.519 312.292 546.627Z" fill="black" />
            <path d="M330.355 547.042C330.357 547.15 330.339 547.257 330.3 547.356C330.26 547.456 330.201 547.547 330.125 547.623C330.049 547.699 329.959 547.759 329.859 547.799C329.76 547.839 329.653 547.859 329.546 547.856C329.438 547.868 329.329 547.855 329.226 547.819C329.124 547.783 329.031 547.724 328.954 547.647C328.878 547.57 328.82 547.476 328.784 547.373C328.748 547.271 328.737 547.162 328.749 547.053C328.74 546.946 328.754 546.838 328.79 546.736C328.827 546.635 328.885 546.542 328.961 546.465C329.037 546.389 329.128 546.329 329.23 546.291C329.331 546.253 329.438 546.237 329.546 546.245C330.063 546.245 330.304 546.554 330.355 547.042Z" fill="black" />
            <path d="M317.672 548.496C317.638 549.007 317.374 549.322 316.885 549.327C316.778 549.34 316.669 549.329 316.567 549.295C316.464 549.26 316.371 549.204 316.292 549.13C316.214 549.055 316.153 548.965 316.114 548.863C316.075 548.763 316.058 548.655 316.066 548.547C316.053 548.441 316.064 548.332 316.098 548.231C316.132 548.129 316.188 548.035 316.262 547.958C316.336 547.88 316.426 547.82 316.526 547.781C316.626 547.742 316.733 547.725 316.841 547.733C316.946 547.723 317.053 547.735 317.154 547.769C317.256 547.802 317.348 547.857 317.426 547.929C317.505 548.001 317.567 548.089 317.609 548.187C317.651 548.285 317.673 548.39 317.672 548.496Z" fill="black" />
            <path d="M332.927 555.183C332.822 555.181 332.718 555.157 332.622 555.113C332.526 555.07 332.439 555.008 332.368 554.93C332.296 554.853 332.241 554.762 332.205 554.662C332.169 554.564 332.153 554.458 332.158 554.352C332.161 554.241 332.186 554.131 332.233 554.03C332.28 553.929 332.347 553.839 332.43 553.765C332.513 553.69 332.611 553.634 332.716 553.599C332.822 553.564 332.934 553.551 333.045 553.561C333.153 553.561 333.259 553.583 333.356 553.628C333.454 553.672 333.541 553.737 333.611 553.818C333.681 553.899 333.734 553.994 333.764 554.097C333.794 554.2 333.802 554.308 333.786 554.414C333.784 554.523 333.759 554.629 333.713 554.727C333.669 554.825 333.603 554.913 333.523 554.985C333.443 555.057 333.348 555.112 333.246 555.146C333.144 555.18 333.035 555.193 332.927 555.183Z" fill="black" />
            <path d="M266.875 554.392C266.836 554.953 266.566 555.256 266.072 555.256C265.861 555.26 265.656 555.18 265.504 555.033C265.352 554.886 265.264 554.685 265.261 554.473C265.257 554.262 265.337 554.058 265.484 553.906C265.631 553.753 265.832 553.666 266.044 553.662C266.147 553.653 266.251 553.665 266.349 553.697C266.447 553.729 266.538 553.78 266.616 553.849C266.694 553.917 266.757 554 266.801 554.094C266.846 554.187 266.871 554.288 266.875 554.392Z" fill="black" />
            <path d="M321.057 555.801C321.057 556.363 320.793 556.643 320.288 556.66C320.086 556.65 319.895 556.564 319.754 556.42C319.613 556.276 319.531 556.083 319.526 555.881C319.52 555.679 319.591 555.483 319.724 555.331C319.856 555.178 320.042 555.082 320.243 555.06C320.347 555.047 320.452 555.057 320.552 555.089C320.651 555.12 320.743 555.173 320.82 555.243C320.898 555.314 320.959 555.4 321 555.496C321.041 555.593 321.06 555.697 321.057 555.801Z" fill="black" />
            <path d="M294.865 559.619C294.404 559.557 294.078 559.338 294.09 558.811C294.077 558.704 294.089 558.596 294.125 558.494C294.159 558.392 294.217 558.299 294.292 558.222C294.367 558.145 294.459 558.086 294.56 558.049C294.66 558.012 294.769 557.998 294.876 558.008C294.983 557.998 295.091 558.011 295.194 558.048C295.295 558.083 295.387 558.142 295.464 558.218C295.541 558.294 295.6 558.386 295.637 558.488C295.674 558.589 295.688 558.698 295.679 558.805C295.684 559.31 295.375 559.569 294.865 559.619Z" fill="black" />
            <path d="M312.932 560.074C312.825 560.074 312.72 560.051 312.622 560.008C312.525 559.965 312.438 559.902 312.366 559.823C312.294 559.745 312.24 559.652 312.206 559.551C312.171 559.45 312.159 559.343 312.168 559.237C312.162 559.13 312.18 559.022 312.22 558.922C312.261 558.822 312.322 558.732 312.401 558.658C312.48 558.585 312.573 558.529 312.676 558.495C312.778 558.461 312.886 558.45 312.993 558.462C313.101 558.458 313.208 558.478 313.308 558.519C313.407 558.561 313.497 558.622 313.57 558.702C313.644 558.78 313.699 558.874 313.733 558.976C313.768 559.078 313.78 559.186 313.768 559.293C313.751 559.81 313.431 560.04 312.932 560.074Z" fill="black" />
            <path d="M300.109 494.668C299.638 494.584 299.329 494.348 299.346 493.826C299.336 493.718 299.351 493.61 299.389 493.51C299.427 493.409 299.488 493.318 299.566 493.244C299.644 493.17 299.739 493.114 299.841 493.082C299.944 493.05 300.053 493.041 300.16 493.056C300.267 493.049 300.375 493.064 300.476 493.103C300.577 493.141 300.668 493.201 300.743 493.279C300.817 493.357 300.873 493.451 300.908 493.553C300.942 493.655 300.953 493.764 300.94 493.871C300.935 494.37 300.603 494.617 300.109 494.668Z" fill="black" />
            <path d="M319.03 494.359C319.026 494.463 319.001 494.565 318.956 494.659C318.91 494.753 318.846 494.836 318.767 494.903C318.688 494.971 318.595 495.021 318.496 495.051C318.396 495.081 318.291 495.09 318.188 495.078C318.081 495.086 317.973 495.069 317.873 495.03C317.773 494.991 317.683 494.93 317.61 494.851C317.537 494.772 317.482 494.678 317.45 494.576C317.418 494.473 317.409 494.365 317.425 494.258C317.418 494.15 317.436 494.042 317.477 493.942C317.518 493.842 317.58 493.752 317.659 493.678C317.738 493.605 317.833 493.55 317.936 493.517C318.039 493.484 318.148 493.475 318.256 493.489C318.366 493.488 318.475 493.511 318.576 493.556C318.677 493.601 318.766 493.666 318.84 493.748C318.914 493.831 318.969 493.928 319.001 494.033C319.034 494.138 319.044 494.25 319.03 494.359Z" fill="black" />
            <path d="M306.458 562.679C306.418 563.19 306.16 563.51 305.666 563.521C305.559 563.533 305.451 563.521 305.348 563.488C305.247 563.454 305.153 563.392 305.076 563.319C304.999 563.246 304.939 563.151 304.901 563.05C304.864 562.949 304.849 562.842 304.858 562.735C304.844 562.629 304.854 562.522 304.887 562.421C304.921 562.314 304.977 562.224 305.051 562.146C305.126 562.068 305.216 562.008 305.317 561.97C305.417 561.932 305.525 561.918 305.632 561.927C305.737 561.917 305.843 561.929 305.943 561.962C306.043 561.995 306.134 562.049 306.212 562.118C306.29 562.191 306.352 562.275 306.394 562.376C306.436 562.472 306.458 562.573 306.458 562.679Z" fill="black" />
            <path d="M286.432 567.598C286.375 568.12 286.123 568.412 285.612 568.423C285.504 568.434 285.395 568.423 285.293 568.389C285.191 568.356 285.098 568.294 285.022 568.215C284.945 568.142 284.887 568.047 284.853 567.946C284.817 567.845 284.807 567.732 284.82 567.626C284.813 567.519 284.828 567.412 284.866 567.311C284.903 567.21 284.961 567.115 285.036 567.042C285.111 566.963 285.202 566.901 285.302 566.862C285.402 566.823 285.51 566.806 285.617 566.812C285.723 566.806 285.828 566.823 285.927 566.862C286.026 566.901 286.115 566.958 286.191 567.031C286.268 567.104 286.328 567.193 286.369 567.289C286.41 567.39 286.432 567.491 286.432 567.598Z" fill="black" />
            <path d="M305.527 496.594C305.42 496.601 305.314 496.586 305.214 496.549C305.114 496.512 305.022 496.456 304.945 496.382C304.869 496.307 304.808 496.218 304.768 496.119C304.728 496.021 304.709 495.914 304.713 495.808C304.708 495.697 304.726 495.585 304.767 495.482C304.806 495.378 304.868 495.283 304.947 495.204C305.025 495.125 305.119 495.063 305.223 495.022C305.326 494.981 305.438 494.962 305.549 494.966C305.655 494.963 305.762 494.983 305.86 495.023C305.959 495.064 306.047 495.126 306.121 495.203C306.194 495.28 306.251 495.372 306.287 495.472C306.323 495.573 306.338 495.679 306.33 495.785C306.333 495.892 306.314 495.998 306.275 496.097C306.235 496.196 306.176 496.286 306.102 496.362C306.027 496.438 305.937 496.497 305.838 496.537C305.739 496.577 305.633 496.596 305.527 496.594Z" fill="black" />
            <path d="M304.51 568.064C304.515 568.17 304.499 568.277 304.461 568.378C304.424 568.479 304.366 568.569 304.293 568.648C304.219 568.726 304.129 568.788 304.031 568.833C303.932 568.872 303.826 568.895 303.718 568.895C303.607 568.906 303.496 568.895 303.389 568.861C303.283 568.827 303.185 568.771 303.1 568.698C303.017 568.625 302.949 568.535 302.901 568.434C302.854 568.333 302.827 568.226 302.825 568.114C302.821 568.002 302.841 567.89 302.883 567.788C302.925 567.682 302.987 567.592 303.067 567.513C303.147 567.435 303.242 567.373 303.347 567.334C303.451 567.294 303.562 567.278 303.674 567.283C304.184 567.261 304.454 567.553 304.51 568.064Z" fill="black" />
            <path d="M315.246 571.977C315.25 572.084 315.233 572.19 315.195 572.286C315.156 572.387 315.098 572.477 315.023 572.555C314.948 572.628 314.858 572.69 314.759 572.729C314.661 572.769 314.555 572.786 314.448 572.78C314.248 572.763 314.061 572.673 313.925 572.522C313.788 572.376 313.713 572.179 313.713 571.983C313.713 571.781 313.788 571.584 313.925 571.438C314.061 571.286 314.248 571.197 314.448 571.18C314.954 571.169 315.223 571.449 315.246 571.977Z" fill="black" />
            <path d="M332.489 573.229C332.382 573.235 332.275 573.212 332.176 573.173C332.077 573.128 331.988 573.066 331.915 572.988C331.841 572.909 331.785 572.819 331.751 572.713C331.716 572.612 331.704 572.505 331.715 572.398C331.721 572.185 331.812 571.988 331.966 571.842C332.12 571.696 332.326 571.618 332.537 571.629C332.748 571.635 332.949 571.724 333.093 571.876C333.238 572.033 333.315 572.235 333.309 572.449C333.314 572.555 333.296 572.662 333.256 572.763C333.217 572.864 333.156 572.954 333.078 573.027C333 573.1 332.907 573.156 332.806 573.19C332.704 573.229 332.596 573.24 332.489 573.229Z" fill="black" />
            <path d="M319.81 574.734C319.305 574.672 319.008 574.425 319.019 573.909C319.009 573.802 319.023 573.695 319.06 573.594C319.097 573.493 319.156 573.398 319.233 573.325C319.31 573.252 319.403 573.19 319.505 573.156C319.607 573.122 319.714 573.111 319.822 573.122C319.93 573.117 320.038 573.128 320.139 573.167C320.24 573.207 320.332 573.268 320.407 573.347C320.483 573.42 320.54 573.516 320.576 573.617C320.612 573.718 320.624 573.83 320.613 573.937C320.617 574.043 320.599 574.15 320.56 574.245C320.521 574.347 320.462 574.436 320.387 574.509C320.311 574.588 320.221 574.644 320.122 574.683C320.023 574.723 319.917 574.74 319.81 574.734Z" fill="black" />
            <path d="M277.128 576.413C277.128 576.974 276.825 577.249 276.336 577.249C276.229 577.261 276.121 577.244 276.02 577.204C275.919 577.171 275.827 577.109 275.751 577.03C275.675 576.957 275.617 576.862 275.581 576.761C275.544 576.66 275.529 576.553 275.539 576.446C275.551 576.244 275.638 576.059 275.782 575.919C275.926 575.778 276.118 575.7 276.318 575.7C276.518 575.694 276.712 575.767 276.862 575.896C277.013 576.031 277.107 576.216 277.128 576.413Z" fill="black" />
            <path d="M269.856 579.815C269.8 580.377 269.541 580.652 269.036 580.646C268.928 580.657 268.82 580.641 268.719 580.607C268.617 580.568 268.525 580.511 268.449 580.433C268.373 580.354 268.314 580.264 268.278 580.163C268.242 580.062 268.229 579.95 268.239 579.843C268.23 579.737 268.245 579.63 268.282 579.529C268.32 579.428 268.379 579.332 268.455 579.259C268.532 579.181 268.624 579.125 268.725 579.085C268.826 579.052 268.934 579.035 269.042 579.046C269.145 579.04 269.249 579.057 269.347 579.096C269.445 579.136 269.534 579.186 269.61 579.259C269.686 579.332 269.746 579.416 269.788 579.512C269.83 579.607 269.853 579.708 269.856 579.815Z" fill="black" />
            <path d="M312.158 479.716C312.157 479.823 312.134 479.928 312.09 480.025C312.046 480.122 311.982 480.209 311.903 480.28C311.824 480.352 311.731 480.405 311.63 480.439C311.529 480.472 311.422 480.484 311.316 480.474C311.104 480.466 310.905 480.374 310.761 480.218C310.617 480.063 310.541 479.857 310.549 479.646C310.557 479.434 310.649 479.235 310.805 479.091C310.96 478.947 311.166 478.871 311.378 478.879C311.485 478.877 311.593 478.897 311.693 478.938C311.793 478.98 311.882 479.043 311.956 479.122C312.03 479.201 312.086 479.295 312.121 479.397C312.156 479.5 312.168 479.608 312.158 479.716Z" fill="black" />
            <path d="M287.902 580.343C287.906 580.45 287.888 580.556 287.848 580.657C287.808 580.753 287.749 580.843 287.673 580.916C287.597 580.994 287.506 581.05 287.406 581.09C287.306 581.123 287.199 581.14 287.093 581.135C286.882 581.135 286.679 581.05 286.529 580.899C286.38 580.753 286.296 580.551 286.296 580.337C286.296 580.124 286.38 579.922 286.529 579.776C286.679 579.624 286.882 579.54 287.093 579.54C287.201 579.535 287.309 579.551 287.41 579.585C287.511 579.624 287.602 579.681 287.679 579.759C287.756 579.832 287.815 579.928 287.853 580.029C287.892 580.13 287.908 580.236 287.902 580.343Z" fill="black" />
            <path d="M303.623 501.961C303.101 501.961 302.781 501.703 302.764 501.198C302.758 501.042 302.798 500.887 302.879 500.753C302.96 500.62 303.079 500.513 303.22 500.446C303.361 500.379 303.519 500.355 303.674 500.378C303.829 500.399 303.974 500.467 304.09 500.571C304.207 500.675 304.291 500.81 304.331 500.961C304.371 501.113 304.366 501.273 304.317 501.421C304.267 501.569 304.175 501.699 304.051 501.795C303.928 501.891 303.779 501.949 303.623 501.961Z" fill="black" />
            <path d="M315.947 583.869C316.053 583.869 316.158 583.891 316.256 583.931C316.353 583.976 316.44 584.043 316.51 584.122C316.58 584.2 316.632 584.296 316.663 584.397C316.694 584.498 316.703 584.605 316.688 584.711C316.695 584.818 316.678 584.925 316.637 585.026C316.598 585.127 316.536 585.217 316.457 585.29C316.378 585.363 316.283 585.419 316.18 585.447C316.078 585.481 315.969 585.492 315.863 585.475C315.754 585.48 315.646 585.464 315.546 585.419C315.447 585.379 315.357 585.318 315.284 585.233C315.211 585.155 315.157 585.059 315.125 584.958C315.093 584.857 315.084 584.745 315.099 584.638C315.096 584.532 315.118 584.419 315.16 584.324C315.203 584.223 315.267 584.133 315.347 584.06C315.427 583.987 315.523 583.931 315.626 583.897C315.73 583.869 315.839 583.858 315.947 583.869Z" fill="black" />
            <path d="M273.624 502.169C273.585 502.686 273.332 502.989 272.821 502.983C272.714 502.995 272.606 502.983 272.504 502.949C272.401 502.914 272.308 502.858 272.23 502.783C272.152 502.709 272.091 502.619 272.052 502.518C272.012 502.418 271.995 502.311 272.002 502.203C271.991 502.096 272.004 501.987 272.041 501.886C272.077 501.785 272.136 501.693 272.212 501.616C272.288 501.54 272.38 501.481 272.482 501.445C272.583 501.409 272.692 501.395 272.799 501.406C273.321 501.394 273.551 501.703 273.624 502.169Z" fill="black" />
            <path d="M326.655 587.777C326.762 587.766 326.872 587.783 326.974 587.816C327.077 587.85 327.171 587.912 327.249 587.985C327.328 588.058 327.39 588.153 327.431 588.249C327.471 588.35 327.49 588.462 327.486 588.569C327.495 588.675 327.482 588.782 327.446 588.889C327.41 588.99 327.353 589.08 327.277 589.158C327.202 589.237 327.111 589.298 327.01 589.332C326.91 589.371 326.802 589.388 326.694 589.383C326.481 589.388 326.275 589.31 326.12 589.164C325.966 589.012 325.876 588.81 325.871 588.602C325.866 588.389 325.945 588.181 326.093 588.024C326.239 587.872 326.442 587.783 326.655 587.777Z" fill="black" />
            <path d="M332.056 591.314C331.949 591.32 331.843 591.303 331.744 591.264C331.645 591.224 331.554 591.168 331.48 591.09C331.404 591.017 331.346 590.927 331.309 590.826C331.272 590.725 331.257 590.618 331.264 590.511C331.258 590.405 331.272 590.298 331.309 590.197C331.345 590.096 331.401 590.006 331.474 589.927C331.548 589.849 331.636 589.787 331.734 589.748C331.833 589.708 331.938 589.686 332.045 589.686C332.261 589.686 332.468 589.77 332.62 589.922C332.773 590.079 332.859 590.287 332.859 590.5C332.859 590.713 332.773 590.921 332.62 591.078C332.468 591.23 332.261 591.314 332.045 591.314H332.056Z" fill="black" />
            <path d="M275.886 595.334C275.386 595.25 275.077 595.014 275.089 594.492C275.079 594.385 275.094 594.279 275.132 594.178C275.17 594.077 275.23 593.987 275.307 593.914C275.385 593.835 275.478 593.779 275.58 593.745C275.682 593.712 275.791 593.706 275.897 593.717C276.004 593.706 276.113 593.723 276.214 593.762C276.314 593.801 276.406 593.858 276.481 593.936C276.556 594.015 276.614 594.105 276.648 594.206C276.683 594.312 276.695 594.419 276.683 594.526C276.683 595.025 276.341 595.245 275.886 595.334Z" fill="black" />
            <path d="M297.059 504.533C297.02 505.094 296.75 505.398 296.256 505.398C296.045 505.398 295.842 505.313 295.693 505.164C295.543 505.015 295.459 504.812 295.459 504.6C295.459 504.389 295.543 504.186 295.693 504.037C295.842 503.887 296.045 503.803 296.256 503.803C296.357 503.798 296.458 503.813 296.553 503.846C296.648 503.881 296.735 503.933 296.81 504.001C296.885 504.069 296.945 504.151 296.988 504.242C297.031 504.333 297.055 504.433 297.059 504.533Z" fill="black" />
            <path d="M315.129 505.027C315.141 505.136 315.13 505.245 315.095 505.349C315.061 505.452 315.004 505.547 314.93 505.626C314.854 505.705 314.763 505.767 314.662 505.807C314.561 505.847 314.452 505.865 314.343 505.858C314.184 505.861 314.029 505.818 313.895 505.733C313.762 505.648 313.656 505.525 313.592 505.38C313.528 505.236 313.508 505.075 313.535 504.919C313.562 504.763 313.634 504.619 313.743 504.504C313.852 504.389 313.992 504.308 314.146 504.273C314.301 504.238 314.462 504.248 314.61 504.305C314.758 504.36 314.886 504.459 314.979 504.588C315.071 504.716 315.123 504.869 315.129 505.027Z" fill="black" />
            <path d="M317.835 580.085C317.727 580.09 317.62 580.068 317.521 580.029C317.421 579.989 317.331 579.928 317.258 579.843C317.184 579.765 317.129 579.675 317.094 579.574C317.06 579.467 317.048 579.36 317.06 579.254C317.054 579.147 317.072 579.04 317.112 578.939C317.153 578.838 317.214 578.748 317.293 578.675C317.371 578.602 317.465 578.546 317.567 578.513C317.67 578.479 317.778 578.468 317.885 578.479C317.993 578.473 318.101 578.49 318.201 578.529C318.302 578.569 318.391 578.631 318.465 578.709C318.538 578.788 318.594 578.883 318.627 578.984C318.659 579.091 318.669 579.198 318.655 579.304C318.66 579.411 318.642 579.518 318.602 579.619C318.562 579.72 318.501 579.81 318.423 579.883C318.346 579.956 318.253 580.012 318.151 580.045C318.049 580.085 317.942 580.096 317.835 580.085Z" fill="black" />
            <path d="M286.975 512.596C287.424 512.641 287.744 512.826 287.767 513.326C287.785 513.432 287.779 513.541 287.751 513.645C287.723 513.749 287.672 513.845 287.602 513.928C287.533 514.01 287.446 514.076 287.349 514.121C287.251 514.167 287.145 514.19 287.037 514.19C286.93 514.211 286.82 514.206 286.716 514.177C286.611 514.148 286.514 514.095 286.433 514.023C286.352 513.951 286.288 513.861 286.247 513.761C286.205 513.661 286.188 513.552 286.195 513.444C286.186 513.336 286.2 513.227 286.235 513.125C286.271 513.023 286.327 512.929 286.4 512.85C286.473 512.77 286.562 512.706 286.661 512.663C286.76 512.619 286.867 512.596 286.975 512.596Z" fill="black" />
            <path d="M293.349 546.924C293.193 546.913 293.044 546.857 292.92 546.762C292.797 546.667 292.703 546.538 292.652 546.391C292.601 546.244 292.593 546.085 292.632 545.934C292.67 545.782 292.752 545.646 292.867 545.541C292.982 545.435 293.125 545.366 293.279 545.341C293.433 545.317 293.591 545.338 293.733 545.402C293.875 545.467 293.995 545.571 294.078 545.702C294.162 545.834 294.205 545.988 294.202 546.144C294.202 546.638 293.893 546.902 293.349 546.924Z" fill="black" />
            <path d="M308.255 490.384C308.258 490.543 308.214 490.699 308.128 490.832C308.043 490.966 307.919 491.071 307.773 491.133C307.627 491.196 307.466 491.214 307.31 491.185C307.154 491.155 307.011 491.08 306.898 490.968C306.785 490.857 306.707 490.715 306.676 490.559C306.645 490.404 306.66 490.242 306.721 490.096C306.782 489.949 306.885 489.824 307.017 489.736C307.149 489.649 307.304 489.602 307.463 489.603C307.569 489.593 307.675 489.606 307.775 489.642C307.875 489.678 307.966 489.734 308.041 489.809C308.117 489.883 308.175 489.973 308.212 490.073C308.249 490.172 308.263 490.278 308.255 490.384Z" fill="black" />
            <path d="M292.888 563.387C292.996 563.375 293.105 563.381 293.209 563.415C293.313 563.448 293.408 563.504 293.488 563.577C293.568 563.65 293.63 563.74 293.671 563.841C293.712 563.942 293.73 564.055 293.724 564.161C293.74 564.268 293.732 564.38 293.701 564.481C293.669 564.588 293.614 564.684 293.541 564.762C293.468 564.841 293.378 564.903 293.278 564.947C293.178 564.987 293.069 565.004 292.961 564.998C292.853 565.009 292.744 564.998 292.641 564.959C292.539 564.925 292.445 564.869 292.366 564.796C292.288 564.717 292.225 564.627 292.185 564.526C292.144 564.431 292.125 564.319 292.13 564.212C292.116 564.105 292.126 563.999 292.159 563.898C292.192 563.796 292.246 563.701 292.318 563.622C292.39 563.544 292.478 563.482 292.577 563.443C292.675 563.403 292.781 563.381 292.888 563.387Z" fill="black" />
            <path d="M295.909 590.376C295.804 590.388 295.697 590.371 295.598 590.337C295.498 590.304 295.407 590.247 295.33 590.174C295.253 590.096 295.193 590.012 295.154 589.91C295.114 589.815 295.096 589.708 295.101 589.602C295.089 589.495 295.102 589.383 295.138 589.282C295.174 589.181 295.232 589.085 295.308 589.012C295.385 588.934 295.477 588.872 295.579 588.838C295.681 588.799 295.79 588.782 295.898 588.793C296.005 588.782 296.114 588.793 296.217 588.827C296.32 588.86 296.413 588.917 296.492 588.995C296.571 589.068 296.633 589.158 296.674 589.259C296.715 589.36 296.733 589.467 296.729 589.574C296.74 589.686 296.726 589.793 296.688 589.899C296.651 590 296.59 590.096 296.512 590.174C296.433 590.247 296.338 590.309 296.234 590.343C296.129 590.377 296.019 590.388 295.909 590.376Z" fill="black" />
            <path d="M317.992 463.529C317.947 464.04 317.689 464.343 317.178 464.337C317.069 464.342 316.962 464.324 316.861 464.285C316.761 464.245 316.67 464.184 316.594 464.107C316.519 464.03 316.461 463.937 316.424 463.835C316.387 463.734 316.372 463.625 316.38 463.517C316.372 463.41 316.386 463.301 316.424 463.2C316.462 463.098 316.523 463.007 316.601 462.931C316.679 462.856 316.772 462.799 316.875 462.765C316.978 462.73 317.087 462.718 317.195 462.731C317.722 462.737 317.953 463.046 317.992 463.529Z" fill="black" />
            <path d="M270.199 494.887C270.193 495.102 270.103 495.306 269.949 495.456C269.795 495.606 269.588 495.69 269.373 495.69C269.266 495.695 269.158 495.679 269.058 495.641C268.957 495.603 268.865 495.545 268.788 495.47C268.71 495.396 268.649 495.306 268.608 495.206C268.566 495.107 268.546 495 268.548 494.893C268.547 494.781 268.569 494.671 268.611 494.567C268.654 494.465 268.718 494.371 268.797 494.293C268.877 494.215 268.971 494.154 269.075 494.113C269.179 494.073 269.29 494.053 269.401 494.056C269.617 494.062 269.821 494.152 269.97 494.308C270.12 494.464 270.201 494.671 270.199 494.887Z" fill="black" />
            <path d="M282.546 476.122C282.438 476.13 282.33 476.114 282.229 476.077C282.128 476.04 282.035 475.982 281.958 475.907C281.881 475.832 281.82 475.741 281.78 475.641C281.74 475.541 281.721 475.433 281.726 475.325C281.717 475.218 281.733 475.11 281.77 475.009C281.806 474.908 281.865 474.816 281.94 474.74C282.016 474.663 282.107 474.603 282.208 474.565C282.308 474.527 282.416 474.51 282.523 474.517C282.63 474.505 282.738 474.518 282.84 474.554C282.941 474.59 283.034 474.647 283.11 474.723C283.186 474.799 283.245 474.891 283.281 474.992C283.318 475.093 283.331 475.202 283.32 475.308C283.33 475.414 283.317 475.521 283.282 475.622C283.247 475.722 283.191 475.814 283.118 475.891C283.044 475.969 282.956 476.029 282.857 476.069C282.758 476.109 282.652 476.127 282.546 476.122Z" fill="black" />
            <path d="M283.999 591.09C284.007 591.196 283.99 591.303 283.952 591.398C283.914 591.5 283.855 591.589 283.778 591.662C283.701 591.735 283.609 591.791 283.509 591.825C283.409 591.859 283.302 591.87 283.197 591.859C283.038 591.859 282.883 591.808 282.752 591.718C282.622 591.629 282.52 591.505 282.462 591.353C282.403 591.208 282.39 591.045 282.423 590.893C282.457 590.736 282.536 590.596 282.651 590.483C282.765 590.377 282.91 590.304 283.066 590.276C283.223 590.247 283.384 590.27 283.528 590.332C283.673 590.399 283.795 590.506 283.879 590.64C283.963 590.775 284.005 590.932 283.999 591.09Z" fill="black" />
            <path d="M315.206 468.11C315.768 468.15 316.048 468.414 316.043 468.919C316.041 469.074 315.993 469.226 315.905 469.355C315.818 469.483 315.695 469.584 315.551 469.643C315.408 469.703 315.25 469.719 315.097 469.69C314.944 469.661 314.803 469.588 314.691 469.48C314.58 469.372 314.502 469.234 314.468 469.082C314.434 468.93 314.444 468.772 314.499 468.626C314.554 468.481 314.65 468.355 314.776 468.263C314.901 468.171 315.051 468.118 315.206 468.11Z" fill="black" />
            <path d="M290.94 503.466C290.378 503.466 290.092 503.169 290.081 502.68C290.07 502.574 290.083 502.466 290.117 502.365C290.151 502.263 290.206 502.169 290.279 502.091C290.351 502.011 290.439 501.949 290.538 501.906C290.636 501.864 290.743 501.842 290.85 501.844C290.956 501.832 291.065 501.844 291.166 501.878C291.269 501.913 291.361 501.969 291.439 502.044C291.517 502.118 291.577 502.209 291.616 502.309C291.655 502.41 291.671 502.517 291.664 502.624C291.673 502.727 291.662 502.831 291.63 502.93C291.598 503.028 291.547 503.12 291.48 503.198C291.412 503.277 291.33 503.341 291.237 503.387C291.144 503.433 291.043 503.46 290.94 503.466Z" fill="black" />
            <path d="M268.222 500.26C268.227 500.365 268.21 500.47 268.172 500.567C268.134 500.665 268.075 500.754 268.001 500.827C267.926 500.901 267.837 500.958 267.738 500.995C267.64 501.032 267.535 501.047 267.431 501.041C267.324 501.055 267.216 501.046 267.113 501.013C267.011 500.981 266.917 500.925 266.839 500.852C266.761 500.777 266.701 500.687 266.662 500.587C266.623 500.487 266.608 500.379 266.617 500.272C266.604 500.165 266.615 500.056 266.649 499.954C266.683 499.853 266.738 499.759 266.812 499.681C266.886 499.603 266.976 499.542 267.076 499.502C267.176 499.462 267.284 499.446 267.391 499.452C267.501 499.443 267.611 499.457 267.714 499.494C267.818 499.532 267.912 499.59 267.99 499.667C268.069 499.744 268.131 499.836 268.171 499.939C268.211 500.041 268.228 500.151 268.222 500.26Z" fill="black" />
            <path d="M279.002 504.073C278.969 504.634 278.71 504.926 278.211 504.943C277.999 504.948 277.794 504.869 277.641 504.723C277.488 504.577 277.399 504.377 277.394 504.165C277.389 503.954 277.467 503.749 277.613 503.596C277.759 503.443 277.96 503.353 278.171 503.348C278.274 503.338 278.378 503.349 278.476 503.381C278.575 503.412 278.665 503.463 278.743 503.531C278.821 503.599 278.884 503.682 278.928 503.775C278.973 503.868 278.998 503.969 279.002 504.073Z" fill="black" />
            <path d="M276.819 557.553C277.341 557.615 277.644 557.89 277.628 558.384C277.633 558.492 277.615 558.599 277.575 558.7C277.535 558.8 277.473 558.89 277.395 558.964C277.317 559.038 277.223 559.095 277.121 559.129C277.018 559.164 276.909 559.176 276.802 559.164C276.694 559.171 276.587 559.154 276.487 559.114C276.386 559.074 276.296 559.013 276.222 558.933C276.149 558.854 276.094 558.761 276.061 558.657C276.028 558.554 276.018 558.446 276.033 558.339C276.027 558.234 276.044 558.129 276.081 558.032C276.119 557.934 276.176 557.845 276.251 557.771C276.325 557.697 276.414 557.639 276.511 557.601C276.61 557.564 276.714 557.547 276.819 557.553Z" fill="black" />
            <path d="M321.984 519.704C321.939 520.226 321.658 520.524 321.169 520.518C321.062 520.527 320.953 520.511 320.853 520.472C320.752 520.434 320.66 520.373 320.585 520.296C320.51 520.218 320.453 520.125 320.418 520.022C320.383 519.92 320.372 519.812 320.383 519.704C320.374 519.597 320.389 519.489 320.427 519.389C320.465 519.287 320.524 519.197 320.602 519.122C320.679 519.047 320.772 518.99 320.875 518.956C320.977 518.922 321.085 518.911 321.192 518.924C321.296 518.917 321.401 518.933 321.5 518.97C321.598 519.007 321.687 519.064 321.762 519.138C321.836 519.211 321.895 519.3 321.933 519.397C321.971 519.495 321.989 519.6 321.984 519.704Z" fill="black" />
            <path d="M312.062 529.294C311.445 529.294 311.147 529.008 311.13 528.525C311.127 528.372 311.168 528.222 311.247 528.092C311.327 527.962 311.442 527.857 311.58 527.791C311.717 527.724 311.871 527.698 312.022 527.717C312.174 527.735 312.317 527.796 312.434 527.893C312.552 527.991 312.64 528.119 312.686 528.265C312.733 528.41 312.737 528.566 312.698 528.713C312.658 528.86 312.578 528.994 312.465 529.097C312.352 529.2 312.213 529.268 312.062 529.294Z" fill="black" />
            <path d="M291.805 569.552C291.812 569.658 291.796 569.771 291.758 569.872C291.721 569.973 291.662 570.068 291.585 570.147C291.509 570.225 291.417 570.281 291.315 570.326C291.214 570.366 291.105 570.383 290.996 570.377C290.889 570.388 290.781 570.371 290.681 570.332C290.58 570.298 290.489 570.237 290.413 570.158C290.338 570.085 290.28 569.99 290.244 569.888C290.208 569.787 290.195 569.681 290.205 569.574C290.194 569.467 290.208 569.361 290.244 569.26C290.28 569.158 290.339 569.063 290.415 568.99C290.492 568.911 290.584 568.855 290.685 568.822C290.787 568.782 290.895 568.771 291.002 568.782C291.106 568.777 291.211 568.788 291.31 568.822C291.409 568.861 291.499 568.917 291.575 568.99C291.651 569.063 291.711 569.147 291.75 569.243C291.79 569.344 291.808 569.445 291.805 569.552Z" fill="black" />
            <path d="M287.935 476.465C288.04 476.461 288.144 476.479 288.241 476.517C288.337 476.556 288.425 476.614 288.498 476.689C288.571 476.764 288.628 476.852 288.665 476.951C288.701 477.048 288.716 477.153 288.71 477.257C288.72 477.364 288.706 477.471 288.669 477.572C288.632 477.673 288.574 477.764 288.497 477.84C288.421 477.915 288.329 477.973 288.228 478.01C288.127 478.046 288.019 478.059 287.913 478.048C287.806 478.057 287.699 478.042 287.598 478.005C287.498 477.969 287.407 477.911 287.331 477.836C287.255 477.761 287.196 477.67 287.158 477.571C287.119 477.471 287.103 477.363 287.11 477.257C287.11 476.774 287.413 476.499 287.935 476.465Z" fill="black" />
            <path d="M328.059 535.167C327.553 535.122 327.25 534.864 327.256 534.358C327.246 534.248 327.26 534.138 327.296 534.033C327.332 533.929 327.388 533.834 327.463 533.752C327.538 533.671 327.629 533.606 327.73 533.562C327.831 533.517 327.94 533.494 328.05 533.494C328.16 533.494 328.27 533.517 328.371 533.562C328.471 533.606 328.562 533.671 328.637 533.752C328.712 533.834 328.769 533.929 328.804 534.033C328.84 534.138 328.854 534.248 328.845 534.358C328.851 534.465 328.835 534.571 328.798 534.67C328.761 534.769 328.704 534.86 328.63 534.936C328.556 535.012 328.467 535.072 328.369 535.112C328.27 535.151 328.165 535.17 328.059 535.167Z" fill="black" />
            <path d="M264.467 577.839C264.433 578.4 264.146 578.72 263.658 578.715C263.551 578.72 263.444 578.709 263.344 578.67C263.243 578.636 263.152 578.574 263.076 578.501C263 578.428 262.94 578.339 262.902 578.237C262.864 578.136 262.848 578.03 262.855 577.923C262.846 577.816 262.861 577.71 262.898 577.609C262.936 577.508 262.995 577.418 263.072 577.345C263.149 577.266 263.241 577.21 263.342 577.176C263.444 577.143 263.551 577.131 263.658 577.143C263.757 577.137 263.856 577.148 263.95 577.176C264.044 577.21 264.132 577.261 264.207 577.322C264.282 577.39 264.344 577.468 264.388 577.553C264.433 577.642 264.459 577.738 264.467 577.839Z" fill="black" />
            <path d="M279.558 552.954C279.496 553.448 279.266 553.78 278.761 553.78C278.654 553.789 278.546 553.775 278.445 553.739C278.344 553.702 278.252 553.644 278.175 553.569C278.098 553.495 278.039 553.404 278 553.303C277.962 553.203 277.945 553.095 277.952 552.988C277.939 552.882 277.951 552.774 277.986 552.673C278.021 552.572 278.079 552.48 278.154 552.405C278.23 552.329 278.322 552.271 278.423 552.236C278.524 552.201 278.632 552.19 278.738 552.202C278.843 552.19 278.95 552.201 279.049 552.233C279.15 552.266 279.242 552.319 279.32 552.391C279.398 552.463 279.459 552.55 279.5 552.647C279.541 552.744 279.561 552.849 279.558 552.954Z" fill="black" />
            <path d="M297.638 553.437C297.647 553.544 297.634 553.652 297.599 553.753C297.564 553.854 297.507 553.946 297.433 554.024C297.359 554.102 297.269 554.163 297.17 554.203C297.071 554.243 296.964 554.261 296.857 554.257C296.749 554.267 296.641 554.254 296.538 554.218C296.436 554.182 296.342 554.124 296.264 554.049C296.186 553.974 296.125 553.883 296.085 553.783C296.044 553.682 296.026 553.574 296.032 553.465C296.021 553.358 296.035 553.249 296.071 553.147C296.108 553.045 296.167 552.953 296.244 552.876C296.32 552.8 296.414 552.742 296.516 552.706C296.618 552.67 296.727 552.657 296.835 552.668C296.938 552.665 297.041 552.681 297.139 552.719C297.235 552.755 297.324 552.811 297.398 552.883C297.474 552.955 297.533 553.041 297.574 553.136C297.615 553.231 297.637 553.334 297.638 553.437Z" fill="black" />
            <path d="M334.802 585.205C334.804 585.306 334.783 585.413 334.742 585.509C334.7 585.598 334.638 585.688 334.561 585.756C334.484 585.823 334.392 585.874 334.294 585.907C334.195 585.935 334.09 585.941 333.988 585.93C333.883 585.924 333.78 585.902 333.684 585.862C333.589 585.817 333.502 585.756 333.431 585.683C333.359 585.604 333.303 585.514 333.265 585.419C333.228 585.318 333.21 585.217 333.213 585.11C333.216 585.003 333.24 584.902 333.282 584.807C333.325 584.711 333.386 584.627 333.463 584.554C333.538 584.481 333.628 584.425 333.726 584.386C333.824 584.352 333.928 584.329 334.033 584.335C334.527 584.352 334.808 584.638 334.802 585.205Z" fill="black" />
            <path d="M307.015 507.7C307.122 507.695 307.228 507.713 307.328 507.751C307.428 507.791 307.518 507.85 307.593 507.927C307.668 508.002 307.727 508.094 307.764 508.194C307.802 508.294 307.818 508.402 307.812 508.508C307.819 508.616 307.804 508.724 307.766 508.825C307.728 508.926 307.668 509.018 307.591 509.093C307.515 509.169 307.422 509.228 307.321 509.264C307.219 509.301 307.111 509.315 307.004 509.306C306.896 509.315 306.788 509.299 306.687 509.262C306.586 509.224 306.494 509.164 306.42 509.087C306.344 509.01 306.287 508.916 306.252 508.815C306.218 508.713 306.205 508.604 306.218 508.497C306.223 507.975 306.521 507.739 307.015 507.7Z" fill="black" />
            <path d="M288.84 544.268C288.835 544.371 288.81 544.472 288.764 544.564C288.719 544.656 288.654 544.737 288.576 544.804C288.497 544.869 288.406 544.918 288.307 544.947C288.209 544.976 288.105 544.984 288.004 544.97C287.896 544.978 287.789 544.962 287.689 544.923C287.589 544.883 287.498 544.822 287.425 544.743C287.352 544.665 287.297 544.57 287.265 544.468C287.233 544.366 287.224 544.257 287.24 544.15C287.233 544.043 287.25 543.935 287.29 543.835C287.33 543.734 287.392 543.645 287.471 543.571C287.549 543.498 287.644 543.442 287.746 543.409C287.849 543.377 287.958 543.367 288.065 543.381C288.178 543.379 288.289 543.401 288.392 543.447C288.494 543.492 288.585 543.56 288.659 543.645C288.733 543.729 288.787 543.829 288.819 543.937C288.85 544.044 288.857 544.158 288.84 544.268Z" fill="black" />
            <path d="M307.072 574.588C307.633 574.588 307.925 574.914 307.919 575.391C307.93 575.497 307.917 575.61 307.882 575.711C307.846 575.812 307.789 575.907 307.713 575.98C307.638 576.059 307.546 576.121 307.445 576.16C307.344 576.199 307.236 576.211 307.128 576.205C307.02 576.216 306.912 576.205 306.809 576.166C306.707 576.132 306.615 576.076 306.538 575.997C306.462 575.919 306.403 575.829 306.367 575.728C306.332 575.627 306.319 575.514 306.33 575.408C306.323 575.307 306.336 575.2 306.37 575.104C306.403 575.003 306.456 574.914 306.525 574.841C306.595 574.762 306.679 574.7 306.772 574.655C306.867 574.616 306.968 574.588 307.072 574.588Z" fill="black" />
            <path d="M323.455 495.471C324.078 495.471 324.381 495.729 324.387 496.223C324.398 496.33 324.386 496.437 324.352 496.539C324.318 496.64 324.263 496.734 324.19 496.812C324.117 496.89 324.029 496.951 323.93 496.992C323.831 497.033 323.724 497.052 323.617 497.049C323.511 497.06 323.404 497.049 323.302 497.014C323.2 496.98 323.108 496.924 323.03 496.85C322.953 496.777 322.892 496.687 322.853 496.587C322.814 496.488 322.797 496.381 322.803 496.274C322.785 496.177 322.789 496.078 322.813 495.983C322.837 495.887 322.881 495.799 322.944 495.722C323.006 495.646 323.083 495.584 323.172 495.54C323.26 495.497 323.356 495.474 323.455 495.471Z" fill="black" />
            <path d="M308.395 557.328C308.401 557.436 308.384 557.543 308.345 557.644C308.306 557.744 308.246 557.835 308.168 557.91C308.092 557.985 307.999 558.043 307.898 558.079C307.796 558.115 307.688 558.129 307.581 558.12C307.472 558.13 307.362 558.116 307.258 558.081C307.154 558.045 307.06 557.989 306.979 557.914C306.898 557.84 306.834 557.75 306.789 557.649C306.746 557.55 306.723 557.441 306.723 557.331C306.723 557.222 306.746 557.113 306.789 557.013C306.834 556.913 306.898 556.822 306.979 556.748C307.06 556.674 307.154 556.617 307.258 556.582C307.362 556.547 307.472 556.533 307.581 556.542C307.688 556.534 307.795 556.549 307.895 556.585C307.996 556.621 308.088 556.679 308.165 556.753C308.241 556.827 308.302 556.917 308.341 557.016C308.381 557.116 308.4 557.222 308.395 557.328Z" fill="black" />
            <path d="M320.075 489.716C319.974 489.713 319.875 489.69 319.783 489.647C319.692 489.605 319.61 489.544 319.542 489.469C319.475 489.394 319.424 489.306 319.392 489.21C319.36 489.114 319.348 489.013 319.356 488.913C319.35 488.804 319.367 488.696 319.407 488.595C319.446 488.494 319.508 488.403 319.587 488.328C319.665 488.254 319.759 488.197 319.862 488.162C319.965 488.127 320.074 488.115 320.181 488.127C320.29 488.121 320.398 488.138 320.5 488.177C320.601 488.217 320.692 488.277 320.769 488.355C320.845 488.433 320.903 488.525 320.94 488.628C320.978 488.729 320.993 488.838 320.984 488.946C321.001 489.429 320.681 489.721 320.075 489.716Z" fill="black" />
            <path d="M308.649 588.917C308.127 588.878 307.818 588.659 307.807 588.131C307.793 588.024 307.803 587.917 307.837 587.811C307.871 587.71 307.926 587.614 308 587.536C308.074 587.457 308.165 587.401 308.265 587.362C308.366 587.322 308.474 587.305 308.581 587.311C308.689 587.3 308.797 587.311 308.899 587.35C309 587.384 309.093 587.44 309.172 587.513C309.25 587.586 309.312 587.676 309.352 587.777C309.393 587.878 309.411 587.985 309.407 588.091C309.415 588.198 309.401 588.299 309.366 588.4C309.332 588.501 309.277 588.591 309.206 588.67C309.135 588.743 309.049 588.81 308.953 588.849C308.857 588.894 308.754 588.917 308.649 588.917Z" fill="black" />
            <path d="M267.785 518.323C267.718 518.794 267.482 519.114 266.965 519.103C266.858 519.113 266.751 519.099 266.65 519.062C266.549 519.025 266.458 518.967 266.382 518.89C266.307 518.815 266.249 518.723 266.212 518.621C266.176 518.52 266.163 518.413 266.173 518.306C266.165 518.199 266.18 518.09 266.217 517.989C266.254 517.888 266.313 517.796 266.39 517.72C266.466 517.643 266.558 517.584 266.659 517.547C266.761 517.51 266.869 517.495 266.976 517.503C267.47 517.497 267.734 517.817 267.785 518.323Z" fill="black" />
            <path d="M336.01 580.556C335.9 580.568 335.79 580.556 335.685 580.523C335.58 580.489 335.484 580.433 335.403 580.36C335.323 580.287 335.26 580.191 335.217 580.09C335.176 579.989 335.157 579.883 335.162 579.77C335.146 579.664 335.155 579.551 335.188 579.45C335.22 579.349 335.275 579.254 335.349 579.175C335.422 579.097 335.513 579.035 335.614 578.995C335.714 578.956 335.823 578.939 335.931 578.945C336.136 578.951 336.331 579.029 336.479 579.175C336.626 579.315 336.713 579.512 336.723 579.714C336.733 579.916 336.665 580.118 336.532 580.276C336.4 580.433 336.213 580.534 336.01 580.556Z" fill="black" />
            <path d="M287.234 464.562C287.239 464.671 287.222 464.78 287.183 464.881C287.143 464.983 287.083 465.076 287.006 465.152C286.928 465.229 286.836 465.289 286.734 465.327C286.632 465.366 286.523 465.383 286.414 465.376C286.306 465.384 286.198 465.368 286.098 465.33C285.997 465.292 285.906 465.232 285.831 465.155C285.755 465.078 285.698 464.986 285.662 464.884C285.626 464.783 285.612 464.675 285.622 464.567C285.612 464.46 285.626 464.351 285.664 464.25C285.702 464.148 285.761 464.056 285.839 463.981C285.916 463.905 286.009 463.849 286.112 463.814C286.214 463.78 286.323 463.768 286.431 463.781C286.536 463.774 286.642 463.789 286.741 463.826C286.84 463.862 286.931 463.919 287.006 463.992C287.082 464.066 287.141 464.155 287.181 464.253C287.22 464.351 287.238 464.456 287.234 464.562Z" fill="black" />
            <path d="M297.981 468.447C297.986 468.553 297.968 468.659 297.929 468.759C297.891 468.858 297.832 468.947 297.756 469.022C297.68 469.097 297.59 469.155 297.49 469.192C297.39 469.229 297.284 469.246 297.178 469.239C296.966 469.239 296.764 469.155 296.614 469.005C296.464 468.856 296.381 468.653 296.381 468.442C296.381 468.23 296.464 468.027 296.614 467.878C296.764 467.728 296.966 467.644 297.178 467.644C297.285 467.64 297.392 467.657 297.491 467.696C297.59 467.734 297.681 467.794 297.756 467.869C297.832 467.945 297.891 468.035 297.929 468.134C297.968 468.234 297.986 468.34 297.981 468.447Z" fill="black" />
            <path d="M300.608 578.838C300.618 578.951 300.604 579.063 300.567 579.17C300.532 579.276 300.474 579.372 300.398 579.456C300.322 579.535 300.23 579.602 300.128 579.647C300.025 579.692 299.914 579.714 299.803 579.714C299.691 579.714 299.58 579.692 299.478 579.647C299.375 579.602 299.283 579.535 299.208 579.456C299.132 579.372 299.074 579.276 299.038 579.17C299.002 579.063 298.988 578.951 298.997 578.838C298.988 578.732 299.002 578.619 299.039 578.518C299.076 578.417 299.136 578.327 299.213 578.249C299.291 578.176 299.384 578.12 299.487 578.08C299.589 578.047 299.698 578.035 299.806 578.047C299.912 578.041 300.02 578.052 300.119 578.092C300.22 578.125 300.311 578.187 300.388 578.26C300.464 578.333 300.523 578.423 300.561 578.524C300.599 578.625 300.615 578.732 300.608 578.838Z" fill="black" />
            <path d="M272.574 471.395C272.581 471.502 272.565 471.608 272.529 471.709C272.492 471.809 272.434 471.9 272.359 471.977C272.285 472.053 272.194 472.113 272.095 472.152C271.996 472.191 271.889 472.209 271.782 472.203C271.581 472.192 271.391 472.104 271.252 471.959C271.112 471.813 271.031 471.621 271.028 471.419C271.023 471.218 271.095 471.022 271.229 470.871C271.362 470.719 271.548 470.624 271.748 470.603C271.856 470.595 271.964 470.61 272.066 470.646C272.167 470.683 272.259 470.741 272.337 470.815C272.416 470.89 272.477 470.98 272.517 471.08C272.558 471.18 272.577 471.287 272.574 471.395Z" fill="black" />
            <path d="M289.861 472.703C289.384 472.647 289.064 472.422 289.07 471.9C289.057 471.794 289.068 471.686 289.102 471.585C289.135 471.483 289.192 471.39 289.266 471.313C289.34 471.235 289.43 471.175 289.53 471.137C289.631 471.099 289.738 471.084 289.845 471.092C289.952 471.081 290.06 471.093 290.162 471.129C290.263 471.164 290.356 471.221 290.434 471.296C290.512 471.371 290.572 471.462 290.61 471.562C290.649 471.663 290.665 471.771 290.659 471.878C290.665 471.986 290.649 472.093 290.611 472.194C290.574 472.296 290.515 472.388 290.441 472.465C290.366 472.543 290.276 472.604 290.176 472.645C290.076 472.686 289.969 472.706 289.861 472.703Z" fill="black" />
            <path d="M307.902 473.152C307.688 473.152 307.484 473.068 307.334 472.918C307.183 472.766 307.099 472.563 307.099 472.349C307.099 472.136 307.183 471.932 307.334 471.782C307.484 471.631 307.688 471.546 307.902 471.546C308.114 471.546 308.319 471.631 308.469 471.782C308.62 471.932 308.704 472.136 308.704 472.349C308.704 472.563 308.62 472.766 308.469 472.918C308.319 473.068 308.114 473.152 307.902 473.152Z" fill="black" />
            <path d="M301.169 525.409C301.061 525.418 300.954 525.404 300.852 525.367C300.75 525.331 300.658 525.273 300.58 525.199C300.502 525.123 300.442 525.033 300.402 524.932C300.362 524.833 300.344 524.725 300.349 524.617C300.338 524.509 300.352 524.4 300.389 524.297C300.426 524.195 300.486 524.103 300.563 524.027C300.641 523.95 300.735 523.893 300.838 523.858C300.941 523.824 301.05 523.812 301.158 523.825C301.368 523.825 301.57 523.909 301.718 524.057C301.866 524.205 301.95 524.407 301.95 524.617C301.95 524.827 301.866 525.028 301.718 525.177C301.57 525.326 301.368 525.409 301.158 525.409H301.169Z" fill="black" />
            <path d="M274.399 478.66C274.395 478.558 274.415 478.455 274.454 478.36C274.495 478.266 274.555 478.181 274.632 478.112C274.708 478.043 274.799 477.992 274.897 477.961C274.996 477.93 275.1 477.922 275.202 477.936C275.31 477.928 275.418 477.943 275.52 477.98C275.621 478.018 275.713 478.078 275.788 478.154C275.865 478.231 275.923 478.325 275.959 478.426C275.996 478.528 276.009 478.637 275.999 478.745C276.004 478.852 275.986 478.96 275.946 479.06C275.906 479.159 275.846 479.25 275.768 479.325C275.69 479.399 275.598 479.456 275.496 479.491C275.394 479.527 275.287 479.541 275.179 479.531C274.702 479.519 274.399 479.244 274.399 478.66Z" fill="black" />
            <path d="M303 555.38C303.012 555.489 303.003 555.601 302.971 555.706C302.939 555.811 302.886 555.909 302.814 555.993C302.743 556.077 302.655 556.145 302.556 556.193C302.457 556.242 302.349 556.27 302.239 556.275C302.129 556.28 302.019 556.262 301.916 556.223C301.813 556.183 301.719 556.123 301.641 556.046C301.562 555.969 301.5 555.877 301.458 555.775C301.417 555.672 301.397 555.563 301.4 555.453C301.388 555.346 301.4 555.237 301.435 555.136C301.469 555.033 301.526 554.94 301.6 554.862C301.674 554.784 301.764 554.723 301.865 554.684C301.965 554.644 302.073 554.627 302.18 554.633C302.285 554.619 302.391 554.628 302.493 554.66C302.593 554.692 302.686 554.744 302.764 554.816C302.842 554.887 302.904 554.974 302.945 555.072C302.985 555.169 303.004 555.274 303 555.38Z" fill="black" />
            <path d="M310.94 496.925C311.098 496.932 311.251 496.986 311.379 497.079C311.507 497.172 311.604 497.302 311.659 497.45C311.713 497.599 311.723 497.76 311.686 497.915C311.649 498.069 311.567 498.208 311.451 498.316C311.334 498.424 311.189 498.495 311.032 498.52C310.876 498.545 310.716 498.523 310.571 498.458C310.427 498.392 310.306 498.284 310.223 498.15C310.139 498.015 310.097 497.859 310.103 497.7C310.099 497.592 310.118 497.484 310.16 497.384C310.201 497.284 310.264 497.194 310.343 497.121C310.423 497.047 310.517 496.992 310.62 496.958C310.723 496.924 310.832 496.913 310.94 496.925Z" fill="black" />
            <path d="M318.098 560.433C318.839 560.484 319.131 560.77 319.131 561.259C319.142 561.367 319.127 561.476 319.089 561.578C319.052 561.68 318.992 561.772 318.913 561.848C318.835 561.923 318.741 561.98 318.638 562.014C318.535 562.048 318.425 562.059 318.317 562.045C317.823 562.017 317.475 561.697 317.565 561.208C317.632 560.866 317.958 560.602 318.098 560.433Z" fill="black" />
            <path d="M324.522 563.14C324.465 563.634 324.241 563.965 323.724 563.971C323.617 563.982 323.509 563.976 323.406 563.942C323.303 563.909 323.209 563.853 323.131 563.78C323.053 563.701 322.992 563.611 322.953 563.51C322.914 563.409 322.897 563.302 322.905 563.196C322.893 563.089 322.905 562.982 322.939 562.881C322.973 562.775 323.029 562.685 323.103 562.606C323.176 562.528 323.266 562.466 323.366 562.426C323.465 562.382 323.572 562.365 323.679 562.37C323.786 562.365 323.891 562.382 323.991 562.415C324.091 562.455 324.182 562.505 324.261 562.578C324.339 562.651 324.403 562.735 324.447 562.831C324.492 562.932 324.517 563.033 324.522 563.14Z" fill="black" />
            <path d="M322.926 483.579C322.929 483.687 322.91 483.794 322.869 483.894C322.829 483.994 322.768 484.084 322.69 484.159C322.612 484.234 322.519 484.291 322.418 484.327C322.316 484.364 322.208 484.379 322.101 484.371C321.992 484.377 321.885 484.36 321.784 484.32C321.683 484.28 321.592 484.219 321.518 484.14C321.443 484.062 321.387 483.967 321.353 483.865C321.319 483.762 321.308 483.653 321.32 483.545C321.314 483.438 321.331 483.331 321.37 483.231C321.41 483.131 321.471 483.042 321.549 482.968C321.627 482.894 321.721 482.839 321.823 482.806C321.926 482.773 322.033 482.762 322.14 482.776C322.246 482.771 322.352 482.789 322.451 482.828C322.549 482.866 322.639 482.925 322.713 483.001C322.787 483.077 322.845 483.168 322.881 483.267C322.918 483.367 322.933 483.473 322.926 483.579Z" fill="black" />
            <path d="M273.241 483.309C273.444 483.322 273.635 483.41 273.775 483.556C273.916 483.702 273.997 483.897 274.001 484.1C274.005 484.303 273.933 484.5 273.798 484.652C273.663 484.805 273.477 484.901 273.275 484.921C273.167 484.929 273.058 484.914 272.956 484.877C272.854 484.84 272.761 484.783 272.684 484.707C272.606 484.632 272.544 484.541 272.504 484.441C272.464 484.34 272.445 484.232 272.45 484.123C272.439 484.016 272.453 483.908 272.489 483.806C272.525 483.705 272.582 483.611 272.657 483.534C272.733 483.457 272.824 483.397 272.925 483.358C273.026 483.319 273.133 483.303 273.241 483.309Z" fill="black" />
            <path d="M271.339 488.699C271.498 488.703 271.652 488.753 271.782 488.845C271.913 488.936 272.013 489.064 272.07 489.212C272.128 489.36 272.139 489.523 272.105 489.678C272.07 489.832 271.99 489.974 271.875 490.084C271.76 490.193 271.615 490.266 271.458 490.293C271.301 490.32 271.14 490.3 270.995 490.236C270.85 490.171 270.727 490.065 270.642 489.93C270.557 489.795 270.515 489.639 270.519 489.48C270.512 489.373 270.53 489.265 270.569 489.164C270.609 489.064 270.669 488.974 270.748 488.899C270.826 488.825 270.919 488.769 271.021 488.734C271.123 488.7 271.231 488.688 271.339 488.699Z" fill="black" />
            <path d="M282.102 494.179C281.619 494.179 281.299 493.944 281.288 493.41C281.274 493.304 281.282 493.196 281.313 493.094C281.345 492.991 281.398 492.897 281.47 492.817C281.541 492.738 281.63 492.674 281.728 492.632C281.827 492.591 281.933 492.57 282.041 492.574C282.146 492.57 282.251 492.587 282.35 492.623C282.449 492.66 282.54 492.715 282.617 492.787C282.695 492.859 282.757 492.945 282.801 493.041C282.845 493.137 282.87 493.24 282.874 493.346C282.878 493.451 282.861 493.556 282.825 493.656C282.788 493.754 282.733 493.845 282.661 493.923C282.589 494 282.503 494.063 282.407 494.106C282.311 494.151 282.208 494.176 282.102 494.179Z" fill="black" />
            <path d="M287.492 496.156C287.385 496.163 287.276 496.147 287.175 496.111C287.073 496.074 286.98 496.017 286.901 495.942C286.823 495.868 286.761 495.779 286.718 495.679C286.676 495.58 286.655 495.473 286.656 495.364C286.641 495.257 286.649 495.149 286.681 495.045C286.714 494.942 286.768 494.848 286.841 494.768C286.914 494.689 287.004 494.626 287.104 494.586C287.204 494.545 287.312 494.527 287.419 494.533C287.525 494.516 287.635 494.523 287.738 494.554C287.842 494.585 287.936 494.639 288.016 494.712C288.095 494.785 288.157 494.875 288.197 494.975C288.236 495.076 288.253 495.184 288.245 495.291C288.253 495.398 288.24 495.505 288.207 495.606C288.173 495.707 288.12 495.801 288.05 495.881C287.98 495.962 287.894 496.028 287.799 496.075C287.703 496.122 287.598 496.15 287.492 496.156Z" fill="black" />
            <path d="M280.935 498.784C280.941 498.891 280.924 498.999 280.884 499.099C280.845 499.199 280.784 499.288 280.706 499.362C280.628 499.437 280.534 499.492 280.432 499.526C280.33 499.56 280.222 499.571 280.115 499.558C280.004 499.565 279.893 499.549 279.789 499.51C279.685 499.472 279.59 499.412 279.51 499.336C279.43 499.259 279.367 499.166 279.324 499.064C279.282 498.961 279.262 498.851 279.264 498.74C279.267 498.629 279.292 498.52 279.339 498.419C279.385 498.319 279.452 498.229 279.535 498.155C279.618 498.082 279.716 498.027 279.821 497.992C279.927 497.959 280.038 497.947 280.149 497.958C280.256 497.955 280.363 497.974 280.463 498.015C280.563 498.055 280.652 498.117 280.726 498.195C280.801 498.273 280.858 498.365 280.893 498.467C280.929 498.569 280.943 498.676 280.935 498.784Z" fill="black" />
            <path d="M298.166 500.03C298.058 500.032 297.951 500.011 297.852 499.969C297.752 499.927 297.663 499.865 297.59 499.786C297.516 499.707 297.46 499.613 297.425 499.511C297.39 499.41 297.376 499.301 297.386 499.193C297.38 499.086 297.398 498.978 297.438 498.878C297.479 498.778 297.541 498.688 297.62 498.615C297.7 498.542 297.795 498.488 297.898 498.456C298.001 498.424 298.11 498.415 298.217 498.43C298.323 498.423 298.431 498.44 298.53 498.48C298.63 498.52 298.719 498.581 298.792 498.659C298.865 498.737 298.92 498.831 298.953 498.933C298.985 499.036 298.994 499.143 298.98 499.25C298.985 499.356 298.966 499.462 298.926 499.562C298.887 499.661 298.826 499.75 298.749 499.823C298.672 499.897 298.581 499.954 298.48 499.99C298.38 500.025 298.272 500.039 298.166 500.03Z" fill="black" />
            <path d="M299.103 566.166C299.106 566.272 299.085 566.379 299.042 566.48C298.999 566.581 298.937 566.665 298.856 566.738C298.777 566.811 298.682 566.868 298.58 566.901C298.477 566.935 298.368 566.941 298.261 566.929C298.153 566.935 298.046 566.918 297.946 566.879C297.846 566.84 297.756 566.778 297.683 566.699C297.61 566.621 297.555 566.525 297.523 566.424C297.491 566.317 297.482 566.211 297.498 566.104C297.494 565.997 297.513 565.891 297.554 565.79C297.595 565.689 297.658 565.604 297.737 565.531C297.815 565.458 297.909 565.402 298.011 565.368C298.113 565.335 298.222 565.324 298.329 565.335C298.435 565.335 298.541 565.357 298.64 565.397C298.738 565.441 298.827 565.503 298.899 565.582C298.972 565.66 299.028 565.75 299.063 565.851C299.098 565.952 299.112 566.059 299.103 566.166Z" fill="black" />
            <path d="M320.866 501.647C320.855 501.54 320.868 501.433 320.903 501.332C320.938 501.23 320.994 501.138 321.068 501.06C321.142 500.983 321.233 500.922 321.332 500.884C321.432 500.844 321.539 500.827 321.647 500.833C321.806 500.829 321.963 500.872 322.098 500.957C322.232 501.042 322.338 501.165 322.403 501.31C322.468 501.456 322.487 501.617 322.46 501.775C322.432 501.931 322.359 502.077 322.249 502.191C322.138 502.306 321.996 502.386 321.841 502.42C321.685 502.454 321.523 502.441 321.375 502.383C321.227 502.325 321.099 502.224 321.008 502.092C320.918 501.962 320.868 501.807 320.866 501.647Z" fill="black" />
            <path d="M292.248 551.45C292.257 551.558 292.242 551.667 292.205 551.77C292.169 551.872 292.111 551.966 292.036 552.045C291.961 552.123 291.869 552.185 291.768 552.226C291.668 552.266 291.559 552.285 291.45 552.281C291.343 552.291 291.235 552.277 291.133 552.241C291.032 552.205 290.939 552.148 290.862 552.072C290.785 551.997 290.726 551.906 290.688 551.805C290.65 551.705 290.634 551.596 290.642 551.489C290.63 551.382 290.643 551.274 290.678 551.173C290.714 551.072 290.771 550.98 290.846 550.903C290.921 550.826 291.012 550.767 291.113 550.73C291.213 550.692 291.321 550.677 291.428 550.686C291.533 550.676 291.639 550.689 291.739 550.724C291.839 550.758 291.931 550.813 292.008 550.885C292.085 550.957 292.146 551.044 292.188 551.142C292.229 551.239 292.249 551.344 292.248 551.45Z" fill="black" />
            <path d="M277.728 523.011C277.515 523.011 277.311 522.927 277.16 522.776C277.01 522.625 276.925 522.421 276.925 522.208C276.925 521.995 277.01 521.791 277.16 521.641C277.311 521.49 277.515 521.405 277.728 521.405C277.836 521.397 277.943 521.412 278.044 521.449C278.146 521.486 278.238 521.544 278.316 521.62C278.392 521.695 278.453 521.786 278.491 521.886C278.531 521.987 278.548 522.095 278.542 522.203C278.549 522.311 278.532 522.42 278.494 522.521C278.455 522.623 278.395 522.715 278.318 522.791C278.241 522.868 278.148 522.927 278.047 522.965C277.944 523.003 277.836 523.018 277.728 523.011Z" fill="black" />
            <path d="M326.795 552.46C326.788 552.247 326.866 552.041 327.012 551.885C327.157 551.73 327.359 551.639 327.572 551.632C327.785 551.625 327.992 551.704 328.147 551.849C328.303 551.995 328.394 552.197 328.401 552.41C328.41 552.518 328.397 552.626 328.361 552.728C328.325 552.83 328.269 552.922 328.194 553.001C328.12 553.079 328.029 553.14 327.929 553.181C327.83 553.221 327.722 553.24 327.615 553.235C327.508 553.246 327.401 553.233 327.299 553.199C327.198 553.164 327.105 553.108 327.028 553.035C326.95 552.962 326.889 552.872 326.849 552.773C326.808 552.674 326.79 552.568 326.795 552.46Z" fill="black" />
            <path d="M289.48 556.054C289.991 556.099 290.305 556.357 290.3 556.857C290.311 556.964 290.297 557.073 290.261 557.174C290.224 557.276 290.165 557.368 290.088 557.443C290.01 557.519 289.917 557.576 289.815 557.61C289.713 557.645 289.604 557.656 289.497 557.643C289.39 557.656 289.282 557.646 289.18 557.611C289.078 557.576 288.985 557.519 288.909 557.443C288.833 557.368 288.775 557.275 288.74 557.173C288.704 557.072 288.692 556.964 288.705 556.857C288.697 556.752 288.711 556.646 288.747 556.547C288.783 556.448 288.839 556.358 288.912 556.282C288.985 556.206 289.074 556.146 289.172 556.107C289.27 556.068 289.375 556.05 289.48 556.054Z" fill="black" />
            <path d="M265.572 573.347C265.095 573.274 264.792 573.033 264.814 572.511C264.805 572.404 264.819 572.297 264.858 572.196C264.895 572.095 264.955 572.005 265.033 571.932C265.11 571.854 265.203 571.797 265.306 571.764C265.408 571.73 265.516 571.724 265.623 571.736C265.731 571.73 265.838 571.747 265.94 571.786C266.04 571.826 266.131 571.882 266.205 571.96C266.28 572.039 266.336 572.134 266.37 572.235C266.404 572.342 266.416 572.449 266.403 572.555C266.405 572.662 266.384 572.769 266.343 572.87C266.301 572.971 266.239 573.061 266.161 573.134C266.083 573.207 265.991 573.263 265.889 573.302C265.787 573.342 265.68 573.353 265.572 573.347Z" fill="black" />
            <path d="M273.747 569.119C273.674 569.619 273.416 569.922 272.905 569.9C272.798 569.905 272.69 569.888 272.59 569.849C272.491 569.81 272.401 569.748 272.327 569.669C272.254 569.591 272.199 569.501 272.165 569.394C272.132 569.293 272.122 569.187 272.136 569.08C272.129 568.973 272.146 568.866 272.186 568.765C272.225 568.664 272.286 568.574 272.364 568.501C272.443 568.429 272.536 568.372 272.638 568.339C272.74 568.305 272.848 568.294 272.955 568.305C273.062 568.305 273.168 568.322 273.266 568.361C273.365 568.4 273.454 568.462 273.529 568.541C273.603 568.614 273.661 568.709 273.698 568.805C273.736 568.906 273.752 569.012 273.747 569.119Z" fill="black" />
            <path d="M311.349 582.724C311.355 582.83 311.34 582.931 311.304 583.032C311.268 583.134 311.213 583.223 311.141 583.296C311.068 583.375 310.982 583.437 310.885 583.476C310.789 583.521 310.685 583.543 310.58 583.543C310.473 583.555 310.364 583.543 310.263 583.504C310.161 583.47 310.067 583.414 309.989 583.341C309.911 583.268 309.849 583.178 309.809 583.077C309.769 582.976 309.75 582.87 309.755 582.763C309.75 582.55 309.828 582.347 309.974 582.196C310.12 582.039 310.321 581.949 310.532 581.943C310.744 581.943 310.948 582.022 311.102 582.168C311.255 582.314 311.344 582.51 311.349 582.724Z" fill="black" />
            <path d="M317.761 513.18C317.654 513.184 317.547 513.166 317.448 513.126C317.349 513.086 317.259 513.026 317.185 512.949C317.111 512.872 317.054 512.78 317.017 512.68C316.981 512.579 316.967 512.472 316.975 512.366C316.975 512.154 317.059 511.951 317.208 511.802C317.358 511.652 317.56 511.568 317.772 511.568C317.984 511.568 318.186 511.652 318.336 511.802C318.485 511.951 318.569 512.154 318.569 512.366C318.578 512.474 318.563 512.583 318.525 512.686C318.487 512.788 318.428 512.88 318.351 512.958C318.275 513.035 318.182 513.095 318.08 513.133C317.978 513.171 317.869 513.188 317.761 513.18Z" fill="black" />
            <path d="M297.054 584.189C297.054 584.083 297.077 583.981 297.12 583.88C297.163 583.785 297.225 583.701 297.303 583.628C297.382 583.56 297.474 583.504 297.574 583.471C297.674 583.437 297.78 583.426 297.885 583.437C297.992 583.431 298.099 583.442 298.199 583.482C298.299 583.521 298.39 583.583 298.464 583.656C298.538 583.734 298.594 583.83 298.628 583.931C298.662 584.032 298.673 584.139 298.66 584.245C298.667 584.352 298.651 584.459 298.612 584.56C298.573 584.661 298.513 584.751 298.435 584.829C298.358 584.902 298.265 584.958 298.163 584.992C298.061 585.031 297.953 585.043 297.846 585.031C297.737 585.031 297.629 585.015 297.529 584.97C297.428 584.93 297.338 584.863 297.263 584.784C297.189 584.706 297.132 584.61 297.096 584.509C297.059 584.408 297.045 584.296 297.054 584.189Z" fill="black" />
            <path d="M301.681 572.656C302.243 572.656 302.541 572.954 302.557 573.443C302.568 573.549 302.555 573.656 302.52 573.763C302.485 573.864 302.428 573.953 302.353 574.032C302.278 574.111 302.188 574.172 302.087 574.212C301.987 574.251 301.879 574.268 301.771 574.262C301.664 574.279 301.555 574.268 301.453 574.234C301.35 574.201 301.256 574.144 301.179 574.066C301.101 573.993 301.041 573.903 301.004 573.802C300.967 573.695 300.952 573.589 300.963 573.482C300.95 573.381 300.959 573.274 300.989 573.179C301.019 573.078 301.07 572.988 301.138 572.909C301.206 572.831 301.289 572.769 301.383 572.724C301.476 572.679 301.578 572.656 301.681 572.656Z" fill="black" />
            <path d="M319.609 543.174C319.612 543.28 319.593 543.385 319.553 543.483C319.513 543.582 319.452 543.67 319.375 543.743C319.298 543.817 319.207 543.873 319.107 543.908C319.007 543.943 318.9 543.957 318.795 543.948C318.685 543.958 318.574 543.944 318.47 543.908C318.365 543.873 318.269 543.816 318.188 543.741C318.107 543.666 318.042 543.576 317.998 543.474C317.954 543.373 317.931 543.264 317.931 543.154C317.931 543.044 317.954 542.934 317.998 542.833C318.042 542.732 318.107 542.642 318.188 542.567C318.269 542.492 318.365 542.435 318.47 542.4C318.574 542.364 318.685 542.35 318.795 542.359C319.317 542.365 319.575 542.663 319.609 543.174Z" fill="black" />
            <path d="M283.679 573.813C283.572 573.824 283.463 573.808 283.362 573.774C283.26 573.74 283.168 573.678 283.091 573.605C283.014 573.527 282.954 573.437 282.916 573.336C282.878 573.235 282.863 573.128 282.87 573.021C282.858 572.915 282.869 572.808 282.903 572.701C282.937 572.6 282.993 572.51 283.068 572.432C283.143 572.353 283.234 572.292 283.334 572.252C283.435 572.213 283.543 572.202 283.651 572.207C283.756 572.207 283.86 572.23 283.958 572.269C284.055 572.308 284.144 572.37 284.219 572.443C284.293 572.516 284.352 572.606 284.393 572.701C284.433 572.802 284.454 572.904 284.454 573.01C284.454 573.117 284.433 573.218 284.393 573.319C284.352 573.414 284.293 573.504 284.219 573.577C284.144 573.65 284.055 573.712 283.958 573.751C283.86 573.791 283.756 573.813 283.651 573.813H283.679Z" fill="black" />
            <path d="M303.932 519.255C303.865 519.76 303.606 520.058 303.095 520.041C302.988 520.049 302.881 520.032 302.781 519.993C302.681 519.954 302.591 519.894 302.517 519.816C302.442 519.738 302.387 519.645 302.353 519.543C302.319 519.442 302.308 519.333 302.321 519.227C302.313 519.119 302.33 519.011 302.369 518.91C302.408 518.81 302.469 518.719 302.547 518.644C302.626 518.57 302.719 518.514 302.821 518.479C302.924 518.445 303.033 518.434 303.14 518.446C303.247 518.444 303.352 518.464 303.45 518.504C303.548 518.543 303.637 518.604 303.711 518.679C303.786 518.755 303.843 518.845 303.882 518.944C303.919 519.043 303.937 519.149 303.932 519.255Z" fill="black" />
            <path d="M278.419 534.925C278.417 534.765 278.462 534.609 278.549 534.475C278.636 534.34 278.761 534.235 278.908 534.172C279.055 534.109 279.217 534.091 279.374 534.119C279.532 534.148 279.677 534.224 279.792 534.335C279.906 534.447 279.985 534.589 280.018 534.746C280.052 534.902 280.038 535.065 279.979 535.214C279.92 535.362 279.818 535.49 279.686 535.58C279.555 535.671 279.399 535.721 279.239 535.723C279.132 535.729 279.024 535.713 278.924 535.676C278.822 535.638 278.73 535.58 278.653 535.505C278.577 535.43 278.516 535.34 278.475 535.24C278.435 535.14 278.416 535.033 278.419 534.925Z" fill="black" />
            <path d="M270.064 507.587C270.057 507.375 270.135 507.167 270.281 507.012C270.427 506.857 270.629 506.766 270.842 506.759C271.055 506.752 271.262 506.83 271.417 506.976C271.572 507.122 271.663 507.324 271.67 507.537C271.68 507.644 271.666 507.752 271.631 507.855C271.595 507.956 271.539 508.049 271.464 508.127C271.39 508.206 271.299 508.267 271.199 508.308C271.099 508.348 270.992 508.367 270.884 508.362C270.777 508.373 270.67 508.36 270.569 508.326C270.468 508.291 270.375 508.235 270.297 508.162C270.22 508.088 270.158 507.999 270.118 507.9C270.078 507.801 270.06 507.694 270.064 507.587Z" fill="black" />
            <path d="M267.998 548.283C268.154 548.283 268.308 548.33 268.439 548.417C268.569 548.504 268.672 548.627 268.732 548.771C268.793 548.916 268.81 549.076 268.781 549.23C268.751 549.384 268.677 549.526 268.567 549.638C268.457 549.751 268.317 549.828 268.163 549.86C268.01 549.893 267.85 549.88 267.704 549.822C267.558 549.764 267.432 549.665 267.343 549.536C267.253 549.406 267.203 549.254 267.201 549.097C267.19 548.989 267.204 548.88 267.24 548.778C267.276 548.676 267.334 548.583 267.411 548.505C267.486 548.428 267.578 548.368 267.679 548.33C267.781 548.291 267.889 548.275 267.998 548.283Z" fill="black" />
            <path d="M324.769 594.762C324.661 594.773 324.552 594.756 324.45 594.722C324.348 594.683 324.255 594.627 324.176 594.554C324.097 594.481 324.035 594.391 323.993 594.29C323.951 594.189 323.93 594.082 323.932 593.976C323.919 593.869 323.93 593.762 323.965 593.656C323.999 593.555 324.055 593.465 324.13 593.386C324.204 593.307 324.295 593.246 324.396 593.206C324.497 593.167 324.605 593.156 324.712 593.162C324.819 593.15 324.926 593.156 325.029 593.19C325.13 593.223 325.223 593.279 325.301 593.352C325.379 593.425 325.44 593.515 325.479 593.616C325.518 593.717 325.534 593.824 325.527 593.931C325.537 594.037 325.526 594.144 325.493 594.245C325.46 594.346 325.405 594.436 325.334 594.515C325.262 594.593 325.175 594.655 325.077 594.7C324.98 594.739 324.875 594.762 324.769 594.762Z" fill="black" />
            <path d="M309.508 552.763C309.307 552.747 309.12 552.655 308.984 552.507C308.847 552.359 308.771 552.165 308.771 551.963C308.771 551.762 308.847 551.568 308.984 551.42C309.12 551.272 309.307 551.18 309.508 551.163C309.615 551.154 309.724 551.168 309.825 551.205C309.927 551.242 310.02 551.301 310.096 551.378C310.172 551.454 310.231 551.547 310.268 551.648C310.305 551.75 310.32 551.858 310.31 551.966C310.317 552.073 310.301 552.181 310.263 552.281C310.225 552.381 310.166 552.471 310.09 552.547C310.013 552.623 309.923 552.681 309.822 552.718C309.722 552.756 309.614 552.771 309.508 552.763Z" fill="black" />
            <path d="M326.132 540.523C326.026 540.529 325.919 540.512 325.819 540.474C325.72 540.436 325.629 540.376 325.553 540.3C325.478 540.225 325.42 540.134 325.382 540.034C325.345 539.934 325.328 539.827 325.335 539.72C325.325 539.612 325.339 539.502 325.375 539.398C325.41 539.294 325.467 539.199 325.541 539.118C325.615 539.038 325.705 538.973 325.805 538.929C325.906 538.885 326.014 538.863 326.124 538.863C326.233 538.863 326.342 538.885 326.442 538.929C326.542 538.973 326.633 539.038 326.707 539.118C326.781 539.199 326.837 539.294 326.873 539.398C326.908 539.502 326.922 539.612 326.913 539.72C326.917 539.825 326.9 539.93 326.863 540.028C326.826 540.126 326.769 540.215 326.696 540.29C326.622 540.366 326.535 540.425 326.438 540.465C326.341 540.505 326.237 540.525 326.132 540.523Z" fill="black" />
            <path d="M277.295 539.501C277.496 539.518 277.683 539.61 277.819 539.758C277.955 539.906 278.031 540.101 278.031 540.302C278.031 540.503 277.955 540.697 277.819 540.845C277.683 540.993 277.496 541.085 277.295 541.102C277.184 541.111 277.073 541.097 276.968 541.061C276.863 541.025 276.766 540.968 276.684 540.893C276.603 540.818 276.537 540.726 276.493 540.624C276.448 540.523 276.425 540.413 276.425 540.302C276.425 540.191 276.448 540.081 276.493 539.979C276.537 539.877 276.603 539.786 276.684 539.711C276.766 539.636 276.863 539.578 276.968 539.542C277.073 539.506 277.184 539.492 277.295 539.501Z" fill="black" />
            <path d="M283.893 524.179C283.826 524.639 283.579 524.977 283.079 524.966C282.864 524.961 282.661 524.871 282.512 524.717C282.364 524.562 282.283 524.354 282.287 524.14C282.292 523.926 282.381 523.721 282.536 523.573C282.69 523.425 282.898 523.344 283.113 523.349C283.618 523.377 283.859 523.674 283.893 524.179Z" fill="black" />
            <path d="M294.994 522.635C294.987 522.529 295.003 522.423 295.041 522.324C295.079 522.225 295.139 522.136 295.215 522.063C295.292 521.989 295.384 521.933 295.484 521.899C295.585 521.866 295.691 521.854 295.797 521.866C295.904 521.856 296.012 521.87 296.113 521.907C296.215 521.944 296.307 522.003 296.382 522.08C296.458 522.157 296.515 522.25 296.551 522.351C296.587 522.453 296.6 522.561 296.588 522.668C296.586 522.773 296.563 522.877 296.521 522.972C296.479 523.068 296.418 523.155 296.343 523.227C296.267 523.3 296.178 523.357 296.08 523.395C295.983 523.433 295.879 523.451 295.774 523.449C295.669 523.447 295.566 523.424 295.471 523.382C295.375 523.34 295.288 523.279 295.215 523.204C295.143 523.128 295.086 523.039 295.048 522.941C295.01 522.844 294.992 522.74 294.994 522.635Z" fill="black" />
            <path d="M300.282 561.556C300.175 561.567 300.067 561.554 299.965 561.519C299.863 561.484 299.77 561.428 299.692 561.354C299.613 561.28 299.552 561.19 299.512 561.091C299.471 560.991 299.453 560.883 299.457 560.776C299.442 560.669 299.452 560.56 299.486 560.458C299.52 560.356 299.578 560.263 299.654 560.187C299.73 560.111 299.823 560.053 299.925 560.019C300.027 559.985 300.136 559.975 300.243 559.99C300.349 559.977 300.458 559.987 300.56 560.019C300.662 560.053 300.756 560.107 300.835 560.179C300.914 560.252 300.976 560.341 301.017 560.44C301.059 560.539 301.078 560.646 301.074 560.753C301.08 560.859 301.065 560.966 301.027 561.065C300.99 561.165 300.931 561.256 300.857 561.332C300.782 561.407 300.693 561.466 300.593 561.505C300.495 561.544 300.389 561.561 300.282 561.556Z" fill="black" />
            <path d="M270.289 561.843C270.29 561.949 270.269 562.055 270.226 562.151C270.183 562.252 270.119 562.337 270.039 562.41C269.959 562.477 269.864 562.533 269.762 562.561C269.66 562.589 269.552 562.6 269.447 562.584C269.339 562.589 269.232 562.567 269.134 562.527C269.035 562.483 268.947 562.421 268.876 562.337C268.805 562.258 268.753 562.163 268.723 562.059C268.694 561.956 268.687 561.848 268.705 561.741C268.7 561.634 268.72 561.526 268.762 561.426C268.804 561.327 268.868 561.238 268.948 561.166C269.029 561.094 269.124 561.041 269.228 561.01C269.331 560.98 269.441 560.972 269.548 560.989C269.655 560.989 269.761 561.012 269.858 561.056C269.956 561.1 270.043 561.165 270.113 561.246C270.184 561.327 270.235 561.423 270.266 561.525C270.296 561.628 270.304 561.736 270.289 561.843Z" fill="black" />
            <path d="M288.346 562.269C288.351 562.376 288.334 562.483 288.294 562.578C288.254 562.679 288.194 562.769 288.116 562.836C288.038 562.909 287.946 562.965 287.845 562.999C287.744 563.033 287.637 563.038 287.532 563.027C287.424 563.033 287.316 563.022 287.214 562.982C287.114 562.943 287.022 562.881 286.948 562.803C286.873 562.73 286.815 562.634 286.78 562.533C286.746 562.426 286.734 562.32 286.746 562.213C286.741 562.103 286.759 561.992 286.8 561.889C286.839 561.786 286.901 561.693 286.979 561.614C287.056 561.536 287.15 561.474 287.253 561.434C287.355 561.393 287.465 561.374 287.576 561.378C287.687 561.382 287.795 561.409 287.894 561.456C287.994 561.504 288.083 561.572 288.155 561.656C288.228 561.739 288.282 561.837 288.315 561.942C288.348 562.048 288.358 562.157 288.346 562.269Z" fill="black" />
            <path d="M331.477 540.855C331.582 540.845 331.689 540.858 331.788 540.893C331.887 540.928 331.978 540.984 332.053 541.059C332.129 541.132 332.187 541.221 332.224 541.32C332.262 541.419 332.277 541.525 332.269 541.63C332.282 541.736 332.272 541.845 332.239 541.947C332.206 542.049 332.152 542.143 332.079 542.222C332.006 542.301 331.918 542.363 331.818 542.404C331.719 542.445 331.613 542.465 331.506 542.461C331.398 542.472 331.289 542.461 331.187 542.426C331.084 542.391 330.99 542.335 330.911 542.261C330.832 542.187 330.77 542.097 330.73 541.997C330.689 541.896 330.669 541.788 330.675 541.68C330.666 541.572 330.68 541.462 330.717 541.359C330.754 541.257 330.812 541.163 330.888 541.085C330.964 541.007 331.056 540.946 331.158 540.906C331.26 540.866 331.368 540.849 331.477 540.855Z" fill="black" />
            <path d="M311.13 565.43C310.529 565.43 310.248 565.161 310.232 564.661C310.219 564.554 310.23 564.448 310.264 564.341C310.298 564.24 310.353 564.145 310.427 564.066C310.501 563.987 310.591 563.931 310.691 563.886C310.791 563.847 310.899 563.83 311.006 563.836C311.114 563.825 311.223 563.836 311.325 563.875C311.427 563.909 311.521 563.965 311.599 564.038C311.677 564.117 311.739 564.206 311.779 564.307C311.819 564.409 311.837 564.515 311.832 564.622C311.845 564.723 311.837 564.824 311.808 564.919C311.779 565.02 311.73 565.11 311.663 565.183C311.597 565.262 311.515 565.324 311.423 565.363C311.331 565.408 311.231 565.43 311.13 565.43Z" fill="black" />
            <path d="M315.61 566.548C315.605 566.441 315.622 566.34 315.661 566.239C315.701 566.143 315.761 566.054 315.838 565.981C315.915 565.913 316.007 565.857 316.108 565.823C316.208 565.79 316.314 565.784 316.419 565.795C316.526 565.79 316.634 565.801 316.734 565.84C316.835 565.88 316.925 565.941 317.001 566.014C317.075 566.093 317.133 566.188 317.168 566.289C317.203 566.39 317.216 566.497 317.205 566.604C317.198 566.817 317.106 567.014 316.952 567.16C316.797 567.306 316.591 567.379 316.38 567.373C316.168 567.367 315.968 567.272 315.824 567.12C315.68 566.963 315.603 566.761 315.61 566.548Z" fill="black" />
            <path d="M334.476 566.233C334.584 566.233 334.69 566.256 334.789 566.301C334.888 566.34 334.977 566.402 335.051 566.48C335.125 566.559 335.182 566.654 335.22 566.755C335.257 566.856 335.273 566.963 335.268 567.07C335.273 567.177 335.254 567.283 335.213 567.384C335.173 567.485 335.11 567.575 335.031 567.648C334.953 567.721 334.858 567.777 334.756 567.811C334.653 567.845 334.544 567.856 334.437 567.845C334.33 567.856 334.223 567.839 334.123 567.8C334.023 567.76 333.932 567.699 333.859 567.62C333.786 567.541 333.731 567.452 333.699 567.345C333.667 567.244 333.658 567.137 333.674 567.031C333.668 566.924 333.685 566.817 333.723 566.716C333.762 566.615 333.821 566.525 333.896 566.452C333.972 566.379 334.063 566.317 334.163 566.278C334.263 566.244 334.37 566.228 334.476 566.233Z" fill="black" />
            <path d="M290.16 521.49C289.862 521.49 289.598 521.181 289.598 520.681C289.591 520.574 289.609 520.467 289.648 520.367C289.688 520.267 289.749 520.177 289.827 520.104C289.905 520.03 289.999 519.975 290.101 519.942C290.203 519.909 290.311 519.899 290.418 519.912C290.529 519.899 290.642 519.912 290.748 519.949C290.854 519.986 290.95 520.047 291.029 520.126C291.108 520.205 291.169 520.301 291.206 520.408C291.243 520.513 291.256 520.626 291.243 520.737C291.193 521.22 290.906 521.518 290.16 521.49Z" fill="black" />
            <path d="M317.222 531.237C317.118 531.226 317.017 531.195 316.924 531.145C316.832 531.096 316.75 531.028 316.684 530.947C316.618 530.866 316.568 530.772 316.538 530.671C316.508 530.571 316.498 530.465 316.509 530.361C316.52 530.257 316.551 530.155 316.601 530.063C316.651 529.971 316.718 529.889 316.799 529.823C316.88 529.757 316.974 529.707 317.075 529.677C317.175 529.647 317.281 529.637 317.385 529.648C317.492 529.648 317.598 529.672 317.695 529.719C317.793 529.765 317.878 529.832 317.946 529.915C318.014 529.999 318.063 530.096 318.09 530.2C318.115 530.304 318.118 530.413 318.098 530.518C318.053 531.029 317.722 531.237 317.222 531.237Z" fill="black" />
            <path d="M322.574 568.541C322.506 569.024 322.276 569.327 321.76 569.333C321.653 569.344 321.546 569.333 321.445 569.299C321.345 569.265 321.253 569.209 321.177 569.13C321.1 569.057 321.043 568.968 321.006 568.867C320.97 568.765 320.957 568.659 320.968 568.552C320.957 568.445 320.969 568.339 321.005 568.238C321.04 568.137 321.098 568.041 321.174 567.968C321.25 567.89 321.342 567.833 321.443 567.794C321.545 567.76 321.652 567.744 321.76 567.755C321.865 567.749 321.97 567.766 322.069 567.805C322.168 567.845 322.258 567.901 322.333 567.974C322.41 568.047 322.47 568.137 322.511 568.232C322.552 568.333 322.574 568.434 322.574 568.541Z" fill="black" />
            <path d="M327.177 569.686C327.377 569.703 327.564 569.793 327.701 569.945C327.837 570.091 327.912 570.287 327.912 570.489C327.912 570.686 327.837 570.882 327.701 571.028C327.564 571.18 327.377 571.27 327.177 571.287C327.066 571.298 326.955 571.281 326.85 571.247C326.745 571.208 326.648 571.152 326.566 571.079C326.485 571 326.419 570.91 326.375 570.809C326.33 570.708 326.307 570.596 326.307 570.489C326.307 570.377 326.33 570.265 326.375 570.164C326.419 570.063 326.485 569.973 326.566 569.894C326.648 569.821 326.745 569.765 326.85 569.726C326.955 569.692 327.066 569.675 327.177 569.686Z" fill="black" />
            <path d="M324.668 527.834C324.558 527.847 324.447 527.836 324.342 527.803C324.237 527.769 324.14 527.713 324.058 527.64C323.976 527.566 323.911 527.475 323.868 527.374C323.823 527.273 323.801 527.164 323.803 527.054C323.792 526.947 323.804 526.838 323.838 526.735C323.873 526.633 323.929 526.539 324.003 526.461C324.077 526.383 324.167 526.321 324.268 526.281C324.368 526.241 324.476 526.223 324.583 526.228C324.796 526.217 325.005 526.291 325.163 526.434C325.322 526.577 325.417 526.776 325.428 526.989C325.44 527.202 325.366 527.411 325.223 527.569C325.08 527.728 324.88 527.823 324.668 527.834Z" fill="black" />
            <path d="M267.314 536.419C267.318 536.526 267.3 536.631 267.26 536.73C267.221 536.829 267.161 536.918 267.085 536.992C267.008 537.066 266.917 537.124 266.817 537.161C266.718 537.197 266.611 537.212 266.505 537.205C266.398 537.213 266.29 537.199 266.189 537.161C266.088 537.124 265.996 537.065 265.92 536.989C265.844 536.912 265.786 536.82 265.75 536.719C265.713 536.618 265.698 536.509 265.708 536.402C265.698 536.295 265.712 536.188 265.749 536.087C265.786 535.986 265.844 535.895 265.921 535.819C265.997 535.744 266.089 535.686 266.19 535.65C266.291 535.614 266.399 535.6 266.505 535.61C266.614 535.602 266.723 535.617 266.824 535.655C266.926 535.692 267.019 535.752 267.095 535.829C267.172 535.905 267.232 535.998 267.269 536.1C267.307 536.202 267.322 536.311 267.314 536.419Z" fill="black" />
            <path d="M270.951 573.673C271.451 573.707 271.748 573.954 271.754 574.459C271.77 574.56 271.764 574.661 271.737 574.756C271.709 574.858 271.661 574.947 271.594 575.026C271.527 575.099 271.445 575.161 271.352 575.2C271.26 575.245 271.159 575.262 271.058 575.262C270.947 575.279 270.835 575.267 270.729 575.234C270.622 575.206 270.524 575.15 270.439 575.077C270.355 575.009 270.286 574.919 270.238 574.818C270.189 574.717 270.163 574.61 270.159 574.498C270.159 574.285 270.241 574.083 270.389 573.926C270.536 573.774 270.737 573.684 270.951 573.673Z" fill="black" />
            <path d="M295.218 576.929C295.195 577.131 295.096 577.317 294.942 577.446C294.788 577.581 294.589 577.648 294.386 577.642C294.184 577.631 293.992 577.547 293.849 577.401C293.706 577.26 293.624 577.064 293.618 576.862C293.614 576.755 293.633 576.648 293.674 576.547C293.715 576.446 293.778 576.356 293.856 576.283C293.936 576.21 294.029 576.154 294.131 576.121C294.233 576.087 294.341 576.076 294.449 576.087C294.954 576.104 295.201 576.418 295.218 576.929Z" fill="black" />
            <path d="M284.589 537.671C284.483 537.677 284.378 537.659 284.279 537.62C284.181 537.581 284.092 537.521 284.019 537.444C283.946 537.367 283.89 537.275 283.856 537.175C283.822 537.075 283.81 536.968 283.82 536.863C283.808 536.756 283.82 536.648 283.854 536.546C283.89 536.444 283.947 536.351 284.022 536.274C284.098 536.198 284.189 536.138 284.29 536.101C284.391 536.064 284.499 536.05 284.606 536.06C284.714 536.052 284.822 536.068 284.924 536.106C285.026 536.144 285.118 536.203 285.195 536.28C285.271 536.357 285.331 536.449 285.368 536.55C285.406 536.652 285.422 536.761 285.415 536.868C285.42 536.977 285.402 537.086 285.361 537.187C285.322 537.288 285.26 537.38 285.182 537.456C285.105 537.531 285.012 537.59 284.909 537.627C284.807 537.664 284.698 537.68 284.589 537.671Z" fill="black" />
            <path d="M323.247 582.039C323.046 582.022 322.859 581.932 322.723 581.78C322.587 581.634 322.511 581.438 322.511 581.241C322.511 581.039 322.587 580.843 322.723 580.697C322.859 580.545 323.046 580.455 323.247 580.438C323.354 580.427 323.462 580.438 323.564 580.478C323.666 580.511 323.758 580.568 323.835 580.646C323.912 580.719 323.971 580.815 324.008 580.916C324.045 581.017 324.059 581.123 324.05 581.23C324.059 581.337 324.045 581.449 324.008 581.55C323.971 581.651 323.912 581.741 323.836 581.82C323.759 581.898 323.667 581.954 323.565 581.994C323.464 582.033 323.355 582.05 323.247 582.039Z" fill="black" />
            <path d="M293.742 461.934C293.633 461.939 293.526 461.92 293.426 461.88C293.325 461.839 293.235 461.777 293.161 461.698C293.087 461.619 293.03 461.525 292.996 461.423C292.962 461.32 292.95 461.211 292.961 461.103C292.956 460.994 292.974 460.885 293.013 460.784C293.052 460.681 293.112 460.589 293.188 460.511C293.265 460.433 293.357 460.373 293.458 460.332C293.56 460.291 293.668 460.272 293.777 460.276C293.887 460.279 293.994 460.305 294.092 460.352C294.191 460.399 294.279 460.466 294.35 460.548C294.422 460.631 294.475 460.727 294.508 460.831C294.54 460.936 294.551 461.045 294.539 461.154C294.545 461.259 294.528 461.365 294.49 461.464C294.452 461.562 294.393 461.651 294.317 461.725C294.242 461.799 294.152 461.856 294.052 461.892C293.953 461.928 293.847 461.943 293.742 461.934Z" fill="black" />
            <path d="M312.579 461.614C312.586 461.72 312.57 461.826 312.532 461.925C312.494 462.024 312.434 462.113 312.357 462.187C312.281 462.26 312.189 462.316 312.089 462.35C311.988 462.384 311.882 462.395 311.776 462.383C311.668 462.393 311.56 462.379 311.459 462.342C311.357 462.305 311.265 462.246 311.189 462.169C311.113 462.092 311.055 462 311.018 461.898C310.982 461.796 310.969 461.688 310.979 461.58C310.972 461.47 310.988 461.359 311.027 461.255C311.065 461.151 311.125 461.056 311.202 460.976C311.279 460.896 311.371 460.833 311.473 460.79C311.576 460.748 311.686 460.727 311.797 460.73C311.908 460.732 312.018 460.757 312.118 460.804C312.219 460.85 312.308 460.918 312.382 461.001C312.456 461.084 312.511 461.181 312.545 461.287C312.578 461.392 312.59 461.504 312.579 461.614Z" fill="black" />
            <path d="M299.665 512.736C299.557 512.742 299.448 512.724 299.348 512.683C299.247 512.643 299.156 512.581 299.082 512.501C299.008 512.422 298.952 512.328 298.918 512.225C298.884 512.122 298.872 512.013 298.885 511.905C298.878 511.798 298.895 511.69 298.935 511.59C298.975 511.49 299.035 511.4 299.114 511.326C299.192 511.253 299.285 511.197 299.387 511.163C299.489 511.129 299.598 511.118 299.705 511.13C299.811 511.124 299.918 511.142 300.018 511.183C300.117 511.223 300.206 511.285 300.279 511.363C300.351 511.442 300.405 511.537 300.437 511.639C300.469 511.741 300.478 511.85 300.462 511.956C300.468 512.061 300.452 512.167 300.414 512.265C300.375 512.364 300.317 512.453 300.241 512.527C300.165 512.601 300.075 512.658 299.976 512.694C299.876 512.73 299.77 512.745 299.665 512.736Z" fill="black" />
            <path d="M296.015 473.882C296.018 473.987 295.998 474.09 295.956 474.185C295.915 474.281 295.852 474.366 295.774 474.435C295.696 474.503 295.604 474.554 295.503 474.582C295.403 474.611 295.298 474.618 295.195 474.601C295.089 474.608 294.982 474.59 294.883 474.551C294.783 474.512 294.693 474.452 294.62 474.374C294.547 474.296 294.491 474.203 294.458 474.102C294.424 474.001 294.413 473.893 294.426 473.787C294.426 473.68 294.447 473.573 294.491 473.475C294.534 473.377 294.597 473.289 294.677 473.217C294.756 473.145 294.85 473.091 294.952 473.057C295.054 473.024 295.162 473.013 295.268 473.023C295.774 473.04 296.009 473.36 296.015 473.882Z" fill="black" />
            <path d="M275.341 546.447C275.236 546.457 275.13 546.444 275.031 546.409C274.931 546.375 274.839 546.32 274.763 546.248C274.686 546.176 274.625 546.088 274.585 545.991C274.544 545.894 274.524 545.789 274.527 545.683C274.514 545.576 274.524 545.467 274.558 545.364C274.591 545.262 274.647 545.167 274.721 545.089C274.795 545.01 274.885 544.948 274.985 544.909C275.086 544.869 275.194 544.851 275.302 544.858C275.41 544.847 275.518 544.859 275.621 544.893C275.724 544.927 275.818 544.983 275.898 545.056C275.978 545.129 276.04 545.218 276.083 545.318C276.126 545.417 276.147 545.524 276.144 545.633C276.161 546.127 275.875 546.396 275.341 546.447Z" fill="black" />
            <path d="M319.473 476.179C319.473 476.695 319.226 477.015 318.698 477.026C318.591 477.039 318.483 477.028 318.381 476.994C318.278 476.96 318.185 476.905 318.106 476.831C318.027 476.757 317.966 476.667 317.926 476.567C317.885 476.467 317.867 476.359 317.873 476.252C317.878 476.048 317.96 475.855 318.102 475.71C318.244 475.564 318.436 475.478 318.639 475.47C318.842 475.46 319.041 475.528 319.196 475.66C319.351 475.791 319.45 475.977 319.473 476.179Z" fill="black" />
            <path d="M282.422 511.456C282.371 512.017 282.091 512.293 281.597 512.276C281.489 512.28 281.382 512.262 281.282 512.221C281.182 512.181 281.092 512.12 281.018 512.041C280.944 511.963 280.888 511.87 280.853 511.768C280.818 511.666 280.806 511.558 280.816 511.45C280.811 511.343 280.829 511.235 280.868 511.135C280.909 511.035 280.971 510.946 281.049 510.871C281.127 510.798 281.221 510.742 281.324 510.708C281.426 510.674 281.534 510.663 281.642 510.676C281.745 510.669 281.85 510.685 281.948 510.722C282.045 510.759 282.134 510.816 282.208 510.889C282.282 510.964 282.339 511.052 282.376 511.15C282.413 511.248 282.429 511.352 282.422 511.456Z" fill="black" />
            <path d="M268.671 482.164C268.632 482.652 268.396 482.956 267.874 482.956C267.763 482.965 267.652 482.951 267.547 482.915C267.442 482.879 267.345 482.822 267.263 482.747C267.181 482.671 267.116 482.58 267.072 482.478C267.027 482.377 267.004 482.267 267.004 482.155C267.004 482.044 267.027 481.935 267.072 481.833C267.116 481.731 267.181 481.64 267.263 481.565C267.345 481.49 267.442 481.432 267.547 481.396C267.652 481.36 267.763 481.346 267.874 481.355C268.38 481.35 268.638 481.659 268.671 482.164Z" fill="black" />
            <path d="M290.519 588.456C290.412 588.456 290.307 588.434 290.209 588.394C290.112 588.35 290.024 588.288 289.952 588.209C289.879 588.131 289.824 588.041 289.789 587.94C289.754 587.839 289.741 587.732 289.749 587.625C289.738 587.519 289.75 587.412 289.786 587.311C289.822 587.204 289.881 587.114 289.957 587.041C290.034 586.963 290.127 586.907 290.229 586.873C290.331 586.839 290.439 586.828 290.547 586.839C290.654 586.834 290.762 586.845 290.863 586.884C290.964 586.923 291.056 586.985 291.132 587.058C291.208 587.137 291.265 587.227 291.302 587.333C291.339 587.434 291.353 587.541 291.344 587.648C291.345 587.754 291.324 587.861 291.282 587.962C291.241 588.063 291.18 588.153 291.103 588.226C291.026 588.305 290.934 588.361 290.834 588.4C290.734 588.439 290.626 588.462 290.519 588.456Z" fill="black" />
            <path d="M285.915 481.827C286.477 481.861 286.769 482.125 286.774 482.624C286.783 482.732 286.769 482.84 286.733 482.942C286.696 483.043 286.638 483.135 286.562 483.212C286.486 483.289 286.395 483.349 286.294 483.387C286.193 483.425 286.085 483.441 285.977 483.433C285.764 483.441 285.556 483.364 285.4 483.219C285.244 483.075 285.151 482.874 285.143 482.661C285.135 482.448 285.212 482.24 285.357 482.084C285.501 481.928 285.702 481.835 285.915 481.827Z" fill="black" />
            <path d="M278.643 589.125C278.576 589.613 278.334 589.916 277.829 589.928C277.63 589.911 277.444 589.821 277.309 589.675C277.173 589.523 277.099 589.332 277.099 589.136C277.099 588.934 277.173 588.743 277.309 588.591C277.444 588.445 277.63 588.355 277.829 588.338C277.934 588.333 278.039 588.355 278.137 588.395C278.236 588.428 278.325 588.49 278.401 588.563C278.477 588.636 278.538 588.72 278.579 588.816C278.621 588.917 278.642 589.018 278.643 589.125Z" fill="black" />
            <path d="M314.824 487.796C314.319 487.734 314.01 487.487 314.016 486.976C314.003 486.869 314.015 486.761 314.05 486.66C314.085 486.558 314.142 486.465 314.218 486.389C314.293 486.313 314.384 486.254 314.485 486.218C314.587 486.181 314.694 486.168 314.802 486.178C314.909 486.169 315.017 486.184 315.119 486.22C315.22 486.257 315.313 486.315 315.389 486.391C315.466 486.467 315.526 486.558 315.564 486.659C315.602 486.76 315.617 486.868 315.61 486.976C315.613 487.081 315.595 487.187 315.557 487.285C315.519 487.384 315.461 487.474 315.388 487.55C315.315 487.627 315.228 487.688 315.131 487.73C315.034 487.772 314.93 487.794 314.824 487.796Z" fill="black" />
            <path d="M302.905 488.464C302.82 488.975 302.568 489.272 302.062 489.266C301.863 489.245 301.679 489.149 301.547 488.998C301.415 488.847 301.345 488.652 301.351 488.451C301.357 488.251 301.437 488.06 301.578 487.917C301.718 487.773 301.907 487.688 302.107 487.677C302.316 487.679 302.517 487.762 302.665 487.909C302.814 488.056 302.9 488.255 302.905 488.464Z" fill="black" />
            <path d="M275.522 496.835C275.518 496.995 275.467 497.149 275.376 497.279C275.284 497.41 275.156 497.51 275.007 497.567C274.859 497.625 274.697 497.637 274.541 497.601C274.386 497.566 274.245 497.485 274.135 497.37C274.026 497.254 273.954 497.108 273.927 496.952C273.901 496.794 273.922 496.633 273.987 496.488C274.053 496.343 274.16 496.22 274.295 496.136C274.43 496.052 274.587 496.01 274.747 496.016C275.292 496.027 275.522 496.341 275.522 496.835Z" fill="black" />
            <path d="M299.592 548.008C299.547 548.536 299.311 548.856 298.806 548.872C298.696 548.889 298.585 548.879 298.48 548.846C298.374 548.812 298.278 548.755 298.199 548.679C298.12 548.602 298.058 548.509 298.02 548.405C297.983 548.301 297.968 548.191 297.98 548.081C297.966 547.974 297.975 547.866 298.008 547.763C298.04 547.66 298.095 547.567 298.168 547.487C298.24 547.408 298.33 547.345 298.429 547.304C298.529 547.263 298.636 547.245 298.744 547.25C298.849 547.243 298.956 547.256 299.056 547.29C299.157 547.325 299.249 547.379 299.328 547.449C299.407 547.52 299.471 547.606 299.516 547.702C299.562 547.798 299.587 547.902 299.592 548.008Z" fill="black" />
            <path d="M302.091 591.522C302.063 592.027 301.816 592.342 301.31 592.347C301.203 592.359 301.095 592.347 300.992 592.308C300.89 592.274 300.797 592.218 300.719 592.145C300.641 592.067 300.58 591.977 300.541 591.876C300.502 591.775 300.484 591.668 300.49 591.561C300.502 591.359 300.588 591.168 300.733 591.028C300.878 590.888 301.07 590.809 301.273 590.803C301.474 590.798 301.671 590.871 301.822 591.005C301.974 591.135 302.07 591.32 302.091 591.522Z" fill="black" />
            <path d="M304.185 550.832C304.078 550.84 303.971 550.825 303.87 550.789C303.77 550.754 303.678 550.697 303.599 550.624C303.522 550.551 303.461 550.462 303.419 550.363C303.378 550.264 303.358 550.159 303.359 550.052C303.345 549.946 303.354 549.838 303.387 549.736C303.419 549.633 303.474 549.54 303.547 549.461C303.62 549.383 303.71 549.322 303.809 549.282C303.909 549.242 304.016 549.225 304.123 549.232C304.229 549.218 304.338 549.227 304.441 549.26C304.544 549.293 304.639 549.347 304.718 549.419C304.798 549.492 304.861 549.582 304.903 549.681C304.944 549.78 304.963 549.888 304.959 549.995C304.968 550.102 304.954 550.21 304.919 550.311C304.884 550.412 304.828 550.505 304.756 550.584C304.683 550.663 304.594 550.725 304.496 550.768C304.398 550.811 304.292 550.833 304.185 550.832Z" fill="black" />
            <path d="M292.714 533.376C292.723 533.484 292.71 533.593 292.675 533.697C292.64 533.799 292.584 533.894 292.511 533.974C292.437 534.054 292.349 534.118 292.249 534.163C292.15 534.206 292.042 534.229 291.934 534.229C291.826 534.243 291.718 534.233 291.615 534.199C291.513 534.166 291.419 534.11 291.341 534.036C291.262 533.962 291.201 533.871 291.162 533.771C291.123 533.67 291.106 533.562 291.114 533.455C291.098 533.348 291.105 533.24 291.136 533.138C291.168 533.035 291.222 532.941 291.295 532.862C291.368 532.783 291.457 532.722 291.557 532.683C291.657 532.643 291.765 532.627 291.872 532.635C291.977 532.623 292.083 532.634 292.183 532.665C292.285 532.697 292.378 532.749 292.457 532.82C292.536 532.889 292.6 532.974 292.644 533.071C292.688 533.167 292.712 533.27 292.714 533.376Z" fill="black" />
            <path d="M313.27 577.277C313.27 577.839 312.995 578.153 312.512 578.176C312.405 578.187 312.297 578.176 312.195 578.142C312.093 578.108 312 578.052 311.921 577.979C311.843 577.906 311.781 577.816 311.741 577.715C311.7 577.614 311.682 577.508 311.686 577.401C311.672 577.294 311.681 577.188 311.714 577.087C311.746 576.98 311.801 576.89 311.874 576.811C311.947 576.733 312.037 576.671 312.136 576.632C312.236 576.592 312.343 576.576 312.45 576.581C312.551 576.564 312.654 576.57 312.753 576.598C312.851 576.626 312.942 576.677 313.02 576.738C313.098 576.806 313.161 576.89 313.204 576.98C313.247 577.075 313.269 577.176 313.27 577.277Z" fill="black" />
            <path d="M323.117 515.15C323.01 515.154 322.903 515.134 322.804 515.092C322.705 515.05 322.617 514.987 322.545 514.907C322.472 514.828 322.418 514.734 322.386 514.631C322.354 514.529 322.345 514.421 322.359 514.314C322.349 514.207 322.363 514.099 322.4 513.998C322.437 513.898 322.496 513.806 322.573 513.732C322.65 513.657 322.743 513.6 322.845 513.566C322.947 513.532 323.055 513.52 323.162 513.533C323.268 513.523 323.376 513.538 323.477 513.576C323.577 513.614 323.667 513.675 323.74 513.754C323.813 513.833 323.866 513.928 323.896 514.03C323.926 514.134 323.933 514.242 323.914 514.348C323.918 514.454 323.9 514.559 323.861 514.658C323.823 514.757 323.764 514.847 323.69 514.922C323.615 514.998 323.526 515.057 323.427 515.096C323.329 515.135 323.223 515.154 323.117 515.15Z" fill="black" />
            <path d="M298.413 480.339C299.154 480.384 299.452 480.665 299.457 481.153C299.464 481.255 299.45 481.358 299.415 481.455C299.381 481.551 299.327 481.64 299.257 481.714C299.187 481.789 299.102 481.849 299.008 481.889C298.914 481.93 298.813 481.951 298.71 481.951C298.256 481.99 297.778 481.558 297.891 481.114C298.014 480.825 298.19 480.562 298.413 480.339Z" fill="black" />
            <path d="M305.841 513.865C305.85 513.97 305.835 514.076 305.799 514.175C305.763 514.273 305.706 514.364 305.632 514.439C305.558 514.514 305.469 514.572 305.37 514.61C305.272 514.647 305.166 514.663 305.061 514.656C304.955 514.671 304.847 514.663 304.744 514.631C304.642 514.6 304.548 514.545 304.469 514.473C304.39 514.4 304.328 514.311 304.288 514.212C304.247 514.113 304.229 514.006 304.236 513.898C304.223 513.791 304.234 513.682 304.268 513.58C304.302 513.477 304.359 513.383 304.433 513.305C304.508 513.227 304.599 513.166 304.699 513.127C304.8 513.088 304.908 513.071 305.016 513.079C305.124 513.068 305.232 513.081 305.334 513.116C305.437 513.151 305.53 513.208 305.609 513.283C305.687 513.357 305.748 513.448 305.789 513.549C305.829 513.649 305.847 513.757 305.841 513.865Z" fill="black" />
            <path d="M284.46 470.732C284.354 470.741 284.247 470.727 284.148 470.69C284.048 470.653 283.958 470.594 283.884 470.517C283.81 470.441 283.754 470.349 283.719 470.249C283.685 470.148 283.673 470.041 283.685 469.935C283.676 469.828 283.69 469.719 283.727 469.618C283.764 469.517 283.823 469.425 283.9 469.35C283.977 469.274 284.069 469.216 284.171 469.18C284.272 469.145 284.381 469.132 284.488 469.143C284.598 469.137 284.708 469.152 284.812 469.19C284.915 469.228 285.01 469.287 285.09 469.362C285.17 469.439 285.233 469.53 285.275 469.632C285.317 469.733 285.339 469.843 285.336 469.954C285.335 470.064 285.31 470.172 285.264 470.272C285.218 470.372 285.151 470.462 285.069 470.535C284.987 470.608 284.89 470.664 284.785 470.697C284.68 470.732 284.57 470.744 284.46 470.732Z" fill="black" />
            <path d="M273.685 465.247C274.247 465.281 274.528 465.561 274.528 466.055C274.538 466.163 274.523 466.272 274.484 466.373C274.446 466.474 274.384 466.565 274.305 466.639C274.226 466.712 274.131 466.767 274.027 466.798C273.924 466.83 273.815 466.837 273.708 466.819C273.609 466.831 273.509 466.82 273.414 466.789C273.319 466.758 273.232 466.707 273.159 466.639C273.086 466.572 273.029 466.489 272.99 466.397C272.952 466.305 272.935 466.206 272.939 466.106C272.925 465.999 272.934 465.891 272.966 465.789C272.997 465.686 273.049 465.59 273.12 465.51C273.19 465.428 273.277 465.363 273.374 465.318C273.472 465.273 273.578 465.249 273.685 465.247Z" fill="black" />
            <path d="M299.148 462.26C299.253 462.257 299.356 462.277 299.452 462.316C299.549 462.356 299.637 462.415 299.71 462.489C299.782 462.564 299.839 462.653 299.875 462.751C299.912 462.849 299.928 462.953 299.923 463.057C299.937 463.164 299.927 463.272 299.893 463.374C299.859 463.476 299.802 463.57 299.727 463.647C299.652 463.723 299.56 463.782 299.459 463.818C299.358 463.854 299.249 463.867 299.143 463.854C299.035 463.868 298.927 463.858 298.825 463.825C298.723 463.791 298.629 463.735 298.551 463.661C298.473 463.586 298.413 463.496 298.374 463.395C298.336 463.294 298.32 463.187 298.328 463.079C298.317 462.591 298.62 462.31 299.148 462.26Z" fill="black" />
            <path d="M329.098 565.93C328.598 565.846 328.273 565.604 328.29 565.099C328.281 564.992 328.296 564.886 328.335 564.785C328.373 564.684 328.434 564.594 328.512 564.521C328.591 564.448 328.684 564.392 328.787 564.364C328.889 564.33 328.998 564.319 329.104 564.335C329.21 564.324 329.317 564.341 329.417 564.38C329.517 564.414 329.608 564.476 329.683 564.549C329.758 564.627 329.815 564.717 329.851 564.818C329.886 564.919 329.9 565.026 329.89 565.133C329.89 565.346 329.807 565.543 329.658 565.694C329.51 565.846 329.309 565.93 329.098 565.93Z" fill="black" />
            <path d="M302.613 538.109C302.411 538.086 302.226 537.988 302.094 537.833C301.962 537.679 301.894 537.481 301.902 537.278C301.91 537.075 301.996 536.883 302.14 536.741C302.284 536.598 302.477 536.515 302.68 536.509C302.788 536.503 302.896 536.52 302.996 536.559C303.097 536.599 303.187 536.66 303.262 536.738C303.337 536.815 303.394 536.909 303.429 537.011C303.464 537.113 303.477 537.221 303.466 537.329C303.469 537.438 303.447 537.547 303.404 537.647C303.361 537.748 303.297 537.838 303.216 537.912C303.136 537.986 303.04 538.042 302.936 538.076C302.832 538.11 302.721 538.121 302.613 538.109Z" fill="black" />
            <path d="M292.001 497.234C292.102 496.717 292.383 496.425 292.882 496.476C292.989 496.478 293.095 496.502 293.191 496.549C293.288 496.595 293.373 496.662 293.441 496.745C293.509 496.828 293.558 496.925 293.585 497.029C293.611 497.132 293.615 497.241 293.596 497.346C293.597 497.453 293.575 497.56 293.531 497.658C293.486 497.756 293.42 497.842 293.338 497.912C293.255 497.98 293.159 498.03 293.055 498.056C292.95 498.083 292.841 498.086 292.737 498.065C292.209 498.031 292.035 497.672 292.001 497.234Z" fill="black" />
            <path d="M314.218 541.197C314.228 541.304 314.215 541.413 314.179 541.515C314.142 541.617 314.084 541.709 314.008 541.786C313.932 541.862 313.84 541.921 313.738 541.958C313.637 541.996 313.529 542.01 313.421 542C313.314 542.01 313.206 541.996 313.105 541.958C313.005 541.922 312.913 541.864 312.838 541.787C312.762 541.711 312.705 541.619 312.669 541.518C312.632 541.417 312.619 541.309 312.629 541.203C312.62 541.093 312.634 540.982 312.67 540.878C312.705 540.773 312.762 540.678 312.837 540.596C312.912 540.515 313.002 540.45 313.103 540.406C313.204 540.362 313.313 540.339 313.424 540.339C313.534 540.339 313.643 540.362 313.744 540.406C313.845 540.45 313.936 540.515 314.011 540.596C314.085 540.678 314.142 540.773 314.178 540.878C314.214 540.982 314.227 541.093 314.218 541.203V541.197Z" fill="black" />
            <path d="M273.602 581.545C273.792 581.415 274.068 581.101 274.388 581.045C274.494 581.028 274.604 581.034 274.708 581.062C274.812 581.095 274.907 581.146 274.986 581.225C275.065 581.298 275.126 581.387 275.163 581.488C275.201 581.59 275.214 581.702 275.202 581.808C275.212 581.915 275.199 582.027 275.162 582.129C275.125 582.23 275.066 582.319 274.99 582.398C274.913 582.471 274.819 582.533 274.717 582.567C274.615 582.6 274.506 582.611 274.399 582.6C273.922 582.583 273.624 582.291 273.602 581.545Z" fill="black" />
            <path d="M268.958 512.147C269.064 512.148 269.17 512.171 269.268 512.216C269.365 512.26 269.452 512.325 269.522 512.405C269.593 512.486 269.646 512.581 269.677 512.683C269.708 512.786 269.718 512.894 269.704 513C269.709 513.107 269.69 513.214 269.648 513.312C269.607 513.41 269.543 513.499 269.463 513.569C269.382 513.64 269.287 513.691 269.184 513.72C269.081 513.749 268.973 513.755 268.868 513.736C268.76 513.742 268.652 513.724 268.553 513.683C268.453 513.643 268.363 513.581 268.291 513.501C268.218 513.421 268.164 513.326 268.133 513.223C268.101 513.12 268.094 513.011 268.11 512.905C268.112 512.798 268.135 512.693 268.18 512.596C268.224 512.499 268.288 512.412 268.368 512.34C268.447 512.27 268.541 512.215 268.643 512.182C268.744 512.149 268.851 512.137 268.958 512.147Z" fill="black" />
            <path d="M313.949 590.854C313.749 590.831 313.565 590.736 313.433 590.584C313.301 590.433 313.231 590.242 313.237 590.04C313.243 589.837 313.324 589.647 313.464 589.506C313.604 589.36 313.793 589.276 313.994 589.265C314.1 589.259 314.207 589.27 314.307 589.31C314.407 589.343 314.498 589.405 314.573 589.478C314.649 589.551 314.708 589.641 314.745 589.742C314.783 589.843 314.798 589.95 314.791 590.056C314.824 590.539 314.493 590.826 313.949 590.854Z" fill="black" />
            <path d="M318.445 524.819C318.661 524.597 318.924 524.424 319.215 524.314C319.709 524.218 320.023 524.583 320.04 525.06C320.053 525.168 320.042 525.277 320.007 525.38C319.973 525.483 319.915 525.576 319.84 525.654C319.765 525.732 319.673 525.793 319.572 525.831C319.471 525.869 319.362 525.883 319.254 525.875C318.754 525.863 318.468 525.577 318.445 524.819Z" fill="black" />
            <path d="M282.208 561.118C281.692 561.056 281.394 560.792 281.405 560.293C281.396 560.184 281.409 560.074 281.445 559.97C281.481 559.867 281.537 559.771 281.611 559.69C281.685 559.61 281.775 559.545 281.876 559.502C281.976 559.457 282.084 559.435 282.194 559.435C282.304 559.435 282.412 559.457 282.513 559.502C282.612 559.545 282.703 559.61 282.777 559.69C282.851 559.771 282.908 559.867 282.943 559.97C282.979 560.074 282.992 560.184 282.983 560.293C282.986 560.398 282.97 560.503 282.933 560.602C282.895 560.7 282.839 560.79 282.767 560.867C282.695 560.944 282.609 561.005 282.513 561.048C282.417 561.092 282.313 561.115 282.208 561.118Z" fill="black" />
            <path d="M296.785 487.307C296.269 487.307 295.954 487.066 295.943 486.538C295.928 486.432 295.936 486.323 295.968 486.221C296 486.118 296.054 486.024 296.126 485.944C296.198 485.865 296.288 485.802 296.387 485.761C296.486 485.72 296.594 485.702 296.701 485.707C296.808 485.694 296.916 485.704 297.019 485.737C297.121 485.77 297.216 485.825 297.295 485.899C297.374 485.972 297.436 486.062 297.477 486.161C297.518 486.261 297.536 486.369 297.532 486.476C297.543 486.581 297.532 486.687 297.499 486.787C297.467 486.887 297.413 486.979 297.343 487.058C297.272 487.136 297.187 487.199 297.091 487.242C296.995 487.285 296.89 487.307 296.785 487.307Z" fill="black" />
            <path d="M325.655 556.997C325.76 556.993 325.865 557.011 325.962 557.05C326.06 557.089 326.149 557.148 326.222 557.223C326.296 557.298 326.353 557.388 326.39 557.486C326.426 557.584 326.442 557.69 326.436 557.794C326.449 557.901 326.438 558.01 326.404 558.112C326.369 558.213 326.312 558.306 326.236 558.382C326.16 558.459 326.068 558.516 325.966 558.552C325.865 558.587 325.756 558.599 325.65 558.586C325.543 558.599 325.435 558.588 325.333 558.554C325.232 558.52 325.139 558.464 325.062 558.39C324.985 558.316 324.925 558.226 324.887 558.126C324.848 558.026 324.833 557.918 324.841 557.811C324.835 557.312 325.144 557.064 325.655 556.997Z" fill="black" />
            <path d="M290.759 538.861C290.745 539.016 290.686 539.163 290.589 539.284C290.493 539.406 290.363 539.496 290.216 539.545C290.068 539.594 289.91 539.599 289.761 539.559C289.611 539.519 289.475 539.436 289.372 539.321C289.268 539.205 289.201 539.062 289.177 538.909C289.154 538.755 289.176 538.598 289.24 538.457C289.305 538.316 289.409 538.197 289.54 538.114C289.671 538.031 289.823 537.988 289.979 537.991C290.09 537.989 290.2 538.01 290.302 538.054C290.404 538.098 290.495 538.164 290.57 538.247C290.644 538.329 290.699 538.427 290.732 538.533C290.764 538.64 290.774 538.751 290.759 538.861Z" fill="black" />
            <path d="M320.147 591.966C320.147 592.527 319.872 592.791 319.367 592.802C319.26 592.813 319.152 592.802 319.051 592.768C318.949 592.735 318.857 592.679 318.779 592.6C318.702 592.527 318.642 592.437 318.604 592.336C318.566 592.235 318.55 592.128 318.558 592.022C318.548 591.915 318.56 591.808 318.595 591.702C318.631 591.601 318.688 591.511 318.762 591.432C318.837 591.354 318.928 591.292 319.028 591.252C319.129 591.213 319.237 591.196 319.344 591.202C319.449 591.191 319.554 591.208 319.653 591.241C319.753 591.275 319.843 591.331 319.919 591.404C319.995 591.471 320.055 591.561 320.094 591.657C320.133 591.758 320.152 591.859 320.147 591.966Z" fill="black" />
            <path d="M274.888 562.926C274.992 562.92 275.096 562.937 275.193 562.977C275.291 563.016 275.379 563.072 275.452 563.145C275.526 563.218 275.583 563.308 275.62 563.409C275.657 563.504 275.674 563.605 275.668 563.712C275.682 563.819 275.672 563.926 275.638 564.027C275.605 564.128 275.55 564.223 275.476 564.296C275.402 564.375 275.312 564.436 275.212 564.47C275.112 564.509 275.005 564.526 274.899 564.515C274.792 564.532 274.684 564.521 274.582 564.487C274.479 564.453 274.386 564.403 274.306 564.33C274.228 564.257 274.166 564.167 274.126 564.066C274.085 563.965 274.067 563.858 274.073 563.751C274.057 563.241 274.354 562.977 274.888 562.926Z" fill="black" />
            <path d="M320.518 507.037C320.521 507.143 320.501 507.247 320.46 507.344C320.419 507.441 320.357 507.528 320.28 507.599C320.202 507.67 320.11 507.723 320.009 507.755C319.909 507.787 319.802 507.797 319.698 507.784C319.591 507.794 319.482 507.779 319.382 507.742C319.281 507.705 319.189 507.646 319.114 507.569C319.039 507.492 318.982 507.4 318.946 507.298C318.911 507.196 318.9 507.088 318.912 506.981C318.905 506.874 318.923 506.766 318.962 506.666C319.002 506.566 319.062 506.476 319.14 506.402C319.219 506.329 319.312 506.272 319.414 506.239C319.517 506.204 319.624 506.194 319.732 506.206C319.84 506.201 319.948 506.219 320.05 506.259C320.151 506.3 320.242 506.362 320.316 506.441C320.391 506.519 320.448 506.614 320.482 506.717C320.517 506.82 320.529 506.929 320.518 507.037Z" fill="black" />
            <path d="M307.442 593.56C307.437 593.661 307.412 593.757 307.369 593.846C307.325 593.931 307.264 594.009 307.188 594.077C307.113 594.138 307.025 594.183 306.931 594.217C306.836 594.245 306.737 594.251 306.639 594.239C306.531 594.251 306.422 594.234 306.321 594.195C306.22 594.155 306.129 594.099 306.055 594.015C305.98 593.936 305.925 593.841 305.893 593.74C305.861 593.639 305.853 593.526 305.87 593.42C305.868 593.313 305.89 593.206 305.933 593.105C305.976 593.01 306.04 592.92 306.121 592.847C306.201 592.78 306.296 592.724 306.399 592.695C306.502 592.662 306.611 592.651 306.718 592.667C307.178 592.712 307.459 592.999 307.442 593.56Z" fill="black" />
            <path d="M307.34 526.459C307.34 527.02 307.065 527.334 306.582 527.357C306.475 527.369 306.367 527.357 306.265 527.323C306.163 527.288 306.07 527.233 305.991 527.159C305.913 527.086 305.852 526.996 305.811 526.897C305.771 526.797 305.752 526.69 305.757 526.582C305.742 526.476 305.752 526.368 305.784 526.266C305.817 526.164 305.871 526.07 305.945 525.991C306.018 525.913 306.107 525.852 306.206 525.812C306.306 525.773 306.414 525.756 306.52 525.762C306.621 525.746 306.725 525.752 306.823 525.78C306.921 525.807 307.013 525.856 307.091 525.922C307.168 525.988 307.231 526.07 307.274 526.163C307.317 526.256 307.339 526.356 307.34 526.459Z" fill="black" />
            <path d="M272.468 587.945C272.255 587.945 272.051 587.861 271.9 587.71C271.75 587.558 271.665 587.356 271.665 587.142C271.665 586.929 271.75 586.727 271.9 586.575C272.051 586.424 272.255 586.34 272.468 586.34C272.573 586.34 272.678 586.362 272.775 586.401C272.873 586.441 272.961 586.502 273.036 586.575C273.11 586.648 273.169 586.738 273.21 586.834C273.25 586.935 273.271 587.036 273.271 587.142C273.271 587.249 273.25 587.35 273.21 587.451C273.169 587.547 273.11 587.637 273.036 587.71C272.961 587.783 272.873 587.844 272.775 587.884C272.678 587.923 272.573 587.945 272.468 587.945Z" fill="black" />
            <path d="M314.875 553.134C314.984 553.119 315.094 553.128 315.197 553.162C315.302 553.196 315.396 553.254 315.474 553.331C315.553 553.407 315.612 553.501 315.648 553.604C315.685 553.707 315.697 553.817 315.684 553.926C315.697 554.027 315.686 554.131 315.654 554.229C315.622 554.326 315.569 554.415 315.497 554.489C315.427 554.564 315.339 554.621 315.243 554.658C315.147 554.694 315.045 554.709 314.943 554.701C314.835 554.716 314.726 554.708 314.623 554.677C314.519 554.646 314.423 554.592 314.343 554.52C314.263 554.447 314.199 554.358 314.157 554.258C314.115 554.159 314.096 554.051 314.1 553.943C314.091 553.837 314.105 553.731 314.14 553.631C314.176 553.531 314.232 553.44 314.305 553.364C314.378 553.287 314.467 553.227 314.565 553.187C314.664 553.148 314.769 553.13 314.875 553.134Z" fill="black" />
            <path d="M285.163 584.908C285.64 584.953 285.971 585.2 285.96 585.705C285.967 585.812 285.95 585.924 285.909 586.025C285.869 586.12 285.807 586.216 285.727 586.289C285.648 586.362 285.553 586.418 285.449 586.446C285.346 586.48 285.236 586.491 285.129 586.474C285.027 586.48 284.925 586.469 284.829 586.435C284.733 586.401 284.646 586.345 284.573 586.272C284.5 586.205 284.443 586.115 284.406 586.019C284.37 585.924 284.354 585.823 284.36 585.722C284.343 585.205 284.64 584.947 285.163 584.908Z" fill="black" />
            <path d="M294.236 510.754C294.14 510.759 294.044 510.742 293.954 510.707C293.865 510.671 293.785 510.617 293.718 510.548C293.651 510.479 293.6 510.397 293.567 510.307C293.534 510.216 293.521 510.12 293.528 510.024C293.524 509.92 293.541 509.816 293.577 509.719C293.613 509.621 293.668 509.532 293.738 509.455C293.809 509.379 293.894 509.317 293.988 509.273C294.083 509.23 294.185 509.206 294.289 509.202C294.393 509.198 294.497 509.214 294.595 509.25C294.692 509.286 294.782 509.341 294.858 509.412C294.934 509.482 294.996 509.567 295.039 509.662C295.083 509.756 295.108 509.859 295.112 509.962C295.129 510.462 294.837 510.748 294.236 510.754Z" fill="black" />
            <path d="M279.688 517.632C279.177 517.587 278.868 517.335 278.868 516.824C278.857 516.716 278.871 516.608 278.908 516.506C278.947 516.405 279.007 516.314 279.086 516.24C279.164 516.167 279.259 516.112 279.363 516.081C279.466 516.049 279.575 516.043 279.682 516.06C279.783 516.048 279.885 516.059 279.983 516.091C280.079 516.123 280.167 516.177 280.241 516.247C280.315 516.317 280.373 516.403 280.41 516.497C280.447 516.592 280.463 516.694 280.457 516.796C280.466 516.902 280.453 517.009 280.419 517.111C280.385 517.212 280.329 517.305 280.257 517.384C280.184 517.463 280.097 517.525 279.998 517.568C279.901 517.611 279.794 517.633 279.688 517.632Z" fill="black" />
            <path d="M309.39 485.825C309.282 485.83 309.174 485.811 309.075 485.77C308.975 485.729 308.885 485.667 308.811 485.588C308.737 485.509 308.681 485.415 308.648 485.312C308.614 485.21 308.603 485.101 308.615 484.994C308.61 484.892 308.626 484.789 308.665 484.693C308.703 484.599 308.761 484.513 308.836 484.442C308.911 484.372 309.001 484.319 309.098 484.287C309.196 484.256 309.299 484.246 309.401 484.258C309.509 484.247 309.617 484.258 309.72 484.293C309.822 484.328 309.915 484.385 309.993 484.46C310.071 484.535 310.131 484.627 310.169 484.728C310.208 484.829 310.223 484.937 310.215 485.044C310.22 485.152 310.201 485.259 310.161 485.359C310.12 485.459 310.059 485.549 309.981 485.623C309.902 485.697 309.809 485.753 309.707 485.788C309.605 485.823 309.497 485.835 309.39 485.825Z" fill="black" />
            <path d="M293.955 595.649C293.461 595.688 293.175 595.48 293.169 594.969C293.166 594.863 293.184 594.762 293.221 594.661C293.259 594.565 293.315 594.475 293.387 594.397C293.459 594.324 293.545 594.262 293.64 594.217C293.736 594.178 293.839 594.155 293.944 594.15C294.049 594.15 294.153 594.166 294.251 594.2C294.349 594.239 294.438 594.296 294.515 594.369C294.59 594.442 294.652 594.526 294.694 594.621C294.737 594.717 294.761 594.818 294.764 594.924C294.775 595.464 294.472 595.688 293.955 595.649Z" fill="black" />
            <path d="M286.111 550.327C286.008 550.34 285.905 550.331 285.807 550.3C285.709 550.27 285.618 550.219 285.541 550.151C285.464 550.083 285.403 549.999 285.36 549.906C285.318 549.812 285.296 549.711 285.296 549.608C285.283 549.497 285.295 549.384 285.33 549.277C285.364 549.171 285.422 549.072 285.498 548.99C285.574 548.908 285.668 548.843 285.771 548.801C285.875 548.758 285.987 548.739 286.099 548.743C286.2 548.736 286.301 548.75 286.396 548.785C286.492 548.82 286.578 548.875 286.65 548.947C286.721 549.018 286.777 549.104 286.813 549.199C286.849 549.294 286.864 549.395 286.857 549.496C286.87 549.601 286.86 549.707 286.829 549.808C286.797 549.91 286.743 550.002 286.673 550.081C286.602 550.16 286.515 550.222 286.418 550.265C286.321 550.307 286.216 550.328 286.111 550.327Z" fill="black" />
            <path d="M264.607 540.984C264.71 540.98 264.814 540.997 264.91 541.036C265.006 541.074 265.092 541.132 265.164 541.207C265.236 541.281 265.292 541.369 265.327 541.466C265.363 541.563 265.378 541.667 265.371 541.77C265.38 541.878 265.365 541.986 265.327 542.087C265.289 542.188 265.228 542.279 265.15 542.354C265.072 542.428 264.978 542.484 264.875 542.517C264.772 542.55 264.663 542.56 264.556 542.545C264.455 542.555 264.354 542.543 264.258 542.509C264.162 542.476 264.075 542.422 264.002 542.352C263.93 542.281 263.873 542.195 263.837 542.101C263.801 542.006 263.786 541.905 263.793 541.804C263.787 541.298 264.034 541.012 264.607 540.984Z" fill="black" />
            <path d="M330.726 529.165C330.726 529.558 330.434 529.726 330.035 529.726C329.928 529.743 329.818 529.736 329.715 529.705C329.611 529.675 329.515 529.621 329.434 529.549C329.353 529.478 329.289 529.389 329.246 529.289C329.204 529.19 329.183 529.082 329.187 528.974C329.175 528.867 329.187 528.758 329.222 528.656C329.258 528.553 329.315 528.46 329.391 528.383C329.467 528.306 329.559 528.247 329.661 528.21C329.762 528.174 329.871 528.16 329.979 528.171C330.63 528.16 330.653 528.648 330.726 529.165Z" fill="black" />
            <path d="M274.836 460.62C275.077 460.48 275.327 460.355 275.583 460.244C275.841 460.143 276.144 459.823 276.363 460.283C276.468 460.459 276.5 460.67 276.45 460.868C276.401 461.067 276.274 461.238 276.099 461.345C275.594 461.687 275.055 461.395 274.836 460.62Z" fill="black" />
            <path d="M337.621 591.64C337.738 591.752 338.137 592.083 338.109 592.123C337.834 592.505 337.532 592.87 337.205 593.212C337.02 592.965 336.711 592.735 336.644 592.465C336.599 591.966 336.936 591.696 337.621 591.64Z" fill="black" />
            <path d="M264.68 524.381L264.753 522.697C265.157 522.921 265.528 523.017 265.685 523.258C265.753 523.389 265.788 523.534 265.788 523.682C265.788 523.83 265.753 523.975 265.685 524.106C265.522 524.336 265.123 524.42 264.854 524.566L264.68 524.381Z" fill="black" />
            <path d="M328.912 516.97C328.137 517.122 327.727 516.88 327.688 516.336C327.648 515.791 327.996 515.527 328.704 515.504L328.912 516.97Z" fill="black" />
            <path d="M311.276 595.609C311.276 594.936 311.529 594.582 312.057 594.627C312.585 594.672 312.877 594.992 312.792 595.609H311.276Z" fill="black" />
            <path d="M261.8 584.071V582.578L262.013 582.46C262.182 582.712 262.451 582.943 262.502 583.218C262.608 583.656 262.328 583.964 261.8 584.071Z" fill="black" />
            <path d="M326.845 502.821L327.064 504.314L326.862 504.449C326.655 504.213 326.301 503.994 326.301 503.736C326.301 503.477 326.48 503.096 326.593 502.77L326.845 502.821Z" fill="black" />
            <path d="M265.539 506.352L265.635 504.932L265.876 504.842C266.016 505.123 266.28 505.403 266.258 505.678C266.235 505.954 265.938 506.206 265.758 506.47L265.539 506.352Z" fill="black" />
            <path d="M339.092 587.884C338.923 587.67 338.637 587.463 338.62 587.232C338.65 586.918 338.737 586.609 338.878 586.328L339.12 586.385C339.176 586.845 339.238 587.311 339.294 587.777L339.092 587.884Z" fill="black" />
            <path d="M330.843 595.61H329.423C329.821 594.913 330.304 594.896 330.843 595.61Z" fill="black" />
            <path d="M263.871 595.615H262.607C262.978 595.042 263.365 595.037 263.871 595.615Z" fill="black" />
            <path d="M264.067 437.746H194.31C193.375 437.747 192.443 437.853 191.531 438.06C190.609 437.851 189.668 437.746 188.723 437.746H75.6373C72.2948 437.746 69.09 439.073 66.7262 441.436C64.3624 443.799 63.0333 447.003 63.0322 450.345V583.414C63.035 586.755 64.3646 589.961 66.7278 592.319C69.0916 594.683 72.296 596.008 75.6373 596.008H188.746C189.69 596.008 190.632 595.907 191.553 595.699C192.465 595.907 193.397 596.008 194.333 596.008H264.067C267.405 596.003 270.604 594.672 272.962 592.308C275.32 589.95 276.644 586.744 276.644 583.409V450.34C276.643 447.003 275.318 443.803 272.96 441.442C270.602 439.081 267.404 437.752 264.067 437.746Z" fill="white" />
            <path d="M264.067 437.746H194.31C193.375 437.747 192.443 437.853 191.531 438.06C190.609 437.851 189.668 437.746 188.723 437.746H75.6373C72.2948 437.746 69.09 439.073 66.7262 441.436C64.3624 443.799 63.0333 447.003 63.0322 450.345V583.414C63.035 586.755 64.3646 589.961 66.7278 592.319C69.0916 594.683 72.296 596.008 75.6373 596.008H188.746C189.69 596.008 190.632 595.907 191.553 595.699C192.465 595.907 193.397 596.008 194.333 596.008H264.067C267.405 596.003 270.604 594.672 272.962 592.308C275.32 589.95 276.644 586.744 276.644 583.409V450.34C276.643 447.003 275.318 443.803 272.96 441.442C270.602 439.081 267.404 437.752 264.067 437.746Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M191.969 452.984V583.409" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M191.671 452.692H77.6641V583.111H191.671V452.692Z" fill="white" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M391.701 399.178C369.203 399.178 345.492 389.246 321.22 369.656C299.137 351.835 288.239 331.476 266.532 290.87C257.841 274.644 249.879 257.833 242.866 240.955C242.849 240.919 242.841 240.88 242.841 240.84C242.841 240.8 242.849 240.762 242.866 240.725C242.885 240.689 242.911 240.656 242.943 240.63C242.974 240.604 243.012 240.585 243.051 240.574L489.914 178.216H489.981C490.038 178.219 490.091 178.236 490.139 178.267C490.172 178.29 490.199 178.32 490.219 178.355C490.24 178.39 490.253 178.429 490.257 178.469C492.614 201.054 493.19 223.789 491.98 246.463C489.858 286.193 488.578 310.168 474.316 337.326C452.133 379.56 425.58 393.35 407.209 397.488L406.137 397.718C401.389 398.702 396.555 399.195 391.707 399.19L391.701 399.178Z" fill="black" />
            <path d="M490.005 178.486C492.359 201.052 492.933 223.768 491.723 246.424C489.584 286.502 488.354 310.061 474.093 337.164C468.753 347.332 447.316 388.151 407.171 397.185C406.676 397.297 406.289 397.376 406.098 397.415C401.37 398.398 396.553 398.889 391.725 398.881C357.929 398.881 329.103 375.624 321.417 369.42C299.379 351.638 288.52 331.33 266.802 290.719C255.916 270.371 248.167 252.903 243.148 240.832L490.005 178.486ZM490.005 177.924C489.958 177.916 489.91 177.916 489.864 177.924L373.746 207.267L242.985 240.304C242.907 240.324 242.834 240.36 242.771 240.411C242.708 240.461 242.657 240.524 242.62 240.596C242.584 240.668 242.564 240.746 242.561 240.827C242.558 240.908 242.572 240.988 242.603 241.062C249.627 257.951 257.594 274.75 266.28 290.999C288.009 331.627 298.913 352.02 321.041 369.875C328.954 376.388 337.479 382.119 346.498 386.989C362.056 395.265 377.267 399.459 391.702 399.459C396.57 399.465 401.426 398.97 406.194 397.982C406.385 397.943 406.755 397.864 407.272 397.746C424.408 393.895 440.157 383.878 454.082 367.988C465.171 355.333 471.998 342.335 474.564 337.444C488.854 310.235 490.139 286.232 492.262 246.486C493.482 223.791 492.915 201.036 490.566 178.43C490.558 178.349 490.533 178.272 490.492 178.202C490.452 178.132 490.396 178.072 490.33 178.025C490.235 177.962 490.124 177.927 490.01 177.924H490.005Z" fill="black" />
            <path d="M264.944 452.411H211.604C211.449 452.411 211.323 452.537 211.323 452.692V583.111C211.323 583.268 211.449 583.392 211.604 583.392H264.944C265.099 583.392 265.225 583.268 265.225 583.111V452.692C265.225 452.537 265.099 452.411 264.944 452.411Z" fill="black" />
            <path d="M264.943 452.692V583.111H211.603V452.692H264.943ZM264.943 452.131H211.603C211.454 452.131 211.311 452.19 211.207 452.295C211.101 452.401 211.042 452.543 211.042 452.692V583.111C211.042 583.263 211.101 583.403 211.207 583.51C211.311 583.611 211.454 583.673 211.603 583.673H264.943C265.092 583.673 265.235 583.611 265.34 583.51C265.445 583.403 265.505 583.263 265.505 583.111V452.692C265.505 452.543 265.445 452.401 265.34 452.295C265.235 452.19 265.092 452.131 264.943 452.131Z" fill="black" />
            <path d="M107.265 531.607C116.047 531.607 123.166 524.488 123.166 515.707C123.166 506.925 116.047 499.806 107.265 499.806C98.4832 499.806 91.3643 506.925 91.3643 515.707C91.3643 524.488 98.4832 531.607 107.265 531.607Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M107.265 527.823C113.957 527.823 119.382 522.399 119.382 515.706C119.382 509.015 113.957 503.59 107.265 503.59C100.573 503.59 95.1484 509.015 95.1484 515.706C95.1484 522.399 100.573 527.823 107.265 527.823Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M165.394 531.607C174.176 531.607 181.295 524.488 181.295 515.707C181.295 506.925 174.176 499.806 165.394 499.806C156.612 499.806 149.493 506.925 149.493 515.707C149.493 524.488 156.612 531.607 165.394 531.607Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M165.394 527.823C172.086 527.823 177.511 522.399 177.511 515.706C177.511 509.015 172.086 503.59 165.394 503.59C158.702 503.59 153.277 509.015 153.277 515.706C153.277 522.399 158.702 527.823 165.394 527.823Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M495.804 263.571C492.728 263.647 489.684 262.915 486.978 261.449C485.638 260.701 484.41 259.769 483.328 258.681C483.295 258.644 483.271 258.6 483.259 258.552C483.248 258.504 483.248 258.454 483.261 258.406C483.273 258.359 483.298 258.315 483.332 258.281C483.367 258.246 483.41 258.221 483.458 258.209C500.851 253.738 518.737 251.465 536.696 251.444C540.47 251.444 544.288 251.545 548.055 251.747C556.718 252.222 565.343 253.23 573.883 254.762C573.956 254.756 574.029 254.78 574.091 254.828C574.147 254.877 574.18 254.946 574.186 255.02C574.192 255.095 574.169 255.168 574.119 255.225C574.074 255.282 574.001 255.318 573.928 255.323L573.832 255.357C567.6 257.629 561.076 259.018 554.456 259.484L521.374 261.797C521.334 261.798 521.295 261.789 521.259 261.773C521.222 261.757 521.19 261.733 521.164 261.703C521.138 261.674 521.118 261.638 521.107 261.6C521.096 261.562 521.093 261.522 521.099 261.483L522.222 253.42L500.324 255.368L501.29 262.819C501.301 262.89 501.284 262.963 501.242 263.022C501.2 263.081 501.137 263.121 501.065 263.133C500.056 263.324 499.036 263.454 498.011 263.521C497.393 263.532 496.647 263.571 495.804 263.571Z" fill="black" />
            <path d="M536.696 251.724C540.39 251.724 544.171 251.825 548.037 252.028C556.701 252.497 565.325 253.504 573.865 255.043L573.77 255.076C567.554 257.335 561.056 258.714 554.461 259.175L521.395 261.488L522.558 253.066L500.048 255.071C500.385 257.653 500.72 260.235 501.053 262.813C500.055 263.003 499.047 263.131 498.033 263.195C497.426 263.234 496.679 263.274 495.826 263.274C492.797 263.337 489.804 262.617 487.134 261.185C485.819 260.45 484.613 259.533 483.552 258.462C500.923 254.002 518.784 251.739 536.718 251.724M536.718 251.163C518.736 251.186 500.828 253.461 483.412 257.934C483.315 257.959 483.227 258.009 483.156 258.079C483.085 258.149 483.033 258.237 483.008 258.333C482.978 258.431 482.977 258.535 483.003 258.634C483.028 258.733 483.081 258.823 483.154 258.894C484.255 259.995 485.502 260.94 486.859 261.702C489.614 263.191 492.712 263.935 495.843 263.858C496.691 263.858 497.443 263.818 498.089 263.779C499.127 263.713 500.161 263.582 501.182 263.386C501.322 263.359 501.447 263.279 501.53 263.163C501.614 263.048 501.65 262.904 501.632 262.763L501.132 258.894L500.705 255.598L512.395 254.56L521.94 253.706L521.457 257.216L520.896 261.421C520.885 261.501 520.892 261.582 520.915 261.658C520.939 261.735 520.979 261.806 521.032 261.866C521.085 261.926 521.151 261.975 521.224 262.007C521.297 262.04 521.377 262.056 521.457 262.055H521.497L544.691 260.433L554.562 259.742C561.209 259.276 567.756 257.885 574.022 255.61L574.118 255.57C574.23 255.529 574.331 255.453 574.399 255.351C574.466 255.25 574.494 255.13 574.488 255.009C574.477 254.886 574.427 254.77 574.342 254.678C574.258 254.587 574.151 254.526 574.028 254.504C565.471 252.969 556.833 251.96 548.155 251.483C544.382 251.281 540.559 251.18 536.78 251.18L536.718 251.163Z" fill="black" />
            <path d="M139.314 499.974H129.931L125.535 537.003H136.871L139.314 499.974Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M136.871 537.576C136.834 537.577 136.796 537.57 136.761 537.555C136.726 537.541 136.695 537.519 136.669 537.491C136.642 537.464 136.62 537.43 136.607 537.393C136.593 537.357 136.588 537.317 136.591 537.278L138.836 499.659C138.839 499.616 138.852 499.572 138.874 499.534C138.897 499.496 138.928 499.464 138.966 499.44C139.01 499.411 139.063 499.396 139.117 499.396C139.15 499.388 139.185 499.388 139.218 499.396L146.545 502.326C146.598 502.347 146.643 502.382 146.675 502.428C146.707 502.474 146.725 502.529 146.725 502.585V536.975C146.723 537.048 146.694 537.118 146.643 537.17C146.592 537.223 146.523 537.253 146.45 537.256L136.871 537.576Z" fill="black" />
            <path d="M139.118 499.682L146.445 502.602V536.992L136.872 537.284C137.621 524.748 138.369 512.209 139.118 499.666M139.118 499.104C139.01 499.104 138.905 499.136 138.815 499.193C138.74 499.241 138.678 499.306 138.633 499.382C138.588 499.458 138.562 499.543 138.557 499.632L137.574 516.077L136.311 537.261C136.306 537.34 136.317 537.418 136.344 537.491C136.371 537.565 136.413 537.632 136.468 537.688C136.52 537.742 136.584 537.785 136.653 537.814C136.722 537.842 136.797 537.857 136.872 537.856L141.656 537.711L146.445 537.565C146.594 537.565 146.737 537.506 146.842 537.401C146.947 537.295 147.007 537.152 147.007 537.004V502.602C147.007 502.489 146.973 502.379 146.91 502.287C146.847 502.193 146.758 502.121 146.653 502.079L142.582 500.451L139.326 499.148C139.259 499.125 139.189 499.111 139.118 499.11V499.104Z" fill="black" />
            <path d="M136.933 566.75C142.831 566.75 147.612 561.969 147.612 556.071C147.612 550.173 142.831 545.391 136.933 545.391C131.035 545.391 126.254 550.173 126.254 556.071C126.254 561.969 131.035 566.75 136.933 566.75Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M136.933 559.552C138.856 559.552 140.414 557.993 140.414 556.071C140.414 554.148 138.856 552.589 136.933 552.589C135.011 552.589 133.452 554.148 133.452 556.071C133.452 557.993 135.011 559.552 136.933 559.552Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M213.592 432.586H128.158C126.731 432.586 125.575 433.741 125.575 435.166C125.575 436.591 126.731 437.746 128.158 437.746H213.592C215.018 437.746 216.174 436.591 216.174 435.166C216.174 433.741 215.018 432.586 213.592 432.586Z" fill="white" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M127.854 433.046C127.779 433.046 127.708 433.017 127.655 432.964C127.603 432.911 127.573 432.84 127.573 432.765C127.587 423.849 131.135 415.3 137.441 408.995C143.746 402.69 152.294 399.141 161.211 399.128H180.514C189.431 399.143 197.977 402.692 204.282 408.997C210.586 415.302 214.133 423.849 214.147 432.765C214.147 432.84 214.117 432.911 214.065 432.964C214.012 433.017 213.941 433.046 213.866 433.046H127.854Z" fill="black" />
            <path d="M180.526 399.408C184.906 399.408 189.244 400.271 193.291 401.948C197.337 403.624 201.014 406.081 204.111 409.179C207.208 412.277 209.665 415.954 211.34 420.001C213.016 424.048 213.878 428.385 213.878 432.765H127.854C127.854 423.919 131.369 415.435 137.625 409.179C143.88 402.923 152.364 399.408 161.212 399.408H180.515M180.515 398.847H161.223C152.228 398.855 143.604 402.43 137.243 408.789C130.882 415.148 127.304 423.771 127.293 432.765C127.293 432.915 127.352 433.057 127.457 433.163C127.562 433.268 127.706 433.327 127.854 433.327H213.878C213.952 433.327 214.025 433.312 214.094 433.283C214.162 433.254 214.224 433.212 214.276 433.159C214.38 433.054 214.439 432.913 214.439 432.765C214.43 423.773 210.855 415.153 204.497 408.793C198.139 402.435 189.518 398.858 180.526 398.847H180.515Z" fill="black" />
            <path d="M243.125 240.849C248.145 252.92 255.893 270.388 266.78 290.735C288.498 331.347 299.345 351.655 321.394 369.437C330.153 376.506 366.351 405.714 406.076 397.432C406.266 397.393 406.637 397.314 407.148 397.202C447.293 388.168 468.73 347.349 474.07 337.18C488.32 310.078 489.561 286.519 491.689 246.441C492.899 223.785 492.325 201.069 489.971 178.503L243.125 240.849Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M491.273 178.918C488.69 205.439 486.109 231.959 483.53 258.479C513.25 252.447 543.752 251.287 573.843 255.043C567.611 242.189 560.419 229.824 552.328 218.053C537.454 196.436 527.325 182.119 510.183 178.486C503.926 177.219 497.465 177.367 491.273 178.918Z" fill="white" />
            <path d="M371.15 227.797C439.727 212.116 493.476 191.337 491.2 181.385C488.925 171.434 431.487 176.079 362.909 191.76C294.332 207.442 240.583 228.221 242.859 238.172C245.134 248.124 302.572 243.479 371.15 227.797Z" fill="white" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M491.273 178.918C488.69 205.439 486.109 231.959 483.53 258.479C513.25 252.447 543.752 251.287 573.843 255.043C567.611 242.189 560.419 229.824 552.328 218.053C537.454 196.436 527.325 182.119 510.183 178.486C503.926 177.219 497.465 177.367 491.273 178.918Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M463.317 357.921L495.619 365.95" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M474.592 337.849L497.146 343.587" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M324.768 223.875C319.092 223.875 316.032 223.078 315.673 221.506C315.049 218.772 322.904 215.111 329.608 212.522C338.951 208.912 351.618 205.223 365.279 202.079C382.892 198.048 399.343 195.644 409.292 195.644C414.969 195.644 418.035 196.447 418.394 198.02C418.955 200.602 411.926 204.022 405.896 206.442C396.592 210.175 383.066 214.19 368.793 217.452C354.392 220.748 340.366 223.016 330.305 223.679C328.3 223.808 326.414 223.875 324.768 223.875Z" fill="black" />
            <path d="M409.292 195.92C414.57 195.92 417.788 196.616 418.119 198.081C418.562 200.013 413.857 202.944 405.766 206.178C396.783 209.788 383.588 213.774 368.709 217.177C353.684 220.613 339.956 222.758 330.265 223.398C328.238 223.527 326.391 223.595 324.746 223.595C319.474 223.595 316.256 222.898 315.925 221.444C314.988 217.351 337.104 208.8 365.335 202.354C383.493 198.199 399.753 195.925 409.287 195.925M409.287 195.364C399.315 195.364 382.841 197.772 365.211 201.804C351.539 204.931 338.856 208.643 329.502 212.258C319.603 216.088 314.853 219.221 315.392 221.573C315.79 223.314 318.856 224.162 324.763 224.162C326.414 224.162 328.283 224.094 330.316 223.96C340.394 223.297 354.459 221.023 368.85 217.727C383.139 214.459 396.676 210.428 405.997 206.7C409.279 205.463 412.442 203.93 415.446 202.118C417.889 200.535 418.939 199.176 418.658 197.958C418.265 196.212 415.199 195.364 409.287 195.364Z" fill="black" />
            <path d="M516.455 371.874C521.143 376.852 525.129 382.447 528.302 388.505C530.088 391.924 534.147 400.346 537.095 420.806C538.946 433.654 539.722 446.633 539.414 459.609" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M517.791 351.038C524.899 354.968 536.32 362.497 545.123 375.888C553.546 388.69 555.623 400.756 558.312 416.41C560.68 430.292 561.449 444.401 560.603 458.459" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M569.733 459.61H533.686C530.653 459.608 527.73 460.748 525.499 462.802C523.268 464.857 521.891 467.676 521.643 470.699L517.027 527.082L510.29 588.075C510.199 588.889 510.281 589.714 510.531 590.5C510.781 591.281 511.193 591.999 511.741 592.611C512.288 593.223 512.958 593.712 513.708 594.049C514.458 594.38 515.269 594.554 516.09 594.554H583.803C584.601 594.554 585.392 594.391 586.128 594.071C586.858 593.751 587.52 593.285 588.065 592.701C588.61 592.117 589.031 591.432 589.3 590.68C589.57 589.922 589.676 589.125 589.626 588.327L581.81 470.89C581.608 467.827 580.244 464.956 578.003 462.861C575.757 460.766 572.804 459.603 569.733 459.61Z" fill="white" />
            <path d="M569.733 459.61H533.686C530.653 459.608 527.73 460.748 525.499 462.802C523.268 464.857 521.891 467.676 521.643 470.699L517.027 527.082L510.29 588.075C510.199 588.889 510.281 589.714 510.531 590.5C510.781 591.281 511.193 591.999 511.741 592.611C512.288 593.223 512.958 593.712 513.708 594.049C514.458 594.38 515.269 594.554 516.09 594.554H583.803C584.601 594.554 585.392 594.391 586.128 594.071C586.858 593.751 587.52 593.285 588.065 592.701C588.61 592.117 589.031 591.432 589.3 590.68C589.57 589.922 589.676 589.125 589.626 588.327L581.81 470.89C581.608 467.827 580.244 464.956 578.003 462.861C575.757 460.766 572.804 459.603 569.733 459.61Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M553.973 584.824L552.513 555.627C552.447 554.315 552.65 553.004 553.107 551.773C553.564 550.542 554.266 549.416 555.171 548.464C556.076 547.513 557.165 546.755 558.373 546.238C559.58 545.72 560.88 545.453 562.193 545.453H585.724C586.432 545.451 587.133 545.585 587.79 545.846L604.932 552.584C607.953 553.777 610.597 555.752 612.602 558.307C614.606 560.862 615.892 563.903 616.33 567.12L618.761 584.986C618.946 586.34 618.834 587.721 618.441 589.029C618.043 590.332 617.369 591.539 616.459 592.566C615.555 593.588 614.438 594.402 613.186 594.953C611.939 595.503 610.581 595.784 609.216 595.761L564.776 595.245C561.991 595.205 559.32 594.105 557.313 592.173C555.307 590.236 554.113 587.608 553.973 584.824Z" fill="white" />
            <path d="M405.783 206.178C396.799 209.788 383.605 213.774 368.726 217.177C353.701 220.613 339.973 222.758 330.281 223.398C330.058 223.25 329.895 223.027 329.821 222.769C329.125 219.72 345.598 213.348 366.626 208.541C387.653 203.735 405.261 202.304 405.957 205.358C406.007 205.644 405.944 205.937 405.783 206.178Z" fill="white" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M539.772 460.373L536.903 594.183" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M553.973 584.824L552.513 555.627C552.447 554.315 552.65 553.004 553.107 551.773C553.564 550.542 554.266 549.416 555.171 548.464C556.076 547.513 557.165 546.755 558.373 546.238C559.58 545.72 560.88 545.453 562.193 545.453H585.724C586.432 545.451 587.133 545.585 587.79 545.846L604.932 552.584C607.953 553.777 610.597 555.752 612.602 558.307C614.606 560.862 615.892 563.903 616.33 567.12L618.761 584.986C618.946 586.34 618.834 587.721 618.441 589.029C618.043 590.332 617.369 591.539 616.459 592.566C615.555 593.588 614.438 594.402 613.186 594.953C611.939 595.503 610.581 595.784 609.216 595.761L564.776 595.245C561.991 595.205 559.32 594.105 557.313 592.173C555.307 590.236 554.113 587.608 553.973 584.824Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M318.243 363.845C311.185 368.059 305.172 373.818 300.658 380.689C296.391 387.303 293.612 395.057 290.552 427.707C289.827 435.393 288.957 445.831 288.261 458.29" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M332.579 379.712C328.386 382.584 324.702 386.136 321.681 390.223C317.324 396.163 314.382 403.294 312.136 421.57C310.569 433.746 309.931 446.023 310.227 458.296" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M267.492 595.508H331.702C332.773 595.508 333.832 595.278 334.807 594.835C335.781 594.385 336.648 593.734 337.349 592.926C338.05 592.111 338.567 591.163 338.867 590.135C339.166 589.102 339.241 588.024 339.086 586.963L322.067 471.064C321.569 467.667 319.865 464.563 317.267 462.319C314.669 460.075 311.351 458.84 307.918 458.84H281.344C279.91 458.84 278.485 459.056 277.116 459.48" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M429.544 177.52L430.656 107.359H406.692" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M391.05 157.391C390.249 156.679 389.203 156.306 388.131 156.349C387.061 156.392 386.048 156.848 385.306 157.622C377.907 165.325 372.462 174.688 369.424 184.927C366.386 195.166 365.844 205.984 367.844 216.475L372.718 215.914C371.71 205.831 373.087 195.653 376.738 186.201C379.982 177.689 384.929 169.928 391.274 163.393C391.666 162.991 391.972 162.514 392.173 161.989C392.375 161.465 392.468 160.906 392.447 160.345C392.427 159.783 392.292 159.232 392.052 158.725C391.811 158.217 391.47 157.764 391.05 157.391Z" fill="white" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M407.754 85.5734L424.486 85.3656C428 85.3173 431.489 85.9647 434.752 87.2696C438.016 88.5744 440.989 90.5121 443.501 92.9702C446.013 95.4283 448.014 98.3592 449.389 101.593C450.765 104.828 451.487 108.302 451.515 111.817L450.19 176.891" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M283.837 289.876C296.962 290.493 310.105 289.144 322.831 285.873C326.677 284.874 330.304 283.745 333.701 282.538C337.733 281.291 348.811 277.283 356.929 266.098C359.108 263.1 365.469 254.071 365.262 241.433C365.197 237.833 364.619 234.262 363.544 230.826" stroke="white" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M419.735 222.213L420.6 224.128C422.679 228.73 426.169 232.55 430.564 235.036C434.959 237.523 440.031 238.545 445.047 237.957C450.966 237.258 456.407 234.362 460.293 229.842C464.179 225.322 466.226 219.508 466.029 213.55L465.608 200.995" stroke="white" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M413.442 316.619C410.559 319.735 408.547 323.554 407.609 327.694C406.67 331.834 406.839 336.147 408.097 340.201L409.692 345.355C410.975 349.501 413.351 353.225 416.572 356.134C419.792 359.043 423.738 361.03 427.992 361.886C432.247 362.743 436.653 362.436 440.749 360.999C444.844 359.562 448.476 357.048 451.263 353.721L466.771 335.283C468.853 332.803 470.416 329.929 471.365 326.832C472.314 323.737 472.63 320.481 472.296 317.259L471.661 311.139C471.478 306.289 469.544 301.67 466.218 298.135C462.893 294.6 458.4 292.388 453.571 291.909C450.011 291.42 444.026 291.14 437.799 293.919C436.047 294.699 433.397 296.114 426.906 302.234C422.118 306.739 417.621 311.544 413.442 316.619Z" stroke="white" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M300.49 326.934L324.285 331.807L342.898 333.239L350.658 333.98C351.922 334.102 353.12 334.604 354.093 335.42C355.067 336.237 355.77 337.329 356.11 338.553C356.45 339.777 356.411 341.076 355.998 342.277C355.585 343.478 354.817 344.526 353.796 345.283L345.801 357.674C344.521 359.658 343.723 361.914 343.469 364.262C343.215 366.609 343.513 368.984 344.34 371.196C345.166 373.408 346.497 375.396 348.228 377.002C349.958 378.609 352.04 379.788 354.307 380.448L363.544 383.126" stroke="white" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M159.88 355.524V379.381" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M181.576 358.46V382.194" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M269.137 120.166L248.009 139.514C246.526 140.848 244.675 141.705 242.698 141.972C240.722 142.239 238.71 141.905 236.926 141.013C235.522 140.307 234.304 139.278 233.376 138.01C232.447 136.741 231.832 135.271 231.583 133.719C231.333 132.167 231.456 130.578 231.941 129.082C232.426 127.587 233.258 126.228 234.371 125.118L281.832 77.6566C286.896 72.5938 292.907 68.5776 299.522 65.8376C306.138 63.0982 313.228 61.6877 320.389 61.6883H400.443L399.5 128.941H363.207L318.48 169.485C316.845 170.357 314.965 170.656 313.14 170.333C309.21 169.547 306.346 165.561 306.363 161.591C306.436 159.221 307.403 156.967 309.069 155.28L331.837 131.21C332.1 130.932 332.277 130.583 332.344 130.206C332.411 129.829 332.366 129.441 332.215 129.089C332.064 128.737 331.813 128.437 331.493 128.227C331.174 128.016 330.799 127.903 330.417 127.903H303.516" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M266.273 99.3407L273.32 151.749C273.977 156.617 279.496 159.66 284.134 158.031H284.695C286.352 157.45 287.762 156.324 288.695 154.837C289.628 153.35 290.03 151.59 289.833 149.845L283.095 88.684" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M372.095 81.149C343.51 115.449 318.828 81.149 318.828 81.149L301.675 93.1027L303.326 139.542C303.376 140.937 303 142.315 302.246 143.49C301.493 144.664 300.398 145.581 299.109 146.117C294.999 147.835 289.806 145.235 289.132 140.828" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M406.115 59.4649C406.115 57.598 404.603 56.085 402.738 56.085C400.872 56.085 399.36 57.598 399.36 59.4649V132.737C399.36 134.604 400.872 136.117 402.738 136.117C404.603 136.117 406.115 134.604 406.115 132.737V59.4649Z" fill="white" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M365.262 218.193C365.206 218.193 365.152 218.176 365.106 218.145C365.06 218.115 365.024 218.071 365.003 218.019C363.448 214.392 361.702 210.793 359.821 207.317C353.897 196.386 346.789 186.633 339.26 179.115C329.372 169.244 316.919 161.602 302.259 156.409C300.7 155.863 299.385 154.783 298.547 153.36C297.709 151.937 297.401 150.263 297.679 148.636C297.957 147.007 298.802 145.531 300.064 144.466C301.327 143.402 302.926 142.819 304.578 142.821C305.392 142.828 306.2 142.977 306.964 143.259C322.907 149.015 337.224 158.536 348.698 171.013L348.827 171.153C353.841 176.639 363.167 186.846 368.591 202.865C370.055 207.2 371.145 211.651 371.848 216.172C371.857 216.24 371.842 216.309 371.805 216.367C371.768 216.425 371.711 216.467 371.646 216.486L365.357 218.182L365.262 218.193Z" fill="black" />
            <path d="M304.578 143.102C305.358 143.102 306.134 143.237 306.869 143.501C322.781 149.227 337.07 158.723 348.513 171.176C353.566 176.74 362.898 186.829 368.344 202.932C369.809 207.249 370.894 211.684 371.59 216.189L365.262 217.935C364.105 215.24 362.398 211.512 360.068 207.205C357.884 203.179 350.607 190.075 339.456 178.941C327.216 166.717 312.977 159.929 302.354 156.162C301.496 155.857 300.709 155.381 300.04 154.763C299.371 154.145 298.834 153.398 298.462 152.566C298.09 151.735 297.89 150.837 297.875 149.926C297.86 149.016 298.029 148.111 298.374 147.268C298.883 146.047 299.74 145.004 300.838 144.267C301.936 143.53 303.227 143.133 304.55 143.124M304.55 142.563C302.853 142.598 301.221 143.222 299.934 144.33C298.649 145.437 297.788 146.959 297.503 148.631C297.217 150.304 297.523 152.025 298.368 153.496C299.214 154.969 300.546 156.099 302.135 156.695C316.734 161.872 329.176 169.491 339.035 179.339C346.537 186.835 353.634 196.56 359.546 207.475C361.427 210.945 363.167 214.538 364.717 218.143C364.76 218.244 364.832 218.331 364.924 218.392C365.016 218.453 365.124 218.485 365.234 218.485C365.282 218.494 365.331 218.494 365.38 218.485L368.535 217.626L371.691 216.767C371.823 216.731 371.938 216.646 372.012 216.53C372.087 216.414 372.116 216.275 372.095 216.138C371.4 211.599 370.316 207.129 368.855 202.775C363.415 186.706 354.06 176.47 349.035 170.973L348.906 170.827C337.401 158.318 323.045 148.772 307.06 143.001C306.263 142.715 305.423 142.569 304.578 142.568L304.55 142.563Z" fill="black" />
            <path d="M161.223 399.408H180.526C189.372 399.408 197.854 402.923 204.109 409.177C210.364 415.432 213.878 423.915 213.878 432.76H127.854C127.854 428.379 128.717 424.041 130.395 419.993C132.072 415.945 134.53 412.268 137.629 409.171C140.727 406.074 144.406 403.618 148.454 401.942C152.503 400.268 156.842 399.407 161.223 399.408Z" stroke="black" stroke-width="1.68442" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M312.365 384.58C312.713 384.58 312.983 384.866 313.022 385.361C313.031 385.58 312.951 385.793 312.803 385.953C312.653 386.114 312.447 386.209 312.228 386.217C312.009 386.225 311.796 386.146 311.635 385.997C311.475 385.848 311.38 385.641 311.371 385.422C311.321 384.962 311.72 384.58 312.365 384.58Z" fill="black" />
            <path d="M313.701 398.886C313.589 398.892 313.478 398.876 313.373 398.836C313.269 398.797 313.173 398.736 313.094 398.658C313.014 398.58 312.951 398.486 312.909 398.382C312.868 398.278 312.849 398.167 312.853 398.055C312.859 397.839 312.95 397.634 313.107 397.484C313.264 397.335 313.473 397.253 313.69 397.258C313.916 397.264 314.132 397.357 314.291 397.519C314.45 397.68 314.54 397.896 314.543 398.123C314.541 398.229 314.517 398.334 314.474 398.431C314.429 398.528 314.366 398.615 314.287 398.686C314.208 398.758 314.115 398.813 314.014 398.847C313.914 398.881 313.807 398.895 313.701 398.886Z" fill="black" />
            <path d="M309.772 408.005C309.887 407.998 310.003 408.016 310.112 408.057C310.22 408.098 310.319 408.162 310.401 408.243C310.484 408.325 310.548 408.422 310.59 408.53C310.633 408.638 310.652 408.754 310.648 408.869C310.645 408.976 310.62 409.081 310.576 409.178C310.532 409.275 310.469 409.363 310.391 409.435C310.312 409.507 310.219 409.563 310.119 409.599C310.018 409.635 309.912 409.65 309.806 409.644C309.588 409.649 309.378 409.567 309.22 409.416C309.064 409.266 308.973 409.059 308.969 408.841C308.964 408.624 309.046 408.414 309.197 408.257C309.347 408.1 309.554 408.009 309.772 408.005Z" fill="black" />
            <path d="M318.693 381.987C318.755 381.464 319.002 381.161 319.508 381.144C319.619 381.138 319.731 381.155 319.836 381.194C319.94 381.234 320.036 381.295 320.115 381.375C320.195 381.453 320.256 381.548 320.297 381.652C320.337 381.757 320.355 381.869 320.35 381.981C320.35 382.088 320.33 382.193 320.29 382.292C320.249 382.391 320.19 382.481 320.114 382.556C320.04 382.632 319.95 382.692 319.852 382.733C319.753 382.774 319.648 382.795 319.541 382.795C319.322 382.796 319.112 382.713 318.953 382.562C318.795 382.411 318.702 382.205 318.693 381.987Z" fill="black" />
            <path d="M316.464 392.801C316.456 392.908 316.428 393.013 316.38 393.11C316.333 393.206 316.267 393.293 316.186 393.364C316.104 393.436 316.011 393.49 315.908 393.525C315.806 393.56 315.698 393.574 315.591 393.567C315.483 393.56 315.378 393.532 315.281 393.484C315.184 393.436 315.098 393.37 315.026 393.289C314.955 393.208 314.9 393.113 314.866 393.012C314.831 392.909 314.817 392.801 314.824 392.694C314.818 392.587 314.835 392.48 314.873 392.381C314.911 392.281 314.97 392.19 315.044 392.114C315.119 392.037 315.209 391.978 315.309 391.939C315.408 391.899 315.515 391.881 315.621 391.885C315.739 391.884 315.855 391.907 315.962 391.954C316.07 392 316.167 392.069 316.246 392.155C316.325 392.241 316.386 392.343 316.423 392.455C316.461 392.566 316.474 392.684 316.464 392.801Z" fill="black" />
            <path d="M317.929 370.055C317.531 370.055 317.233 369.78 317.216 369.264C317.203 369.156 317.214 369.047 317.249 368.945C317.283 368.843 317.339 368.75 317.414 368.672C317.488 368.594 317.579 368.534 317.68 368.495C317.781 368.457 317.889 368.441 317.997 368.449C318.108 368.436 318.222 368.447 318.329 368.483C318.436 368.517 318.534 368.576 318.616 368.653C318.699 368.73 318.763 368.824 318.806 368.928C318.848 369.033 318.867 369.145 318.861 369.258C318.872 369.758 318.597 370.038 317.929 370.055Z" fill="black" />
            <path d="M327.648 378.437C327.66 378.555 327.645 378.674 327.607 378.785C327.569 378.897 327.506 378.998 327.425 379.084C327.343 379.169 327.244 379.236 327.135 379.28C327.024 379.323 326.907 379.342 326.789 379.336C326.634 379.327 326.485 379.273 326.36 379.181C326.234 379.09 326.137 378.965 326.081 378.82C326.024 378.676 326.01 378.518 326.04 378.366C326.07 378.213 326.143 378.073 326.25 377.961C326.357 377.849 326.494 377.769 326.645 377.732C326.796 377.696 326.954 377.703 327.101 377.753C327.249 377.803 327.378 377.894 327.475 378.015C327.571 378.137 327.632 378.283 327.648 378.437Z" fill="black" />
            <path d="M314.055 379.19C314.174 379.179 314.294 379.193 314.406 379.232C314.52 379.271 314.623 379.333 314.71 379.415C314.797 379.497 314.866 379.596 314.912 379.707C314.958 379.817 314.979 379.936 314.976 380.055C314.976 380.162 314.954 380.269 314.911 380.367C314.868 380.466 314.805 380.554 314.725 380.626C314.646 380.699 314.552 380.754 314.45 380.788C314.348 380.822 314.24 380.834 314.134 380.824C314.021 380.831 313.909 380.813 313.805 380.771C313.7 380.729 313.606 380.664 313.531 380.581C313.455 380.498 313.398 380.399 313.365 380.292C313.333 380.184 313.324 380.07 313.342 379.959C313.335 379.861 313.348 379.763 313.381 379.67C313.413 379.577 313.465 379.493 313.532 379.42C313.598 379.348 313.679 379.29 313.769 379.251C313.86 379.211 313.957 379.191 314.055 379.19Z" fill="black" />
            <path d="M318.407 387.382C318.383 387.59 318.28 387.781 318.121 387.916C317.962 388.052 317.757 388.122 317.547 388.112C317.338 388.102 317.141 388.013 316.995 387.862C316.849 387.713 316.766 387.513 316.762 387.303C316.762 387.197 316.784 387.092 316.826 386.993C316.867 386.895 316.928 386.806 317.004 386.731C317.081 386.657 317.17 386.598 317.27 386.558C317.369 386.519 317.475 386.499 317.581 386.5C317.694 386.5 317.807 386.523 317.91 386.568C318.015 386.613 318.108 386.679 318.186 386.762C318.263 386.844 318.323 386.942 318.361 387.049C318.399 387.156 318.414 387.269 318.407 387.382Z" fill="black" />
            <path d="M310.231 389.948C310.343 389.94 310.455 389.956 310.561 389.994C310.666 390.033 310.761 390.094 310.841 390.172C310.921 390.251 310.983 390.346 311.023 390.45C311.063 390.555 311.08 390.667 311.073 390.779C311.083 390.885 311.069 390.993 311.032 391.094C310.996 391.195 310.938 391.286 310.863 391.363C310.788 391.439 310.697 391.498 310.597 391.536C310.496 391.574 310.389 391.589 310.282 391.582C310.176 391.597 310.068 391.589 309.965 391.559C309.862 391.528 309.767 391.475 309.687 391.404C309.607 391.333 309.543 391.245 309.5 391.147C309.457 391.049 309.437 390.942 309.439 390.835C309.427 390.724 309.438 390.61 309.472 390.503C309.506 390.396 309.562 390.298 309.637 390.214C309.712 390.131 309.803 390.063 309.906 390.017C310.008 389.972 310.119 389.948 310.231 389.948Z" fill="black" />
            <path d="M311.759 404.277C311.647 404.282 311.536 404.263 311.432 404.222C311.328 404.181 311.234 404.118 311.156 404.038C311.078 403.958 311.018 403.862 310.979 403.757C310.941 403.652 310.925 403.54 310.934 403.429C310.927 403.323 310.943 403.216 310.981 403.116C311.019 403.017 311.079 402.926 311.155 402.852C311.231 402.777 311.322 402.72 311.423 402.684C311.523 402.648 311.63 402.634 311.737 402.643C311.842 402.63 311.949 402.641 312.05 402.675C312.151 402.708 312.244 402.763 312.321 402.835C312.399 402.908 312.459 402.997 312.499 403.096C312.539 403.194 312.557 403.3 312.551 403.406C312.563 403.517 312.553 403.629 312.518 403.735C312.484 403.841 312.428 403.939 312.353 404.022C312.277 404.104 312.186 404.169 312.084 404.214C311.981 404.257 311.87 404.279 311.759 404.277Z" fill="black" />
            <path d="M316.145 373.8C316.245 373.8 316.345 373.821 316.438 373.86C316.53 373.9 316.614 373.958 316.683 374.031C316.752 374.104 316.806 374.19 316.841 374.285C316.876 374.379 316.891 374.48 316.886 374.58C316.904 374.691 316.897 374.805 316.865 374.913C316.833 375.02 316.776 375.119 316.699 375.202C316.623 375.284 316.529 375.348 316.424 375.388C316.319 375.429 316.206 375.444 316.094 375.434C315.988 375.443 315.881 375.43 315.78 375.397C315.679 375.362 315.587 375.307 315.508 375.236C315.429 375.164 315.367 375.077 315.323 374.979C315.281 374.882 315.258 374.777 315.258 374.671C315.253 374.553 315.272 374.436 315.315 374.328C315.359 374.219 315.424 374.12 315.507 374.038C315.591 373.956 315.691 373.892 315.801 373.851C315.911 373.81 316.028 373.793 316.145 373.8Z" fill="black" />
            <path d="M322.281 376.551C322.281 376.769 322.195 376.977 322.041 377.131C321.887 377.285 321.679 377.371 321.461 377.371C321.243 377.371 321.035 377.285 320.881 377.131C320.727 376.977 320.642 376.769 320.642 376.551C320.632 376.444 320.646 376.337 320.683 376.236C320.72 376.135 320.778 376.044 320.854 375.968C320.93 375.892 321.022 375.835 321.123 375.799C321.224 375.763 321.332 375.749 321.439 375.759C321.548 375.745 321.66 375.755 321.765 375.789C321.871 375.823 321.967 375.88 322.048 375.956C322.128 376.032 322.191 376.124 322.231 376.227C322.272 376.33 322.288 376.441 322.281 376.551Z" fill="black" />
            <path d="M324.932 384.721C324.767 384.73 324.604 384.689 324.463 384.604C324.321 384.518 324.209 384.392 324.141 384.242C324.072 384.092 324.051 383.924 324.08 383.762C324.108 383.6 324.184 383.449 324.299 383.331C324.414 383.213 324.562 383.132 324.724 383.099C324.885 383.066 325.053 383.083 325.206 383.147C325.357 383.211 325.486 383.319 325.576 383.458C325.665 383.596 325.711 383.759 325.706 383.924C325.712 384.028 325.696 384.132 325.659 384.23C325.623 384.327 325.566 384.417 325.493 384.491C325.421 384.566 325.333 384.625 325.236 384.665C325.14 384.704 325.036 384.723 324.932 384.721Z" fill="black" />
            <path d="M322.645 370.925L323.645 371.717C323.707 371.768 323.819 371.812 323.752 371.913C323.698 371.979 323.622 372.023 323.538 372.037C323.01 372.15 322.623 371.717 322.645 370.925Z" fill="black" />
            <path d="M453.57 571.056C453.563 571.157 453.536 571.258 453.49 571.348C453.444 571.438 453.38 571.517 453.303 571.578C453.225 571.646 453.135 571.691 453.038 571.719C452.941 571.752 452.839 571.758 452.739 571.747C452.628 571.747 452.519 571.719 452.417 571.674C452.316 571.629 452.225 571.561 452.15 571.483C452.075 571.399 452.017 571.303 451.98 571.197C451.944 571.095 451.929 570.983 451.937 570.871C451.943 570.759 451.973 570.652 452.024 570.551C452.075 570.45 452.145 570.366 452.232 570.293C452.318 570.225 452.419 570.169 452.526 570.141C452.634 570.113 452.747 570.102 452.857 570.119C452.969 570.13 453.076 570.158 453.174 570.214C453.272 570.264 453.358 570.337 453.425 570.427C453.492 570.517 453.54 570.618 453.565 570.725C453.591 570.837 453.592 570.949 453.57 571.056Z" fill="black" />
            <path d="M480.751 575.262C480.754 575.149 480.779 575.043 480.825 574.942C480.871 574.841 480.937 574.751 481.018 574.672C481.1 574.599 481.196 574.537 481.3 574.504C481.405 574.464 481.516 574.448 481.627 574.453C481.734 574.453 481.841 574.476 481.939 574.515C482.037 574.56 482.126 574.622 482.198 574.7C482.271 574.779 482.326 574.874 482.36 574.975C482.394 575.076 482.407 575.183 482.396 575.29C482.405 575.397 482.39 575.503 482.353 575.604C482.316 575.705 482.257 575.795 482.181 575.874C482.106 575.947 482.015 576.009 481.914 576.042C481.813 576.082 481.706 576.098 481.599 576.087C481.488 576.093 481.377 576.076 481.272 576.037C481.169 575.997 481.074 575.941 480.994 575.863C480.914 575.784 480.851 575.688 480.809 575.587C480.768 575.486 480.748 575.374 480.751 575.262Z" fill="black" />
            <path d="M476.697 554.437C476.8 554.432 476.904 554.447 477.001 554.484C477.098 554.52 477.186 554.576 477.261 554.648C477.335 554.721 477.393 554.808 477.433 554.904C477.472 554.999 477.491 555.103 477.488 555.206C477.497 555.314 477.484 555.423 477.449 555.525C477.416 555.628 477.361 555.723 477.291 555.804C477.219 555.886 477.133 555.953 477.036 556C476.939 556.048 476.833 556.076 476.725 556.082C476.613 556.086 476.501 556.067 476.397 556.027C476.292 555.987 476.196 555.927 476.115 555.849C476.034 555.772 475.969 555.679 475.925 555.576C475.88 555.474 475.856 555.363 475.854 555.251C475.849 555.14 475.868 555.03 475.909 554.927C475.95 554.824 476.012 554.731 476.092 554.654C476.172 554.576 476.266 554.517 476.371 554.48C476.475 554.443 476.586 554.428 476.697 554.437Z" fill="black" />
            <path d="M446.254 574.363C446.259 574.47 446.243 574.571 446.206 574.672C446.17 574.768 446.115 574.863 446.043 574.936C445.971 575.015 445.885 575.077 445.789 575.116C445.693 575.161 445.589 575.183 445.484 575.183C445.373 575.194 445.261 575.183 445.155 575.15C445.049 575.116 444.951 575.06 444.869 574.981C444.786 574.908 444.721 574.813 444.677 574.712C444.633 574.61 444.611 574.498 444.613 574.386C444.612 574.274 444.631 574.167 444.672 574.06C444.713 573.959 444.773 573.864 444.851 573.785C444.928 573.707 445.021 573.645 445.122 573.6C445.224 573.555 445.334 573.532 445.445 573.532C445.553 573.532 445.66 573.555 445.759 573.594C445.859 573.639 445.949 573.701 446.024 573.78C446.1 573.853 446.159 573.948 446.198 574.049C446.238 574.15 446.256 574.257 446.254 574.363Z" fill="black" />
            <path d="M488.543 553.757C488.542 553.647 488.563 553.537 488.604 553.433C488.646 553.331 488.708 553.237 488.787 553.159C488.865 553.08 488.958 553.018 489.062 552.976C489.164 552.935 489.274 552.914 489.386 552.915C489.492 552.915 489.598 552.935 489.696 552.977C489.794 553.018 489.883 553.078 489.957 553.155C490.031 553.232 490.089 553.323 490.127 553.423C490.164 553.522 490.182 553.629 490.177 553.735C490.187 553.842 490.175 553.949 490.141 554.05C490.107 554.151 490.052 554.245 489.979 554.323C489.906 554.402 489.818 554.464 489.72 554.506C489.621 554.548 489.515 554.568 489.408 554.566C489.296 554.578 489.184 554.565 489.077 554.529C488.971 554.493 488.874 554.435 488.792 554.358C488.71 554.282 488.646 554.189 488.602 554.085C488.56 553.981 488.54 553.87 488.543 553.757Z" fill="black" />
            <path d="M474.283 577.884C474.394 577.884 474.505 577.906 474.607 577.951C474.709 577.99 474.801 578.058 474.876 578.142C474.951 578.221 475.009 578.322 475.043 578.428C475.079 578.529 475.091 578.642 475.08 578.754C475.084 578.861 475.065 578.967 475.024 579.068C474.984 579.164 474.923 579.254 474.845 579.332C474.768 579.405 474.676 579.461 474.575 579.495C474.475 579.534 474.367 579.546 474.26 579.54C474.037 579.54 473.823 579.45 473.665 579.293C473.507 579.136 473.418 578.923 473.418 578.698C473.418 578.473 473.507 578.26 473.665 578.103C473.823 577.946 474.037 577.856 474.26 577.856L474.283 577.884Z" fill="black" />
            <path d="M487.393 559.956C487.281 559.954 487.171 559.93 487.069 559.883C486.967 559.836 486.876 559.77 486.801 559.686C486.726 559.603 486.67 559.505 486.634 559.399C486.599 559.293 486.586 559.181 486.596 559.069C486.597 558.962 486.62 558.857 486.664 558.759C486.708 558.662 486.771 558.575 486.85 558.503C486.93 558.432 487.022 558.377 487.123 558.343C487.225 558.309 487.332 558.296 487.438 558.305C487.545 558.302 487.652 558.321 487.751 558.361C487.85 558.4 487.941 558.46 488.017 558.535C488.092 558.611 488.152 558.702 488.191 558.801C488.231 558.9 488.25 559.007 488.247 559.114C488.248 559.226 488.227 559.337 488.185 559.441C488.142 559.544 488.079 559.638 487.999 559.717C487.92 559.795 487.825 559.857 487.721 559.899C487.617 559.94 487.505 559.959 487.393 559.956Z" fill="black" />
            <path d="M484.389 569.866C484.393 569.978 484.374 570.091 484.334 570.192C484.294 570.298 484.232 570.394 484.154 570.472C484.076 570.557 483.983 570.618 483.879 570.663C483.776 570.703 483.665 570.725 483.552 570.725C483.446 570.736 483.338 570.719 483.238 570.68C483.137 570.646 483.046 570.585 482.97 570.512C482.894 570.433 482.836 570.343 482.799 570.242C482.762 570.141 482.747 570.034 482.755 569.928C482.743 569.821 482.753 569.714 482.784 569.608C482.816 569.507 482.869 569.411 482.939 569.333C483.009 569.248 483.095 569.187 483.192 569.136C483.289 569.091 483.395 569.069 483.502 569.063C483.614 569.052 483.726 569.069 483.833 569.102C483.939 569.142 484.036 569.198 484.119 569.271C484.203 569.349 484.269 569.439 484.316 569.54C484.363 569.641 484.387 569.754 484.389 569.866Z" fill="black" />
            <path d="M463.519 574.021C463.625 574.01 463.732 574.021 463.833 574.054C463.934 574.088 464.027 574.144 464.105 574.217C464.183 574.29 464.245 574.375 464.286 574.476C464.327 574.571 464.347 574.678 464.344 574.784C464.344 575.003 464.258 575.211 464.103 575.368C463.948 575.526 463.738 575.61 463.519 575.61C463.3 575.61 463.09 575.526 462.935 575.368C462.78 575.211 462.693 575.003 462.693 574.784C462.696 574.678 462.719 574.577 462.762 574.481C462.805 574.386 462.867 574.296 462.944 574.229C463.021 574.156 463.112 574.099 463.21 574.066C463.309 574.032 463.414 574.015 463.519 574.021Z" fill="black" />
            <path d="M461.527 580.983C461.426 580.983 461.326 580.966 461.233 580.933C461.139 580.893 461.055 580.837 460.983 580.764C460.912 580.697 460.856 580.613 460.818 580.517C460.78 580.427 460.762 580.326 460.763 580.225C460.753 580.113 460.767 580.001 460.805 579.894C460.843 579.787 460.903 579.692 460.981 579.613C461.06 579.529 461.155 579.467 461.259 579.428C461.364 579.388 461.476 579.372 461.589 579.377C461.695 579.372 461.801 579.388 461.901 579.428C462.001 579.467 462.092 579.523 462.168 579.596C462.244 579.675 462.304 579.765 462.343 579.86C462.383 579.961 462.401 580.068 462.397 580.175C462.4 580.287 462.379 580.399 462.335 580.5C462.292 580.607 462.226 580.697 462.144 580.775C462.062 580.854 461.964 580.91 461.858 580.944C461.751 580.983 461.639 580.994 461.527 580.983Z" fill="black" />
            <path d="M474.754 561.444C474.535 561.444 474.325 561.357 474.17 561.203C474.015 561.048 473.929 560.838 473.929 560.619C473.929 560.4 474.015 560.19 474.17 560.035C474.325 559.88 474.535 559.793 474.754 559.793C474.973 559.793 475.182 559.88 475.337 560.035C475.492 560.19 475.579 560.4 475.579 560.619C475.579 560.838 475.492 561.048 475.337 561.203C475.182 561.357 474.973 561.444 474.754 561.444Z" fill="black" />
            <path d="M472.835 565.172C473.039 565.172 473.234 565.256 473.379 565.397C473.524 565.543 473.606 565.739 473.61 565.941C473.622 566.054 473.612 566.166 473.581 566.267C473.549 566.374 473.496 566.475 473.425 566.559C473.355 566.643 473.267 566.716 473.168 566.767C473.07 566.817 472.962 566.845 472.851 566.851C472.741 566.862 472.63 566.845 472.525 566.811C472.42 566.772 472.324 566.716 472.242 566.643C472.16 566.564 472.095 566.475 472.049 566.374C472.003 566.272 471.978 566.166 471.976 566.054C471.967 565.936 471.984 565.823 472.024 565.711C472.064 565.604 472.127 565.503 472.208 565.419C472.289 565.335 472.387 565.273 472.495 565.228C472.603 565.189 472.719 565.166 472.835 565.172Z" fill="black" />
            <path d="M491.975 560.995C491.999 560.788 492.099 560.597 492.257 560.461C492.415 560.325 492.618 560.253 492.827 560.261C493.035 560.269 493.232 560.356 493.38 560.503C493.527 560.65 493.613 560.849 493.62 561.056C493.621 561.17 493.598 561.282 493.553 561.385C493.508 561.489 493.443 561.583 493.361 561.661C493.279 561.739 493.182 561.799 493.076 561.838C492.97 561.877 492.857 561.894 492.744 561.888C492.634 561.883 492.526 561.855 492.426 561.808C492.327 561.759 492.239 561.691 492.167 561.608C492.095 561.524 492.041 561.426 492.008 561.321C491.974 561.216 491.964 561.105 491.975 560.995Z" fill="black" />
            <path d="M466.309 569.417C466.312 569.535 466.292 569.653 466.247 569.759C466.203 569.872 466.136 569.967 466.052 570.051C465.967 570.13 465.867 570.197 465.757 570.237C465.646 570.276 465.529 570.293 465.411 570.287C465.3 570.287 465.191 570.259 465.09 570.214C464.989 570.169 464.899 570.102 464.824 570.023C464.749 569.939 464.692 569.844 464.655 569.737C464.619 569.636 464.604 569.523 464.611 569.411C464.619 569.299 464.649 569.192 464.699 569.097C464.75 568.996 464.82 568.906 464.905 568.838C464.99 568.765 465.089 568.715 465.195 568.681C465.302 568.653 465.413 568.642 465.523 568.653C465.729 568.653 465.926 568.732 466.073 568.872C466.22 569.018 466.305 569.215 466.309 569.417Z" fill="black" />
            <path d="M471.06 572.179C470.375 572.179 469.999 571.837 470.027 571.359C470.025 571.253 470.044 571.146 470.084 571.045C470.123 570.95 470.182 570.86 470.258 570.781C470.333 570.702 470.423 570.646 470.523 570.607C470.622 570.562 470.728 570.545 470.835 570.545C470.947 570.54 471.058 570.562 471.161 570.601C471.265 570.641 471.359 570.702 471.438 570.781C471.516 570.865 471.578 570.955 471.618 571.062C471.658 571.163 471.676 571.275 471.672 571.387C471.644 571.882 471.38 572.162 471.06 572.179Z" fill="black" />
            <path d="M490.504 548.412C490.494 548.2 490.568 547.993 490.71 547.834C490.851 547.675 491.049 547.579 491.262 547.564C491.375 547.557 491.487 547.574 491.594 547.611C491.701 547.65 491.798 547.709 491.882 547.785C491.965 547.861 492.032 547.954 492.079 548.057C492.126 548.16 492.152 548.271 492.154 548.384C492.158 548.603 492.075 548.815 491.923 548.972C491.77 549.13 491.562 549.22 491.343 549.223C491.124 549.227 490.913 549.144 490.755 548.991C490.598 548.839 490.507 548.631 490.504 548.412Z" fill="black" />
            <path d="M485.9 547.272C485.799 547.273 485.699 547.252 485.606 547.213C485.513 547.173 485.429 547.115 485.36 547.042C485.29 546.969 485.236 546.883 485.2 546.788C485.164 546.693 485.149 546.593 485.153 546.492C485.141 546.382 485.153 546.269 485.187 546.164C485.223 546.058 485.28 545.962 485.355 545.88C485.431 545.799 485.523 545.734 485.626 545.692C485.729 545.649 485.839 545.629 485.951 545.633C486.057 545.627 486.163 545.642 486.264 545.679C486.364 545.715 486.456 545.772 486.533 545.845C486.611 545.918 486.673 546.006 486.716 546.104C486.758 546.201 486.781 546.307 486.781 546.413C486.787 546.529 486.767 546.645 486.724 546.753C486.681 546.861 486.616 546.959 486.533 547.04C486.45 547.121 486.35 547.184 486.241 547.224C486.132 547.264 486.016 547.281 485.9 547.272Z" fill="black" />
            <path d="M477.022 573.386C477.023 573.493 477.002 573.6 476.959 573.695C476.916 573.791 476.854 573.88 476.776 573.953C476.697 574.026 476.605 574.077 476.505 574.111C476.404 574.144 476.298 574.161 476.192 574.15C476.085 574.161 475.977 574.144 475.876 574.105C475.776 574.071 475.685 574.01 475.61 573.931C475.535 573.858 475.478 573.763 475.442 573.661C475.407 573.56 475.395 573.454 475.405 573.347C475.392 573.235 475.405 573.122 475.441 573.016C475.478 572.909 475.538 572.814 475.617 572.729C475.696 572.651 475.793 572.589 475.899 572.55C476.006 572.51 476.119 572.499 476.231 572.51C476.73 572.51 477.006 572.786 477.022 573.386Z" fill="black" />
            <path d="M480.925 562.556C480.863 563.038 480.644 563.37 480.128 563.392C480.015 563.398 479.902 563.375 479.797 563.336C479.692 563.291 479.597 563.229 479.518 563.151C479.438 563.072 479.376 562.977 479.335 562.87C479.294 562.763 479.275 562.651 479.28 562.539C479.347 562.084 479.578 561.741 480.117 561.758C480.656 561.775 480.863 562.084 480.925 562.556Z" fill="black" />
            <path d="M450.992 575.509C451.183 575.531 451.357 575.627 451.477 575.778C451.598 575.924 451.655 576.115 451.638 576.306C451.643 576.418 451.624 576.531 451.583 576.637C451.541 576.738 451.479 576.834 451.398 576.912C451.317 576.991 451.221 577.053 451.115 577.092C451.01 577.131 450.897 577.143 450.785 577.137C450.678 577.143 450.571 577.12 450.472 577.081C450.372 577.041 450.283 576.98 450.208 576.907C450.133 576.828 450.074 576.738 450.037 576.637C449.998 576.536 449.982 576.429 449.987 576.323C449.97 575.84 450.347 575.492 450.992 575.509Z" fill="black" />
            <path d="M493.288 542.192C493.493 542.191 493.69 542.272 493.836 542.416C493.982 542.56 494.065 542.755 494.068 542.961C494.08 543.071 494.067 543.183 494.032 543.289C493.998 543.394 493.941 543.491 493.867 543.574C493.792 543.656 493.701 543.722 493.599 543.768C493.498 543.813 493.388 543.836 493.277 543.837C493.165 543.84 493.055 543.82 492.952 543.779C492.848 543.738 492.754 543.677 492.675 543.599C492.596 543.521 492.533 543.427 492.491 543.325C492.448 543.221 492.428 543.111 492.429 543C492.43 542.889 492.453 542.781 492.497 542.68C492.541 542.578 492.605 542.487 492.685 542.411C492.766 542.336 492.861 542.278 492.964 542.239C493.068 542.202 493.178 542.185 493.288 542.192Z" fill="black" />
            <path d="M478.994 567.901C479 568.019 478.982 568.131 478.941 568.243C478.9 568.35 478.835 568.451 478.754 568.53C478.671 568.614 478.573 568.676 478.464 568.721C478.355 568.76 478.239 568.777 478.124 568.771C478.017 568.771 477.911 568.749 477.814 568.709C477.715 568.664 477.628 568.603 477.556 568.524C477.484 568.445 477.43 568.35 477.396 568.249C477.362 568.148 477.35 568.041 477.36 567.935C477.372 567.727 477.462 567.536 477.61 567.396C477.759 567.25 477.955 567.171 478.161 567.165C478.367 567.16 478.567 567.233 478.722 567.367C478.876 567.508 478.973 567.699 478.994 567.901Z" fill="black" />
            <path d="M458.146 572.044C458.258 572.044 458.369 572.067 458.472 572.112C458.574 572.157 458.666 572.219 458.743 572.303C458.819 572.387 458.878 572.482 458.915 572.589C458.953 572.69 458.968 572.802 458.961 572.915C458.961 573.021 458.939 573.128 458.897 573.224C458.855 573.325 458.793 573.409 458.716 573.482C458.639 573.555 458.547 573.611 458.447 573.65C458.347 573.684 458.241 573.701 458.135 573.695C458.023 573.695 457.913 573.673 457.81 573.628C457.707 573.583 457.614 573.521 457.537 573.437C457.46 573.358 457.399 573.263 457.359 573.156C457.319 573.055 457.3 572.943 457.304 572.831C457.322 572.617 457.418 572.421 457.574 572.275C457.729 572.129 457.934 572.05 458.146 572.044Z" fill="black" />
            <path d="M485.625 565.307C485.008 565.307 484.614 564.947 484.643 564.487C484.633 564.375 484.647 564.262 484.684 564.156C484.721 564.043 484.78 563.948 484.856 563.864C484.933 563.78 485.026 563.712 485.13 563.667C485.234 563.622 485.346 563.6 485.46 563.6C485.573 563.6 485.685 563.622 485.789 563.667C485.892 563.712 485.986 563.78 486.063 563.864C486.139 563.948 486.198 564.043 486.235 564.156C486.271 564.262 486.285 564.375 486.276 564.487C486.293 564.582 486.289 564.683 486.265 564.779C486.24 564.874 486.196 564.964 486.134 565.043C486.073 565.121 485.996 565.183 485.908 565.228C485.82 565.273 485.724 565.301 485.625 565.307Z" fill="black" />
            <path d="M468.843 577.564C468.637 577.558 468.443 577.474 468.299 577.328C468.154 577.176 468.072 576.985 468.068 576.778C468.064 576.665 468.083 576.553 468.125 576.446C468.166 576.345 468.229 576.25 468.309 576.171C468.39 576.093 468.486 576.031 468.591 575.986C468.696 575.947 468.809 575.93 468.921 575.936C469.029 575.93 469.136 575.947 469.235 575.986C469.335 576.025 469.425 576.087 469.499 576.166C469.573 576.244 469.63 576.34 469.665 576.441C469.7 576.542 469.713 576.649 469.702 576.755C469.706 576.868 469.687 576.98 469.645 577.081C469.603 577.188 469.539 577.277 469.457 577.356C469.375 577.435 469.279 577.491 469.172 577.524C469.066 577.564 468.954 577.575 468.843 577.564Z" fill="black" />
            <path d="M466.922 581.292C467.03 581.286 467.138 581.297 467.241 581.331C467.343 581.37 467.437 581.427 467.518 581.494C467.599 581.567 467.664 581.657 467.71 581.752C467.756 581.853 467.782 581.96 467.786 582.067C467.796 582.179 467.782 582.291 467.745 582.392C467.71 582.499 467.652 582.595 467.576 582.679C467.501 582.757 467.409 582.825 467.307 582.87C467.205 582.909 467.095 582.931 466.984 582.931C466.872 582.943 466.759 582.931 466.653 582.892C466.547 582.858 466.45 582.797 466.37 582.718C466.291 582.639 466.23 582.544 466.192 582.437C466.154 582.331 466.141 582.218 466.153 582.106C466.153 581.589 466.473 581.365 466.922 581.292Z" fill="black" />
            <path d="M457.023 578.238C456.984 578.709 456.765 579.024 456.254 579.046C456.143 579.063 456.029 579.057 455.921 579.029C455.813 578.996 455.713 578.939 455.629 578.866C455.545 578.793 455.479 578.698 455.435 578.597C455.392 578.49 455.372 578.378 455.378 578.266C455.37 578.153 455.386 578.041 455.425 577.934C455.463 577.833 455.524 577.738 455.603 577.654C455.681 577.575 455.776 577.513 455.88 577.474C455.985 577.435 456.097 577.418 456.209 577.423C456.316 577.423 456.422 577.446 456.522 577.485C456.621 577.524 456.711 577.586 456.787 577.659C456.862 577.738 456.922 577.828 456.963 577.923C457.003 578.024 457.024 578.131 457.023 578.238Z" fill="black" />
            <path d="M482.025 558.008C481.913 558.009 481.803 557.986 481.701 557.941C481.599 557.896 481.508 557.83 481.433 557.747C481.358 557.665 481.302 557.567 481.268 557.461C481.233 557.355 481.221 557.243 481.233 557.132C481.23 557.025 481.25 556.919 481.291 556.821C481.333 556.723 481.396 556.634 481.474 556.563C481.553 556.49 481.647 556.436 481.749 556.404C481.85 556.371 481.958 556.361 482.064 556.374C482.175 556.371 482.286 556.392 482.389 556.435C482.492 556.478 482.584 556.543 482.659 556.625C482.735 556.707 482.792 556.805 482.827 556.91C482.862 557.016 482.873 557.128 482.862 557.239C482.858 557.345 482.833 557.449 482.789 557.545C482.745 557.641 482.682 557.727 482.604 557.799C482.526 557.87 482.435 557.926 482.335 557.962C482.236 557.998 482.13 558.013 482.025 558.008Z" fill="black" />
            <path d="M484.058 551.001C484.211 551.015 484.357 551.071 484.479 551.164C484.6 551.258 484.694 551.385 484.746 551.529C484.799 551.673 484.809 551.829 484.777 551.979C484.744 552.13 484.668 552.267 484.56 552.376C484.452 552.485 484.315 552.561 484.165 552.595C484.015 552.629 483.859 552.619 483.714 552.568C483.57 552.515 483.443 552.424 483.348 552.302C483.254 552.181 483.196 552.035 483.182 551.882C483.176 551.766 483.195 551.649 483.237 551.54C483.279 551.431 483.343 551.332 483.426 551.249C483.508 551.166 483.607 551.101 483.716 551.058C483.825 551.016 483.941 550.996 484.058 551.001Z" fill="black" />
            <path d="M479.454 549.911C479.457 550.018 479.439 550.124 479.398 550.223C479.358 550.322 479.298 550.411 479.221 550.485C479.144 550.559 479.053 550.616 478.953 550.653C478.853 550.689 478.746 550.705 478.64 550.697C478.23 550.697 477.825 550.506 477.91 550.04C477.994 549.574 477.977 549.024 478.668 549.069C478.777 549.067 478.884 549.088 478.984 549.131C479.084 549.173 479.175 549.236 479.249 549.315C479.323 549.395 479.379 549.489 479.414 549.592C479.45 549.694 479.463 549.803 479.454 549.911Z" fill="black" />
            <path d="M494.67 556.504C494.466 556.495 494.273 556.406 494.132 556.258C493.992 556.108 493.915 555.911 493.918 555.706C493.908 555.593 493.923 555.48 493.962 555.374C494.002 555.268 494.065 555.172 494.146 555.094C494.227 555.015 494.325 554.956 494.432 554.92C494.54 554.884 494.654 554.872 494.766 554.886C495.131 554.886 495.44 555.016 495.44 555.448C495.434 556.139 495.17 556.504 494.67 556.504Z" fill="black" />
            <path d="M440.134 571.595C440.235 571.595 440.336 571.612 440.429 571.651C440.522 571.691 440.606 571.752 440.675 571.825C440.744 571.898 440.796 571.988 440.829 572.084C440.861 572.179 440.873 572.28 440.864 572.381C440.883 572.55 440.846 572.718 440.758 572.864C440.671 573.005 440.538 573.117 440.381 573.179C439.943 572.965 439.559 572.656 439.258 572.275C439.241 571.966 439.668 571.606 440.134 571.595Z" fill="black" />
            <path d="M490.004 566.362C490.014 566.16 490.103 565.969 490.253 565.835C490.401 565.7 490.599 565.627 490.801 565.633C491.042 565.633 491.363 565.632 491.413 565.997C491.266 566.475 491.003 566.913 490.65 567.266C490.38 567.289 490.004 566.828 490.004 566.362Z" fill="black" />
            <path d="M496.613 549.535C496.637 549.982 496.59 550.429 496.472 550.86C496.472 551.175 496.248 551.074 496.107 550.933C496.002 550.833 495.926 550.705 495.888 550.565C495.851 550.424 495.852 550.276 495.894 550.136C495.929 549.97 496.019 549.82 496.149 549.711C496.28 549.603 496.442 549.54 496.613 549.535Z" fill="black" />
            <path d="M466.944 564.723L468.14 563.796C468.217 563.931 468.244 564.088 468.219 564.24C468.193 564.397 468.116 564.538 468 564.639C467.865 564.779 467.683 564.863 467.488 564.88C467.294 564.897 467.1 564.841 466.944 564.723Z" fill="black" />
            <path d="M416.109 101.054C416.105 101.265 416.017 101.467 415.864 101.614C415.712 101.762 415.507 101.843 415.295 101.84C415.183 101.843 415.071 101.823 414.967 101.781C414.862 101.739 414.767 101.676 414.688 101.596C414.61 101.516 414.548 101.42 414.507 101.315C414.467 101.21 414.448 101.099 414.453 100.986C414.448 100.879 414.466 100.772 414.505 100.672C414.544 100.573 414.603 100.483 414.68 100.407C414.756 100.332 414.847 100.274 414.947 100.236C415.048 100.199 415.155 100.182 415.262 100.189C415.375 100.184 415.489 100.203 415.596 100.245C415.702 100.287 415.798 100.351 415.878 100.432C415.958 100.514 416.02 100.611 416.06 100.718C416.1 100.825 416.117 100.94 416.109 101.054Z" fill="black" />
            <path d="M414.166 106.455C414.161 106.561 414.136 106.665 414.091 106.762C414.046 106.858 413.982 106.944 413.903 107.015C413.824 107.086 413.731 107.141 413.631 107.176C413.53 107.211 413.424 107.225 413.318 107.218C413.21 107.211 413.105 107.183 413.008 107.136C412.912 107.088 412.825 107.022 412.754 106.94C412.683 106.859 412.628 106.765 412.594 106.663C412.559 106.561 412.544 106.453 412.552 106.345C412.558 106.238 412.586 106.132 412.634 106.036C412.682 105.939 412.748 105.852 412.83 105.781C412.91 105.71 413.005 105.656 413.107 105.621C413.209 105.586 413.317 105.572 413.425 105.579C413.531 105.586 413.636 105.614 413.731 105.662C413.827 105.711 413.912 105.777 413.981 105.859C414.05 105.941 414.102 106.036 414.134 106.138C414.166 106.241 414.177 106.348 414.166 106.455Z" fill="black" />
            <path d="M413.779 89.1337C413.665 89.1427 413.55 89.1287 413.441 89.0911C413.332 89.054 413.233 88.9945 413.148 88.9164C413.063 88.839 412.996 88.7441 412.95 88.6391C412.904 88.5335 412.88 88.4201 412.88 88.3056C412.88 88.1905 412.904 88.077 412.95 87.9715C412.996 87.8665 413.063 87.7716 413.148 87.6941C413.233 87.6161 413.332 87.5566 413.441 87.5195C413.55 87.4819 413.665 87.4678 413.779 87.4774C413.886 87.469 413.993 87.4836 414.093 87.5201C414.194 87.5571 414.285 87.6149 414.361 87.6896C414.437 87.7649 414.496 87.8553 414.535 87.9552C414.573 88.0551 414.589 88.1624 414.582 88.2691C414.594 88.3802 414.582 88.4925 414.547 88.5986C414.512 88.7053 414.454 88.8025 414.378 88.8844C414.302 88.9664 414.209 89.031 414.106 89.0742C414.002 89.1169 413.891 89.1371 413.779 89.1337Z" fill="black" />
            <path d="M409.91 98.229C410.021 98.2312 410.131 98.2559 410.233 98.3014C410.334 98.3469 410.426 98.4126 410.501 98.4945C410.577 98.5765 410.635 98.6725 410.672 98.7775C410.71 98.8825 410.726 98.9937 410.718 99.1048C410.714 99.3238 410.623 99.5321 410.465 99.6837C410.307 99.8353 410.095 99.9179 409.876 99.9134C409.658 99.9089 409.449 99.8179 409.298 99.6601C409.146 99.5018 409.063 99.2901 409.068 99.0712C409.067 98.9606 409.088 98.8505 409.13 98.7483C409.173 98.6456 409.234 98.5524 409.313 98.4743C409.391 98.3957 409.484 98.3339 409.587 98.2918C409.689 98.2497 409.799 98.2284 409.91 98.229Z" fill="black" />
            <path d="M418.046 95.562C418.053 95.6794 418.037 95.7967 417.996 95.9068C417.955 96.0174 417.892 96.1173 417.81 96.201C417.727 96.2852 417.628 96.3503 417.519 96.393C417.409 96.4362 417.292 96.4553 417.175 96.4491C416.967 96.425 416.776 96.3234 416.641 96.1633C416.505 96.0039 416.435 95.799 416.445 95.5901C416.455 95.3807 416.544 95.1836 416.695 95.0376C416.845 94.8916 417.044 94.8079 417.254 94.804C417.458 94.8051 417.653 94.8843 417.8 95.0252C417.948 95.1667 418.035 95.3588 418.046 95.562Z" fill="black" />
            <path d="M408.775 104.517C408.773 104.622 408.749 104.725 408.704 104.82C408.659 104.914 408.595 104.998 408.515 105.066C408.435 105.134 408.343 105.184 408.242 105.214C408.142 105.243 408.036 105.251 407.933 105.236C407.827 105.249 407.719 105.236 407.618 105.2C407.518 105.165 407.426 105.106 407.352 105.03C407.277 104.954 407.221 104.861 407.187 104.76C407.153 104.658 407.143 104.551 407.158 104.444C407.147 104.338 407.158 104.23 407.192 104.128C407.225 104.026 407.28 103.932 407.352 103.853C407.423 103.774 407.512 103.711 407.61 103.667C407.708 103.624 407.814 103.602 407.921 103.602C408.04 103.596 408.16 103.615 408.271 103.66C408.381 103.704 408.481 103.773 408.563 103.86C408.644 103.948 408.705 104.051 408.742 104.165C408.779 104.279 408.79 104.399 408.775 104.517Z" fill="black" />
            <path d="M411.841 94.4958C411.737 94.5036 411.632 94.489 411.533 94.4537C411.434 94.4183 411.344 94.3627 411.269 94.2903C411.193 94.2178 411.133 94.1297 411.093 94.0326C411.054 93.9354 411.035 93.831 411.038 93.7266C411.028 93.6159 411.041 93.5048 411.077 93.4003C411.112 93.2953 411.168 93.1988 411.243 93.1168C411.318 93.0348 411.408 92.9697 411.509 92.9248C411.611 92.8798 411.72 92.8563 411.831 92.8563C411.942 92.8529 412.052 92.8725 412.156 92.9135C412.26 92.9551 412.354 93.0168 412.433 93.096C412.512 93.1746 412.574 93.2689 412.615 93.3723C412.656 93.4761 412.676 93.5867 412.673 93.6985C412.673 93.8057 412.651 93.9124 412.609 94.0112C412.567 94.11 412.505 94.1999 412.428 94.274C412.35 94.3487 412.258 94.4065 412.157 94.4447C412.056 94.4828 411.949 94.5003 411.841 94.4958Z" fill="black" />
            <path d="M409.247 86.3482C409.18 86.8198 408.961 87.1455 408.444 87.1736C408.332 87.1831 408.22 87.1691 408.114 87.132C408.008 87.0955 407.911 87.0366 407.83 86.9597C407.748 86.8827 407.684 86.7901 407.641 86.6862C407.597 86.5829 407.577 86.4717 407.579 86.3594C407.573 86.2522 407.591 86.1444 407.629 86.0444C407.669 85.9439 407.729 85.8535 407.806 85.7783C407.883 85.7031 407.976 85.6458 408.077 85.6093C408.178 85.5728 408.286 85.5588 408.393 85.5678C408.501 85.5627 408.608 85.5784 408.71 85.6149C408.811 85.6514 408.903 85.7076 408.983 85.7806C409.062 85.853 409.126 85.9406 409.172 86.0377C409.217 86.1354 409.242 86.241 409.247 86.3482Z" fill="black" />
            <path d="M419.197 89.4475C419.305 89.4459 419.413 89.4666 419.512 89.5087C419.611 89.5509 419.701 89.6132 419.774 89.6923C419.848 89.7709 419.904 89.8647 419.939 89.9663C419.974 90.0685 419.987 90.1769 419.978 90.2841C419.978 90.8175 419.658 91.0421 419.186 91.0758C419.074 91.0814 418.963 91.064 418.858 91.0236C418.753 90.9837 418.658 90.9225 418.578 90.8439C418.499 90.7653 418.436 90.671 418.395 90.5671C418.354 90.4627 418.334 90.3515 418.338 90.2392C418.361 89.7339 418.647 89.4812 419.197 89.4475Z" fill="black" />
            <path d="M406.176 91.7103C406.344 91.4689 405.873 90.9804 406.479 90.9411C406.585 90.9276 406.692 90.9377 406.794 90.9708C406.896 91.004 406.988 91.0596 407.065 91.1337C407.142 91.2072 407.202 91.2976 407.239 91.397C407.277 91.4969 407.292 91.6042 407.282 91.7103C407.298 91.817 407.29 91.9253 407.259 92.0281C407.228 92.1314 407.175 92.2257 407.103 92.306C407.031 92.3858 406.942 92.4486 406.843 92.4908C406.744 92.5323 406.637 92.5514 406.53 92.5469C405.923 92.5301 406.344 91.9854 406.176 91.7103Z" fill="black" />
            <path d="M421.049 84.9614C421.291 85.1635 421.785 84.7873 421.824 85.2253C421.864 85.6632 421.437 85.7306 421.066 85.7137C420.696 85.6969 420.331 85.4948 420.37 85.1523C420.409 84.8098 420.825 85.1242 421.049 84.9614Z" fill="black" />
            <path d="M538.807 517.313H538.667C538.523 517.072 538.447 516.798 538.447 516.518C538.447 516.238 538.523 515.963 538.667 515.724H538.807V517.313Z" fill="black" />
            <path d="M538.555 564.751C538.578 564.706 538.615 564.667 538.66 564.644C538.705 564.616 538.756 564.605 538.808 564.611V566.093L538.667 565.992C538.499 565.599 538.459 565.166 538.555 564.751Z" fill="black" />
            <path d="M538.808 499.121H538.668C538.465 498.704 538.415 498.229 538.527 497.779C538.583 497.644 538.701 497.661 538.808 497.65V499.121Z" fill="black" />
            <path d="M533.95 459.762C533.948 459.72 533.948 459.679 533.95 459.638H535.163V459.767C534.991 459.907 534.777 459.982 534.556 459.982C534.334 459.981 534.12 459.903 533.95 459.762Z" fill="black" />
            <path d="M538.678 534.01H538.807V535.088H538.751H538.689C538.558 534.941 538.484 534.75 538.481 534.552C538.48 534.353 538.549 534.161 538.678 534.01Z" fill="black" />
            <path d="M538.807 467.992L538.701 468.026C538.639 467.976 538.589 467.913 538.554 467.841C538.518 467.769 538.499 467.691 538.496 467.612C538.494 467.532 538.508 467.453 538.539 467.379C538.569 467.305 538.614 467.239 538.673 467.184H538.807V467.992Z" fill="black" />
            <path d="M538.808 480.659C538.758 480.619 538.717 480.568 538.689 480.509C538.661 480.451 538.646 480.387 538.646 480.322C538.646 480.258 538.661 480.194 538.689 480.136C538.717 480.077 538.758 480.026 538.808 479.985V480.659Z" fill="black" />
            <path d="M519.672 515.83C519.672 515.504 519.986 515.268 520.464 515.195C520.576 515.193 520.687 515.214 520.791 515.255C520.895 515.298 520.989 515.36 521.068 515.439C521.147 515.518 521.21 515.613 521.252 515.716C521.293 515.82 521.314 515.931 521.311 516.043C521.316 516.151 521.299 516.258 521.261 516.359C521.223 516.46 521.165 516.551 521.09 516.629C521.016 516.707 520.926 516.769 520.827 516.811C520.729 516.853 520.622 516.875 520.514 516.874C520.065 516.925 519.666 516.498 519.672 515.83Z" fill="black" />
            <path d="M528.645 512.663C528.64 512.772 528.613 512.878 528.565 512.976C528.517 513.074 528.45 513.161 528.367 513.231C528.285 513.302 528.188 513.355 528.084 513.386C527.98 513.418 527.871 513.428 527.763 513.416C527.656 513.418 527.551 513.399 527.453 513.358C527.354 513.318 527.265 513.257 527.192 513.181C527.117 513.104 527.06 513.014 527.023 512.914C526.987 512.814 526.97 512.707 526.977 512.601C526.974 512.49 526.993 512.38 527.035 512.276C527.076 512.173 527.138 512.079 527.217 512.001C527.296 511.923 527.39 511.861 527.494 511.82C527.597 511.78 527.708 511.761 527.819 511.765C527.933 511.767 528.046 511.792 528.149 511.839C528.254 511.886 528.347 511.953 528.424 512.037C528.501 512.121 528.561 512.22 528.599 512.327C528.636 512.435 528.652 512.549 528.645 512.663Z" fill="black" />
            <path d="M526.802 584.857C526.806 585.082 526.722 585.301 526.567 585.464C526.413 585.627 526.202 585.722 525.977 585.733C525.87 585.733 525.764 585.716 525.665 585.677C525.566 585.638 525.476 585.576 525.4 585.497C525.325 585.424 525.266 585.335 525.228 585.234C525.189 585.132 525.171 585.026 525.174 584.919C525.166 584.807 525.18 584.695 525.218 584.588C525.255 584.481 525.313 584.386 525.39 584.301C525.466 584.223 525.559 584.156 525.662 584.111C525.765 584.066 525.876 584.043 525.988 584.043C526.096 584.043 526.202 584.06 526.302 584.105C526.402 584.144 526.492 584.2 526.568 584.279C526.644 584.352 526.704 584.447 526.744 584.543C526.784 584.644 526.804 584.751 526.802 584.857Z" fill="black" />
            <path d="M522.569 578.4C522.457 578.412 522.344 578.4 522.238 578.361C522.131 578.322 522.034 578.266 521.952 578.187C521.871 578.108 521.807 578.013 521.765 577.912C521.724 577.805 521.705 577.693 521.71 577.581C521.708 577.474 521.727 577.367 521.767 577.266C521.806 577.171 521.866 577.081 521.942 577.002C522.017 576.924 522.107 576.868 522.206 576.828C522.305 576.783 522.412 576.766 522.518 576.766C522.625 576.755 522.734 576.761 522.837 576.795C522.939 576.828 523.034 576.879 523.113 576.952C523.194 577.025 523.257 577.115 523.3 577.21C523.343 577.311 523.363 577.418 523.361 577.524C523.375 577.637 523.364 577.749 523.33 577.856C523.296 577.962 523.24 578.058 523.165 578.142C523.09 578.226 522.998 578.294 522.895 578.339C522.792 578.384 522.681 578.4 522.569 578.4Z" fill="black" />
            <path d="M518.639 589.153C518.528 589.153 518.417 589.13 518.315 589.08C518.214 589.035 518.122 588.967 518.048 588.889C517.972 588.804 517.916 588.709 517.88 588.602C517.845 588.496 517.832 588.383 517.842 588.271C517.84 588.164 517.861 588.058 517.903 587.957C517.945 587.861 518.008 587.771 518.087 587.698C518.166 587.625 518.259 587.569 518.362 587.535C518.464 587.502 518.572 587.491 518.679 587.502C518.791 587.507 518.901 587.53 519.002 587.575C519.104 587.62 519.196 587.687 519.272 587.771C519.348 587.855 519.406 587.951 519.445 588.052C519.482 588.159 519.499 588.271 519.493 588.383C519.489 588.49 519.464 588.597 519.419 588.692C519.374 588.793 519.309 588.877 519.23 588.95C519.15 589.023 519.057 589.074 518.955 589.113C518.854 589.147 518.746 589.158 518.639 589.153Z" fill="black" />
            <path d="M527.195 464.557C527.093 464.558 526.991 464.539 526.896 464.501C526.801 464.462 526.715 464.405 526.642 464.333C526.57 464.261 526.512 464.175 526.473 464.081C526.434 463.986 526.414 463.884 526.415 463.782C526.408 463.67 526.424 463.558 526.461 463.453C526.498 463.348 526.557 463.251 526.632 463.169C526.707 463.087 526.799 463.021 526.9 462.975C527.002 462.928 527.112 462.903 527.223 462.9C527.335 462.899 527.445 462.921 527.548 462.964C527.651 463.008 527.744 463.072 527.821 463.152C527.899 463.232 527.959 463.328 527.997 463.432C528.037 463.537 528.054 463.648 528.048 463.759C528.05 463.869 528.028 463.978 527.985 464.079C527.942 464.18 527.878 464.271 527.798 464.346C527.717 464.421 527.622 464.478 527.518 464.515C527.415 464.551 527.305 464.565 527.195 464.557Z" fill="black" />
            <path d="M526.91 549.581C526.802 549.584 526.694 549.566 526.593 549.527C526.492 549.488 526.4 549.429 526.322 549.353C526.244 549.278 526.183 549.188 526.141 549.088C526.099 548.988 526.078 548.88 526.079 548.772C526.074 548.659 526.091 548.547 526.131 548.442C526.17 548.336 526.23 548.24 526.308 548.159C526.386 548.077 526.479 548.012 526.583 547.969C526.686 547.924 526.797 547.901 526.91 547.902C527.022 547.905 527.132 547.929 527.235 547.975C527.337 548.021 527.43 548.086 527.507 548.167C527.584 548.249 527.645 548.344 527.684 548.449C527.725 548.554 527.744 548.665 527.741 548.778C527.732 548.993 527.641 549.196 527.486 549.345C527.332 549.495 527.125 549.579 526.91 549.581Z" fill="black" />
            <path d="M520.413 552.208C520.413 552.431 520.324 552.646 520.166 552.803C520.008 552.961 519.793 553.05 519.571 553.05C519.347 553.05 519.133 552.961 518.975 552.803C518.817 552.646 518.728 552.431 518.728 552.208C518.721 552.1 518.736 551.993 518.773 551.892C518.809 551.79 518.866 551.698 518.939 551.619C519.014 551.541 519.102 551.478 519.201 551.436C519.3 551.393 519.407 551.371 519.514 551.372C519.629 551.363 519.745 551.378 519.854 551.416C519.962 551.455 520.062 551.515 520.146 551.593C520.23 551.672 520.298 551.767 520.344 551.873C520.389 551.979 520.413 552.093 520.413 552.208Z" fill="black" />
            <path d="M524.254 506.099C524.049 506.084 523.858 505.988 523.724 505.832C523.589 505.677 523.521 505.474 523.535 505.269C523.531 505.157 523.55 505.045 523.593 504.941C523.635 504.836 523.698 504.742 523.78 504.665C523.861 504.587 523.958 504.528 524.064 504.491C524.17 504.454 524.282 504.439 524.395 504.449C524.502 504.445 524.608 504.464 524.708 504.505C524.807 504.546 524.896 504.608 524.971 504.686C525.044 504.764 525.101 504.856 525.136 504.958C525.171 505.06 525.185 505.167 525.175 505.274C525.174 505.391 525.15 505.507 525.103 505.613C525.055 505.719 524.985 505.815 524.898 505.892C524.812 505.97 524.709 506.029 524.598 506.065C524.487 506.101 524.37 506.112 524.254 506.099Z" fill="black" />
            <path d="M536.045 473.843C535.94 473.847 535.835 473.831 535.736 473.795C535.638 473.759 535.548 473.703 535.471 473.631C535.394 473.56 535.332 473.473 535.289 473.378C535.246 473.282 535.222 473.179 535.219 473.074C535.208 472.962 535.219 472.849 535.253 472.742C535.287 472.635 535.342 472.536 535.416 472.451C535.489 472.366 535.58 472.298 535.681 472.249C535.783 472.201 535.893 472.174 536.005 472.17C536.232 472.169 536.451 472.257 536.612 472.416C536.775 472.575 536.867 472.791 536.87 473.018C536.872 473.127 536.853 473.235 536.812 473.336C536.771 473.437 536.711 473.53 536.633 473.607C536.556 473.683 536.464 473.744 536.363 473.785C536.262 473.825 536.154 473.845 536.045 473.843Z" fill="black" />
            <path d="M532.069 519.907C532.076 520.019 532.06 520.131 532.02 520.237C531.98 520.342 531.919 520.437 531.839 520.517C531.759 520.596 531.663 520.657 531.557 520.697C531.451 520.735 531.339 520.751 531.227 520.743C531.12 520.75 531.013 520.734 530.912 520.696C530.812 520.657 530.721 520.598 530.646 520.522C530.57 520.446 530.512 520.355 530.475 520.255C530.437 520.154 530.422 520.047 530.43 519.94C530.426 519.828 530.445 519.716 530.486 519.612C530.526 519.507 530.588 519.412 530.667 519.332C530.746 519.253 530.84 519.189 530.944 519.147C531.048 519.105 531.159 519.084 531.272 519.087C531.378 519.087 531.483 519.109 531.581 519.151C531.679 519.193 531.768 519.254 531.842 519.33C531.916 519.406 531.974 519.496 532.013 519.596C532.052 519.694 532.071 519.8 532.069 519.907Z" fill="black" />
            <path d="M535.972 509.261C535.949 509.473 535.845 509.669 535.68 509.806C535.516 509.943 535.305 510.01 535.092 509.993C534.878 509.976 534.68 509.877 534.539 509.716C534.398 509.555 534.326 509.345 534.338 509.131C534.33 509.025 534.346 508.917 534.383 508.816C534.42 508.716 534.479 508.624 534.554 508.548C534.63 508.471 534.72 508.412 534.82 508.373C534.92 508.334 535.028 508.317 535.135 508.323C535.252 508.327 535.368 508.354 535.475 508.404C535.581 508.453 535.677 508.524 535.755 508.612C535.833 508.7 535.893 508.802 535.93 508.914C535.967 509.026 535.981 509.144 535.972 509.261Z" fill="black" />
            <path d="M522.817 491.776C522.933 491.764 523.051 491.777 523.162 491.815C523.273 491.853 523.374 491.914 523.459 491.994C523.544 492.075 523.61 492.173 523.653 492.282C523.697 492.391 523.716 492.507 523.71 492.624C523.713 492.737 523.693 492.849 523.651 492.954C523.61 493.058 523.547 493.153 523.466 493.232C523.387 493.311 523.291 493.373 523.186 493.414C523.081 493.455 522.969 493.473 522.856 493.469C522.744 493.464 522.633 493.436 522.532 493.387C522.43 493.338 522.341 493.268 522.268 493.183C522.194 493.097 522.139 492.998 522.107 492.89C522.073 492.782 522.063 492.669 522.076 492.557C522.07 492.456 522.085 492.356 522.12 492.261C522.155 492.167 522.209 492.081 522.278 492.008C522.347 491.934 522.431 491.876 522.524 491.836C522.616 491.797 522.716 491.776 522.817 491.776Z" fill="black" />
            <path d="M531.361 587.659C531.254 587.665 531.147 587.648 531.046 587.614C530.945 587.575 530.853 587.519 530.775 587.446C530.696 587.373 530.634 587.283 530.591 587.187C530.548 587.086 530.525 586.98 530.525 586.873C530.516 586.761 530.531 586.648 530.569 586.542C530.606 586.441 530.666 586.34 530.743 586.261C530.82 586.177 530.914 586.115 531.018 586.076C531.121 586.031 531.233 586.008 531.345 586.014C531.456 586.014 531.567 586.031 531.671 586.076C531.774 586.115 531.868 586.182 531.946 586.261C532.024 586.34 532.085 586.435 532.124 586.542C532.164 586.643 532.181 586.755 532.176 586.867C532.175 586.974 532.153 587.075 532.112 587.176C532.07 587.272 532.01 587.362 531.934 587.435C531.858 587.507 531.769 587.564 531.671 587.603C531.572 587.642 531.467 587.659 531.361 587.659Z" fill="black" />
            <path d="M530.644 471.895C530.438 471.892 530.241 471.81 530.093 471.665C529.946 471.522 529.86 471.326 529.853 471.12C529.85 470.909 529.928 470.704 530.072 470.548C530.215 470.393 530.414 470.299 530.625 470.285C530.836 470.271 531.044 470.339 531.206 470.475C531.369 470.61 531.473 470.803 531.498 471.013C531.502 471.128 531.484 471.242 531.443 471.349C531.402 471.457 531.339 471.554 531.26 471.637C531.18 471.719 531.084 471.785 530.978 471.829C530.873 471.873 530.759 471.896 530.644 471.895Z" fill="black" />
            <path d="M528.745 477.296C528.638 477.302 528.53 477.285 528.429 477.247C528.328 477.21 528.236 477.152 528.158 477.077C528.08 477.002 528.018 476.913 527.976 476.813C527.935 476.714 527.913 476.607 527.914 476.499C527.906 476.388 527.92 476.276 527.956 476.171C527.992 476.065 528.049 475.968 528.123 475.885C528.198 475.803 528.288 475.735 528.389 475.689C528.491 475.641 528.6 475.616 528.712 475.612C528.818 475.607 528.926 475.623 529.026 475.66C529.126 475.698 529.218 475.755 529.295 475.829C529.373 475.904 529.434 475.993 529.475 476.092C529.517 476.19 529.538 476.297 529.537 476.403C529.547 476.515 529.534 476.627 529.499 476.733C529.464 476.84 529.408 476.937 529.334 477.021C529.26 477.105 529.169 477.172 529.068 477.219C528.967 477.267 528.857 477.293 528.745 477.296Z" fill="black" />
            <path d="M532.316 551.523C532.1 551.53 531.89 551.453 531.731 551.307C531.573 551.16 531.478 550.958 531.468 550.742C531.458 550.631 531.471 550.518 531.507 550.412C531.542 550.305 531.6 550.208 531.675 550.125C531.751 550.042 531.843 549.976 531.945 549.93C532.048 549.885 532.159 549.861 532.271 549.861C532.495 549.855 532.711 549.938 532.873 550.092C533.035 550.245 533.13 550.457 533.136 550.68C533.141 550.904 533.058 551.121 532.905 551.283C532.751 551.445 532.539 551.539 532.316 551.545V551.523Z" fill="black" />
            <path d="M516.051 581.05C516.05 581.152 516.029 581.258 515.988 581.354C515.949 581.444 515.89 581.533 515.817 581.606C515.743 581.679 515.656 581.736 515.56 581.775C515.465 581.814 515.362 581.831 515.259 581.831C515.147 581.837 515.035 581.82 514.931 581.78C514.826 581.741 514.731 581.679 514.651 581.601C514.571 581.522 514.509 581.427 514.469 581.32C514.429 581.219 514.411 581.107 514.417 580.994C514.41 580.888 514.425 580.781 514.461 580.68C514.498 580.579 514.555 580.489 514.629 580.416C514.703 580.337 514.792 580.276 514.891 580.236C514.99 580.197 515.096 580.175 515.203 580.18C515.318 580.175 515.433 580.191 515.54 580.231C515.648 580.276 515.745 580.337 515.826 580.422C515.906 580.506 515.968 580.601 516.006 580.714C516.046 580.82 516.06 580.938 516.051 581.05Z" fill="black" />
            <path d="M532.985 483.669C533 483.786 532.99 483.904 532.956 484.016C532.921 484.129 532.862 484.232 532.784 484.32C532.705 484.407 532.609 484.476 532.501 484.523C532.394 484.57 532.277 484.592 532.159 484.59C532.052 484.6 531.944 484.588 531.842 484.553C531.74 484.518 531.647 484.462 531.569 484.388C531.49 484.314 531.429 484.224 531.388 484.124C531.348 484.024 531.33 483.917 531.334 483.81C531.328 483.697 531.346 483.586 531.386 483.481C531.425 483.376 531.486 483.281 531.564 483.2C531.641 483.12 531.735 483.056 531.838 483.013C531.942 482.97 532.053 482.949 532.165 482.951C532.369 482.938 532.569 483.007 532.723 483.141C532.876 483.276 532.97 483.466 532.985 483.669Z" fill="black" />
            <path d="M525.652 487.263C525.649 487.369 525.626 487.474 525.582 487.571C525.538 487.667 525.475 487.755 525.397 487.828C525.32 487.9 525.228 487.957 525.128 487.994C525.028 488.031 524.922 488.048 524.815 488.043C524.704 488.042 524.593 488.017 524.491 487.972C524.389 487.926 524.298 487.86 524.222 487.777C524.147 487.694 524.089 487.597 524.053 487.491C524.017 487.385 524.003 487.273 524.012 487.162C524.014 487.055 524.037 486.95 524.08 486.853C524.124 486.756 524.186 486.668 524.265 486.596C524.343 486.524 524.435 486.469 524.536 486.434C524.636 486.399 524.743 486.385 524.849 486.392C524.961 486.39 525.072 486.411 525.175 486.455C525.278 486.5 525.371 486.565 525.446 486.647C525.522 486.729 525.579 486.826 525.615 486.933C525.651 487.039 525.663 487.151 525.652 487.263Z" fill="black" />
            <path d="M536.42 490.957C536.42 491.636 536.1 491.878 535.612 491.906C535.454 491.907 535.299 491.863 535.164 491.779C535.031 491.694 534.924 491.573 534.857 491.43C534.79 491.287 534.766 491.127 534.787 490.97C534.808 490.813 534.874 490.666 534.976 490.545C535.079 490.425 535.214 490.336 535.366 490.291C535.517 490.245 535.679 490.243 535.831 490.287C535.983 490.33 536.119 490.416 536.224 490.535C536.329 490.654 536.397 490.8 536.42 490.957Z" fill="black" />
            <path d="M526.253 536.048C526.251 536.16 526.226 536.269 526.181 536.371C526.135 536.472 526.069 536.563 525.987 536.638C525.905 536.713 525.809 536.771 525.704 536.808C525.599 536.844 525.488 536.859 525.377 536.851C525.172 536.835 524.981 536.741 524.842 536.59C524.703 536.439 524.625 536.24 524.625 536.034C524.625 535.829 524.703 535.631 524.842 535.48C524.981 535.328 525.172 535.235 525.377 535.218C525.49 535.21 525.604 535.226 525.711 535.264C525.818 535.302 525.915 535.363 525.997 535.441C526.08 535.519 526.145 535.613 526.189 535.718C526.233 535.823 526.255 535.935 526.253 536.048Z" fill="black" />
            <path d="M529.082 574.156C529.07 574.043 529.081 573.931 529.117 573.824C529.153 573.723 529.211 573.622 529.289 573.544C529.365 573.465 529.459 573.398 529.564 573.358C529.667 573.319 529.779 573.302 529.89 573.308C529.997 573.302 530.103 573.325 530.203 573.358C530.303 573.398 530.394 573.454 530.47 573.527C530.548 573.605 530.609 573.689 530.651 573.791C530.693 573.886 530.715 573.993 530.716 574.099C530.718 574.212 530.698 574.324 530.658 574.425C530.618 574.532 530.557 574.622 530.48 574.706C530.402 574.784 530.31 574.846 530.208 574.891C530.106 574.936 529.997 574.964 529.885 574.964C529.779 574.964 529.674 574.942 529.577 574.902C529.479 574.863 529.39 574.801 529.315 574.728C529.241 574.65 529.182 574.565 529.141 574.464C529.102 574.369 529.081 574.262 529.082 574.156Z" fill="black" />
            <path d="M521.099 564.066C521.204 564.066 521.309 564.083 521.406 564.122C521.504 564.167 521.592 564.223 521.666 564.296C521.74 564.375 521.798 564.465 521.837 564.56C521.875 564.661 521.894 564.762 521.89 564.869C521.895 564.981 521.877 565.093 521.836 565.195C521.795 565.301 521.734 565.397 521.655 565.475C521.575 565.554 521.48 565.616 521.376 565.655C521.272 565.694 521.16 565.711 521.048 565.705C520.937 565.705 520.826 565.683 520.724 565.638C520.621 565.593 520.53 565.531 520.454 565.447C520.378 565.369 520.319 565.268 520.283 565.166C520.246 565.06 520.231 564.948 520.24 564.835C520.242 564.729 520.267 564.622 520.313 564.521C520.358 564.425 520.423 564.335 520.503 564.263C520.584 564.195 520.678 564.139 520.78 564.105C520.883 564.072 520.991 564.055 521.099 564.066Z" fill="black" />
            <path d="M531.143 555.88C531.143 556.543 530.811 556.925 530.334 556.902C530.226 556.908 530.119 556.892 530.018 556.854C529.916 556.817 529.824 556.759 529.747 556.684C529.669 556.609 529.608 556.519 529.567 556.419C529.526 556.319 529.506 556.213 529.509 556.105C529.503 555.993 529.521 555.88 529.561 555.776C529.601 555.671 529.662 555.575 529.74 555.495C529.819 555.415 529.913 555.352 530.018 555.31C530.121 555.268 530.233 555.248 530.345 555.251C530.84 555.268 531.126 555.532 531.143 555.88Z" fill="black" />
            <path d="M537.538 553.443C537.128 553.443 536.876 553.128 536.859 552.634C536.852 552.523 536.869 552.412 536.909 552.308C536.949 552.203 537.012 552.109 537.092 552.031C537.172 551.953 537.268 551.894 537.373 551.857C537.478 551.819 537.59 551.805 537.701 551.815C537.807 551.815 537.913 551.836 538.011 551.877C538.109 551.918 538.198 551.978 538.273 552.054C538.347 552.13 538.406 552.219 538.446 552.318C538.485 552.417 538.506 552.523 538.504 552.629C538.538 553.095 538.122 553.46 537.538 553.443Z" fill="black" />
            <path d="M516.955 544.932C516.938 545.138 516.842 545.33 516.687 545.468C516.533 545.605 516.331 545.679 516.124 545.673C515.905 545.673 515.695 545.586 515.54 545.431C515.386 545.276 515.299 545.066 515.299 544.847C515.299 544.628 515.386 544.418 515.54 544.263C515.695 544.108 515.905 544.022 516.124 544.022C516.239 544.024 516.353 544.051 516.457 544.098C516.562 544.146 516.656 544.214 516.733 544.299C516.811 544.384 516.871 544.484 516.909 544.592C516.947 544.701 516.962 544.816 516.955 544.932Z" fill="black" />
            <path d="M523.912 522.545C524.076 522.542 524.237 522.588 524.374 522.678C524.512 522.767 524.62 522.895 524.683 523.046C524.747 523.198 524.764 523.364 524.732 523.525C524.7 523.686 524.62 523.834 524.504 523.949C524.387 524.064 524.238 524.141 524.077 524.171C523.916 524.201 523.749 524.182 523.599 524.116C523.449 524.05 523.322 523.94 523.234 523.802C523.147 523.663 523.103 523.501 523.109 523.337C523.107 523.231 523.126 523.127 523.165 523.028C523.205 522.931 523.264 522.842 523.34 522.768C523.414 522.694 523.504 522.636 523.602 522.597C523.701 522.559 523.806 522.542 523.912 522.545Z" fill="black" />
            <path d="M536.246 503.725C536.253 503.521 536.34 503.328 536.488 503.187C536.636 503.046 536.833 502.969 537.038 502.972C537.149 502.956 537.263 502.966 537.37 503.001C537.477 503.036 537.576 503.093 537.657 503.171C537.738 503.249 537.801 503.344 537.841 503.45C537.881 503.555 537.896 503.669 537.886 503.781C537.893 503.887 537.879 503.994 537.844 504.094C537.809 504.195 537.754 504.287 537.681 504.365C537.609 504.444 537.522 504.507 537.425 504.55C537.327 504.593 537.222 504.616 537.116 504.618C536.999 504.62 536.883 504.598 536.775 504.554C536.667 504.51 536.569 504.444 536.488 504.36C536.406 504.276 536.342 504.176 536.301 504.068C536.259 503.958 536.24 503.841 536.246 503.725Z" fill="black" />
            <path d="M517.185 574.796C517.297 574.796 517.407 574.818 517.509 574.863C517.611 574.908 517.703 574.975 517.778 575.06C517.854 575.138 517.911 575.239 517.947 575.346C517.984 575.447 517.997 575.559 517.988 575.672C517.991 575.778 517.971 575.885 517.93 575.98C517.889 576.081 517.827 576.171 517.749 576.244C517.671 576.317 517.578 576.373 517.477 576.407C517.376 576.441 517.269 576.452 517.163 576.441C517.056 576.446 516.949 576.43 516.85 576.39C516.75 576.351 516.659 576.295 516.583 576.216C516.506 576.143 516.445 576.053 516.404 575.958C516.363 575.857 516.342 575.75 516.343 575.643C516.341 575.531 516.362 575.424 516.404 575.318C516.446 575.217 516.507 575.121 516.586 575.043C516.664 574.964 516.758 574.902 516.861 574.857C516.963 574.818 517.074 574.796 517.185 574.796Z" fill="black" />
            <path d="M532.743 533.421C532.636 533.425 532.53 533.408 532.43 533.371C532.33 533.333 532.238 533.275 532.161 533.201C532.084 533.127 532.022 533.038 531.981 532.94C531.939 532.842 531.918 532.736 531.918 532.629C531.914 532.518 531.932 532.408 531.971 532.305C532.011 532.202 532.07 532.107 532.147 532.027C532.223 531.947 532.314 531.883 532.416 531.839C532.517 531.795 532.627 531.772 532.737 531.77C532.848 531.771 532.958 531.794 533.06 531.838C533.162 531.882 533.253 531.947 533.33 532.028C533.406 532.109 533.464 532.204 533.503 532.308C533.541 532.413 533.558 532.524 533.551 532.635C533.555 532.741 533.536 532.846 533.496 532.945C533.456 533.043 533.396 533.132 533.32 533.206C533.244 533.28 533.153 533.337 533.054 533.374C532.955 533.411 532.848 533.427 532.743 533.421Z" fill="black" />
            <path d="M536.718 485.646C536.722 485.542 536.747 485.441 536.791 485.347C536.835 485.254 536.898 485.17 536.975 485.101C537.052 485.032 537.141 484.979 537.239 484.945C537.337 484.911 537.44 484.897 537.543 484.904C537.655 484.901 537.766 484.921 537.869 484.962C537.972 485.004 538.066 485.067 538.144 485.146C538.222 485.226 538.283 485.321 538.323 485.425C538.362 485.529 538.38 485.641 538.374 485.752C538.367 485.967 538.276 486.171 538.121 486.319C537.966 486.469 537.758 486.551 537.543 486.55C537.429 486.547 537.316 486.522 537.212 486.475C537.107 486.428 537.013 486.36 536.936 486.275C536.858 486.19 536.8 486.091 536.762 485.982C536.725 485.875 536.71 485.76 536.718 485.646Z" fill="black" />
            <path d="M523.244 510.675C523.246 510.884 523.167 511.085 523.023 511.235C522.88 511.385 522.682 511.473 522.474 511.478C522.363 511.486 522.251 511.471 522.145 511.434C522.039 511.397 521.943 511.339 521.86 511.263C521.778 511.188 521.711 511.096 521.665 510.994C521.619 510.892 521.594 510.782 521.593 510.67C521.593 510.451 521.68 510.241 521.834 510.086C521.989 509.931 522.199 509.844 522.418 509.844C522.637 509.844 522.847 509.931 523.001 510.086C523.156 510.241 523.244 510.451 523.244 510.67V510.675Z" fill="black" />
            <path d="M527.623 481.894C527.62 482.098 527.537 482.291 527.392 482.433C527.246 482.575 527.051 482.654 526.848 482.652C526.732 482.669 526.614 482.66 526.501 482.625C526.39 482.591 526.286 482.533 526.199 482.454C526.112 482.375 526.044 482.278 525.999 482.169C525.954 482.061 525.933 481.944 525.939 481.827C525.931 481.72 525.945 481.614 525.981 481.513C526.016 481.412 526.072 481.319 526.144 481.241C526.217 481.162 526.305 481.099 526.403 481.057C526.501 481.013 526.606 480.991 526.713 480.99C526.835 480.979 526.958 480.993 527.074 481.034C527.19 481.076 527.295 481.141 527.381 481.228C527.468 481.314 527.535 481.419 527.576 481.534C527.619 481.65 527.634 481.773 527.623 481.894Z" fill="black" />
            <path d="M536.297 540.782C536.178 540.795 536.059 540.784 535.945 540.748C535.832 540.714 535.727 540.655 535.637 540.578C535.547 540.501 535.474 540.405 535.422 540.299C535.371 540.191 535.341 540.075 535.337 539.956C535.332 539.85 535.349 539.743 535.387 539.644C535.426 539.545 535.484 539.454 535.559 539.378C535.634 539.302 535.724 539.243 535.822 539.203C535.921 539.163 536.027 539.144 536.134 539.148C536.245 539.132 536.359 539.142 536.466 539.176C536.574 539.211 536.672 539.269 536.753 539.347C536.835 539.425 536.898 539.52 536.938 539.626C536.977 539.731 536.992 539.844 536.982 539.956C536.992 540.155 536.927 540.35 536.8 540.503C536.673 540.656 536.493 540.755 536.297 540.782Z" fill="black" />
            <path d="M525.343 469.952C524.854 469.912 524.534 469.699 524.501 469.177C524.488 469.065 524.501 468.951 524.536 468.844C524.57 468.737 524.628 468.638 524.703 468.554C524.779 468.471 524.872 468.404 524.975 468.358C525.078 468.313 525.19 468.289 525.304 468.29C525.411 468.285 525.518 468.303 525.619 468.342C525.719 468.381 525.81 468.44 525.886 468.516C525.962 468.592 526.021 468.683 526.06 468.784C526.099 468.884 526.117 468.991 526.112 469.099C526.121 469.206 526.107 469.313 526.072 469.416C526.038 469.517 525.982 469.611 525.911 469.691C525.839 469.77 525.751 469.835 525.653 469.88C525.556 469.925 525.45 469.95 525.343 469.952Z" fill="black" />
            <path d="M518.886 539.704C518.886 539.984 518.617 540.265 518.123 540.265C518.015 540.27 517.907 540.253 517.806 540.217C517.705 540.18 517.612 540.124 517.532 540.051C517.453 539.978 517.388 539.891 517.343 539.793C517.297 539.695 517.271 539.59 517.267 539.482C517.261 539.375 517.278 539.267 517.315 539.166C517.351 539.064 517.408 538.971 517.48 538.892C517.553 538.812 517.641 538.748 517.739 538.702C517.836 538.657 517.942 538.631 518.05 538.626C518.51 538.598 518.886 538.991 518.886 539.704Z" fill="black" />
            <path d="M512.463 586.345C512.463 586.244 512.482 586.137 512.522 586.042C512.562 585.946 512.621 585.862 512.694 585.789C512.768 585.716 512.856 585.66 512.952 585.621C513.048 585.582 513.151 585.565 513.255 585.565C513.366 585.559 513.478 585.57 513.583 585.61C513.688 585.649 513.784 585.705 513.865 585.784C513.947 585.857 514.011 585.952 514.055 586.053C514.098 586.154 514.121 586.267 514.12 586.379C514.121 586.592 514.039 586.8 513.89 586.957C513.741 587.114 513.537 587.204 513.322 587.21C513.208 587.215 513.094 587.193 512.988 587.148C512.883 587.109 512.786 587.047 512.706 586.963C512.626 586.884 512.562 586.783 512.521 586.682C512.479 586.575 512.459 586.457 512.463 586.345Z" fill="black" />
            <path d="M519.2 534.078C519.196 533.974 519.213 533.871 519.251 533.774C519.289 533.677 519.346 533.589 519.419 533.516C519.492 533.442 519.58 533.384 519.677 533.346C519.773 533.307 519.876 533.289 519.98 533.292C520.145 533.284 520.309 533.327 520.449 533.413C520.59 533.5 520.701 533.627 520.768 533.778C520.835 533.929 520.855 534.096 520.825 534.259C520.795 534.421 520.717 534.571 520.601 534.688C520.484 534.805 520.335 534.885 520.173 534.916C520.011 534.947 519.843 534.928 519.691 534.862C519.54 534.796 519.412 534.686 519.325 534.546C519.236 534.406 519.193 534.243 519.2 534.078Z" fill="black" />
            <path d="M533.659 497.256C533.553 497.268 533.446 497.256 533.345 497.223C533.244 497.19 533.152 497.136 533.073 497.064C532.994 496.992 532.932 496.905 532.889 496.808C532.847 496.71 532.826 496.605 532.828 496.498C532.821 496.387 532.837 496.276 532.874 496.171C532.911 496.066 532.969 495.97 533.045 495.888C533.12 495.806 533.212 495.74 533.313 495.695C533.415 495.649 533.525 495.625 533.636 495.622C533.747 495.622 533.857 495.645 533.96 495.688C534.063 495.731 534.156 495.794 534.234 495.873C534.312 495.953 534.373 496.047 534.414 496.151C534.455 496.254 534.475 496.365 534.473 496.476C534.473 496.581 534.451 496.686 534.41 496.782C534.369 496.88 534.308 496.967 534.232 497.04C534.156 497.113 534.066 497.17 533.967 497.207C533.869 497.244 533.764 497.261 533.659 497.256Z" fill="black" />
            <path d="M524.255 541.344C524.255 541.961 523.884 542.304 523.396 542.265C523.288 542.267 523.181 542.247 523.082 542.206C522.983 542.164 522.894 542.102 522.821 542.024C522.748 541.945 522.692 541.852 522.657 541.75C522.623 541.648 522.61 541.54 522.621 541.434C522.619 541.321 522.642 541.21 522.686 541.107C522.731 541.004 522.797 540.912 522.879 540.836C522.962 540.76 523.059 540.703 523.166 540.667C523.272 540.632 523.385 540.619 523.497 540.631C523.594 540.625 523.692 540.638 523.783 540.671C523.875 540.704 523.959 540.756 524.03 540.823C524.101 540.89 524.158 540.97 524.196 541.06C524.235 541.15 524.255 541.246 524.255 541.344Z" fill="black" />
            <path d="M522.351 546.795C522.351 547.011 522.267 547.218 522.117 547.373C521.968 547.528 521.763 547.619 521.548 547.626C521.436 547.632 521.325 547.615 521.221 547.577C521.116 547.538 521.021 547.478 520.941 547.4C520.86 547.323 520.797 547.23 520.755 547.126C520.713 547.023 520.692 546.912 520.695 546.801C520.686 546.695 520.701 546.588 520.737 546.487C520.774 546.387 520.831 546.296 520.905 546.22C520.979 546.143 521.069 546.083 521.169 546.044C521.267 546.005 521.374 545.987 521.481 545.992C521.592 545.982 521.704 545.994 521.81 546.029C521.916 546.065 522.014 546.122 522.096 546.198C522.178 546.274 522.244 546.367 522.288 546.469C522.331 546.572 522.353 546.683 522.351 546.795Z" fill="black" />
            <path d="M516.506 562.937C516.516 563.044 516.503 563.145 516.468 563.246C516.434 563.342 516.379 563.431 516.306 563.51C516.234 563.583 516.146 563.645 516.048 563.679C515.951 563.718 515.847 563.735 515.742 563.729C515.631 563.746 515.516 563.74 515.407 563.707C515.299 563.673 515.198 563.622 515.114 563.544C515.029 563.471 514.962 563.375 514.917 563.274C514.873 563.168 514.851 563.055 514.855 562.943C514.845 562.836 514.857 562.73 514.891 562.629C514.925 562.528 514.981 562.438 515.053 562.359C515.126 562.28 515.215 562.219 515.313 562.174C515.412 562.134 515.518 562.115 515.625 562.118C515.738 562.105 515.853 562.117 515.962 562.151C516.07 562.191 516.17 562.247 516.254 562.325C516.338 562.404 516.404 562.499 516.447 562.606C516.491 562.707 516.511 562.825 516.506 562.937Z" fill="black" />
            <path d="M524.068 591.095C523.96 591.101 523.852 591.084 523.75 591.045C523.648 591.011 523.555 590.955 523.475 590.882C523.396 590.803 523.332 590.719 523.287 590.618C523.242 590.517 523.218 590.41 523.215 590.304C523.216 590.079 523.303 589.866 523.457 589.703C523.612 589.54 523.822 589.445 524.046 589.433C524.27 589.445 524.482 589.54 524.634 589.708C524.786 589.871 524.868 590.09 524.86 590.315C524.863 590.422 524.845 590.523 524.806 590.618C524.767 590.719 524.709 590.803 524.635 590.876C524.56 590.949 524.472 591.011 524.374 591.045C524.277 591.084 524.173 591.101 524.068 591.095Z" fill="black" />
            <path d="M534.023 514.567C534.025 514.67 534.006 514.772 533.968 514.868C533.93 514.963 533.873 515.051 533.8 515.124C533.729 515.198 533.643 515.256 533.548 515.296C533.453 515.336 533.351 515.358 533.249 515.358C533.137 515.372 533.024 515.362 532.917 515.33C532.809 515.298 532.709 515.244 532.623 515.172C532.537 515.1 532.466 515.011 532.416 514.911C532.365 514.811 532.336 514.701 532.329 514.589C532.322 514.477 532.338 514.365 532.376 514.259C532.414 514.154 532.473 514.057 532.55 513.975C532.626 513.893 532.718 513.828 532.821 513.783C532.924 513.738 533.035 513.714 533.147 513.713C533.262 513.707 533.377 513.725 533.484 513.765C533.592 513.805 533.69 513.868 533.772 513.948C533.855 514.028 533.919 514.125 533.963 514.231C534.006 514.337 534.027 514.452 534.023 514.567Z" fill="black" />
            <path d="M533.434 465.786C533.42 465.988 533.326 466.177 533.174 466.312C533.021 466.446 532.823 466.516 532.62 466.505C532.507 466.51 532.395 466.49 532.289 466.449C532.184 466.409 532.089 466.347 532.009 466.267C531.928 466.188 531.866 466.092 531.824 465.987C531.782 465.882 531.763 465.77 531.767 465.657C531.771 465.545 531.798 465.434 531.847 465.332C531.895 465.231 531.964 465.14 532.05 465.066C532.135 464.992 532.235 464.936 532.343 464.903C532.45 464.869 532.564 464.858 532.676 464.871C532.787 464.879 532.895 464.91 532.993 464.96C533.092 465.012 533.179 465.082 533.249 465.167C533.32 465.253 533.373 465.352 533.405 465.458C533.436 465.564 533.446 465.676 533.434 465.786Z" fill="black" />
            <path d="M524.187 474.573C524.183 474.676 524.159 474.777 524.117 474.87C524.074 474.963 524.013 475.047 523.939 475.117C523.863 475.186 523.775 475.241 523.679 475.277C523.583 475.313 523.48 475.329 523.378 475.325C523.266 475.33 523.155 475.312 523.05 475.271C522.946 475.23 522.851 475.169 522.772 475.089C522.693 475.01 522.631 474.915 522.59 474.811C522.549 474.707 522.531 474.595 522.536 474.483C522.542 474.375 522.569 474.268 522.616 474.171C522.663 474.073 522.729 473.985 522.809 473.912C522.89 473.84 522.985 473.784 523.087 473.749C523.19 473.713 523.298 473.697 523.406 473.703C523.515 473.709 523.621 473.736 523.718 473.783C523.817 473.83 523.904 473.896 523.977 473.977C524.049 474.057 524.105 474.151 524.141 474.254C524.177 474.356 524.192 474.464 524.187 474.573Z" fill="black" />
            <path d="M527.152 499.924C527.15 500.132 527.067 500.331 526.92 500.478C526.773 500.625 526.573 500.708 526.365 500.71C526.254 500.717 526.143 500.701 526.038 500.663C525.933 500.625 525.836 500.566 525.755 500.49C525.673 500.414 525.608 500.322 525.564 500.22C525.519 500.118 525.496 500.007 525.495 499.896C525.49 499.789 525.506 499.682 525.543 499.581C525.581 499.481 525.639 499.389 525.713 499.312C525.787 499.235 525.876 499.174 525.975 499.132C526.074 499.09 526.18 499.069 526.287 499.07C526.401 499.062 526.517 499.078 526.624 499.118C526.732 499.158 526.83 499.22 526.912 499.301C526.994 499.382 527.058 499.479 527.099 499.586C527.14 499.694 527.158 499.809 527.152 499.924Z" fill="black" />
            <path d="M532.535 501.855C532.534 501.967 532.508 502.077 532.462 502.178C532.415 502.279 532.348 502.37 532.265 502.444C532.181 502.518 532.083 502.573 531.977 502.607C531.871 502.641 531.759 502.653 531.648 502.641C531.541 502.645 531.434 502.625 531.335 502.582C531.236 502.54 531.148 502.477 531.075 502.398C531.004 502.319 530.95 502.224 530.918 502.122C530.886 502.019 530.876 501.911 530.89 501.805C530.885 501.698 530.902 501.592 530.941 501.493C530.98 501.393 531.039 501.303 531.115 501.228C531.19 501.153 531.281 501.095 531.38 501.056C531.48 501.018 531.586 501.002 531.693 501.008C531.804 501.006 531.915 501.027 532.018 501.069C532.121 501.111 532.215 501.173 532.294 501.252C532.372 501.332 532.434 501.426 532.476 501.529C532.517 501.633 532.537 501.744 532.535 501.855Z" fill="black" />
            <path d="M530.587 507.194C530.592 507.306 530.573 507.417 530.532 507.521C530.491 507.625 530.43 507.719 530.35 507.797C530.271 507.875 530.176 507.936 530.072 507.975C529.967 508.015 529.856 508.032 529.745 508.025C529.638 508.032 529.531 508.016 529.431 507.978C529.331 507.94 529.241 507.881 529.166 507.805C529.091 507.728 529.033 507.637 528.996 507.537C528.959 507.436 528.945 507.329 528.953 507.222C528.94 507.117 528.95 507.01 528.982 506.908C529.015 506.806 529.069 506.713 529.141 506.635C529.213 506.557 529.302 506.494 529.4 506.453C529.498 506.412 529.604 506.393 529.711 506.397C529.822 506.386 529.935 506.399 530.04 506.434C530.146 506.469 530.244 506.526 530.326 506.601C530.409 506.676 530.475 506.768 530.52 506.87C530.564 506.973 530.588 507.083 530.587 507.194Z" fill="black" />
            <path d="M525.892 517.155C525.999 517.156 526.105 517.178 526.203 517.22C526.301 517.263 526.39 517.325 526.463 517.402C526.536 517.48 526.594 517.572 526.631 517.672C526.668 517.772 526.684 517.879 526.678 517.986C526.686 518.099 526.667 518.211 526.625 518.316C526.583 518.421 526.519 518.515 526.436 518.592C526.353 518.669 526.254 518.726 526.147 518.76C526.039 518.794 525.926 518.804 525.814 518.789C525.706 518.793 525.599 518.772 525.499 518.73C525.4 518.688 525.311 518.624 525.239 518.544C525.167 518.464 525.113 518.369 525.081 518.265C525.05 518.163 525.041 518.054 525.056 517.946C525.072 517.441 525.376 517.2 525.892 517.155Z" fill="black" />
            <path d="M529.307 526.133C529.193 526.135 529.079 526.113 528.974 526.069C528.869 526.026 528.773 525.961 528.694 525.879C528.614 525.797 528.553 525.699 528.512 525.592C528.472 525.486 528.454 525.372 528.459 525.257C528.473 525.045 528.571 524.847 528.729 524.706C528.887 524.564 529.094 524.49 529.307 524.499C529.515 524.516 529.709 524.611 529.85 524.765C529.991 524.918 530.07 525.119 530.07 525.327C530.07 525.536 529.991 525.737 529.85 525.89C529.709 526.044 529.515 526.139 529.307 526.155V526.133Z" fill="black" />
            <path d="M520.57 582.117C521.103 582.157 521.418 582.415 521.418 582.954C521.425 583.061 521.41 583.167 521.373 583.268C521.337 583.364 521.28 583.459 521.206 583.532C521.131 583.611 521.042 583.673 520.943 583.712C520.844 583.751 520.738 583.768 520.632 583.762C520.521 583.774 520.408 583.757 520.303 583.717C520.198 583.678 520.102 583.622 520.022 583.543C519.943 583.465 519.88 583.369 519.841 583.263C519.8 583.162 519.783 583.049 519.789 582.937C519.787 582.83 519.805 582.729 519.842 582.628C519.88 582.533 519.937 582.443 520.009 582.364C520.082 582.291 520.168 582.23 520.265 582.185C520.361 582.145 520.465 582.117 520.57 582.117Z" fill="black" />
            <path d="M530.807 538.805C530.7 538.811 530.594 538.795 530.494 538.758C530.394 538.721 530.303 538.663 530.225 538.59C530.148 538.516 530.086 538.428 530.045 538.33C530.003 538.231 529.981 538.126 529.982 538.02C529.974 537.908 529.989 537.796 530.026 537.691C530.063 537.586 530.121 537.489 530.197 537.407C530.272 537.325 530.364 537.26 530.466 537.214C530.568 537.169 530.678 537.145 530.79 537.144C531.013 537.153 531.225 537.247 531.382 537.407C531.54 537.566 531.631 537.779 531.638 538.002C531.624 538.216 531.531 538.416 531.377 538.565C531.223 538.713 531.02 538.799 530.807 538.805Z" fill="black" />
            <path d="M535.052 545.453C535.026 545.657 534.922 545.843 534.761 545.971C534.601 546.099 534.396 546.159 534.193 546.138C534.08 546.135 533.97 546.109 533.869 546.061C533.768 546.012 533.677 545.943 533.604 545.858C533.531 545.773 533.477 545.674 533.445 545.566C533.413 545.459 533.403 545.345 533.418 545.234C533.419 545.127 533.443 545.022 533.488 544.925C533.533 544.828 533.598 544.742 533.678 544.671C533.759 544.601 533.853 544.548 533.955 544.517C534.057 544.485 534.165 544.475 534.271 544.487C534.388 544.494 534.502 544.525 534.606 544.578C534.71 544.632 534.802 544.706 534.876 544.797C534.949 544.888 535.003 544.993 535.033 545.107C535.063 545.22 535.07 545.337 535.052 545.453Z" fill="black" />
            <path d="M524.866 554.937C524.761 554.932 524.658 554.906 524.564 554.86C524.469 554.814 524.385 554.75 524.316 554.67C524.248 554.591 524.196 554.499 524.164 554.399C524.132 554.299 524.12 554.194 524.131 554.089C524.134 553.977 524.16 553.869 524.207 553.767C524.255 553.667 524.323 553.577 524.406 553.504C524.49 553.431 524.588 553.376 524.694 553.342C524.8 553.308 524.913 553.297 525.023 553.309C525.136 553.312 525.246 553.34 525.346 553.391C525.447 553.441 525.535 553.513 525.604 553.601C525.674 553.689 525.723 553.792 525.749 553.902C525.775 554.011 525.776 554.125 525.753 554.235C525.703 554.757 525.377 554.943 524.866 554.937Z" fill="black" />
            <path d="M517.628 556.733C517.739 556.733 517.85 556.755 517.952 556.798C518.055 556.841 518.148 556.904 518.225 556.985C518.303 557.066 518.363 557.161 518.402 557.265C518.441 557.37 518.458 557.481 518.453 557.592C518.436 557.797 518.343 557.989 518.192 558.128C518.04 558.267 517.842 558.345 517.636 558.345C517.431 558.345 517.233 558.267 517.081 558.128C516.93 557.989 516.836 557.797 516.819 557.592C516.812 557.482 516.827 557.371 516.864 557.267C516.901 557.162 516.96 557.067 517.036 556.986C517.111 556.905 517.203 556.841 517.305 556.798C517.407 556.754 517.517 556.732 517.628 556.733Z" fill="black" />
            <path d="M535.73 557.188C535.948 557.188 536.156 557.274 536.31 557.428C536.464 557.582 536.55 557.79 536.55 558.008C536.55 558.225 536.464 558.434 536.31 558.588C536.156 558.741 535.948 558.827 535.73 558.827C535.623 558.827 535.516 558.806 535.417 558.765C535.317 558.724 535.227 558.663 535.15 558.588C535.075 558.511 535.014 558.421 534.973 558.322C534.931 558.222 534.91 558.115 534.91 558.008C534.91 557.9 534.931 557.794 534.973 557.694C535.014 557.594 535.075 557.504 535.15 557.428C535.227 557.352 535.317 557.292 535.417 557.25C535.516 557.209 535.623 557.188 535.73 557.188Z" fill="black" />
            <path d="M529.217 561.511C529.205 561.715 529.117 561.907 528.973 562.05C528.828 562.191 528.635 562.275 528.431 562.286C528.319 562.292 528.207 562.281 528.102 562.241C527.996 562.202 527.9 562.146 527.818 562.067C527.737 561.99 527.673 561.896 527.629 561.793C527.586 561.69 527.564 561.579 527.566 561.466C527.559 561.36 527.574 561.254 527.61 561.154C527.646 561.054 527.702 560.962 527.774 560.884C527.847 560.806 527.934 560.744 528.032 560.701C528.13 560.658 528.235 560.636 528.341 560.635C528.458 560.628 528.575 560.646 528.685 560.687C528.795 560.729 528.894 560.793 528.977 560.876C529.06 560.959 529.124 561.058 529.166 561.168C529.207 561.277 529.225 561.395 529.217 561.511Z" fill="black" />
            <path d="M513.788 569.119C513.674 569.125 513.559 569.114 513.452 569.074C513.345 569.035 513.247 568.973 513.165 568.895C513.083 568.81 513.018 568.715 512.975 568.614C512.933 568.507 512.913 568.389 512.918 568.277C512.918 568.064 513.003 567.856 513.156 567.704C513.308 567.547 513.516 567.463 513.732 567.463C513.947 567.463 514.155 567.547 514.307 567.704C514.46 567.856 514.546 568.064 514.546 568.277C514.553 568.384 514.538 568.49 514.504 568.586C514.469 568.687 514.415 568.782 514.344 568.855C514.274 568.934 514.188 569.001 514.092 569.046C513.997 569.091 513.893 569.114 513.788 569.119Z" fill="black" />
            <path d="M531.88 567.934C531.989 567.934 532.098 567.957 532.198 568.002C532.298 568.047 532.387 568.114 532.458 568.198C532.53 568.283 532.583 568.378 532.612 568.485C532.642 568.591 532.649 568.704 532.632 568.81C532.636 568.917 532.617 569.024 532.576 569.119C532.535 569.22 532.473 569.31 532.395 569.383C532.316 569.45 532.223 569.507 532.121 569.54C532.019 569.574 531.912 569.58 531.807 569.568C531.699 569.574 531.593 569.557 531.493 569.518C531.394 569.479 531.304 569.417 531.228 569.338C531.153 569.265 531.094 569.175 531.055 569.074C531.017 568.973 530.999 568.867 531.004 568.76C531.002 568.648 531.023 568.535 531.068 568.429C531.112 568.327 531.177 568.232 531.259 568.153C531.342 568.075 531.439 568.019 531.546 567.979C531.653 567.94 531.767 567.929 531.88 567.934Z" fill="black" />
            <path d="M519.054 569.417C519.169 569.406 519.284 569.422 519.392 569.462C519.501 569.501 519.6 569.557 519.684 569.636C519.768 569.714 519.834 569.81 519.878 569.916C519.922 570.023 519.944 570.141 519.941 570.253C519.943 570.36 519.923 570.467 519.882 570.562C519.842 570.663 519.782 570.753 519.705 570.826C519.629 570.899 519.538 570.961 519.439 571C519.34 571.039 519.234 571.056 519.127 571.051C519.015 571.056 518.903 571.034 518.8 570.994C518.696 570.95 518.602 570.888 518.524 570.809C518.446 570.731 518.386 570.63 518.348 570.528C518.309 570.422 518.294 570.309 518.302 570.197C518.293 570.096 518.306 569.989 518.34 569.894C518.375 569.799 518.429 569.709 518.5 569.636C518.572 569.563 518.658 569.507 518.754 569.467C518.849 569.428 518.952 569.411 519.054 569.417Z" fill="black" />
            <path d="M535.248 575.245C535.361 575.239 535.474 575.256 535.579 575.295C535.684 575.335 535.78 575.402 535.859 575.481C535.938 575.565 535.998 575.66 536.037 575.767C536.074 575.874 536.089 575.986 536.079 576.098C536.084 576.205 536.066 576.312 536.027 576.413C535.988 576.508 535.928 576.604 535.852 576.677C535.775 576.75 535.684 576.806 535.584 576.845C535.484 576.884 535.377 576.896 535.27 576.89C535.158 576.896 535.047 576.879 534.942 576.84C534.837 576.8 534.742 576.738 534.664 576.66C534.585 576.576 534.524 576.48 534.486 576.379C534.447 576.272 534.431 576.16 534.44 576.048C534.441 575.835 534.527 575.632 534.678 575.481C534.83 575.329 535.034 575.245 535.248 575.245Z" fill="black" />
            <path d="M533.367 580.646C533.47 580.646 533.572 580.669 533.666 580.708C533.76 580.753 533.845 580.815 533.915 580.888C533.984 580.966 534.037 581.056 534.071 581.152C534.104 581.247 534.117 581.354 534.108 581.455C534.116 581.567 534.101 581.679 534.062 581.78C534.024 581.887 533.964 581.983 533.886 582.061C533.808 582.14 533.713 582.202 533.609 582.246C533.505 582.286 533.394 582.303 533.283 582.297C533.176 582.303 533.069 582.286 532.97 582.246C532.87 582.207 532.78 582.151 532.705 582.072C532.631 581.994 532.574 581.904 532.537 581.803C532.502 581.702 532.488 581.595 532.497 581.488C532.491 581.376 532.511 581.258 532.553 581.152C532.595 581.045 532.66 580.949 532.742 580.871C532.824 580.792 532.923 580.73 533.03 580.691C533.138 580.652 533.253 580.635 533.367 580.646Z" fill="black" />
            <path d="M536.696 589.63C536.584 589.63 536.474 589.607 536.372 589.562C536.27 589.512 536.179 589.445 536.105 589.366C536.031 589.282 535.976 589.181 535.944 589.074C535.911 588.967 535.901 588.855 535.915 588.743C535.916 588.636 535.938 588.529 535.982 588.434C536.026 588.333 536.089 588.249 536.169 588.176C536.248 588.103 536.341 588.052 536.443 588.018C536.544 587.985 536.652 587.973 536.758 587.985C536.865 587.985 536.971 588.007 537.069 588.052C537.168 588.091 537.257 588.153 537.332 588.232C537.407 588.305 537.465 588.395 537.504 588.496C537.544 588.597 537.563 588.703 537.561 588.81C537.56 588.922 537.537 589.029 537.493 589.136C537.448 589.237 537.384 589.327 537.303 589.405C537.223 589.484 537.127 589.54 537.023 589.579C536.918 589.619 536.807 589.635 536.696 589.63Z" fill="black" />
            <path d="M533.799 564.212C533.585 564.218 533.379 564.133 533.224 563.987C533.069 563.841 532.977 563.639 532.968 563.426C532.959 563.314 532.974 563.201 533.012 563.095C533.049 562.994 533.109 562.898 533.186 562.814C533.264 562.735 533.357 562.673 533.461 562.629C533.565 562.589 533.676 562.567 533.788 562.572C533.895 562.561 534.003 562.578 534.104 562.612C534.205 562.651 534.296 562.707 534.373 562.786C534.448 562.859 534.507 562.954 534.544 563.055C534.58 563.156 534.594 563.263 534.585 563.37C534.594 563.476 534.581 563.589 534.546 563.69C534.51 563.791 534.454 563.886 534.38 563.965C534.306 564.043 534.215 564.111 534.115 564.15C534.016 564.195 533.908 564.212 533.799 564.212Z" fill="black" />
            <path d="M534.922 478.492C534.919 478.59 534.896 478.687 534.855 478.777C534.814 478.866 534.755 478.946 534.682 479.012C534.609 479.078 534.524 479.129 534.431 479.161C534.338 479.193 534.239 479.206 534.142 479.199C534.03 479.211 533.918 479.198 533.813 479.162C533.706 479.126 533.61 479.068 533.529 478.991C533.448 478.915 533.384 478.821 533.343 478.718C533.301 478.614 533.283 478.503 533.288 478.391C533.283 478.285 533.299 478.178 533.336 478.078C533.373 477.978 533.43 477.887 533.504 477.81C533.577 477.732 533.666 477.671 533.764 477.629C533.862 477.587 533.968 477.566 534.074 477.566C534.193 477.562 534.311 477.585 534.421 477.631C534.531 477.678 534.629 477.747 534.709 477.836C534.79 477.923 534.85 478.027 534.887 478.14C534.924 478.254 534.936 478.374 534.922 478.492Z" fill="black" />
            <path d="M528.291 493.708C528.398 493.712 528.503 493.738 528.6 493.783C528.697 493.828 528.784 493.891 528.857 493.97C528.929 494.049 528.986 494.141 529.022 494.242C529.059 494.342 529.076 494.449 529.071 494.556C529.067 494.663 529.042 494.768 528.996 494.865C528.951 494.962 528.888 495.049 528.809 495.122C528.73 495.194 528.638 495.25 528.537 495.287C528.437 495.324 528.33 495.341 528.224 495.336C528.116 495.336 528.01 495.313 527.912 495.269C527.814 495.225 527.727 495.161 527.655 495.082C527.584 495.001 527.53 494.907 527.497 494.805C527.464 494.703 527.454 494.595 527.466 494.488C527.488 493.966 527.808 493.747 528.291 493.708Z" fill="black" />
            <path d="M518.559 522.254C518.453 522.26 518.347 522.245 518.247 522.21C518.147 522.174 518.055 522.118 517.977 522.045C517.899 521.973 517.837 521.885 517.794 521.788C517.751 521.69 517.729 521.585 517.728 521.479C517.721 521.367 517.736 521.255 517.773 521.15C517.809 521.044 517.866 520.947 517.942 520.865C518.016 520.781 518.107 520.715 518.209 520.668C518.31 520.621 518.42 520.595 518.531 520.592C518.757 520.594 518.972 520.687 519.13 520.847C519.288 521.008 519.375 521.225 519.373 521.451C519.373 521.557 519.352 521.662 519.31 521.76C519.268 521.857 519.208 521.946 519.133 522.021C519.057 522.095 518.968 522.154 518.869 522.194C518.771 522.234 518.665 522.254 518.559 522.254Z" fill="black" />
            <path d="M527.931 578.687C528.037 578.681 528.143 578.698 528.243 578.732C528.343 578.771 528.435 578.827 528.512 578.9C528.59 578.973 528.651 579.063 528.693 579.158C528.735 579.254 528.756 579.36 528.756 579.467C528.762 579.579 528.745 579.692 528.706 579.793C528.668 579.899 528.609 579.995 528.534 580.079C528.458 580.163 528.367 580.225 528.265 580.276C528.164 580.32 528.054 580.343 527.942 580.349C527.718 580.337 527.505 580.242 527.351 580.085C527.196 579.922 527.11 579.703 527.111 579.478C527.112 579.372 527.135 579.271 527.177 579.169C527.219 579.074 527.28 578.984 527.356 578.911C527.433 578.838 527.522 578.782 527.621 578.743C527.72 578.703 527.825 578.687 527.931 578.687Z" fill="black" />
            <path d="M524.372 572.988C524.274 572.982 524.18 572.954 524.093 572.909C524.007 572.864 523.93 572.803 523.867 572.73C523.804 572.657 523.757 572.572 523.727 572.477C523.699 572.387 523.688 572.286 523.698 572.19C523.698 571.977 523.784 571.769 523.936 571.612C524.089 571.461 524.296 571.376 524.512 571.376C524.727 571.376 524.935 571.461 525.087 571.612C525.24 571.769 525.326 571.977 525.326 572.19C525.377 572.651 524.989 572.993 524.372 572.988Z" fill="black" />
            <path d="M527.281 566.828C527.264 567.036 527.169 567.233 527.016 567.373C526.862 567.513 526.661 567.592 526.453 567.592C526.244 567.592 526.043 567.513 525.89 567.373C525.737 567.233 525.642 567.036 525.625 566.828C525.62 566.716 525.639 566.604 525.679 566.497C525.72 566.39 525.783 566.295 525.862 566.216C525.942 566.138 526.037 566.076 526.142 566.031C526.247 565.992 526.36 565.969 526.473 565.975C526.582 565.981 526.689 566.003 526.789 566.048C526.889 566.093 526.979 566.155 527.054 566.233C527.128 566.312 527.187 566.407 527.227 566.508C527.265 566.609 527.284 566.722 527.281 566.828Z" fill="black" />
            <path d="M520.919 498.778C520.7 498.778 520.49 498.691 520.335 498.536C520.181 498.382 520.094 498.172 520.094 497.953C520.094 497.734 520.181 497.524 520.335 497.369C520.49 497.214 520.7 497.127 520.919 497.127C521.145 497.136 521.359 497.233 521.515 497.396C521.671 497.56 521.757 497.777 521.756 498.003C521.735 498.213 521.638 498.407 521.485 498.549C521.33 498.693 521.129 498.774 520.919 498.778Z" fill="black" />
            <path d="M516.753 592.886C516.864 592.886 516.974 592.914 517.075 592.959C517.176 593.004 517.266 593.072 517.34 593.156C517.413 593.24 517.468 593.341 517.501 593.448C517.535 593.549 517.546 593.661 517.533 593.773C517.535 593.88 517.514 593.987 517.471 594.082C517.428 594.183 517.365 594.268 517.286 594.341C517.206 594.408 517.113 594.464 517.011 594.492C516.909 594.526 516.802 594.531 516.697 594.52C516.59 594.526 516.483 594.509 516.384 594.47C516.284 594.43 516.194 594.369 516.12 594.296C516.045 594.217 515.987 594.127 515.95 594.026C515.913 593.925 515.898 593.818 515.905 593.712C515.902 593.599 515.921 593.493 515.963 593.386C516.005 593.285 516.067 593.189 516.147 593.111C516.227 593.038 516.322 592.976 516.426 592.937C516.531 592.898 516.642 592.881 516.753 592.886Z" fill="black" />
            <path d="M529.667 543.404C529.669 543.505 529.65 543.606 529.613 543.7C529.576 543.793 529.519 543.879 529.448 543.951C529.377 544.022 529.291 544.079 529.198 544.117C529.104 544.155 529.004 544.175 528.903 544.173C528.737 544.184 528.571 544.144 528.428 544.059C528.285 543.974 528.171 543.847 528.102 543.695C528.033 543.544 528.012 543.375 528.041 543.211C528.07 543.047 528.149 542.896 528.266 542.778C528.384 542.659 528.534 542.58 528.698 542.549C528.861 542.519 529.031 542.539 529.183 542.607C529.335 542.675 529.463 542.788 529.549 542.93C529.635 543.072 529.676 543.237 529.667 543.404Z" fill="black" />
            <path d="M528.184 530.675C528.187 530.782 528.169 530.889 528.128 530.989C528.088 531.088 528.028 531.179 527.952 531.254C527.875 531.329 527.784 531.388 527.685 531.427C527.584 531.465 527.477 531.483 527.37 531.478C527.263 531.489 527.156 531.478 527.055 531.444C526.953 531.41 526.86 531.355 526.782 531.282C526.704 531.209 526.642 531.12 526.601 531.021C526.56 530.922 526.541 530.816 526.545 530.709C526.534 530.597 526.548 530.484 526.585 530.378C526.622 530.271 526.682 530.174 526.76 530.093C526.839 530.013 526.934 529.95 527.039 529.91C527.145 529.87 527.258 529.853 527.37 529.861C527.477 529.86 527.584 529.881 527.682 529.922C527.782 529.962 527.872 530.022 527.947 530.098C528.023 530.174 528.083 530.264 528.124 530.363C528.164 530.462 528.185 530.568 528.184 530.675Z" fill="black" />
            <path d="M531.019 489.126C531.03 489.233 531.019 489.341 530.986 489.443C530.953 489.545 530.899 489.639 530.827 489.72C530.756 489.8 530.669 489.864 530.572 489.909C530.474 489.954 530.369 489.978 530.261 489.98C530.15 489.992 530.037 489.981 529.931 489.948C529.824 489.913 529.726 489.857 529.642 489.782C529.559 489.707 529.492 489.616 529.447 489.513C529.402 489.411 529.379 489.3 529.379 489.188C529.371 489.082 529.384 488.975 529.419 488.875C529.454 488.774 529.51 488.682 529.582 488.604C529.654 488.525 529.742 488.464 529.839 488.421C529.937 488.378 530.042 488.357 530.149 488.357C530.258 488.341 530.37 488.348 530.476 488.38C530.583 488.411 530.68 488.465 530.763 488.539C530.846 488.612 530.912 488.703 530.957 488.804C531 488.905 531.022 489.015 531.019 489.126Z" fill="black" />
            <path d="M530.211 592.319C530.206 592.426 530.18 592.527 530.134 592.617C530.088 592.712 530.023 592.797 529.944 592.864C529.864 592.931 529.771 592.982 529.671 593.01C529.571 593.044 529.466 593.049 529.363 593.038C529.256 593.038 529.15 593.015 529.052 592.971C528.954 592.926 528.866 592.864 528.794 592.785C528.721 592.707 528.666 592.611 528.632 592.51C528.597 592.409 528.584 592.302 528.594 592.196C528.596 592.083 528.621 591.971 528.668 591.87C528.715 591.769 528.783 591.679 528.868 591.601C528.953 591.528 529.052 591.471 529.159 591.438C529.267 591.404 529.381 591.398 529.492 591.41C530.009 591.466 530.217 591.786 530.211 592.319Z" fill="black" />
            <path d="M522.143 527.941C522.239 527.95 522.332 527.978 522.418 528.024C522.502 528.07 522.577 528.132 522.638 528.208C522.698 528.283 522.742 528.37 522.768 528.464C522.794 528.556 522.801 528.654 522.789 528.749C522.791 528.861 522.771 528.972 522.727 529.075C522.685 529.178 522.621 529.27 522.54 529.347C522.459 529.424 522.363 529.483 522.258 529.52C522.153 529.557 522.041 529.572 521.93 529.563C521.818 529.557 521.711 529.528 521.611 529.478C521.512 529.428 521.424 529.359 521.351 529.275C521.279 529.191 521.224 529.093 521.191 528.987C521.157 528.881 521.145 528.77 521.155 528.659C521.194 528.21 521.548 527.913 522.143 527.941Z" fill="black" />
            <path d="M536.606 522.697C536.403 522.693 536.209 522.612 536.065 522.47C535.92 522.328 535.834 522.136 535.825 521.934C535.81 521.718 535.88 521.505 536.02 521.341C536.161 521.176 536.36 521.074 536.576 521.057C536.791 521.039 537.005 521.107 537.17 521.246C537.336 521.384 537.44 521.583 537.459 521.799C537.468 521.915 537.453 522.032 537.413 522.142C537.374 522.252 537.312 522.352 537.232 522.437C537.152 522.521 537.054 522.588 536.947 522.633C536.839 522.678 536.723 522.699 536.606 522.697Z" fill="black" />
            <path d="M522.929 558.682C523.045 558.671 523.163 558.686 523.274 558.724C523.385 558.762 523.487 558.823 523.573 558.903C523.658 558.983 523.726 559.08 523.772 559.188C523.818 559.296 523.84 559.412 523.838 559.53C523.841 559.636 523.823 559.742 523.783 559.841C523.743 559.94 523.682 560.029 523.606 560.103C523.529 560.177 523.437 560.234 523.337 560.271C523.237 560.307 523.131 560.323 523.024 560.316C522.913 560.329 522.799 560.316 522.694 560.279C522.588 560.241 522.492 560.18 522.413 560.1C522.334 560.019 522.276 559.922 522.24 559.816C522.205 559.709 522.195 559.596 522.21 559.485C522.204 559.385 522.218 559.284 522.251 559.19C522.284 559.095 522.335 559.008 522.402 558.934C522.469 558.859 522.55 558.798 522.64 558.755C522.73 558.712 522.829 558.687 522.929 558.682Z" fill="black" />
            <path d="M537.234 571.505C537.123 571.517 537.01 571.505 536.905 571.466C536.799 571.427 536.703 571.371 536.622 571.292C536.541 571.213 536.477 571.124 536.436 571.017C536.394 570.916 536.375 570.804 536.381 570.691C536.373 570.585 536.389 570.478 536.427 570.377C536.465 570.276 536.525 570.186 536.602 570.107C536.678 570.034 536.771 569.973 536.872 569.939C536.974 569.905 537.082 569.888 537.189 569.9C537.296 569.894 537.403 569.905 537.504 569.945C537.605 569.978 537.696 570.04 537.773 570.113C537.85 570.186 537.909 570.276 537.948 570.377C537.987 570.478 538.004 570.585 537.998 570.691C538.01 570.798 538 570.905 537.966 571.006C537.933 571.107 537.878 571.197 537.805 571.275C537.732 571.354 537.644 571.416 537.545 571.455C537.447 571.494 537.34 571.511 537.234 571.505Z" fill="black" />
            <path d="M534.697 526.408C535.191 526.47 535.483 526.717 535.472 527.239C535.481 527.346 535.467 527.454 535.431 527.555C535.395 527.656 535.338 527.749 535.263 527.826C535.189 527.904 535.1 527.964 535 528.005C534.9 528.045 534.793 528.063 534.686 528.059C534.467 528.059 534.257 527.972 534.102 527.817C533.947 527.662 533.86 527.452 533.86 527.233C533.86 527.014 533.947 526.805 534.102 526.65C534.257 526.495 534.467 526.408 534.686 526.408H534.697Z" fill="black" />
            <path d="M538.673 467.184L538.701 468.026C538.647 468.138 538.566 468.236 538.467 468.311C538.367 468.387 538.251 468.437 538.129 468.458C538.006 468.48 537.879 468.471 537.76 468.434C537.641 468.397 537.532 468.333 537.444 468.245C537.356 468.173 537.286 468.083 537.236 467.981C537.187 467.879 537.159 467.768 537.157 467.655C537.153 467.542 537.174 467.429 537.218 467.325C537.262 467.22 537.327 467.127 537.41 467.049C537.491 466.955 537.595 466.882 537.711 466.837C537.827 466.791 537.952 466.774 538.076 466.787C538.2 466.8 538.319 466.844 538.423 466.913C538.527 466.982 538.612 467.075 538.673 467.184Z" fill="black" />
            <path d="M533.95 459.761H535.169C535.246 459.842 535.305 459.938 535.342 460.044C535.38 460.149 535.394 460.261 535.385 460.372C535.376 460.484 535.344 460.592 535.29 460.69C535.236 460.787 535.163 460.873 535.073 460.94C534.933 461.074 534.747 461.149 534.554 461.149C534.36 461.149 534.174 461.074 534.035 460.94C533.942 460.875 533.865 460.79 533.809 460.691C533.754 460.593 533.721 460.484 533.713 460.371C533.705 460.258 533.722 460.145 533.763 460.039C533.804 459.934 533.868 459.839 533.95 459.761Z" fill="black" />
            <path d="M538.678 534.01V535.133C538.608 535.222 538.519 535.294 538.417 535.343C538.316 535.393 538.204 535.418 538.091 535.418C537.978 535.418 537.866 535.393 537.765 535.343C537.663 535.294 537.574 535.222 537.504 535.133C537.359 534.979 537.281 534.774 537.288 534.562C537.294 534.351 537.384 534.15 537.538 534.005C537.692 533.86 537.897 533.782 538.109 533.788C538.321 533.794 538.521 533.885 538.666 534.038L538.678 534.01Z" fill="black" />
            <path d="M535.439 594.683H534.097C533.973 594.509 533.921 594.296 533.952 594.088C533.984 593.88 534.096 593.689 534.265 593.56C534.406 593.431 534.591 593.358 534.784 593.358C534.977 593.358 535.162 593.431 535.304 593.56C535.469 593.689 535.577 593.886 535.602 594.093C535.627 594.307 535.568 594.515 535.439 594.683Z" fill="black" />
            <path d="M521.002 479.924C521.165 479.638 520.8 479.031 521.491 479.06C521.597 479.061 521.703 479.085 521.8 479.13C521.897 479.175 521.983 479.241 522.053 479.322C522.122 479.404 522.174 479.499 522.204 479.601C522.234 479.704 522.241 479.812 522.226 479.918C522.222 480.029 522.197 480.138 522.15 480.239C522.103 480.34 522.037 480.43 521.955 480.504C521.873 480.579 521.776 480.636 521.671 480.672C521.567 480.709 521.455 480.723 521.345 480.716C520.772 480.654 521.087 480.216 521.002 479.924Z" fill="black" />
            <path d="M512.16 591.876C512.162 591.994 512.129 592.111 512.065 592.213C512.001 592.314 511.909 592.392 511.801 592.437C511.475 592.566 511.464 592.173 511.307 592.022C511.256 591.977 511.217 591.921 511.194 591.859C511.121 591.578 510.723 591.359 510.959 591.039C511.043 590.966 511.147 590.921 511.258 590.904C511.369 590.893 511.482 590.91 511.582 590.96C511.77 591.022 511.932 591.146 512.037 591.314C512.143 591.483 512.186 591.679 512.16 591.876Z" fill="black" />
            <path d="M538.666 515.701V517.29C538.551 517.317 538.431 517.316 538.316 517.286C538.202 517.257 538.096 517.201 538.008 517.122C537.92 517.043 537.852 516.944 537.81 516.834C537.768 516.723 537.754 516.604 537.768 516.487C537.751 516.369 537.764 516.25 537.806 516.139C537.847 516.028 537.915 515.929 538.004 515.851C538.094 515.773 538.201 515.718 538.316 515.692C538.432 515.666 538.552 515.669 538.666 515.701Z" fill="black" />
            <path d="M519.077 503.365C519.256 503.135 518.925 502.647 519.34 502.63C519.756 502.613 519.829 503.017 519.806 503.388C519.784 503.758 519.576 504.123 519.245 504.084C518.79 504.028 519.2 503.607 519.077 503.365Z" fill="black" />
            <path d="M511.936 574.498C511.975 574.043 512.003 573.673 512.042 573.296C512.042 573.179 512.042 572.988 512.211 572.993C512.379 572.999 512.43 573.134 512.497 573.235C512.806 573.701 512.637 574.122 511.936 574.498Z" fill="black" />
            <path d="M538.527 497.768C538.824 498.189 538.611 498.661 538.667 499.11C538.078 498.672 538.049 498.346 538.527 497.768Z" fill="black" />
            <path d="M514.708 549.586C515.146 550.148 515.085 550.529 514.546 551.001C514.508 550.523 514.564 550.043 514.708 549.586Z" fill="black" />
            <path d="M517.353 527.132C517.308 527.205 517.285 527.295 517.223 527.34C517.162 527.385 517.105 527.34 517.111 527.261C517.079 527.03 517.116 526.793 517.218 526.582C517.409 526.745 517.24 526.964 517.353 527.132Z" fill="black" />
            <path d="M517.354 527.133C517.174 526.981 517.314 526.751 517.219 526.571C517.219 526.504 517.281 526.347 517.354 526.47C517.4 526.574 517.423 526.687 517.423 526.802C517.423 526.916 517.4 527.028 517.354 527.133Z" fill="black" />
            <path d="M257.745 368.174C257.707 368.168 257.671 368.153 257.639 368.132C257.607 368.11 257.58 368.083 257.56 368.05C255.578 365.08 253.567 362.194 251.619 359.409C249.671 356.624 247.65 353.727 245.662 350.74C245.622 350.682 245.605 350.61 245.617 350.54C245.628 350.469 245.666 350.407 245.724 350.364C248.368 348.286 250.289 345.427 251.215 342.194C252.242 338.534 251.653 336.439 251.265 335.047L251.215 334.867C251.195 334.798 251.204 334.723 251.239 334.659C251.273 334.595 251.331 334.547 251.4 334.525C255.213 333.29 259.092 331.717 262.849 330.246C266.605 328.775 270.502 327.192 274.336 325.951H274.421C274.472 325.953 274.522 325.966 274.567 325.99C274.602 326.012 274.632 326.04 274.655 326.074C274.678 326.108 274.694 326.146 274.701 326.187C275.981 333.737 275.285 341.49 272.68 348.691C269.8 356.482 264.68 363.247 257.964 368.134C257.916 368.172 257.856 368.192 257.795 368.191L257.745 368.174Z" fill="black" />
            <path d="M274.399 326.215C275.671 333.717 274.981 341.422 272.394 348.578C268.357 359.679 260.94 365.468 257.796 367.899C253.826 361.941 249.862 356.546 245.893 350.588C248.596 348.479 250.561 345.568 251.508 342.273C252.597 338.376 251.906 336.226 251.508 334.794C259.155 332.307 266.802 328.702 274.444 326.215M274.444 325.653C274.386 325.646 274.328 325.646 274.27 325.653C270.429 326.9 266.538 328.461 262.771 329.954C259.003 331.448 255.14 332.992 251.334 334.233C251.198 334.278 251.084 334.374 251.016 334.5C250.948 334.626 250.931 334.774 250.969 334.912L251.019 335.097C251.418 336.535 251.974 338.506 250.969 342.093C250.06 345.27 248.171 348.078 245.573 350.117C245.461 350.204 245.386 350.33 245.364 350.469C245.341 350.608 245.371 350.751 245.449 350.869C247.437 353.851 249.453 356.742 251.407 359.533C253.36 362.323 255.371 365.204 257.347 368.179C257.39 368.243 257.445 368.298 257.51 368.339C257.575 368.381 257.647 368.409 257.723 368.421H257.819C257.94 368.419 258.059 368.378 258.155 368.303C264.904 363.388 270.049 356.588 272.945 348.758C275.559 341.512 276.253 333.713 274.96 326.12C274.946 326.041 274.915 325.966 274.87 325.9C274.824 325.834 274.765 325.779 274.696 325.738C274.609 325.683 274.508 325.654 274.404 325.653H274.444Z" fill="black" />
            <path d="M500.358 287.558C500.284 287.549 500.216 287.512 500.169 287.455C500.122 287.397 500.099 287.323 500.106 287.249C500.572 282.1 500.611 276.699 500.667 271.477C500.723 266.255 500.734 260.809 501.229 255.655C501.233 255.592 501.259 255.533 501.302 255.487C501.345 255.441 501.402 255.411 501.464 255.402C504.435 254.97 507.893 254.695 511.234 254.425C514.575 254.156 518.011 253.864 520.964 253.454H521.004C521.042 253.455 521.079 253.463 521.114 253.478C521.149 253.494 521.18 253.516 521.206 253.544C521.233 253.572 521.253 253.604 521.266 253.64C521.279 253.676 521.286 253.713 521.284 253.751L519.415 289.017C519.412 289.056 519.401 289.093 519.384 289.126C519.367 289.16 519.343 289.19 519.314 289.214C519.264 289.258 519.2 289.282 519.134 289.281L500.358 287.558Z" fill="black" />
            <path d="M520.959 253.712L519.089 288.978L500.386 287.26C501.318 276.94 500.527 265.98 501.459 255.66C507.382 254.801 515.035 254.577 520.959 253.712ZM520.959 253.151H520.886C517.944 253.577 514.507 253.852 511.184 254.116C507.86 254.38 504.378 254.678 501.397 255.093C501.272 255.112 501.157 255.172 501.071 255.263C500.984 255.355 500.931 255.473 500.92 255.599C500.448 260.781 500.414 266.205 500.358 271.443C500.302 276.682 500.285 282.061 499.797 287.198C499.785 287.346 499.831 287.494 499.927 287.607C500.023 287.721 500.16 287.792 500.308 287.805L509.684 288.68L519.039 289.54H519.089C519.223 289.54 519.352 289.492 519.454 289.405C519.512 289.355 519.56 289.294 519.593 289.226C519.627 289.157 519.647 289.083 519.651 289.006L520.47 273.481L521.515 253.785C521.517 253.761 521.517 253.736 521.515 253.712C521.515 253.563 521.456 253.421 521.35 253.315C521.245 253.21 521.102 253.151 520.953 253.151H520.959Z" fill="black" />
        </svg>
    )
}