import * as API from 'apis/api';
import { UserSVG } from 'assets/sidebar/userSVG';
import { fetchToken, isUserAdmin, logOut } from "auth/auth";
import { Button } from 'components/button/Button';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';

export default function NavigationHeader({ userInfo, setUserInfo }) {

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        let token = fetchToken()

        token && API.getUserInfo(token).then((res) => {
            setUserInfo(res)
        }).catch(() => {
            logOut()
            setUserInfo(null)
        })
    }, [])

    window.addEventListener('click', function (e) {
        let popoutNode = document.querySelector('.user-popout')
        if (popoutNode) {
            if (e.target.className !== 'user-popout' && e.target.id !== 'user-popout' && popoutNode.style.visibility === 'visible') {
                popoutNode.style.visibility = 'hidden'
            }
        }

        if (e.target.id === 'overlay-loader') {
            document.getElementById('loader-reset-btn').click()
            e.target.style.display = 'none'
        }
        if (e.target.id === 'overlay-export') {
            document.getElementById('loader-reset-btn').click()
            e.target.style.display = 'none'
        }

    });

    const logoSRC = "https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/643eca2362c1b771f19be634_Frame%2015.png"

    return (<>
        {userInfo &&
            location.pathname !== '/user/account/verify' &&
            location.pathname !== '/login' &&
            location.pathname !== '/org-setup' &&
            location.pathname !== '/user/password/reset/confirm' &&
            <div className="header">
                <div className="header-container">
                    <button className="header-block-left !w-auto px-[30px]" onClick={() => {
                        navigate('/')
                    }}>
                        <img src={logoSRC} alt='myBiros' />
                    </button>

                    <div className="header-block-right gap-5 max-[550px]:!hidden">
                        <div>
                            <div id="user-popout" className=" cursor-pointer flex flex-row gap-2 max-[600px]:!hidden" onClick={() => { document.querySelector('.user-popout').style.visibility = 'visible' }}>
                                <div id="user-popout" className="border border-slate-200 p-2 rounded-md pointer-events-none">
                                    <UserSVG width={25} height={25} />
                                </div>

                                <div id="user-popout" className='flex flex-col items-start justify-center max-[950px]:!hidden' >
                                    <span id="user-popout" className={"text-clickable-base"}>{userInfo.email}</span>
                                </div>
                            </div>
                            {createPortal(
                                <div id="user-popout" className="user-popout" style={{ visibility: 'hidden' }}>
                                    <div id="user-popout" className="user-account-info">
                                        <div id="user-popout" className=' flex justify-center items-start !w-1/3'>
                                            <div className='w-full h-3/4 border rounded-xl'>
                                                <UserSVG width={'100%'} height={'100%'} />
                                            </div>
                                        </div>
                                        <div id="user-popout">
                                            <div id="user-popout" className="flex flex-col mx-auto mt-3 text-xl font-semibold gap-1 w-1/2">
                                                <span id="user-popout">{userInfo.name}</span>

                                                <div className=" flex flex-row justify-start w-full">
                                                    <div id="user-popout" className="flex 
                                                justify-center w-full items-center text-xs font-light bg-slate-200 border border-slate-500 rounded text-slate-500"
                                                    >
                                                        {isUserAdmin() === true ? 'Admin' : 'User'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-buttons-container">
                                        <div onClick={() => navigate('/user')}><span className='text-clickable-small'>User Profile</span></div>
                                        <div onClick={() => navigate('/plans')}><span className='text-clickable-small'>Plan</span></div>
                                    </div>
                                    <div id="user-popout" className="user-footer">
                                        <Button className="!transition-none h-8 w-2/3 text-sm hover:transform-none" action={() => {
                                            logOut()
                                            navigate('/login')
                                        }} inverted> Logout</Button>

                                    </div>
                                </div>, document.body

                            )}

                        </div>

                        <a href={"https://mybiros.com#form"} rel="noreferrer" target="_blank">
                            <Button rounded>
                                Contact Us
                            </Button>
                        </a>

                    </div>

                </div>
            </div >
        }</>)
}