import { useNotifications } from "components/notificationSystem/notificationContext";
import { useCallback, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useLoaderData, useLocation, useNavigate } from "react-router";
import * as API from '../../apis/api';

function UseCaseAnalytics({ usagePerMonth }) {

    const [plotData, setPlotData] = useState([
        ["Year-Month", "API Call"],
        ["Year-Month", 0],
        ["Year-Month", 0],
        ["Year-Month", 0],
        ["Year-Month", 0],
    ]
    )

    function plotDataHelper(quarter_usage) {
        let _plotData = [["Year-Month", "API Call"]]
        quarter_usage.forEach((monthUsage) => _plotData.push([monthUsage.key, monthUsage.counter]))
        return _plotData
    }

    useEffect(() => {
        usagePerMonth &&
            setPlotData(plotDataHelper(usagePerMonth))
    }, [usagePerMonth])

    const options = {
        isStacked: true,
        height: 325,
        legend: { position: "top", maxLines: 3 },
        vAxis: { minValue: 0 },
        colors: ["#3898ec", "#484dff"]
    };


    return (
        <div className="p-5">

            <h3 className="text-base font-normal text-gray-500"> Trend </h3>

            {plotData &&
                <Chart
                    chartType="AreaChart"
                    width="100%"
                    height="50%"
                    data={plotData}
                    options={options}
                />
            }

        </div>

    )
}

function CountCard({ counter }) {

    return (

        <div
            className="items-center justify-between p-4 rounded-lg sm:flex sm:p-6">
            <div className="w-full">
                <h3 className="text-base font-normal text-gray-500 mb-2">
                    Page Processed
                </h3>
                <span
                    className="text-2xl font-bold leading-none text-[#484dff] sm:text-3xl p-2"
                >
                    {counter}
                </span>
                <h3 className="text-base font-normal text-gray-500 mb-2 mt-4">Overall Usage</h3>
            </div>
        </div>
    )
}

function PlanCard({ useCasePlan }) {

    const navigate = useNavigate()

    const navigateToPlan = () => navigate("/plans")

    return (

        <div
            className="items-center justify-between p-4 rounded-lg">
            <div className="w-full">
                <h3 className="text-base font-normal text-gray-500  mb-2">
                    Use Case Plan
                </h3>
                <span
                    className="leading-none text-gray-600  p-2"
                >
                    <div>
                        {useCasePlan.premium_type === 0 ?
                            <p>This Use Case belong to a <span className={"text-[#484dff]"}>Free Plan</span></p>
                            :
                            <p>This Use Case belong to a <span className={"text-[#484dff]"}>Premium Plan</span></p>
                        }
                    </div>
                </span>

                <div onClick={() => navigateToPlan()}>
                    {// eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a href={""} className="text-base text-[#484dff] font-normal  mb-2 mt-4">
                            Manage your plan
                        </a>
                    }
                </div>

            </div>
        </div>
    )
}

export const UseCaseMonitorRoute = () => {
    const navigate = useNavigate()
    const loaderData = useLoaderData()
    const locationState = useLocation().state

    const [currentUseCase, setCurrentUseCase] = useState()
    const [useCaseMonitorPlan, setUseCaseMonitorPlan] = useState()
    const [useCaseMonthAnalytics, setUseCaseMonthAnalytics] = useState()

    const [navigateToDashboard, setNavigateToDashboard] = useState(false)


    useEffect(() => {
        if (navigateToDashboard) navigate('/', { replace: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateToDashboard])


    const notification = useNotifications()

    const initializeUseCase = useCallback(async () => {
        await API.getUseCase(loaderData['usecaseID']).then((res) => {
            setCurrentUseCase(res)
        }).catch((e) => {

            notification.addNotification({
                title: 'Use Case Error',
                message: "Can't find selected Use Case",
                severity: 'ERROR',
                timeout: 3000
            })

            setNavigateToDashboard(true)
        })
    }, [loaderData, navigate])

    useEffect(() => {
        locationState ? setCurrentUseCase(locationState) : initializeUseCase()
    }, [locationState, initializeUseCase])


    const initializeMonitor = async () => {
        await API.getMonitorUseCasePlan(currentUseCase.public_id).then((res) => {
            setUseCaseMonitorPlan(res)
        }).catch(e => {
            notification.addNotification({
                title: 'Plan Error',
                message: "Can't find your plan info",
                severity: 'ERROR',
                timeout: 3000
            })

            setNavigateToDashboard(true)
        })

    }

    useEffect(() => {
        currentUseCase && !useCaseMonitorPlan && initializeMonitor()
    }, [currentUseCase, useCaseMonitorPlan])


    const initializeAnalytics = async () => {
        await API.getMonitorUseCaseAnalytics(loaderData['usecaseID']).then((res) => {
            setUseCaseMonthAnalytics(res)
        }).catch((e) => {
            notification.addNotification({
                title: 'Plan Error',
                message: "Can't find your plan info",
                severity: 'ERROR',
                timeout: 3000
            })
            setNavigateToDashboard(true)
        })

    }

    useEffect(() => {
        useCaseMonitorPlan && !useCaseMonthAnalytics && initializeAnalytics()
    }, [useCaseMonitorPlan, useCaseMonthAnalytics, loaderData])


    return (
        <div className="product-bg">
            {currentUseCase &&
                <div className="dashboard-container max-xl:px-5">
                    <div className="dashboard-head">
                        <div className="page-header">
                            <div className="title">{currentUseCase.name}</div>
                        </div>
                    </div>
                    <div className="dashboard-body">
                        <div className="h-full w-full flex flex-col gap-10">
                            <div className="w-full flex flex-row justify-center gap-10 flex-1">
                                <div className="bg-white h-[250px] w-2/3 rounded shadow-md">
                                    {useCaseMonitorPlan && <CountCard counter={useCaseMonitorPlan.plan_usage.usage} />}
                                </div>
                                <div className="bg-white  h-[250px] w-1/3  rounded shadow-md">
                                    {useCaseMonitorPlan && <PlanCard useCasePlan={useCaseMonitorPlan.plan} />}
                                </div>
                            </div>
                            <div className="bg-slate-200 w-full flex flex-row justify-center gap-10">
                                <div className="bg-white h-[400px] w-full rounded shadow-md">
                                    {useCaseMonthAnalytics && <UseCaseAnalytics usagePerMonth={useCaseMonthAnalytics} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}