import * as API from "apis/api";
import { AxiosResponse } from "axios";
import { Button } from "components/button/Button";
import { useNotifications } from "components/notificationSystem/notificationContext";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router";
import { ClipboardSVG } from "../../assets/apikey/ClipboardSVG";
import { Toast } from "../../helpers/toast";
import { ApiKeyPillNew } from "./components/apiKeyPillNew";
import { NewApiKeyModal } from "./components/newApiKeyModal";

type Dict = {
  [key: string]: any;
};

type APIKey = {
  name: string;
  created_at: string;
  token: string;
};

const bgGray = "#F2F2F2";
const dargGray = "#C6C6C6";

const APIDocUrl =
  "https://mybiros.notion.site/myBiros-Platform-Documentation-33609e71b4da418b8751981e5e34e4aa";

export const ApiKeysRoute = () => {
  const loaderData = useLoaderData() as Dict;
  const navigate = useNavigate();
  const [currentUseCase, setCurrentUseCase] = useState<Dict | null>(null);
  const [resourceTokens, setResourceTokens] = useState<Array<APIKey> | null>(
    null
  );
  const [showAPIKey, setShowAPIKey] = useState<number>(-1);
  const [showNewApiKeyModal, setShowApiKeyModal] = useState<boolean>(false);

  const notification = useNotifications();

  const [navigateToDashboard, setNavigateToDashboard] = useState(false);
  useEffect(() => {
    if (navigateToDashboard) navigate("/", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateToDashboard]);

  const initializeUseCase = async () => {
    await API.getUseCase(loaderData["usecaseID"])
      .then((res) => {
        setCurrentUseCase(res);
      })
      .catch((e) => {
        notification.addNotification({
          title: "UseCase Error",
          message: "Can't find selected Use Case",
          severity: "ERROR",
          timeout: 3000,
        });
        setNavigateToDashboard(true);
      });
  };

  const initializeResourceTokens = async () => {
    await API.getServiceTokens(loaderData["usecaseID"])
      .then((res) => {
        setResourceTokens(res.data["tokens"]);
      })
      .catch((e) => {
        notification.addNotification({
          title: "Fetch API Keys Error",
          message: "Error fetching your created API Keys for this use case",
          severity: "ERROR",
          timeout: 3000,
        });
        setNavigateToDashboard(true);
      });
  };

  useEffect(() => {
    if (currentUseCase) {
      !resourceTokens && initializeResourceTokens();
    } else {
      initializeUseCase();
    }
  }, [currentUseCase]);

  const deleteApiPill = (index: number) => {
    let newResourceToken = resourceTokens!.filter((x, i) => i !== index);
    setResourceTokens(newResourceToken);
  };

  const deleteAPIKey = async (index: number) => {
    await API.deleteServiceToken(
      currentUseCase!.public_id,
      resourceTokens![index].token
    )
      .then((res) => {
        let pillToDelete = document.getElementById(`api-${index}-delete`);
        if (pillToDelete) {
          pillToDelete.style.animation = "deleteApiKey 2s ease-in-out";
        } else {
          deleteApiPill(index);
        }
      })
      .catch((e) => {
        notification.addNotification({
          title: "Delete API Key Error",
          message: "Error deleting API Key for this use case",
          timeout: 3000,
          severity: "ERROR",
        });
      });
  };

  const afterCreateCallback = (res: AxiosResponse<any, any>) => {
    let newApiKey: APIKey = {
      name: res.data["name"],
      created_at: res.data["created_at"],
      token: res.data["token"],
    };
    let newResourceToken = resourceTokens!.concat(newApiKey);
    setResourceTokens(newResourceToken);
  };

  return (
    <div className="product-bg default-page-layout relative">
      {currentUseCase && showNewApiKeyModal && (
        <NewApiKeyModal
          setShowNewApiKeyModal={setShowApiKeyModal}
          usecaseID={currentUseCase.public_id}
          afterCreateCallback={afterCreateCallback}
        />
      )}
      <div className="default-page-container !max-w-[80%] max-xl:px-5">
        <div className="dashboard-head">
          <div className="page-header w-full !flex-col !items-start">
            {currentUseCase && (
              <div className="text-page-title ">{currentUseCase!.name} </div>
            )}
            <div className="w-full flex flex-row justify-between">
              <div className="text-subtitle ">API Keys </div>
            </div>
          </div>
        </div>
        <div className="h-[80%] gap-5">
          <div className="flex flex-col xl:flex-row w-full justify-between mb-5  gap-2 xl:gap-10">
            <div className="w-full xl:w-1/2 flex flex-col gap-3 grow items-start">
              <div>
                <span className="text-normal-small text-primary-color">
                  API Service ID
                </span>
              </div>
              <div className={"flex flex-col gap-2"}>
                <div className={"flex flex-row gap-1"}>
                  <div className="bg-[#f4f7fc] font-mono text-primary-color h-8 !overflow-x-auto !overflow-y-hidden border rounded-myb text-xs flex flex-nowrap whitespace-nowrap items-center px-3">
                    {currentUseCase && currentUseCase.public_id}
                  </div>

                  {currentUseCase && (
                    <Toast mode="click" text="Copied!">
                      <div
                        className="border bg-[#f4f7fc] rounded-myb w-8 h-8 p-1 cursor-pointer select-none flex justify-center hover:bg-blue-100 hover:border-blue-500 active:bg-blue-100 !transition-all !duration-75"
                        onClick={async () => {
                          try {
                            navigator.clipboard.writeText(
                              currentUseCase.public_id
                            );
                          } catch (e) {
                            copy(currentUseCase.public_id);
                          }
                        }}
                      >
                        <ClipboardSVG width={20} height={20} />
                      </div>
                    </Toast>
                  )}
                </div>

                <span className="text-xs text-slate-500">
                  Use this ID to specify your service in myBiros API requests.{" "}
                </span>
              </div>
            </div>
            <div className="w-full xl:w-fit flex flex-row justify-end xl:justify-end gap-10">
              <Button
                className="!h-10"
                action={() => {
                  window.open(APIDocUrl);
                }}
              >
                API Documentation{" "}
              </Button>
              <Button
                className="!h-10"
                action={() => {
                  setShowApiKeyModal(true);
                }}
                inverted
              >
                Create API Key
              </Button>
            </div>
          </div>

          <div className="h-[70%] !max-h-[500px] w-full flex flex-col gap-2">
            <div className="flex flex-col h-full w-full rounded-myb gap-2 mt-5">
              <div className="w-full h-full flex gap-2 flex-col overflow-y-auto">
                {resourceTokens?.map((apiKey, index) => (
                  <ApiKeyPillNew
                    apiKey={apiKey}
                    index={index}
                    showAPIKey={showAPIKey}
                    setShowAPIKey={setShowAPIKey}
                    deleteAPIKey={deleteAPIKey}
                    deleteApiPill={deleteApiPill}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
