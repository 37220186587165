import { useEffect, useRef } from "react";

type Props = {
    children: JSX.Element,
    mode: 'hover' | 'click',
    text: string,
}

export function Toast({ children, mode, text }: Props) {

    const childrenRef = useRef<HTMLDivElement | null>(null)
    const toastRef = useRef<HTMLDivElement | null>(null)
    let timer: NodeJS.Timeout;

    useEffect(() => {
        if (childrenRef.current === null || toastRef.current === null) return;
    }, [childrenRef])

    const showToast = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, toast: HTMLDivElement) => {
        let y = e.currentTarget.getBoundingClientRect().y - e.currentTarget.offsetHeight - 10
        let x = e.currentTarget.getBoundingClientRect().x - toast.offsetWidth / 2 + e.currentTarget.offsetWidth / 2
        toast.style.top = `${y.toString()}px`;
        toast.style.left = `${x.toString()}px`;
        toast.style.opacity = '100%';
    }

    const hideToast = (toast: HTMLDivElement | null) => {
        clearTimeout(timer)
        if (toast === null) return;
        toast.style.opacity = '0%';
    }

    return (<>
        <div ref={toastRef} className="fixed w-fit h-10 bg-white boorer-1-[#000] text-primary-color z-50 pointer-events-none flex items-center justify-center p-2 rounded-myb" style={{
            opacity: '0'
        }}>
            {text}
        </div>
        <div ref={childrenRef} onMouseEnter={(e) => {
            if (mode !== 'hover') return;
            if (toastRef.current === null) return;

            showToast(e, toastRef.current)

        }} onMouseLeave={() => {
            if (mode !== 'hover') return;
            if (toastRef.current === null) return;
            hideToast(toastRef.current)
        }}

            onMouseDown={(e) => {
                if (mode !== 'click') return;
                if (toastRef.current === null) return;

                hideToast(toastRef.current)
                showToast(e, toastRef.current)
                timer = setTimeout(() => hideToast(toastRef.current), 1000)
            }}>
            {children}
        </div>
    </>
    )
}