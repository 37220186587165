import React from "react";
import { useNavigate } from "react-router-dom";

export const ErrorRoute = () => {

    const [seconds, setSeconds] = React.useState(5)
    const navigate = useNavigate()

    const timeout = React.useRef()
    const refSeconds = React.useRef()

    React.useEffect(() => {
        const updateTimer = () => {
            if (refSeconds.current <= 1) {
                clearInterval(timeout.current)
                navigate('/', { replace: true })
            }
            refSeconds.current -= 1
            setSeconds(refSeconds.current)
        }
        refSeconds.current = 5
        timeout.current = setInterval(() => { updateTimer() }, 1000)
        return () => clearInterval(timeout.current)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    return (
        <div className=" w-full h-screen flex items-center justify-center">
            <div className='w-1/2 h-1/2 flex flex-col items-center'>
                <span className=" font-bold text-9xl text-primary-color"> 404</span>
                <span className="font-bold text-2xl text-primary-color"> Page not found </span>
                <span className="font-bold text-xl text-primary-color"> Redirect in {seconds}... </span>
            </div>
        </div>)
}