
export const Button = ({ children, inverted = false, className = '', type = 'button', action = () => { }, disabled = false, rounded = false }) => {

    let defaultButtonClass = 'rounded-myb p-2 scale-100 shadow-none transition-all duration-300 flex justify-center items-center w-fit border-2 border-primary-color text-primary-color font-semibold min-w-[180px] hover:transform-gpu !transition-all !duration-300 hover:translate-x-0 hover:-translate-y-[2px] hover:translate-z-[10px] hover:scale-x-[1.01] hover:scale-y-[1.01] hover:scale-z-[2]'

    let classNames = [defaultButtonClass, inverted ? 'bg-primary-color text-white active:bg-white active:text-primary-color' : 'bg-white text-primary-color active:bg-primary-color active:text-white', rounded ? '!rounded-full' : '', className]

    return (
        type !== 'recaptcha' ?
            <button className={classNames.join(' ')} type={type} onClick={() => action()} disabled={disabled}>
                {children}
            </button>
            :
            <>
                <button
                    type="submit"
                    class={'g-recaptcha ' + classNames.join(' ')}
                    data-sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    data-callback='onSubmit'
                    data-action='submit' disabled={disabled}>{children}

                </button>
            </>
    )
}