import { useEffect, useState } from "react";
import { Image } from "react-konva";

const ImageFromUrl = ({
  imageUrl,
  initializeImageSize,
  setIMGLoaded
}) => {
  const [image, setImage] = useState(null);


  useEffect(() => {
    setIMGLoaded(false)
    const imageToLoad = new window.Image();
    imageToLoad.src = imageUrl;
    imageToLoad.addEventListener("load", () => {
      setImage(imageToLoad);
      setIMGLoaded(true)
    });
    return () => imageToLoad.removeEventListener("load", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  useEffect(() => {
    if (!image) return;
    initializeImageSize()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  return (
    <Image name="canvas-image" image={image} />
  );
};

export default ImageFromUrl;
