import { Button } from "components/button/Button";
import { useState } from "react";
type Props = {
  labels: Array<any>;
  backCallback: () => void;
  saveSelectedLabels: (labels: Array<any>) => void;
};

export const SubRequiredFields = ({
  labels,
  backCallback,
  saveSelectedLabels,
}: Props) => {
  const [selectedLabels, setSelectedLabels] = useState<Array<any>>([]);

  const handleLabel = (label: Array<any>) => {
    let newSelectedLabels = selectedLabels;
    if (checkLabel(label)) {
      newSelectedLabels = newSelectedLabels.filter((x) => x[0] !== label[0]);
    } else {
      newSelectedLabels = [...newSelectedLabels, label];
    }
    setSelectedLabels(newSelectedLabels);
  };
  const checkLabel = (label: Array<any>) => {
    return selectedLabels.find((x) => x[0] === label[0]);
  };

  const handleBackBtn = () => {
    setSelectedLabels([]);
    backCallback();
  };

  return (
    <div className="relative h-full w-full modal-grid !grow">
      <div className="w-full flex flex-row items-center justify-center ">
        <span className="text-subtitle-small">
          Toggle the mandatory labels of your use case.
        </span>
      </div>
      <div className="relative w-full flex h-[90%] !min-h-0 items-start justify-center gap-5 m-2">
        <div className="relative w-max min-w-[400px] h-full bg-white shadow rounded-myb flex flex-col gap-2 px-2 py-2 !overflow-y-scroll">
          {labels.map((x) => (
            <div
              className="text-lg py-1 px-2 flex justify-between items-center shadow rounded-myb"
              onClick={() => {
                handleLabel(x);
              }}
            >
              <span> {x[1].tag_alias}</span>
              <div className="relative w-[3.5rem] h-7 bg-slate-100 rounded-full flex items-center px-1 shadow-inner ">
                <div
                  className="absolute w-5 h-5 rounded-full !transition-all !duration-300 !ease-out shadow-md"
                  style={{
                    left: checkLabel(x) ? "60%" : "10%",
                    backgroundColor: checkLabel(x)
                      ? "var(--main-myb-color)"
                      : "rgb(148 163 184)",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-5">
        <Button
          action={() => {
            handleBackBtn();
          }}
          rounded
        >
          Go Back
        </Button>
        <Button
          disabled={selectedLabels.length === 0}
          action={() => {
            saveSelectedLabels(selectedLabels);
          }}
          rounded
        >
          Next
        </Button>
      </div>
    </div>
  );
};
