export const Loading = () => {
    return (<>
        <svg viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
            <circle class="spin" cx="400" cy="400" fill="none"
                r="300" stroke-width="100" stroke="#9a85cf"
                stroke-dasharray="500 1400"
                stroke-linecap="round" />
        </svg>
    </>
    )
}