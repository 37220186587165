import * as API from "apis/api";
import { AxiosResponse } from "axios";
import { Button } from "components/button/Button";
import { useNotifications } from "components/notificationSystem/notificationContext";
import { useState } from "react";

type Props = {
  setShowNewApiKeyModal: React.Dispatch<React.SetStateAction<boolean>>;
  usecaseID: string;
  afterCreateCallback: (result: AxiosResponse<any, any>) => void;
};

export const NewApiKeyModal = ({
  setShowNewApiKeyModal,
  usecaseID,
  afterCreateCallback,
}: Props) => {
  const closePanel = () => {
    setShowNewApiKeyModal(false);
  };

  const [name, setName] = useState<string>("");

  const notification = useNotifications();

  const createNewApiKey = async () => {
    await API.createServiceToken(usecaseID, name)
      .then((res) => {
        afterCreateCallback(res);
        closePanel();
      })
      .catch((e) => {
        notification.addNotification({
          title: "Create new API Key Error",
          message: "Error creating a new API Key for this use case",
          severity: "ERROR",
          timeout: 3000,
        });
      });
  };

  return (
    <div className="absolute flex items-center justify-center bg-black w-full !z-20 h-full !pointer-events-default bg-opacity-10">
      <div className="h-[fit] min-h-[400px] !max-h-[50%] w-fit min-w-[500px] flex flex-col gap-2 ">
        <div className=" bg-white shadow-md flex flex-col h-full w-full border px-10 rounded-myb gap-2 p-10">
          <span className="text-primary-color text-subtitle-small w-full flex items-center justify-center mb-5">
            Choose a name for your new API Key
          </span>

          <div className="w-full h-full flex gap-10 flex-col !overflow-hidden">
            <div className="input-container">
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className="flex flex-row justify-between">
              <Button action={closePanel} rounded>
                Close
              </Button>
              <Button
                action={createNewApiKey}
                disabled={name === ""}
                inverted
                rounded
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
