import { useNavigate } from "react-router";
import CatalogList from "components/catalogList/catalogList";

export const CreateUseCaseFirstStep = ({ products, searchParams, setSearchParams }) => {

    const navigate = useNavigate()

    const highlightSelectedProduct = (product) => {
        document.querySelectorAll("div.catalog-item").forEach((el, index) => {
            if (el.id === product.name)
                el.children[0].style.backgroundColor = 'rgba(216,216,241, 0.6)'
            else el.children[0].style.backgroundColor = 'white'

        })
    }

    const onClickFallback = (product) => {
        var url = new URL(window.location.href);
        searchParams.set('product', product.name)
        navigate({ pathname: url.pathname, search: '?' + searchParams.toString() }, { replace: false })
        highlightSelectedProduct(product)
    }

    return (
        <div className="product-bg default-page-layout">
            <div className="default-page-container">
                <div className="dashboard-head">
                    <div className="page-header !flex-col !items-start mb-4">
                        <div className="text-page-title">Product Catalog</div>
                        <div className="text-subtitle">Choose a product and start using myBiros! </div>
                        <div className="text-subtitle">Missing Something?
                            <a className={"ml-1"} href={"https://mybiros.com#form"} rel="noreferrer" target="_blank">
                                <span className="font-semibold">Contact Us!</span>
                            </a>
                        </div>

                    </div>

                </div>
                <div className="px-2 py-2 bg-white items-center !font-[Inter,_sans-serif] text-slate-300 h-full !min-h-[360px] !max-h-[65vh] border border-gray-200 rounded-lg shadow-sm">
                    {products && products.length > 0 &&
                        <CatalogList products={products} select={true} onClickFallback={onClickFallback} />
                    }
                </div>
            </div>
        </div>
    )
}