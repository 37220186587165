import * as API from "apis/api";
import { LeftButtonSVG } from "assets/canvas/LeftButtonSVG";
import { RightButtonSVG } from "assets/canvas/RightButtonSVG";
interface Dict {
  [key: string]: any;
}

type PanelProps = {
  revisionMapping: Dict;
  currentDoc: Dict;
  docList: Array<string>;
  pageIndex: number;
  lastPage: number;
  currentPage: number;
  useCaseID: string;
  navigateTo: (src: string, replace?: boolean, options?: Dict) => void;
  searchParams: URLSearchParams;
};

export const DocControlPanel = ({
  revisionMapping,
  currentDoc,
  docList,
  pageIndex,
  lastPage,
  currentPage,
  useCaseID,
  navigateTo,
  searchParams,
}: PanelProps) => {
  const textStatus: Dict = {
    approved: "Approved",
    to_review: "To Review",
    not_approved: "Rejected",
    pending: "New",
  };

  const fetchLastOfPreviousPage = async () => {
    const previousList = await API.listAllDocuments(
      useCaseID,
      searchParams.get("revision") === "All"
        ? ""
        : searchParams.get("revision"),
      currentPage - 1,
      10
    );

    return previousList["items_data"][9];
  };

  const fetchFirstOfNextPage = async () => {
    const previousList = await API.listAllDocuments(
      useCaseID,
      searchParams.get("revision") === "All"
        ? ""
        : searchParams.get("revision"),
      currentPage + 1,
      10
    );

    return previousList["items_data"][0];
  };

  return (
    <div className="w-full h-11 bg-white rounded-myb flex items-center">
      <div className="relative w-full grid grid-cols-[50px,auto,50px]">
        <div className="flex items-start ps-2">
          <button
            className="text-white mt-1 w-8 h-8 bg-slate-100 border
                        border-primary-color rounded-myb flex items-center justify-center"
            disabled={pageIndex === 0 && currentPage === 0}
            onClick={async () => {
              if (pageIndex === 0) {
                let pageToNavigate = await fetchLastOfPreviousPage();
                searchParams.set("page", String(currentPage - 1));
                searchParams.delete("file");
                navigateTo(
                  `/usecase/${useCaseID}/result/${
                    pageToNavigate.public_id
                  }?${searchParams.toString()}`,
                  false
                );
              } else {
                let prevDocIndex = pageIndex - 1;
                let prevDoc = docList[prevDocIndex];
                searchParams.delete("file");
                navigateTo(
                  `/usecase/${useCaseID}/result/${prevDoc}?${searchParams.toString()}`,
                  false
                );
              }
            }}
          >
            <LeftButtonSVG />
          </button>
        </div>
        <div className="relative w-full px-2 flex items-center justify-between">
          <div className="max-w-[250px] h-10 flex items-center overflow-x-auto whitespace-nowrap">
              {currentDoc.document_metadata.original_name}
          
          </div>
          <div
            id="doc-revision-status"
            className={`revision-status-pill !w-[100px] bg-opacity-30`}
            style={{
              backgroundColor:
                revisionMapping[currentDoc.revision_status].color,
              border: `1px solid ${
                revisionMapping[currentDoc.revision_status].border
              }`,
              color: revisionMapping[currentDoc.revision_status].text,
            }}
          >
            {textStatus[currentDoc.revision_status]}
          </div>
        </div>
        <div className="flex justify-end pe-2">
          <button
            className="text-white mt-1 w-8 h-8 bg-slate-100 border 
                        border-primary-color rounded-myb flex items-center justify-center"
            disabled={
              pageIndex === docList.length - 1 && currentPage === lastPage
            }
            onClick={async () => {
              if (pageIndex === 9) {
                let pageToNavigate = await fetchFirstOfNextPage();
                searchParams.set("page", String(currentPage + 1));
                searchParams.delete("file");
                navigateTo(
                  `/usecase/${useCaseID}/result/${
                    pageToNavigate.public_id
                  }?${searchParams.toString()}`,
                  false
                );
              } else {
                let nextDocIndex = pageIndex + 1;
                let nextDoc = docList[nextDocIndex];
                searchParams.delete("file");
                navigateTo(
                  `/usecase/${useCaseID}/result/${nextDoc}?${searchParams.toString()}`,

                  false
                );
              }
            }}
          >
            <div>
              <RightButtonSVG />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
