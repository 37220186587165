import { AddSimpleAnnotationSVG } from "assets/canvas/AddSimpleAnnotationSVG";
import { Button } from "components/button/Button";
import { ResultPill } from "components/resultPill/resultPill";
import { useEffect, useState } from "react";
import { CreateSimpleEntityPanel } from "./createSimpleEntityPanel";
type Dict = {
  [key: string]: any;
};
type Props = {
  labels: Dict | null;
  inference: Dict;
  unusedLabels: Array<any>;
  handleEditField: (
    label: string,
    newField: string,
    value_index?: number
  ) => {};
  setDefaultAddLabel: React.Dispatch<React.SetStateAction<string>>;
  setAddSimpleAnnotationMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddNewAnnotation: (entity: any) => Promise<void>;
  handleEditAnnotation: (entity: any) => Promise<void>;
  handleDeleteAnnotation: (entity: any) => Promise<void>;
  setEditSimpleAnnotation: React.Dispatch<React.SetStateAction<any>>;
};
export const SimpleAnnotationPanel = ({
  labels,
  inference,
  unusedLabels,
  setDefaultAddLabel,
  setAddSimpleAnnotationMode,
  handleAddNewAnnotation,
  handleEditAnnotation,
  handleDeleteAnnotation,
  setEditSimpleAnnotation,
}: Props) => {
  const [createMode, setCreateMode] = useState(false);

  const [editField, setEditField] = useState<{
    label: string;
    tag_alias: string;
    'bounding-boxes': any;
    id: number;
    text: string;
  } | null>(null);

  if (!labels || !inference) return <></>;

  return (
    <>
      {createMode || editField ? (
        <CreateSimpleEntityPanel
          unusedLabels={unusedLabels}
          setCreateMode={setCreateMode}
          createMode={createMode}
          setAddSimpleAnnotationMode={setAddSimpleAnnotationMode}
          handleAddNewAnnotation={handleAddNewAnnotation}
          handleEditAnnotation={handleEditAnnotation}
          handleDeleteAnnotation={handleDeleteAnnotation}
          editField={editField}
          setEditField={setEditField}
          setEditSimpleAnnotation={setEditSimpleAnnotation}
        />
      ) : (
        <Button
          className="!h-10 w-full rounded-myb border-2 !border-[#EAEBFB] flex items-center justify-center cursor-pointer gap-2"
          action={() => setCreateMode(true)}
        >
          Add field{""}
          <AddSimpleAnnotationSVG width={25} height={25} />
        </Button>
      )}

      <div className=" h-full px-1 flex flex-col overflow-auto w-full gap-5">
        {Object.keys(labels).map(
          (label, index) =>
            label in inference["entities"] &&
            (!Array.isArray(inference["entities"][label]) ? (
              <>
                {inference["entities"][label].id !== editField?.id && (
                  <div className="relative flex flex-row w-full items-center">
                    <div
                      id={`anchor-${label}`}
                      className="absolute w-1 h-1 top-1/2"
                    />
                    <ResultPill
                      setCreateMode={setCreateMode}
                      label={label}
                      labelKey={labels[label].tag_alias}
                      labelValue={inference["entities"][label].text}
                      labelId={inference["entities"][label].id}
                      confidence={inference["entities"][label].confidence}
                      bBox={inference['entities'][label].bounding_boxes}
                      setDefaultAddLabel={setDefaultAddLabel}
                      setEditField={setEditField}
                    />
                  </div>
                )}
              </>
            ) : inference["entities"][label].length > 0 ? (
              inference["entities"][label].map(
                (x: Dict, index: number) =>
                  x.id !== editField?.id && (
                    <div className="relative flex flex-row w-full items-center">
                      <div
                        id={`anchor-${label}`}
                        className="absolute w-1 h-1 top-1/2"
                      />
                      <ResultPill
                        setCreateMode={setCreateMode}
                        label={label}
                        labelKey={labels[label].tag_alias}
                        labelValue={x.text}
                        labelId={x.id}
                        confidence={x.confidence}
                        bBox={x.bounding_boxes}
                        setDefaultAddLabel={setDefaultAddLabel}
                        setEditField={setEditField}
                      />
                    </div>
                  )
              )
            ) : (
              <ResultPill
                setCreateMode={setCreateMode}
                label={label}
                labelKey={labels[label].tag_alias}
                labelValue={null}
                labelId={-1}
                confidence={null}
                bBox={null}
                setDefaultAddLabel={setDefaultAddLabel}
                setEditField={setEditField}
              />
            ))
        )}
      </div>
    </>
  );
};
