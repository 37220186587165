export const CustomersCarousel = () => {

    const customers = [
        <img loading="eager" src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/646f86e163cc9bbd5be79bc4_Logo-IBANWAY-Registrato-SF.png" alt="IbanWay" />,

        <img loading="eager" src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/646f86e1532f9690437a3582_AlanAdvantage.png" alt="AlanAdvantage" />,

        <img loading="eager" sizes="130px" srcset="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/646f86e0ce98c9eacf012c3a_image_2-Oct-28-2022-12-12-22-5036-PM-removebg-preview.png" alt="Freekar" />,

        <img loading="eager" alt="CompareTech" src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/646f86e083e73d0d2c23ccf8_Logo_default.png" />,

        <img loading="eager" alt="Automyo" class="item" src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/646f86e062533ab7383752e2_Automyo.png" />,

        <img src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/646f86e0318f49709f27ba7e_elba_v1_color-scaled-removebg-preview.png" alt="Elba Assicurazioni" />,

        <img src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/646f86e0bf48fa8f1d40de6b_image.png" alt="Stip" />,

        <img src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/646f86e004d458adc61798e6_CGK.png" alt="CGK Solutions" />,

        <img src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/64751561490ec1183ba0f116_image001%201.png" alt="Gestione dinamica" />,

        <img src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/64751579ed4e2e778d573fb4_image_2-removebg-preview.png" alt="Frontiere" />,

        <img src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/65426db944ad3226f781c567_Italsoft%202.png" alt="Italsoft" />,
    ]

    return (
        <div class="slider">
            <div class="slide-track">
                {
                    customers.map(c => <div class="slide">{c}</div>)
                }
                {
                    customers.map(c => <div class="slide">{c}</div>)
                }
            </div>
        </div>
    )
}