import { useNotifications } from "components/notificationSystem/notificationContext";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import * as API from "../../apis/api";
import { CreateUseCaseFirstStep } from "./panels/firstStep";
import { CreateUseCaseSecondStep } from "./panels/seondStep";

export function CreateUseCase({ setLoadingData, setNavigateAfterLoad, loadingData, analytics }) {

    const navigate = useNavigate()

    const [products, setProducts] = useState(null)

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()

    const [nUseCases, setNUseCases] = useState(0)

    const notification = useNotifications()

    const [navigateToDashboard, setNavigateToDashboard] = useState(false)

    useEffect(() => {
        if (navigateToDashboard) navigate('/', { replace: true });
    }, [navigateToDashboard])

    useEffect(() => {
        const fetchModels = async () => {
            await API.listAllModels().then((res) => {
                setProducts(res['items_data'])
            }).catch(() => {
                notification.addNotification({
                    title: 'Error',
                    message: "Can't create a new use case at the moment. Try later.",
                    severity: "ERROR",
                    timeout: 3000
                });

                setNavigateToDashboard(true)

            });

        }

        !products && fetchModels()
    }, [products])

    const [selectedProduct, setSelectedProduct] = useState()

    const initializePage = useCallback(() => {
        products ?
            setSelectedProduct(products.find(x => x['name'] === searchParams.get('product')))
            :
            navigate('/create-usecase', { replace: true })
    }, [searchParams, navigate, products])

    useEffect(() => {
        initializePage()

    }, [initializePage])

    useEffect(() => {
        const fetchUserUseCases = async () => {
            await API.listUserUseCases().then((res) => {
                setNUseCases(res.total)
            })
        }
        fetchUserUseCases()
    }, [])

    return selectedProduct ?
        <CreateUseCaseSecondStep selectedProduct={selectedProduct} nUseCases={nUseCases} loadingData={loadingData} setLoadingData={setLoadingData} setNavigateAfterLoad={setNavigateAfterLoad} analytics={analytics} />
        :
        <CreateUseCaseFirstStep products={products} searchParams={searchParams} setSearchParams={setSearchParams} />
}