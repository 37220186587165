import CatalogItem from "../catalogItem/catalogItem";

export default function CatalogList({ products, select = false, onClickFallback }) {

    return (
        <div role="list" className="relative h-full overflow-y-auto grid max-[950px]:!grid-cols-1 max-[1370px]:!grid-cols-2 !grid-cols-3 w-full justify-between gap-8">
            {products.map((product, index) => (
                <CatalogItem key={product.name} item={product} select={select} fallback={onClickFallback} type="MODEL"/>
            ))}
        </div>

    )
}