export const FilterPill = ({ children, filterItem, filterSelected, callbackAction }) => {


    return (
        <div className='relative h-fit py-1 w-fit px-4 flex justify-center items-center rounded-xl cursor-default hover:bg-opacity-[.5] !transition-all duration-100 ease-out'
            style={{
                background: filterSelected === filterItem ? '#1a3066' : '#f4f7fc',
                fontWeight: filterSelected === filterItem ? '700' : '',
                color: filterSelected === filterItem ? 'white' : '#646669',
            }}
            onClick={() => {
                filterSelected !== filterItem && callbackAction(filterItem)
            }}>
            {children}
        </div>
    )
}