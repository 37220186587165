import eyeClosed from 'assets/form/eyeClosed.svg';
import eyeOpened from 'assets/form/eyeOpened.svg';
import React from "react";
import { Button } from 'components/button/Button';

export const RegisterPanel = ({ registerCallback, setCurrentPanel }) => {
    const [showPassword, setShowPassword] = React.useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [name, setName] = React.useState('')
    const [surname, setSurname] = React.useState('')
    const role = 'Unknown'
    const phone = ''

    React.useEffect(() => {
        let GoogleScript = document.createElement('script')
        GoogleScript.src = `https://www.google.com/recaptcha/api.js`
        document.body.appendChild(GoogleScript)
    }, [])

    function onSubmit(token) {

        let check = true;
        if (checkEmailInput()) {
            document.getElementById('email-input').style.outline = 'solid 2px red'
            check = false;
        }

        if (checkPasswordInput()) {
            document.getElementById('password-input').style.outline = 'solid 2px red'
            document.getElementById('confirmPassword-input').style.outline = 'solid 2px red'
            check = false;
        }

        if (checkConfirmPasswordInput()) {
            document.getElementById('confirmPassword-input').style.outline = 'solid 2px red'
            check = false;
        }

        if (checkNameInput()) {
            document.getElementById('name-input').style.outline = 'solid 2px red'
            check = false;
        }

        if (checkSurnameInput()) {
            document.getElementById('surname-input').style.outline = 'solid 2px red'
            check = false;
        }

        if (!document.getElementById('terms-checkbox').checked) check = false


        if (check)
            registerCallback(email, password, name, surname, role, phone, token)

    }

    window.onSubmit = onSubmit


    const checkEmailInput = (e) => {
        if (!document.getElementById('email-input')) return true;
        let value = e ? e.value : document.getElementById('email-input').value;
        if (value === '') {
            document.getElementById('email-required').textContent = ''
            return true;
        }

        // eslint-disable-next-line no-empty-character-class
        let emailREGEXP = /^[a-zA-Z0-9]([.\-_]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]([.\-_]?[a-zA-Z0-9]+)*\.[a-zA-Z]+$/g

        if (!value.match(emailREGEXP)) {
            document.getElementById('email-required').textContent = 'Invalid email'
            return true;
        }

        if (value.length < 5) {
            document.getElementById('email-required').textContent = 'Email too short! Min 5 characters length'
            return true;
        }

        if (value.length > 254) {
            document.getElementById('email-required').textContent = 'Email too long! Max 254 characters length'
            return true;
        }

        document.getElementById('email-required').textContent = ''
        return false;
    }

    const checkPasswordInput = (e) => {
        if (!document.getElementById('password-input')) return true;
        let value = e ? e.value : document.getElementById('password-input').value;
        if (value === '')
            return true;

        if (value.length < 10) {
            document.getElementById('password-required').textContent = 'Password too short! Min 10 characters'
            return true;
        }

        if (value.length > 255) {
            document.getElementById('password-required').textContent = 'Password too long! Max 255 characters'
            return true;
        }

        if (value !== document.getElementById('confirmPassword-input').value) {
            document.getElementById('confirmPassword-required').textContent = 'Must match with the password above!'
            return true;
        }

        document.getElementById('confirmPassword-required').textContent = ''
        return false;
    }

    const checkConfirmPasswordInput = (e) => {
        if (!document.getElementById('confirmPassword-input')) return true;
        let value = e ? e.value : document.getElementById('confirmPassword-input').value;
        if (value === '')
            return true;


        if (value !== document.getElementById('password-input').value) {
            document.getElementById('confirmPassword-required').textContent = 'Must match with the password above!'
            return true;
        }
        document.getElementById('confirmPassword-required').textContent = ''
        return false;
    }

    const checkNameInput = (e) => {
        if (!document.getElementById('name-input')) return true;
        let value = e ? e.value : document.getElementById('name-input').value;
        if (value === '') {
            return true;
        }
        if (value.length > 64) {
            document.getElementById('name-required').textContent = 'Too long. Max 64 characters length';
            return true
        }

        return false;
    }

    const checkSurnameInput = (e) => {
        if (!document.getElementById('surname-input')) return true;
        let value = e ? e.value : document.getElementById('surname-input').value;
        if (value === '') {
            return true;
        }
        if (value.length > 64) {
            document.getElementById('surname-required').textContent = 'Too long. Max 64 characters length';
            return true
        }
        return false;
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault()
        }} className="w-full max-w-[700px] h-fit !max-h-screen bg-transparent rounded-myb py-2 overflow-auto">

            <header className="text-center text-title text-primary-color font-bold pb-5">Register</header>

            <div className='mx-auto relative h-fit w-2/3 outline-none pb-5'>
                <label className='font-semibold'> Email * </label>

                <input id='email-input' className='h-10 px-4 w-full border rounded-myb text-lg' type="email" placeholder="user@mail.com"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    onFocus={(e) => e.target.style.outline = ''} />

                <label id='email-required' className='absolute -bottom-1 right-0 text-red-400'></label>
            </div>
            <div className=' mx-auto relative h-fit w-2/3 outline-none pb-5'>

                <label className='font-semibold'> Password * </label>
                <div className='relative w-full h-10'>

                    <input id='password-input' className='h-10 px-4 w-full border rounded-myb text-lg' type={showPassword ? 'text' : 'password'} placeholder="password" onChange={(e) => {

                        setPassword(e.target.value)
                    }}
                        onFocus={(e) => e.target.style.outline = ''} />

                    <img className='absolute w-8 bottom-1 right-2' src={showPassword ? eyeClosed : eyeOpened} alt='Show Password' onClick={() => setShowPassword(!showPassword)} />
                    <label id='password-required' className='absolute right-0 -bottom-5 text-red-400 sm:text-base text-xs'></label>
                </div>
            </div>

            <div className=' mx-auto relative h-fit w-2/3 outline-none pb-5'>
                <label className='font-semibold'> Confirm Password * </label>
                <div className='relative w-full h-10'>
                    <input id='confirmPassword-input' className='h-10 px-4 w-full border rounded-myb text-lg' type={showConfirmPassword ? 'text' : 'password'} placeholder="password"
                        onFocus={(e) => e.target.style.outline = ''} />
                    <img className='absolute w-8 bottom-1 right-2' src={showConfirmPassword ? eyeClosed : eyeOpened} alt='Show Password' onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                    <label id='confirmPassword-required' className='absolute right-0 -bottom-5 text-red-400 sm:text-base text-xs'></label>
                </div>

            </div>

            <div className=' mx-auto relative h-fit w-2/3 outline-none pb-5'>
                <label className='font-semibold'> Name * </label>
                <input id='name-input' className='h-10 px-4 w-full border rounded-myb text-lg' type="text" placeholder="name" onChange={(e) => {

                    setName(e.target.value)
                }}
                    onFocus={(e) => e.target.style.outline = ''} />
                <label id='name-required' className='absolute -bottom-1 right-0 text-red-400'></label>
            </div>

            <div className=' mx-auto relative h-fit w-2/3 outline-none pb-5'>
                <label className='font-semibold' > Surname * </label>
                <input id='surname-input' className=' h-10 px-4 w-full border rounded-myb text-lg' type="text" placeholder="surname" onChange={(e) => {

                    setSurname(e.target.value
                    )
                }}
                    onFocus={(e) => e.target.style.outline = ''} />
                <label id='surname-required' className='absolute -bottom-1 right-0 text-red-400'></label>
            </div>


            <div className='flex flex-col gap-3 w-2/3 mx-auto'>
                <div className="login-form-span flex flex-col gap-2">
                    <div className='flex flex-row gap-3 items-center'>
                        <input id="terms-checkbox" type='checkbox' />

                        <label for="terms-checkbox" className='text-slate-500 max-sm:!text-[14px]'>
                            <span>*
                                Yes, I agree to myBiros
                                <button type='button' className=' ps-1 underline text-blue-700'>Terms of Use.</button>
                            </span>
                        </label>

                    </div>
                    <div className="login-form-span flex flex-row gap-1" >Already have an account? <button type='button' className='underline text-blue-700 focus:text-primary-color' onClick={(e) => {
                        e.preventDefault()
                        setCurrentPanel('login')
                    }}>Login</button></div>
                </div>
                <div>

                    <p className='relative text-slate-500 max-sm:!text-[10px] '>

                        By clicking the button below, you accept that we can email you & process your data according to our <button type='button' className='underline text-blue-700'>Privacy Policy.</button>
                    </p>
                </div>
                <div className='w-full h-12 px-5 flex flex-col sm:flex-row justify-center items-center gap-4'>
                    <div className=" w-[380px] flex flex-col justify-between items-center">
                        <Button type='recaptcha' rounded>Get Trial Access</Button>
                    </div>
                </div>
            </div>



        </form>


    )
}