import cursor from "assets/sidebar/cursor.png";
import * as MENU from "components/commons/SidebarMenus";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MenuComponent } from "./menuComponent";

export const Sidebar = () => {

  const [sidebar, setSidebar] = useState(true);

  const location = useLocation();

  type MenuType = {
    title: string;
    svg: JSX.Element;
    to: string;
    children: Array<MenuType>;
  };

  const [menu, setMenu] = useState<Array<MenuType>>([]);

  const initializeMenu = useCallback(() => {
    if (location.pathname.split("/")[1] === "usecase") {
      let UCID = location.pathname.split("/")[2];
      setMenu(MENU.UseCase({ UCID }));
    } else setMenu(MENU.Default());
    let locCheck = location.pathname.split("/")[3];
    if (locCheck === "result") setSidebar(false);
    else if (locCheck === "settings") setSidebar(true);
  }, [location, setSidebar]);

  useEffect(() => {
    initializeMenu();
  }, [initializeMenu]);

  return location.pathname !== "/user/account/verify" &&
    location.pathname !== "/login" &&
    location.pathname !== "/reset-password" &&
    location.pathname !== "/org-setup" &&
    location.pathname !== "/user/password/reset/confirm" ? (
    <div
      id="sidebar"
      className="relative bg-white border-r  flex flex-col !transition-all !duration-300"
      style={{
        width: sidebar ? "15rem" : "5rem",
        maxWidth: "15rem",
        minWidth: sidebar ? "15rem" : "5rem",
      }}
    >
      <img
        src={cursor}
        alt={"cursor"}
        className="absolute -right-4 top-2 w-8 cursor-pointer !transition-transform !duration-300 z-20"
        style={{
          transform: `rotate(${sidebar ? 0 : 180}deg)`,
        }}
        onClick={(e) => {
          setSidebar(!sidebar);
        }}
      />
      <div className=" h-[180px] bg-[#f4f7fc]"></div>
      <ul className="mt-20 px-2 flex flex-col gap-5">
        {menu.map((item, index) => (
          <MenuComponent item={item} sidebar={sidebar} />
        ))}
      </ul>
    </div>
  ) : (
    <></>
  );
};
