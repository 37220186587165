import { EditSVG } from "assets/usecase/EditSVG";
import { TrashSVG } from "assets/usecase/TrashSVG";
import { Label, Line } from "./aggregatedAnnotationPanel";
import { AggregatedEntityPill } from "./aggregatedEntityPill";
type Dict<T> = {
  [key: string]: T | null;
};

type Props = {
  line: Line;
  labels: Dict<Label>;
  handleDeleteLine: (line: Line) => Promise<void>;
  closeCreateMode: () => void;
  setUpdateFlow: React.Dispatch<
    React.SetStateAction<{
      line: Line;
      labelKey: string;
    } | null>
  >;
};

export const AggregatedEntityBox = ({
  line,
  labels,
  handleDeleteLine,
  closeCreateMode,
  setUpdateFlow,
}: Props) => {
  const formatText = (text: string | undefined): string => {
    if (!text) return "";
    let newText = text[0].toUpperCase() + text.slice(1);
    newText = newText.replace(/_([a-z0-9A-Z])/g, function (m) {
      return " " + m[1].toUpperCase();
    });

    return newText;
  };

  return (
    <>
      {line && (
        <div className=" h-fit w-full rounded-myb flex flex-col">
          <div className="w-full ps-5 pe-3 bg-[#EAEBFB] h-8 rounded-t-myb border-[#EAEBFB] flex items-center justify-between text-[16px] font-semibold">
            <div>{line.name && labels[line.name]!.tag_alias}</div>
            <div className="!w-20 h-full flex flex-row items-center justify-end gap-2 pe-1">
              <div
                className="w-7 h-7 p-1 hover:bg-white rounded-myb flex items-center justify-center cursor-pointer"
                onClick={() => {
                  closeCreateMode();
                  if (line && line.name)
                    setUpdateFlow({
                      line: line,
                      labelKey: labels[line.name]!.tag,
                    });
                }}
              >
                <EditSVG width={22} height={22} />
              </div>
              <div
                className="w-7 h-7 p-1 cursor-pointer flex justify-center items-center rounded-myb hover:bg-red-300 hover:border-red-500 !transition-all duration-700"
                onClick={() => {
                  handleDeleteLine(line);
                }}
              >
                <TrashSVG width={22} height={22} />
              </div>
            </div>
          </div>
          {Object.entries(line["aggregated_fields"]).map(([key, field]) => {
            return (
              <AggregatedEntityPill
                labelKey={key}
                field={field}
                label={labels[key]}
                formatText={formatText}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
