import { CheckSVG } from "assets/firstUse/CheckSVG";
import { ClickSVG } from "assets/firstUse/ClickSVG";
import { UploadDocSVG } from "assets/firstUse/UploadDocSVG";
import { Button } from "components/button/Button";
import { useNavigate } from "react-router-dom";
import logoSVG from 'assets/logo/myb_logo.png';

export const FirstUseOverlay = ({ firstUse, setFirstUse }) => {

    const navigate = useNavigate();

    const navigateToCatalog = () => {
        navigate(`/create-usecase`)
    }

    return (
        <div className="w-screen h-screen absolute left-0 top-0 z-50 transition-opacity !duration-700 !delay-200"
            style={{
                background: 'rgba(26, 48, 102, 0.75)',
                opacity: firstUse ? '1' : '0',
                pointerEvents: firstUse ? 'all' : 'none'
            }}
        >
            <div className="relative w-screen h-screen flex items-center justify-center ">
                <div className="w-[960px] h-fit pt-10 pb-16 bg-white rounded-myb flex flex-col gap-10 items-center">
                    <div className="w-full h-fit flex flex-col items-center justify-center gap-5">
                        <img src={logoSVG} alt='myBiros' className='w-1/4' />
                        <h1 className='font-semibold text-title text-primary-color'>
                            Welcome to myBiros!
                        </h1>
                    </div>

                    <div className='w-3/4 h-fit text-center text-description text-primary-color '>
                        <p>
                            Quickly transform your documents into actionable data with our AI-driven platform. Experience effortless efficiency.
                            Now you can upload your documents, validate them, and download your data effortlessly.
                        </p>
                    </div>
                    <div className='flex flex-row  justify-center w-[80%]'>
                        <ul className='flex flex-col gap-5'>
                            <li className='flex flex-row items-center gap-2'>
                                <ClickSVG width={40} />
                                <span className='text-description'>Choose the product you are interested in from the catalog</span>
                            </li>
                            <li className='flex flex-row items-center gap-2'>
                                <UploadDocSVG width={40} />
                                <span className='text-description'>Upload your documents</span>
                            </li>
                            <li className='flex flex-row items-center gap-2'>
                                <CheckSVG width={40} />
                                <span className='text-description'>Validate and export!</span>
                            </li>
                        </ul>
                    </div>
                    <span className="text-description">
                        Ready to get started?
                    </span>
                    <div className='w-full h-fit flex flex-row items-center justify-center gap-10'>
                        <Button inverted action={() => {
                            navigateToCatalog()
                            setFirstUse(false)
                        }} >Start</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}