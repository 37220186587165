import { ResultFooterBtn } from "./resultFooterBtn";

type FooterProps = {
  updateFileStatus: (status: string) => void;
};

export const ResultFooter: React.FC<FooterProps> = ({ updateFileStatus }) => {
  return (
    <div className="h-20 w-full flex flex-row items-center px-[1vh] pb-[1vh] gap-4">
      <ResultFooterBtn className='w-[40%] bg-[rgba(0,255,0,0.05)] border-[rgba(0,255,0,1)]' text='Accept' updateFileStatus={updateFileStatus} status="approved" />
      <ResultFooterBtn className='w-[30%] bg-[rgba(255,175,0,0.1)] border-[rgba(255,175,0,1)]' text='To Review' updateFileStatus={updateFileStatus} status="to_review" />
      <ResultFooterBtn className='w-[30%] bg-[rgba(255,0,0,0.1)] border-[rgba(255,0,0,1)]' text='Reject' updateFileStatus={updateFileStatus} status="not_approved" />
    </div>
  );
};
