import { useContext } from "react";
import { createPortal } from "react-dom";
import stc from "string-to-color";
import { StageContext } from "../stageContext";

type Props = {
  bbLayerRef: React.MutableRefObject<any>;
};
export const SvgLinePortal = ({ bbLayerRef }: Props) => {
  const stageContext = useContext(StageContext);

  const calculateSvgLines = () => {
    let svgColor = "black"; //#1a3066
    let stageContainer = document.getElementById("stage-container")
      ?.children[0] as HTMLElement;
    let targetBB = bbLayerRef.current.findOne(
      `#bb-${stageContext?.highlighted?.id}`
    );
    let fromPill = document.getElementById(
      `anchor-${stageContext?.highlighted?.id}`
    ) as HTMLElement;
    if (fromPill) {
      svgColor = stc(stageContext?.highlighted?.tag);
    }
    let galleryDiv = document.getElementById(`gallery`);
    let sidebarDiv = document.getElementById(`sidebar`);
    let header = document.getElementsByClassName(
      "header-container"
    )[0] as HTMLElement;
    if (
      !fromPill ||
      !targetBB ||
      !galleryDiv ||
      !sidebarDiv ||
      !stageContainer ||
      !header
    ) {
      return { x1: 0, x2: 0, y1: 0, y2: 0, svgColor: "transparent" };
    }

    let bbAttrs = targetBB.getClientRect();

    let pillLeftWall = {
      x: fromPill.getBoundingClientRect().x - sidebarDiv.clientWidth - 20,
      y: fromPill.getBoundingClientRect().top + 15,
    };
    let x1, x2, y1, y2;

    x1 =
      bbAttrs.x +
      bbAttrs.width +
      galleryDiv.offsetLeft +
      galleryDiv.offsetWidth;

    if (x1 < galleryDiv.offsetLeft + galleryDiv.offsetWidth)
      x1 = galleryDiv.offsetLeft + galleryDiv.offsetWidth;
    else if (x1 > pillLeftWall.x + 80) x1 = pillLeftWall.x + 79;

    y1 =
      bbAttrs.y +
      header.getBoundingClientRect().height +
      10 +
      bbAttrs.height / 2;
    if (y1 < header.getBoundingClientRect().height + 10)
      y1 = header.getBoundingClientRect().height + 10;
    else if (y1 > stageContainer.offsetHeight + stageContainer.offsetTop)
      y1 = stageContainer.offsetHeight + stageContainer.offsetTop;
    x2 = pillLeftWall.x + 100;
    y2 = pillLeftWall.y;

    return { x1: x1, x2: x2, y1: y1, y2: y2, svgColor: svgColor };
  };

  if (stageContext?.highlighted === null || stageContext?.highlighted.id! < 0)
    return;
  return (
    <>
      {createPortal(
        <div className="absolute h-screen w-screen z-10 top-0 left-0 pointer-events-none">
          <svg
            id="svg"
            className="absolute h-screen w-screen z-10 top-0 left-0 pointer-events-none"
          >
            <circle
              className="stroke-[3] stroke-black fill-white"
              id="circle"
            />
            <line
              className="stroke-[3]"
              id="line"
              x1={calculateSvgLines().x1}
              y1={calculateSvgLines().y1}
              x2={calculateSvgLines().x2}
              y2={calculateSvgLines().y2}
              stroke={calculateSvgLines().svgColor}
            />
          </svg>
        </div>,
        document.body
      )}
    </>
  );
};
