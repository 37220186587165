import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendVerificationEmail, verifyAccountEmail } from "../../auth/auth";
const logoSRC = "https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/643eca2362c1b771f19be634_Frame%2015.png";

export function VerifyAccount() {

    const navigate = useNavigate()
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()

    const [errorMessage, setErrorMessage] = useState(false)
    const [email, setEmail] = useState()

    useEffect(() => {

        const verify = async () => {
            await verifyAccountEmail(code, userEmail).then(() => {
                document.getElementById('verification-txt').textContent = 'Account verified. Going to login...'
                setTimeout(() => navigate('/login', { replace: true }), 5000)
            }).catch(() => {
                setErrorMessage(true)
            })
        }

        let code = searchParams.get('code')
        let userEmail = searchParams.get('email')
        if (!userEmail) navigate('/login', { replace: true })
        setEmail(userEmail)
        if (code) verify(code, userEmail)
        else {
            alert('Error verifying account')
            navigate('/login', { replace: true })
        }
    }, [navigate, searchParams])

    const sendVerificationLink = async () => {
        await sendVerificationEmail(email).then(() => {
            document.getElementById('verification-txt').textContent = 'Verification code sent!'
        }).catch(() => {
            document.getElementById('verification-txt').textContent = 'Cannot send another verification email. Going to login...'
            setTimeout(() => navigate('/login', { replace: true }), 5000)
        })
    }

    return (
        <div className="h-screen w-screen flex flex-col items-center py-10">
            <div className="flex justify-center">
                <img className="w-1/2" src={logoSRC} alt="MyBiros" />
            </div>
            <div className="bg-white flex justify-center h-full py-40">
                <div id="verification-txt" className="shadow-lg p-10 rounded-lg h-fit">
                    {errorMessage && <span> Can't verify your email. <span className="text-blue-500 cursor-pointer" onClick={() => {
                        sendVerificationLink()
                    }}> Send another verification link</span> </span>}
                </div>
            </div>
        </div>
    )
}