import { DocumentPlusSVG } from "assets/usecase/DocumentPlusSVG";
import { ExtractAllSVG } from "assets/usecase/ExtractAllSVG";
import { SimpleCheckSVG } from "assets/usecase/SimpleCheckSVG";
import { Button } from "components/button/Button";
import { FileUploader } from "components/newFileUploader/NewFileUploader";
import { useNotifications } from "components/notificationSystem/notificationContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import * as API from "../../apis/api";
import DocumentsListTable from "../../components/documentListTable/documentsListTable";
import ExportPanel from "../../components/export/exportPanel";
import Plan from "../../components/plan/plan";
import { handleExportDoc, handleExtractAllWithStatusAndDate } from "./utils";

export function UseCaseRoute({ analytics }) {

    const loaderData = useLoaderData();

    const navigate = useNavigate();

    const [currentUseCase, setCurrentUseCase] = useState(null)

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()

    const statusList = ['not_ready', 'pending', 'to_review', 'approved', 'not_approved']

    const [revisionStatusSearch, setRevisionStatusSearch] = useState(null)

    const [navigateToDashboard, setNavigateToDashboard] = useState(false)


    useEffect(() => {
        if (navigateToDashboard) navigate('/', { replace: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateToDashboard])

    const notification = useNotifications()

    useEffect(() => {
        if (revisionStatusSearch) return;


        let revision = searchParams.get('revision')

        if (revision && statusList.find(x => x === revision))
            setRevisionStatusSearch(revision)
        else
            setRevisionStatusSearch('All')
    }, [])

    useEffect(() => {

        if (navigateToDashboard) return;
        const fetchUseCase = async () => {
            await API.getUseCase(loaderData['usecaseID']).then((res) => {
                setCurrentUseCase(res)
            }).catch((e) => {
                notification.addNotification({
                    title: 'Use Case Error',
                    message: "Can't find selected Use Case",
                    severity: 'ERROR',
                    timeout: 3000
                })

                setNavigateToDashboard(true)
            })
        }

        loaderData && fetchUseCase()
    }, [loaderData, searchParams, navigate])


    const afterUploadDocumentsRendering = async () => {
        await API.getUseCase(currentUseCase.public_id).then((res) => setCurrentUseCase(res))


        await API.getMonitorUseCasePlan(loaderData['usecaseID']).then((res) => {

            if (planLimit === 0)
                setPlanLimit(res.plan_usage.usage_limit)
            setPlanUsage(res.plan_usage.usage)

        })
    }

    const [planLimit, setPlanLimit] = useState(0)
    const [planUsage, setPlanUsage] = useState(0)

    let interval = useRef()

    const fetchStatus = useCallback(() => {

        clearInterval(interval.current)
        interval.current = setInterval(async () => {
            await API.getMonitorUseCasePlan(loaderData['usecaseID']).then((res) => {

                if (planLimit === 0)
                    setPlanLimit(res.plan_usage.usage_limit)
                setPlanUsage(res.plan_usage.usage)

            }).catch((e) => {
                clearInterval(interval.current)
            })
        }, 5000)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planUsage])

    useEffect(() => {
        const initializePlanData = async () => {
            await API.getMonitorUseCasePlan(loaderData['usecaseID']).then((res) => {

                if (planLimit === 0)
                    setPlanLimit(res.plan_usage.usage_limit)
                setPlanUsage(res.plan_usage.usage)
            }).catch((e) => {
                clearInterval(interval.current)
            })

            fetchStatus()
        }

        initializePlanData()
        return () => clearInterval(interval.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [extractLoading, setExtractLoading] = useState(false)

    return (

        <div className="product-bg default-page-layout">
            <div id='extract-overlay'
                className="absolute top-0 left-0 w-screen h-screen z-50 !transition-opacity !duration-300"
                style={{ opacity: 0, pointerEvents: 'none' }}
                onClick={() => {
                    document.getElementById('extract-overlay').style.opacity = '0';
                    document.getElementById('extract-overlay').style.pointerEvents = 'none';
                }}>
                <div className="h-screen w-screen flex justify-center items-center"
                    style={{ backgroundColor: 'rgba(26, 48, 102, 0.75)' }}>
                    <div
                        className="w-1/3 h-1/3 bg-white rounded-myb shadow flex flex-col items-center justify-center px-16 gap-8">
                        <p className="text-3xl text-center font-semibold text-primary-color"> Congratulations! </p>
                        <p className="text-xl text-center font text-primary-color"> You have successfully exported your results. </p>
                        <div className="bg-green-300 rounded-full w-14 h-14 flex justify-center">
                            <SimpleCheckSVG width={48} height={48} />
                        </div>
                    </div>
                </div>
            </div>
            <div id='overlay-loader' className={`overlay-loader`} style={{ display: 'none' }}>
                {currentUseCase && <FileUploader useCaseID={currentUseCase.public_id}
                    afterUploadCallback={afterUploadDocumentsRendering}
                    analytics={analytics} />}
            </div>

            <div id='overlay-export' className={`overlay-export`} style={{ display: 'none' }}>
                {currentUseCase &&
                    <ExportPanel
                        useCaseID={currentUseCase.public_id}
                        afterConfirmExport={
                            async (revision_status, from_date, to_date) => {
                                setExtractLoading(true)
                                await handleExtractAllWithStatusAndDate(currentUseCase.public_id, currentUseCase.name, analytics, notification, revision_status, from_date, to_date, setExtractLoading).then((res) => {
                                    if (res) setTimeout(() => setExtractLoading(false), 1000)
                                })
                            }}
                        analytics={analytics}
                        loading={extractLoading}
                    />
                }
            </div>

            <div className="default-page-container !gap-5 max-[1370px]:!max-w-[1000px]">
                <div className="dashboard-head items-start justify-between">
                    <div className="page-header ">
                        <div className="title">{currentUseCase && currentUseCase.name}</div>

                    </div>
                    <div className="flex flex-row gap-5 h-fit justify-end">
                        <div className="me-[100px]">
                            {currentUseCase && <Plan useCaseID={currentUseCase.public_id} planLimit={planLimit}
                                setPlanLimit={setPlanLimit} planUsage={planUsage}
                                setPlanUsage={setPlanUsage} />}
                        </div>
                        {/*<div className="relative">
                            <div id='extract-loader' className="absolute bg-green-300 h-full rounded  !transition-transform !duration-700 !ease-in-out" />
                            <Button className="flex flex-row gap-2 !bg-transparent" action={async (e) => {
                                setExtractLoading(true)

                                await handleExtractAll(currentUseCase.public_id, currentUseCase.name, analytics, notification).then((res) => {
                                    if (res) setTimeout(() => setExtractLoading(false), 1000)
                                })
                            }} disabled={extractLoading}><ExtractAllSVG width={20} height={20} />Export All </Button>
                        </div>*/}
                        <div className="relative">
                            <div id='extract-loader'
                                className="absolute bg-green-300 h-full rounded  !transition-transform !duration-700 !ease-in-out" />
                            <Button className="outline-1 flex flex-row gap-2 disabled:pointer-events-none"
                                action={(e) => {
                                    document.getElementById('overlay-export').style.display = 'flex'
                                }}><ExtractAllSVG width={20} height={20} /> Export
                            </Button>
                        </div>
                        <Button className="outline-1 flex flex-row gap-2 disabled:pointer-events-none"
                            action={(e) => {
                                document.getElementById('overlay-loader').style.display = 'flex'
                            }} disabled={planUsage >= planLimit}><DocumentPlusSVG width={20} height={20} /> Upload
                            File</Button>
                    </div>
                </div>
                <DocumentsListTable usecase={currentUseCase}
                    setUseCase={setCurrentUseCase} setRevisionStatusSearch={setRevisionStatusSearch}
                    revisionStatusSearch={revisionStatusSearch}
                    refetchAllDocuments={afterUploadDocumentsRendering}
                    handleExportDoc={handleExportDoc} analytics={analytics} />

            </div>

        </div>
    )
}