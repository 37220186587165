
import * as API from 'apis/api';
import logoRegisterSimple from 'assets/brand/simple_logo_register.png';
import myBLogo from "assets/logo/myb_logo.png";
import { fetchToken } from "auth/auth";
import { CustomersCarousel } from "components/customersCarousel/customersCarousel";
import { useNotifications } from 'components/notificationSystem/notificationContext';
import { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import { useLocation, useNavigate } from 'react-router';
import { loginFun, registerFun } from "./component";
import { LoginPanel } from './panels/loginPanel';
import { RegisterPanel } from "./panels/registerPanel";
import { ResetPasswordPanel } from './panels/resetPasswordPanel';
const listItemSVG = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={"#389ee1"} className="w-6 h-6 mr-1">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

export function LoginRoute({ mobile = false, setUserInfo }) {

    const navigate = useNavigate()

    const [login, setLogin] = useState(false)
    let location = useLocation();
    const previousPath = location.state?.from || '/';

    const notification = useNotifications()

    useEffect(() => {
        login && navigate(mobile ? '/mobile-device' : previousPath)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [login])

    useEffect(() => {
        if (fetchToken())
            navigate('/')
        else
            setUserInfo(null)
    })

    const handleLogin = (email, password, notification) => {
        loginFun(email, password, notification).then(res => {
            if (res === true) {
                setLogin(true)
                navigate(mobile ? '/mobile-device' : previousPath)
                API.getUserInfo().then(res => setUserInfo(res)).catch()
            }
        })
    }

    const handleRegister = (email, password, name, surname, role, phone, token) => {
        registerFun(email, password, name, surname, role, phone, token, mobile, notification).then(res => {
            if (res === true)
                setCurrentPanel('login')
        })


    }

    const [currentPanel, setCurrentPanel] = useState('login')

    const panel = {
        'reset': <ResetPasswordPanel setCurrentPanel={setCurrentPanel} />,
        'login': <LoginPanel loginCallback={handleLogin} setCurrentPanel={setCurrentPanel} />,
        'register': <RegisterPanel setCurrentPanel={setCurrentPanel} registerCallback={handleRegister} />,

    }


    return (
        <div className='2xl:product-bg h-screen lg:grid lg:grid-cols-[repeat(2,_minmax(500px,_1fr))] flex flex-col w-screen'>

            {/* COLONNA SINISTRA */}
            <div className='hidden lg:flex flex-col items-center justify-center gap-10 border-e'>
                {/* PANNELLO LOGO */}
                <div className='h-1/4 flex flex-col justify-center items-center p-6 gap-3'>
                    <img
                        src={myBLogo}
                        alt="" className="logo-image"
                    />
                </div>

                {/* PANNELLO INFO TRIAL */}
                <div className='w-full h-full sm:h-2/3 flex flex-row items-center justify-center  '>
                    <div className='px-auto relative'>
                        <h1 className='text-2xl font-bold text-primary-color'>Are you ready to start your free trial? </h1>
                        <div className='mt-5 text-xl text-primary-color font-bold'>Your myBiros free plan includes:</div>
                        <ul className='mt-3 flex flex-col gap-3 '>
                            <li className='flex flex-row gap-2 items-center'>
                                {listItemSVG}
                                <span className={"text-primary-color"}>
                                    A trial of our prebuilt services
                                </span>
                            </li>
                            <li className='flex flex-row gap-2 items-center'>
                                {listItemSVG}
                                <span className={"text-primary-color"}>
                                    First 300 free pages
                                </span>
                            </li>
                            <li className='flex flex-row gap-2 items-center'>
                                {listItemSVG}
                                <span className={"text-primary-color"}>
                                    No credit card required
                                </span>
                            </li>
                        </ul>
                        <div className='flex flex-col w-full mt-14'>
                            <span className={"font-light text-primary-color"}>
                                Trusted by businesses across all industries ✅
                            </span>


                            <a href="https://it.trustpilot.com/review/mybiros.com" target="_blank" rel='noreferrer' alt='Trust Pilot Reviews'>
                                <div data-w-id="7aea5002-8303-d79e-1cd7-d33b65e5612c" class="mt-4 flex flex-col hover:opacity-[.9] gap-1">
                                    <div class="flex flex-row gap-1 ">
                                        <img loading="lazy" alt="" class="w-[250px]" src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-4.svg" />
                                    </div>
                                    <div class="w-full flex flex-row gap-2">
                                        <span className={"text-primary-color font-light"}>
                                            Read our customer reviews
                                        </span>
                                        <img loading="lazy" alt="Trustpilot" class="image-8" src="https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/64ac1158542ff0c6634706c3_6348364c15b4b4e352faf13c_Trustpilot_Logo_(2022)%201.svg" />
                                    </div>
                                </div>
                            </a>

                        </div>

                    </div>
                </div>
                <CustomersCarousel />
                <div className={"h-[50px]"}>

                </div>
            </div>
            <div className="register-login-bg w-full h-full relative flex flex-col items-center justify-center">
                <div className="relative w-48 h-fit lg:w-0 mt-5">
                    <img src={logoRegisterSimple} />
                </div>
                {
                    panel[currentPanel]
                }
            </div>

        </div>
    );
}

