import { EditSVG } from 'assets/usecase/EditSVG'
import { EmptyField } from 'assets/usecase/EmptyField'
import { useContext, useEffect, useState } from 'react'
import { StageContext } from 'routes/Document Result/stageContext'
import stc from 'string-to-color'

export const ResultPill = ({ label, labelKey, labelId, labelValue, bBox, confidence, setDefaultAddLabel, setEditField, setCreateMode }) => {

    const { setHighlighted } = useContext(StageContext)

    let calculateFontSize = (id) => {
        let el = document.getElementById(id)
        if (!el) return ''

        let spanHeight = el.offsetHeight
        let lineHeight = parseInt(el.style.lineHeight)

        let lines = spanHeight / lineHeight

        return `${20 - lines * 2}px`
    }

    const scoreColorMap = (score) => {
        if (score === null)
            return "rgba(0,0,0,0.08)"
        if (score >= 90) {
            return "rgba(0, 255, 0, 0.25)"
        } else if (score === 0) {
            return "rgba(136,136,136,0.25)"
        } else if (score >= 70) {
            return "rgba(255,183,0,0.25)"
        } else if (score < 70) {
            return "rgba(255,0,0,0.25)"
        }
        return "rgba(0,0,0,0.08)"
    }

    const [currentLabel, setCurrentLabel] = useState(labelValue ? labelValue : '')

    useEffect(() => {
        labelValue && setCurrentLabel(labelValue)
    }, [labelValue])

    return (
        <div name='result-pill' className="relative !w-full flex-col "
            onMouseEnter={() => {
                setHighlighted({
                    id: labelId,
                    tag: label
                })
            }}
            onMouseLeave={() => {
                setHighlighted(null)
            }}
        >
            <div id={`anchor-${labelId}`} className={`w-1 h-full absolute rounded-s-myb z-10`}
                style={{
                    backgroundColor: stc(label)
                }}></div>
            <div name='result-pill-key' className="bg-[#EAEBFB] w-full flex items-center justify-between ps-5 pe-3 h-8 rounded-t-myb text-[16px] font-semibold ">
                {labelKey.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z]+)(?=[A-Z][a-z])/g, '$1 ')}
                <div className='w-7 h-7 py-1 hover:bg-white rounded-myb flex items-center justify-center cursor-pointer' onClick={() => {
                    if (labelId >= 0) {
                        setEditField({ label: label, id: labelId, 'bounding-boxes': bBox, text: currentLabel, tag_alias: labelKey.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z]+)(?=[A-Z][a-z])/g, '$1 ') })
                    } else {
                        setCreateMode(label)
                    }
                }}>
                    <EditSVG width={20} height={20} />
                </div>
            </div>
            <div className='relative w-full h-10 grid grid-cols-[auto,100px] border rounded-b-myb'>
                <div name='result-pill-value' className="relative px-5 max-w-[348px] h-full flex flex-row w-full items-center justify-start flex-wrap  font-semibold" style={{ fontSize: calculateFontSize('label-debug') }}>
                    <span id={'label-debug'} className='relative text-[14px] font-normal w-full whitespace-nowrap overflow-x-auto overflow-y-hidden' style={{ lineHeight: '20px' }}>
                        {currentLabel}
                    </span>

                </div>
                <div name='result-pill-status' className="h-full flex flex-row items-center justify-end pe-3 ">

                    <div className="h-8 w-8 rounded-full flex justify-center items-center text-[12px]" style={{ backgroundColor: scoreColorMap(confidence) }} >
                        {confidence ? Math.floor(confidence) : <EmptyField width={18} height={18} />}
                    </div>

                </div>
            </div>
        </div>
    )
}