import * as API from "apis/api";
import { CircleLoading } from "assets/circle_loading";
import { InfoSVG } from "assets/usecase/InfoSVG";
import { TrashSVG } from "assets/usecase/TrashSVG";
import { Button } from "components/button/Button";
import { useNotifications } from "components/notificationSystem/notificationContext";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router";
import { InfoPolicyModal } from "./components/infoPolicyModal";
import { NewPolicyModal } from "./components/newPolicyModal";

type Dict = {
  [key: string]: any;
};
export const ValidationRoute = () => {
  const navigate = useNavigate();
  const loaderData = useLoaderData() as any;

  const [rulesList, setRulesList] = useState([]);
  const [currentUseCase, setCurrentUseCase] = useState<Dict | null>(null);
  const [policySettings, setPolicySettings] = useState(false);
  const [addRulePanel, setAddRulePanel] = useState(false);

  const [labels, setLabels] = useState<Array<any>>([]);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const ruleClasses = ["RequiredFields", "FieldMinConfidence"];

  const [ruleName, setRuleName] = useState("");
  const [ruleClass, setRuleClass] = useState(ruleClasses[0]);
  const [rulePageFrom, setRulePageFrom] = useState(0);
  const [rulePageTo, setRulePageTo] = useState(0);
  const [rulePageAll, setRulePageAll] = useState(false);
  const [singleSelectedLabel, setSingleSelectedLabel] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [mustExists, setMustExists] = useState(false);

  const [selectedInfoIndex, setSelectedInfoIndex] = useState(-1);

  const [navigateToDashboard, setNavigateToDashboard] = useState(false);

  const notification = useNotifications();
  useEffect(() => {
    if (navigateToDashboard) navigate("/", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateToDashboard]);

  // Mandatory Labels
  const policyCopyMapping = (policyRawType: string) => {
    if (policyRawType === "RequiredFields") {
      return "Mandatory Labels";
    }
    if (policyRawType === "FieldMinConfidence") {
      return "Label Score";
    }
  };

  const initializeUseCase = async () => {
    await API.getUseCase(loaderData["usecaseID"])
      .then((res: Dict) => {
        setCurrentUseCase(res);
        setLabels(Object.entries(res.labels).map((x) => x));
      })
      .catch((e) => {
        notification.addNotification({
          title: "UseCase Error",
          message: "Can't find selected Use Case",
          severity: "ERROR",
          timeout: 3000,
        });

        setNavigateToDashboard(true);
      });
  };

  const initializePolicyData = async () => {
    await API.getUseCasePolicy(loaderData["usecaseID"])
      .then((res) => {
        setPolicySettings(res["enable_auto_validation"]);
        setRulesList(res["policy_rules"]);
      })
      .catch((e) => {
        notification.addNotification({
          title: "Validation Rule Error",
          message: "Can't find Use Case's Validation Rules",
          severity: "ERROR",
          timeout: 3000,
        });
        setNavigateToDashboard(true);
      });
  };

  useEffect(() => {
    !currentUseCase ? initializeUseCase() : initializePolicyData();
  }, [currentUseCase]);

  const savePolicySettings = async (ps: boolean) => {
    await API.updateUseCasePolicyActivation(loaderData["usecaseID"], ps);
  };

  const validateForm = (ruleArgs: any) => {
    let check = true;
    if (ruleName === "") {
      document.getElementById("rule_name_input")!.style.outline =
        "1px solid red";
      check = false;
    }
    if (Array.isArray(ruleArgs) && ruleArgs.length === 0) {
      document.getElementById("rule-args-input")!.style.outline =
        "1px solid red";
      check = false;
    }
    if (rulePageAll === false) {
      if (!rulePageFrom) {
        document.getElementById("from-page-input")!.style.outline =
          "1px solid red";
        check = false;
      }
      if (!rulePageTo) {
        document.getElementById("to-page-input")!.style.outline =
          "1px solid red";
        check = false;
      }
      if (Number(rulePageFrom) > Number(rulePageTo)) {
        document.getElementById("from-page-input")!.style.outline =
          "1px solid red";
        document.getElementById("to-page-input")!.style.outline =
          "1px solid red";
        check = false;
      }
    }
    if (ruleClass === ruleClasses[1]) {
      if (!minValue) {
        let divCheck = document.getElementById("min-value-input");
        if (divCheck)
          document.getElementById("min-value-input")!.style.outline =
            "1px solid red";
        check = false;
      }
    }

    return check;
  };

  const handleSaveNewRule = async () => {
    let ruleArgs: Dict = [];
    if (ruleClass === ruleClasses[0]) {
      ruleArgs = {
        required_fields: selectedLabels.map((x) => x[0]),
      };
    } else {
      if (singleSelectedLabel) {
        ruleArgs = {
          field: singleSelectedLabel[0],
          min_value: minValue,
          must_exists: mustExists,
        };
      }
    }
    if (validateForm(ruleArgs) === false) return;
    let rulePages = rulePageAll ? "*" : rulePageFrom + "-" + rulePageTo;
    await API.addNewPolicyRule(
      loaderData["usecaseID"],
      ruleName,
      ruleClass,
      ruleArgs,
      rulePages
    ).then((res) => {
      setRulesList(res["policy_rules"]);
      setAddRulePanel(false);
      resetFormState();
    });
  };

  const resetFormState = () => {
    setRuleName("");
    setRuleClass(ruleClasses[0]);
    setRulePageFrom(0);
    setRulePageTo(0);
    setRulePageAll(false);
    setSelectedLabels([]);
    setSingleSelectedLabel(null);
    setMinValue(0);
    setMustExists(false);
  };
  const handleDeleteRule = async (rule_index: number) => {
    await API.deletePolicyRule(currentUseCase!.public_id, rule_index).then(
      () => {
        initializePolicyData();
      }
    );
  };

  const saveNewPolicyRule = async (
    name: string,
    policyType: "RequiredFields" | "FieldMinConfidence" | null | undefined,
    ruleArgs: Dict,
    rulePages: string
  ) => {
    await API.addNewPolicyRule(
      loaderData["usecaseID"],
      name,
      policyType,
      ruleArgs,
      rulePages
    ).then((res) => {
      setRulesList(res["policy_rules"]);
      setAddRulePanel(false);
      resetFormState();
    });
  };

  const [toggleDisabled, setToggleDisabled] = useState(false);

  useEffect(() => {
    if (!toggleDisabled) return;
    setTimeout(() => {
      setToggleDisabled(false);
    }, 2000);
  }, [toggleDisabled]);

  return (
    <div className="product-bg default-page-layout relative">
      {addRulePanel === true && (
        <NewPolicyModal
          labels={labels}
          saveNewPolicyRule={saveNewPolicyRule}
          setAddRulePanel={setAddRulePanel}
          newIndex={rulesList.length}
        />
      )}
      {selectedInfoIndex >= 0 && (
        <InfoPolicyModal
          rule={rulesList[selectedInfoIndex]}
          labels={labels}
          setSelectedInfoIndex={setSelectedInfoIndex}
        />
      )}
      <div className="default-page-container !max-w-[80%] max-xl:px-5">
        <div className="dashboard-head">
          <div className="page-header w-full !flex-col !items-start">
            {currentUseCase && (
              <div className="text-page-title">{currentUseCase!.name} </div>
            )}
            <div className="w-full flex flex-row justify-between">
              <div className="text-subtitle">Validation Settings</div>
            </div>
          </div>
        </div>
        <div className="h-[80%] gap-5">
          <div className="flex flex-col md:flex-row w-full items-center justify-between mb-5  gap-2 xl:gap-10">
            <div className="w-full xl:w-1/2 flex flex-row grow items-start xl:items-center ">
              <div className={"flex flex-col  gap-2"}>
                <div className={"flex flex-row gap-2 items-center"}>
                  <span className="w-fit text-normal-small text-primary-color mr-3">
                    Enable Validation
                  </span>
                  <div
                    className={`relative w-[3.5rem] h-6 ${
                      toggleDisabled ? "bg-slate-300" : "bg-slate-100"
                    } rounded-full flex items-center px-1 shadow-inner mt-2`}
                    onClick={() => {
                      if (toggleDisabled) return;
                      savePolicySettings(!policySettings);
                      setPolicySettings(!policySettings);
                      setToggleDisabled(true);
                    }}
                  >
                    <div
                      className="absolute w-5 h-5 rounded-full !transition-all !duration-300 !ease-out shadow-md"
                      style={{
                        left: policySettings ? "60%" : "10%",
                        backgroundColor: toggleDisabled
                          ? "rgb(100 116 139)"
                          : policySettings
                          ? "var(--main-myb-color)"
                          : "rgb(148 163 184)",
                      }}
                    />
                  </div>
                  <div
                    className={`h-10 w-10 pointer-events-none ${
                      toggleDisabled ? "!opacity-100" : "!opacity-0"
                    }`}
                  >
                    <CircleLoading width={50} height={50} />
                  </div>
                </div>

                <span className="text-xs text-slate-500">
                  Toggle this switch button to enable the validation policies
                  listed below
                </span>
              </div>
            </div>
            <div className="w-full md:w-fit flex flex-row justify-start md:justify-end gap-10">
              <Button
                className="!px-4 !h-10"
                inverted
                action={() => {
                  setAddRulePanel(true);
                }}
              >
                Add Policy{" "}
              </Button>
            </div>
          </div>
          <div className="h-[70%] !max-h-[500px] w-full flex flex-col gap-2 ">
            <div className=" bg-white flex flex-col h-full w-full rounded-myb gap-2">
              <div className="w-full h-18 font-semibold text-sm text-primary-color px-5 py-2 rounded-myb shadow flex flex-row mx-auto px bg-slate-100">
                <div className="relative w-1/3 max-w-1/3 flex items-center ">
                  Name
                </div>
                <div className="relative w-1/3 max-w-1/3  flex items-center">
                  Policy Type
                </div>
                <div className="relative w-1/3 max-w-1/3 flex justify-end gap-10 items-center"></div>
              </div>
              <div className="w-full h-full flex gap-2 flex-col overflow-y-auto !px-0">
                {rulesList.map((x, index) => (
                  <div className="w-full h-18 bg-[#f4f7fc] text-sm text-primary-color px-5 py-2 rounded-myb shadow flex flex-row mx-auto px hover:bg-slate-50">
                    <div className="relative w-1/3 max-w-1/3 flex items-center ">
                      {x["rule_name"]}
                    </div>
                    <div className="relative w-1/3 max-w-1/3  flex items-center">
                      {policyCopyMapping(x["rule_class"])}
                    </div>
                    <div className="relative w-1/3 max-w-1/3 flex justify-end gap-10 items-center">
                      <button
                        className="flex text-base flex-row gap-2 items-center border rounded-myb px-2 h-8 hover:border-blue-400 !transition-all !duration-150"
                        onClick={() => {
                          setSelectedInfoIndex(index);
                        }}
                      >
                        <InfoSVG width={20} height={20} />
                        <span className="text-clickable-small">Info</span>
                      </button>
                      <button
                        className="flex text-base flex-row gap-2 items-center border rounded-myb px-2 h-8 hover:border-red-400 !transition-all !duration-150"
                        onClick={() => {
                          handleDeleteRule(index);
                        }}
                      >
                        <TrashSVG width={20} height={20} />
                        <span className="text-clickable-small">Delete</span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
