import { Stage } from "konva/lib/Stage";
import { createContext, useMemo, useRef, useState } from "react";
interface ContextProps {
  annotationRect: any;
  setAnnotationRect: React.Dispatch<React.SetStateAction<any>>;
  stageRef: React.MutableRefObject<Stage | null>;
  highlighted: { id: number; tag: string } | null;
  setHighlighted: React.Dispatch<
    React.SetStateAction<{ id: number; tag: string } | null>
  >;
  selectedText: string;
  setSelectedText: React.Dispatch<React.SetStateAction<string>>;
}

export const StageContext = createContext<ContextProps | null>(null);

export const StageProvider = (props: any) => {
  const stageRef = useRef<Stage>(null);
  const [annotationRect, setAnnotationRect] = useState<any>();

  const [highlighted, setHighlighted] = useState<{
    id: number;
    tag: string;
  } | null>(null);
  const [selectedText, setSelectedText] = useState<string>("");

  const providerValue = useMemo(
    () => ({
      stageRef,
      annotationRect,
      setAnnotationRect,
      highlighted,
      setHighlighted,
      selectedText,
      setSelectedText,
    }),
    [stageRef, annotationRect, highlighted, selectedText]
  );

  return (
    <StageContext.Provider value={providerValue}>
      {props.children}
    </StageContext.Provider>
  );
};
