
import { fetchOrg } from "auth/auth";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { SetupOrgWelcome } from './panels/firstStep';
import { SetupOrgForm } from './panels/secondStep';
import { identifyJune } from "helpers/hooks/useJune";
import { fetchUser } from "auth/auth";

export const OrgSetupRoute = ({ setLoadingData, setRefresh, setNavigateAfterLoad, userInfo, analytics }) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (fetchOrg()) {
            navigate('/organization')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        userInfo && identifyJune(fetchUser(), userInfo, analytics)

    }, [userInfo])

    const [step, setStep] = useState(0);

    const stepPanels = [
        <SetupOrgWelcome setStep={setStep} />,
        <SetupOrgForm setLoadingData={setLoadingData} setRefresh={setRefresh} setNavigateAfterLoad={setNavigateAfterLoad} userInfo={userInfo} analytics={analytics} />,

    ]

    return (
        <div className="orgSetup-page product-bg">
            <div className='orgSetup-container !h-[60%]'>
                {stepPanels[step]}
            </div>
        </div>
    )
}

