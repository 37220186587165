import { AddSimpleAnnotationSVG } from "assets/canvas/AddSimpleAnnotationSVG";
import { RotateLeftSVG } from "assets/canvas/RotateLeftSVG";
import { RotateRightSVG } from "assets/canvas/RotateRightSVG";
import { ZoomInSVG } from "assets/canvas/ZoomInSVG";
import { ZoomOutSVG } from "assets/canvas/ZoomOutSVG";

type Props = {
  action: (x: any) => any;
  param: any;
  icon: string;
  text?: string;
};

interface Dict<T> {
  [Key: string]: T;
}

const icons: Dict<JSX.Element> = {
  ZoomIn: <ZoomInSVG width={22} height={22} />,
  ZoomOut: <ZoomOutSVG width={22} height={22} />,
  RotateRight: <RotateRightSVG width={22} height={22} />,
  RotateLeft: <RotateLeftSVG width={22} height={22} />,
  AddSimpleAnnotations: <AddSimpleAnnotationSVG width={22} height={22} />,
};

export function CanvasToolbarBtn({ action, param, icon, text='' }: Props) {
  return (
    <div
      className="button px-2 flex flex-row gap-2 border-2 rounded-lg cursor-pointer py-1 shadow-slate-500 shadow-sm active:!bg-transparent bg-white bg-opacity-70 hover:!bg-opacity-100 !transition-all !duration-100 !ease-in"
      onClick={() => action(param)}
    >
      {icons[icon]} {text}
    </div>
  );
}
