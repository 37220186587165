import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export const Pagination = ({ currentPage, setCurrentPage, lastPage }) => {

    const navigate = useNavigate()

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()

    const arrayRange = (start, stop, step) =>
        Array.from(
            { length: (stop - start) / step + 1 },
            (value, index) => start + index * step
        );

    const [page, setPage] = useState(parseInt(currentPage))
    const [last, setLast] = useState(parseInt(lastPage))

    const [pagePanel, setPagePanel] = useState(false)

    document.addEventListener("click", clickOutside, false);
    function clickOutside(e) {
        const pagePanelDiv = document.getElementById('page-panel')
        if (pagePanelDiv) {
            const inside = document.getElementById('page-panel').contains(e.target);
            setPagePanel(inside)
        } else return
    }
    const navigateToPage = (p) => {
        searchParams.set('page', p)
        var url = new URL(window.location.href);
        setCurrentPage(p)
        navigate({ pathname: url.pathname, search: '?' + searchParams.toString() })
    }

    useEffect(() => {
        setLast(parseInt(lastPage))
    }, [lastPage])

    useEffect(() => {
        setPage(parseInt(currentPage))
    }, [currentPage])

    return (

        <div className="relative h-10 flex items-center text-xl font-light gap-3">
            {
                pagePanel && <div id='page-panel' className="absolute flex flex-row gap-2 -top-7 right-0 w-28 h-8 border p-1 justify-center rounded-myb shadow">
                    <input id='page-input' type="number" className="w-16 outline outline-1 rounded-myb ps-2 py-1" max={last + 1} min={1} />
                    <button className='self-center' onMouse onClick={() => {
                        let selectedPage = parseInt(document.getElementById('page-input').value)
                        if (selectedPage > 0 && selectedPage < last + 2)
                            navigateToPage(parseInt(selectedPage - 1))
                        setPagePanel(false)
                    }}>Go</button>
                </div>}
            <button disabled={page === 0} onClick={() => { navigateToPage(parseInt(page - 1)) }}> &lt;</button>
            <div className=" w-fit flex flex-row items-center justify-center gap-2">
                {
                    [...Array(page).keys()].length > 2 ?
                        <>
                            <button onClick={() => { navigateToPage(parseInt(0)) }}>1</button>
                            <button onClick={(e) => {
                                setPagePanel(true)
                                e.stopPropagation()
                            }}>...</button>
                            <button onClick={() => { navigateToPage(parseInt(page - 1)) }}>{page}</button>
                        </> :
                        <>
                            {[...Array(page).keys()].map(i => <button onClick={() => { navigateToPage(parseInt(i)) }}>{i + 1}</button>)}
                        </>
                }
                <button className="underline">{page + 1}</button>
                {
                    arrayRange(page, last - 1, 1).length > 2 ?
                        <>
                            <button onClick={() => { navigateToPage(parseInt(page + 1)) }}>{page + 2}</button>
                            <button onClick={(e) => {
                                setPagePanel(true)
                                e.stopPropagation()
                            }}>...</button>
                            <button onClick={() => { navigateToPage(parseInt(last)) }}>{last + 1}</button>
                        </>
                        :
                        <>
                            {
                                arrayRange(page + 1, last, 1).map(i => <button onClick={() => { navigateToPage(parseInt(i)) }}>{i + 1}</button>)
                            }
                        </>
                }
                { }
            </div>
            <button disabled={page === last} onClick={() => { navigateToPage(parseInt(page + 1)) }}> &gt;</button>
        </div>
    )
}






/*
        <div className="mt-2 flex w-full justify-end flex-row gap-2">
            <select className='border-2 border-slate-200 rounded-md' defaultValue={searchParams.get('page') ? searchParams.get('page') : 0} onChange={(e) => {
                if (e.target.value === currentPage) return
                var url = new URL(window.location.href);
                searchParams.set('page', e.target.value)

                navigate({ pathname: url.pathname, search: '?' + searchParams.toString() }, { replace: true })
            }}>
                {[...Array(lastPage + 1).fill(0)].map((_, i) => (
                    <option key={i} value={i}>{i + 1}</option>
                ))}
            </select>
            of {lastPage + 1}
                </div>*/