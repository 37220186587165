import 'App.css';
import { RequireToken } from 'auth/auth';
import { AxiosInterceptors } from 'auth/axiosInterceptors';
import { FirstUseOverlay } from 'components/commons/firstUse';
import { LoadingOverlay } from 'components/commons/loadingOverlay';
import { Sidebar } from 'components/commons/NewSidebar';
import NavigationHeader from 'components/navigationHeader/navigationHeader';
import NotificationProvider from 'components/notificationSystem/notificationContext';
import { CustomNavigate } from 'helpers/hooks/customNavigate';
import { identifyJune, initializeJune } from 'helpers/hooks/useJune';
import { useEffect, useState } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { ApiKeysRoute } from 'routes/Api Keys';
import { LoginRoute } from 'routes/Auth';
import { CreateUseCase } from 'routes/Create Use Case';
import { DashboardRoute } from 'routes/Dashboard';
import { ResultRoute } from 'routes/Document Result';
import { ErrorRoute } from 'routes/Error';
import { MobileRoute } from 'routes/Mobile';
import { OrgRoute } from 'routes/Organization';
import { ResetPasswordRoute } from 'routes/Reset Pasword';
import { ServiceUnavailableRoute } from 'routes/Service Unavailable/serviceUnavailableRoute';
import { OrgSetupRoute } from 'routes/Setup Org';
import { UseCaseRoute } from 'routes/UseCase';
import { UseCaseMonitorRoute } from 'routes/UseCase Monitor';
import { UseCaseSettingsRoute } from 'routes/UseCase Settings';
import { UserRoute } from 'routes/User';
import { PlansRoute } from 'routes/User Plans';
import { ValidationRoute } from 'routes/Validation';
import { VerifyAccount } from 'routes/Verify Account';

function App() {
  let analytics = initializeJune()

  const mobile = window.innerWidth <= 768

  const [loadingData, setLoadingData] = useState(false)
  const [navigateAfterLoad, setNavigateAfterLoad] = useState({})
  const [refresh, setRefresh] = useState()
  const [firstUse, setFirstUse] = useState(false)

  const [userInfo, setUserInfo] = useState(null)

  useEffect(() => {
    const trackingInit = () => {
      identifyJune(userInfo.username, { email: userInfo.email }, analytics);
    }
    userInfo && trackingInit()

  }, [userInfo])

  const customRouter = createBrowserRouter([{
    path: '/',
    element:
      <AxiosInterceptors>
        <div className='w-screen h-screen flex flex-col border'>
          <NavigationHeader userInfo={userInfo} setUserInfo={setUserInfo} />
          <LoadingOverlay loadingData={loadingData} navigateAfterLoad={navigateAfterLoad} refresh={refresh} setRefresh={setRefresh} />
          <FirstUseOverlay firstUse={firstUse} setFirstUse={setFirstUse} />
          <div className='flex flex-row grow min-h-0 w-screen border max-w-full h-screen'>

            <Sidebar />

            <Outlet />

          </div>
    </div>
      </AxiosInterceptors>,
    children: [
      {
        path: '/org-setup',
        element: <OrgSetupRoute setLoadingData={setLoadingData} setRefresh={setRefresh} setNavigateAfterLoad={setNavigateAfterLoad} userInfo={userInfo} analytics={analytics} />,
      },
      {
        path: '/',
        element: <RequireToken setUserInfo={setUserInfo}> <DashboardRoute setFirstUse={setFirstUse} userInfo={userInfo} analytics={analytics} /></RequireToken>,
      },
      {
        path: '/user',
        element: <RequireToken setUserInfo={setUserInfo}><UserRoute /></RequireToken>,
      },
      {
        path: '/create-usecase',
        element: <RequireToken setUserInfo={setUserInfo}><CreateUseCase setLoadingData={setLoadingData} setNavigateAfterLoad={setNavigateAfterLoad} loadingData={loadingData} analytics={analytics} /></RequireToken>,
      },
      {
        path: '/usecase/:usecaseID',
        element: <RequireToken setUserInfo={setUserInfo}><CustomNavigate subPath={'/documents'} /></RequireToken>,
        loader: async ({ params }) => params
      },
      {
        path: '/usecase/:usecaseID/documents',
        element: <RequireToken setUserInfo={setUserInfo}><UseCaseRoute analytics={analytics} /></RequireToken>,
        loader: async ({ params }) => params
      },
      {
        path: '/usecase/:usecaseID/monitor',
        element: <RequireToken setUserInfo={setUserInfo}><UseCaseMonitorRoute /></RequireToken>,
        loader: async ({ params }) => params
      },
      {
        path: '/usecase/:usecaseID/settings',
        element: <RequireToken setUserInfo={setUserInfo}><CustomNavigate subPath={'/general'} /></RequireToken>,
        loader: async ({ params }) => params
      },
      {
        path: '/usecase/:usecaseID/settings/general',
        element: <RequireToken setUserInfo={setUserInfo}><UseCaseSettingsRoute /></RequireToken>,
        loader: async ({ params }) => params
      },
      {
        path: '/usecase/:usecaseID/result/:documentID',
        element: <RequireToken setUserInfo={setUserInfo}><ResultRoute analytics={analytics} /></RequireToken>,
        loader: async ({ params }) => params
      },
      {
        path: '/usecase/:usecaseID/settings/validation',
        element: <RequireToken setUserInfo={setUserInfo}><ValidationRoute /></RequireToken>,
        loader: async ({ params }) => params
      },
      {
        path: '/usecase/:usecaseID/settings/api-keys',
        element: <RequireToken><ApiKeysRoute /></RequireToken>,
        loader: async ({ params }) => params
      },
      {
        path: '/organization',
        element: <RequireToken setUserInfo={setUserInfo}><OrgRoute /></RequireToken>,
      },
      {
        path: '/plans',
        element: <RequireToken setUserInfo={setUserInfo}><PlansRoute /></RequireToken>,
      },

      {
        path: '/login',
        element: <LoginRoute setUserInfo={setUserInfo} analytics={analytics} />
      },
      {
        path: '/user/password/reset/confirm',
        element: <ResetPasswordRoute />
      },
      {
        path: '/user/account/verify',
        element: <VerifyAccount />
      },
      {
        path: '/service-unavailable',
        element: <ServiceUnavailableRoute />
      },
      {
        path: '*',
        element: <ErrorRoute />
      }

    ]
  }
  ])

  const mobileRouter = createBrowserRouter([
    {
      path: '/',
      element:
        <AxiosInterceptors>
          <div className="h-screen w-screen !overflow-hidden">
            <Outlet />
          </div>
        </AxiosInterceptors>,
      children: [
        {
          path: '/',
          element: <RequireToken setUserInfo={setUserInfo}><Navigate to={'/login'} /></RequireToken>,
        }, {
          path: '/login',
          element: <LoginRoute mobile={mobile} setUserInfo={setUserInfo} />
        },
        {
          path: '/user/password/reset/confirm',
          element: <ResetPasswordRoute mobile={mobile} />
        },
        {
          path: '/user/account/verify',
          element: <VerifyAccount mobile={mobile} />
        },
        {
          path: '*',
          element: <MobileRoute />
        },]
    }
  ])

  return (
    <NotificationProvider>
      <RouterProvider router={mobile ? mobileRouter : customRouter} />
    </NotificationProvider>
  );
}

export default App;
