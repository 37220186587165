import { Navigate, useLocation } from 'react-router-dom';

export const CustomNavigate = ({ subPath }) => {


    let original_path = useLocation().pathname;
    original_path = original_path[original_path.length - 1] === '/' ? original_path.substring(0, original_path.length - 1) : original_path
    const toPath = original_path + subPath

    return (
        <Navigate to={toPath} replace />
    )
}