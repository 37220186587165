import { requestResetPassword } from "auth/auth"
import { Button } from "components/button/Button"
import { useNotifications } from "components/notificationSystem/notificationContext"

import React from "react"

export const ResetPasswordPanel = ({ setCurrentPanel }) => {

    const [email, setEmail] = React.useState('')

    const notification = useNotifications()
    React.useEffect(() => {
        let GoogleScript = document.createElement('script')
        GoogleScript.src = `https://www.google.com/recaptcha/api.js`
        document.body.appendChild(GoogleScript)
    }, [])

    const handleRequestResetPassword = async () => {
        await requestResetPassword(email).then(() => {
            notification.addNotification({
                title: 'Request sent',
                message: 'Request for the password reset sent. Check your mail box.',
                severity: 'DEFAULT',
                timeout: 3000
            })

        }).catch((e) => {
            notification.addNotification({
                title: 'Request Error',
                message: "Can't send password reset request. Try again.",
                severity: 'ERROR',
                timeout: 5000
            })

            setCurrentPanel('login')
        })
    }

    function onSubmit(token) {
        handleRequestResetPassword()
    }
    window.onSubmit = onSubmit


    return (

        <form onSubmit={(e) => {
            e.preventDefault()
        }} className="w-full max-w-[700px] h-fit !max-h-screen bg-transparent rounded-myb py-2 overflow-auto">

            <header className="text-center text-title text-primary-color font-bold pb-5">Reset password</header>

            <div className='mx-auto relative h-fit w-2/3 outline-none pb-5'>
                <label className='font-semibold'> Email * </label>

                <input id='email-input' className='h-10 px-4 w-full border rounded-myb text-lg' type="email" placeholder="user@mail.com"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    onFocus={(e) => e.target.style.outline = ''} />

                <label id='email-required' className='absolute -bottom-5 right-0 text-red-400'></label>
            </div>

            <div className='flex flex-col gap-10 w-2/3 mx-auto'>
                <div className="login-form-span flex flex-col gap-2">
                    <div className="login-form-span flex flex-row gap-1" >Back to login <button type='button' className='underline text-blue-700 focus:text-primary-color' onClick={(e) => {
                        e.preventDefault()
                        setCurrentPanel('login')
                    }}>Login</button></div>
                </div>

                <div className='w-full h-12 px-5 flex flex-col sm:flex-row justify-center items-center gap-4'>
                    <div className=" w-[380px] flex flex-col justify-between items-center">
                        <Button
                            type='recaptcha' disabled={email === ''} rounded>Send Email</Button>
                    </div>
                </div>
            </div>


        </form>
    )
}