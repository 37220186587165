import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

type MenuType = {
  title: string;
  svg: JSX.Element;
  to: string;
  children: Array<MenuType>;
};

type Props = {
  sidebar: boolean;
  item: MenuType;
  isChildren?: boolean;
};

const linkColor = "#DCDBFF";

export const MenuComponent = ({ sidebar, item, isChildren = false }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = useState<boolean>(false);
  const [showChildren, setShowChildren] = useState<boolean>(false);

  const checkShowChildren = () => {
    if (!active) return;
    const parentPath = location.pathname.split("/")[3];
    const parentToPath = item.to.split("/")[3];
    setShowChildren(parentPath === parentToPath);
  };

  useEffect(() => {
    const locationParts = location.pathname.split("/");
    const itemParts = item.to.split("/");

    const targetLocation = locationParts[locationParts.length - 1];
    const scopeTargetLocation = locationParts[locationParts.length - 2];
    const menuItemLocation = itemParts[itemParts.length - 1];

    // Check if the current page matches the menu item
    if (targetLocation === menuItemLocation) {
      setActive(true);
    } else {
      setActive(false);
    }

    // Special handling for settings page
    if (item.children.length && scopeTargetLocation === "settings" && item.title === "Settings") {
      setActive(true);
    }

    // Check if children should be shown
    const settingsPages = ["api-keys", "validation", "general"];
    if (settingsPages.includes(targetLocation)) {
      checkShowChildren();
    } else {
      setShowChildren(false);
    }
  }, [location, item]);
  /*
  useEffect(() => {
    const subPaths = location.pathname.split("/");
    let toSearch = item.to.split("/")[item.to.split("/").length - 1];
    if (item.to === "/") toSearch = "dashboard";
    if (subPaths.find((x) => x === toSearch)) setActive(true);
    else {
      if (
        item.to.split("/")[item.to.split("/").length - 1] === "settings" &&
        isChildren === false
      ) {
        if (subPaths.find((x) => x === "validation" || x === "apikeys"))
          setActive(true);
        else setActive(false);
      } else {
        setActive(false);
      }
    }
    item.children.length > 0 && checkShowChildren();
  }, [location, item]);
  */


  return (
    <div className={`w-full`}>
      {" "}
      <div
        className={`flex flex-row ${
          isChildren ? "w-auto" : "w-full"
        } 
       {/*me-6*/}
        gap-5 px-4 py-2 rounded-myb !transition-all !duration-150 cursor-pointer hover:!bg-[#d7e0faa3] ${
          active && "!bg-[#d7e0faa3]"
        }`}
        style={{
          marginLeft: isChildren ? "0px" : 0,
        }}
        onClick={() => {
          navigate(item.to);
        }}
      >
        {isChildren ? <div className="w-10">{item.svg}</div> : <div className="w-10">{item.svg}</div>}
        <span
          className={`${isChildren? 'text-clickable-small' : 'text-clickable-base'} relative block origin-left !overflow-hidden !whitespace-nowrap !text-ellipsis !transition-all !duration-300`}
          style={{
            maxWidth: sidebar ? "100%" : "0px",
          }}
        >
          {item.title}
        </span>
      </div>
      {showChildren && sidebar && item.children.length > 0 && (
        <div className="flex flex-col gap-2 bg-[#f5f7fd] pt-2">
          {item.children.map((subItem) => (
            <MenuComponent sidebar={sidebar} item={subItem} isChildren={true} />
          ))}
        </div>
      )}
    </div>
  );
};
