import { CircleCancel } from "assets/customButtons/CircleCancel";
import { Button } from "components/button/Button";
import { InfoToolTip } from "components/infoToolTip/infoToolTip";
import { useContext, useEffect, useState } from "react";
import { PiRectangleDashed } from "react-icons/pi";
import Select from "react-select";
import stc from "string-to-color";
import { StageContext } from "../stageContext";
interface CircleProps {
  radius: number;
  bgColor: string;
  label: string;
}

const Circle: React.FC<CircleProps> = ({ radius, bgColor, label }) => {
  const [hover, setHover] = useState(false);

  const circleStyle: React.CSSProperties = {
    width: `${radius * 2}px`,
    height: `${radius * 2}px`,
    backgroundColor: bgColor,
    borderRadius: "50%",
    display: "inline-block",
    position: "relative",
  };

  const labelStyle: React.CSSProperties = {
    display: hover ? "block" : "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -100%)",
    padding: "5px 10px",
    backgroundColor: "#1a3066cc",
    color: "white",
    borderRadius: "5px",
    whiteSpace: "nowrap",
    fontSize: "small",
  };

  return (
    <div className={"p-1"}>
      <div
        style={circleStyle}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div style={labelStyle}>{label}</div>
      </div>
    </div>
  );
};

type Dict<T> = {
  [key: string]: T;
};

type Props = {
  unusedLabels: Array<any>;
  setCreateMode: React.Dispatch<React.SetStateAction<boolean | any>>;
  createMode: boolean | any;
  setAddSimpleAnnotationMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddNewAnnotation: (entity: any) => Promise<void>;
  handleEditAnnotation: (entity: any) => Promise<void>;
  handleDeleteAnnotation: (entity: any) => Promise<void>;
  editField: {
    label: string;
    tag_alias: string;
    "bounding-boxes": any;
    id: number;
    text: string;
  } | null;
  setEditField: React.Dispatch<
    React.SetStateAction<{
      label: string;
      tag_alias: string;
      "bounding-boxes": any;
      id: number;
      text: string;
    } | null>
  >;
  setEditSimpleAnnotation: React.Dispatch<React.SetStateAction<any>>;
};

export const CreateSimpleEntityPanel = ({
  unusedLabels,
  setCreateMode,
  createMode,
  setAddSimpleAnnotationMode,
  handleAddNewAnnotation,
  handleEditAnnotation,
  handleDeleteAnnotation,
  editField,
  setEditField,
  setEditSimpleAnnotation,
}: Props) => {
  const [selectOptions, setSelectOptions] = useState<Array<Dict<any>> | null>(
    null
  );

  const [formLabel, setFormLabel] = useState<any>(null);
  const [formText, setFormText] = useState<any>("");

  useEffect(() => {
    if (!unusedLabels || unusedLabels.length === 0) return;
    let temp = [];
    if (editField) {
      temp.push({
        value: editField.label,
        label: (
          <div className="flex flex-row gap-2 items-center h-4">
            <Circle
              radius={6}
              bgColor={stc(editField.label)}
              label={"Label Color"}
            />
            {editField.tag_alias}
          </div>
        ),
      });
    } else {
      for (let x in unusedLabels) {
        temp.push({
          value: unusedLabels[x].tag,
          label: (
            <div className="flex flex-row gap-2 items-center h-4">
              <Circle
                radius={6}
                bgColor={stc(unusedLabels[x].tag)}
                label={"Label Color"}
              />
              {unusedLabels[x].tag_alias}
            </div>
          ),
        });
      }
    }
    setSelectOptions([...temp]);
  }, [unusedLabels, editField]);

  let stageContext = useContext(StageContext);
  const [transformerLayer, setTransformerLayer] = useState<any>();

  useEffect(() => {
    setTransformerLayer(
      stageContext?.stageRef
        ?.current!.getLayers()
        .find((x) => x.id() === "transformer-layer")
    );
  }, [stageContext?.stageRef]);

  const getTransformerInfo = (transformer: any) => {
    let x1, x2, y1, y2;
    if (transformer && transformer._nodes) {
      let rectToSave = transformer._nodes[0].attrs;
      x1 = Math.trunc(rectToSave.x);
      y1 = Math.trunc(rectToSave.y);
      x2 = rectToSave.scaleX
        ? Math.trunc(rectToSave.x + rectToSave.width * rectToSave.scaleX)
        : Math.trunc(rectToSave.x + rectToSave.width);
      y2 = rectToSave.scaleY
        ? Math.trunc(rectToSave.y + rectToSave.height * rectToSave.scaleY)
        : Math.trunc(rectToSave.y + rectToSave.height);
    }

    let p1 = [x1, y1];
    let p2 = [x2, y1];
    let p3 = [x2, y2];
    let p4 = [x1, y2];

    return [p1, p2, p3, p4];
  };

  const handleCreateNewSimpleAnnotation = async () => {
    let transformer = transformerLayer.children[0];
   /* if (!transformer) {
      if (
        !window.confirm(
          "Do you really want to create a new field without drawing a box?"
        )
      )
        return;
    }*/
    let newEntity;
    let bb =
      transformer && transformer._nodes ? getTransformerInfo(transformer) : [];

    newEntity = {
      label: formLabel,
      text: formText,
      bounding_boxes: bb,
      confidence: 0,
    };

    await handleAddNewAnnotation(newEntity).then(() => {
      if (transformer) transformer.nodes([]);
      setAddSimpleAnnotationMode(false);
      stageContext?.setAnnotationRect(null);
      handleClosePanel();
    });
  };

  const handleCreateUpdatedSimpleAnnotation = async () => {
    let transformer = transformerLayer.children[0];

    let newEntity;
    let bb =
      transformer && transformer._nodes ? getTransformerInfo(transformer) : [];

    newEntity = {
      label: formLabel,
      text: formText,
      bounding_boxes: bb,
      id: editField?.id,
    };

    await handleEditAnnotation(newEntity).then(() => {
      if (transformer) transformer.nodes([]);
      setAddSimpleAnnotationMode(false);
      if (stageContext?.annotationRect)
        setTimeout(() => stageContext?.annotationRect.toShow.show(), 100);
      stageContext?.setAnnotationRect(null);
      handleClosePanel();
    });
  };

  const handleClosePanel = () => {
    setCreateMode(false);
    setAddSimpleAnnotationMode(false);
    setEditField(null);
    setEditSimpleAnnotation(null);
    stageContext?.setSelectedText("");
    let transformer = transformerLayer.children[0];
    if (!transformer) return;
    transformer.nodes[0] = null;
  };

  useEffect(() => {
    if (!editField) return;

    setFormLabel(editField.label);
    setFormText(editField.text);
  }, [editField]);

  useEffect(() => {
    if (!createMode || typeof createMode !== "string") return;
    setFormLabel(createMode);
  }, [createMode]);

  useEffect(() => {
    setFormText(stageContext?.selectedText);
  }, [stageContext?.selectedText]);

  const handleDeleteSimpleAnnotation = async (entity: any) => {
    await handleDeleteAnnotation(entity).then(() => {
      handleClosePanel();
    });
  };

  return (
    <div className=" h-fit flex flex-col w-full gap-5 border rounded-myb">
      <div className="h-8 bg-[#EAEBFB] w-full flex items-center px-2 gap-2">
        <div className="flex flex-between gap-2">
          <span className="font-small font-bold text-primary-color">
            {editField ? "Edit Field" : "Add Field"}
          </span>
          <InfoToolTip
            text={
              "Provide the text value for the new field. Additionally, use the canvas to draw a bounding box at the field location"
            }
          ></InfoToolTip>
        </div>
        <div
          className="absolute right-4 cursor-pointer"
          onClick={() => {
            handleClosePanel();
          }}
        >
          <CircleCancel stroke="#1a3066" />
        </div>
      </div>
      <div className="bg-white h-fit px-2">
        <label className="flex flex-col gap-1 mb-2">
          <div>
            <span className={"block font-medium text-gray-800"}>
              Select label
            </span>
          </div>
          {selectOptions && (
            <Select
              options={editField ? selectOptions : selectOptions}
              defaultValue={
                editField
                  ? selectOptions[0]
                  : typeof createMode === "string"
                  ? selectOptions.find((x) => x.value === createMode)
                  : ""
              }
              onChange={(e) => setFormLabel(e?.value)}
              isDisabled={editField ? true : false}
            />
          )}
        </label>

        <label className="flex flex-col gap-2 mb-2">
          <div>
            <span className={"block font-medium text-gray-800"}>
              Input Text
            </span>
          </div>
          <div className={"relative block text-gray-900"}>
            <div className="absolute box-border self-stretch right-9 top-0 my-2 !w-[1px] !h-[20.1px] bg-[hsl(0,0%,80%)]"></div>
            <input
              onChange={(e) => setFormText(e.target.value)}
              defaultValue={editField ? editField.text : ""}
              value={formText}
              type="text"
              id="large-input"
              className="block h-9 w-full ps-2 pe-9 text-gray-900 border rounded-[4px] text-base border-[hsl(0,0%,80%)] "
            />
            <button
              className="absolute top-[0.55rem] right-1 bg-transparent h-5 w-8 flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault();
                if (editField) setEditSimpleAnnotation(editField);
                else setAddSimpleAnnotationMode(true);
              }}
              disabled={
                editField?.["bounding-boxes"] === null ||
                editField?.["bounding-boxes"].length === 0
              }
            >
              <PiRectangleDashed
                size={22}
                style={{
                  color: "white",
                }}
              />
            </button>
          </div>
        </label>

        {/*<label className="flex flex-row gap-4 mb-2 items-center">
          <span className={"block font-medium text-gray-800"}>
            {editField ? "Edit box" : "Draw a box"}
          </span>
          <Button
            action={() => {
              if (editField) setEditSimpleAnnotation(editField);
              else setAddSimpleAnnotationMode(true);
            }}
          >
            {editField ? "Edit box" : "Draw"}
          </Button>
        </label>*/}

        {editField ? (
          <div className="flex flex-row !w-full justify-center items-center gap-2 mb-3 mt-4">
            <Button
              className="!w-2/3"
              action={() => {
                handleCreateUpdatedSimpleAnnotation();
              }}
              disabled={!formLabel || formText === ""}
            >
              Update
            </Button>
            <Button
              className="!w-1/3 hover:bg-red-500 hover:text-white hover:border-white"
              action={() => {
                handleDeleteSimpleAnnotation(editField);
              }}
            >
              Delete
            </Button>
          </div>
        ) : (
          <div className="flex flex-row !w-full justify-center items-center gap-2 mb-3 mt-4">
            <Button
              className="!w-full"
              action={() => {
                handleCreateNewSimpleAnnotation();
              }}
              disabled={!formLabel || formText === ""}
            >
              Create
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
