
import * as AUTH from "auth/auth"
import { jwtDecode } from "jwt-decode"

export const loginFun = async (email, password, notification) => {
    const [newToken, status] = await AUTH.loginCall(email, password, notification)
    if (status === 200) {
        if (newToken) {
            const decoded = jwtDecode(newToken['access_token'])
            AUTH.setToken(newToken, decoded['org'])
            return true
        }
    }
    return false
}

export const registerFun = async (email, password, name, surname, role, phone, token, mobile, notification) => {
    await AUTH.register(email, password, name, surname, role, phone, token).then(() => {

        notification.addNotification({
            title: 'Great, please verify your email.',
            message: "We've sent a verification link to your email. Click it to finalize your account setup. Don't forget to check your spam folder if you don't see it. Welcome aboard!",
            severity: 'SUCCESS',
            timeout: 10000
        })

        return true
    }).catch((e) => {
        notification.addNotification({
            title: "Registration Error",
            message: e.response.data.detail,
            severity: 'ERROR',
            timeout: 10000
        })
        return false
    })

}