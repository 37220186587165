import eyeClosed from 'assets/form/eyeClosed.svg';
import eyeOpened from 'assets/form/eyeOpened.svg';
import { Button } from "components/button/Button";
import { useNotifications } from 'components/notificationSystem/notificationContext';
import React from "react";

export const LoginPanel = ({ loginCallback, setCurrentPanel }) => {

    const [showPassword, setShowPassword] = React.useState(false)

    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')

    React.useEffect(() => {
        let GoogleScript = document.createElement('script')
        GoogleScript.src = `https://www.google.com/recaptcha/api.js`
        document.body.appendChild(GoogleScript)
    }, [])

    const notification = useNotifications()
    const onSubmit = () => {

        let check = true;
        if (checkEmailInput()) {
            document.getElementById('email-input').style.outline = 'solid 2px red'
            check = false;
        }

        if (checkPasswordInput()) {
            document.getElementById('password-input').style.outline = 'solid 2px red'
            check = false;
        }

        if (check)
            loginCallback(email, password, notification)
    }
    window.onSubmit = onSubmit


    const checkEmailInput = (e) => {
        if (!document.getElementById('email-input')) return true;
        let value = e ? e.value : document.getElementById('email-input').value;
        if (value === '')
            return true;

        return false
    }

    const checkPasswordInput = (e) => {
        if (!document.getElementById('password-input')) return true;
        let value = e ? e.value : document.getElementById('password-input').value;
        if (value === '')
            return true;

        return false
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault()
        }} className="w-full max-w-[700px] h-fit !max-h-screen bg-transparent rounded-myb py-2 overflow-auto">
         
                <header className="text-center text-title text-primary-color font-bold pb-5">Login</header>

                <div className='mx-auto relative h-fit w-2/3 outline-none pb-5'>
                    <label className='font-semibold'> Email * </label>

                    <input id='email-input' className='h-10 px-4 w-full border rounded-myb text-lg' type="email" placeholder="user@mail.com"
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        onFocus={(e) => e.target.style.outline = ''} />

                    <label id='email-required' className='absolute -bottom-5 right-0 text-red-400'></label>
                </div>
                <div className=' mx-auto relative h-fit w-2/3 outline-none pb-5'>

                    <label className='font-semibold'> Password * </label>
                    <div className='relative w-full h-10'>

                        <input id='password-input' className='h-10 px-4 w-full border rounded-myb text-lg' type={showPassword ? 'text' : 'password'} placeholder="password" onChange={(e) => {

                            setPassword(e.target.value)
                        }}
                            onFocus={(e) => e.target.style.outline = ''} />

                        <img className='absolute w-8 bottom-1 right-2' src={showPassword ? eyeClosed : eyeOpened} alt='Show Password' onClick={() => setShowPassword(!showPassword)} />
                    </div>
                </div>
                <div className='flex flex-col gap-10 w-2/3 mx-auto'>
                    <div className="login-form-span flex flex-col gap-2">
                        <div className='flex flex-row gap-1'>
                            <span>
                                Forgot your password?
                            </span>
                            <button className='underline text-blue-700 focus:text-primary-color' type='button' onClick={(e) => {
                                e.preventDefault()
                                setCurrentPanel('reset')
                            }}> Reset password</button>
                        </div>
                        <div className="login-form-span flex flex-row gap-1" >No account? <button type='button' className='underline text-blue-700 focus:text-primary-color' onClick={(e) => {
                            e.preventDefault()
                            setCurrentPanel('register')
                        }}>Register</button></div>
                    </div>

                    <div className='w-full h-12 px-5 flex flex-col sm:flex-row justify-center items-center gap-4'>
                        <div className=" w-[380px] flex flex-col justify-between items-center">
                            <Button
                                type='recaptcha' disabled={email === '' && password === ''} rounded>Login</Button>
                        </div>
                    </div>
                </div>

           
        </form>

    )
}