import { useEffect } from "react";
import scrittaSVG from 'assets/logo/myb_logo.png';

export function MobileCheck({ setMobile }) {

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 768);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

}

export function MobileRoute() {


    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center gap-5">
            <div className="w-full px-10">
                <img src={scrittaSVG} alt='myBiros' />
            </div>
            <div className="w-4/5">
                <p className="text-center font-semibold">
                    For the best user experience, the demo is currently only available on desktop devices.
                </p>
            </div>
        </div>
    )

}