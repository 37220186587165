import { InfoSVG } from 'assets/usecase/InfoSVG'
import { useRef } from 'react'

export const InfoToolTip = ({ text = 'tooltip default description' }) => {

    const tooltipRef = useRef()

    const hitboxRef = useRef()
    const showToolTip = (e) => {
        tooltipRef.current.style.opacity = 1

        tooltipRef.current.style.top = -hitboxRef.current.getBoundingClientRect().height * 2 + 'px'
        tooltipRef.current.style.left = hitboxRef.current.getBoundingClientRect().width + 5 + 'px'
    }

    const hideToolTip = (e) => {
        tooltipRef.current.style.opacity = 0
    }

    return (
        <div ref={hitboxRef} className='relative w-4 py-0.5' onMouseEnter={(e) => {
            showToolTip(e)
        }}

            onMouseLeave={(e) => {
                hideToolTip(e)
            }}
        >
            <div ref={tooltipRef} className={`absolute flex flex-row wrap justify-start items-end max-w-[300px] min-w-[100px] w-[300px] h-auto bg-white shadow border rounded-t-myb rounded-e-myb px-2 py-1 pointer-events-none !z-50 !transition-opacity !duration -top-[1000px]]`}
                style={{
                    opacity: 0,
                }}>
                <span className='text-start text-sm font-small'>
                    {text}
                </span>
            </div>
            <InfoSVG width={20} height={20} />
        </div>
    )
}