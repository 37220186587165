import { Button } from "components/button/Button";
import { useState } from "react";
type Props = {
  labels: Array<any>;
  backCallback: () => void;
  saveSelectedScoreLabel: (labels: any, score: number) => void;
};

export const SubScoreFields = ({
  labels,
  backCallback,
  saveSelectedScoreLabel,
}: Props) => {
  const [selectedLabel, setSelectedLabel] = useState<any>(null);
  const [score, setScore] = useState<number>(50);

  const handleLabel = (label: any) => {
    if (!selectedLabel) setSelectedLabel(label);
    else {
      if (selectedLabel === label) setSelectedLabel(null);
      else setSelectedLabel(label);
    }
  };

  /*const checkLabel = (label: Array<any>) => {
    return selectedLabels.find((x) => x[0] === label[0]);
  };*/

  const handleBackBtn = () => {
    setScore(50);
    setSelectedLabel(null);
    backCallback();
  };

  return (
    <div className="relative h-full w-full flex flex-col grow">
      <div className="w-full h-14 flex flex-row items-center justify-center">
        <span className="text-subtitle-small text-center">
          {selectedLabel ? (
            <>Set a score threshold for the selected label.</>
          ) : (
            <>
              Select a label.

            </>
          )}
        </span>
      </div>
      <div
        className={`h-[80%] min-h-0 w-full flex flex-col items-center ${
          selectedLabel ? "justify-start" : "justify-center"
        } gap-5`}
      >
        {!selectedLabel && (
          <div className="w-max min-w-[400px] bg-primary-color h-[90%] min-h-[90%] max-h-[90%] bg-white shadow rounded-myb flex flex-col gap-2 px-2 py-2 overflow-y-scroll">
            {labels.map((x) => (
              <div
                className="text-lg py-1 px-2 flex justify-between shadow rounded-myb hover:bg-slate-100"
                style={{
                  outline:
                    selectedLabel && selectedLabel[0] === x[0]
                      ? "1px solid yellow"
                      : "",
                }}
                onClick={() => {
                  handleLabel(x);
                }}
              >
                <span> {x[1].tag_alias}</span>
              </div>
            ))}
          </div>
        )}
        {selectedLabel && (
          <div className="flex flex-row items-center justify-center mt-10 ">
            Label:
            <div className="w-fit  py-1 px-2 flex shadow rounded-myb bg-white mx-2">
              <span> {selectedLabel[1].tag_alias}</span>
            </div>
            <div
              className="!cursor-pointer"
              onClick={() => {
                setSelectedLabel(null);
              }}
            >
              <span className=" text-xs bg-slate-100 rounded shadow p-2 hover:bg-slate-50 mx-1">
                Change Label
              </span>
            </div>
          </div>
        )}
        <div className=" w-2/3 h-full flex flex-col">
          {selectedLabel && (
            <>
              <div className="flex items-center justify-center py-2 text-normal-small">
                {score}
              </div>
              <input
                id="minmax-range"
                type="range"
                min="0"
                max="100"
                value={score}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                onChange={(e) => {
                  setScore(Number(e.target.value));
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className="sticky w-full h-20 flex flex-row items-center justify-center gap-5">
        <Button
          action={() => {
            handleBackBtn();
          }}
          rounded
        >
          Go Back
        </Button>

        <Button
          disabled={!selectedLabel}
          action={() => {
            saveSelectedScoreLabel(selectedLabel, score);
          }}
          rounded
        >
          Next
        </Button>
      </div>
    </div>
  );
};
