import { createContext, useContext, useRef, useState } from "react";
import { NotificationToast } from "./notificationToast";
type Props = {
  children: JSX.Element[];
};

export type Notification = {
  id: string;
  severity: "DEFAULT" | "SUCCESS" | "ERROR";
  title: string;
  message: string;
  timeout: number;
};

export type NotificationToAdd = {
  severity: "DEFAULT" | "SUCCESS" | "ERROR";
  title: string;
  message: string;
  timeout: number;
};

type NotificationContextType = {
  notifications: Notification[];
  addNotification: (notification: NotificationToAdd) => string;
  clearNotification: (id: string) => void;
};

export const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType
);

const NotificationContainer = ({ children }: Props) => {
  return (
    <div className="fixed bottom-10 left-0 z-50 max-w-sm min-w-fit w-full">
      {children}
    </div>
  );
};

const NotificationProvider = ({ children }: Props) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (alert: NotificationToAdd) => {
    const id =
      Math.random().toString(36).slice(2, 9) +
      new Date().getTime().toString(36);
    setNotifications((prev) => [{ ...alert, id: id }, ...prev]);
    return id;
  };

  const clearNotification = (id: string) => {
    setNotifications((prev) => prev.filter((alert) => alert.id !== id));
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, clearNotification }}
    >
      <NotificationContainer>
        {notifications.map((notification) => (
          <NotificationToast
            notification={notification}
            handleClear={() => {
              clearNotification(notification.id);
            }}
          />
        ))}
      </NotificationContainer>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const [notificationIds, setNotificationIds] = useState<string[]>([]);

  const alertIdsRef = useRef<string[]>(notificationIds);

  const { addNotification, clearNotification } =
    useContext(NotificationContext);

  const addNotificationWithId = (notification: NotificationToAdd) => {
    const id = addNotification(notification);
    alertIdsRef.current.push(id);
    setNotificationIds(alertIdsRef.current);
  };

  const clearNotifications = () => {
    alertIdsRef.current.forEach((id) => clearNotification(id));
    alertIdsRef.current = [];
    setNotificationIds([]);
  };
  return { addNotification: addNotificationWithId, clearNotifications };
};

export default NotificationProvider;
