import { Button } from "components/button/Button";
import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router";
import * as API from '../../apis/api';
import { messageIcons } from "../../assets/messageIcons/messageIcons";

import { useNotifications } from "components/notificationSystem/notificationContext";
import { notificationTypeColor } from "../../helpers/notificationColors";

export const UseCaseSettingsRoute = () => {
    const navigate = useNavigate()
    const loaderData = useLoaderData()
    const locationState = useLocation().state

    const [currentUseCase, setCurrentUseCase] = useState(null)
    const [labelsList, setLabelsList] = useState()

    const [formName, setFormName] = useState('')
    const [formDescription, setFormDescription] = useState('')


    const [navigateToDashboard, setNavigateToDashboard] = useState(false)


    useEffect(() => {
        if (navigateToDashboard) navigate('/', { replace: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateToDashboard])


    const notification = useNotifications()

    const initializeUseCase = useCallback(async () => {
        await API.getUseCase(loaderData['usecaseID']).then((res) => {
            setCurrentUseCase(res)
        }).catch((e) => {
            notification.addNotification({
                title: 'Use Case Error',
                message: "Can't find selected Use Case",
                severity: 'ERROR',
                timeout: 3000
            })

            setNavigateToDashboard(true)
        })
    }, [loaderData, navigate])

    useEffect(() => {
        locationState ? setCurrentUseCase(locationState) : initializeUseCase()
    }, [locationState, loaderData, initializeUseCase])

    useEffect(() => {
        const cookLabels = () => {
            let newLabelsList = Object.entries(currentUseCase.labels).map(([key, entry]) => {
                return { 'key': key, 'tag': entry.tag, 'tag_alias': entry.tag_alias, 'tag_multiple_values': entry.tag_multiple_values }
            })
            setLabelsList(newLabelsList)
        }
        currentUseCase && !labelsList && cookLabels()
    }, [currentUseCase, labelsList])

    const handleUpdateUseCaseInfo = async () => {
        await API.updateUseCaseInfo(
            currentUseCase.public_id,
            formName === '' ? currentUseCase.name : formName,
            formDescription === '' ? currentUseCase.description : formDescription
        ).then(async () => {
            const fetchedUseCase = await API.getUseCase(loaderData['usecaseID'])
            setCurrentUseCase(fetchedUseCase)
        })
    }

    const handleDeleteUseCase = async () => {
        if (window.prompt('Type "' + currentUseCase.name + '" in order to delete the Use Case.') === currentUseCase.name) {
            await API.deleteUseCase(currentUseCase.public_id).then(() => {
                notification.addNotification({
                    title: 'Success',
                    message: 'Use case successfully deleted!',
                    severity: 'SUCCESS',
                    timeout: 3000
                })
                setNavigateToDashboard(true)
            }).catch((e) => {
                notification.addNotification({
                    title: 'Error deleting Use Case',
                    message: e.response.data.detail,
                    severity: 'ERROR',
                    timeout: 3000
                })
            })
        }
    }

    return (
        <div className="product-bg default-page-layout relative">

            <div className="default-page-container !max-w-[80%] max-xl:px-5">

                <div className="dashboard-head">
                    <div className="page-header w-full !flex-col !items-start">
                        {currentUseCase && (
                            <div className="text-page-title ">{currentUseCase.name} </div>
                        )}
                        <div className="w-full flex flex-row justify-between">
                            <div className="text-subtitle ">General Settings </div>
                        </div>
                    </div>
                </div>


                <div className="flex flex-col gap-4 md:grid md:grid-cols-2 md:grid-rows-4 md:gap-4 py-5">

                    <div className="row-span-3">
                        <div className="flex flex-col gap-5 md:h-[470px] h-fit">
                            <div className="input-container mb-5">
                                <label className="text-normal-small text-primary-color font-semibold">Use Case Name</label>
                                <p className="text-description !text-sm">Human-friendly name for your use case, shown in dashboard</p>
                                <input type='text' placeholder={currentUseCase && currentUseCase.name} onChange={(e) => setFormName(e.target.value)} />
                            </div>

                            <div className="input-container">
                                <label className="text-normal-small text-primary-color">Use Case Description</label>
                                <p className="text-description !text-sm">Qualitative description of your use case</p>
                                <textarea className="text-normal-small" rows={3} maxLength={100} placeholder={currentUseCase && currentUseCase.description} onChange={(e) => setFormDescription(e.target.value)} />
                            </div>
                        </div>

                        <div className=" flex flex-col md:grid md:grid-cols-2 gap-5">
                            <Button className="col-span-1" rounded action={() => {
                                if (window.confirm('Only the changes will be saved.'))
                                    handleUpdateUseCaseInfo()
                            }}
                                disabled={formName === '' && formDescription === ''}>Update</Button>
                            <Button className="border-2 border-red-600 text-red-600 w-1/2" rounded action={() => handleDeleteUseCase()}>Delete Use Case</Button>

                        </div>



                    </div>
                    <div className="row-span-3">
                        <div className="flex flex-col ">
                            <div className="text-normal-small text-primary-color">
                                Labels
                            </div>
                            <div className="h-[10%] mb-2">
                                <div className="input-container">
                                    <p className={"text-description !text-sm"}>Here are the labels handled by the service</p>
                                </div>
                            </div>
                            <div className="h-[100px] md:h-[400px] border bg-white p-5 w-full !min-h-0 !min-w-0 rounded-myb flex flex-row gap-10 h-max-[600px] overflow-auto">
                                <div className="h-fit flex flex-row flex-wrap gap-3" >
                                    {labelsList && labelsList.length > 0 && labelsList.map((label) =>
                                        <div className="bg-[#f4f7fc] h-fit px-6 py-3 rounded-[20px] shadow">
                                            <p className="text-[#546681]"> {label.tag_alias} </p>
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className="input-container mt-5">
                                <div className="h-[5%] text-description !text-sm !text-primary-color">Missing Something?
                                    <a className={"ml-1"} href={"https://mybiros.com#form"} rel="noreferrer" target="_blank">
                                        <b>Contact Us!</b>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </div>

        </div>
    )
}