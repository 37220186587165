import { SubRequiredFields } from "../subSteps/subRequiredFields";
import { SubScoreFields } from "../subSteps/subScoreFields";
type Props = {
  policyType: "RequiredFields" | "FieldMinConfidence" | null | undefined;
  labels: Array<any>;
  backCallback: () => void;
  saveSelectedLabels: (labels: Array<any>) => void;
  saveSelectedScoreLabel: (label: any, score: number) => void;
};

export const StepFields = ({
  policyType,
  labels,
  backCallback,
  saveSelectedLabels,
  saveSelectedScoreLabel,
}: Props) => {
  if (!policyType) return <></>;

  return (
    <>
      {policyType === "RequiredFields" ? (
        <SubRequiredFields
          labels={labels}
          backCallback={backCallback}
          saveSelectedLabels={saveSelectedLabels}
        />
      ) : (
        <SubScoreFields
          labels={labels}
          backCallback={backCallback}
          saveSelectedScoreLabel={saveSelectedScoreLabel}
        />
      )}
    </>
  );
};
