import { useEffect, useState } from "react";
import { AnalyticsBrowser } from "@june-so/analytics-next";

export const JuneEvents = {
  SIGNED_UP: "Signed In",
  SETUP: "Use Case created",
  USED: "File Uploaded",
  AHA: "Document Result",
  ACTIVATED: "Inference Exported",
};

export function initializeJune() {
  if (process.env.REACT_APP_JUNE_ACTIVATED === "0") return null;

  const secretKey = process.env.REACT_APP_JUNE_KEY
    ? process.env.REACT_APP_JUNE_KEY
    : "";

  return AnalyticsBrowser.load({
    writeKey: secretKey,
  });
}

export function identifyJune(
  userID: string,
  userInfo: { [key: string]: any },
  analytics: AnalyticsBrowser | null
) {
  if (process.env.REACT_APP_JUNE_ACTIVATED === "0" || !analytics) return null;

  analytics.identify(userID, {
    email: userInfo.email,
    // Optional
    // name: userInfo.name,
    // Add anything else about the user here
  });
}

export function trackJune(analytics: AnalyticsBrowser | null, event: string) {
  if (process.env.REACT_APP_JUNE_ACTIVATED === "0") return null;
  if (analytics === null || analytics === undefined) return null;
  analytics.track(event);
}

export function useCustomJune() {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | undefined>(
    undefined
  );

  const secretKey = process.env.REACT_APP_JUNE_KEY
    ? process.env.REACT_APP_JUNE_KEY
    : "";

  useEffect(() => {
    const loadAnalytics = async () => {
      let response = AnalyticsBrowser.load({
        writeKey: secretKey,
      });
      setAnalytics(response);
    };
    loadAnalytics();
  }, []);

  return analytics;
}
