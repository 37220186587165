import * as API from 'apis/api'
import { JuneEvents, trackJune } from 'helpers/hooks/useJune'
import JSZip from 'jszip'

export const handleExtractAll = async (usecaseID, usecaseNAME, analytics, notification) => {
    let docsToExtract = []
    let tmpPage = 0
    let total = 0
    let tmpLastPage = 0
    let checkShown = false
    do {
        // eslint-disable-next-line no-loop-func
        await API.listAllDocuments(usecaseID, 'approved', tmpPage).then((res) => {
            if (res.total === 0) {
                if (checkShown === false) {
                    notification.addNotification({
                        title: 'No Approved documents',
                        message: "There are no approved document available to extract!",
                        severity: 'DEFAULT',
                        timeout: 3000
                    })
                    checkShown = true
                }
                return;
            }
            total = res.total
            if (res.lastPage !== tmpLastPage)
                tmpLastPage = res.last_page

            docsToExtract = [...docsToExtract, ...res.items_data]
            tmpPage++;
        })
    } while (tmpPage <= tmpLastPage && total !== 0)

    if (total === 0) return;

    return await downloadAll(docsToExtract, usecaseID, usecaseNAME, analytics)
}

export const handleExtractAllWithStatusAndDate = async (usecaseID, usecaseNAME, analytics, notification, revision_status, from_data, to_date, setExtractLoading) => {
    let docsToExtract = []
    let tmpPage = 0
    let total = 0
    let tmpLastPage = 0
    let checkShown = false
    do {
        // eslint-disable-next-line no-loop-func
        await API.listAllDocumentsDateRange(usecaseID, revision_status, from_data, to_date, tmpPage).then((res) => {
            if (res.total === 0) {
                setExtractLoading(false)

                if (checkShown === false) {
                    notification.addNotification({
                        title: 'Export Warning',
                        message: "There are no documents in the specified range!",
                        severity: 'DEFAULT',
                        timeout: 3000
                    })
                    checkShown = true
                }
                return;
            }
            total = res.total
            if (res.lastPage !== tmpLastPage)
                tmpLastPage = res.last_page

            docsToExtract = [...docsToExtract, ...res.items_data]
            tmpPage++;
        })
    } while (tmpPage <= tmpLastPage && total !== 0)

    if (total === 0) return;

    return await downloadAll(docsToExtract, usecaseID, usecaseNAME, analytics)
}

const downloadAll = async (docs, usecaseID, usecaseNAME, analytics) => {

    const zip = new JSZip()
    let total = docs.length
    let docProcessing = 1
    for (let doc of docs) {


        const response = await API.exportDocument(usecaseID, doc.public_id)
        const fileName = doc.document_metadata.original_name.replace(/(.)[a-zA-Z]*$/g, '(' + doc.public_id + ').json')
        const jsonData = JSON.stringify(response, null, 2)
        const blob = new Blob([jsonData], { type: "application/json" });
        zip.file(fileName, blob)
        let loadingState = (100 / total) * docProcessing
        docProcessing++
        document.getElementById('extract-loader').style.opacity = '1'
        document.getElementById('extract-loader').style.width = loadingState.toString() + '%'
    }

    const zipData = await zip.generateAsync({
        type: "blob",
        streamFiles: true,
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(zipData);
    link.download = usecaseNAME + '.zip'
    link.click();
    trackJune(analytics, JuneEvents.ACTIVATED)
    document.getElementById('extract-overlay').style.opacity = '1';
    document.getElementById('extract-overlay').style.pointerEvents = 'all';
    setTimeout(() => {
        document.getElementById('extract-loader').style.opacity = '0'
        document.getElementById('extract-loader').style.width = '0px'
    }, 1000)

    return true;
}

export const handleExportDoc = async (doc, usecaseID, analytics, notification) => {
    const response = await API.exportDocument(usecaseID, doc.public_id)

    const fileName = doc.document_metadata.original_name.replace(/(.)[a-zA-Z]*$/g, '')

    const jsonData = JSON.stringify(response, null, 2)
    const blob = new Blob([jsonData], { type: "application/json" });

    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link)
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href)
    trackJune(analytics, JuneEvents.ACTIVATED)
    notification.addNotification({
        title: 'Document Extraction',
        message: "Document Result Exported",
        severity: 'SUCCESS',
        timeout: 3000
    })

}