
import * as API from 'apis/api';
import { UcPlusSVG } from "assets/dashboard/UcPlusSVG";
import { fetchUser, userIsAdmin } from "auth/auth";
import { Button } from "components/button/Button";
import UseCasesCard from "components/dashboard/useCasesCard";
import { useNotifications } from 'components/notificationSystem/notificationContext';
import { identifyJune } from "helpers/hooks/useJune";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
export function DashboardRoute({ setFirstUse, userInfo, analytics }) {

    const [useCaseList, setUseCaseList] = useState(null)

    const notification = useNotifications()

    const fetchUseCaseList = useCallback(async () => {
        const fetchedUseCases = await API.listUserUseCases().then(async responseUseCases => {
            if (responseUseCases.total === 0) {
                setFirstUse(true)
            }

            return responseUseCases
        }).catch(() => {
            notification.addNotification({
                title: "Error",
                message: "Error fetching your use cases. Try to refresh the page, or contact us.",
                severity: "ERROR",
                timeout: 5000
            })
        })
        setUseCaseList(fetchedUseCases['items_data'])
    }, [setFirstUse])

    useEffect(() => {
        !useCaseList && fetchUseCaseList()
    }, [useCaseList, fetchUseCaseList])

    useEffect(() => {
        userInfo && identifyJune(fetchUser(), userInfo, analytics)
    }, [userInfo])

    const navigate = useNavigate();

    return (
        <div className="product-bg default-page-layout">
            <div className='default-page-container'>
                <div className="page-header">

                    <div className="text-page-title">Welcome {userInfo && userInfo.name}</div>
                    <Button className="!rounded text-[20px] px-10 disabled:pointer-events-none flex gap-1" action={() => { navigate('/create-usecase') }}
                        disabled={
                            !userIsAdmin() && (!useCaseList || useCaseList.length >= 3)
                        }> <UcPlusSVG width={26} height={26} /> New Use Case</Button>

                </div>


                {useCaseList &&
                    <UseCasesCard useCaseList={useCaseList} admin={userIsAdmin()} />
                }


            </div>
        </div>
    )
}