import eyeClosed from 'assets/form/eyeClosed.svg';
import eyeOpened from 'assets/form/eyeOpened.svg';
import { Button } from "components/button/Button";
import { useNotifications } from 'components/notificationSystem/notificationContext';
import { useEffect, useState } from "react";
import * as API from '../../apis/api';
import { fetchToken } from "../../auth/auth";

export function UserRoute() {

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const [newPassword, setNewPassword] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState(false)

    const [userInfos, setUserInfos] = useState(null)

    useEffect(() => {
        const fetchUser = async (token) => {
            await API.getUserInfo().then((res) => setUserInfos(res))
        }

        if (userInfos) return;
        fetchToken() && fetchUser(fetchToken())
    }, [userInfos])

    useEffect(() => {
        if (newPassword === '')
            setConfirmPassword('')

        if (document.getElementById('new-password-length')) {
            document.getElementById('new-password-length').textContent = ''
        }

        if (document.getElementById('confirm-password-match')) {
            document.getElementById('confirm-password-match').textContent = ''
        }

    }, [newPassword, confirmPassword])

    const notification = useNotifications()

    const validateUpdatePasswordForm = () => {

        if (newPassword.length < 10) {
            document.getElementById('new-password-length').textContent = 'Must be at least 10 chars long'
            return false;
        }

        if (confirmPassword !== newPassword) {
            document.getElementById('confirm-password-match').textContent = 'Must match with the new password'
            return false;
        }

        return true;
    }

    const handleUpdatePassword = async () => {
        if (!validateUpdatePasswordForm()) return;
        await API.updatePassword(currentPassword, newPassword, confirmPassword).then((e) => {

            notification.addNotification({
                title: 'Success!',
                message: e.response.data.detail,
                severity: 'SUCCESS',
                timeout: 3000
            })

        }).catch((err) => {
            notification.addNotification({
                title: 'Error',
                message: err.response.data.detail,
                severity: 'ERROR',
                timeout: 3000
            })

        })
    }

    const [updatePasswordForm, setUpdatePasswordForm] = useState(false)

    return (
        <div className="product-bg default-page-layout">
            <div className="default-page-container">

                <div className="page-header">
                    <div className="text-page-title">User Profile</div>
                </div>

                <div className="ORGsettings-panel">
                    <div className="flex flex-col gap-5">

                        <div className="flex flex-row gap-24">
                            <div className="input-container">
                                <label className="text-title-small">Name</label>
                                <p className='text-subtitle'>Name of the account holder</p>
                                <input type='text' placeholder={userInfos ?userInfos.name : ''} onChange={(e) => setName(e.target.value)} disabled />
                            </div>

                            <div className="input-container">
                                <label className="text-title-small">Surname</label>
                                <p className='text-subtitle'>Surname of the account holder</p>
                                <input type='text' placeholder={userInfos ?userInfos.surname : ''} onChange={(e) => setSurname(e.target.value)} disabled />
                            </div>
                        </div>

                        <div className="input-container">
                            <label className="text-title-small">Email</label>
                            <p className='text-subtitle'>Email of the account holder</p>
                            <input type='text' placeholder={userInfos ? userInfos.email : ''} onChange={(e) => setEmail(e.target.value)} disabled />
                        </div>
                        <div className="flex flex-row justify-between">
                            <Button className="!h-10 w-1/3" rounded action={() => {
                                if (window.confirm('Only the changes will be saved.'))
                                    alert('TBD')
                            }}
                                disabled={name === '' && surname === '' && email === ''}>Save</Button>
                            <Button className="!h-10 w-1/3" action={() => {
                                setUpdatePasswordForm(!updatePasswordForm)
                            }}
                            >{updatePasswordForm ? 'Close Update Password' : 'Update Password'}
                            </Button>
                        </div>



                        {updatePasswordForm &&
                            <>
                                <div className="flex flex-row gap-24">
                                    <div className="input-container">
                                        <label className="text-xl font-semibold">Current Password</label>
                                        <p>Please enter your current password</p>
                                        <div className='update-form-input'>
                                            <input type={showCurrentPassword ? 'text' : 'password'} placeholder={'current password'} onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword} />
                                            <img src={showCurrentPassword ? eyeClosed : eyeOpened} alt='Show Password' onClick={() => setShowCurrentPassword(!showCurrentPassword)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-24">
                                    <div className="input-container">
                                        <label className="text-xl font-semibold">New Password</label>
                                        <p>Choose a new password {'('}must be at least 10 characters long{')'}</p>
                                        <div className='relative update-form-input'>
                                            <input type={showNewPassword ? 'text' : 'password'} placeholder={'new password'} onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
                                            <img src={showNewPassword ? eyeClosed : eyeOpened} alt='Show Password' onClick={() => setShowNewPassword(!showNewPassword)} />
                                            <label id='new-password-length' className='absolute right-0 -bottom-5 text-red-400 sm:text-base text-xs'></label>
                                        </div>
                                    </div>


                                    <div className="relative input-container w-fit">
                                        <label className="text-xl font-semibold">Confirm New Password</label>
                                        <p>Type your new password again to confirm</p>
                                        <div className='update-form-input'>
                                            <input type={showConfirmPassword ? 'text' : 'password'} placeholder={'confirm new password'} onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} disabled={newPassword === ''} />
                                            <img src={showConfirmPassword ? eyeClosed : eyeOpened} alt='Show Password' onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                            <label id='confirm-password-match' className='absolute right-0 -bottom-5 max-[950px]:-bottom-8 lg:-bottom-12 text-red-400 lg:text-base text-xs'></label>
                                        </div>
                                    </div>

                                </div>
                                <Button className="!h-10 w-1/3" action={() => {

                                    handleUpdatePassword()
                                }}
                                    disabled={currentPassword === ''} rounded>Update Password
                                </Button>
                            </>
                        }

                    </div>

                </div>

            </div>


        </div>)
}