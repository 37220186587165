import { CircleConfirm } from "assets/customButtons/CircleConfirm";
import { EyeSVG } from "assets/usecase/Eye";
import { CircleCancel } from "assets/customButtons/CircleCancel";

type Props = {
  className: string,
  text: string,
  updateFileStatus: any,
  status: string,
}

interface Dict<T> {
  [Key: string]: T
}

const icons: Dict<JSX.Element> = {
  'approved': <CircleConfirm stroke="rgba(0,255,0,1)" />,
  'to_review': <EyeSVG stroke="rgba(255,175,0,1)" />,
  'not_approved': <CircleCancel stroke="rgba(255,0,0,1)" />
}

export function ResultFooterBtn({ className, text, updateFileStatus, status }: Props) {
  return (
    <button
      name="result-table-header-tab"
      className={`  border-2 rounded-myb h-1/2 flex flex-row items-center font-semibold px-2 whitespace-nowrap ${className}`}
      onClick={() => {
        updateFileStatus(status);
      }}
    >

      {icons[status]}
      <span className="px-2 text-lg">
        {text}
      </span>
    </button>
  )
}