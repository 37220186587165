import { CircleCancel } from "assets/customButtons/CircleCancel";
import { useEffect, useRef } from "react";
import { Notification } from "./notificationContext";

type Props = {
  notification: Notification;
  handleClear: Function;
};

type Dict = {
  [key: string]: string;
};

const TypeColor: Dict = {
  DEFAULT: "bg-primary-color",
  SUCCESS: "bg-green-500",
  ERROR: "bg-red-500",
};

export const NotificationToast = ({ notification, handleClear }: Props) => {
  const notificationDiv = useRef<HTMLDivElement | null>(null);

  const handleAnimationClear = () => {
    notificationDiv.current?.classList.replace(
      "notification-in",
      "notification-out"
    );
   
    notificationDiv.current?.addEventListener("animationend", () => {
      notificationDiv.current?.classList.replace(
        "left-0",
        "!-left-[100%]"
      );
      handleClear();
    });
  };
  useEffect(() => {
    if (notification.timeout > 0 && handleClear) {
      const timer = setTimeout(() => {
        handleAnimationClear();
      }, notification.timeout);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div
      ref={notificationDiv}
      className="notification-in opacity-100 !ease-in relative h-28 w-96 border-e border-y rounded-e-myb bg-white flex-col left-0 px-0 py-1"
    >
      <div
        className="absolute top-2 right-2 cursor-pointer "
        onClick={() => {
          handleAnimationClear();
        }}
      >
        <CircleCancel width={20} height={20} />
      </div>
      <div className="w-full px-4 pt-2 text-subtitle">
        {notification.title}
      </div>
      <div className="w-full px-4 text-sm">{notification.message}</div>

      <div
        className={`${
          TypeColor[notification.severity]
        } absolute  w-full h-1 bottom-0 rounded-br-myb`}
      ></div>
    </div>
  );
};
