import * as API from 'apis/api'
import { Button } from "components/button/Button"
import { useNotifications } from 'components/notificationSystem/notificationContext'
import { JuneEvents, trackJune } from 'helpers/hooks/useJune'

import { useState } from "react"

export const SetupOrgForm = ({ setLoadingData, setRefresh, setNavigateAfterLoad, userInfo, analytics }) => {

    const [companyName, setCompanyName] = useState('')
    let orgEmail = ''
    const [companyType, setCompanyType] = useState('Agriculture')
    let orgPhone = ''
    const [companySize, setCompanySize] = useState('individual')
    let invoiceEmail = ''

    let vat = ''

    const notification = useNotifications()

    const onSubmit = async () => {
        if (checkCompanyNameInput()) {
            document.getElementById('companyName-input').style.outline = 'solid 2px red'
            return;
        }

        /* STUBBED */
        orgEmail = userInfo.email
        invoiceEmail = userInfo.email
        orgPhone = '00000'
        vat = '00000'
        /*        */
        await API.registerOrganization(companyName, companyType, companySize, vat, invoiceEmail, orgEmail, orgPhone).then(async (res) => {
            trackJune(analytics, JuneEvents.SIGNED_UP)
            setRefresh(true)
            setNavigateAfterLoad({ to: '/', replace: true })
            setLoadingData(true)

            setTimeout(() => setLoadingData(false), 5000)
        }).catch((e) => {
            notification.addNotification({
                title: 'Organization Setup Error',
                message: e.response?.data?.detail,
                severity: 'ERROR',
                timeout: 5000
            })
        })
    }

    window.onSubmit = onSubmit

    const checkCompanyNameInput = (e) => {
        if (!document.getElementById('companyName-input')) return true;
        let value = e ? e.value : document.getElementById('companyName-input').value;
        if (value === '')
            return true;

        return false;
    }



    const completeLogoSRC = "https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/643eca2362c1b771f19be634_Frame%2015.png";

    return (
        <div className="register-form-box !w-full !h-full">
            <div className="login-header">
                <img src={completeLogoSRC} alt="MyBiros" />
            </div>

            <div className="relative flex flex-col w-full h-full justify-center items-center gap-8">
                <h1 className='text-2xl font-bold text-primary-color'>
                    Setup organization
                </h1>
                <form className="relative flex flex-col w-1/2 h-full  justify-between" onSubmitCapture={(e) => {
                    onSubmit()
                    e.preventDefault()
                }}>

                    <div className='h-fit flex flex-col gap-5'>
                        <div className='relative flex flex-col w-full gap-1'>
                            <label className='text-lg font-semibold'> Company * </label>

                            <input id='companyName-input' className='h-10 px-4 w-full border rounded-myb text-md text-lg' type="text" placeholder="Company name" onChange={(e) => {
                                setCompanyName(e.target.value)
                            }}
                                onFocus={(e) => e.target.style.outline = ''}
                                maxLength={32} />

                            <label id='email-required' className='absolute -bottom-5 right-0 text-red-400'></label>
                        </div>

                        <div className='relative flex flex-col w-full gap-1'>
                            <label className='text-xl font-semibold'> Company size * </label>
                            <select id='role-input' className='h-8 3xl:h-10 px-4 w-full border rounded-myb text-md 3xl:text-lg' type="text" placeholder="surname" onChange={(e) => setCompanySize(e.target.value)} >
                                <option value={"individual"}>Only me</option>
                                <option value={"xxs"}>2-10 employees</option>
                                <option value={"xs"}>11-50 employees</option>
                                <option value={"s"}>51-200 employees</option>
                                <option value={"m"}>201-500 employees</option>
                                <option value={"l"}>501-1000 employees</option>
                                <option value={"xl"}>1001-5000 employees</option>
                                <option value={"2xl"}>5001-10.000 employees</option>
                                <option value={"3xl"}>&gt; 10.001 employees</option>

                            </select>
                        </div>

                        <div className='relative flex flex-col w-full gap-1'>
                            <label className='text-xl font-semibold'> Industry * </label>
                            <select id='role-input' className='h-8 3xl:h-10 px-4 w-full border rounded-myb text-md 3xl:text-lg' type="text" placeholder="surname" onChange={(e) => setCompanyType(e.target.value)} >
                                <option value="Agriculture">Agriculture</option>

                                <option value="Food&Beverages">Food&Beverages</option>
                                <option value="Entertainment&GamingOnline">Entertainment & Gaming online</option>
                                <option value="Construction">Construction</option>
                                <option value="RealEstate">Real Estate</option>
                                <option value="ConsumerGoods&Services">Consumer Goods & Services</option>
                                <option value="Education&Training">Education & Training</option>
                                <option value="Energy & Utilities">Energy & Utilities</option>
                                <option value="Banking&FinancialServices">Banking & Financial Services</option>
                                <option value="Insurance">Insurance</option>
                                <option value="Business">Business</option>
                                <option value="PublicAdministration">Public Administration</option>
                                <option value="HealthcarePharmaceuticals&Biotechnology">Healthcare, Pharmaceuticals & Biotechnology</option>
                                <option value="Hospitality&Tourism">Hospitality & Tourism </option>
                                <option value="InformationTechnology&Telecommunications">Information Technology & Telecommunications </option>
                                <option value="Manufacturing&Industrial">Manufacturing & Industrial </option>
                                <option value="Media">Media</option>
                                <option value="Accounting">Accounting</option>
                                <option value="LegalServices">Legal Services</option>
                                <option value="Consulting">Consulting</option>

                                <option value="Retail&Wholesale">Retail & Wholesale</option>
                                <option value="Transportation&Logistics">Transportation & Logistics</option>
                            </select>
                        </div>

                    </div>
                    <div className="w-full relative flex flex-row justify-center items-center">
                        <Button type="submit" rounded>Create Organization</Button>
                    </div>

                </form>

            </div>
        </div>
    )
}