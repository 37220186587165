import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchToken, logOut, refreshToken } from './auth';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_FRONTEND_SERVER,
});

export const AxiosInterceptors = ({ children }) => {

    const [isInterceptorLoaded, setIsInterceptorLoaded] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const requestInterceptor = axiosInstance.interceptors.request.use(
            (request) => {

                request.headers['Authorization'] = 'Bearer ' + fetchToken()['access_token']
                return request
            },
            (error) => {
                return Promise.reject(error)
            }
        );

        const responseInterceptor = axiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalRequest = error.config;
                if (error.code === 'ERR_NETWORK') {
                    navigate('/service-unavailable', { replace: true })
                    return;
                }
                if (error.response.status === 401 && originalRequest._retry !== true) {
                    originalRequest._retry = true;

                    return refreshToken(originalRequest.url).then(async response => {
                        return await axiosInstance(originalRequest)
                    }).catch(e => {
                        logOut()
                        navigate('/login', { replace: true })
                        return Promise.reject(e);
                    });
                }
                return Promise.reject(error);
            }
        );
        setIsInterceptorLoaded(true);
        // Return cleanup function to remove interceptors if necessary
        if (responseInterceptor > 1) return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInterceptorLoaded]);

    return isInterceptorLoaded && children;
};

export default axiosInstance;