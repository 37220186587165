import { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { useSearchParams } from 'react-router-dom';

import * as API from 'apis/api';
import { Button } from 'components/button/Button';
import { FilterPill } from 'components/filterPill/filterPill';
import { Pagination } from 'components/pagination/pagination';
import { useRef } from 'react';
import DocumentRow from '../documentRow/documentRow';
import { InfoToolTip } from '../infoToolTip/infoToolTip';
export default function DocumentsListTable({ usecase, setUseCase, setRevisionStatusSearch, revisionStatusSearch, refetchAllDocuments, handleExportDoc, analytics }) {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    const handleRevisionStatusFilter = (searchedFilter) => {
        setRevisionStatusSearch(searchedFilter)

        var url = new URL(window.location.href);
        if (searchedFilter === 'All') {
            navigate({ pathname: url.pathname }, { replace: true })
            return
        }

        searchParams.set('revision', searchedFilter)
        searchParams.set('page', 0)
        navigate({ pathname: url.pathname, search: '?' + searchParams.toString() }, { replace: true })
    }

    const [documentList, setDocumentList] = useState([])
    const [lastPage, setLastPage] = useState()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') ? searchParams.get('page') : 0)
    let deleteInterval = useRef(null)

    const [deleteLoading, setDeleteLoading] = useState(false)

    useEffect(() => {

        const fetchDocuments = async () => {
            await API.listAllDocuments(usecase.public_id, revisionStatusSearch !== 'All' ? revisionStatusSearch : '', currentPage).then((res) => {
                let newDocList = res.items_data;
                setLastPage(res.last_page);
                setDocumentList(newDocList);
                if (newDocList.find(x => x.status === 'DELETE'))
                    setDeleteLoading(true)
            })

        }
        usecase && fetchDocuments()

    }, [usecase, revisionStatusSearch, searchParams])

    useEffect(() => {
        deleteInterval.current = setInterval(async () => {
            if (usecase && deleteLoading) {

                await API.listAllDocuments(usecase.public_id, revisionStatusSearch !== 'All' ? revisionStatusSearch : '', currentPage).then((res) => {
                    let newDocList = res.items_data;
                    setLastPage(res.last_page);
                    setDocumentList(newDocList);
                    if (!newDocList.find(x => x.status === 'DELETE')) {
                        clearInterval(deleteInterval.current);
                        setDeleteLoading(false)
                    }
                })

                await API.getUseCase(usecase.public_id).then((res) => {

                    setUseCase(res)
                })

            }
        }, [3500]);
        return () => clearInterval(deleteInterval.current);
    }, [currentPage, revisionStatusSearch, usecase, deleteLoading])

    const navigateToResult = (doc) => {
        searchParams.set('revision', revisionStatusSearch)
        searchParams.set('file', Object.keys(doc.files)[0])
        navigate({ pathname: `/usecase/${usecase.public_id}/result/${doc.public_id}`, search: '?' + searchParams.toString() }, { state: { 'currentUseCase': usecase, 'fromPage': currentPage }, replace: false })
    }

    return (
        <>{
            usecase && documentList && <div>
                {
                    usecase.current_saved_documents > 0 ? <>
                        <div className='relative w-full h-10 flex flex-row justify-between'>
                            <div className='w-fit flex flex-row gap-2 mb-2 '>
                                <FilterPill filterItem={'All'} filterSelected={revisionStatusSearch} callbackAction={handleRevisionStatusFilter} > All </FilterPill>
                                <FilterPill filterItem={'pending'} filterSelected={revisionStatusSearch} callbackAction={handleRevisionStatusFilter} >New </FilterPill>
                                <FilterPill filterItem={'to_review'} filterSelected={revisionStatusSearch} callbackAction={handleRevisionStatusFilter} >To Review </FilterPill>
                                <FilterPill filterItem={'approved'} filterSelected={revisionStatusSearch} callbackAction={handleRevisionStatusFilter} >Approved </FilterPill>
                                <FilterPill filterItem={'not_approved'} filterSelected={revisionStatusSearch} callbackAction={handleRevisionStatusFilter} >Rejected </FilterPill>
                            </div>
                            {documentList && <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} lastPage={lastPage} />}
                        </div>
                        <div className="relative table overflow-visible">
                            <div className="th !shadow-lg">
                                <div id="t-name">
                                    File Name
                                </div>
                                <div id="t-status" className='gap-1'>
                                    Processing <InfoToolTip text='This value indicates the processing status of the document' />
                                </div>
                                <div id="t-createdat">
                                    Created at
                                </div>
                                <div id="t-revision_status" className='flex-row gap-[0.15rem] justify-center'>
                                    Revision Status <InfoToolTip text='This value indicates the revision status of the document' />
                                </div>
                                <div id="t-export">

                                </div>
                                <div id="t-delete" />
                            </div>
                            <div className="relative tb h-full" >
                                {
                                    documentList && documentList.length > 0 && documentList.map((entry, index) =>
                                        <DocumentRow entry={entry} navigateCallback={navigateToResult} handleExportDoc={handleExportDoc} refetchAllDocuments={refetchAllDocuments} analytics={analytics} setDeleteLoading={setDeleteLoading} />
                                    )
                                }
                            </div>
                            <div className="table-footer"></div>
                        </div>
                    </>
                        :
                        <div className="relative w-100 h-[600px] border-2 shadow-primary-color rounded-sm flex justify-center text-center bg-[#f5f7fd]">
                            <div className="flex flex-row items-center gap-2">
                                <div className="flex flex-col gap-2">
                                    <span className='flex flex-col gap-4 font-light text-2xl mb-5 text-primary-color'>
                                        <h1 className='font-bold '>
                                            Ready to go?</h1>
                                        Upload your documents and start processing!
                                    </span>

                                    <Button className="w-full h-15 text-" inverted action={() => {
                                        document.getElementById('overlay-loader').style.display = 'flex'
                                    }}>Upload</Button>
                                    <div className='font-light mt-1 text-center'>Please upload a file to get started.</div>

                                </div>
                            </div>
                        </div>
                }</div>}
        </>
    )
}