import {ClipboardSVG} from "assets/apikey/ClipboardSVG";
import eyeClosed from "assets/form/eyeClosed.svg";
import eyeOpened from "assets/form/eyeOpened.svg";
import {TrashSVG} from "assets/usecase/TrashSVG";
import copy from "copy-to-clipboard";
import {useEffect, useRef} from "react";
import {Toast} from "../../../helpers/toast";

type APIKey = {
    name: string;
    created_at: string;
    token: string;
};

type Props = {
    apiKey: APIKey;
    index: number;
    showAPIKey: number;
    setShowAPIKey: React.Dispatch<React.SetStateAction<number>>;
    deleteAPIKey: (index: number) => Promise<void>;
    deleteApiPill: (index: number) => void;
};

export const ApiKeyPillNew = ({
                                  apiKey,
                                  index,
                                  showAPIKey,
                                  setShowAPIKey,
                                  deleteAPIKey,
                                  deleteApiPill,
                              }: Props) => {
    const hideReplace = "************************";

    const apiKeySpan = useRef<HTMLElement | null>(null);

    const deleteRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        deleteRef &&
        deleteRef.current &&
        deleteRef.current.addEventListener("animationend", () => {
            deleteApiPill(index);
        });
    }, [deleteRef]);

    return (
        <div id={`api-${index}`}
             className="bg-white border md:flex-row p-4 rounded-myb hover:bg-[#f5f7fd] transition-colors duration-300"
        >

            <div className="grid grid-cols-9 grid-rows-1 gap-4 md:flex-row p-4 rounded-lg md:justify-between">

                <div className="col-span-2 flex-col">
                    <div><span className={"text-xs text-slate-500"}>Name</span></div>
                    <div className="text-container overflow-hidden text-ellipsis whitespace-nowrap">
                            <span className="text-md text-primary-color">{apiKey.name}</span>
                    </div>
                </div>

                <div className={"col-span-4 flex flex-row items-center mt-1 p-3 gap-3"}>
                     <span
                         ref={apiKeySpan}
                         className="w-[340px] w-min-[100px] bg-white whitespace-nowrap h-8 text-xs font-normal text-primary-color font-mono border rounded-myb flex items-center overflow-auto p-1"
                         style={{
                             userSelect: showAPIKey === index ? "auto" : "none",
                         }}
                     >
                    {showAPIKey === index ? apiKey.token : hideReplace}
                    </span>

                    <div className="flex flex-row gap-2 items-center">
                        <div
                            className="border bg-white rounded-myb w-8 h-8 p-1 cursor-pointer select-none flex hover:bg-slate-300 hover:border-slate-500 active:bg-slate-100 !transition-all !duration-75"
                            onClick={() =>
                                showAPIKey === index ? setShowAPIKey(-1) : setShowAPIKey(index)
                            }
                        >
                            <img
                                className=""
                                src={showAPIKey === index ? eyeClosed : eyeOpened}
                                alt="Show API Key"
                            />
                        </div>
                        <Toast mode="click" text='Copied!'>
                            <div
                                className="border bg-white rounded-myb w-8 h-8 p-1 cursor-pointer select-none flex justify-center hover:bg-blue-100 hover:border-blue-500 active:bg-blue-100 !transition-all !duration-75"
                                onClick={async () => {
                                    try {
                                        navigator.clipboard.writeText(apiKey.token);
                                    } catch (e) {
                                        copy(apiKey.token);
                                    }
                                }}
                            >
                                <ClipboardSVG width={20} height={20}/>
                            </div>
                        </Toast>
                    </div>
                </div>

                <div className="col-span-2 flex-col hidden md:inline">
                    <div><span className={"text-xs text-slate-500"}>Created At</span></div>
                    <div><span className={"text-sm text-primary-color"}>{apiKey.created_at}</span></div>
                </div>

                <div className="col-span-1 ml-auto flex justify-center items-center">
                    <div
                        className="border bg-white rounded-myb w-8 h-8 p-1 cursor-pointer select-none flex justify-center hover:bg-red-300 hover:border-red-500 active:bg-blue-100 !transition-all !duration-75"
                        onClick={() => {
                            if (window.confirm("Are you sure?")) deleteAPIKey(index);
                        }}
                    >
                        <TrashSVG width={20} height={20} />
                    </div>
                </div>

            </div>
        </div>
    );
};
