import logoSRC from 'assets/logo/myb_logo_cube.png';
import { Button } from 'components/button/Button';

export const SetupOrgWelcome = ({ setStep }) => {
    return (
        <div className="flex h-full flex-col items-center justify-center gap-10">
            <div className="w-36"><img src={logoSRC} alt="Welcome to myBiros" /></div>
            <div className="flex flex-col items-center justify-center gap-4">
                <span className="font-bold text-3xl">Welcome to myBiros!</span>
                <span className="text-xl">To ensure the best experience, please set up your organization first.</span>
            </div>
            <div>
                <Button className=" mt-10 w-60 text-lg font-medium text-blue-600 outline-blue-600"
                    action={() => setStep(1)} rounded>Start</Button>
            </div>
        </div>
    )
}