import { Error503SVG } from "assets/Error503SVG";
import { createPortal } from "react-dom";

export const ServiceUnavailableRoute = ({}) => {
  return createPortal(
    <div className="fixed bg-white top-[75px] left-0 z-[30] h-screen w-screen flex items-center justify-center">
      <div className=" w-full min-[1092px]:w-3/4 min-[1650px]:w-1/2 h-full flex flex-row max-[820px]:flex-col  items-center">
        <div className="flex items-center justify-end max-[820px]:h-1/3 max-[820px]:mx-10">
          <div className="h-1/2  w-fit flex flex-col max-[820px]:items-center min-[820px]:ps-20">
            <span className="text-page-title">Service Unavailable</span>
            <span className="w-fit">
              {" "}
              Sorry for testing delay in your work. We are just temporarily down
              due to regular maintenance. We will back up again soon!
            </span>
          </div>
        </div>
        <div className="flex min-[820px]:min-w-[450px] max-[820px]:h-1/2 max-[820px]:absolute max-[820px]:w-2/3 top-[100px]">
          {" "}
          <Error503SVG />
        </div>
      </div>
    </div>,
    document.body
  );
};
