import * as API from "apis/api";
import { AddSimpleAnnotationSVG } from "assets/canvas/AddSimpleAnnotationSVG";
import { Button } from "components/button/Button";
import { useEffect, useState } from "react";
import { AggregatedEntityBox } from "./aggregatedEntityBox";
import { UpdateAggregatedEntityPanel } from "./updateAggregatedEntityPanel";

export type Dict<T> = {
  [key: string]: T | null;
};

type Aggregation = {
  aggregation_type: string;
  aggregation_fields: Array<string>;
};

export type Label = {
  tag: string;
  tag_alias: string;
  tag_multiple_values: false;
  tag_aggregation: Aggregation | null;
};

type Entity = {
  id: number;
  confidence: number;
  text: string;
  bounding_boxes: Array<any>;
};

export type Line = {
  id: number;
  aggregated_fields: Dict<Entity>;
  name?: string;
  isNew?: boolean;
};

type Props = {
  inference: Dict<any>;
  labels: Dict<Label>;
  refetchInferenceResult: () => void;
};

export const formatText = (text: string | undefined): string => {
  if (!text) return "";
  let newText = text[0].toUpperCase() + text.slice(1);
  newText = newText.replace(/_([a-z0-9A-Z])/g, function (m) {
    return " " + m[1].toUpperCase();
  });

  return newText;
};

export const AggregatedAnnotationPanel = ({
  inference,
  labels,
  refetchInferenceResult,
}: Props) => {
  const [lines, setLines] = useState<Array<Line>>([]);
  const [aggregationType, setAggregationType] = useState<Label | null>(null);
  const [aggregationKey, setAggregationKey] = useState<string | null>(null);

  const [unusedLabels, setUnusedLabels] = useState<Array<any> | null>(null);

  const createUnusedLabels = () => {
    //console.log(inference)
  }

  useEffect(() => {
    if (!inference) {
      setLines([]);
      return;
    }

    let newLines: Array<Line> = [];
    let temp;
    let toEdit = inference["aggregated_entities"];
    for (let aggr in toEdit) {
      temp = toEdit[aggr];
      for (let line in temp) {
        temp[line]["name"] = aggr;
        newLines.push(temp[line]);
      }
    }
    setLines(newLines.reverse());
    createUnusedLabels()
  }, [inference]);

  useEffect(() => {
    if (!labels) {
      setAggregationType(null);
      return;
    }
    let aggregationLabel = null;
    let realKey = null;
    for (let key in labels) {
      if (labels[key]?.tag_aggregation !== null) {
        aggregationLabel = labels[key];
        realKey = key;
        break;
      }
    }
    setAggregationKey(realKey);
    setAggregationType(aggregationLabel);
  }, [labels]);

  const handleDeleteLine = async (line: Line) => {
    await API.deleteAggregatedEntity(
      inference.use_case,
      inference.document,
      inference.public_id,
      line.name,
      line.id
    ).then(() => {
      if (line.isNew && line.isNew === true) closeUpdateFlow();
      refetchInferenceResult();
    });
  };

  const [createMode, setCreateMode] = useState(false);

  const handleCreateNewAggregatedEntity = async (aggregatedFields: any[]) => {
    let entity = {} as Dict<number>;
    for (let item of aggregatedFields) {
      entity[item[0]] = item[1] ? item[1].id : -1;
    }

    API.addAggregatedEntity(
      inference.use_case,
      inference.document,
      inference.public_id,
      aggregationKey,
      entity
    ).then((res) => {
      refetchInferenceResult();
      setCreateMode(false);
    });
  };

  const [updateFlow, setUpdateFlow] = useState<{
    line: Line;
    labelKey: string;
  } | null>(null);

  const closeUpdateFlow = () => {
    setUpdateFlow(null);
  };

  const handleUpdateAggregatedEntity = async (
    line: Line,
    newLabels: Array<any>
  ) => {
    let oldAggrFields = line.aggregated_fields;
    if (!oldAggrFields) return;
    let newAggrFields: Dict<any> = {};

    for (let x in oldAggrFields) {
      let labelToUpdate = newLabels.find((l) => l[0] === x);
      if (labelToUpdate) {
        newAggrFields[x] = labelToUpdate[1]?.id ? labelToUpdate[1].id : -1;
      }
    }

    await API.updateAggregatedEntity(
      inference.use_case,
      inference.document,
      inference.public_id,
      aggregationKey,
      newAggrFields,
      line.id
    ).then(() => {
      refetchInferenceResult();
      closeUpdateFlow();
    });
  };
  const closeCreateMode = () => {
    setCreateMode(false);
  };

  const getEntitiesForAggregationType = () => {
    if(!aggregationType?.tag_aggregation) return {}
    let toFilter = {} as Dict<any>

    for(let keyToHave of aggregationType?.tag_aggregation?.aggregation_fields){
      toFilter[keyToHave] = inference.entities[keyToHave]
      
    }

    return toFilter
  }
  return (
    <>
      {(createMode || updateFlow) && aggregationType ? (
        <UpdateAggregatedEntityPanel
          line={updateFlow && updateFlow.line}
          lines={lines}
          aggregationType={aggregationType}
          labels={labels}
          closeUpdateFlow={closeUpdateFlow}
          entities={getEntitiesForAggregationType()}
          handleUpdateAggregatedEntity={handleUpdateAggregatedEntity}
          handleDeleteLine={handleDeleteLine}
          handleCreateNewAggregatedEntity={handleCreateNewAggregatedEntity}
          createMode={createMode}
          closeCreateMode={closeCreateMode}
        />
      ) : (
        <>
          {aggregationType && (
            <Button
              className="!h-10 w-full rounded-myb border-2 !border-[#EAEBFB] flex items-center justify-center cursor-pointer gap-2"
              action={() => setCreateMode(true)}
            >
              Add aggregated field{""}
              <AddSimpleAnnotationSVG width={25} height={25} />
            </Button>
          )}
        </>
      )}
      <div className=" h-full px-1 flex flex-col overflow-auto w-full gap-5">
        {lines &&
          lines.filter(x => x.id!== updateFlow?.line.id).reverse().map((line) => (
            <AggregatedEntityBox
              line={line}
              labels={labels}
              handleDeleteLine={handleDeleteLine}
              closeCreateMode={closeCreateMode}
              setUpdateFlow={setUpdateFlow}
            />
          ))}
      </div>
    </>
  );
};
