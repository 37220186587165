/*

    Creazione a step:
    1- campo obbligatorio o confidence score? (Campo Obbligatorio, Core Rule)scegliere nomi??
    2- CO - 'Seleziona i campi che ti aspetti di ricevere sempre valorizzati durante l'elaborazione', toogle vicino per label a lista di label
    3- CO - Applicare a tutte le pagine, altrimenti selezione range di pagine
    2- CS - Seleziona il campo (stubba false must exists per ora) e slider da 0 a 100
    3- CS - identico al 3 di CO
    4- Scegli nome??? Forse.

*/
import { useState } from "react";
import { StepFields } from "./steps/stepFields";
import { StepPages } from "./steps/stepPages";
import { StepPolicyType } from "./steps/stepPolicyType";
import { StepResume } from "./steps/stepResume";

type Dict = {
  [key: string]: any;
};

type Props = {
  labels: Array<any>;
  saveNewPolicyRule: (
    name: string,
    pType: "RequiredFields" | "FieldMinConfidence" | null | undefined,
    ruleArgs: Dict,
    rulePages: string
  ) => void;
  setAddRulePanel: React.Dispatch<React.SetStateAction<boolean>>;
  newIndex: number;
};

export const NewPolicyModal = ({
  labels,
  saveNewPolicyRule,
  setAddRulePanel,
  newIndex,
}: Props) => {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [policyType, setPolicyType] = useState<
    "RequiredFields" | "FieldMinConfidence" | null
  >();
  const [selectedLabels, setSelectedLabels] = useState<Array<any> | null>(null);
  const [selectedScoreLabel, setSelectedScoreLabel] = useState<any>(null);
  const [isAllPages, setIsAllPages] = useState<boolean>(false);
  const [fromAToB, setFromAToB] = useState<any>(null);

  const selectPolicyType = (
    newType: "RequiredFields" | "FieldMinConfidence"
  ) => {
    setPolicyType(newType);
    setStepIndex((old) => old + 1);
  };

  const backCallback = () => {
    if (stepIndex === 0) return;
    if (stepIndex === 1) {
      setPolicyType(null);
      setSelectedLabels(null);
      setSelectedScoreLabel(null);
      setIsAllPages(false);
      setFromAToB(null);
    } else if (stepIndex === 2) {
      setSelectedLabels(null);
      setSelectedScoreLabel(null);
      setIsAllPages(false);
      setFromAToB(null);
    } else if (stepIndex === 3) {
      setIsAllPages(false);
      setFromAToB(null);
    }
    setStepIndex((old) => old - 1);
  };

  const saveSelectedLabels = (labels: Array<any>) => {
    setSelectedLabels(labels);
    setStepIndex((old) => old + 1);
  };

  const saveSelectedScoreLabel = (label: any, score: number) => {
    setSelectedScoreLabel({ label: label, score: score });
    setStepIndex((old) => old + 1);
  };

  const savePageRange = (isAll: boolean, a: number, b: number) => {
    if (isAll) {
      setFromAToB(null);
      setIsAllPages(true);
    } else {
      setIsAllPages(false);
      setFromAToB({ A: a, B: b });
    }
    setStepIndex((old) => old + 1);
  };

  const createCallback = async (name: string) => {
    let ruleArgs: Dict = [];
    if (policyType === "RequiredFields") {
      ruleArgs = {
        required_fields: selectedLabels!.map((x) => x[0]),
      };
    } else {
      ruleArgs = {
        field: selectedScoreLabel.label[0],
        min_value: selectedScoreLabel.score,
        must_exists: false,
      };
    }
    let rulePages = isAllPages ? "*" : fromAToB.A + "-" + fromAToB.B;
    saveNewPolicyRule(name, policyType, ruleArgs, rulePages);
  };

  const closePanel = () => {
    setAddRulePanel(false);
  };

  const steps = [
    <StepPolicyType
      selectPolicyType={selectPolicyType}
      closePanel={closePanel}
    />,
    <StepFields
      policyType={policyType}
      labels={labels}
      backCallback={backCallback}
      saveSelectedLabels={saveSelectedLabels}
      saveSelectedScoreLabel={saveSelectedScoreLabel}
    />,
    <StepPages backCallback={backCallback} savePageRange={savePageRange} />,
    <StepResume
      backCallback={backCallback}
      policyType={policyType}
      selectedLabels={selectedLabels}
      selectedScoreLabel={selectedScoreLabel}
      isAllPages={isAllPages}
      fromAToB={fromAToB}
      createCallback={createCallback}
      newIndex={newIndex}
    />,
  ];

  return (
    <div className="absolute flex items-center justify-center bg-black w-full !z-10 h-full !pointer-events-default bg-opacity-10">
      <div className="h-[70%] lg:w-2/5 w-fit flex flex-col gap-2 p-5">
        <div className="bg-white shadow-md flex flex-col h-full w-full border px-10 rounded-myb gap-1 py-5">

          <div className="mt-5 text-normal-small text-primary-color w-full flex items-center justify-center !font-semibold">
            Setup a New Validation Policy
          </div>

          <div className="w-full h-full flex gap-2 flex-col !overflow-hidden">
            {steps[stepIndex]}
          </div>
        </div>
      </div>
    </div>
  );
};
